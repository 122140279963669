import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../store';
import { SettingsState } from '../settings.state';

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectSelectedHookType = createSelector(selectSettingsState, (state: SettingsState) =>
  state.hooks.types.find((item) => item.type === state.hooks.selectedHookType),
);
export const selectHooks = createSelector(selectSettingsState, (state: SettingsState) => state.hooks.items);
export const selectHooksErrors = createSelector(selectSettingsState, (state: SettingsState) => state.hooks.errors);
export const selectHookTypes = createSelector(selectSettingsState, (state: SettingsState) => state.hooks.types);
export const selectHookEvents = createSelector(selectSettingsState, (state: SettingsState) => state.hooks.events);
export const selectHooksLoadingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hooks.isLoading,
);
export const selectHooksErrorFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hooks.isResponseError,
);
export const selectHooksTypesLoadingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hooks.areTypesLoading,
);
export const selectHooksIsSubmittingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hooks.isFormSubmitting,
);
export const selectHooksIsModalOpenFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hooks.isModalOpen,
);
export const selectAreAllHooksLoadedFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hooks.areAllItemsLoaded,
);
export const selectIsHookRemoved = (id: number) =>
  createSelector(selectSettingsState, (state: SettingsState) => !state.hooks.items.find((item) => item.id === id));
