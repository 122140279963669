import { ValidatorRunner } from './models';
import * as VS from '../helpers/validators.helpers';

const settings = [
  { name: 'with', fn: VS.doesMatch },
  { name: 'without', fn: VS.doesNotMatch },
];

export const FormatValidator: ValidatorRunner = {
  name: 'FormatValidator',
  message: 'errors.FormatValidator',
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    // common validator options
    if (VS.isTrue(options.allow_nil) && VS.isNull(value)) return true;
    if (VS.isTrue(options.allow_blank) && VS.isEmpty(value)) return true;

    // probably the safest casting to string
    const v = `${value}`;

    return settings
      .map(function (o) {
        return VS.isUndefined(options[o.name]) || o.fn.call(null, v, new RegExp(options[o.name]));
      })
      .every(function (result) {
        return result;
      });
  },
};
