import { SelectOption } from '../common/components/forms/xp-select.component';

export const SORT_DIRECTION: SelectOption[] = [
  {
    text: 'fields-collection.editors.sort-direction-picker.ASC',
    value: 'ASC',
    translate: true,
  },
  {
    text: 'fields-collection.editors.sort-direction-picker.DESC',
    value: 'DESC',
    translate: true,
  },
];
