import { createAction, props } from '@ngrx/store';
import { EdgeEvent, ExtendedEdge } from '../package.models';
import { ComponentTypeItem } from '../../constants/component_types';
import { ValidationError } from './state.model';

export const openComponentsModal = createAction(
  '[PackageDesigner] Open components modal',
  props<{
    componentId?: string;
    parentComponentId?: string;
    flags?: {
      hasSources: boolean;
      hasTransformations: boolean;
      hasDestinations: boolean;
      hasWorkflow: boolean;
    };
  }>(),
);
export const closeComponentsModal = createAction('[PackageDesigner] Close components modal');

export const openPackageVariablesModal = createAction('[PackageDesigner] Open package variables modal');
export const closePackageVariablesModal = createAction('[PackageDesigner] Close package variables modal');

export const openPackageImportModal = createAction('[PackageDesigner] Open package import modal');
export const closePackageImportModal = createAction('[PackageDesigner] Close package import modal');

export const openPackageVersionMessageModal = createAction('[PackageDesigner] Open package version message modal');
export const closePackageVersionMessageModal = createAction('[PackageDesigner] Close package version message modal');

export const openExpressionEditor = createAction(
  '[PackageDesigner] Open expression editor',
  props<{
    code: string;
    origin?: string;
    category: string;
    index?: number;
  }>(),
);
export const closeExpressionEditor = createAction(
  '[PackageDesigner] Close expression editor',
  props<{
    code?: string;
  }>(),
);

export const switchParentSchemasOrder = createAction('[PackageDesigner] Switch parent schemas order');
export const updateComponentPosition = createAction(
  '[PackageDesigner] Update component position',
  props<{ distanceX: number; distanceY: number; id: string }>(),
);
export const updateComponentTempPosition = createAction(
  '[PackageDesigner] Update temporary component position',
  props<{ distanceX: number; distanceY: number; id: string }>(),
);

export const updateEdge = createAction('[PackageDesigner] Update edge', props<{ edgeId: string; event: EdgeEvent }>());
export const createEdge = createAction(
  '[PackageDesigner] Create edge',
  props<{ sourceComponentId: string; x: number; y: number }>(),
);
export const createEdgeEndDrag = createAction(
  '[PackageDesigner] Create edge end drag',
  props<{ sourceComponentId: string }>(),
);
export const removeEdge = createAction('[PackageDesigner] Remove edge', props<{ edge: ExtendedEdge }>());
export const updateEdgeTempPosition = createAction(
  '[PackageDesigner] Update temporary edge position',
  props<{ x: number; y: number; id: string }>(),
);

export const resizeNoteComponentTemp = createAction(
  '[PackageDesigner] Resize note component temp',
  props<{ height: number; width: number; id: string }>(),
);

export const resizeNoteComponent = createAction(
  '[PackageDesigner] Resize note component',
  props<{ height: number; width: number; id: string }>(),
);

export const removeComponents = createAction('[PackageDesigner] Remove component', props<{ componentId?: string }>());
export const duplicateComponentsAction = createAction(
  '[PackageDesigner] Duplicate components',
  props<{ componentId?: string }>(),
);
export const createComponentAction = createAction(
  '[PackageDesigner] Create component',
  props<{ component: Partial<ComponentTypeItem> }>(),
);
export const createNoteAction = createAction('[PackageDesigner] Create Note');

export const hoverComponent = createAction(
  '[PackageDesigner] Hover component',
  props<{ componentId: string; isHovered: boolean }>(),
);
export const selectComponents = createAction(
  '[PackageDesigner] Select components',
  props<{ componentIds: string[] }>(),
);
export const selectAllComponents = createAction('[PackageDesigner] Select all components');
export const selectConnectedComponents = createAction('[PackageDesigner] Select connected components');
export const unselectAllComponents = createAction('[PackageDesigner] Unselect all components');

export const undoAction = createAction('[PackageDesigner] UndoAction');
export const redoAction = createAction('[PackageDesigner] RedoAction');

export const cutAction = createAction('[PackageDesigner] CutAction');
export const copyAction = createAction('[PackageDesigner] CopyAction');
export const pasteAction = createAction('[PackageDesigner] PasteAction');

export const autoAlign = createAction('[PackageDesigner] Auto align', props<{ width: number }>());

export const clearHistory = createAction('[PackageDesigner] Clear history');

export const exportJSON = createAction('[PackageDesigner] Export JSON');
export const exportJSONSuccess = createAction('[PackageDesigner] Export JSON success');
export const exportJSONError = createAction('[PackageDesigner] Export JSON error', props<{ err: any }>());

export const importJSON = createAction('[PackageDesigner] Import JSON', props<{ body: string }>());
export const importJSONError = createAction('[PackageDesigner] Import JSON error', props<{ err: any }>());

export const createMajorVersion = createAction('[PackageDesigner] Create major version', props<{ message: string }>());
export const createMajorVersionError = createAction(
  '[PackageDesigner] Create major version error',
  props<{ err: any }>(),
);

export const savePackage = createAction(
  '[PackageDesigner] Save package',
  props<{ openJobModal?: boolean; runValidation?: boolean }>(),
);
export const savePackageSuccess = createAction(
  '[PackageDesigner] Save package success',
  props<{ openJobModal?: boolean; runValidation?: boolean }>(),
);
export const savePackageError = createAction('[PackageDesigner] Save package error', props<{ err: any }>());

export const runValidationAction = createAction('[PackageDesigner] Run validation');
export const runValidationError = createAction('[PackageDesigner] Run validation error', props<{ err: any }>());
export const getValidationStatus = createAction('[PackageDesigner] Get validation status', props<{ id: number }>());
export const validationStatusSuccess = createAction('[PackageDesigner] Validation status success');
export const validationStatusError = createAction(
  '[PackageDesigner] Validation status error',
  props<{ err: ValidationError[] }>(),
);
export const hideErrorsViewer = createAction('[PackageDesigner] Hide Errors Viewer');

export const clearPackageData = createAction('[PackageDesigner] Clear package data');
