import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemType, ListItemSnippetType } from '../../components/lists/list-item-snippet.component';

export enum DialogType {
  danger = 'danger',
  success = 'success',
  info = 'info',
  warning = 'warning',
}

export interface DialogData {
  title: string;
  hint: string;
  yes: string;
  no: string;
  type: DialogType;
  itemState: string;
  item?: ListItemSnippetType;
  itemType?: ItemType;
  hideSnippet?: boolean;
  maxWidth?: string;
  minWidth?: string;
  hideIcon?: boolean;
}

@Component({
  selector: 'dialog-template',
  template: `
    <div class="dialog confirm alert alert-danger text-center">
      <div class="dialog-text">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="danger-icon"
          *ngIf="!data.hideIcon"
        >
          <path
            d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke="#F44B3F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h3>{{ data.title }}</h3>
        <p [innerHTML]="data.hint"></p>
        <list-item-snippet *ngIf="!data.hideSnippet" [itemType]="data.itemType" [item]="data.item"></list-item-snippet>
      </div>
      <div class="dialog-btn-container">
        <button class="btn btn-lg btn-default dialog-button" (click)="cancel()">{{ data.no }}</button>
        <button
          class="btn btn-lg dialog-button"
          [ngClass]="{
            'btn-danger': data.type === 'danger',
            'btn-warning': data.type === 'warning',
            'btn-info': data.type === 'info',
            'btn-success': data.type === 'success'
          }"
          (click)="confirm()"
        >
          {{ data.yes }}
        </button>
      </div>
      <div class="dialog-close" (click)="cancel()"></div>
    </div>
  `,
})
export class DialogTemplateComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogTemplateComponent>,
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
