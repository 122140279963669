import { Component, EventEmitter, Input, Output } from '@angular/core';
import { groupBy, uniq } from 'lodash';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../store';
import { selectHookEvents } from '../../store/hooks/hooks.selectors';
import { HookEvent } from '../../hook.models';

interface HookEventGroup {
  name: string;
  id: string;
  events: HookEvent[];
}

function initGroups(hookEvents: HookEvent[]): HookEventGroup[] {
  const hookEventGroupsMap = groupBy(hookEvents, (hookEvent) => hookEvent.group_name);

  return Object.keys(hookEventGroupsMap).map((key) => {
    const groupData = hookEventGroupsMap[key].shift();

    return {
      name: groupData.group_name,
      id: groupData.id,
      events: hookEventGroupsMap[key],
    };
  });
}

@Component({
  selector: 'hook-events-editor',
  template: `
    <div class="hook-events-editor">
      <p>
        <strong>{{ 'hook.form.hint' | translate }}</strong>
      </p>
      <div *ngFor="let hookEventGroup of hookEventGroups$ | async" class="hook-events">
        <label for="hook-group-{{ hookEventGroup.name }}">
          <input
            type="checkbox"
            name="hook-group-{{ hookEventGroup.name }}"
            [checked]="isGroupChecked(hookEventGroup)"
            (click)="toggleGroup(hookEventGroup, !isGroupChecked(hookEventGroup))"
            id="hook-group-{{ hookEventGroup.name }}"
          />
          {{ hookEventGroup.name }}
        </label>
        <div *ngFor="let event of hookEventGroup.events" class="hook-events-children">
          <label for="hook-{{ hookEventGroup.name }}-{{ event.name }}">
            <input
              type="checkbox"
              name="hook-{{ hookEventGroup.name }}-{{ event.name }}"
              [checked]="isEventChecked(event.id)"
              (click)="toggleEvent(event, !isEventChecked(event.id))"
              id="hook-{{ hookEventGroup.name }}-{{ event.name }}"
            />
            {{ event.name }}
          </label>
        </div>
      </div>
    </div>
  `,
})
export class HookEventsEditorComponent {
  hookEvents$ = this.store.select(selectHookEvents);
  hookEventGroups$: Observable<HookEventGroup[]> = this.hookEvents$.pipe(map(initGroups));
  @Input() events: string[] = [];
  @Output() eventsChange = new EventEmitter<string[]>();

  constructor(protected store: Store<AppState>) {}

  isGroupChecked(group: HookEventGroup): boolean {
    return group.events.every((event) => this.events.includes(event.id));
  }

  isEventChecked(eventId: string): boolean {
    return this.events.includes(eventId);
  }

  toggleGroup(group: HookEventGroup, isActive: boolean) {
    const groupEventsIds = group.events.map((event) => event.id);
    if (isActive) {
      this.eventsChange.emit(uniq([...this.events, ...groupEventsIds]));
    } else {
      this.eventsChange.emit(this.events.filter((item) => !groupEventsIds.includes(item)));
    }
  }

  toggleEvent(event: HookEvent, isActive: boolean) {
    if (isActive) {
      this.eventsChange.emit(uniq([...this.events, event.id]));
    } else {
      this.eventsChange.emit(this.events.filter((item) => item !== event.id));
    }
  }
}
