import { Injectable } from '@angular/core';

import { catchError, shareReplay } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ValidationsExportsResource } from '../../account/resources/validationsExports.resource';
import { AppState } from '../../store';
import { updateValidators } from './store/validators.actions';
import { NotifyService } from '../services/notify.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationExportsService {
  private subscribeCall = this.validationsExportResource.get().pipe(
    shareReplay(1),
    catchError((err) => {
      this.notify.error('Validations export failed!!');
      return of({});
    }),
  );

  constructor(
    private validationsExportResource: ValidationsExportsResource,
    private store: Store<AppState>,
    private notify: NotifyService,
  ) {}

  public loadValidations(): Promise<any> {
    this.subscribeCall.subscribe({
      next: (validators) => {
        this.store.dispatch(updateValidators({ validators }));
      },
      error: () => {
        this.notify.error('Validations export failed!!');
      },
    });
    return lastValueFrom(this.subscribeCall);
  }
}
