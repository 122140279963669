import { createReducer, on } from '@ngrx/store';
import { Job } from '../jobs.models';
import {
  closeChildJobDetailsModal,
  closeJobDetailsModal,
  closeJobsModal,
  getJobsList,
  getJobsListError,
  getJobsListResponse,
  loadMoreJobsList,
  loadMoreJobsListResponse,
  openChildJobDetailsModal,
  openJobDetailsModal,
  openJobsModal,
  removeJobResponse,
  saveJob,
  saveJobError,
  saveJobResponse,
  searchJobsList,
  searchJobsListResponse,
  updateJob,
  updateJobError,
  updateJobResponse,
} from './jobs.actions';
import { ValidationError } from '../../config/validation-error.model';
import { QueryParamsJobsList } from '../../common/helper/query-params-generic-list.helper';
import { closeAllModals } from '../../account/store/account.actions';

export const initialState: JobsState = {
  items: [],
  item: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  isDetailsModalOpen: false,
  isChildDetailsModalOpen: false,
  errors: [],
  areAllItemsLoaded: false,
  isResponseError: false,
  isSearchEnabled: false,
};

export interface JobsState {
  items: Job[];
  item: Job;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  isDetailsModalOpen: boolean;
  isChildDetailsModalOpen: boolean;
  errors: ValidationError[];
  areAllItemsLoaded: boolean;
  isResponseError: boolean;
  isSearchEnabled: boolean;
}

export const jobsReducer = createReducer<JobsState>(
  initialState,

  on(openJobsModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeJobsModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({
    ...state,
    isModalOpen: false,
    isDetailsModalOpen: false,
    isChildDetailsModalOpen: false,
  })),

  on(openJobDetailsModal, (state) => ({ ...state, isDetailsModalOpen: true })),
  on(closeJobDetailsModal, (state) => ({ ...state, isDetailsModalOpen: false })),

  on(openChildJobDetailsModal, (state) => ({ ...state, isChildDetailsModalOpen: true })),
  on(closeChildJobDetailsModal, (state) => ({ ...state, isChildDetailsModalOpen: false })),

  on(getJobsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getJobsListResponse, (state, { jobs }) => ({
    ...state,
    isLoading: false,
    items: jobs,
    areAllItemsLoaded: jobs.length < QueryParamsJobsList.limit,
    isSearchEnabled: false,
  })),
  on(getJobsListError, (state) => ({
    ...state,
    isLoading: false,
    items: [],
    isResponseError: true,
    isSearchEnabled: false,
  })),

  on(searchJobsList, (state) => ({ ...state, isLoading: true, isResponseError: false, isSearchEnabled: true })),
  on(searchJobsListResponse, (state, { jobs }) => ({
    ...state,
    isLoading: false,
    items: jobs,
    areAllItemsLoaded: jobs.length < QueryParamsJobsList.limit,
    isSearchEnabled: true,
  })),

  on(loadMoreJobsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreJobsListResponse, (state, { jobs }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...jobs],
    areAllItemsLoaded: jobs.length < QueryParamsJobsList.limit,
  })),

  on(saveJob, (state) => ({ ...state, isFormSubmitting: true })),
  on(saveJobResponse, (state, { data, closeModal }) => {
    // eslint-disable-next-line prefer-destructuring
    let items = state.items;

    if (data['is_root?'] && !state.isSearchEnabled) {
      const foundItemInTheList = state.items.find((item) => item.id === data.id);

      if (!foundItemInTheList) {
        items = [data, ...state.items];
      }
    }

    return {
      ...state,
      isFormSubmitting: false,
      // eslint-disable-next-line no-nested-ternary
      items,
      isModalOpen: closeModal ? false : state.isModalOpen,
    };
  }),
  on(saveJobError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(updateJob, (state) => ({ ...state, isFormSubmitting: true })),
  on(updateJobResponse, (state, { data }) => ({
    ...state,
    items: !data['is_root?']
      ? state.items
      : state.items.map((item) => (item.id === data.id ? { ...item, ...data } : item)),
    isFormSubmitting: false,
  })),
  on(updateJobError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(removeJobResponse, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id === data.id ? { ...item, ...data } : item)),
  })),
);
