import { createAction, props } from '@ngrx/store';
import { Package } from '../../packages/package.models';
import { Component } from '../package.models';

export const setPackageFromJson = createAction(
  '[PackageDesigner] Set package from json',
  props<{ packageItem: Package }>(),
);
export const updatePackage = createAction('[PackageDesigner] Update package', props<{ packageItem: Package }>());
export const updateComponentInPackage = createAction(
  '[PackageDesigner] Update component in package',
  props<{ changedComponentsOrder: Component[] }>(),
);
export const loadPackage = createAction('[PackageDesigner] Load package');
export const loadPackageError = createAction('[PackageDesigner] Load package error');
