import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../store';
import { SchedulesState } from './schedules.reducer';

export const selectSchedulesState = createFeatureSelector<SchedulesState>('schedules');

export const selectSchedules = createSelector(selectSchedulesState, (state: SchedulesState) => state.items);
export const selectSchedulesErrors = createSelector(selectSchedulesState, (state: SchedulesState) => state.errors);
export const selectSchedulesLoadingFlag = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.isLoading,
);
export const selectSchedulesErrorFlag = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.isResponseError,
);
export const selectSchedulesIsSubmittingFlag = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.isFormSubmitting,
);
export const selectSchedulesIsModalOpenFlag = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.isModalOpen,
);
export const selectPackagePickerIsModalOpenFlag = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.isPackagePickerModalOpen,
);
export const selectIsEditingPackage = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.isEditingPackage,
);
export const selectAreAllSchedulesLoadedFlag = createSelector(
  selectSchedulesState,
  (state: SchedulesState) => state.areAllItemsLoaded,
);
