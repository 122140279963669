import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'xp-name-description-editor',
  template: `
    <div class="name-description-editor">
      <div>
        <xp-form-group *ngIf="!disableValidation">
          <label
            >{{ 'name-description-editor.labels.name' | translate }}
            <span class="text-muted" *ngIf="optionalName">{{ 'generic.labels.optional' | translate }}</span></label
          >
          <input
            type="text"
            maxlength="254"
            name="name"
            [(ngModel)]="item.name"
            #nameInput
            class="form-control"
            [placeholder]="namePlaceholder | translate"
          />
        </xp-form-group>
        <div class="form-group" *ngIf="disableValidation">
          <label
            >{{ 'name-description-editor.labels.name' | translate }}
            <span class="text-muted" *ngIf="optionalName">{{ 'generic.labels.optional' | translate }}</span></label
          >
          <input
            type="text"
            maxlength="254"
            name="name"
            [(ngModel)]="item.name"
            #nameInput
            class="form-control"
            [placeholder]="namePlaceholder | translate"
          />
        </div>
      </div>
      <div *ngIf="showDescription || item.description">
        <xp-form-group *ngIf="!disableValidation">
          <label
            >{{ 'name-description-editor.labels.description' | translate }}
            <span class="text-muted" *ngIf="optionalDescription">{{
              'generic.labels.optional' | translate
            }}</span></label
          >
          <textarea
            name="description"
            maxlength="1024"
            [(ngModel)]="item.description"
            class="form-control"
            [placeholder]="descriptionPlaceholder | translate"
            rows="3"
          >
          </textarea>
        </xp-form-group>
        <div class="form-group" *ngIf="disableValidation">
          <label
            >{{ 'name-description-editor.labels.description' | translate }}
            <span class="text-muted" *ngIf="optionalDescription">{{
              'generic.labels.optional' | translate
            }}</span></label
          >
          <textarea
            name="description"
            maxlength="1024"
            [(ngModel)]="item.description"
            class="form-control"
            [placeholder]="descriptionPlaceholder | translate"
            rows="3"
          >
          </textarea>
        </div>
      </div>
      <div class="form-group description-toggle" *ngIf="!showDescription || !item.description">
        <div *ngIf="!item.description" (click)="toggleDescription()">
          <span class="add text-muted" *ngIf="!showDescription">{{
            'name-description-editor.actions.add' | translate
          }}</span>
          <span class="remove text-muted" *ngIf="showDescription">{{
            'name-description-editor.actions.remove' | translate
          }}</span>
        </div>
      </div>
    </div>
  `,
})
export class XpNameDescriptionEditorComponent implements AfterViewInit {
  @Input() item: any = {};
  @Input() disableValidation: boolean = false;
  @Input() optionalName: boolean = false;
  @Input() optionalDescription: boolean = false;
  @Input() namePlaceholder: string = 'name-description-editor.placeholders.name';
  @Input() descriptionPlaceholder: string = 'name-description-editor.placeholders.description';
  @ViewChild('nameInput') nameInput: ElementRef<HTMLInputElement>;

  showDescription = false;

  ngAfterViewInit() {
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    });
  }

  toggleDescription(): void {
    this.showDescription = !this.showDescription;
  }
}
