import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { Step } from '../xp-steps.component';
import { BaseForm, BaseFormInterface } from '../../base/base-form.component';
import { Package } from '../../../packages/package.models';
import { selectPackagesErrors, selectPackagesIsSubmittingFlag } from '../../../packages/store/packages.selectors';
import { SelectPickerTypes } from './select-picker/select-picker-types.enum';
import { AppState } from '../../../store';
import { closePackageVersionsModal, rollbackPackage } from '../../../packages/store/packages.actions';
import { setPackageFromJson } from '../../../package-designer/store/package.actions';
import { PackagesResource } from '../../../packages/resources/packages.resource';

function getStep(): Step {
  return {
    title: '',
    activeTitle: '',
    tags: [],
    active: true,
    valid: false,
    lock: false,
    disable: false,
  };
}

@Component({
  selector: 'package-version-form',
  template: `
    <div class="package-version-form">
      <div class="package-form-body">
        <xp-form-validation [type]="'Package'" [name]="formName">
          <form name="packageVersionForm" class="package-version-form" #form="ngForm">
            <div class="package-version-form-body">
              <div class="package-version-form-body-content">
                <xp-steps>
                  <xp-step [step]="packageVersionStep">
                    <xp-select-picker-editable
                      id="package-version-form-picker"
                      *ngIf="item"
                      [type]="selectPickerTypes['package-version']"
                      [value]="versionObject"
                      [params]="{ package_id: item.id }"
                      placeholder="Select package version"
                      emptyPlaceholder="The list of package versions is empty"
                      (valueChange)="onPackageVersionChange($event)"
                      [hideNew]="true"
                      definedId="version"
                    ></xp-select-picker-editable>
                  </xp-step>
                </xp-steps>
              </div>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="package-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-package" size="L"></common-icon>
          <h3 class="modal-title">{{ 'package.generic-list.item.labels.version' | translate }}</h3>
        </div>

        <xp-submit-button
          (click)="savePackage(item)"
          classNames="btn-lg btn-success pull-right rollback"
          [createText]="!item.id && 'package.form.buttons.rollback' | translate"
          [updateText]="item.id && 'package.form.buttons.rollback' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class PackageVersionFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Input() item: Partial<Package> = {};
  @Input() isPackageDesigner = false;
  @ViewChild('form') form: NgForm;
  formName = 'packageVersionForm';
  successMessageText = 'package.form.success_message';
  isSubmitting$ = this.store.select(selectPackagesIsSubmittingFlag);
  errors$ = this.store.select(selectPackagesErrors);
  errorTexts = [];
  selectPickerTypes = SelectPickerTypes;
  packageVersionId: number;
  versionObject: Package;
  packageVersionStep = getStep();

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private packagesResource: PackagesResource,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.packageVersionStep.title = this.translate.instant('package-version-form.steps.package');
    this.packageVersionStep.activeTitle = this.translate.instant('package-version-form.steps.package-edit');
  }

  onPackageVersionChange(packageVersionItem: Package) {
    if (!packageVersionItem) {
      return;
    }
    this.versionObject = packageVersionItem;
    this.packageVersionId = packageVersionItem.version;
  }

  savePackage(packageItem: Partial<Package>) {
    this.store.dispatch(rollbackPackage({ packageId: packageItem.id, versionId: this.packageVersionId }));

    this.store.dispatch(closePackageVersionsModal());

    if (this.isPackageDesigner) {
      this.packagesResource
        .get(
          this.route.snapshot.params.package_id,
          {
            include: 'flow',
          } as any,
          this.route.parent.snapshot.params.account_id,
        )
        .subscribe((packageItemElement) => {
          this.store.dispatch(setPackageFromJson({ packageItem: packageItemElement }));
        });
    } else {
      this.router.navigate(['./'], { relativeTo: this.route });
    }
  }
}
