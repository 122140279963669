import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'xp-input-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => XpInputCheckboxComponent),
      multi: true,
    },
  ],
  template: `
    <label class="input-checkbox" [ngClass]="{ disabled: disabled, readonly: readOnly, 'is-selected': ngModel }">
      <div class="input-checkbox-content">
        <i class="fa" [ngClass]="{ 'fa-square-o': !checked, 'fa-check-square': checked }"></i>
        <input
          [attr.name]="name"
          type="checkbox"
          [(ngModel)]="checked"
          (change)="onChange(checked)"
          [disabled]="disabled"
          [readOnly]="readOnly"
        />

        <span
          [matTooltip]="hint"
          [matTooltipPosition]="matTooltipPosition || 'above'"
          [matTooltipClass]="matTooltipPosition || 'above'"
          >{{ labelText }}</span
        >
      </div>
      <ng-content></ng-content>
    </label>
  `,
})
export class XpInputCheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() ngModel: string | number | boolean;
  @Output() ngModelChange = new EventEmitter<string | number | boolean>();
  @Input() hint: string = '';
  @Input() matTooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after';
  @Input() name: string = '';
  @Input() labelText: string = '';
  @Input() disabled: boolean = false;
  @Input() readOnly: boolean = false;

  checked = false;

  firstChange = true;
  propagateChange = (_: string | number | boolean) => {};
  propagateTouch = () => {};

  ngOnInit() {
    this.onChange(this.ngModel);
  }

  ngOnChanges(changes) {
    if (changes.ngModel) {
      this.checked = !!changes.ngModel.currentValue;
    }
  }

  onChange(value) {
    // this.ngModelChange.emit(value);
    this.propagateChange(value);
  }

  writeValue(value: string | number | boolean) {
    if (value !== undefined) {
      this.ngModel = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }
}
