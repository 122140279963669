import { AfterViewInit, Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { filter, first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericListType, LoadMoreQuery } from '../../../common/components/lists/generic-list.component';
import { AppState } from '../../../store';
import {
  closeHooksModal,
  getHookEvents,
  getHooksList,
  getHookTypes,
  loadMoreHooksList,
  openHooksModal,
  selectHookType,
} from '../../store/hooks/hooks.actions';
import {
  selectAreAllHooksLoadedFlag,
  selectHooks,
  selectHooksErrorFlag,
  selectHooksIsModalOpenFlag,
  selectHooksLoadingFlag,
} from '../../store/hooks/hooks.selectors';
import { Hook, HookTypesEnum } from '../../hook.models';
import { QueryParamsHooksList } from '../../../common/helper/query-params-generic-list.helper';

@Component({
  selector: 'hooks-list',
  template: `
    <generic-list
      [type]="type"
      [emptyTitle]="'hook.generic-object.empty.title' | translate"
      [emptyDescription]="'hook.generic-object.empty.description' | translate"
      [items]="hooks$ | async"
      [isLoading]="hooksLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openCreateModal()"
      [newBtnText]="'hook.generic-object.buttons.new' | translate"
      (loadMore)="loadMoreHooks($event)"
      [limit]="queryParams.limit"
      [allItemsLoaded]="areAllHooksLoaded$ | async"
      [isError]="hooksError$ | async"
    >
      <ng-template templateName="listItem" let-item>
        <hook-list-item [item]="item" (editClick)="openEditModal($event)"></hook-list-item>
      </ng-template>
    </generic-list>
    <xp-modal
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'hook.generic-object.buttons.close' | translate"
      [saveButtonText]="'hook.generic-object.buttons.save' | translate"
      [titleText]="'hook.generic-object.title' | translate"
      [isSmall]="true"
      (close)="onModalClose()"
    >
      <ng-template>
        <hook-form [item]="hook"></hook-form>
      </ng-template>
    </xp-modal>
  `,
})
export class HooksListComponent implements AfterViewInit {
  hook: Partial<Hook> = {
    settings: {},
    events: [],
  };
  type = GenericListType.hooks;
  hooksLoading$ = this.store.select(selectHooksLoadingFlag);
  hooksError$ = this.store.select(selectHooksErrorFlag);
  isModalOpen$ = this.store.select(selectHooksIsModalOpenFlag);
  hooks$ = this.store.select(selectHooks);
  areAllHooksLoaded$ = this.store.select(selectAreAllHooksLoadedFlag);
  queryParams = QueryParamsHooksList;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(getHooksList({ params: this.queryParams }));
    this.store.dispatch(getHookTypes());
    this.store.dispatch(getHookEvents());
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/hooks/new')) {
      this.openCreateModal(false);
    }
    if (this.router.url.match(/\/hooks\/\w+\/new/)) {
      const { type } = this.route.firstChild?.snapshot.params;
      this.openCreateModal(false, type);
    }
    if (this.router.url.match(/\/hooks\/\w+\/edit/)) {
      this.hooks$
        .pipe(
          filter((hooks) => hooks.length > 0),
          first(),
        )
        .subscribe((hooks) => {
          const { hook_id } = this.route.firstChild?.snapshot.params;
          const foundHook = hooks.find((item) => item.id === Number(hook_id));
          if (!foundHook) {
            this.router.navigate(['./'], { relativeTo: this.route });
          } else {
            this.openEditModal(foundHook);
          }
        });
    }
  }

  openEditModal(item: Hook): void {
    this.router.navigate([`${item.id}/edit`], { relativeTo: this.route });
    this.hook = cloneDeep(item);
    this.store.dispatch(selectHookType({ hookType: item.type }));
    this.store.dispatch(openHooksModal());
  }

  openCreateModal(redirect = true, type?: HookTypesEnum): void {
    if (redirect) {
      this.router.navigate(['new'], { relativeTo: this.route });
    }

    this.hook = {
      settings: {},
      events: [],
      active: true,
      type,
    };
    this.store.dispatch(selectHookType({ hookType: type }));
    this.store.dispatch(openHooksModal());
  }

  onModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeHooksModal());
  }

  loadMoreHooks(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreHooksList({ params }));
  }
}
