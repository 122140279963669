import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { RackspaceConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { RegionsResource } from '../../resources/regions.resource';

@Component({
  selector: 'connection-form-rackspace',
  template: `
    <xp-form-validation type="RackspaceCloudFilesConnection" [name]="formName">
      <form id="connectionFormRackspace" name="connectionFormRackspace" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <xp-form-group>
              <label for="username">{{ 'connections.form.rackspace.labels.username' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="username"
                id="username"
                [(ngModel)]="connection.username"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="password">{{ 'connections.form.rackspace.labels.password' | translate }}</label>
              <xp-input
                type="password"
                class="form-control"
                name="password"
                id="password"
                [(ngModel)]="connection.password"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="region">{{ 'connections.form.rackspace.labels.region' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                id="region"
                name="region"
                [value]="connection.region"
                [options]="rackspaceRegions"
                [isGrouped]="true"
                [isSearchEnabled]="true"
                panelClass="connection-select-box"
                (valueChange)="onRegionChange($event)"
                emptyPlaceholder="No region found"
              >
              </xp-select>
            </xp-form-group>
          </div>
          <div class="col-md-6"></div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormRackspaceComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<RackspaceConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormRackspace';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  rackspaceRegions = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,

    private regionsResource: RegionsResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.regionsResource.query('rackspace').subscribe((rackspaceRegions) => {
      this.rackspaceRegions = rackspaceRegions.map(({ id, short_name, group_name }) => ({
        text: short_name,
        value: id,
        group_name,
      }));
    });
  }

  onRegionChange(region: string) {
    this.connection.region = region;
  }
}
