import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericListType } from '../../../common/components/lists/generic-list.component';
import { User } from '../../user.models';

@Component({
  selector: 'sidebar-mock',
  template: `
    <div id="sidebar-mock" class="sidebar">
      <div class="sidebar-main">
        <ul class="list-unstyled menu-top">
          <li [ngClass]="{ active: type === types.connections }">
            <a
              [attr.disabled]="!started ? 'disabled' : false"
              (click)="started && setType.emit(types.connections)"
              matTooltipPosition="right"
              matTooltipClass="hide-arrow"
              [matTooltip]="'sidebar.menu.items.connections' | translate"
            >
              <svg class="icon icon-connection">
                <use href="#icon-connection"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.connections' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: type === types.workspaces }">
            <a
              [attr.disabled]="!started ? 'disabled' : false"
              (click)="started && setType.emit(types.workspaces)"
              matTooltipPosition="right"
              matTooltipClass="hide-arrow"
              [matTooltip]="'sidebar.menu.items.workspaces' | translate"
            >
              <svg class="icon icon-workspace">
                <use href="#icon-workspace"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.workspaces' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: type === types.packages }">
            <a
              [attr.disabled]="!started ? 'disabled' : false"
              (click)="started && setType.emit(types.packages)"
              matTooltipPosition="right"
              matTooltipClass="hide-arrow"
              [matTooltip]="'sidebar.menu.items.packages' | translate"
            >
              <svg class="icon icon-package">
                <use href="#icon-package"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.packages' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: type === types.jobs }">
            <a
              [attr.disabled]="!started ? 'disabled' : false"
              (click)="started && setType.emit(types.jobs)"
              matTooltipPosition="right"
              matTooltipClass="hide-arrow"
              [matTooltip]="'sidebar.menu.items.jobs' | translate"
            >
              <svg class="icon icon-job">
                <use href="#icon-job"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.jobs' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: type === types.clusters }">
            <a
              [attr.disabled]="!started ? 'disabled' : false"
              (click)="started && setType.emit(types.clusters)"
              matTooltipPosition="right"
              matTooltipClass="hide-arrow"
              [matTooltip]="'sidebar.menu.items.clusters' | translate"
            >
              <svg class="icon icon-cluster">
                <use href="#icon-cluster"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.clusters' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: type === types.schedules }">
            <a
              [attr.disabled]="!started ? 'disabled' : false"
              (click)="started && setType.emit(types.schedules)"
              matTooltipPosition="right"
              matTooltipClass="hide-arrow"
              [matTooltip]="'sidebar.menu.items.schedules' | translate"
            >
              <svg class="icon icon-schedules">
                <use href="#icon-schedules"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.schedules' | translate }}</span>
            </a>
          </li>
        </ul>
        <div class="menu-bottom">
          <div class="user-name">
            <span>{{ user.name || user.email }}</span>
          </div>
          <img *ngIf="user.avatar_url" class="user-avatar" [src]="user.avatar_url" alt="{{ user.name }}" />
          <svg *ngIf="!user.avatar_url" class="user-avatar">
            <use href="#icon-avatar"></use>
          </svg>
          <help-menu></help-menu>
        </div>
      </div>
    </div>
  `,
})
export class SidebarMockComponent {
  @Input() started: boolean;
  @Input() type: GenericListType;
  @Input() user: Partial<User>;
  @Output() setType = new EventEmitter<GenericListType>();
  types = GenericListType;
}
