import { Component, Input } from '@angular/core';

@Component({
  selector: 'success-notify',
  template: `
    <div
      [ngClass]="{ 'show-notify': !!success.length }"
      class="mat-mdc-snack-bar-container success-notify alert notify-template success no-shadow"
    >
      <div class="mdc-snackbar__surface">
        <span class="fa-stack" *ngIf="success.length === 1">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa fa-check fa-stack-1x"></i>
        </span>
        <div
          class="message-container"
          *ngFor="let message of success"
          [ngStyle]="{ display: success.length === 1 ? 'inline-block' : 'block' }"
        >
          <p class="message" [innerHTML]="message"></p>
        </div>
      </div>
    </div>
  `,
})
export class SuccessNotifyComponent {
  @Input() success: string[];
}
