import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemType, ListItemSnippetType } from '../../components/lists/list-item-snippet.component';
import { NotifyService } from '../notify.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { selectAccountId } from '../../../account/store/account.selectors';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

const typeMap = {
  'ai-formatter': 'AI Formatter',
  'ai-mapper': 'AI Mapper',
  'ai-enrich': 'AI Enrich',
  'ai-matcher': 'AI Matcher',
};
@Component({
  selector: 'dialog-template',
  template: `
    <div class="dialog confirm alert alert-danger text-center ai-transformations-dialog">
      <div class="dialog-text">
        <h3>AI Transformation Components</h3>

        <div class="component-container" (click)="requestBetaAccess('ai-formatter')">
          <div class="component-title">AI Formatter</div>
          <div class="component-description">
            Take bunch of different formatted phone numbers and standardize to one format
            <br />
            <i class="component-description-example"
              >Example: Format this list of mobile phone numbers into the following format: (XXX)-XXX-XXXX</i
            >
          </div>
          <button
            type="button"
            class="component-button btn btn-lg btn-success"
            [disabled]="isButtonDisabled('ai-formatter')"
          >
            {{ buttonText('ai-formatter') }}
          </button>
        </div>
        <div class="component-container" (click)="requestBetaAccess('ai-mapper')">
          <div class="component-title">AI Mapper</div>
          <div class="component-description">
            AI Mapper allows the source data to get reformed into the target data based on exemplar that you provide.
            <br />
            <i class="component-description-example"
              >Example: review these descriptions of properties and map them to apartment / condo / home and the number
              of rooms, etc.</i
            >
          </div>
          <button
            type="button"
            class="component-button btn btn-lg btn-success"
            [disabled]="isButtonDisabled('ai-mapper')"
          >
            {{ buttonText('ai-mapper') }}
          </button>
        </div>
        <div class="component-container" (click)="requestBetaAccess('ai-enrich')">
          <div class="component-title">AI Enrich</div>
          <div class="component-description">
            AI Enrich allows the source data to be used in a prompt that is sent to ChatGPT and then outputs the return.
            <br />
            <i class="component-description-example"
              >Example: Suggest improvements for this sales transcript with a focus on whether these product benefits
              were specifically highlighted: ease of use, scalability, reliability</i
            >
          </div>
          <button
            type="button"
            class="component-button btn btn-lg btn-success"
            [disabled]="isButtonDisabled('ai-enrich')"
          >
            {{ buttonText('ai-enrich') }}
          </button>
        </div>
        <div class="component-container" (click)="requestBetaAccess('ai-matcher')">
          <div class="component-title">AI Matcher</div>
          <div class="component-description">
            AI Matcher allows the source data to be taken from one and matched to the other.
            <br />
            <i class="component-description-example"
              >Example: Match the records in this table with the records in this CRM object.</i
            >
          </div>
          <button
            type="button"
            class="component-button btn btn-lg btn-success"
            [disabled]="isButtonDisabled('ai-matcher')"
          >
            {{ buttonText('ai-matcher') }}
          </button>
        </div>
      </div>
      <div class="dialog-btn-container"></div>
      <div class="dialog-close" (click)="close()"></div>
    </div>
  `,
})
export class AiTransformationsDialogComponent {
  requestedItems = {
    'ai-formatter': false,
    'ai-mapper': false,
    'ai-enrich': false,
    'ai-matcher': false,
  };
  constructor(
    public dialogRef: MatDialogRef<AiTransformationsDialogComponent>,
    private notify: NotifyService,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  close() {
    this.dialogRef.close(false);
  }

  requestBetaAccess(type: string) {
    this.requestedItems[type] = true;

    this.store
      .pipe(
        selectAccountId,
        mergeMap((account_id) =>
          this.http.post(`${environment.API_URL}/features/ai_transformations`, { type: typeMap[type], account_id }),
        ),
      )
      .subscribe(() => {
        this.notify.success(
          'One of our team will be in touch to discuss your AI requirements and get you access.',
          'Thank you for joining our beta list!',
          10000,
        );
      });
  }

  buttonText(type: string): string {
    return this.requestedItems[type] ? 'Requested' : 'Request Access';
  }

  isButtonDisabled(type: string): boolean {
    return this.requestedItems[type];
  }
}
