import { Component, Input } from '@angular/core';
import { Workspace } from '../../../../workspaces/workspaces.models';

@Component({
  selector: 'xp-select-picker-workspace',
  template: `
    <div class="select-picker-workspace">
      <div class="select-picker-item">
        <div class="select-picker-header">
          <div class="select-picker-icon">
            <common-icon iconId="icon-workspace"></common-icon>
          </div>
          <div class="select-picker-name" [innerHTML]="workspace.name | xpHighLight : highlightValue"></div>
        </div>
        <div class="select-picker-body" *ngIf="workspace.updated_at">
          <span class="small" *ngIf="workspace.updated_at && workspace.packages">{{
            'workspace.generic-list.item.labels.packages-length' | translate : { length: workspace.packages.length }
          }}</span>
          <span class="small" *ngIf="workspace.updated_at && workspace.modified_by"
            >created by {{ workspace.modified_by.name || workspace.modified_by.email }}</span
          >
          <span class="small" *ngIf="workspace.updated_at"
            >created
            <span class="date-tooltip" [matTooltip]="workspace.updated_at | xpDateUTC">{{
              workspace.created_at | xpPrettyDate
            }}</span></span
          >
        </div>
      </div>
    </div>
  `,
})
export class XpSelectPickerWorkspaceComponent {
  @Input() workspace: Workspace;
  @Input() highlightValue: string = '';
}
