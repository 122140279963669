import { GLOBAL_TERM_REFERENCE } from './help/components/xplenty-console.component';

document.addEventListener('copy', function (e) {
  let text;

  const isTermVisible =
    GLOBAL_TERM_REFERENCE &&
    !!GLOBAL_TERM_REFERENCE.element.offsetHeight &&
    !!GLOBAL_TERM_REFERENCE.element.offsetWidth &&
    !!GLOBAL_TERM_REFERENCE.element.getClientRects().length;

  if (isTermVisible) {
    text = GLOBAL_TERM_REFERENCE.getSelection();
    text = text.replace(/[\n\r]+[ ]+/g, '').replace(/[\n\r]+/g, '');
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
  }
});
