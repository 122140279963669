import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../store';
import { SettingsState } from '../settings.state';

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectKeys = createSelector(selectSettingsState, (state: SettingsState) => state.keys.items);
export const selectKeysErrors = createSelector(selectSettingsState, (state: SettingsState) => state.keys.errors);
export const selectKeysLoadingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.keys.isLoading,
);
export const selectKeysErrorFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.keys.isResponseError,
);
export const selectKeysIsSubmittingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.keys.isFormSubmitting,
);
export const selectKeysIsModalOpenFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.keys.isModalOpen,
);
export const selectAreAllKeysLoadedFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.keys.areAllItemsLoaded,
);
export const selectIsKeyRemoved = (id: number) =>
  createSelector(selectSettingsState, (state: SettingsState) => !state.keys.items.find((item) => item.id === id));
