import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

const iconClassMap = {
  success: 'fa-check',
  warning: 'fa-exclamation',
  danger: 'fa-exclamation',
  info: 'fa-info',
};

export interface NotifyTemplateData {
  message: string;
  title: string;
  iconType: string;
  containerClass?: string;
}

@Component({
  selector: 'notify-template',
  template: `
    <div class="{{ data.containerClass }} notify-template">
      <button type="button" (click)="snackBarRef.dismiss()" class="close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="alert-heading" *ngIf="data.title">
        <span class="fa-stack">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa {{ iconClass }} fa-stack-1x"></i>
        </span>
        {{ data.title }}
      </h4>
      <div class="message-container">
        <span *ngIf="!data.title" class="fa-stack">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa {{ iconClass }} fa-stack-1x"></i>
        </span>
        <span class="message" [innerHTML]="data.message"></span>
      </div>
    </div>
  `,
})
export class NotifyTemplateComponent {
  iconClass = '';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotifyTemplateData,
    public snackBarRef: MatSnackBarRef<NotifyTemplateComponent>,
  ) {
    this.iconClass = iconClassMap[data.iconType];
  }
}
