import { Component } from '@angular/core';

@Component({
  template: `
    <div class="auth-container">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class AuthComponent {}
