import { ValidatorRunner } from './models';

export const FieldNamePresenceValidator: ValidatorRunner = {
  name: 'FieldNamePresenceValidator',
  validate({ value, store }) {
    let presence = false;
    const { schemas } = store.validation;

    schemas.forEach(function (schema) {
      schema.fields.forEach(function (field) {
        if (field.name === value) presence = true;
      });
    });
    return !presence;
  },
};
