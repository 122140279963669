import { createAction, props } from '@ngrx/store';
import { ValidationError } from '../../config/validation-error.model';
import { Job } from '../jobs.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

export const openJobsModal = createAction('[Jobs] Open modal');
export const closeJobsModal = createAction('[Jobs] Close modal');

export const openJobDetailsModal = createAction('[Jobs] Open details modal');
export const closeJobDetailsModal = createAction('[Jobs] Close details modal');

export const openChildJobDetailsModal = createAction('[Jobs] Open child details modal');
export const closeChildJobDetailsModal = createAction('[Jobs] Close child details modal');

export const getJobsList = createAction('[Jobs] Get jobs list', props<{ params: ListParams }>());
export const getJobsListResponse = createAction('[Jobs] Get jobs list response', props<{ jobs: Job[] }>());
export const getJobsListError = createAction('[Jobs] Get jobs list error', props<{ errors: ValidationError[] }>());

export const getJobError = createAction('[Jobs] Get job error', props<{ errors: ValidationError[] }>());

export const searchJobsList = createAction('[Jobs] Search jobs list', props<{ params: ListParams }>());
export const searchJobsListResponse = createAction('[Jobs] Search jobs list response', props<{ jobs: Job[] }>());

export const loadMoreJobsList = createAction('[Jobs] Load more jobs list', props<{ params: ListParams }>());
export const loadMoreJobsListResponse = createAction('[Jobs] Load more jobs list response', props<{ jobs: Job[] }>());

export const saveJob = createAction('[Jobs] Save job', props<{ job: Partial<Job> }>());
export const saveJobResponse = createAction('[Jobs] Save job response', props<{ data: Job; closeModal?: boolean }>());
export const saveJobError = createAction('[Jobs] Save job error', props<{ errors: ValidationError[] }>());

export const updateJob = createAction(
  '[Jobs] Update job',
  props<{ job: Partial<Job>; params: ListParams; jobId: number }>(),
);
export const updateJobResponse = createAction('[Jobs] Update job response', props<{ data: Job }>());
export const updateJobError = createAction('[Jobs] Update job error', props<{ errors: ValidationError[] }>());

export const removeJob = createAction('[Jobs] Remove job', props<{ jobId: number }>());
export const removeJobItem = createAction('[Jobs] Remove job item', props<{ data: Job }>());
export const removeJobResponse = createAction('[Jobs] Remove job response', props<{ data: Job }>());
export const removeJobError = createAction('[Jobs] Remove job error', props<{ errors: ValidationError[] }>());
