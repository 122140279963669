import { Component } from '@angular/core';

@Component({
  selector: 'error-not-allowed',
  template: `
    <div class="alert-box-middle">
      <div class="alert-box">
        <div class="alert-box-container">
          <h3 class="lead">Access Denied</h3>
          <p>
            Your account access has been turned off due to overdue unpaid invoices on your account. Please contact
            support (<a href="mailto:support@integrate.io">support&#64;integrate.io</a>) with proof of payment in order
            to turn back on your account access. Existing schedules and pipelines are still running during this time.
          </p>
        </div>
      </div>
    </div>
  `,
})
export class ErrorNotAllowedComponent {}
