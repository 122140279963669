import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { ADVANCED_ROLES, Member } from '../../members.models';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { closeEditRolesMembersModal, removeMember, updateMember } from '../../store/members/members.actions';
import { selectMembersErrors, selectMembersIsSubmittingFlag } from '../../store/members/members.selectors';
import { ItemType } from '../../../common/components/lists/list-item-snippet.component';
import { ConfirmationDialogService } from '../../../common/services/confirmation-dialog.service';
import { selectUserId } from '../../../account/store/account.selectors';

@Component({
  selector: 'member-edit-form',
  template: `
    <div class="member-form">
      <div class="member-form-body">
        <xp-form-validation type="User" [name]="formName">
          <form id="memberForm" name="memberForm" #form="ngForm" class="modal-form-container">
            <div class="form-group">
              <xp-form-group>
                <label for="email">{{ 'member.form.labels.email' | translate }}</label>
                <xp-input
                  id="email"
                  type="email"
                  name="email"
                  [(ngModel)]="item.email"
                  class="form-control"
                  [readOnly]="true"
                  [attr.readonly]="true"
                  placeholder="{{ 'member.form.placeholders.email' | translate }}"
                ></xp-input>
              </xp-form-group>
            </div>

            <member-edit-roles
              [roles]="item.advanced_roles || []"
              [role]="item.role"
              (rolesChange)="onRolesChange($event)"
            ></member-edit-roles>

            <div *ngxPermissionsOnly="'deleteMember'">
              <div *ngIf="!item.owner" class="btn btn-danger2 btn-lg" (click)="onRemove(item)">
                {{ 'member.generic-list.item.actions.delete' | translate }}
              </div>
            </div>
            <errors-notify [errors]="errorTexts"></errors-notify>
          </form>
        </xp-form-validation>
      </div>
      <div class="member-form-footer modal-footer">
        <div class="modal-title-container">
          <h3 class="modal-title">{{ 'member.generic-object.buttons.edit' | translate }}</h3>
        </div>

        <xp-submit-button
          (click)="updateMember(item)"
          classNames="btn-lg btn-success pull-right"
          [createText]="'member.generic-object.buttons.update' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
          *ngxPermissionsOnly="'updateProfile'"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class MemberEditFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Input() item: Partial<Member> = {};
  @ViewChild('form') form: NgForm;
  formName = 'memberForm';
  successMessageText = 'member.form.success_message';
  isSubmitting$ = this.store.select(selectMembersIsSubmittingFlag);
  errors$ = this.store.select(selectMembersErrors);
  errorTexts = [];
  advanced_roles: ADVANCED_ROLES[] = [];
  userId: number;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationDialog: ConfirmationDialogService,
  ) {
    super();

    this.store.pipe(selectUserId).subscribe((userId: number) => {
      this.userId = userId;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateMember(member: Partial<Member>) {
    this.store.dispatch(updateMember({ member: { ...member, advanced_roles: this.advanced_roles } }));
    this.store.dispatch(closeEditRolesMembersModal());

    this.router.navigate(['./'], { relativeTo: this.route });
  }

  onRolesChange(roles: ADVANCED_ROLES[]) {
    this.advanced_roles = roles;
  }

  onRemove(item: Member): void {
    if (item.id === this.userId) {
      const dialogRef = this.confirmationDialog.openDialog({
        title: this.translate.instant('member.generic-list.item.modals.leave.confirm.title'),
        hint: this.translate.instant('member.generic-list.item.modals.leave.confirm.hint'),
        yes: this.translate.instant('member.generic-list.item.modals.leave.confirm.yes'),
        no: this.translate.instant('member.generic-list.item.modals.leave.confirm.no'),
        item,
        itemType: ItemType.member,
      });
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.store.dispatch(removeMember({ memberId: item.id, isLogout: true }));
        }
      });
    } else {
      const dialogRef = this.confirmationDialog.openDialog({
        title: this.translate.instant('member.generic-list.item.modals.delete.confirm.title'),
        hint: this.translate.instant('member.generic-list.item.modals.delete.confirm.hint'),
        yes: this.translate.instant('member.generic-list.item.modals.delete.confirm.yes'),
        no: this.translate.instant('member.generic-list.item.modals.delete.confirm.no'),
        item,
        itemType: ItemType.member,
      });

      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.store.dispatch(removeMember({ memberId: item.id, isLogout: false }));
          this.store.dispatch(closeEditRolesMembersModal());

          this.router.navigate(['./'], { relativeTo: this.route });
        }
      });
    }
  }
}
