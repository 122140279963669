import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import {
  AllComponentData,
  BingAdsSourceComponentData,
  Connection,
  ConnectionSchemaField,
  Schema,
} from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchemaResponse } from '../../../connections/store/connections.actions';
import { SelectOption } from '../../../common/components/forms/xp-select.component';
import { AuthService } from '../../../common/services/auth.service';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { environment } from '../../../../environments/environment';

const REPORT_DATE_RANGE_TYPES = [
  {
    value: 'TODAY',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.today',
    translate: true,
    unsuportedAggregationTypes: 'MONTHLY WEEKLY YEARLY',
  },
  {
    value: 'YESTERDAY',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.yesterday',
    translate: true,
    unsuportedAggregationTypes: 'MONTHLY WEEKLY YEARLY',
  },
  {
    value: 'LAST_SEVEN_DAYS',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_seven_days',
    translate: true,
    unsuportedAggregationTypes: 'HOURLY MONTHLY WEEKLY YEARLY',
  },
  {
    value: 'THIS_WEEK',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.this_week',
    translate: true,
    unsuportedAggregationTypes: 'SUMMARY HOURLY DAILY MONTHLY YEARLY HOUR_OF_DAY DAY_OF_WEEK',
  },
  {
    value: 'LAST_WEEK',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_week',
    translate: true,
    unsuportedAggregationTypes: 'SUMMARY HOURLY DAILY MONTHLY YEARLY HOUR_OF_DAY DAY_OF_WEEK',
  },
  {
    value: 'LAST_FOUR_WEEKS',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_four_weeks',
    translate: true,
    unsuportedAggregationTypes: 'SUMMARY HOURLY DAILY MONTHLY YEARLY HOUR_OF_DAY DAY_OF_WEEK',
  },
  {
    value: 'THIS_MONTH',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.this_month',
    translate: true,
    unsuportedAggregationTypes: 'HOURLY YEARLY',
  },
  {
    value: 'LAST_MONTH',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_month',
    translate: true,
    unsuportedAggregationTypes: 'HOURLY YEARLY',
  },
  {
    value: 'LAST_THREE_MONTHS',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_three_months',
    translate: true,
    unsuportedAggregationTypes: 'DAILY HOURLY WEEKLY YEARLY SUMMARY',
  },
  {
    value: 'LAST_SIX_MONTHS',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_six_months',
    translate: true,
    unsuportedAggregationTypes: 'DAILY HOURLY WEEKLY YEARLY SUMMARY',
  },
  {
    value: 'THIS_YEAR',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.this_year',
    translate: true,
    unsuportedAggregationTypes: 'SUMMARY HOURLY DAILY HOUR_OF_DAY DAY_OF_WEEK',
  },
  {
    value: 'LAST_YEAR',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.last_year',
    translate: true,
    unsuportedAggregationTypes: 'SUMMARY HOURLY DAILY HOUR_OF_DAY DAY_OF_WEEK',
  },
  {
    value: 'CUSTOM_DATE',
    text: 'bing-ads-source-editor.form.selects.report_aggregation_type.options.custom_date',
    translate: true,
    unsuportedAggregationTypes: 'DAY_OF_WEEK HOUR_OF_DAY',
  },
];

@Component({
  selector: 'bing-ads-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="bing-ads-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::BingAdsSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="api_version">{{
                        'bing-ads-source-editor.form.labels.api_version' | translate
                      }}</label>
                      <xp-select
                        class="form-control xp-select"
                        name="api_version"
                        id="api_version"
                        [value]="rawComponent.api_version"
                        [options]="apiVersionOptions"
                        (valueChange)="onValueChange($event, 'api_version')"
                        emptyPlaceholder="No api version found"
                      >
                      </xp-select>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="report_type">{{
                        'bing-ads-source-editor.form.labels.report_type' | translate
                      }}</label>
                      <xp-select
                        [value]="rawComponent.report_type"
                        name="report_type"
                        id="report_type"
                        [options]="reportTypes"
                        (valueChange)="onReportTypeChange($event)"
                        class="form-control xp-select"
                        emptyPlaceholder="No report type found"
                      ></xp-select>
                      <div class="alert alert-warning" *ngIf="fieldsLoadError">
                        Unable to load columns. Click on "reload" to try again.
                      </div>
                    </xp-form-group>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group refresh-button">
                      <button class="btn btn-default" (click)="refreshReportType(rawComponent.report_type)">
                        <i class="fa fa-refresh" [ngClass]="{ 'fa-spin fa-fw': loadingReportTypeFields }"></i> Refresh
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="report_aggregation_type">{{
                        'bing-ads-source-editor.form.labels.unit_of_time' | translate
                      }}</label>
                      <xp-select
                        class="form-control xp-select"
                        name="report_aggregation_type"
                        id="report_aggregation_type"
                        [value]="rawComponent.report_aggregation_type"
                        [options]="reportAggregationTypes"
                        (valueChange)="onReportAggregateChange($event)"
                        [preventEmpty]="true"
                      >
                      </xp-select>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="report_date_range_type">{{
                        'bing-ads-source-editor.form.labels.date_range' | translate
                      }}</label>
                      <xp-select
                        class="form-control xp-select"
                        name="report_date_range_type"
                        id="report_date_range_type"
                        [value]="rawComponent.report_date_range_type"
                        [options]="reportDateRangeTypes"
                        (valueChange)="onReportDateRangeChange($event)"
                        [preventEmpty]="true"
                      >
                      </xp-select>
                    </xp-form-group>
                    <div *ngIf="rawComponent.report_date_range_type === 'CUSTOM_DATE'">
                      <date-range
                        [startDate]="rawComponent.report_date_range_min"
                        [endDate]="rawComponent.report_date_range_max"
                        (startDateChange)="onValueChange($event, 'report_date_range_min')"
                        (endDateChange)="onValueChange($event, 'report_date_range_max')"
                        [form]="form"
                      ></date-range>
                    </div>
                    <div>
                      <!--all accounts feature not implemented yet-->
                      <label for="report_on">{{ 'bing-ads-source-editor.form.labels.report_on' | translate }}</label>
                      <div class="form-group-options">
                        <div class="radio">
                          <input
                            type="radio"
                            (ngModelChange)="onValueChange($event, 'report_on')"
                            [ngModel]="rawComponent.report_on"
                            name="report_on"
                            value="all"
                            id="report-on-all"
                          />
                          <label for="report-on-all">{{
                            'bing-ads-source-editor.form.labels.all_accounts_and_campains' | translate
                          }}</label>
                        </div>
                        <div class="radio">
                          <input
                            type="radio"
                            (ngModelChange)="onValueChange($event, 'report_on')"
                            [ngModel]="rawComponent.report_on"
                            name="report_on"
                            value="specific"
                            id="report-on-specific"
                          />
                          <label for="report-on-specific">{{
                            'bing-ads-source-editor.form.labels.specific_accounts_and_campains' | translate
                          }}</label>
                        </div>
                        <div class="radio">
                          <input
                            type="radio"
                            (ngModelChange)="onValueChange($event, 'report_on')"
                            [ngModel]="rawComponent.report_on"
                            name="report_on"
                            value="custom"
                            id="report-on-custom"
                          />
                          <label for="report-on-custom">{{
                            'bing-ads-source-editor.form.labels.custom' | translate
                          }}</label>
                        </div>
                      </div>
                      <xp-form-group *ngIf="rawComponent.report_on === 'custom'">
                        <label for="accounts_campaigns">{{
                          'bing-ads-source-editor.form.labels.accounts_and_campaigns' | translate
                        }}</label>

                        <xp-chips
                          name="accounts_campaigns"
                          id="accounts_campaigns"
                          [value]="rawComponent.accounts_campaigns"
                          (valueChange)="onValueChange($event, 'accounts_campaigns')"
                          class="form-control xp-select"
                          [placeholder]="'bing-ads-source-editor.form.labels.accounts_and_campaigns' | translate"
                        ></xp-chips>
                        <i
                          class="fa fa-exclamation-circle form-control-info profile-ids-hint"
                          [matTooltip]="'bing-ads-source-editor.form.hints.accounts_and_campaigns' | translate"
                          matTooltipPosition="after"
                          matTooltipClass="after"
                        ></i>
                      </xp-form-group>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="rawComponent.report_on === 'specific'">
                    <accounts-campaigns-selector
                      [component]="component"
                      [rawComponent]="rawComponent"
                    ></accounts-campaigns-selector>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            (updateValidation)="updateSchemaValidation($event)"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class BingAdsSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: BingAdsSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  isSchemaValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  loadingReportTypeFields = false;
  fieldsLoadError = false;
  isBaseComponentStepActivated = false;

  reportTypes = [];

  apiVersionOptions = [
    {
      text: 'v13',
      value: 'v13',
    },
  ];

  reportDateRangeTypes: SelectOption[] = REPORT_DATE_RANGE_TYPES;

  reportAggregationTypes = [
    {
      value: 'SUMMARY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.summary',
      translate: true,
    },
    {
      value: 'HOURLY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.hourly',
      translate: true,
    },
    {
      value: 'DAILY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.daily',
      translate: true,
    },
    {
      value: 'WEEKLY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.weekly',
      translate: true,
    },
    {
      value: 'MONTHLY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.monthly',
      translate: true,
    },
    {
      value: 'YEARLY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.yearly',
      translate: true,
    },
    {
      value: 'HOUR_OF_DAY',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.hour_of_day',
      translate: true,
    },
    {
      value: 'DAY_OF_WEEK',
      text: 'bing-ads-source-editor.form.selects.report_date_range_type.options.day_of_week',
      translate: true,
    },
  ];
  url = `${environment.API_URL}/${this.authGuard.account.account_id}/api/connections/metadata/bingads/columns?report_type=`;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    this.refreshReportType(this.rawComponent.report_type);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };

    if (changes.rawComponent?.currentValue?.api_version !== changes.rawComponent?.previousValue?.api_version) {
      this.getReportTypes();
    }
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  onReportAggregateChange(reportAggregate: string) {
    this.onValueChange(reportAggregate, 'report_aggregation_type');

    let dateRangeNonExistent = false;

    this.reportDateRangeTypes = REPORT_DATE_RANGE_TYPES.map((reportDateRangeType) => {
      if (reportDateRangeType.unsuportedAggregationTypes.indexOf(reportAggregate) === -1) {
        return {
          text: reportDateRangeType.text,
          value: reportDateRangeType.value,
          translate: true,
        };
      }

      if (this.rawComponent.report_date_range_type === reportDateRangeType.value) {
        dateRangeNonExistent = true;
      }
      return null;
    }).filter(Boolean);

    if (dateRangeNonExistent) {
      this.onValueChange(this.reportDateRangeTypes[0].value, 'report_date_range_type');
    }

    this.onFieldsChange([...this.rawComponent.schema.fields]);

    if (reportAggregate !== 'HOURLY') {
      this.onValueChange(null, 'time_period_hour_field_alias');
    }

    if (reportAggregate !== 'DAILY' && reportAggregate !== 'WEEKLY' && reportAggregate !== 'HOURLY') {
      this.onValueChange(null, 'time_period_date_field_alias');
    }
  }

  onReportDateRangeChange(reportDateRange: string) {
    this.onValueChange(reportDateRange, 'report_date_range_type');

    const existingDateField = this.rawComponent.schema.fields.find((item) => item.name === 'time_period_date');
    if (existingDateField) {
      const newFields = this.rawComponent.schema.fields.map((item) =>
        item.name === 'time_period_date' ? { ...item, markToBeRemoved: true } : item,
      );
      this.onFieldsChange(newFields);
      this.onValueChange(null, 'time_period_date_field_alias');
    }
    const existingHourField = this.rawComponent.schema.fields.find((item) => item.name === 'time_period_hour');
    if (existingHourField) {
      const newFields = this.rawComponent.schema.fields.map((item) =>
        item.name === 'time_period_hour' ? { ...item, markToBeRemoved: true } : item,
      );
      this.onFieldsChange(newFields);
      this.onValueChange(null, 'time_period_hour_field_alias');
    }
  }

  onReportTypeChange(reportType) {
    this.onValueChange(reportType, 'report_type');
    this.refreshReportType(reportType);
  }

  getReportTypes() {
    if (this.rawComponent.api_version) {
      this.http
        .get(`${environment.API_URL}/bingads/${this.rawComponent.api_version}.json`, {
          headers: {
            Authorization: `Bearer ${this.authService.getToken()}`,
          },
        })
        .subscribe((res: any[]) => {
          this.reportTypes = res.map(({ name, title }) => ({ text: title, value: name }));
        });
    }
  }

  refreshReportType(report_type: string) {
    if (!report_type) return;

    this.loadingReportTypeFields = true;
    this.fieldsLoadError = false;

    this.http
      .get(`${this.url + report_type}&api_version=${this.rawComponent.api_version}`, {
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      })
      .subscribe({
        next: (response: any) => {
          const fields = [];
          response.fields.forEach((field) => {
            const name = Object.keys(field)[0];
            const data_type = field[name];
            fields.push({
              name,
              alias: name,
              data_type,
              category: 'Report',
            });
          });
          const timePeriodFieldIndex = fields.findIndex((item) => item.name === 'TIME_PERIOD');
          if (timePeriodFieldIndex > -1) {
            this.onValueChange(true, 'hasTimePeriodField');
            fields.splice(timePeriodFieldIndex, 1);
          } else {
            this.onValueChange(false, 'hasTimePeriodField');
          }
          this.store.dispatch(
            getConnectionSchemaResponse({
              connectionSchema: fields,
              connectionSchemaData: { data: [], fields: [] },
            }),
          );
          this.loadingReportTypeFields = false;
        },
        error: () => {
          this.fieldsLoadError = true;
          this.loadingReportTypeFields = false;
        },
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
