import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClustersState } from './clusters.reducer';

export const selectClustersState = createFeatureSelector<ClustersState>('clusters');

export const selectClusters = createSelector(selectClustersState, (state: ClustersState) => state.items);
export const selectClustersErrors = createSelector(selectClustersState, (state: ClustersState) => state.errors);
export const selectClustersLoadingFlag = createSelector(selectClustersState, (state: ClustersState) => state.isLoading);
export const selectClustersErrorFlag = createSelector(
  selectClustersState,
  (state: ClustersState) => state.isResponseError,
);
export const selectClustersIsSubmittingFlag = createSelector(
  selectClustersState,
  (state: ClustersState) => state.isFormSubmitting,
);
export const selectClustersIsModalOpenFlag = createSelector(
  selectClustersState,
  (state: ClustersState) => state.isModalOpen,
);
export const selectIsClusterRemoved = (id: number) =>
  createSelector(selectClustersState, (state: ClustersState) => !state.items.find((item) => item.id === id));

export const selectAllClustersLoadedFlag = createSelector(
  selectClustersState,
  (state: ClustersState) => state.areAllItemsLoaded,
);
export const selectLastlyCreatedCluster = createSelector(
  selectClustersState,
  (state: ClustersState) => state.lastlyCreatedCluster,
);

export const isAnyModalOpen = createSelector(selectClustersState, (state: ClustersState) => state.isModalOpen);
