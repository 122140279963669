/* eslint-disable @typescript-eslint/dot-notation */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../store';
import { UsersResource } from '../../account/resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import { PlansResource } from '../resources/plans.resource';
import { Plan } from '../plan.models';
import { SubscriptionsResource } from '../resources/subscriptions.resource';
import { Subscription } from '../subscription.models';
import { PaymentMethodsResource } from '../resources/paymentMethods.resource';
import { PaymentMethod } from '../paymentMethod.models';
import { selectAccountState } from '../../account/store/account.selectors';
import { CheckoutService } from '../services/checkout.service';
import { Account } from '../../account/account.models';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { User } from '../../account/user.models';
import { DialogType } from '../../common/services/components/dialog-template.component';
import { PermissionsService } from '../../common/services/permissions.service';

@Component({
  selector: 'account-settings-billing',
  template: `
    <div class="settings-header">
      <h2>{{ 'account.settings.plans.title' | translate }}</h2>
    </div>
    <div class="settings-body billing">
      <h2>{{ 'account.settings.plans.billing.title' | translate }}</h2>
      <p class="alert alert-info">
        <i class="fa fa-credit-card-alt"></i>
        <span *ngIf="account.partner"
          >{{ 'account.settings.plans.billing.labels.partner' | translate }}
          <a [href]="account.partner.plans_url">{{ account.partner.name }}</a></span
        >
        <span *ngIf="!paymentMethod && !account.partner"
          >No credit card on file. Click <a (click)="updateCreditCard()">here</a> to add a credit card.</span
        >
        <span *ngIf="paymentMethod && !account.partner" [innerHTML]="sanitizedCardDescription()"></span>
      </p>
      <p>
        Billing or plan queries? We're here to help! Speak with us through our Live Chat
        <a
          [attr.data-track-event]="'click'"
          [attr.data-category]="'Billing'"
          [attr.data-action]="'Live support'"
          [attr.data-label]="'Give us a shout'"
          href="javascript:"
          *ngIf="!user.is_dpwc"
          (click)="showIntercom()"
          >here</a
        >
        or schedule a time for a call <a href="https://calendly.com/integrateio/customer-success-call">here</a>.
      </p>
      <br />
      <div>
        <button
          *ngIf="paymentMethod"
          class="btn btn-md btn-primary update-credit-card-btn"
          (click)="updateCreditCard()"
        >
          {{ 'account.settings.plans.billing.labels.update_credit_card' | translate }}
        </button>
        <button
          *ngIf="(hasPermission('listInvoices') | async) && account.billing_customer_token"
          class="btn btn-md btn-default invoices-btn"
          (click)="openInvoicesModal()"
        >
          {{ 'account.settings.plans.billing.labels.view_invoices' | translate }}
        </button>
      </div>
    </div>
  `,
})
export class AccountSettingsBillingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  freePlan: Plan;
  starterPlan: Plan;
  plans: Plan[];
  subscription: Subscription;
  billable: boolean;
  paymentMethod: PaymentMethod;
  account: Account;
  user: Partial<User> = {};
  error = false;

  constructor(
    private plansResource: PlansResource,
    private usersResource: UsersResource,
    private subscriptionsResource: SubscriptionsResource,
    private paymentMethodsResource: PaymentMethodsResource,
    private translate: TranslateService,
    private notify: NotifyService,
    private store: Store<AppState>,
    private checkout: CheckoutService,
    private confirmationDialog: ConfirmationDialogService,
    private permissionsService: PermissionsService,
  ) {
    plansResource.query().subscribe({
      next: (plans) => {
        this.freePlan = plans.find((plan) => plan.id === 'free');

        // Store the starter plan as default
        this.starterPlan = plans.find((plan) => plan.id === 'starter');

        // Filter out free plan and take first 3
        this.plans = plans.filter((plan) => plan.id !== 'free-trial').splice(0, 3);

        // Recommended plan
        plans.forEach((plan) => {
          // eslint-disable-next-line no-param-reassign
          plan.recommended = plan.id === 'professional';
        });
      },
      error: () => {
        this.notify.error('An error occurred while loading plans.');
      },
    });

    this.usersResource.get().subscribe({
      next: (user) => {
        this.user = user;
      },
      error: () => {
        this.notify.error('An error occurred while loading user data.');
      },
    });
  }

  ngOnInit() {
    this.getSubscription();

    this.store.select(selectAccountState).subscribe((account) => {
      this.account = account.info;
      this.billable = account.info.billable;
    });

    this.getPaymentMethod();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Handle freeze account link
      const freezeAccountElement = document.querySelector('[data-behavior~=freeze-account]');
      if (freezeAccountElement) {
        freezeAccountElement.addEventListener('click', this.planChanged.bind(this));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showIntercom() {
    if (window['Intercom']) {
      window['Intercom']('show');
    }
  }

  ngOnDestroy() {
    const freezeAccountElement = document.querySelector('[data-behavior~=freeze-account]');
    if (freezeAccountElement) {
      freezeAccountElement.removeEventListener('click', this.planChanged.bind(this));
    }
  }

  planChanged() {
    if (this.freePlan) {
      if (this.account.partner) {
        if (this.account.partner.subscription_url) {
          window.location.replace(this.account.partner.subscription_url);
        }
      } else if (this.billable) {
        const dialogRef = this.confirmationDialog.openDialog({
          title: this.translate.instant('plan.checkout_confirmation.labels.title'),
          hint: this.translate.instant('plan.checkout_confirmation.labels.hint', this.freePlan),
          yes: this.translate.instant('plan.checkout_confirmation.labels.yes'),
          no: this.translate.instant('plan.checkout_confirmation.labels.no'),
          type: DialogType.warning,
        });

        dialogRef.afterClosed().subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.updatePaymentMethod(this.freePlan.id);
          }
        });
      } else {
        // is not billable
        this.checkout.open(
          {
            name: this.translate.instant('plan.checkout.labels.title'),
            description: this.translate.instant('plan.checkout.labels.description'),
            amount: 0,
            token(token) {
              this.updatePaymentMethod(this.freePlan.id, token.id);
            },
          },
          this.account.billing_email || this.user.email,
        );
      }
    }
  }

  getSubscription() {
    this.subscriptionsResource.get().subscribe({
      next: (subscription) => {
        this.subscription = subscription;
      },
      error: () => {
        this.notify.error('An error occurred while loading subscriptions.');
      },
    });
  }

  updatePaymentMethod(plan_id: string, token?: string) {
    const data: Partial<PaymentMethod> = {};
    if (plan_id) {
      data.plan_id = plan_id;
    }
    if (token) {
      data.billing_payment_token = token;
    }

    this.paymentMethodsResource.update(data).subscribe({
      next: () => {
        this.billable = true;
        this.getSubscription();
        this.getPaymentMethod();
        this.notify.success(this.translate.instant('plan.checkout_confirmation.success_message'));
      },
      error: (error) => {
        this.notify.error(
          error.data?.message || 'There was a problem with payment method update. Please contact support.',
        );
        this.billable = false;
      },
    });
  }

  getPaymentMethod() {
    this.paymentMethodsResource.get().subscribe({
      next: (paymentMethod) => {
        this.paymentMethod = paymentMethod;
      },
      error: () => {
        this.error = true;
      },
    });
  }

  sanitizedCardDescription() {
    if (this.paymentMethod) {
      return this.translate.instant(
        'account.settings.plans.billing.labels.account_is_billed_to_card',
        this.paymentMethod,
      );
    }
    return '';
  }

  updateCreditCard() {
    this.checkout.open(
      {
        name: this.translate.instant('plan.checkout.labels.title'),
        description: this.translate.instant('plan.checkout.labels.description'),
        amount: 0,
        token: (token) => {
          this.updatePaymentMethod(null, token.id);
        },
      },
      this.account.billing_email || this.user.email,
    );
  }

  openInvoicesModal() {
    this.checkout.openInvoicesModal({}, this.account.billing_customer_token);
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }
}
