import { uniqBy } from 'lodash';
import { createReducer, on } from '@ngrx/store';
import {
  clearAccountsList,
  loginSuccess,
  logout,
  removeAccountFromList,
  updateAccountInfo,
  updateAccountsList,
  updateUserInfo,
} from './account.actions';
import { Account } from '../account.models';
import { User } from '../user.models';

export const initialState = {
  info: {},
  user: {},
  isLogged: false,
  isLoggedOut: false,
  accounts: [],
};

export interface AccountState {
  info: Account;
  user: Partial<User>;
  isLogged: boolean;
  isLoggedOut: boolean;
  accounts: Account[];
}

export const accountReducer = createReducer<AccountState>(
  initialState,
  on(loginSuccess, (state) => ({ ...state, isLogged: true, isLoggedOut: false })),
  on(logout, (state) => ({ ...state, isLoggedOut: true, isLogged: false })),
  on(updateAccountInfo, (state, { account }) => ({ ...state, info: { ...state.info, ...account } })),
  on(updateUserInfo, (state, { user }) => ({ ...state, user: { ...state.user, ...user } })),
  on(updateAccountsList, (state, { accounts }) => ({
    ...state,
    accounts: uniqBy([...state.accounts, ...accounts], (acc) => acc.id),
  })),
  on(clearAccountsList, (state) => ({ ...state, accounts: [] })),
  on(removeAccountFromList, (state, { account }) => ({
    ...state,
    accounts: state.accounts.filter((item) => item.account_id !== account.account_id),
  })),
);
