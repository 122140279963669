import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';

type Options = {
  element: any;
  keys: string;
  shouldNotPreventDefault?: boolean;
  shouldPreventInInput?: boolean;
};

@Injectable({ providedIn: 'root' })
export class ShortcutsService {
  defaults: Partial<Options> = {
    element: this.document,
  };

  constructor(private eventManager: EventManager, @Inject(DOCUMENT) private document: Document) {}

  addShortcut(options: Partial<Options>): Observable<KeyboardEvent> {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    return new Observable((observer) => {
      const handler = (e) => {
        if (
          ((e.target as HTMLElement).tagName === 'INPUT' || (e.target as HTMLElement).tagName === 'TEXTAREA') &&
          options.shouldPreventInInput
        ) {
          return;
        }

        if (!options.shouldNotPreventDefault) {
          e.preventDefault();
        }
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      let disposeWindows;
      if (event.includes('meta')) {
        disposeWindows = this.eventManager.addEventListener(merged.element, event.replace('meta', 'control'), handler);
      }

      return () => {
        dispose();

        if (disposeWindows) {
          disposeWindows();
        }
      };
    });
  }
}
