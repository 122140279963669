import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { map } from 'lodash';
import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { FunctionPickerType } from '../../../../constants/function_picker';
import { Schema } from '../../../package.models';

function escape(value: string): string {
  // replace all non-alphanumeric characters with underscore
  let alias = value.replace(/[^a-zA-Z\d]/g, '_');
  // replace consecutive instances of underscores with a single underscore
  alias = alias.replace(/_{2,}/g, '_');
  // take the first 50 characters and trim them from trailing underscores
  alias = alias.replace(/^_+|_+$/g, '');
  return alias.toLowerCase();
}

function autoGenerateAlias(record: DesignerSchemaFieldI): string {
  let alias = '';
  if (record) {
    if (record.function_name === 'row_number') alias = 'row_number';
    else if (record.function_name === 'ntile' && record.ntiles && record.ntiles !== '')
      alias = `ntile_${escape(record.ntiles)}`;
    else if (record.field_name) {
      if (record.function_name === 'count') alias = `total_${record.field_name}`;
      else if (record.function_name === 'sum') alias = `sum_${record.field_name}`;
      else if (record.function_name === 'avg') alias = `avg_${record.field_name}`;
      else if (record.function_name === 'min') alias = `min_${record.field_name}`;
      else if (record.function_name === 'max') alias = `max_${record.field_name}`;
      else if (record.function_name === 'first_value') alias = `first_${record.field_name}`;
      else if (record.function_name === 'last_value') alias = `last_${record.field_name}`;
      else if (record.function_name === 'lead' && record.offset)
        alias = `lead_${escape(record.offset)}_${record.field_name}`;
      else if (record.function_name === 'lag' && record.offset)
        alias = `lag_${escape(record.offset)}_${record.field_name}`;
    }
  }
  return alias;
}

@Component({
  selector: 'window-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['function_name', 'field_name', 'window_arguments', 'alias']"
      duplicationValidationProp="alias"
      duplicationValidationPropName="Alias"
    >
      <ng-template templateName="function_name" let-item>
        <xp-function-picker
          [value]="item.record.function_name"
          [index]="item.index"
          [componentType]="functionPickerType.window"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'function_name')"
        ></xp-function-picker>
      </ng-template>
      <ng-template templateName="function_name-header" let-item>
        {{ 'fields-collection.headers.function-picker' | translate }}
      </ng-template>

      <ng-template templateName="field_name" let-item>
        <xp-field-picker
          [value]="item.record.field_name"
          [index]="item.index"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [disable]="item.record.function_name === 'row_number' || item.record.function_name === 'ntile'"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'field_name')"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="field_name-header" let-item>
        {{ 'fields-collection.headers.field-argument' | translate }}
      </ng-template>

      <ng-template templateName="window_arguments" let-item>
        <xp-window-arguments-editor
          [offsetValue]="item.record.offset"
          [defaultValue]="item.record.default_value"
          [rangeBeforeValue]="item.record.range_before"
          [rangeAfterValue]="item.record.range_after"
          [ntilesValue]="item.record.ntiles"
          [index]="item.index"
          [function]="item.record.function_name"
          [focusedProp]="item.focusedProp"
          class="fields-collection-editor"
          (offsetValueChange)="onFieldChange($event, item.record, 'offset')"
          (defaultValueChange)="onFieldChange($event, item.record, 'default_value')"
          (rangeBeforeValueChange)="onFieldChange($event, item.record, 'range_before')"
          (rangeAfterValueChange)="onFieldChange($event, item.record, 'range_after')"
          (ntilesValueChange)="onFieldChange($event, item.record, 'ntiles')"
        ></xp-window-arguments-editor>
      </ng-template>
      <ng-template templateName="window_arguments-header" let-item>
        {{ 'fields-collection.headers.window-arguments-editor' | translate }}
      </ng-template>

      <ng-template templateName="alias" let-item>
        <xp-alias-editor
          [value]="item.record.alias"
          [index]="item.index"
          propName="alias"
          [focusedProp]="item.focusedProp"
          [isDuplicateError]="item.record.isDuplicateError"
          [isOutsideDuplicateError]="item.record.isOutsideDuplicateError"
          [outsideValidationMessage]="((collectionSettings.outsideSourceValidation || {}).unique || {}).message"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'alias')"
        ></xp-alias-editor>
      </ng-template>
      <ng-template templateName="alias-header" let-item>
        <xp-alias-editor-title [title]="'fields-collection.headers.alias-editor' | translate"></xp-alias-editor-title>
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class WindowCollectionComponent implements OnInit {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() fieldsName: string;
  @Input() collectionSettings: CollectionSettings;
  @Input() parentSchemas: Schema[];
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();
  functionPickerType = FunctionPickerType;

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 20,
      emptyRecord: {
        alias: '',
        default_value: '',
        function_name: '',
        field_name: '',
        ntiles: '',
        offset: '',
        range_after: '',
        range_before: '',
        FC_pristine: true,
      },
      parentSchemas: this.parentSchemas,
      autoGenerateFn: this.autoGenerateAliasAndUpdateRecords.bind(this),
      addRecord: this.addRecord.bind(this),
      outsideSourceValidation: {
        unique: {
          validation: this.parentSchemas && this.parentSchemas[0] ? map(this.parentSchemas[0].fields, 'name') : [],
          message: this.translate.instant('window-editor.form.errors.duplicate_fields'),
        },
      },
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  addRecord(index: number) {
    const newRecord = { ...this.collectionSettings.emptyRecord, id: uuidv4() };
    if (this.recordsCopy[index - 1]) {
      newRecord.function_name = this.recordsCopy[index - 1].function_name;
    }
    if (newRecord.function_name === 'row_number' || newRecord.function_name === 'ntile') {
      newRecord.field_name = null;
    }
    this.recordsCopy.splice(index, 0, newRecord);
    this.recordsCopy = this.recordsCopy.slice();
  }

  autoGenerateAliasAndUpdateRecords(record: DesignerSchemaFieldI) {
    const alias = autoGenerateAlias(record);

    if (alias) {
      this.recordsCopy = this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            projected_name: alias,
            alias,
          };
        }
        return item;
      });
    }

    this.onRecordChange({ records: this.recordsCopy });
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return {
        alias: record.alias,
        default_value: record.default_value,
        function_name: record.function_name,
        field_name: record.field_name,
        ntiles: record.ntiles,
        offset: record.offset,
        range_after: record.range_after,
        range_before: record.range_before,
      };
    });
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fieldsName = this.fieldsName || 'fields';
    const fields = records.map(
      ({ alias, default_value, function_name, field_name, ntiles, offset, range_after, range_before }) => ({
        alias,
        default_value,
        function_name,
        field_name,
        ntiles,
        offset,
        range_after,
        range_before,
      }),
    );
    this.store.dispatch(updateComponent({ component: { [fieldsName]: fields } }));
    this.recordsChange.emit(fields);
  }

  onFieldChange(
    value: string | { value: string; prop: string },
    record: DesignerSchemaFieldI,
    prop?: keyof DesignerSchemaFieldI,
  ) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        if (typeof value !== 'object') {
          return {
            ...item,
            [prop]: value,
          };
        }
        return {
          ...item,
          [value.prop]: value.value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
