import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { AllComponentData, Component as PackageComponent, CrossJoinComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { switchParentSchemasOrder } from '../../store/package-designer.actions';
import { setComponentValidity } from '../../store/component.actions';

@Component({
  selector: 'cross-join-editor',
  template: `
    <div class="cross-join-editor">
      <form name="componentForm" novalidate #form="ngForm">
        <div class="row cross-join-editor-texts">
          <div class="col-md-6">
            <p>{{ 'cross-join-editor.title1' | translate }}</p>
            <p>{{ 'cross-join-editor.title2' | translate }}</p>
          </div>
        </div>
        <components-order-editor
          [components]="parentComponents"
          [isSwitchDisabled]="true"
          (switchComponents)="onSwitchComponents($event)"
        ></components-order-editor>
      </form>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class CrossJoinEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: CrossJoinComponentData;
  @Input() parentSchemas: Schema[];
  @Input() parentComponents: PackageComponent[];
  @Output() componentsOrderChange = new EventEmitter<PackageComponent[]>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  errorMessage = '';

  isFormValid = false;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.dispatch(setComponentValidity({ isComponentFormValid: true }));
  }

  onSwitchComponents(components: PackageComponent[]) {
    this.store.dispatch(switchParentSchemasOrder());
    this.componentsOrderChange.emit(components);
  }
}
