import moment from 'moment';
import { trimTime } from './assisted-search.helpers';

export const AssistedSearchDateSource = {
  MONTHS: moment.months().map(function (m) {
    return m.toLowerCase();
  }),
  ALLOWED_VALUES: ['yesterday', 'today'].concat(
    moment.months().map(function (m) {
      return m.toLowerCase();
    }),
  ),
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_REGEX: /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/,
  DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss\\Z',
  DATETIME_REGEX: /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}Z$/,

  defaultHint: 'a date',

  serialize(date) {
    if (!date) {
      return null;
    }
    return trimTime(date.format(this.DATETIME_FORMAT));
  },

  deserialize(val) {
    if (moment(val, this.DATETIME_FORMAT).isValid()) {
      return moment(val, this.DATETIME_FORMAT);
    }
    return null;
  },

  validate(string) {
    return string && (string.match(this.DATE_REGEX) || string.match(this.DATETIME_REGEX));
  },

  getHints(string) {
    // we need to have hint to autocomplete the token
    const m = moment(string, this.DATETIME_FORMAT); // try to create moment from the input
    return [trimTime(m.format(this.DATETIME_FORMAT))];
  },
};
