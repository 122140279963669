import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { FunctionPickerType, FUNCTION_PICKER } from '../../../../constants/function_picker';
import { Schema } from '../../../package.models';

function autoGenerateAlias(function_name: string, projected_field_name: string, field_name: string): string {
  let alias = '';

  if (function_name) {
    if (function_name === 'max_by' && projected_field_name) alias = `last_${projected_field_name}`;
    else if (function_name === 'min_by' && projected_field_name) alias = `first_${projected_field_name}`;
    else if (function_name === 'count_star') alias = 'total';
    else if (function_name === 'count' && field_name) alias = `total_${field_name}`;
    else if (function_name === 'count_distinct' && field_name) alias = `total_unique_${field_name}`;
    else if (field_name) alias = `${function_name}_${field_name}`;
  }
  return alias;
}

@Component({
  selector: 'aggregate-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['function_name', 'field_name', 'projected_field_name', 'alias']"
      duplicationValidationProp="alias"
      duplicationValidationPropName="Alias"
    >
      <ng-template templateName="function_name" let-item>
        <xp-function-picker
          [value]="item.record.function_name"
          [index]="item.index"
          [componentType]="functionType"
          (fieldChange)="onFieldChange($event, item.record, 'function_name')"
          class="fields-collection-editor"
        ></xp-function-picker>
      </ng-template>
      <ng-template templateName="function_name-header" let-item>
        {{ 'fields-collection.headers.function-picker' | translate }}
      </ng-template>

      <ng-template templateName="field_name" let-item>
        <xp-field-picker
          [value]="item.record.field_name"
          [index]="item.index"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          propName="field_name"
          [disable]="item.record.function_name === 'count_star'"
          (fieldChange)="onFieldChange($event, item.record, 'field_name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="field_name-header" let-item>
        {{ 'fields-collection.headers.field-argument' | translate }}
      </ng-template>

      <ng-template templateName="projected_field_name" let-item>
        <xp-field-picker
          [value]="item.record.projected_field_name"
          [index]="item.index"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          propName="projected_field_name"
          [disable]="item.record.function_name !== 'max_by' && item.record.function_name !== 'min_by'"
          (fieldChange)="onFieldChange($event, item.record, 'projected_field_name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="projected_field_name-header" let-item>
        {{ 'fields-collection.headers.projected-field' | translate }}
      </ng-template>

      <ng-template templateName="alias" let-item>
        <xp-alias-editor
          [value]="item.record.alias"
          [index]="item.index"
          propName="alias"
          [focusedProp]="item.focusedProp"
          [isDuplicateError]="item.record.isDuplicateError"
          [isOutsideDuplicateError]="item.record.isOutsideDuplicateError"
          [outsideValidationMessage]="((collectionSettings.outsideSourceValidation || {}).unique || {}).message"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'alias')"
        ></xp-alias-editor>
      </ng-template>
      <ng-template templateName="alias-header" let-item>
        <xp-alias-editor-title [title]="'fields-collection.headers.alias-editor' | translate"></xp-alias-editor-title>
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class AggregateCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: Partial<CollectionSettings>;
  @Input() parentSchemas: Schema[];
  @Input() functionType: FunctionPickerType = FunctionPickerType.aggregate;
  @Input() fieldsName: string;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  recordsCopy: DesignerSchemaFieldI[] = [];

  defaultCollectionSettings: CollectionSettings = {
    itemsPerPage: 10,
    emptyRecord: {
      field_name: '',
      alias: '',
      function_name: FUNCTION_PICKER[FunctionPickerType.aggregate][0].value,
      projected_field_name: '',
      FC_pristine: true,
    },
    autoGenerateFn: this.autoGenerateAliasAndUpdateRecords.bind(this),
    addRecord: this.addRecord.bind(this),
  };

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    this.collectionSettings = {
      ...this.defaultCollectionSettings,
      ...(this.collectionSettings || {}),
      parentSchemas: this.parentSchemas,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.collectionSettings && changes.collectionSettings.currentValue) {
      this.collectionSettings = {
        ...this.defaultCollectionSettings,
        ...(this.collectionSettings || {}),
        parentSchemas: this.parentSchemas,
      };
    }
  }

  addRecord(index: number) {
    const newRecord = { ...this.collectionSettings.emptyRecord, id: uuidv4() };
    if (this.recordsCopy[index]) {
      newRecord.function_name = this.recordsCopy[index].function_name;
    }
    if (newRecord.function_name !== 'max_by' && newRecord.function_name !== 'min_by') {
      newRecord.projected_field_name = null;
      if (newRecord.function_name === 'count_star') newRecord.field_name = null;
    }
    this.recordsCopy.splice(index + 1, 0, newRecord);
    this.recordsCopy = this.recordsCopy.slice();
  }

  autoGenerateAliasAndUpdateRecords(record: DesignerSchemaFieldI) {
    const alias = autoGenerateAlias(record.function_name, record.projected_field_name, record.field_name);

    if (alias) {
      this.recordsCopy = this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            alias,
          };
        }
        return item;
      });
    }

    this.onRecordChange({ records: this.recordsCopy });
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return {
        field_name: record.field_name,
        alias: record.alias,
        function_name: record.function_name,
        projected_field_name: record.projected_field_name,
      };
    });
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fieldsName = this.fieldsName || 'fields';
    const fields = records.map(({ field_name, function_name, alias, projected_field_name }) => ({
      field_name,
      function_name,
      alias,
      projected_field_name,
    }));
    this.store.dispatch(
      updateComponent({
        component: {
          [fieldsName]: fields,
        },
      }),
    );

    this.recordsChange.emit(fields);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
