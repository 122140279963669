import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { GoogleAdsDestinationComponentData } from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { getMessageFromError } from '../../../connections/helpers/http-errors.helpers';

@Component({
  selector: 'google-ads-object-picker',
  template: `
    <div>
      <div class="row">
        <div class="col-sm-6">
          <xp-form-group>
            <label for="object_name">{{ component.componentType + '.form.labels.object_name' | translate }}</label>
            <xp-select
              class="form-control xp-select"
              name="object_name"
              id="object_name"
              [value]="rawComponent.object_name"
              [options]="objectsAndFields"
              [preventEmpty]="true"
              (valueChange)="onObjectNameChange($event)"
            ></xp-select>
          </xp-form-group>
        </div>
        <div class="col-sm-6">
          <div class="form-group refresh-button">
            <button class="btn btn-default" (click)="loadObjects(true)">
              <i class="fa fa-refresh" [ngClass]="{ 'fa-spin fa-fw': loadingObjects }"></i> Refresh
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="error-message higher-message" *ngIf="!loadingObjects && errorMessage">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class GoogleAdsObjectPickerComponent implements OnChanges {
  @Input() rawComponent: GoogleAdsDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Output() fieldsLoadStart = new EventEmitter();
  @Output() fieldsLoadEnd = new EventEmitter();

  objectsAndFields = [];
  loadingObjects = false;
  errorMessage = null;
  lastObjectName = null;
  objectEntities = [];

  constructor(
    private connectionItemsResource: ConnectionItemsResource,
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentValue = changes.rawComponent.currentValue || {};
    const previousValue = changes.rawComponent.previousValue || {};
    if (currentValue.connection !== previousValue.connection) {
      this.loadObjects(false);
    }
  }

  loadObjects(withCacheRefresh) {
    if (this.rawComponent.connection && this.rawComponent.connection.type && this.rawComponent.connection.id) {
      this.loadingObjects = true;

      const schemaRequestData: any = {
        force_fetch: withCacheRefresh,
      };

      this.connectionItemsResource.googleAdsObjects().subscribe({
        next: (objectEntities) => {
          if (this.rawComponent.object_name) {
            this.lastObjectName = this.rawComponent.object_name;
          }

          this.objectEntities = objectEntities;
          this.objectsAndFields = objectEntities.map((entity) => ({ text: entity.name, value: entity.name }));
          this.loadingObjects = false;
          if (this.lastObjectName !== this.rawComponent.object_name) {
            this.errorMessage = null;
            this.store.dispatch(
              updateRawComponent({
                rawComponent: { object_name: this.lastObjectName },
              }),
            );
            this.store.dispatch(updateComponent({ component: { object_name: this.lastObjectName } }));
          } else if (!this.objectsAndFields.map((item) => item.value).includes(this.lastObjectName)) {
            this.errorMessage = this.translate.instant('google-ads-destination-editor.form.errors.object_name', {
              objectName: this.lastObjectName,
            });
          } else {
            this.errorMessage = null;
          }
          this.loadFields(this.lastObjectName);
        },
        error: (res) => {
          this.loadingObjects = false;
          this.errorMessage = getMessageFromError(res);
        },
      });
    }
  }

  loadFields(objectName: string) {
    if (this.rawComponent.connection && this.rawComponent.connection.type && objectName && objectName !== '') {
      const foundObject = this.objectEntities.find((obj) => obj.name === objectName) || {};

      this.fieldsLoadEnd.emit(foundObject.fields || []);
    }
  }

  onObjectNameChange(objectName: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { object_name: objectName },
      }),
    );
    this.store.dispatch(updateComponent({ component: { object_name: objectName } }));

    this.errorMessage = null;
    this.fieldsLoadStart.emit();
    this.loadFields(objectName);
  }
}
