import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { Workspace } from '../workspaces.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { CustomHeaders, ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  get(workspace_id: number, params?: ListParams, headers?: CustomHeaders): Observable<Workspace> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((store_account_id) =>
        this.http.get<Workspace>(`${this.apiRoot}/${store_account_id}/api/workspaces/${workspace_id}`, {
          params: params as QueryParams,
          headers: headers as HttpHeaders,
        }),
      ),
    );
  }

  save(body: Partial<Workspace>, params?: ListParams): Observable<Workspace> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Workspace>(`${this.apiRoot}/${account_id}/api/workspaces`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  update(workspace_id: number, body: Partial<Workspace>, params?: ListParams): Observable<Workspace> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<Workspace>(`${this.apiRoot}/${account_id}/api/workspaces/${workspace_id}`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  remove(workspace_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<Workspace>(`${this.apiRoot}/${account_id}/api/workspaces/${workspace_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  query(params?: ListParams): Observable<Workspace[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Workspace[]>(`${this.apiRoot}/${account_id}/api/workspaces`, { params: params as QueryParams }),
      ),
    );
  }

  search(params?: ListParams): Observable<Workspace[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Workspace[]>(`${this.apiRoot}/${account_id}/api/workspaces/search`, {
          params: params as QueryParams,
        }),
      ),
    );
  }
}
