import { Injectable, inject } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthenticationGuard {
  constructor(private authService: AuthService) {}

  canActivate(): boolean {
    return this.authService.isAuthenticated();
  }

  canActivateChild(): boolean {
    return this.authService.isAuthenticated();
  }
}

export const canActivateAuthenticationFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(AuthService).isAuthenticated();
};
