/// <reference types="@angular/localize" />

import '../lib/boomerang';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/localize/init';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.SENTRY_ENABLED) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.xplenty\.com/, /^https:\/\/api-staging\.xplenty\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      'Object Not Found Matching Id:4',
      'Failed to fetch',
      '(unknown): Script error.',
      'Script error.',
      'ResizeObserver loop limit exceeded',
      '"statusText":"Unknown Error","url":"https://api.xplenty.com/validations_export","ok":false,',
      'item per minute limit reached, ignoring errors until timeout',
      'Uncaught TypeError: Illegal invocation',
    ],
    environment: environment.SENTRY_ENV,
  });
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
