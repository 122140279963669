import { createReducer, on } from '@ngrx/store';
import { Schedule } from '../schedule.models';
import {
  closePackagePickerModal,
  closeSchedulesModal,
  getSchedulesList,
  getSchedulesListError,
  getSchedulesListResponse,
  loadMoreSchedulesList,
  loadMoreSchedulesListResponse,
  openPackagePickerModal,
  openSchedulesModal,
  removeScheduleItem,
  removeScheduleResponse,
  saveSchedule,
  saveScheduleError,
  saveScheduleResponse,
  searchSchedulesList,
  searchSchedulesListResponse,
  updateSchedule,
  updateScheduleError,
  updateScheduleResponse,
} from './schedules.actions';
import { ValidationError } from '../../config/validation-error.model';
import { closeAllModals } from '../../account/store/account.actions';
import { QueryParamsSchedulesList } from '../../common/helper/query-params-generic-list.helper';

export const initialState: SchedulesState = {
  items: [],
  item: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  isPackagePickerModalOpen: false,
  errors: [],
  isEditingPackage: false,
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface SchedulesState {
  items: Schedule[];
  item: Schedule;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  isPackagePickerModalOpen: boolean;
  errors: ValidationError[];
  isEditingPackage: boolean;
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const schedulesReducer = createReducer<SchedulesState>(
  initialState,

  on(openSchedulesModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeSchedulesModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(openPackagePickerModal, (state, { isEdit }) => ({
    ...state,
    isPackagePickerModalOpen: true,
    isEditingPackage: isEdit,
  })),
  on(closePackagePickerModal, (state) => ({ ...state, isPackagePickerModalOpen: false, isEditingPackage: false })),

  on(getSchedulesList, (state) => ({ ...state, isLoading: true, items: [], isResponseError: false })),
  on(getSchedulesListResponse, (state, { schedules }) => ({
    ...state,
    isLoading: false,
    items: schedules,
    areAllItemsLoaded: schedules.length < QueryParamsSchedulesList.limit,
  })),
  on(getSchedulesListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(searchSchedulesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(searchSchedulesListResponse, (state, { schedules }) => ({
    ...state,
    isLoading: false,
    items: schedules,
    areAllItemsLoaded: schedules.length < QueryParamsSchedulesList.limit,
  })),

  on(loadMoreSchedulesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreSchedulesListResponse, (state, { schedules }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...schedules],
    areAllItemsLoaded: schedules.length < QueryParamsSchedulesList.limit,
  })),

  on(saveSchedule, (state) => ({ ...state, isFormSubmitting: true, errors: [] })),
  on(saveScheduleResponse, (state, { data, closeModal }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: closeModal ? false : state.isModalOpen,
  })),
  on(saveScheduleError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(updateSchedule, (state) => ({ ...state, isFormSubmitting: true, errors: [] })),
  on(updateScheduleResponse, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id === data.id ? { ...data, task: data.task || item.task } : item)),
    isFormSubmitting: false,
  })),
  on(updateScheduleError, (state, { errors }) => ({
    ...state,
    isFormSubmitting: false,
    errors,
  })),

  on(removeScheduleItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeScheduleResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
