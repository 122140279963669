import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'xpIndex' })
export class XpIndexPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: number): string {
    let index = `${value + 1}`;
    if (index.length === 1) index = `0${index}`;
    return index;
  }
}
