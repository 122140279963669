import { Component, Input } from '@angular/core';

@Component({
  selector: 'xp-icon',
  template: `
    <div class="base-icon">
      <i class="fa fa-{{ type }}"></i>
    </div>
  `,
})
export class XpIconComponent {
  @Input() type: string;
}
