import { isDefined } from '../../common/validators/helpers/validators.helpers';
import { PopupOptions } from './connections-authentication.config';

function normalize(str: string): string {
  return str.replace(/[/]+/g, '/').replace(/\/\?/g, '?').replace(/\/#/g, '#').replace(/:\//g, '://');
}

export function joinUrl(baseUrl: string, url: string): string {
  if (/^(?:[a-z]+:)?\/\//i.test(url)) {
    return url;
  }

  const joined = [baseUrl, url].join('/');

  return normalize(joined);
}

export function camelCase(name: string): string {
  return name.replace(/([:\-_]+(.))/g, function (_, separator, letter, offset) {
    return offset ? letter.toUpperCase() : letter;
  });
}

export function prepareOptions(options: Partial<PopupOptions> = {}) {
  const width = options.width || 500;
  const height = options.height || 500;

  return {
    width,
    height,
    left: window.screenX + (window.outerWidth - width) / 2,
    top: window.screenY + (window.outerHeight - height) / 2.5,
    ...options,
  };
}

export function stringifyOptions(options: any): string {
  const parts = [];
  Object.keys(options).forEach(function (key) {
    parts.push(`${key}=${options[key]}`);
  });
  return parts.join(',');
}

export function getFullUrlPath(location: Location): string {
  const isHttps = location.protocol === 'https:';
  return `${location.protocol}//${location.hostname}:${location.port || (isHttps ? '443' : '80')}${
    /^\//.test(location.pathname) ? location.pathname : `/${location.pathname}`
  }`;
}

export function parseQueryString(keyValue: string): { [key: string]: string } {
  const obj = {};
  let key;
  let value;
  (keyValue || '').split('&').forEach(function (item) {
    if (item) {
      value = item.split('=');
      key = decodeURIComponent(value[0]);
      obj[key] = isDefined(value[1]) ? decodeURIComponent(value[1]) : true;
    }
  });
  return obj;
}
