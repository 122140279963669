function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function transformErrorFieldName(fieldName: string, addArticle?: boolean): string {
  let res = fieldName
    ? fieldName
        .toLowerCase()
        .replace(/\bid\b/, 'ID')
        .replace('_', ' ')
    : '';
  res = capitalizeFirstLetter(res);

  if (addArticle) {
    return (res.match(/^[aeiouAEIOU]/) ? 'an ' : 'a ') + res;
  }

  return res;
}
