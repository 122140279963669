import { Component, Input } from '@angular/core';
import { COMPONENTS } from '../../constants/components';
type CommonIconType = 'S' | 'M' | 'L';

@Component({
  selector: 'common-icon',
  template: `
    <svg class="common-icon" [ngClass]="iconClass">
      <rect [attr.width]="iconSize" [attr.height]="iconSize" rx="10" class="modal-icon-use-background"></rect>
      <use class="modal-icon-use" [ngClass]="iconClass + ' ' + iconId" [attr.href]="'#' + iconId"></use>
    </svg>
  `,
})
export class CommonIconComponent {
  @Input() size: CommonIconType;
  @Input() iconId: string;

  get iconSize(): number {
    switch (this.size) {
      case 'S':
        return 24;
      case 'M':
        return 30;
      case 'L':
        return 40;
      default:
        return 30;
    }
  }

  get iconClass(): string {
    switch (this.size) {
      case 'S':
        return 'small';
      case 'M':
        return '';
      case 'L':
        return 'big';
      default:
        return '';
    }
  }
}
