interface ShortcutItem {
  command: string[];
  description: string;
  category: string;
}

export const SHORTCUTS_LIST: ShortcutItem[] = [
  {
    command: ['g+i'],
    description: 'shortcuts.list.go_to_connections',
    category: 'shortcuts.list.category.navigation',
  },
  {
    command: ['g+p'],
    description: 'shortcuts.list.go_to_packages',
    category: 'shortcuts.list.category.navigation',
  },
  {
    command: ['g+j'],
    description: 'shortcuts.list.go_to_jobs',
    category: 'shortcuts.list.category.navigation',
  },
  {
    command: ['g+c'],
    description: 'shortcuts.list.go_to_clusters',
    category: 'shortcuts.list.category.navigation',
  },
  {
    command: ['g+s'],
    description: 'shortcuts.list.go_to_schedules',
    category: 'shortcuts.list.category.navigation',
  },
  {
    command: ['mod+s'],
    description: 'shortcuts.list.designer.save',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['mod+a'],
    description: 'shortcuts.list.designer.select_all',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['esc', 'mod+shift+a'],
    description: 'shortcuts.list.designer.deselect_components',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['mod+f'],
    description: 'shortcuts.list.designer.vertical_align',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['mod+z'],
    description: 'shortcuts.list.designer.undo',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['ctrl+y', 'mod+shift+z'],
    description: 'shortcuts.list.designer.redo',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['mod+x'],
    description: 'shortcuts.list.designer.cut',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['mod+c'],
    description: 'shortcuts.list.designer.copy',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['mod+v'],
    description: 'shortcuts.list.designer.paste',
    category: 'shortcuts.list.category.designer',
  },
  {
    command: ['?', 'mod+/'],
    description: 'shortcuts.list.shortcuts',
    category: 'shortcuts.list.category.application',
  },
];
