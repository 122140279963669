import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { escape } from 'lodash';
import { Subject } from 'rxjs';
import moment from 'moment';
import { AssistedSearchToken } from '../../../helper/assisted-search-token.helper';
import { assistedSearchUtils } from '../../../helper/assisted-search-utils.helper';
import { SearchConfig } from '../../../services/assisted-search/search-config.model';
import { ActivatedRoute, Router } from '@angular/router';

export interface Hint {
  section: string;
}

export interface Token {
  type: 'thumbs-list' | 'date' | 'space';
  length: number;
  hint: string;
  modifier: string;
  value: string;
  autoComplete: () => boolean;
  hints?: Hint[];
  model: moment.Moment;
  isValueValid: boolean;
}

export interface SearchValue {
  query: string;
  text: string;
}

@Component({
  selector: 'assisted-search',
  template: `
    <div class="assisted-search" [ngClass]="{ 'search-focused': isFocused }">
      <svg>
        <use *ngIf="!proxyValue" href="#icon-search"></use>
        <use *ngIf="proxyValue" (click)="clear()" class="clear-query-button" href="#icon-search-delete"></use>
      </svg>

      <input
        autocomplete="off"
        spellcheck="false"
        class="main-input"
        #mainInput
        [placeholder]="placeholder"
        [(ngModel)]="proxyValue"
        (ngModelChange)="valueChanged($event)"
        [maxlength]="maxlength"
        (click)="onClick($event)"
        (focus)="onFocus()"
        (keydown)="onKeyDown($event)"
        (paste)="onPaste($event)"
        (cut)="onCut($event)"
        (blur)="onBlur()"
      />

      <div class="background-container" #backgroundContainer [innerHTML]="backgroundContent"></div>

      <div class="btn btn-lg btn-default submit" (click)="search()">
        {{ 'generic-list.labels.search_btn_text' | translate }}
      </div>

      <assisted-search-popup-list
        *ngIf="
          !isPopupHidden &&
          activeToken &&
          activeToken.type &&
          activeToken.type !== 'space' &&
          activeToken.type !== 'date' &&
          activeToken.type !== 'thumbs-list'
        "
        class="hint-menu-container assisted-search-popup-list"
        [token]="activeToken"
        [cursor]="activeTokenCursor"
        [keyDownEvents]="keyDownEventSubject.asObservable()"
        [keyUpEvents]="keyUpEventSubject.asObservable()"
        [enterEvents]="enterEventSubject.asObservable()"
      ></assisted-search-popup-list>

      <assisted-search-popup-thumbs-list
        *ngIf="!isPopupHidden && activeToken && activeToken.type && activeToken.type === 'thumbs-list'"
        class="hint-menu-container assisted-search-popup-thumbs-list"
        [token]="activeToken"
        [cursor]="activeTokenCursor"
        [keyDownEvents]="keyDownEventSubject.asObservable()"
        [keyUpEvents]="keyUpEventSubject.asObservable()"
        [enterEvents]="enterEventSubject.asObservable()"
      ></assisted-search-popup-thumbs-list>

      <assisted-search-popup-date
        *ngIf="!isPopupHidden && activeToken && activeToken.type && activeToken.type === 'date'"
        class="hint-menu-container assisted-search-popup-date"
        [token]="activeToken"
        [cursor]="activeTokenCursor"
        [keyDownEvents]="keyDownEventSubject.asObservable()"
        [keyUpEvents]="keyUpEventSubject.asObservable()"
        [clickEvents]="clickEventSubject.asObservable()"
      ></assisted-search-popup-date>

      <div *ngIf="!isPopupHidden && showHelp && helpContent" class="hint-menu-container">
        <div class="hint-menu with-help">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
})
export class AssistedSearchComponent implements OnChanges, OnInit {
  @Input() configHash: SearchConfig;
  @Input() inputValue = '';
  @Input() placeholder: string;
  @Output() submit = new EventEmitter<SearchValue>();
  @ViewChild('mainInput') mainInput: ElementRef<HTMLInputElement>;
  @ViewChild('backgroundContainer') backgroundContainer: ElementRef<HTMLDivElement>;

  classNames = ['slack-search-input'];
  cursorLocationValue = -1;
  isPopupHidden = false;
  isPopupFocused = false;
  firstTimeFocus = false;
  isFocused = false;
  maxlength = 250;
  disabled = false;
  excludedTokenTypes = ['default', 'modifier-list', 'space'];
  pristine = true;
  proxyValue = '';
  helpContent = 'help';
  backgroundContent = '';
  blurTimeout: any;
  downClicked: boolean;
  tokenConfig: any;
  tokens: Token[] = [];
  activeTokenIndex = -1;

  enterEventSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  keyDownEventSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  keyUpEventSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  clickEventSubject: Subject<MouseEvent> = new Subject<MouseEvent>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const query = this.route.snapshot.queryParams.query;

    if (query) {
      this.proxyValue = query;
      this.isPopupFocused = false;
      this.calculateBgContent();
      this.updateTokens(this.proxyValue);
      this.search();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputValue && changes.inputValue.currentValue) {
      this.isPopupFocused = false;
      this.calculateBgContent();
      this.updateTokens(this.inputValue);
    }

    if (changes.configHash && changes.configHash.currentValue) {
      this.tokenConfig = assistedSearchUtils.prepareConfig(this.configHash || {});
    }
  }

  valueChanged(value: string) {
    this.updateTokens(value);
  }

  updateTokens(value: string) {
    this.tokens = assistedSearchUtils.tokenize(value, this.tokenConfig) || [];
    this.calculateBgContent();
  }

  get cursorLocation(): number {
    return this.cursorLocationValue;
  }

  set cursorLocation(value: number) {
    this.cursorLocationValue = value;
    assistedSearchUtils.setCursor(this.mainInput.nativeElement, value);
    this.calculateActiveTokenIndex();
  }

  get showHelp(): boolean {
    return !this.inputValue && this.cursorLocation !== -1 && this.firstTimeFocus;
  }

  calculateActiveTokenIndex(): number {
    let sumIndex = 0;
    let token;
    let startIndex;
    let endIndex;
    for (let i = 0; i < this.tokens.length; i += 1) {
      token = this.tokens[i];
      startIndex = sumIndex;
      endIndex = token.length + startIndex;
      sumIndex = endIndex;
      if (startIndex < this.cursorLocation && this.cursorLocation <= endIndex) {
        this.activeTokenIndex = i;
        return;
      }
    }
    if (this.cursorLocation > endIndex) {
      this.activeTokenIndex = this.tokens.length - 1; // last token active if cursor is beyond
      return;
    }
    this.activeTokenIndex = -1;
  }

  get activeToken() {
    return this.tokens[this.activeTokenIndex];
  }

  get activeTokenCursor() {
    if (this.activeToken) {
      const tokenCursorEnd = assistedSearchUtils.getTokenEndCursorPos(this.tokens, this.activeToken);
      return this.cursorLocation - (tokenCursorEnd - this.activeToken.length);
    }
    return -1;
  }

  isLastTokenSelected() {
    const count = this.tokens.length;
    return count && count - 1 === this.activeTokenIndex;
  }

  calculateBgContent() {
    const renderTokenBg = (t) => {
      const excluded = this.excludedTokenTypes.indexOf(t.type) !== -1;
      if (excluded) {
        return escape(t.fullText);
      }
      if (t.isValueValid) {
        return `<span class="modifier">${escape(t.modifier + t.value)}</span>`;
      }
      return `<span class="modifier incomplete">${escape(t.modifier + t.value)}</span>`;
    };
    const isLastTokenActive = this.tokens && this.activeTokenIndex === this.tokens.length - 1;
    const hint = isLastTokenActive && this.activeToken ? this.activeToken.hint || '' : '';
    this.backgroundContent = `${(this.tokens || [])
      .map(renderTokenBg)
      .join('')}<span class="hint-value">${hint}</span>`;
  }

  clear() {
    this.proxyValue = '';
    this.inputValue = '';
    if (!this.pristine) {
      this.search();
    }
    this.valueChanged('');
  }

  search() {
    this.pristine = false;
    const text =
      this.proxyValue && !this.tokens[0].modifier
        ? this.tokens
            .filter((token) => !token.modifier)
            .map((token) => token.value.replace(/"/g, ''))
            .join('')
        : '';

    this.submit.emit({ query: this.proxyValue, text });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { query: this.proxyValue },
      queryParamsHandling: 'merge',
    });
  }

  scrollInputAndBackground(amount: number) {
    this.mainInput.nativeElement.scrollLeft = amount;
    this.backgroundContainer.nativeElement.scrollLeft = amount;
  }

  scrollBackground(amount: number) {
    this.backgroundContainer.nativeElement.scrollLeft = amount;
  }

  applyModifier(modifier: string) {
    this.concatToInputValue(modifier);
    this.updateTokens(modifier);
    this.updateTokensView(this.activeToken, false, false);
  }

  concatToInputValue(text: string) {
    const newStr = this.inputValue + text;
    this.inputValue = newStr;
    this.proxyValue = this.inputValue;
    this.mainInput.nativeElement.focus();
    this.cursorLocation = newStr.length;
    this.updateTokens(newStr);
  }

  popupArrowsNavigating() {
    this.isPopupFocused = true;
  }

  changeTokenModel(data: any) {
    const { token, model, shouldMoveCursor, shouldFocus } = data;

    token.model = model;
    const appendSpace =
      data.appendSpace !== undefined ? data.appendSpace : this.tokens.indexOf(token) === this.activeTokenIndex;

    this.updateTokensView(token, shouldMoveCursor, appendSpace);

    if (shouldFocus) {
      this.mainInput.nativeElement.focus();
    }

    this.isPopupFocused = false;
  }

  resetCursor() {
    assistedSearchUtils.setCursor(this.mainInput.nativeElement, this.cursorLocation);
  }

  updateTokensView(activeToken, shouldMoveCursor, appendSpace) {
    let cursorLocation;

    if (shouldMoveCursor) {
      if (appendSpace && activeToken.isValueValid) {
        this.tokens.push(AssistedSearchToken.create({ fullText: ' ' }));
      }
      cursorLocation =
        assistedSearchUtils.getTokenEndCursorPos(this.tokens, activeToken) +
        (appendSpace && activeToken.isValueValid ? 1 : 0);
    } else {
      cursorLocation = this.cursorLocation;
    }

    this.proxyValue = assistedSearchUtils.getTokensString(this.tokens);

    if (cursorLocation === this.cursorLocation) {
      setTimeout(() => {
        this.resetCursor();
      }, 50);
    }
    this.cursorLocation = cursorLocation;

    this.calculateBgContent();

    if (appendSpace) {
      this.scrollInputAndBackground(Number.MAX_VALUE);
    } else {
      this.scrollBackground(this.mainInput.nativeElement.scrollLeft);
    }
  }

  onClick(e: MouseEvent) {
    this.scrollBackground((e.target as HTMLInputElement).scrollLeft);
    this.cursorLocation = (e.target as HTMLInputElement).selectionStart;
    this.clickEventSubject.next(e);
  }

  onFocus() {
    this.isFocused = true;
    this.firstTimeFocus = true;

    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }

    this.calculateActiveTokenIndex();
  }

  onBlur() {
    if (this.activeToken && this.activeToken.type === 'date') {
      return;
    }

    this.isFocused = false;
    this.blurTimeout = setTimeout(() => {
      this.cursorLocation = -1;
      this.scrollBackground(0);
      this.blurTimeout = null;
    }, 200);
  }

  onPaste(e: ClipboardEvent) {
    setTimeout(async () => {
      let valueText = (e.target as HTMLInputElement).value;

      if (valueText[0] === ' ') {
        valueText = valueText.replace(' ', '');
      }

      try {
        const clipboardText = await navigator.clipboard.readText();

        const regexIndex = (new RegExp(clipboardText).exec(valueText) || {}).index || valueText.length;
        const previousText = valueText.slice(0, regexIndex);

        if (previousText.slice(-2) === ': ') {
          valueText = valueText.replace(': ', ':');
        }

        this.inputValue = valueText;
        this.proxyValue = this.inputValue;
        this.cursorLocation = (e.target as HTMLInputElement).selectionStart;
        this.calculateBgContent();
        this.scrollBackground((e.target as HTMLInputElement).scrollLeft);
      } catch (e) {
        console.log('clipboard permissions rejected');
      }
    });
  }

  onCut(e: ClipboardEvent) {
    this.inputValue = (e.target as HTMLInputElement).value;
    this.proxyValue = this.inputValue;
    this.cursorLocation = (e.target as HTMLInputElement).selectionStart;
    this.calculateBgContent();
    this.scrollBackground((e.target as HTMLInputElement).scrollLeft);
  }

  onKeyDown(e: KeyboardEvent) {
    const { KEYS } = assistedSearchUtils;
    const target = e.target as HTMLInputElement;
    const { keyCode } = e;

    if (keyCode === KEYS.ENTER) {
      e.preventDefault();
      this.enterEventSubject.next(e);
      if (this.activeToken && this.activeToken.modifier && !this.activeToken.isValueValid) {
        if (this.activeToken.autoComplete()) {
          this.updateTokensView(this.activeToken, true, this.activeTokenIndex === this.tokens.length - 1);
          this.calculateBgContent();
        }
        this.search();
        this.mainInput.nativeElement.blur();
      } else if (!this.isPopupFocused) {
        this.search();
        this.mainInput.nativeElement.blur();
      }
    } else if (keyCode === KEYS.ESC) {
      e.preventDefault();
      this.mainInput.nativeElement.blur();
    } else if (keyCode === KEYS.UP) {
      e.preventDefault();
      this.keyUpEventSubject.next(e);
    } else if (keyCode === KEYS.DOWN) {
      e.preventDefault();
      this.keyDownEventSubject.next(e);
    } else if (keyCode === KEYS.TAB) {
      e.preventDefault();
      const { activeToken } = this;
      if (activeToken && activeToken.autoComplete()) {
        const hasVal = activeToken.value;
        const { tokens } = this;
        const lastTokenSelected = this.isLastTokenSelected();
        let cursorLocation = assistedSearchUtils.getTokenEndCursorPos(this.tokens, activeToken);
        if (hasVal) {
          if (lastTokenSelected) {
            tokens.push(AssistedSearchToken.create({ fullText: ' ' }));
          }
          cursorLocation += 1;
        }

        this.proxyValue = assistedSearchUtils.getTokensString(tokens);
        this.cursorLocation = cursorLocation;

        if (lastTokenSelected) {
          this.scrollInputAndBackground(Number.MAX_VALUE);
        } else {
          this.scrollBackground(target.scrollLeft);
        }

        this.calculateBgContent();
      } else if (activeToken && activeToken.type !== 'date') {
        this.downClicked = !this.downClicked;
      }
    } else {
      setTimeout(() => {
        const val = target.value;
        if (val !== this.inputValue) {
          this.inputValue = val;
        }
        this.cursorLocation = target.selectionStart;
        this.scrollBackground(target.scrollLeft);
        this.firstTimeFocus = false;
      }, 1);
    }
  }
}
