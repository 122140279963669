import * as ChartList from 'chartist';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { UsagesResource } from '../resources/usage.resource';
import { NotifyService } from '../../common/services/notify.service';

function serializeDate(date: Date): string {
  const yyyy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  return `${yyyy}-${mm[1] ? mm : `0${mm[0]}`}-${dd[1] ? dd : `0${dd[0]}`}`;
}

interface TimePeriod {
  text: string;
  active: boolean;
  days: number;
  id: number;
}

@Component({
  selector: 'account-settings-usage',
  template: `
    <div class="settings-body">
      <div id="account-usage">
        <p class="current-usage" [hidden]="isLoadingCurrentUsageData">
          <strong>{{ 'account-usage.current-usage-label' | translate }}</strong>
          <span>{{ currentUsageText }}</span>
        </p>
        <div class="btn-group pull-right">
          <button
            class="btn btn-default btn-sm"
            [ngClass]="timePeriod.active ? 'active' : ''"
            (click)="selectTimePeriod(timePeriod)"
            *ngFor="let timePeriod of timePeriods"
          >
            {{ timePeriod.text }}
          </button>
        </div>
        <p id="total-usage-text" [hidden]="isLoadingData">
          <span class="total-box distributed-box"></span>
          {{ 'account-usage.distributed' | translate: { hours: totalDistributedHours, days: days } }}
          <br />
          <span class="total-box sandbox-box"></span>
          {{ 'account-usage.sandbox' | translate: { hours: totalSandboxHours, days: days } }}
        </p>
        <div class="alert" id="no-data-alert" *ngIf="isNoDataAlertVisible">{{ 'account-usage.alert' | translate }}</div>
        <div [hidden]="isLoadingData">
          <div (mouseout)="onChartMouseOut()" id="chart-stacked-bar"></div>
        </div>
        <div #chartTooltipRef [hidden]="isCharToolTipHidden" id="chart-tooltip">
          <strong class="chartjs-tooltip-title">{{ tooltipTitle }}</strong>
          <div class="chartjs-tooltip-section">
            <span class="chartjs-tooltip-key distributed"></span>
            <span class="chartjs-tooltip-value"
              >{{ distributedValue }} {{ 'account-usage.distributed-node-hours' | translate }}</span
            >
          </div>
          <div class="chartjs-tooltip-section">
            <span class="chartjs-tooltip-key sandbox"></span>
            <span class="chartjs-tooltip-value"
              >{{ sandboxesValue }} {{ 'account-usage.sandbox-node-hours' | translate }}</span
            >
          </div>
        </div>
        <fieldset>
          <div class="chart" style="display: none;"></div>
          <div class="x_axis" style="display: none;"></div>
        </fieldset>
        <xp-loader *ngIf="isLoadingData"></xp-loader>
      </div>
    </div>
  `,
})
export class AccountSettingsUsageComponent implements OnInit {
  currentUsageText = '';
  isLoadingData = true;
  isLoadingCurrentUsageData = true;
  canvasSelector = '#chart-stacked-bar';
  isCharToolTipHidden = true;
  timePeriods: TimePeriod[] = [
    {
      text: this.translate.instant('account-usage.time-period.last-7-days'),
      active: false,
      days: 7,
      id: 0,
    },
    {
      text: this.translate.instant('account-usage.time-period.last-28-days'),
      active: true,
      days: 28,
      id: 1,
    },
    {
      text: this.translate.instant('account-usage.time-period.last-90-days'),
      active: false,
      days: 90,
      id: 2,
    },
  ];
  sinceDate: Date;
  currentDate: Date;
  tooltipTitle: string;
  distributedValue: string;
  sandboxesValue: string;
  isNoDataAlertVisible = false;

  @ViewChild('chartTooltipRef') chartTooltipRef: ElementRef<HTMLDivElement>;
  totalHours = 0;
  totalSandboxHours = 0;
  totalDistributedHours = 0;
  days = 0;

  constructor(
    private translate: TranslateService,
    private usagesResource: UsagesResource,
    private elementRef: ElementRef,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.getCurrentUsageData();

    this.selectTimePeriod(this.timePeriods.find((item) => item.active));
  }

  onChartMouseOut() {
    this.isCharToolTipHidden = true;
  }

  getData(timePeriod) {
    this.isLoadingData = true;

    this.usagesResource
      .get({
        since: timePeriod,
      } as any)
      .subscribe({
        next: (data) => {
          this.prepareDataForChart(data);
        },
        error: () => {
          this.notify.error('An error occurred while loading usage data.');
        },
      });
  }

  getCurrentUsageData() {
    this.isLoadingCurrentUsageData = true;

    const timePeriod = moment().utc().startOf('month');

    this.usagesResource
      .get({
        since: timePeriod.toISOString(),
      } as any)
      .subscribe({
        next: (data) => {
          this.isLoadingCurrentUsageData = false;

          this.currentUsageText = this.translate.instant('account-usage.current-usage-text', {
            distributed: data.distributed_clusters_uptime.total / 3600,
            sandbox: data.sandbox_clusters_uptime.total / 3600,
            date: moment(timePeriod).format('MMM Do'),
          });
        },
        error: () => {
          this.notify.error('An error occurred while loading usage data.');
        },
      });
  }

  checkData(data) {
    const checkedData = [];

    for (let d = this.sinceDate; d <= this.currentDate; d.setDate(d.getDate() + 1)) {
      let added = false;

      data.forEach(function (date) {
        const newDate = new Date(date.day);
        if ((newDate as any).yyyymmdd() === serializeDate(d)) {
          checkedData.push(date);
          added = true;
        }
      });

      if (!added) {
        checkedData.push({
          completed_jobs_count: 0,
          day: serializeDate(d),
          distributed_clusters_uptime: 0,
          failed_jobs_count: 0,
          sandbox_clusters_uptime: 0,
          stopped_jobs_count: 0,
        });
      }
    }
    return checkedData.reverse();
  }

  prepareDataForChart(data) {
    this.isNoDataAlertVisible = data.length === 0;

    this.totalDistributedHours = data.distributed_clusters_uptime.total / 3600;
    this.totalSandboxHours = data.sandbox_clusters_uptime.total / 3600;

    const clustersUptime = [];
    const distributedUptime = [];

    data.sandbox_clusters_uptime.series.forEach(function (uptime) {
      clustersUptime.push(uptime / 3600);
    });

    data.distributed_clusters_uptime.series.forEach(function (uptime) {
      distributedUptime.push(uptime / 3600);
    });

    const dataForChart = {
      labels: data.labels,
      series: [distributedUptime, clustersUptime],
    };

    this.totalHours = this.totalDistributedHours + this.totalSandboxHours;

    this.buildChart(dataForChart);

    this.isLoadingData = false;
  }

  selectTimePeriod(timePeriod: TimePeriod) {
    this.timePeriods = this.timePeriods.map((item) => ({
      ...item,
      active: item.id === timePeriod.id,
    }));

    const currentDate = new Date();
    const sinceDate = new Date();

    const { days } = timePeriod;

    this.days = days;

    sinceDate.setDate(currentDate.getDate() - days);

    this.getData(sinceDate.toISOString());

    this.currentDate = currentDate;
    this.sinceDate = sinceDate;
  }

  buildChart(_data) {
    const canvasElement = this.elementRef.nativeElement.querySelector(this.canvasSelector);
    const canvasWidth = canvasElement.innerWidth;
    let chartContainerWidth = canvasWidth - 100;

    new ChartList.Bar(this.canvasSelector, _data, {
      stackBars: true,
      height: 300,
      axisX: {
        labelOffset: {
          x: -4,
          y: 0,
        },
        showGrid: false,
        labelInterpolationFnc: (value, index) => {
          // eslint-disable-next-line no-nested-ternary
          return index % (this.days > 7 ? (this.days > 28 ? 14 : 7) : 1) ? null : value;
        },
      },
      axisY: {
        labelOffset: {
          x: 0,
          y: 5,
        },
      },
    }).on('draw', (data) => {
      if (data.element.attr('class') && data.element.attr('class').indexOf('ct-bar') > -1) {
        chartContainerWidth =
          (this.elementRef.nativeElement.querySelector(this.canvasSelector) as HTMLElement).offsetWidth - 100;
        const width = Math.floor(chartContainerWidth / (this.days * 1.35));

        data.element.attr({
          style: `stroke-width: ${width}px`,
        });

        // eslint-disable-next-line no-underscore-dangle
        data.element._node.addEventListener('mouseenter', (event) => {
          this.tooltipTitle = _data.labels[data.index];
          this.distributedValue = _data.series[0][data.index];
          this.sandboxesValue = _data.series[1][data.index];

          this.isCharToolTipHidden = false;
          this.chartTooltipRef.nativeElement.style.top = `${event.target.y2.baseVal.value + 100}px`;
          this.chartTooltipRef.nativeElement.style.left = `${event.target.x1.baseVal.value + 20}px`;
        });

        // eslint-disable-next-line no-underscore-dangle
        data.element._node.addEventListener('mouseleave', () => {
          this.isCharToolTipHidden = true;
        });
      }
    });
  }
}
