import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ValidationError } from '../../../config/validation-error.model';
import { Member } from '../../members.models';
import { ListParams } from '../../../common/helper/query-params-generic-list.helper';

export const openMembersModal = createAction('[Members] Open modal');
export const closeMembersModal = createAction('[Members] Close modal');

export const openEditRolesMembersModal = createAction('[Members] Open edit roles modal');
export const closeEditRolesMembersModal = createAction('[Members] Close edit roles modal');

export const getMember = createAction('[Members] Get member', props<{ memberId: number }>());
export const getMemberResponse = createAction('[Members] Get member response', props<{ member: Member }>());

export const getMembersList = createAction('[Members] Get members list', props<{ params: ListParams }>());
export const getMembersListResponse = createAction(
  '[Members] Get members list response',
  props<{ members: Member[] }>(),
);
export const getMemberError = createAction('[Members] Get member error', props<{ errors: ValidationError[] }>());
export const getMembersListError = createAction(
  '[Members] Get members list error',
  props<{ errors: ValidationError[] }>(),
);

export const loadMoreMembersList = createAction('[Members] Load more members list', props<{ params: ListParams }>());
export const loadMoreMembersListResponse = createAction(
  '[Members] Load more members list response',
  props<{ members: Member[] }>(),
);

export const saveMember = createAction('[Members] Save member', props<{ member: Partial<Member> }>());
export const saveMemberResponse = createAction('[Members] Save member response', props<{ data: Member }>());
export const saveMemberError = createAction('[Members] Save member error', props<{ errors: ValidationError[] }>());

export const updateMember = createAction('[Members] Update member', props<{ member: Partial<Member> }>());
export const updateMemberResponse = createAction('[Members] Update member response', props<{ data: Member }>());
export const updateMemberError = createAction('[Members] Update member error', props<{ errors: ValidationError[] }>());

export const removeMember = createAction('[Members] Remove member', props<{ memberId: number; isLogout: boolean }>());
export const removeMemberItem = createAction('[Members] Remove member item', props<{ member: Member }>());
export const removeMemberResponse = createAction('[Members] Remove member response', props<{ data: Member }>());
export const removeMemberError = createAction('[Members] Remove member error', props<{ errors: ValidationError[] }>());
