import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'xp-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => XpInputComponent),
      multi: true,
    },
  ],
  template: `
    <div class="base-input">
      <input
        #tooltipEl="matTooltip"
        [attr.id]="id"
        [attr.type]="type"
        [matTooltip]="tooltip"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipClass]="state + ' ' + tooltipPosition"
        [attr.name]="name"
        [attr.placeholder]="placeholder"
        [(ngModel)]="ngModel"
        #ctrl="ngModel"
        [className]="state + ' ' + 'base-input-control'"
        (input)="onChange(ngModel)"
        (focus)="onFocusInternal()"
        (blur)="blur.emit()"
        [disabled]="disable"
        [readOnly]="state === 'readonly' || state === 'button' || readOnly"
        [matAutocomplete]="auto"
        #inputRef
      />
      <mat-autocomplete #auto="matAutocomplete" (closed)="autocompleteClosed()" (opened)="autocompleteOpened()">
        <div class="autocomplete-description" *ngIf="autoCompleteDescription">{{ autoCompleteDescription }}</div>
        @for (option of filteredOptions; track option) {
          <mat-option [value]="option" class="autocomplete-item" (onSelectionChange)="onOptionClick($event)">{{
            option
          }}</mat-option>
        }
        <mat-option *ngIf="isLazyLoading">
          <xp-loader></xp-loader>
        </mat-option>
      </mat-autocomplete>
    </div>
  `,
})
export class XpInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() ngModel: string | number;
  @Output() ngModelChange = new EventEmitter<string | number>();
  @Input() state: string = '';
  @Input() name: string = '';
  @Input() id: string = String(Date.now());
  @Input() placeholder = '';
  @Input() tooltip: string;
  @Input() disable: boolean;
  @Input() autoCompleteOptions: string[];
  @Input() autoCompleteDescription: string;
  @Input() icon: string;
  @Input() type = 'text';
  @Input() readOnly = false;
  @Input() isFocused = false;
  @Input() isLazyLoading = false;
  @Input() disableSearch = false;
  @Output() inputChange = new EventEmitter<{ value: string | number; isFirstChange?: boolean }>();
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();
  @Output() autocompleteReachedEnd = new EventEmitter<void>();

  @ViewChild('tooltipEl') tooltipRef: MatTooltip;
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('auto') auto: ElementRef<HTMLInputElement>;

  tooltipPosition: TooltipPosition = 'above';
  firstChange = true;
  propagateChange = (_: string | number) => {};
  propagateTouch = () => {};
  initialChange = true;
  filteredOptions = [];
  height: string;
  hasInputChanged = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.handleFilteredOptions();

    if (this.ngModel !== undefined) {
      this.onChange(this.ngModel, true);
    }

    if (this.disable) {
      this.elementRef.nativeElement.classList.add('disabled');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.autoCompleteOptions) {
      this.handleFilteredOptions();
    }

    if (changes.tooltip && !changes.tooltip.previousValue && changes.tooltip.currentValue && this.tooltipRef) {
      this.tooltipRef.message = changes.tooltip.currentValue;
      this.tooltipRef.show();
    }

    if (changes.isFocused && changes.isFocused.currentValue && !changes.isFocused.previousValue) {
      setTimeout(() => {
        this.inputRef.nativeElement.focus();
      });
    }

    if (
      changes.ngModel &&
      changes.ngModel?.currentValue &&
      !changes.ngModel?.previousValue &&
      this.initialChange &&
      this.inputRef
    ) {
      this.inputRef.nativeElement.value = changes.ngModel.currentValue;
      this.inputRef.nativeElement.dispatchEvent(
        new Event('input', {
          bubbles: true,
          cancelable: true,
        }),
      );
      this.initialChange = false;
    }

    if (this.disable) {
      this.elementRef.nativeElement.classList.add('disabled');
    } else {
      this.elementRef.nativeElement.classList.remove('disabled');
    }
  }

  handleFilteredOptions() {
    if (this.disableSearch) {
      this.filteredOptions = this.autoCompleteOptions || [];
    } else {
      if (!this.hasInputChanged) {
        this.filteredOptions = this.autoCompleteOptions || [];
      } else {
        this.filteredOptions = (this.autoCompleteOptions || []).filter((option) =>
          (option || '').toLowerCase().startsWith((String(this.ngModel || '') || '').toLowerCase()),
        );
      }
    }

    // Recompute how big the viewport should be.
    if (this.filteredOptions.length < 4) {
      this.height = `${this.filteredOptions.length * 50}px`;
    } else {
      this.height = '200px';
    }
  }

  onChange(value, isInitialChange = false) {
    this.ngModelChange.emit(value);
    this.propagateChange(value);

    if (this.inputChange) {
      this.inputChange.emit({ value, isFirstChange: this.firstChange });
    }

    this.firstChange = false;
    this.hasInputChanged = !isInitialChange;

    this.handleFilteredOptions();
  }

  onFocusInternal() {
    this.focus.emit();
    this.propagateTouch();
  }

  onOptionClick(event: MatOptionSelectionChange) {
    this.inputRef.nativeElement.value = event.source.value;
    this.onChange(event.source.value);
    this.inputRef.nativeElement.dispatchEvent(new Event('input', { bubbles: true, cancelable: false }));
  }

  writeValue(value: string | number) {
    if (value !== undefined) {
      this.ngModel = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  onScroll(event) {
    const eventTarget = event.target as HTMLElement;
    if (eventTarget.scrollTop === 0 || eventTarget.offsetHeight + eventTarget.scrollTop < eventTarget.scrollHeight) {
      return;
    }

    if (!this.isLazyLoading) {
      this.autocompleteReachedEnd.emit();
    }
  }

  autocompleteOpened() {
    document.querySelector('.mat-mdc-autocomplete-panel').addEventListener('scroll', this.onScroll.bind(this));
  }

  autocompleteClosed() {
    document.querySelector('.mat-mdc-autocomplete-panel').removeEventListener('scroll', this.onScroll.bind(this));
  }
}
