import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { AppState } from '../../../store';
import { getMembersList } from '../../../settings/store/members/members.actions';
import { selectMembers } from '../../../settings/store/members/members.selectors';
import { Member } from '../../../settings/members.models';
import { MembersSearchItem, SearchConfig } from './search-config.model';

const basicConfig: SearchConfig = {
  'name:': {
    defaultHint: 'Workspaces with this name',
  },
  'description:': {
    defaultHint: 'Workspaces with this description',
  },
  'created:>': {
    type: 'date',
    defaultHint: 'Workspaces created after the date',
  },
  'created:<': {
    type: 'date',
    defaultHint: 'Workspaces created before the date',
  },
  'modified:>': {
    type: 'date',
    defaultHint: 'Workspaces modified after the date',
  },
  'modified:<': {
    type: 'date',
    defaultHint: 'Workspaces modified before the date',
  },
  'user:': {
    type: 'thumbs-list',
    defaultHint: 'Workspaces owned by matching users',
    sectionTitle: 'Users',
    content: [], // thumbs-list -compiliant autocomplete items will go here
  },
};

function prepareValue(stringVal: string): string {
  if (stringVal && typeof stringVal.indexOf !== 'undefined' && stringVal.indexOf(' ') !== -1) {
    return `"${stringVal}"`; // wrap in quotes if contains spaces
  }
  return stringVal;
}

function formatMemberForThumbsList(u: Member): MembersSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name || u.email),
    label: u.name || u.email,
    thumb_url: u.avatar_url,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssistedSearchWorkspacesConfiguratorService {
  constructor(private store: Store<AppState>) {}

  getConfig$(): Observable<SearchConfig> {
    this.store.dispatch(getMembersList({ params: {} }));

    return this.store.select(selectMembers).pipe(
      map((members) => {
        const configCopy = { ...basicConfig };
        configCopy['user:'].content = members.map(formatMemberForThumbsList);
        return configCopy;
      }),
    );
  }
}
