import { Injectable } from '@angular/core';
import { NAV_DATA_KEY } from '../../constants/app';

@Injectable({
  providedIn: 'root',
})
export class BoomerangService {
  init() {
    if (window['Boomerang']) {
      try {
        if (window.localStorage.getItem(NAV_DATA_KEY)) {
          const navData = JSON.parse(window.atob(window.localStorage.getItem(NAV_DATA_KEY)));
          window['Boomerang'].init({ app: navData.appname, addon: 'xplenty' });
        } else {
          window['Boomerang'].reset();
        }
      } catch (e) {
        console.log('An error occurred loading Boomerang: ', e);
      }
    }
  }

  logout() {
    window.localStorage.removeItem(NAV_DATA_KEY);
    if (window['Boomerang']) {
      window['Boomerang'].reset();
    }
  }
}
