import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class ValidationsExportsResource {
  constructor(private http: HttpClient) {}

  get(params?: ListParams): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}/validations_export`, { params: params as QueryParams });
  }
}
