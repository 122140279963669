import { Component, Input } from '@angular/core';
import { AllComponentData } from '../../package.models';

@Component({
  selector: 'component-thumbnail',
  template: `
    <div class="component" [ngClass]="{ empty: !component }">
      <div class="component-icon" *ngIf="component">
        <svg xmlns="http://www.w3.org/2000/svg">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" [attr.href]="'#' + component.icon"></use>
        </svg>
      </div>
      <div class="component-title" *ngIf="component">
        {{ component.name }}
      </div>
      <div class="component-empty" *ngIf="!component">Input not connected</div>
    </div>
  `,
})
export class ComponentThumbnailComponent {
  @Input() component: AllComponentData;
}
