import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { MongoConnection, MongoConnectionScheme, MongoReadPreference } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';

interface ReplicaMember {
  value: string;
  id: number;
}

@Component({
  selector: 'connection-form-mongo',
  template: `
    <xp-form-validation type="MongoDatabaseConnection" [name]="formName">
      <form id="connectionFormMongo" name="connectionFormMongo" #form="ngForm" class="form connection-form-mongo">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <connection-component-access-type
              [connection]="connection"
              (tunnelTypeChange)="onTunnelTypeChange($event)"
              (portChange)="updateReplicaSetMembers()"
            ></connection-component-access-type>
            <div class="form-group">
              <label>{{ 'connections.form.mongo.labels.connection_type' | translate }} </label>

              <xp-select
                class="form-control xp-select"
                id="connection_scheme"
                name="connection_scheme"
                [value]="connection.connection_scheme"
                [options]="connectionSchemeOptions"
                (valueChange)="onConnectionSchemeChange($event)"
              >
              </xp-select>
            </div>
            <div
              class="form-group"
              *ngIf="!connection.connection_scheme || connection.connection_scheme === 'replica-members'"
            >
              <label
                >{{ 'connections.form.mongo.labels.replica_member' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <section *ngFor="let replicaMember of replicaMembers; let index = index" class="replica-row">
                <div class="replica-input">
                  <xp-input
                    type="text"
                    class="form-control replica-xp-input"
                    [name]="'replicaMember-' + replicaMember.id"
                    [id]="'replicaMember-' + replicaMember.id"
                    [(ngModel)]="replicaMember.value"
                    (ngModelChange)="updateReplicaSetMembers()"
                  ></xp-input>
                  <button
                    type="button"
                    class="btn btn-link btn-md variables-editor-remove-btn"
                    (click)="removeReplicaMember(replicaMember.id)"
                  >
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      [attr.alt]="'connections.form.mongo.buttons.remove_replica' | translate"
                    ></i>
                  </button>
                  <button
                    *ngIf="index === replicaMembers.length - 1 && replicaMembers.length < 5"
                    type="button"
                    class="btn btn-link btn-md variables-editor-remove-btn"
                    (click)="addReplicaMember()"
                  >
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      [attr.alt]="'connections.form.mongo.buttons.add_replica' | translate"
                    ></i>
                  </button>
                </div>
              </section>
            </div>
          </div>
          <div class="col-md-6">
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
            <xp-form-group>
              <label for="read_preference"
                >{{ 'connections.form.mongo.labels.read_preference' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-select
                class="form-control xp-select"
                id="read_preference"
                name="read_preference"
                [value]="connection.read_preference"
                [options]="readPreferencesOptions"
                (valueChange)="onReadPreferenceChange($event)"
              >
              </xp-select>
            </xp-form-group>
            <xp-form-group>
              <label for="authentication_database"
                >{{ 'connections.form.mongo.labels.authentication_database' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="text"
                class="form-control"
                name="authentication_database"
                id="authentication_database"
                [(ngModel)]="connection.authentication_database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="database">{{ 'connections.form.mongo.labels.database' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="database"
                id="database"
                [(ngModel)]="connection.database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group [validationDisabled]="true">
              <xp-input-checkbox
                [(ngModel)]="connection.ssl"
                name="ssl"
                labelText="{{ 'connections.form.labels.connect_using_ssl' | translate }}"
              ></xp-input-checkbox>
            </xp-form-group>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormMongoComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<MongoConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormMongo';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  @Output() tunnelTypeChange = new EventEmitter<String>();

  readPreferencesOptions = [
    { value: MongoReadPreference.primary, text: 'Primary' },
    { value: MongoReadPreference.primaryPreferred, text: 'Primary preferred' },
    { value: MongoReadPreference.secondary, text: 'Secondary' },
    { value: MongoReadPreference.secondaryPreferred, text: 'Secondary preferred' },
    { value: MongoReadPreference.nearest, text: 'Nearest' },
  ];

  connectionSchemeOptions = [
    {
      value: MongoConnectionScheme.dnsSeedList,
      text: 'connections.form.mongo.selects.connection_scheme.options.dns-seed-list',
      translate: true,
    },
    {
      value: MongoConnectionScheme.replicaMembers,
      text: 'connections.form.mongo.selects.connection_scheme.options.replica-members',
      translate: true,
    },
  ];
  replicaMembers: ReplicaMember[] = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.connection.replica_set_members && this.connection.replica_set_members.length) {
      this.replicaMembers = this.connection.replica_set_members
        .filter(Boolean)
        .map((replicaMember, index) => ({ value: replicaMember.split(':')[0], id: index }))
        .filter((replicaMember) => replicaMember.value !== this.connection.host);
    }

    if (!this.replicaMembers || !this.replicaMembers.length) {
      this.replicaMembers = [{ value: '', id: 0 }];
    }

    if (
      !this.connection.connection_scheme ||
      (this.connection.replica_set_members && this.connection.replica_set_members.length) ||
      this.connection.connection_scheme === MongoConnectionScheme.replicaMembers
    ) {
      this.connection.connection_scheme = MongoConnectionScheme.replicaMembers;
    } else {
      this.connection.connection_scheme = MongoConnectionScheme.dnsSeedList;
    }
  }

  onTunnelTypeChange(value: string) {
    this.tunnelTypeChange.emit(value);
  }

  onReadPreferenceChange(readPreference: MongoReadPreference) {
    this.connection.read_preference = readPreference;
  }

  onConnectionSchemeChange(connectionScheme: MongoConnectionScheme) {
    this.connection.connection_scheme = connectionScheme;
    if (this.connection.connection_scheme && this.connection.connection_scheme === MongoConnectionScheme.dnsSeedList) {
      this.replicaMembers = [{ value: '', id: 0 }];
      this.connection.replica_set_members = [];
    }
  }

  addReplicaMember() {
    const lastReplicaMember = this.replicaMembers[this.replicaMembers.length - 1];
    const lastId = lastReplicaMember ? lastReplicaMember.id : -1;
    this.replicaMembers = [...this.replicaMembers, { value: '', id: lastId + 1 }];
    this.updateReplicaSetMembers();
  }

  removeReplicaMember(id: number) {
    if (this.replicaMembers.length === 1) {
      this.replicaMembers[0].value = '';
    } else {
      this.replicaMembers = this.replicaMembers.filter((replicaMember) => replicaMember.id !== id);
    }
    this.updateReplicaSetMembers();
  }

  updateReplicaSetMembers() {
    this.connection.replica_set_members = this.replicaMembers
      .filter((replicaMember) => replicaMember.value && replicaMember.value !== this.connection.host)
      .map((replicaMember) => `${replicaMember.value}:${this.connection.port}`);

    const areReplicaSetMembersEmpty =
      this.connection.replica_set_members.every((item) => item === '') &&
      this.connection.replica_set_members.length <= 1;
    if (this.connection.host && this.connection.connection_scheme === 'replica-members' && !areReplicaSetMembersEmpty) {
      this.connection.replica_set_members.unshift(`${this.connection.host}:${this.connection.port}`);
    }
  }
}
