import { createReducer, on } from '@ngrx/store';
import { Workspace } from '../workspaces.models';
import {
  closeWorkspacesModal,
  getWorkspacesList,
  getWorkspacesListError,
  getWorkspacesListResponse,
  loadMoreWorkspacesList,
  loadMoreWorkspacesListResponse,
  openWorkspacesModal,
  removeWorkspaceItem,
  removeWorkspaceResponse,
  saveWorkspace,
  saveWorkspaceError,
  saveWorkspaceResponse,
  searchWorkspacesList,
  searchWorkspacesListResponse,
  updateWorkspace,
  updateWorkspaceError,
  updateWorkspaceResponse,
} from './workspaces.actions';
import { ValidationError } from '../../config/validation-error.model';
import { closeAllModals } from '../../account/store/account.actions';
import { QueryParamsWorkspacesList } from '../../common/helper/query-params-generic-list.helper';

export const initialState: WorkspacesState = {
  items: [],
  item: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  errors: [],
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface WorkspacesState {
  items: Workspace[];
  item: Workspace;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  errors: ValidationError[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const workspacesReducer = createReducer<WorkspacesState>(
  initialState,

  on(openWorkspacesModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeWorkspacesModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(getWorkspacesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getWorkspacesListResponse, (state, { workspaces }) => ({
    ...state,
    isLoading: false,
    items: workspaces,
    areAllItemsLoaded: workspaces.length < QueryParamsWorkspacesList.limit,
  })),
  on(getWorkspacesListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(searchWorkspacesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(searchWorkspacesListResponse, (state, { workspaces }) => ({
    ...state,
    isLoading: false,
    items: workspaces,
    areAllItemsLoaded: workspaces.length < QueryParamsWorkspacesList.limit,
  })),

  on(loadMoreWorkspacesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreWorkspacesListResponse, (state, { workspaces, limit }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...workspaces],
    areAllItemsLoaded: workspaces.length < limit,
  })),

  on(saveWorkspace, (state) => ({ ...state, isFormSubmitting: true })),
  on(saveWorkspaceResponse, (state, { data, closeModal }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: closeModal ? false : state.isModalOpen,
  })),
  on(saveWorkspaceError, (state, { errors }) => ({
    ...state,
    isFormSubmitting: false,
    errors,
  })),

  on(updateWorkspace, (state) => ({ ...state, isFormSubmitting: true })),
  on(updateWorkspaceResponse, (state, { data }) => ({
    ...state,
    items: state.items.map((item) =>
      item.id === data.id && data.packages ? { ...data, packages: data.packages } : item,
    ),
    isFormSubmitting: false,
  })),
  on(updateWorkspaceError, (state, { errors }) => ({
    ...state,
    isFormSubmitting: false,
    errors,
  })),

  on(removeWorkspaceItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeWorkspaceResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
