import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Component as PackageComponent, JoinComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { JOIN_TYPES } from '../../../constants/join_types';
import { OPTIMIZATION_TYPES } from '../../../constants/optimization_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { switchParentSchemasOrder } from '../../store/package-designer.actions';

@Component({
  selector: 'join-editor',
  template: `
    <div class="join-editor">
      <xp-steps>
        <xp-step [step]="componentOrderEditorStep">
          <components-order-editor
            [components]="parentComponents"
            (switchComponents)="onSwitchComponents($event)"
          ></components-order-editor>
        </xp-step>
        <xp-step [step]="componentRecordsStep">
          <form name="componentForm" novalidate #form="ngForm">
            <div class="row">
              <div class="alert alert-warning" *ngIf="errorMessage">
                <button type="button" class="close" (click)="errorMessage = null">
                  <span aria-hidden="true">&times;</span>
                </button>
                <span>{{ errorMessage }}</span>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="join_type"
                    ><b>{{ 'join-editor.labels.join_type' | translate }}</b></label
                  >
                  <xp-select
                    name="join_type"
                    id="join_type"
                    [value]="rawComponent.join_type"
                    [options]="joinTypes"
                    (valueChange)="onJoinTypeChange($event)"
                    [preventEmpty]="true"
                    class="form-control xp-select"
                  ></xp-select>
                </div>
                <div class="form-group">
                  <label for="optimization_type"
                    ><b>{{ 'join-editor.labels.optimization_type' | translate }}</b></label
                  >
                  <xp-select
                    name="optimization_type"
                    id="optimization_type"
                    [value]="rawComponent.optimization_type"
                    [options]="optimizationTypes"
                    (valueChange)="onOptimizationChangeType($event)"
                    [preventEmpty]="true"
                    class="form-control xp-select"
                  ></xp-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label
                    ><b>{{ 'join-editor.labels.join_keys' | translate }}</b></label
                  >
                  <br />
                  <label>{{ 'join-editor.labels.join_keys_desc' | translate }}</label>
                </div>
              </div>
            </div>

            <join-collection
              [records]="rawComponent.relations"
              [parentSchemas]="parentSchemas"
              (recordsChange)="onRecordsChange($event)"
              (validityChange)="onJoinCollectionValidityChange($event)"
            ></join-collection>

            <div class="row">
              <component-previewer [componentId]="rawComponent.id"></component-previewer>
            </div>
          </form>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class JoinEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: JoinComponentData;
  @Input() parentSchemas: Schema[];
  @Input() parentComponents: PackageComponent[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() componentsOrderChange = new EventEmitter<PackageComponent[]>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  errorMessage = '';

  isFormValid = false;
  isComponentValid = false;
  isJoinCollectionValid = false;
  validationChangeSubscription: Subscription;
  joinTypes = JOIN_TYPES;
  optimizationTypes = OPTIMIZATION_TYPES;

  componentOrderEditorStep = getStep({
    valid: true,
    title: this.translate.instant('component-editor.step-order.join_component.closed'),
    activeTitle: this.translate.instant('component-editor.step-order.join_component.active'),
  });
  componentRecordsStep = getStep({
    active: true,
    title: this.translate.instant('component-editor.step-editor.join_component.closed'),
    activeTitle: this.translate.instant('component-editor.step-editor.join_component.active'),
  });

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });

    this.checkOptimizationValidity();
  }

  onRecordsChange(records) {
    this.store.dispatch(updateRawComponent({ rawComponent: { relations: records } }));
  }

  onJoinCollectionValidityChange(isValid) {
    this.isJoinCollectionValid = isValid;
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isComponentValid && this.isFormValid && this.isJoinCollectionValid;

    this.componentRecordsStep = { ...this.componentRecordsStep, valid: isValid };

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onJoinTypeChange(join_type: string) {
    this.checkOptimizationValidity();
    this.store.dispatch(updateRawComponent({ rawComponent: { join_type } }));
    this.store.dispatch(updateComponent({ component: { join_type } }));
  }

  onOptimizationChangeType(optimization_type: string) {
    this.checkOptimizationValidity();
    this.store.dispatch(updateRawComponent({ rawComponent: { optimization_type } }));
    this.store.dispatch(updateComponent({ component: { optimization_type } }));
  }

  checkOptimizationValidity() {
    if (
      this.rawComponent.optimization_type === 'replicated' &&
      this.rawComponent.join_type !== 'inner' &&
      this.rawComponent.join_type !== 'left'
    ) {
      this.errorMessage = this.translate.instant('join-editor.errors.replicated_optimization_error');
      this.isComponentValid = false;
    } else {
      this.errorMessage = null;
      this.isComponentValid = true;
    }
    this.onValidityChange();
  }

  onSwitchComponents(components: PackageComponent[]) {
    this.componentOrderEditorStep = { ...this.componentOrderEditorStep, active: true };
    this.componentRecordsStep = { ...this.componentRecordsStep, active: false };
    this.store.dispatch(switchParentSchemasOrder());
    this.componentsOrderChange.emit(components);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
