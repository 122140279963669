import { ValidatorRunner } from './models';
import { REGEXES } from '../../../constants/regexes';

export const KeywordsValidator: ValidatorRunner = {
  name: 'KeywordsValidator',
  message: 'errors.KeywordsValidator',
  order: 2,
  validate({ value }) {
    const keywords = REGEXES.RESERVED_KEYWORDS;
    return !keywords.test(value);
  },
};
