import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Credentials } from '../../common/services/auth.service';
import { UsersResource } from '../resources/users.resource';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'sign-up',
  template: `
    <div class="panel">
      <div class="panel-header">
        <div class="panel-logo">
          <img class="logo-img" width="80" height="80" src="assets/img/xplenty-logo.svg" alt="Prepforce" />
        </div>
        <h3 class="panel-title">{{ 'auth.sign_up.form.title' | translate }}</h3>
      </div>
      <div class="panel-body">
        <xp-form-validation type="User">
          <form role="form" name="signupForm" #form="ngForm">
            <fieldset>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.name' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.name"
                  [placeholder]="'auth.sign_up.form.placeholders.name' | translate"
                  name="name"
                  type="text"
                  autofocus
                />
              </xp-form-group>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.email' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.email"
                  [placeholder]="'auth.sign_up.form.placeholders.email' | translate"
                  name="email"
                  type="email"
                  maxlength="254"
                />
              </xp-form-group>
            </fieldset>
            <xp-submit-button
              (submitForm)="submit()"
              classNames="btn-lg btn-block btn-primary"
              [isFormValid]="form.valid"
              [isFormSubmitting]="isLoading"
              [updateText]="'auth.sign_up.form.actions.sign_up' | translate"
            ></xp-submit-button>
          </form>
        </xp-form-validation>
      </div>
      <div class="panel-footer text-center panel-links">
        <span>{{ 'auth.sign_up.form.labels.have_account' | translate }}</span>
        <a (click)="goToSignIn()">{{ 'auth.sign_up.form.actions.sign_in' | translate }}</a>
      </div>
    </div>
    <help-menu></help-menu>
  `,
})
export class SignUpComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  credentials: Credentials = {
    email: '',
    password: '',
  };
  isLoading = false;
  partner_id = '';
  partner_resource_id = '';

  constructor(
    private usersResource: UsersResource,
    private router: Router,
    private httpClient: HttpClient,
  ) {
    // @ts-ignore
    window.email_required = true;
  }

  // eslint-disable-next-line class-methods-use-this
  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_medium = urlParams.get('utm_medium');
    const utm_source = urlParams.get('utm_source');
    this.partner_id = urlParams.get('partner_id');
    this.partner_resource_id = urlParams.get('partner_resource_id');

    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['woopra'].config({
      domain: 'xplenty.com',
    });

    if (document.referrer.includes('appexchange.salesforce.com') && utm_source) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].identify({
        utm_campaign,
        utm_medium,
        utm_source,
      });

      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].track('appexchange.salesforce.com visitor', {
        utm_campaign,
        utm_medium,
        utm_source,
      });
    }
  }

  submit() {
    this.isLoading = true;
    const params = {};

    if (this.partner_id) {
      params['partner_id'] = this.partner_id;
      params['partner_resource_id'] = this.partner_resource_id;
    }
    this.usersResource.save({ ...this.credentials, ...params, grant_type: 'password' }).subscribe({
      next: () => {
        this.isLoading = false;
        this.httpClient
          .post(environment.HUBSPOT_URL, {
            email: this.credentials.email,
            name: this.credentials.name,
            formId: 'etl_signup_form',
            is_prepforce: true,
          })
          .subscribe({
            next: () => {
              this.router.navigate(['/auth/thank-you']);
            },
            error: () => {
              this.router.navigate(['/auth/thank-you']);
            },
          });
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  goToSignIn() {
    this.router.navigate(['/auth/login']);
  }
}
