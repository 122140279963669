import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { Schedule } from '../schedule.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class SchedulesResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  get(schedule_id: number, params?: ListParams): Observable<Schedule> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((store_account_id) =>
        this.http.get<Schedule>(`${this.apiRoot}/${store_account_id}/api/schedules/${schedule_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  save(body: Partial<Schedule>, params?: ListParams): Observable<Schedule> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Schedule>(`${this.apiRoot}/${account_id}/api/schedules`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  update(schedule_id: number, body: Partial<Schedule>, params?: ListParams): Observable<Schedule> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<Schedule>(`${this.apiRoot}/${account_id}/api/schedules/${schedule_id}`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  remove(schedule_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<Schedule>(`${this.apiRoot}/${account_id}/api/schedules/${schedule_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  query(params?: ListParams): Observable<Schedule[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Schedule[]>(`${this.apiRoot}/${account_id}/api/schedules`, { params: params as QueryParams }),
      ),
    );
  }

  search(params?: ListParams): Observable<Schedule[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Schedule[]>(`${this.apiRoot}/${account_id}/api/schedules/search`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  run(schedule_id: number, body: Partial<Schedule>, params?: ListParams): Observable<Schedule> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Schedule>(`${this.apiRoot}/${account_id}/api/schedules/${schedule_id}/run`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  clone(schedule_id: number, body: Partial<Schedule>, params?: ListParams): Observable<Schedule> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Schedule>(`${this.apiRoot}/${account_id}/api/schedules/${schedule_id}/clone`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }
}
