import { getMatch } from './assisted-search.helpers';

export const AssistedSearchThumbsListSource = {
  serialize(model) {
    return model && model.value;
  },

  deserialize(label, list) {
    if (list) {
      return list.filter((item) => item.value === label)[0];
    }
    return label;
  },

  validate(string, list) {
    if (list) {
      const that = this;
      return list.some(function (item) {
        return that.serialize(item) === string;
      });
    }
    return string;
  },

  getHints(string, list) {
    if (list && list.length) {
      const labelMatches = getMatch(string, list, 'label');
      const valueMatches = getMatch(string, list, 'value');

      const matches = labelMatches
        .concat(valueMatches)
        .filter(function (item, index, arr) {
          return arr.indexOf(item) === index;
        })
        .filter(function (item) {
          return item.value !== string;
        })
        .sort(function (a, b) {
          return a.value.length - b.value.length;
        });

      if (matches.length) {
        return matches;
      }
    }
    return [];
  },
};
