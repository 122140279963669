import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { Schema } from '../../../package.models';

@Component({
  selector: 'group-by-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['field_name']"
      duplicationValidationProp="field_name"
    >
      <ng-template templateName="field_name" let-item>
        <xp-field-picker
          [value]="item.record.field_name"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [index]="item.index"
          [isOutsideDuplicateError]="item.record.isOutsideDuplicateError"
          [outsideValidationMessage]="((collectionSettings.outsideSourceValidation || {}).unique || {}).message"
          propName="field_name"
          (fieldChange)="onFieldChange($event, item.record, 'field_name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="left-header" let-item>
        {{ 'fields-collection.headers.field-picker' | translate }}
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class GroupByCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: Partial<CollectionSettings>;
  @Input() parentSchemas: Schema[];
  @Input() fieldsName: string;
  @Input() isVisible: boolean;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  defaultCollectionSettings: CollectionSettings = {
    hideSearch: true,
    itemsPerPage: 10,
    emptyRecord: { field_name: '', FC_pristine: true },
  };

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    this.collectionSettings = {
      ...this.defaultCollectionSettings,
      ...(this.collectionSettings || {}),
      parentSchemas: this.parentSchemas,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible && changes.isVisible.currentValue && this.recordsCopy) {
      this.onRecordChange({ records: this.recordsCopy.length ? this.recordsCopy : this.records });
    }

    if (changes.collectionSettings && changes.collectionSettings.currentValue) {
      this.collectionSettings = {
        ...this.defaultCollectionSettings,
        ...(this.collectionSettings || {}),
        parentSchemas: this.parentSchemas,
      };
    }
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { field_name: record.field_name };
    });
  }

  onRecordChange({ records }) {
    const fieldsName = this.fieldsName || 'fields';
    if (this.isVisible) {
      this.recordsCopy = records;
      const fields = records.map(({ field_name }) => ({ field_name }));
      this.store.dispatch(
        updateComponent({
          component: {
            [fieldsName]: fields,
          },
        }),
      );
      this.recordsChange.emit(fields);
    }
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
