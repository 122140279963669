import { Component, EventEmitter, Input, Output } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator, CONDITION_OPERATOR } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';

@Component({
  selector: 'xp-checkbox-editor',
  template: `
    <div class="checkbox-editor" (click)="toggle()">
      <i class="fa" [ngClass]="{ 'fa-square-o': !value, 'fa-check-square': value }"></i>
    </div>
  `,
  providers: [],
})
export class XpCheckboxEditorComponent {
  @Input() value: boolean;
  @Input() index: number;
  @Output() valueChange = new EventEmitter();
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  toggle() {
    this.value = !this.value;

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.valueChange.emit(this.value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
