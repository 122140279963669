import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xplenty-console-shortcuts',
  template: `
    <div id="shortcuts-modal">
      <div class="modal-header">
        <h3 class="title">Keyboard Shortcuts</h3>
      </div>
      <div class="modal-body">
        <div id="help-shortcuts-content">
          <div class="shortcut"><kbd>Enter</kbd></div>
          Evaluate the entered command.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>G</kbd>
          </div>
          Open functions list.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>S</kbd>
          </div>
          Open keyboard shortcuts preview.<br />
          <div class="shortcut"><kbd>Up</kbd></div>
          Previous history item.<br />
          <div class="shortcut"><kbd>Down</kbd></div>
          Next history item.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>Up</kbd>
          </div>
          Move to the line above the cursor.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>Down</kbd>
          </div>
          Move to the line below the cursor.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>E</kbd>
          </div>
          Move to the end of the current line.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>A</kbd>
          </div>
          Move to the start of the current line.<br />
          <div class="shortcut"><kbd>Shift<em>+</em>Enter</kbd></div>
          Continue to the next line.<br />
          <div class="shortcut"><kbd>Tab</kbd></div>
          Indent.<br />
          <div class="shortcut"><kbd>Shift<em>+</em>Tab</kbd></div>
          Unindent.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>L</kbd>
          </div>
          Clears the console.<br />
          <div class="shortcut">
            <kbd>{{ getMetaKey() }}<em>+</em>Z</kbd>
          </div>
          Cancel current prompt.<br />
        </div>
      </div>
      <footer class="modal-footer">
        <button aria-hidden="true" data-dismiss="modal" class="cancel btn btn-primary" (click)="close()">Close</button>
      </footer>
    </div>
  `,
})
export class XplentyConsoleShortcutsComponent {
  constructor(public dialogRef: MatDialogRef<XplentyConsoleShortcutsComponent>) {}

  // eslint-disable-next-line class-methods-use-this
  getMetaKey() {
    return navigator.userAgent.indexOf('Mac OS X') !== -1 ? 'Cmd' : 'Ctrl';
  }

  close(functionName?: string) {
    this.dialogRef.close(functionName);
  }
}
