import { Component, Input, OnInit } from '@angular/core';
import { VariableTabs } from '../../../common/components/forms/package-variables-editor.component';
import { Job } from '../../jobs.models';
import { JobsResource } from '../../resources/jobs.resource';
import { PackageVariable, PackageVariablesService } from '../../services/package-variables.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

function checkDeserializeFromJson(userVariables: PackageVariable[]): PackageVariable[] {
  return userVariables.map((userVariable) => {
    if (userVariable.value.indexOf('DeserializeFromJson') > -1) {
      try {
        // eslint-disable-next-line no-eval
        return { ...userVariable, value: eval(userVariable.value) };
      } catch (e) {
        return userVariable;
      }
    }
    return userVariable;
  });
}

@Component({
  selector: 'job-variables',
  template: `
    <div class="job-variables job-content modal-form-container">
      <div class="package-variables-editor variables-editor" *ngIf="!areVariablesLoading">
        <div class="package-variables-editor-body">
          <div class="package-variables-editor-tabs-box">
            <div class="package-variables-editor-tabs">
              <div
                [ngClass]="{ active: activeTab === VariableTabs.package }"
                (click)="activeTab = VariableTabs.package"
              >
                <span>{{ 'package-variables-editor.tabs.user' | translate }}</span>
              </div>
              <div
                [ngClass]="{ active: activeTab === VariableTabs.secrets }"
                (click)="activeTab = VariableTabs.secrets"
              >
                <span>{{ 'package-variables-editor.tabs.secrets' | translate }}</span>
              </div>
              <div [ngClass]="{ active: activeTab === VariableTabs.global }" (click)="activeTab = VariableTabs.global">
                <span>{{ 'package-variables-editor.tabs.global' | translate }}</span>
              </div>
              <div [ngClass]="{ active: activeTab === VariableTabs.system }" (click)="activeTab = VariableTabs.system">
                <span>{{ 'package-variables-editor.tabs.system' | translate }}</span>
              </div>
            </div>

            <div class="package-variables-editor-content">
              <div
                class="variables-editor-empty-text"
                *ngIf="activeTab === VariableTabs.package && userVariables && userVariables.length === 0"
              >
                <h4>No package variables were defined during job run.</h4>
              </div>
              <div
                *ngIf="activeTab === VariableTabs.package && userVariables && userVariables.length > 0"
                [ngClass]="{ active: activeTab === VariableTabs.package }"
              >
                <div class="variables-editor variables-editor-wrapper">
                  <div class="variables-editor-headers">
                    <div class="variables-editor-header variables-variable">
                      <span>{{ 'job-view.variables.headers.variable' | translate }} </span>
                    </div>
                    <div class="variables-editor-header variables-expression">
                      <span>{{ 'job-view.variables.headers.expression' | translate }} </span>
                    </div>
                  </div>
                  <div
                    class="variables-editor-row string"
                    *ngFor="let userVariable of userVariables; let index = index"
                  >
                    <div class="variables-editor-row-number">{{ index | xpIndex }}</div>
                    <div class="variables-editor-cell variables-editor-move locked">
                      <button class="btn btn-defaut btn-lg" tabindex="-1" disabled="disabled">
                        <svg width="15px" height="16.5px" viewBox="0 0 15 16.5">
                          <g id="icon-list-lock_1_">
                            <path
                              style="fill: none; stroke: #999999; stroke-width: 2; stroke-miterlimit: 10;"
                              d="M3,5.5C3,3,5,1,7.5,1S12,3,12,5.5"
                            ></path>
                            <path
                              style="fill: #999999;"
                              d="M13.9,5.5H1.1C0.5,5.5,0,6,0,6.6v8.9c0,0.6,0.5,1.1,1.1,1.1h12.9c0.6,0,1.1-0.5,1.1-1.1V6.6 C15,6,14.5,5.5,13.9,5.5z M8,11.4v2.1H7v-2.1c-0.6-0.2-1-0.8-1-1.4c0-0.8,0.7-1.5,1.5-1.5S9,9.2,9,10C9,10.7,8.6,11.2,8,11.4z"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div class="variables-editor-cell-input variables-editor-key variables-editor-cell-padding">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ userVariable.key }}"
                        />
                      </div>
                    </div>
                    <div class="variables-editor-cell-input">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ userVariable.value }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="variables-editor-empty-text"
                *ngIf="activeTab === VariableTabs.secrets && secretVariables.length === 0"
              >
                <h4>No secrets were defined during job run.</h4>
              </div>
              <div
                *ngIf="activeTab === VariableTabs.secrets && secretVariables.length > 0"
                [ngClass]="{ active: activeTab === VariableTabs.secrets }"
              >
                <div class="variables-editor variables-editor-wrapper">
                  <div class="variables-editor-headers">
                    <div class="variables-editor-header variables-variable">
                      <span>{{ 'job-view.variables.headers.variable' | translate }} </span>
                    </div>
                    <div class="variables-editor-header variables-expression">
                      <span>{{ 'job-view.variables.headers.string_value' | translate }} </span>
                    </div>
                  </div>
                  <div
                    class="variables-editor-row string"
                    *ngFor="let secretVariable of secretVariables; let index = index"
                  >
                    <div class="variables-editor-row-number">{{ index | xpIndex }}</div>
                    <div class="variables-editor-cell variables-editor-move locked">
                      <button class="btn btn-defaut btn-lg" tabindex="-1" disabled="disabled">
                        <svg width="15px" height="16.5px" viewBox="0 0 15 16.5">
                          <g id="icon-list-lock_1_">
                            <path
                              style="fill: none; stroke: #999999; stroke-width: 2; stroke-miterlimit: 10;"
                              d="M3,5.5C3,3,5,1,7.5,1S12,3,12,5.5"
                            ></path>
                            <path
                              style="fill: #999999;"
                              d="M13.9,5.5H1.1C0.5,5.5,0,6,0,6.6v8.9c0,0.6,0.5,1.1,1.1,1.1h12.9c0.6,0,1.1-0.5,1.1-1.1V6.6 C15,6,14.5,5.5,13.9,5.5z M8,11.4v2.1H7v-2.1c-0.6-0.2-1-0.8-1-1.4c0-0.8,0.7-1.5,1.5-1.5S9,9.2,9,10C9,10.7,8.6,11.2,8,11.4z"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div class="variables-editor-cell-input variables-editor-key variables-editor-cell-padding">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ secretVariable.key }}"
                        />
                      </div>
                    </div>
                    <div class="variables-editor-cell-input">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ secretVariable.value }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="variables-editor-empty-text"
                *ngIf="activeTab === VariableTabs.global && globalVariables.length === 0"
              >
                <h4>No global variables were defined during job run.</h4>
              </div>
              <div
                *ngIf="activeTab === VariableTabs.global && globalVariables.length > 0"
                [ngClass]="{ active: activeTab === VariableTabs.global }"
              >
                <div class="variables-editor variables-editor-wrapper">
                  <div class="variables-editor-headers">
                    <div class="variables-editor-header variables-variable">
                      <span>{{ 'job-view.variables.headers.variable' | translate }} </span>
                    </div>
                    <div class="variables-editor-header variables-expression">
                      <span>{{ 'job-view.variables.headers.expression' | translate }} </span>
                    </div>
                  </div>
                  <div
                    class="variables-editor-row string"
                    *ngFor="let globalVariable of globalVariables; let index = index"
                  >
                    <div class="variables-editor-row-number">{{ index | xpIndex }}</div>
                    <div class="variables-editor-cell variables-editor-move locked">
                      <button class="btn btn-defaut btn-lg" tabindex="-1" disabled="disabled">
                        <svg width="15px" height="16.5px" viewBox="0 0 15 16.5">
                          <g id="icon-list-lock_1_">
                            <path
                              style="fill: none; stroke: #999999; stroke-width: 2; stroke-miterlimit: 10;"
                              d="M3,5.5C3,3,5,1,7.5,1S12,3,12,5.5"
                            ></path>
                            <path
                              style="fill: #999999;"
                              d="M13.9,5.5H1.1C0.5,5.5,0,6,0,6.6v8.9c0,0.6,0.5,1.1,1.1,1.1h12.9c0.6,0,1.1-0.5,1.1-1.1V6.6 C15,6,14.5,5.5,13.9,5.5z M8,11.4v2.1H7v-2.1c-0.6-0.2-1-0.8-1-1.4c0-0.8,0.7-1.5,1.5-1.5S9,9.2,9,10C9,10.7,8.6,11.2,8,11.4z"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div class="variables-editor-cell-input variables-editor-key variables-editor-cell-padding">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ globalVariable.key }}"
                        />
                      </div>
                    </div>
                    <div class="variables-editor-cell-input">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ globalVariable.value }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="activeTab === VariableTabs.system" [ngClass]="{ active: activeTab === VariableTabs.system }">
                <div class="variables-editor variables-editor-wrapper">
                  <div class="variables-editor-headers">
                    <div class="variables-editor-header variables-variable">
                      <span>{{ 'job-view.variables.headers.variable' | translate }} </span>
                    </div>
                    <div class="variables-editor-header variables-expression">
                      <span>{{ 'job-view.variables.headers.expression' | translate }} </span>
                    </div>
                  </div>
                  <div
                    class="variables-editor-row string"
                    *ngFor="let systemVariable of systemVariables; let index = index"
                  >
                    <div class="variables-editor-row-number">{{ index | xpIndex }}</div>
                    <div class="variables-editor-cell variables-editor-move locked">
                      <button class="btn btn-defaut btn-lg" tabindex="-1" disabled="disabled">
                        <svg width="15px" height="16.5px" viewBox="0 0 15 16.5">
                          <g id="icon-list-lock_1_">
                            <path
                              style="fill: none; stroke: #999999; stroke-width: 2; stroke-miterlimit: 10;"
                              d="M3,5.5C3,3,5,1,7.5,1S12,3,12,5.5"
                            ></path>
                            <path
                              style="fill: #999999;"
                              d="M13.9,5.5H1.1C0.5,5.5,0,6,0,6.6v8.9c0,0.6,0.5,1.1,1.1,1.1h12.9c0.6,0,1.1-0.5,1.1-1.1V6.6 C15,6,14.5,5.5,13.9,5.5z M8,11.4v2.1H7v-2.1c-0.6-0.2-1-0.8-1-1.4c0-0.8,0.7-1.5,1.5-1.5S9,9.2,9,10C9,10.7,8.6,11.2,8,11.4z"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div class="variables-editor-cell-input variables-editor-key variables-editor-cell-padding">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ systemVariable.key }}"
                        />
                      </div>
                    </div>
                    <div class="variables-editor-cell-input">
                      <div class="cell-container">
                        <input
                          type="text"
                          class="form-control input-lg"
                          readonly="readonly"
                          value="{{ systemVariable.value }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <xp-loader *ngIf="areVariablesLoading"></xp-loader>
    </div>
  `,
})
export class JobVariablesComponent implements OnInit {
  @Input() item: Job;

  userVariables: PackageVariable[];
  secretVariables: PackageVariable[];
  systemVariables: PackageVariable[];
  globalVariables: PackageVariable[];
  areVariablesLoading = true;
  VariableTabs = VariableTabs;
  activeTab: VariableTabs = VariableTabs.package;

  constructor(
    private jobsResource: JobsResource,
    private packageVariablesService: PackageVariablesService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.jobsResource.variables(this.item.id).subscribe({
      next: (variables) => {
        const secretVariables = this.item.package.secret_variables;
        Object.keys(this.item.variables || {})
          .filter((key) => Object.keys(secretVariables).includes(key))
          .forEach((key) => {
            secretVariables[key] = this.item.variables[key];
          });

        const packageVariables = Object.keys(variables)
          .filter((key) => !Object.keys(secretVariables).includes(key))
          .reduce((obj, key) => ({ ...obj, [key]: variables[key] }), {});
        this.packageVariablesService
          .separateVariables(packageVariables, true, secretVariables)
          .subscribe((variablesObject) => {
            this.userVariables = checkDeserializeFromJson(variablesObject.userVariables);
            this.systemVariables = variablesObject.systemVariables;
            this.globalVariables = variablesObject.globalVariables;
            this.secretVariables = variablesObject.secretVariables;
          });
        this.areVariablesLoading = false;
      },
      error: () => {
        this.areVariablesLoading = false;
      },
    });
  }
}
