import { createReducer, on } from '@ngrx/store';
import { Cluster, ClusterStatuses } from '../clusters.models';
import {
  closeClustersModal,
  getClustersList,
  getClustersListError,
  getClustersListResponse,
  loadMoreClustersList,
  loadMoreClustersListResponse,
  openClustersModal,
  removeCluster,
  removeClusterItem,
  removeClusterResponse,
  saveCluster,
  saveClusterError,
  saveClusterResponse,
  searchClustersList,
  searchClustersListResponse,
  updateCluster,
  updateClusterError,
  updateClusterResponse,
} from './clusters.actions';
import { ValidationError } from '../../config/validation-error.model';
import { closeAllModals } from '../../account/store/account.actions';
import { QueryParamsClustersList } from '../../common/helper/query-params-generic-list.helper';

export const initialState: ClustersState = {
  items: [],
  lastlyCreatedCluster: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  errors: [],
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface ClustersState {
  items: Cluster[];
  lastlyCreatedCluster: Cluster;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  errors: ValidationError[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const clustersReducer = createReducer<ClustersState>(
  initialState,

  on(openClustersModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeClustersModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(getClustersList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getClustersListResponse, (state, { clusters }) => ({
    ...state,
    isLoading: false,
    items: clusters,
    areAllItemsLoaded: clusters.length < QueryParamsClustersList.limit,
  })),
  on(getClustersListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(searchClustersList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(searchClustersListResponse, (state, { clusters }) => ({
    ...state,
    isLoading: false,
    items: clusters,
    areAllItemsLoaded: clusters.length < QueryParamsClustersList.limit,
  })),

  on(loadMoreClustersList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreClustersListResponse, (state, { clusters }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...clusters],
    areAllItemsLoaded: clusters.length < QueryParamsClustersList.limit,
  })),

  on(saveCluster, (state) => ({ ...state, isFormSubmitting: true, lastlyCreatedCluster: null })),
  on(saveClusterResponse, (state, { data, closeModal }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: closeModal ? false : state.isModalOpen,
    lastlyCreatedCluster: data,
  })),
  on(saveClusterError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(updateCluster, (state) => ({ ...state, isFormSubmitting: true, lastlyCreatedCluster: null })),
  on(updateClusterResponse, (state, { data, closeModal }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.map((item) => (item.id === data.id ? data : item)),
    lastlyCreatedCluster:
      state.lastlyCreatedCluster?.id === data.id &&
      [ClusterStatuses.pending_terminate, ClusterStatuses.terminating, ClusterStatuses.terminated].includes(data.status)
        ? null
        : state.lastlyCreatedCluster,
    isModalOpen: closeModal ? false : state.isModalOpen,
  })),
  on(updateClusterError, (state, { errors }) => ({
    ...state,
    isFormSubmitting: false,
    errors,
  })),

  on(removeCluster, (state, { clusterId }) => ({
    ...state,
    items: state.items.map((item) =>
      item.id === clusterId ? { ...item, status: ClusterStatuses.pending_terminate } : item,
    ),
    lastlyCreatedCluster: null,
  })),
  on(removeClusterItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeClusterResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
