import { ValidatorRunner } from './models';
import * as VS from '../helpers/validators.helpers';
import { REGEXES } from '../../../constants/regexes';

export const MatchValidator: ValidatorRunner = {
  name: 'MatchValidator',
  message: 'errors.MatchValidator',
  order: 2,
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    if (options.match_selector) {
      const matchValue = options.match_selector;

      return value === matchValue;
    }
    return false;
  },
};
