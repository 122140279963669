function splitValues(values: string): string[] {
  if (values.includes(',')) {
    return values.split(',');
  }
  if (values.includes('-')) {
    return values.split('-');
  }
  return [values];
}
function checkIntegerValue(value: number, down: number, up: number): boolean {
  return /^\d+$/.test(String(value)) && value >= down && value <= up;
}

function parseSplitArrayToInt(value: string, split: string): number[] {
  return value.split(split).map(function (element) {
    return parseInt(element, 10);
  });
}

function checkSequenceValues(value: string, down: number, up: number): boolean {
  let result = true;
  const parsed_value = parseSplitArrayToInt(value, ',');
  parsed_value.forEach(function (element) {
    result = result && checkIntegerValue(element, down, up);
  });
  return result;
}

function checkRangeValues(value: string, down: number, up: number): boolean {
  const parsed_value = parseSplitArrayToInt(value, '-');
  return checkIntegerValue(parsed_value[0], down, up) && checkIntegerValue(parsed_value[1], down, up);
}

function checkPeriodValues(value: string, down: number, up: number): boolean {
  const parsed_value = value.split('/');
  return (
    (checkIntegerValue(parseInt(parsed_value[0], 10), down, up) || parsed_value[0] === '*') &&
    checkIntegerValue(parseInt(parsed_value[1], 10), down, up)
  );
}

function validateWeekDaysValues(value: string): boolean {
  const daysNames = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN', '*'];
  const daysNumbers = ['1', '2', '3', '4', '5', '6', '7', '*'];

  if (value.length > 0) {
    let result = true;
    value.split(/,|-/).forEach(function (element) {
      result = result && (daysNumbers.includes(element) || daysNames.includes(element));
    });
    return result;
  }
  return false;
}

function validateMonthDaysValues(days: string, months: string): boolean {
  const monthDayHash = {
    JAN: 31,
    FEB: 29,
    MAR: 31,
    APR: 30,
    MAY: 31,
    JUN: 30,
    JUL: 31,
    AUG: 31,
    SEP: 30,
    OCT: 31,
    NOV: 30,
    DEC: 31,
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
    '*': 31,
  };

  if (months.length > 0 && days.length > 0) {
    let result = true;
    splitValues(months).forEach(function (month) {
      splitValues(days).forEach(function (day) {
        result = result && ((parseInt(day, 10) > 0 && day <= monthDayHash[month]) || day === '*' || day === 'L');
      });
    });
    return result;
  }
  return false;
}

function validateValue(value: string, down: number, up: number): boolean {
  if (!Number.isNaN(parseInt(value, 10)) && value.match(/^[0-9]+$/) != null) {
    return checkIntegerValue(parseInt(value, 10), down, up);
  }
  if (value.toString().includes('-')) {
    return checkRangeValues(value, down, up);
  }
  if (value.toString().includes(',')) {
    return checkSequenceValues(value, down, up);
  }
  if (value.toString().includes('/')) {
    return checkPeriodValues(value, down, up);
  }
  return value === '*';
}

// validating on months and week days is provided by cronstrue
export function validateCronValues(parsed_expression: string[]): boolean {
  const validateMinutes = validateValue(parsed_expression[0], 0, 59);
  const validateHours = validateValue(parsed_expression[1], 0, 23);
  const validateDays = validateValue(parsed_expression[2], 1, 31) || parsed_expression[2] === 'L';
  const validateMonthDays = validateMonthDaysValues(parsed_expression[2], parsed_expression[3]);
  const validateWeekDays = validateWeekDaysValues(parsed_expression[4]);

  return validateMinutes && validateHours && validateDays && validateMonthDays && validateWeekDays;
}
