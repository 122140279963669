import * as VS from '../helpers/validators.helpers';
import { ValidatorRunner } from './models';

const settings = [{ name: 'minimum', fn: VS.hasMinimumLength }];

export const MinLengthValidator: ValidatorRunner = {
  name: 'MinLengthValidator',
  message: 'errors.MinLengthValidator',
  order: 1,
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    // common validator options
    if (VS.isTrue(options.allow_nil) && VS.isNull(value)) return true;
    if (VS.isTrue(options.allow_blank) && VS.isEmpty(value)) return true;

    if (VS.hasNotLength(value)) return false;

    return settings
      .map(function (o) {
        return VS.isUndefined(options[o.name]) || o.fn.call(null, value, options[o.name]);
      })
      .every(function (result) {
        return result;
      });
  },
};
