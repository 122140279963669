import { Component, Input } from '@angular/core';

interface AlertBoxData {
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'alert-box',
  template: `
    <div class="alert-box">
      <div class="alert-box-container">
        <svg class="alert-box-icon" width="44" height="44">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" [attr.href]="'#' + data.icon"></use>
        </svg>
        <h3 class="lead">{{ data.title }}</h3>
        <p>{{ data.description }}</p>
      </div>
    </div>
  `,
})
export class AlertBoxComponent {
  @Input() data: AlertBoxData;
}
