import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable, Subscription } from 'rxjs';
import { AssistedSearchComponent, Token } from './assisted-search.component';

@Component({
  selector: 'assisted-search-popup-date',
  template: `
    <div>
      <input
        class="assisted-search-date-input"
        [matDatepicker]="picker"
        [value]=""
        (dateChange)="onDateChange($event)"
      />
      <mat-datepicker #picker (input)="onDateChange($event)" [opened]="true"></mat-datepicker>
    </div>
  `,
})
export class AssistedSearchPopupDateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() token: Token;
  @Input() cursor: number;
  @Input() keyDownEvents: Observable<any>;
  @Input() keyUpEvents: Observable<any>;
  @Input() clickEvents: Observable<any>;
  @ViewChild('picker') picker: MatDatepicker<any>;

  pickerDate = '';

  private keyDownEventsSubscription: Subscription;
  private keyUpEventsSubscription: Subscription;
  private clickEventsSubscription: Subscription;

  constructor(private assistedSearch: AssistedSearchComponent) {}

  ngOnInit() {
    this.keyDownEventsSubscription = this.keyDownEvents.subscribe(() => this.goDown());
    this.keyUpEventsSubscription = this.keyUpEvents.subscribe(() => this.goUp());
    this.clickEventsSubscription = this.clickEvents.subscribe(() => this.onClick());
  }

  onClick() {
    this.picker.open();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.token && changes.token.currentValue) {
      this.setPickerDate();
    }
  }

  setPickerDate(date?) {
    const newDate = date || this.token.model;
    const currentMoment = this.pickerDate;
    if (!currentMoment || this.pickerDate !== newDate) {
      this.pickerDate = newDate;
    }
  }

  onDateChange(event: MatDatepickerInputEvent<any>) {
    const initialSet = !this.token.model;
    const newDate = event.value;
    const tokenDate = this.token.model;
    const tokenDateHasNoHours =
      tokenDate && tokenDate.hours() === 0 && tokenDate.minutes() === 0 && tokenDate.seconds() === 0;
    const isDateExtendedWithHours = tokenDate && tokenDateHasNoHours && tokenDate.isSame(newDate, 'day');
    if (newDate && !newDate.isSame(tokenDate)) {
      const moveCursor = initialSet || isDateExtendedWithHours;
      this.applyNewDate(newDate, moveCursor, false);
    }
  }

  applyNewDate(date, shouldMoveCursor, appendSpace?) {
    this.assistedSearch.changeTokenModel({
      token: this.token,
      model: date,
      shouldMoveCursor,
      appendSpace,
    });
  }

  getCursorLocationType() {
    const keyLength = this.token.modifier.length;
    if (this.cursor >= keyLength) {
      if (this.cursor < keyLength + 5) {
        return 'year';
      }
      if (this.cursor < keyLength + 8) {
        return 'month';
      }
      if (this.cursor < keyLength + 11) {
        return 'day';
      }
      if (this.cursor < keyLength + 14) {
        return 'hour';
      }
      if (this.cursor < keyLength + 17) {
        return 'minute';
      }
    }
    return '';
  }

  goUp() {
    const date = this.token.model;
    const cursorLocationType = this.getCursorLocationType();
    if (date && cursorLocationType) {
      date.add(1, cursorLocationType);
      this.applyNewDate(date, false);
    }
  }

  goDown() {
    const date = this.token.model;
    const cursorLocationType = this.getCursorLocationType();
    if (date && cursorLocationType) {
      date.subtract(1, cursorLocationType);
      this.applyNewDate(date, false);
    }
  }

  ngOnDestroy() {
    this.keyDownEventsSubscription.unsubscribe();
    this.keyUpEventsSubscription.unsubscribe();
    this.clickEventsSubscription.unsubscribe();
  }
}
