import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  getWorkspacesList,
  getWorkspacesListError,
  getWorkspacesListResponse,
  loadMoreWorkspacesList,
  loadMoreWorkspacesListResponse,
  removeWorkspace,
  removeWorkspaceError,
  removeWorkspaceItem,
  removeWorkspaceResponse,
  saveWorkspace,
  saveWorkspaceError,
  saveWorkspaceResponse,
  searchWorkspacesList,
  searchWorkspacesListResponse,
  updateWorkspace,
  updateWorkspaceError,
  updateWorkspaceResponse,
} from './workspaces.actions';
import { WorkspacesResource } from '../resources/workspaces.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../constants/animation-constants';
import { AppState } from '../../store';
import { NotifyService } from '../../common/services/notify.service';
import { getErrorFromResponse } from '../../common/helper/response.helpers';

@Injectable()
export class WorkspacesEffects {
  getWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkspacesList),
      withLatestFrom(this.store$),
      switchMap(([{ params, isWorkspacesView }, store]) =>
        store.account.info.workspaces_feature_enabled || isWorkspacesView
          ? this.workspacesResource.query(params).pipe(
              map((workspaces) => getWorkspacesListResponse({ workspaces })),
              catchError((response) => {
                this.notify.error(this.translate.instant(`response.${response.status}.message`));
                return of(getWorkspacesListError({ err: getErrorFromResponse(response) }));
              }),
            )
          : of(getWorkspacesListResponse({ workspaces: [] })),
      ),
    ),
  );

  searchWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchWorkspacesList),
      switchMap(({ params }) =>
        this.workspacesResource.search(params).pipe(
          map((workspaces) => searchWorkspacesListResponse({ workspaces })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getWorkspacesListError({ err: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreWorkspacesList),
      switchMap(({ params }) =>
        (params.q ? this.workspacesResource.search(params) : this.workspacesResource.query(params)).pipe(
          map((workspaces) => loadMoreWorkspacesListResponse({ workspaces, limit: params.limit })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getWorkspacesListError({ err: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveWorkspace),
      switchMap(({ workspace }) =>
        this.workspacesResource.save(workspace, { include: 'packages' } as any).pipe(
          map((workspaceResponse) => saveWorkspaceResponse({ data: workspaceResponse, closeModal: true })),
          catchError((response) => of(saveWorkspaceError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  updateWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWorkspace),
      switchMap(({ workspace, params, workspaceId }) =>
        this.workspacesResource.update(workspaceId, workspace, { ...params, include: 'packages' } as any).pipe(
          map((workspaceResponse) => updateWorkspaceResponse({ data: workspaceResponse })),
          catchError((response) => of(updateWorkspaceError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeWorkspace),
      switchMap(({ workspaceId }) =>
        this.workspacesResource.remove(workspaceId).pipe(
          map((workspaceResponse) => removeWorkspaceItem({ data: workspaceResponse })),
          catchError((response) => of(removeWorkspaceError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeWorkspaceResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeWorkspaceItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeWorkspaceResponse({ data })),
    ),
  );

  constructor(
    private actions$: Actions,
    private workspacesResource: WorkspacesResource,
    private store$: Store<AppState>,
    private notify: NotifyService,
    private translate: TranslateService,
  ) {}
}
