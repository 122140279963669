import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  listeners = [];

  init() {
    document.addEventListener('visibilitychange', () => {
      this.listeners.forEach((l) => {
        l.fn.call(null, document.hidden ? 'hidden' : 'visible');
      });
    });
  }

  onChange(fn) {
    const id = Math.random().toString(36).substring(7);
    this.listeners.push({
      id,
      fn,
    });

    return id;
  }

  // eslint-disable-next-line class-methods-use-this
  currentState(): boolean {
    return document.hidden;
  }
}
