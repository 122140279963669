import { AssistedSearchToken } from './assisted-search-token.helper';

export const assistedSearchUtils = {
  KEYS: {
    ESC: 27,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    ENTER: 13,
    TAB: 9,
  },

  getTokensString: function getTokensString(tokens) {
    return tokens.reduce((acc, token) => acc + token.fullText, '');
  },

  getTokenEndCursorPos: function getTokenEndCursorPos(tokens, token) {
    let sum = 0;
    let t;
    for (let i = 0; i < tokens.length; i += 1) {
      t = tokens[i];
      sum += t.length;
      if (t === token) {
        break;
      }
    }
    return sum;
  },

  sanitizeTokens: function sanitizeTokens(tokens) {
    return tokens.reduce(function (acc, token) {
      const t = this.sanitizeToken(token);
      if (t) {
        if (acc[t.modifier]) {
          acc[t.modifier].push(t);
        } else {
          acc[t.modifier] = [t];
        }
      }
      return acc;
    }, {});
  },

  sanitizeToken: function sanitizeToken(token) {
    if (token) {
      const { type } = token;
      if (type !== 'space' && type !== 'default') {
        return {
          model: token.model,
          fullText: token.fullText,
          modifier: token.modifier,
          value: token.value,
        };
      }
    }
    return null;
  },

  getDefaultContent: function getDefaultContent(configHash, modifiersList) {
    let key;
    let val;
    let list;
    let allList = [];

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (key in configHash) {
      val = configHash[key];
      if (val.type === 'list' && val.content) {
        // eslint-disable-next-line no-loop-func
        list = val.content.map(function (item) {
          return {
            label: item.label,
            value: key + item.value,
            fullText: true,
            section: val.sectionTitle,
          };
        });
        allList = allList.concat(list);
      }
    }
    const modifiers = modifiersList.map((item) => ({ ...item, section: 'modifiers' }));
    return allList.concat(modifiers);
  },

  getAllModifiers: function getAllModifiers(configHash) {
    const modifiers = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in configHash) {
      const config = configHash[key];
      const section = config.type === 'date' ? 'time' : 'others';
      modifiers.push({
        value: key,
        label: config.defaultHint,
        modifier: true,
        section,
      });
    }
    return modifiers;
  },

  tokenize: function tokenize(text, configHash) {
    const tokens = [];
    let mem = '';
    let i = 0;
    let isQuote = '';

    for (i = 0; i <= text.length; i += 1) {
      const character = text[i];
      if (new RegExp(/['"`]/).test(character)) {
        isQuote = isQuote ? '' : character;
      }

      if ((character === ' ' && !isQuote) || !character) {
        if (mem) {
          tokens.push(AssistedSearchToken.create({ configHash, fullText: mem }));
        }
        if (character) {
          tokens.push(AssistedSearchToken.create({ configHash, fullText: ' ' }));
        }
        mem = '';
      } else if (character !== ' ' || (character === ' ' && isQuote)) {
        mem += character;
      }
    }
    return tokens;
  },

  prepareConfig: function prepareConfig(configHash) {
    const modifiers = this.getAllModifiers(configHash);
    // eslint-disable-next-line no-param-reassign
    configHash['+'] = { type: 'modifier-list', content: modifiers };
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    configHash._default = {
      type: 'default',
      content: this.getDefaultContent(configHash, modifiers),
    };
    return configHash;
  },

  setCursor: function setCursor(node, pos) {
    if (node) {
      // DOM element
      const currentStart = node.selectionStart;
      const currentEnd = node.selectionEnd;
      if (pos < currentStart || pos > currentEnd) {
        if (node.createTextRange) {
          const textRange = node.createTextRange();
          textRange.collapse(true);
          textRange.moveEnd(pos);
          textRange.moveStart(pos);
          textRange.select();
          return true;
        }
        if (node.setSelectionRange) {
          node.setSelectionRange(pos, pos);
          return true;
        }
      }
    }
    return false;
  },
};
