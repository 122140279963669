import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AllComponentData, FilterComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { MATCH_TYPES } from '../../../constants/match_types';

@Component({
  selector: 'filter-editor',
  template: `
    <div class="filter-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::FilterComponent">
        <form name="componentForm" novalidate>
          <div class="row">
            <xp-form-group class="col-md-6">
              <div class="form-group">
                <label for="match_type">{{ 'filter_component.labels.conditions' | translate }}</label>
                <xp-select
                  [value]="rawComponent.predicates.match_type"
                  id="match_type"
                  name="match_type"
                  (valueChange)="setMatchType($event)"
                  [options]="matchTypes"
                  style="white-space: nowrap;"
                  class="form-control xp-select"
                ></xp-select>
              </div>
            </xp-form-group>
          </div>
          <filter-collection
            [records]="rawComponent.predicates.expressions"
            (recordsChange)="onRecordsChange($event)"
            [parentSchemas]="parentSchemas"
            (validityChange)="onValidityChange($event)"
            fieldsName="predicates.expressions"
          ></filter-collection>
        </form>
      </xp-form-validation>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class FilterEditorComponent {
  @Input() rawComponent: FilterComponentData;
  @Input() parentSchemas: Schema[];

  constructor(private store: Store<AppState>) {}

  matchTypes = MATCH_TYPES;

  onRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { predicates: { ...this.rawComponent.predicates, expressions: records } } as AllComponentData,
      }),
    );
    this.store.dispatch(updateComponent({ component: { predicates: { expressions: records } } }));
  }

  onValidityChange(isValid: boolean) {
    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  setMatchType(match_type: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { predicates: { ...this.rawComponent.predicates, match_type } } as AllComponentData,
      }),
    );
    this.store.dispatch(updateComponent({ component: { predicates: { match_type } } }));
  }
}
