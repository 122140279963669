// Searches for a destination field by name in the list of fields ignoring spaces, underscores and hyphens
import { Field } from '../package.models';
import { DesignerSchemaFieldI } from '../models/designer-schema-field.model';

export function findDestinationField(
  fieldName: string,
  fields: Array<Field | DesignerSchemaFieldI>,
): Field | DesignerSchemaFieldI {
  let field = fields.find((item) => item.name === fieldName);

  if (!field) {
    field = fields.find((item) => item.name.toLowerCase() === fieldName.toLowerCase());
  }

  if (!field) {
    field = fields.find(
      (item) => item.name.toLowerCase().replace(/[\s_-]/g, '') === fieldName.toLowerCase().replace(/[\s_-]/g, ''),
    );
  }

  return field;
}

export function mergeFields(recordsCopy: DesignerSchemaFieldI[], schemaFields: Field[]) {
  return [
    ...recordsCopy,
    ...schemaFields.filter((field) => !recordsCopy.find((item) => item.field_name === field.name)),
  ];
}
