/* eslint-disable no-param-reassign */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AdwordsSourceComponentData,
  Analytics4SourceComponentData,
  AnalyticsSourceComponentData,
  BingAdsSourceComponentData,
  FacebookAdsInsightsSourceComponentData,
} from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { ACCOUNTS_CAMPAIGNS_SELECTOR_LIST_TYPE } from '../../../constants/accounts_campaigns_selector_list_type';
import {
  AccountsCampaignsSelectorSettings,
  getSettings,
} from '../../helpers/accounts-campaigns-selector-settings.helper';
import { AccountsCampaignsSelectorDataAdapterService } from '../../services/accounts-campaigns-selector-data-adapter.service';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { Subscription } from 'rxjs';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

export type AccountsCampaignsSelectorComponentData =
  | AdwordsSourceComponentData
  | BingAdsSourceComponentData
  | FacebookAdsInsightsSourceComponentData
  | AnalyticsSourceComponentData
  | Analytics4SourceComponentData;

@Component({
  selector: 'accounts-campaigns-selector',
  template: `
    <div class="accounts-campaigns-selector">
      <div class="row" *ngFor="let error of errors">
        <div class="col-md-12">
          <div class="alert alert-warning">
            <button type="button" class="close" (click)="removeError(error)">
              <span aria-hidden="true">&times;</span>
            </button>
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="accounts-campaigns-selector-label">{{ settings.availableAccountsLabel }}</label>
          <div class="btn-group btn-group-xs pull-right">
            <button type="button" class="btn btn-default" (click)="loadAccounts()" [disabled]="accountsLoading">
              <i class="fa fa-refresh" [ngClass]="{ 'fa-spin fa-fw': accountsLoading }"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              (click)="setAllSelection(true)"
              [disabled]="available.length === 0 || accountsLoading"
            >
              Select all
            </button>
          </div>
          <accounts-campaigns-selector-list
            [component]="component"
            [accounts]="available"
            (selectAccount)="selectAccount($event)"
            (selectCampaign)="selectCampaign($event)"
            [type]="LIST_TYPE.AVAILABLE"
            [componentType]="component.componentType"
            (collapseAccount)="collapseAccount($event)"
            (collapseCampaign)="collapseCampaign($event)"
            (collapseView)="collapseView($event)"
            [accountsLoading]="accountsLoading"
          ></accounts-campaigns-selector-list>
        </div>
        <div class="col-md-6">
          <label class="accounts-campaigns-selector-label">{{ settings.selectedAccountsLabel }}</label>
          <div class="btn-group btn-group-xs pull-right">
            <button
              type="button"
              class="btn btn-default"
              (click)="setAllSelection(false)"
              *ngIf="settings.allowRemoveAll"
              [disabled]="selected.length === 0"
            >
              Remove all
            </button>
          </div>
          <accounts-campaigns-selector-list
            [component]="component"
            [accounts]="selected"
            (selectAccount)="selectAccount($event)"
            (selectCampaign)="selectCampaign($event)"
            [type]="LIST_TYPE.SELECTED"
            [componentType]="component.componentType"
            (collapseAccount)="collapseAccount($event)"
            [accountsLoading]="accountsLoading"
          ></accounts-campaigns-selector-list>
        </div>
      </div>
    </div>
  `,
})
export class AccountsCampaignsSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rawComponent: AccountsCampaignsSelectorComponentData;
  @Input() component: ComponentTypeItem;
  @Input() hasComponentChanged: number;
  @Input() connection: number;
  @Output() componentChange = new EventEmitter<any>();

  errors = [];
  accountsLoading = false;
  available = [];
  LIST_TYPE = ACCOUNTS_CAMPAIGNS_SELECTOR_LIST_TYPE;
  settings: Partial<AccountsCampaignsSelectorSettings> = {};
  accountsToPopulate = [];
  selected = [];
  valueChangedSubscription: Subscription;
  loadAccountsSubscription: Subscription;
  loadAccountCampaignsSubscription: Subscription;
  accountsCampaignsSelectorDataAdapterService: AccountsCampaignsSelectorDataAdapterService;

  constructor(private connectionItemsResource: ConnectionItemsResource, private store: Store<AppState>) {
    this.accountsCampaignsSelectorDataAdapterService = new AccountsCampaignsSelectorDataAdapterService(
      connectionItemsResource,
      store,
    );
  }

  ngOnInit() {
    this.settings = getSettings(this.component);

    this.valueChangedSubscription = this.accountsCampaignsSelectorDataAdapterService.valueChanged$.subscribe(
      (component) => {
        this.componentChange.emit(component);
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connection?.currentValue !== changes.connection?.previousValue || changes.connection?.firstChange) {
      const newConnection = changes.connection?.currentValue;
      const oldConnection = changes.connection?.previousValue;
      if (!oldConnection?.type || oldConnection?.id === newConnection.id) {
        this.clear(false);
      } else {
        this.clear(true);
      }
      this.loadAccounts();
    }

    if (
      changes.rawComponent?.currentValue?.connection !== changes.rawComponent?.previousValue?.connection ||
      changes.rawComponent?.firstChange
    ) {
      const newConnection = changes.rawComponent?.currentValue?.connection;
      const oldConnection = changes.rawComponent?.previousValue?.connection;
      if (!oldConnection?.type || oldConnection?.id === newConnection.id) {
        this.clear(false);
      } else {
        this.clear(true);
      }
      this.loadAccounts();
    }
  }

  loadAccounts() {
    this.accountsLoading = true;
    this.errors = [];
    this.loadAccountsSubscription = this.accountsCampaignsSelectorDataAdapterService
      .load(this.component, this.rawComponent, this.accountsToPopulate)
      .subscribe({
        next: (adaptedData) => {
          this.available = adaptedData.available;
          this.errors = adaptedData.errors;
          this.accountsLoading = false;
          this.initSelected(false, true);
        },

        error: (res) => {
          if (res.error) {
            this.errors = [res.error.message || res.error.error_message];
            this.accountsLoading = false;
          }
        },
      });
  }

  clear(clearSavedAccounts: boolean) {
    this.accountsToPopulate = [];
    this.available = [];
    this.selected = [];
    this.errors = [];
    if (clearSavedAccounts) {
      this.accountsCampaignsSelectorDataAdapterService.clear(this.component, this.rawComponent);
    }
  }

  initSelected(removeSelection?: boolean, isInitialLoad?: boolean) {
    const selected = [];

    if (!this.shouldSelectChildren) {
      this.available.forEach((account) => {
        const isAccountSelected = account.selected;
        if (isAccountSelected) {
          const newAccount = { ...account };
          newAccount.campaigns = [];
          newAccount.collapsed = false;

          selected.push(newAccount);
        }

        (account.campaigns || []).forEach((campaign) => {
          if (campaign.selected) {
            const newCampaign = { ...campaign };
            newCampaign.collapsed = false;
            newCampaign.views = [];

            selected.push(newCampaign);
          }

          (campaign.views || []).forEach((view) => {
            if (view.selected) {
              const newView = { ...view };
              newView.collapsed = false;
              newView.subViews = [];

              selected.push(newView);
            }

            (view.subViews || []).forEach((subView) => {
              if (subView.selected) {
                const newSubView = { ...subView };

                selected.push(newSubView);
              }
            });
          });
        });
      });

      const adaptedData: any = {};
      this.selected = selected;
      adaptedData.selected = selected;
      this.accountsCampaignsSelectorDataAdapterService.save(this.component, this.rawComponent, adaptedData);
      return;
    }

    const isAnyItemSelected =
      this.available &&
      this.available.find(
        (account) => account.selected || (account.campaigns && account.campaigns.find((campaign) => campaign.selected)),
      );

    this.available.forEach((account) => {
      const hasUnselectedCampaign = (account.campaigns || []).some((campaign) => !campaign.selected);

      if (hasUnselectedCampaign && account.selected) {
        account.selected = false;
      } else if (
        !hasUnselectedCampaign &&
        this.component.componentType === COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT &&
        !removeSelection &&
        !isAnyItemSelected
      ) {
        account.semiSelected = false;
        account.selected = true;
      }
      if (account.selected) {
        account.semiSelected = false;
        const accountClone = { ...account };
        const previousItem = this.selected.find((item) => accountClone.id === item.id);
        if (previousItem) {
          accountClone.collapsed = previousItem.collapsed;
        }
        selected.push(accountClone);
        this.accountsToPopulate.push(account.id);
      } else {
        const hasSelectedCampaign = (account.campaigns || []).some(
          (campaign) => campaign.selected || campaign.semiSelected,
        );
        if (hasSelectedCampaign) {
          account.semiSelected = this.shouldSelectChildren;
          if (isInitialLoad) {
            account.collapsed = false;
          }
          const accountClone = { ...account };
          accountClone.campaigns = [];
          const previousItem = this.selected.find((item) => accountClone.id === item.id);
          if (previousItem) {
            accountClone.collapsed = previousItem.collapsed;
          }
          selected.push(accountClone);
          (account.campaigns || []).forEach((campaign) => {
            if (campaign.selected) {
              accountClone.campaigns.push({ ...campaign });
            } else if (campaign.semiSelected) {
              const newCampaign = { ...campaign };
              newCampaign.views = [];
              (campaign.views || []).forEach((view) => {
                if (view.selected) {
                  newCampaign.views.push({ ...view });
                } else if (view.semiSelected) {
                  const newView = { ...view };
                  newView.subViews = [];
                  (view.subViews || []).forEach((subView) => {
                    if (subView.selected) {
                      newView.subViews.push({ ...subView });
                    }
                  });
                  newCampaign.views.push({ ...newView });
                }
              });
              accountClone.campaigns.push({ ...newCampaign });
            }
          });
        } else {
          account.selected = false;
          account.semiSelected = false;
        }
      }
    });
    const adaptedData: any = {};
    this.selected = selected;
    adaptedData.selected = selected;
    this.accountsCampaignsSelectorDataAdapterService.save(this.component, this.rawComponent, adaptedData);
  }

  loadAccountCampaigns(account) {
    account.loading = true;

    this.loadAccountCampaignsSubscription = this.accountsCampaignsSelectorDataAdapterService
      .loadAccountCampaigns(this.rawComponent, account)
      .subscribe({
        next: (campaigns) => {
          campaigns.forEach((campaign) => {
            campaign.selected = account.selected || account.semiSelected;
          });
          if (campaigns.length === 0 && account.semiSelected) {
            account.semiSelected = false;
            account.selected = true;
          }
          account.campaigns = campaigns;
          account.collapsed = false;
          account.loaded = true;
          account.loading = false;
          this.initSelected();
        },
        error: (res) => {
          if (res.error) {
            this.errors.push(res.error.message || res.error.error_message);
          }
        },
      });
  }

  setAllSelection(selection) {
    if (selection) {
      this.available.forEach((account) => {
        if (account.semiSelected) {
          this.selectAccount(account);
        } // fixes a semiSelect bug by unselecting and reselecting the account
        if (!account.selected) {
          this.selectAccount(account);
        }
      });
      this.initSelected();
    } else {
      this.available.forEach((account) => {
        account.selected = false;
        (account.campaigns || []).forEach((campaign) => {
          campaign.selected = false;
          if (campaign.semiSelected) campaign.semiSelected = false;
          (campaign.views || []).forEach((view) => {
            view.selected = false;
          });
        });
      });
      this.initSelected(true);
    }
  }

  selectAccount(_account) {
    const account = this.available.find((item) => item.id === _account.id);

    if (!this.shouldSelectChildren) {
      if (account) {
        account.selected = !account.selected;
        this.initSelected(true);
      } else {
        this.unselectItem(_account.id);
      }
      return;
    }

    if (!account.selected && !account.semiSelected) {
      account.selected = true;
    } else if (
      account.selected &&
      !account.semiSelected &&
      this.component.componentType !== COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT
    ) {
      account.semiSelected = true;
      account.collapsed = false;
      account.selected = false;
    } else {
      account.selected = false;
      account.semiSelected = false;
      (account.campaigns || []).forEach((campaign) => {
        campaign.selected = false;
        if (campaign.semiSelected) campaign.semiSelected = false;
        (campaign.views || []).forEach((view) => {
          view.selected = false;
        });
      });
    }

    if ((account.selected || account.semiSelected) && account.campaigns) {
      if (account.campaigns.length === 0 && account.semiSelected) {
        account.semiSelected = false;
        account.selected = false;
      }
      (account.campaigns || []).forEach((campaign) => {
        campaign.selected = true;
        (campaign.views || []).forEach((view) => {
          view.selected = true;
        });
      });
    }

    this.initSelected(true);
  }

  unselectItem(id) {
    this.available.forEach((account) => {
      if (account.id === id) {
        account.selected = false;
        return;
      }

      (account.campaigns || []).forEach((campaign) => {
        if (campaign.id === id) {
          campaign.selected = false;
          return;
        }

        (campaign.views || []).forEach((view) => {
          if (view.id === id) {
            view.selected = false;
            return;
          }

          (view.subViews || []).forEach((subView) => {
            if (subView.id === id) {
              subView.selected = false;
            }
          });
        });
      });
    });
    this.initSelected(true);
  }

  selectCampaign({ account: _account, campaign: _campaign, view: _view, subView: _subView }) {
    const account = this.available.find((item) => item.id === _account.id);
    const campaign = account.campaigns.find((item) => item.id === _campaign.id);
    if (!_view) {
      if (campaign.semiSelected) {
        campaign.semiSelected = false;
        campaign.selected = false;
      } else {
        campaign.selected = !campaign.selected;
      }
      if (this.shouldSelectChildren) {
        (campaign.views || []).forEach((view) => {
          view.selected = campaign.selected;
        });
      }
    } else {
      const view = campaign.views.find((item) => item.id === _view.id);
      view.selected = !view.selected;

      if (this.shouldSelectChildren) {
        campaign.selected = (campaign.views || []).every((item) => item.selected);

        if (!campaign.selected) {
          campaign.semiSelected = (campaign.views || []).some((item) => item.selected);
        } else {
          campaign.semiSelected = false;
        }
      }

      if (_subView) {
        const subView = view.subViews.find((item) => item.id === _subView.id);
        subView.selected = !subView.selected;

        if (this.shouldSelectChildren) {
          view.selected = (view.subViews || []).every((item) => item.selected);
          if (!view.selected) {
            view.semiSelected = (view.subViews || []).some((item) => item.selected);
          } else {
            view.semiSelected = false;
          }
        }
      }
    }

    this.initSelected(true);
  }

  // eslint-disable-next-line class-methods-use-this
  collapseAccount(account) {
    account.collapsed = !account.collapsed;

    if (
      !account.loaded &&
      this.component.componentType !== COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT &&
      this.component.componentType !== COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT
    ) {
      this.loadAccountCampaigns(account);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  collapseCampaign(campaign) {
    campaign.collapsed = !campaign.collapsed;
  }

  // eslint-disable-next-line class-methods-use-this
  collapseView(view) {
    view.collapsed = !view.collapsed;
  }

  removeError(error) {
    const index = this.errors.indexOf(error);
    if (index > -1) {
      this.errors.splice(index, 1);
    }
  }

  get shouldSelectChildren(): boolean {
    return this.component.componentType !== COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT;
  }

  ngOnDestroy(): void {
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
    if (this.loadAccountsSubscription) {
      this.loadAccountsSubscription.unsubscribe();
    }
    if (this.loadAccountCampaignsSubscription) {
      this.loadAccountCampaignsSubscription.unsubscribe();
    }

    this.accountsCampaignsSelectorDataAdapterService.complete();
  }
}
