import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateModule } from '@ngx-translate/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EffectsModule } from '@ngrx/effects';
import { combineReducers, StoreModule } from '@ngrx/store';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { SettingsProfileComponent } from './components/settings-profile.component';
import { CommonModule } from '../common/common.module';
import { SettingsPasswordComponent } from './components/settings-password.component';
import { SettingsMfaComponent } from './components/settings-mfa.component';
import { SettingsNotificationsComponent } from './components/settings-notifications.component';
import { SettingsTokensComponent } from './components/settings-tokens.component';
import { AccountSettingsInfoComponent } from './components/account-settings-info.component';
import { AccountSettingsBillingComponent } from './components/account-settings-billing.component';
import { AccountSettingsUsageComponent } from './components/account-settings-usage.component';
import { RolePickerComponent } from './components/role-picker.component';
import { MemberListItemComponent } from './components/members/member-list-item.component';
import { MemberFormComponent } from './components/members/member-form.component';
import { MembersItemComponent } from './components/members/members-item.component';
import { MembersListComponent } from './components/members/members-list.component';
import { membersReducer } from './store/members/members.reducer';
import { MembersEffects } from './store/members/members.effects';
import { HookListItemComponent } from './components/hooks/hook-list-item.component';
import { HooksListComponent } from './components/hooks/hooks-list.component';
import { hooksReducer } from './store/hooks/hooks.reducer';
import { HookFormComponent } from './components/hooks/hook-form.component';
import { HooksEffects } from './store/hooks/hooks.effects';
import { HookEventsEditorComponent } from './components/hooks/hook-events-editor.component';
import { HookFormEmailComponent } from './components/hooks/hook-form-email.component';
import { HookFormWebComponent } from './components/hooks/hook-form-web.component';
import { HookFormPagerDutyComponent } from './components/hooks/hook-form-pagerduty.component';
import { HookFormSlackComponent } from './components/hooks/hook-form-slack.component';
import { keysReducer } from './store/keys/keys.reducer';
import { KeysEffects } from './store/keys/keys.effects';
import { KeysListComponent } from './components/keys/keys-list.component';
import { KeyFormComponent } from './components/keys/key-form.component';
import { KeyListItemComponent } from './components/keys/key-list-item.component';
import { accountsReducer } from './store/accounts/accounts.reducer';
import { AccountsEffects } from './store/accounts/accounts.effects';
import { AccountsListComponent } from './components/accounts/accounts-list.component';
import { AccountListItemComponent } from './components/accounts/account-list-item.component';
import { AccountFormComponent } from './components/accounts/account-form.component';
import { SettingsComponent } from './settings.component';
import { GlobalVariablesComponent } from './components/global-variables/global-variables.component';
import { MemberEditFormComponent } from './components/members/member-edit-form.component';
import { MemberEditRolesComponent } from './components/members/member-edit-roles.component';

@NgModule({
  declarations: [
    AccountSettingsInfoComponent,
    AccountSettingsBillingComponent,
    AccountSettingsUsageComponent,
    SettingsProfileComponent,
    SettingsPasswordComponent,
    SettingsMfaComponent,
    SettingsNotificationsComponent,
    SettingsTokensComponent,
    RolePickerComponent,
    MemberListItemComponent,
    MembersItemComponent,
    MemberFormComponent,
    MemberEditFormComponent,
    MemberEditRolesComponent,
    MembersListComponent,
    HookListItemComponent,
    HooksListComponent,
    HookFormComponent,
    HookEventsEditorComponent,
    HookFormEmailComponent,
    HookFormWebComponent,
    HookFormPagerDutyComponent,
    HookFormSlackComponent,
    KeysListComponent,
    KeyFormComponent,
    KeyListItemComponent,
    AccountsListComponent,
    AccountListItemComponent,
    AccountFormComponent,
    SettingsComponent,
    GlobalVariablesComponent,
  ],
  exports: [AccountFormComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    NgxPermissionsModule,
    MatTooltipModule,
    StoreModule.forFeature(
      'settings',
      combineReducers({
        members: membersReducer,
        hooks: hooksReducer,
        keys: keysReducer,
        accounts: accountsReducer,
      }),
    ),
    EffectsModule.forFeature([MembersEffects, HooksEffects, KeysEffects, AccountsEffects]),
    MatMenuModule,
    MatSlideToggleModule,
    RouterModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class SettingsModule {}
