import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { NoteComponentData } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentNameFormValidity, setComponentValidity, updateRawComponent } from '../../store/component.actions';

@Component({
  selector: 'note-editor',
  template: `
    <div class="note-editor-body">
      <form name="noteEditorForm">
        <div class="form-group">
          <label for="description">{{ 'note-form.labels.note_description' | translate }}</label>
          <textarea
            id="description"
            name="description"
            class="form-control"
            placeholder="Enter a description..."
            [ngModel]="rawComponent.description"
            (ngModelChange)="onDescriptionChange($event)"
          ></textarea>
        </div>
      </form>
    </div>
  `,
})
export class NoteEditorComponent implements OnInit, OnChanges {
  @Input() rawComponent: NoteComponentData;

  constructor(protected store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rawComponent?.currentValue) {
      if (this.rawComponent.description.includes('<br />')) {
        this.onDescriptionChange(this.rawComponent.description.replaceAll('<br />', '\n'));
      }
    }
  }

  ngOnInit() {
    this.store.dispatch(setComponentNameFormValidity({ isComponentNameFormValid: true }));
    this.store.dispatch(setComponentValidity({ isComponentFormValid: true }));
  }

  onDescriptionChange(description: string) {
    this.store.dispatch(updateRawComponent({ rawComponent: { description } }));
  }
}
