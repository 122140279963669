import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import {
  createEdge,
  createEdgeEndDrag,
  duplicateComponentsAction,
  hoverComponent,
  openComponentsModal,
  removeComponents,
  resizeNoteComponent,
  resizeNoteComponentTemp,
  selectComponents,
  updateComponentPosition,
  updateComponentTempPosition,
  updateEdgeTempPosition,
} from '../../store/package-designer.actions';
import { COMPONENT_CATEGORY, ComponentTypeItem } from '../../../constants/component_types';
import { DESIGNER_SETTINGS } from '../../../constants/designer_settings';
import {
  selectComponentInputEdgesLength,
  selectComponentOutputEdgesLength,
  selectFixedComponentById,
  selectIsDraggingFlag,
  selectTempComponenSizeById,
  selectTempComponentPositionById,
} from '../../store/package-designer.selectors';
import { COMPONENT_TYPE } from '../../../constants/component_type';

@Component({
  selector: 'xp-designer-package-item',
  template: `
    <svg
      *ngIf="component && component.id"
      class="designer-component"
      [attr.height]="isHover ? '156px' : '111px'"
      [attr.width]="isHover ? '184px' : '140px'"
      [id]="component.id"
      [ngClass]="{
        hover: isHover,
        connectable: (selectIsDraggingFlag$ | async) && isHover,
        selected: component.isSelected,
        'is-component-valid': isValid
      }"
      cdkDrag
      cdkDragBoundary=".designer-scroll"
      (cdkDragStarted)="onDragStarted()"
      (cdkDragMoved)="onDragMoved($event)"
      (cdkDragEnded)="onDragEnded($event)"
      [cdkDragFreeDragPosition]="dragPosition"
    >
      <g *ngIf="component.componentType !== COMPONENT_TYPE.STICKY_NOTE_COMPONENT" (mouseleave)="onMouseLeave()">
        <rect class="designer-component-buttons-container" width="76" height="64" rx="8" />
        <g class="designer-component-port-source" *ngIf="component.outputMin > 0">
          <rect width="10" height="8" [attr.fill]="outputEdgesLength > 0 ? '#ffffff' : '#BDC5CF'"></rect>
        </g>

        <path
          class="designer-component-hover"
          *ngIf="isHover"
          d="M140,0c-24.3,0-44,19.7-44,44H0v81.7v-1c0,2.2,1.9,4.3,4.1,4.3h132c2.2,0,3.9-2.1,3.9-4.3V88c24.3,0,44-19.7,44-44
    S164.3,0,140,0z"
        ></path>
        <g class="designer-component-background" (mouseenter)="onMouseEnter()">
          <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="15" height="15">
            <rect width="15" height="15" fill="#F2F3F5" />
            <line
              x1="0"
              y1="15"
              x2="15"
              y2="0"
              stroke="#F2F3F5"
              stroke-width="1"
              opacity="0.2"
              stroke-linecap="round"
            />
          </pattern>
          <rect
            class="designer-component-bg-new"
            width="140"
            height="84"
            rx="4"
            ry="4"
            [attr.fill]="isValid ? '#fff' : 'url(#diagonalHatch)'"
            [attr.opacity]="isValid ? '1' : '0.75'"
            cdkDragHandle
            (click)="editComponent()"
          ></rect>
          <path
            class="designer-component-border"
            d="M136,3c0.6,0,1,0.4,1,1v76c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1H136 M136,0H4
            C1.8,0,0,1.8,0,4v76c0,2.2,1.8,4,4,4h132c2.2,0,4-1.8,4-4V4C140,1.8,138.2,0,136,0L136,0z"
            stroke="#ffffff"
            stroke-width="2"
          ></path>
        </g>
        <g class="designer-component-edit">
          <rect class="designer-component-edit-hit-area"></rect>
          <path
            class="designer-component-edit-text"
            d="M6 10H11M8.5 1.3266C8.72101 1.11748 9.02077 1 9.33333 1C9.4881 1 9.64135 1.02884 9.78433 1.08488C9.92731 1.14092 10.0572 1.22306 10.1667 1.3266C10.2761 1.43015 10.3629 1.55308 10.4221 1.68837C10.4814 1.82366 10.5118 1.96866 10.5118 2.11509C10.5118 2.26153 10.4814 2.40653 10.4221 2.54182C10.3629 2.67711 10.2761 2.80004 10.1667 2.90358L3.22222 9.47434L1 10L1.55556 7.89736L8.5 1.3266Z"
            stroke="#BDC5CF"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
        <g
          #componentAdd
          class="designer-component-add"
          *ngIf="outputEdgesLength < component.outputMax"
          (click)="addComponent()"
          cdkDrag
          (cdkDragStarted)="onDragStartedAdd()"
          (cdkDragMoved)="onDragMovedAdd($event)"
          (cdkDragEnded)="onDragEndedAdd()"
          [ngClass]="{ invisible: isDraggingAdd }"
        >
          <path
            class="rect"
            d="M2 1H141V19C141 23.4183 137.418 27 133 27H10C5.58172 27 2 23.4183 2 19V1Z"
            fill="#BDC5CF"
          />
          <path
            class="bottom-bar"
            d="M136,4H4C1.8,4,0,2.2,0,0v0h140v0C140,2.2,138.2,4,136,4z"
            stroke="#177af0"
            stroke-width="1"
          ></path>
          <path
            class="plus-sign"
            d="M5.5 1V10M1 5.5H10"
            stroke="#F5F7F9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <text class="designer-component-name" text-anchor="middle" x="70" y="64">{{ name }}</text>
        <rect class="designer-component-icon-background" width="40" height="40" rx="10" />
        <use
          class="designer-component-icon"
          width="32"
          height="32"
          [attr.href]="'#' + component.icon"
          [ngClass]="component.icon"
        ></use>

        <g class="designer-component-remove" (mousedown)="remove($event)">
          <path
            class="designer-component-remove-icon"
            d="M1 3.66667H2.33333M2.33333 3.66667H13M2.33333 3.66667V13C2.33333 13.3536 2.47381 13.6928 2.72386 13.9428C2.97391 14.1929 3.31304 14.3333 3.66667 14.3333H10.3333C10.687 14.3333 11.0261 14.1929 11.2761 13.9428C11.5262 13.6928 11.6667 13.3536 11.6667 13V3.66667H2.33333ZM4.33333 3.66667V2.33333C4.33333 1.97971 4.47381 1.64057 4.72386 1.39052C4.97391 1.14048 5.31304 1 5.66667 1H8.33333C8.68696 1 9.02609 1.14048 9.27614 1.39052C9.52619 1.64057 9.66667 1.97971 9.66667 2.33333V3.66667M5.66667 7V11M8.33333 7V11"
          />
        </g>
        <g class="designer-component-duplicate" (mousedown)="duplicate()">
          <path
            class="designer-component-duplicate-icon"
            d="M2.95 9.45H2.3C1.95522 9.45 1.62456 9.31304 1.38076 9.06924C1.13696 8.82544 1 8.49478 1 8.15V2.3C1 1.95522 1.13696 1.62456 1.38076 1.38076C1.62456 1.13696 1.95522 1 2.3 1H8.15C8.49478 1 8.82544 1.13696 9.06924 1.38076C9.31304 1.62456 9.45 1.95522 9.45 2.3V2.95M6.85 5.55H12.7C13.418 5.55 14 6.13203 14 6.85V12.7C14 13.418 13.418 14 12.7 14H6.85C6.13203 14 5.55 13.418 5.55 12.7V6.85C5.55 6.13203 6.13203 5.55 6.85 5.55Z"
          />
        </g>
        <g class="designer-component-port-target" *ngIf="component.inputMin > 0">
          <rect *ngIf="inputEdgesLength > 0" width="10" height="8" fill="#ffffff"></rect>
          <polygon
            *ngIf="inputEdgesLength === 0"
            points="7,0 7,3 3,3 3,0 0,0 0,3 0,6 0,8 10,8 10,6 10,3 10,0 "
          ></polygon>
        </g>
      </g>
      <g class="designer-note" *ngIf="component.componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT">
        <pattern id="note-bg" width="7" height="22" patternUnits="userSpaceOnUse">
          <rect width="7" height="22" fill="#FFF" />
          <line style="stroke: #ddd; stroke-miterlimit: 10; stroke-width: 1;" x1="0" y1="7" x2="5" y2="7" />
        </pattern>
        <rect
          #noteBody
          class="designer-note-body"
          width="208"
          height="160"
          cdkDragHandle
          (mousedown)="onMouseDownEdit()"
          (mouseup)="onMouseUpEdit()"
          fill="#ffffff"
        />
        <rect class="designer-note-header" #noteHeader width="208" height="41" cdkDragHandle />
        <g class="designer-note-remove" #noteRemove (mousedown)="remove($event)">
          <path
            class="designer-note-remove-icon"
            d="M1 3.66667H2.33333M2.33333 3.66667H13M2.33333 3.66667V13C2.33333 13.3536 2.47381 13.6928 2.72386 13.9428C2.97391 14.1929 3.31304 14.3333 3.66667 14.3333H10.3333C10.687 14.3333 11.0261 14.1929 11.2761 13.9428C11.5262 13.6928 11.6667 13.3536 11.6667 13V3.66667H2.33333ZM4.33333 3.66667V2.33333C4.33333 1.97971 4.47381 1.64057 4.72386 1.39052C4.97391 1.14048 5.31304 1 5.66667 1H8.33333C8.68696 1 9.02609 1.14048 9.27614 1.39052C9.52619 1.64057 9.66667 1.97971 9.66667 2.33333V3.66667M5.66667 7V11M8.33333 7V11"
          />
        </g>
        <g class="designer-note-duplicate" #noteDuplicate (mousedown)="duplicate()">
          <path
            class="designer-note-duplicate-icon"
            d="M2.95 9.45H2.3C1.95522 9.45 1.62456 9.31304 1.38076 9.06924C1.13696 8.82544 1 8.49478 1 8.15V2.3C1 1.95522 1.13696 1.62456 1.38076 1.38076C1.62456 1.13696 1.95522 1 2.3 1H8.15C8.49478 1 8.82544 1.13696 9.06924 1.38076C9.31304 1.62456 9.45 1.95522 9.45 2.3V2.95M6.85 5.55H12.7C13.418 5.55 14 6.13203 14 6.85V12.7C14 13.418 13.418 14 12.7 14H6.85C6.13203 14 5.55 13.418 5.55 12.7V6.85C5.55 6.13203 6.13203 5.55 6.85 5.55Z"
          />
        </g>
        <g class="designer-note-title" #noteTitle>
          <text>Note</text>
        </g>
        <foreignObject #foreignObject x="10" y="51" width="188" height="160">
          <p #noteText xmlns="http://www.w3.org/1999/xhtml">{{ noteDescription }}</p>
        </foreignObject>
        <g
          #noteResize
          class="designer-note-resize"
          *ngIf="component.componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT"
          cdkDrag
          (cdkDragStarted)="onDragStartedResize()"
          (cdkDragMoved)="onDragMovedResize($event)"
          (cdkDragEnded)="onDragEndedResize()"
        >
          <rect width="20" height="20"></rect>
        </g>
      </g>
    </svg>
  `,
})
export class DesignerPackageItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() componentId: string;
  @ViewChild('noteText') noteText: ElementRef<HTMLElement>;
  @ViewChild('noteBody') noteBody: ElementRef<HTMLElement>;
  @ViewChild('foreignObject') foreignObject: ElementRef<HTMLElement>;
  @ViewChild('noteHeader') noteHeader: ElementRef<HTMLElement>;
  @ViewChild('noteRemove') noteRemove: ElementRef<HTMLElement>;
  @ViewChild('noteDuplicate') noteDuplicate: ElementRef<HTMLElement>;
  @ViewChild('noteTitle') noteTitle: ElementRef<HTMLElement>;
  @ViewChild('noteResize') noteResize: ElementRef<HTMLElement>;
  @ViewChild('componentAdd') componentAdd: ElementRef<HTMLElement>;
  component: Partial<ComponentTypeItem> = {};

  isHover = false;
  isDragging = false;
  isDraggingAdd = false;
  isDraggingResize = false;
  noteInitialWidth = 0;
  noteInitialHeight = 0;

  inputEdgesLength: number = 0;
  outputEdgesLength: number = 0;
  tempOutputEdgesLength: number = 0;
  componentSubscription: Subscription;
  componentTempPositionSubscription: Subscription;
  componentTempSizeSubscription: Subscription;
  inputEdgesLengthSubscription: Subscription;
  outputEdgesLengthSubscription: Subscription;
  lastDistanceX = 0;
  lastDistanceY = 0;
  dragPosition = { x: 0, y: 0 };
  selectIsDraggingFlag$ = this.store.select(selectIsDraggingFlag);
  COMPONENT_TYPE = COMPONENT_TYPE;
  isWorkflow = false;
  noteDescription = '';
  isMouseDownEdit = false;

  constructor(private store: Store<AppState>) {}

  ngAfterViewInit() {
    if (this.component.componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT) {
      this.updateNoteDimensions(this.component.noteHeight, this.component.noteWidth);
    }
  }

  ngOnInit() {
    this.componentSubscription = this.store
      .select(selectFixedComponentById(this.componentId))
      .pipe(filter(Boolean))
      .subscribe((component: ComponentTypeItem) => {
        if (this.component.description !== component.description) {
          this.noteDescription = component.description.replaceAll('<br />', '\n');
          setTimeout(() => {
            this.updateNoteDimensions();
          }, 100);
        }

        if (this.component.noteHeight !== component.noteHeight || this.component.noteWidth !== component.noteWidth) {
          setTimeout(() => {
            this.updateNoteDimensions(component.noteHeight, component.noteWidth);
          });
        }

        this.component = component;
        this.isWorkflow = component.type === COMPONENT_CATEGORY.workflow;

        if (!this.isDragging) {
          this.dragPosition = {
            x: this.component.xy[0],
            y:
              this.component.componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT
                ? this.component.xy[1] + 28
                : this.component.xy[1] - DESIGNER_SETTINGS.TOP_MARGIN_CELL + 43,
          };
        }

        if (this.inputEdgesLengthSubscription) {
          this.inputEdgesLengthSubscription.unsubscribe();
        }
        this.inputEdgesLengthSubscription = this.store
          .select(selectComponentInputEdgesLength(this.component.id))
          .subscribe((inputEdgesLength) => {
            this.inputEdgesLength = inputEdgesLength;
          });

        if (this.outputEdgesLengthSubscription) {
          this.outputEdgesLengthSubscription.unsubscribe();
        }
        this.outputEdgesLengthSubscription = this.store
          .select(selectComponentOutputEdgesLength(this.component.id))
          .subscribe((outputEdgesLength) => {
            if (!this.isDraggingAdd) {
              this.outputEdgesLength = outputEdgesLength;
            } else {
              this.tempOutputEdgesLength = outputEdgesLength;
            }
          });
      });

    this.componentTempPositionSubscription = this.store
      .select(selectTempComponentPositionById(this.componentId))
      .pipe(filter(Boolean))
      .subscribe((coords) => {
        if (this.component.isSelected && !this.isDragging) {
          this.dragPosition = {
            x: coords[0],
            y:
              this.component.componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT
                ? coords[1] + 43
                : coords[1] - DESIGNER_SETTINGS.TOP_MARGIN_CELL + 43,
          };
        }
      });

    this.componentTempSizeSubscription = this.store
      .select(selectTempComponenSizeById(this.componentId))
      .pipe(filter(Boolean))
      .subscribe((sizes) => {
        this.updateNoteDimensions(sizes[0], sizes[1]);
      });
  }

  updateNoteDimensions(height?: number, width?: number) {
    if (this.noteText?.nativeElement) {
      const descriptionHeight = height || this.noteText.nativeElement.offsetHeight;

      this.foreignObject.nativeElement.setAttribute('height', `${descriptionHeight}`);
      this.noteBody.nativeElement.setAttribute(
        'height',
        `${descriptionHeight + DESIGNER_SETTINGS.NOTE_PADDING_HORIZONTAL}`,
      );
      let descriptionWidth = width || this.noteText.nativeElement.offsetWidth;

      if (descriptionWidth < 188) {
        descriptionWidth = 188;
      }

      this.foreignObject.nativeElement.setAttribute('width', `${descriptionWidth}`);
      this.noteBody.nativeElement.setAttribute(
        'width',
        `${descriptionWidth + DESIGNER_SETTINGS.NOTE_PADDING_VERTICAL}`,
      );
      this.noteHeader.nativeElement.setAttribute(
        'width',
        `${descriptionWidth + DESIGNER_SETTINGS.NOTE_PADDING_VERTICAL}`,
      );
      this.noteRemove.nativeElement.setAttribute('style', `transform: translate(${descriptionWidth - 5}px, 14px)`);
      this.noteDuplicate.nativeElement.setAttribute('style', `transform: translate(${descriptionWidth - 26}px, 13px)`);
      this.noteTitle.nativeElement.setAttribute('style', `transform: translate(25px, 24px)`);
      this.noteResize.nativeElement.setAttribute(
        'style',
        `transform: translate(${descriptionWidth}px, ${descriptionHeight + 35}px)`,
      );
    }
  }

  editComponent() {
    if (this.isDragging) {
      this.isDragging = false;
      return;
    }
    this.store.dispatch(openComponentsModal({ componentId: this.component.id }));
  }

  onMouseDownEdit() {
    this.isMouseDownEdit = true;
  }

  onMouseUpEdit() {
    if (this.isMouseDownEdit && !this.isDragging) {
      this.editComponent();
    }
  }

  addComponent() {
    if (this.isDraggingAdd) {
      this.isDraggingAdd = false;
      return;
    }
    this.store.dispatch(
      openComponentsModal({
        parentComponentId: this.component.id,
        flags: {
          hasSources: false,
          hasTransformations: !this.isWorkflow,
          hasDestinations: !this.isWorkflow,
          hasWorkflow: this.isWorkflow,
        },
      }),
    );
  }

  onDragStarted() {
    this.isDragging = true;
    this.isMouseDownEdit = false;
    if (!this.component.isSelected) {
      this.store.dispatch(selectComponents({ componentIds: [] }));
    }
  }

  onDragStartedAdd() {
    this.isDraggingAdd = true;
    this.store.dispatch(
      createEdge({
        sourceComponentId: this.component.id,
        x: this.component.xy[0] - DESIGNER_SETTINGS.CELL,
        y: this.component.xy[1] + DESIGNER_SETTINGS.COMPONENT_HEIGHT + 20,
      }),
    );
  }

  onDragMovedAdd(event) {
    const { distance } = event;

    if (this.lastDistanceX === distance.x && this.lastDistanceY === distance.y) {
      return;
    }

    this.lastDistanceX = distance.x;
    this.lastDistanceY = distance.y;

    this.store.dispatch(
      updateEdgeTempPosition({
        x: this.component.xy[0] + distance.x,
        y: this.component.xy[1] + distance.y + DESIGNER_SETTINGS.COMPONENT_HEIGHT + 20,
        id: this.component.id,
      }),
    );
  }

  onDragEndedAdd() {
    this.isDraggingAdd = false;
    this.store.dispatch(createEdgeEndDrag({ sourceComponentId: this.component.id }));
    this.outputEdgesLength = this.tempOutputEdgesLength;
    if (
      [
        COMPONENT_TYPE.CLONE_COMPONENT,
        COMPONENT_TYPE.EXECUTE_SQL_COMPONENT,
        COMPONENT_TYPE.RUN_PACKAGE_COMPONENT,
      ].includes(this.component.componentType)
    ) {
      this.componentAdd.nativeElement.removeAttribute('transform');
      this.componentAdd.nativeElement.removeAttribute('style');
    }
  }

  onDragStartedResize() {
    this.isDraggingResize = true;
    this.noteInitialWidth = Number(this.noteBody.nativeElement.getAttribute('width'));
    this.noteInitialHeight = Number(this.noteBody.nativeElement.getAttribute('height'));
  }

  onDragMovedResize(event) {
    const { distance } = event;

    if (this.lastDistanceX === distance.x && this.lastDistanceY === distance.y) {
      return;
    }

    this.lastDistanceX = distance.x;
    this.lastDistanceY = distance.y;

    let width = this.noteInitialWidth - DESIGNER_SETTINGS.NOTE_PADDING_VERTICAL + distance.x;
    let height = this.noteInitialHeight - DESIGNER_SETTINGS.NOTE_PADDING_HORIZONTAL + distance.y;

    if (width < 188) {
      width = 188;
    }

    if (height < 100) {
      height = 100;
    }

    this.store.dispatch(
      resizeNoteComponentTemp({
        width,
        height,
        id: this.component.id,
      }),
    );
  }

  onDragEndedResize() {
    this.isDraggingResize = false;

    this.store.dispatch(
      resizeNoteComponent({
        width: Number(this.noteBody.nativeElement.getAttribute('width')) - DESIGNER_SETTINGS.NOTE_PADDING_VERTICAL,
        height: Number(this.noteBody.nativeElement.getAttribute('height')) - DESIGNER_SETTINGS.NOTE_PADDING_HORIZONTAL,
        id: this.component.id,
      }),
    );
  }

  get name(): string {
    if (!this.component.name) {
      return '';
    }

    return this.component.name.length > DESIGNER_SETTINGS.MAX_NAME_LENGTH
      ? `${this.component.name.substring(0, DESIGNER_SETTINGS.MAX_NAME_LENGTH)} ..`
      : this.component.name;
  }

  onDragMoved(event) {
    const { distance } = event;

    if (this.lastDistanceX === distance.x && this.lastDistanceY === distance.y) {
      return;
    }

    this.lastDistanceX = distance.x;
    this.lastDistanceY = distance.y;

    this.store.dispatch(
      updateComponentTempPosition({
        distanceX: distance.x,
        distanceY: distance.y,
        id: this.component.id,
      }),
    );
  }

  onDragEnded({ distance }) {
    if (this.component.componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT) {
      this.isDragging = false;
    }

    this.store.dispatch(
      updateComponentPosition({
        distanceX: distance.x,
        distanceY: distance.y,
        id: this.component.id,
      }),
    );
  }

  onMouseEnter() {
    if (!this.isHover) {
      this.isHover = true;
      this.store.dispatch(hoverComponent({ componentId: this.component.id, isHovered: true }));
    }
  }

  onMouseLeave() {
    if (this.isHover) {
      this.isHover = false;
      this.store.dispatch(hoverComponent({ componentId: this.component.id, isHovered: false }));
    }
  }

  get isValid(): boolean {
    switch (this.component.componentType) {
      case COMPONENT_TYPE.RUN_PACKAGE_COMPONENT:
        return this.component.package_id !== null && typeof this.component.package_id === 'number';
      case COMPONENT_TYPE.EXECUTE_SQL_COMPONENT:
        return this.component.query && this.component.query.length > 0;
      case COMPONENT_TYPE.FILE_MOVER_COMPONENT:
        return this.component.query && this.component.query.length > 0;
      default:
        return this.outputEdgesLength >= this.component.outputMin && this.inputEdgesLength >= this.component.inputMin;
    }
  }

  remove(event) {
    event.stopImmediatePropagation();
    this.store.dispatch(removeComponents({ componentId: this.component.id }));
  }

  duplicate() {
    this.store.dispatch(duplicateComponentsAction({ componentId: this.component.id }));
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }

    if (this.componentTempPositionSubscription) {
      this.componentTempPositionSubscription.unsubscribe();
    }

    if (this.inputEdgesLengthSubscription) {
      this.inputEdgesLengthSubscription.unsubscribe();
    }

    if (this.outputEdgesLengthSubscription) {
      this.outputEdgesLengthSubscription.unsubscribe();
    }

    if (this.componentTempSizeSubscription) {
      this.componentTempSizeSubscription.unsubscribe();
    }
  }
}
