import { createReducer, on } from '@ngrx/store';
import { Account } from '../../../account/account.models';
import {
  getAccountsList,
  getAccountsListResponse,
  loadMoreAccountsList,
  loadMoreAccountsListResponse,
  saveAccount,
  saveAccountError,
  saveAccountResponse,
  openAccountsModal,
  closeAccountsModal,
  getAccountsListError,
} from './accounts.actions';
import { removeMemberItem, removeMemberResponse } from '../members/members.actions';
import { ValidationError } from '../../../config/validation-error.model';
import { closeAllModals } from '../../../account/store/account.actions';

export const initialState: AccountsState = {
  items: [],
  item: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  errors: [],
  lastlyCreatedAccount: null,
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface AccountsState {
  items: Account[];
  item: Account;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  errors: ValidationError[];
  lastlyCreatedAccount: Account;
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const accountsReducer = createReducer<AccountsState>(
  initialState,

  on(openAccountsModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeAccountsModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(getAccountsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getAccountsListResponse, (state, { accounts, limit }) => ({
    ...state,
    isLoading: false,
    items: accounts,
    areAllItemsLoaded: accounts.length < limit,
  })),
  on(getAccountsListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(loadMoreAccountsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreAccountsListResponse, (state, { accounts, limit }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...accounts],
    areAllItemsLoaded: accounts.length < limit,
  })),

  on(saveAccount, (state) => ({ ...state, isFormSubmitting: true, lastlyCreatedAccount: null })),
  on(saveAccountResponse, (state, { data }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: false,
    lastlyCreatedAccount: data,
  })),
  on(saveAccountError, (state, { errors }) => ({
    ...state,
    isFormSubmitting: false,
    errors,
    lastlyCreatedAccount: null,
  })),

  on(removeMemberItem, (state, { member }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== member.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeMemberResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
