import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../../common/services/permissions.service';
import { Member, STANDARD_ROLES } from '../../members.models';
import { AppState } from '../../../store';
import { selectUserId } from '../../../account/store/account.selectors';
import { removeMember, updateMember } from '../../store/members/members.actions';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../../common/services/confirmation-dialog.service';
import { ItemType } from '../../../common/components/lists/list-item-snippet.component';

@Component({
  selector: 'member-list-item',
  template: `
    <div
      class="generic-list-item slider-animation advanced-roles"
      [ngClass]="{ 'closed-animation': item.isRemoved, deactivated: isUserDeactivated }"
    >
      <div class="member-list-item">
        <div class="member-image">
          <img class="img-circle" [src]="item.avatar_url" [alt]="item.name" />
        </div>
        <div class="member-body">
          <a
            class="list-member-name"
            (click)="goToMember(item)"
            [attr.disabled]="hasPermission$('app.account.settings.account.member') | async"
            ><strong>{{ item.name || item.email }}</strong
            ><span class="text-muted" *ngIf="item.id === userId">{{
              'member.generic-list.item.current' | translate
            }}</span></a
          >

          <p class="list-member-email">{{ item.email }}</p>
        </div>
        <div class="member-role-picker">
          <role-picker
            class="role-picker"
            [disabled]="item.owner || !(hasPermission$('updateMemberRole') | async) || isUserDeactivated"
            [value]="memberRole()"
            (onChange)="roleUpdate($event, item)"
          ></role-picker>
          <div class="hidden">{{ memberRole }}</div>
        </div>
        <div class="advanced-role icon-container">
          <div class="role-icon" [ngClass]="{ checked: hasRole(STANDARD_ROLES.READER) }">
            <svg>
              <use [attr.href]="hasRole(STANDARD_ROLES.READER) ? '#icon-checked' : '#icon-unchecked'"></use>
            </svg>
          </div>
        </div>
        <div class="advanced-role icon-container">
          <div class="role-icon" [ngClass]="{ checked: hasRole(STANDARD_ROLES.EDITOR) }">
            <svg>
              <use [attr.href]="hasRole(STANDARD_ROLES.EDITOR) ? '#icon-checked' : '#icon-unchecked'"></use>
            </svg>
          </div>
        </div>
        <div class="advanced-role icon-container">
          <div class="role-icon" [ngClass]="{ checked: hasRole(STANDARD_ROLES.OPERATOR) }">
            <svg>
              <use [attr.href]="hasRole(STANDARD_ROLES.OPERATOR) ? '#icon-checked' : '#icon-unchecked'"></use>
            </svg>
          </div>
        </div>
        <div class="advanced-role icon-container">
          <div class="role-icon" [ngClass]="{ checked: hasRole(STANDARD_ROLES.ADMIN) }">
            <svg>
              <use [attr.href]="hasRole(STANDARD_ROLES.ADMIN) ? '#icon-checked' : '#icon-unchecked'"></use>
            </svg>
          </div>
        </div>
        <div class="icon-container">
          <svg
            *ngIf="item.id !== userId && !item.owner && (hasPermission$('deleteMember') | async)"
            class="icon icon-delete"
            (click)="onRemove(item)"
            [matTooltip]="'member.generic-list.item.actions.delete' | translate"
            matTooltipPosition="above"
            matTooltipClass="above"
          >
            <use href="#icon-delete-bin"></use>
          </svg>
        </div>
      </div>
    </div>
  `,
})
export class MemberListItemComponent {
  @Input() item: Member;
  @Output() editRolesClick = new EventEmitter<Member>();
  userId: number;
  STANDARD_ROLES = STANDARD_ROLES;

  constructor(
    protected store: Store<AppState>,
    private permissionsService: PermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
  ) {
    this.store.pipe(selectUserId).subscribe((userId: number) => {
      this.userId = userId;
    });
  }

  hasPermission$(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  roleUpdate(role: STANDARD_ROLES, member: Member): void {
    this.store.dispatch(updateMember({ member: { ...member, role } }));
  }

  goToMember(item: Member): void {
    this.router.navigate([`${item.id}`], { relativeTo: this.route });
  }

  hasRole(role: STANDARD_ROLES): boolean {
    if (role === STANDARD_ROLES.READER) {
      return true;
    }

    if (role === STANDARD_ROLES.EDITOR) {
      return this.item.role !== STANDARD_ROLES.READER;
    }

    if (role === STANDARD_ROLES.OPERATOR) {
      return (
        this.item.role === STANDARD_ROLES.OPERATOR ||
        this.item.role === STANDARD_ROLES.ADMIN ||
        this.item.role === STANDARD_ROLES.MEMBER
      );
    }

    if (role === STANDARD_ROLES.ADMIN) {
      return this.item.role === STANDARD_ROLES.ADMIN || this.item.owner;
    }
  }

  onRemove(item: Member): void {
    if (item.id === this.userId) {
      const dialogRef = this.confirmationDialog.openDialog({
        title: this.translate.instant('member.generic-list.item.modals.leave.confirm.title'),
        hint: this.translate.instant('member.generic-list.item.modals.leave.confirm.hint'),
        yes: this.translate.instant('member.generic-list.item.modals.leave.confirm.yes'),
        no: this.translate.instant('member.generic-list.item.modals.leave.confirm.no'),
        item,
        itemType: ItemType.member,
      });
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.store.dispatch(removeMember({ memberId: item.id, isLogout: true }));
        }
      });
    } else {
      const dialogRef = this.confirmationDialog.openDialog({
        title: this.translate.instant('member.generic-list.item.modals.delete.confirm.title'),
        hint: this.translate.instant('member.generic-list.item.modals.delete.confirm.hint'),
        yes: this.translate.instant('member.generic-list.item.modals.delete.confirm.yes'),
        no: this.translate.instant('member.generic-list.item.modals.delete.confirm.no'),
        item,
        itemType: ItemType.member,
      });

      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.store.dispatch(removeMember({ memberId: item.id, isLogout: false }));
        }
      });
    }
  }

  memberRole(): STANDARD_ROLES {
    if (this.item.owner) {
      return STANDARD_ROLES.OWNER;
    }

    return this.item.role;
  }

  get isUserDeactivated(): boolean {
    return this.item.name === 'User deactivated';
  }
}
