import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionsService } from '../../common/services/permissions.service';
import { SelectOption } from '../../common/components/forms/xp-select.component';
import { STANDARD_ROLES } from '../members.models';

@Component({
  selector: 'role-picker',
  template: `
    <div class="role-picker" [ngClass]="{ wider: value === 'member' }">
      <xp-select
        [value]="value"
        (valueChange)="onValueChange($event)"
        class="form-control xp-select"
        [options]="roleOptions"
        [disabled]="disabled"
        panelClass="role-picker-panel"
      >
      </xp-select>
    </div>
  `,
})
export class RolePickerComponent implements OnInit {
  @Input() value: string;
  @Input() disabled: boolean;
  @Output() onChange = new EventEmitter<STANDARD_ROLES>();
  roleOptions: SelectOption[] = [];

  constructor(private permissionsService: PermissionsService) {}

  ngOnInit() {
    this.hasPermission('changeAccountOwner').subscribe((hasPermission) => {
      this.roleOptions = [
        {
          value: STANDARD_ROLES.READER,
          text: 'roles.reader.name',
          translate: true,
          description: 'roles.reader.description',
        },
        {
          value: STANDARD_ROLES.EDITOR,
          text: 'roles.editor.name',
          translate: true,
          description: 'roles.editor.description',
        },
        {
          value: STANDARD_ROLES.OPERATOR,
          text: 'roles.operator.name',
          translate: true,
          description: 'roles.operator.description',
        },
        {
          value: STANDARD_ROLES.MEMBER,
          text: 'roles.member.name',
          translate: true,
          description: 'roles.member.description',
          disabled: true,
        },
        {
          value: STANDARD_ROLES.ADMIN,
          text: 'roles.admin.name',
          translate: true,
          description: 'roles.admin.description',
        },
        hasPermission || this.disabled
          ? {
              value: STANDARD_ROLES.OWNER,
              text: 'roles.owner.name',
              translate: true,
              description: 'roles.owner.description',
            }
          : null,
      ].filter(Boolean);
    });
  }

  onValueChange(value) {
    this.onChange.emit(value);
  }

  private hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }
}
