import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { getValidatorData, serializeForm } from '../helpers/validator-data.helper';
import { AppState } from '../../../store';
import { addField, removeField, removeForm, setFormValidity, updateFormValidity } from '../store/validators.actions';
import { selectValidators } from '../store/validators.selectors';
import { FieldsCollectionValidationsService } from '../../services/fields-collection-validations.service';

@Component({
  selector: 'xp-form-validation',
  template: '<ng-content></ng-content>',
})
export class XpFormValidationComponent implements AfterViewInit, OnDestroy {
  @Input() type: string;
  @Input() name: string;
  validators$ = this.store.select(selectValidators);
  services = {
    fieldsCollectionValidationsService: this.fieldsCollectionValidationsService,
    translate: this.translate,
  };
  public disabledValidationFields: string[] = [];

  constructor(
    public elementRef: ElementRef,
    private store: Store<AppState>,
    private translate: TranslateService,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.validateForm();
    });
  }

  public get formElement(): HTMLFormElement {
    return this.elementRef.nativeElement.querySelector('form');
  }

  public get formName(): string {
    return this.formElement.getAttribute('name') || this.name;
  }

  public validateForm(fieldName?: string): void {
    this.getFormData(fieldName).subscribe((formData) => {
      if (fieldName) {
        this.store.dispatch(updateFormValidity({ formName: this.formName, formData }));
      } else {
        this.store.dispatch(setFormValidity({ formName: this.formName, formData }));
      }
    });
  }

  private getFormData(fieldName?: string): Observable<any> {
    let formScope = serializeForm(this.formElement, this.disabledValidationFields);

    if (fieldName && !fieldName.includes('{{')) {
      formScope = { [fieldName]: formScope[fieldName] };
    }

    return this.validators$.pipe(
      filter(Boolean),
      first(),
      withLatestFrom(this.store),
      map(([validatorsObject, store]) => {
        return Object.keys(formScope)
          .filter(Boolean)
          .reduce((acc, key) => {
            const validatorObject = validatorsObject[this.type];

            if (!validatorObject) {
              return acc;
            }

            const validators = validatorObject[key];

            const validatorData = getValidatorData({
              validators,
              value: formScope[key],
              name: key,
              services: this.services,
              formScope,
              store,
            });
            return { ...acc, [key]: validatorData.every((item) => item.valid) };
          }, {});
      }),
    );
  }

  public disableValidationForField(fieldName: string) {
    this.disabledValidationFields = [...this.disabledValidationFields, fieldName];
    this.store.dispatch(removeField({ formName: this.formName, fieldName }));
  }

  public enableValidationForField(fieldName: string) {
    this.disabledValidationFields = this.disabledValidationFields.filter((name) => name !== fieldName);
    this.store.dispatch(addField({ formName: this.formName, fieldName }));
  }

  ngOnDestroy() {
    this.store.dispatch(removeForm({ formName: this.formName }));
  }
}
