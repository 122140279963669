import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Package } from '../package.models';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { duplicatePackage, removePackage } from '../store/packages.actions';
import { selectWorkspacesFeatureFlag } from '../../account/store/account.selectors';
import { AuthorizationGuard } from '../../common/services/authorization.guard';
import { CustomMiddleClickEvent } from '../../common/helper/global-types.helper';
import { Workspace } from '../../workspaces/workspaces.models';

@Component({
  selector: 'package-list-item',
  template: `
    <div class="generic-list-item slider-animation" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div
        class="package-list-item"
        [ngClass]="{
          inactive: item.status === 'archived'
        }"
        id="package-{{ item.id }}"
      >
        <div class="package-name">
          <common-icon iconId="icon-workflow" *ngIf="item.flow_type === 'workflow'"></common-icon>
          <common-icon iconId="icon-package" *ngIf="item.flow_type === 'dataflow'"></common-icon>
          <div class="package-name-container">
            <strong
              ><a
                (click)="goToPackage(item, $event)"
                (auxclick)="goToPackage(item, { isMiddleClick: true })"
                [matTooltip]="item.name"
                matTooltipPosition="right"
                matTooltipClass="right wide"
                [innerHTML]="item.name | xpHighLight: searchValue"
              ></a>
              <span *ngIf="item.status === 'archived'" class="inactive"
                >({{ 'package.generic-list.item.labels.inactive' | translate }})</span
              ></strong
            >
            <p
              *ngIf="item.description"
              [matTooltip]="item.description"
              matTooltipPosition="right"
              matTooltipClass="right wide"
            >
              {{ item.description }}
            </p>
            <span *ngxPermissionsOnly="'versionControl'">
              <a class="package-version" (click)="editPackageVersion(item)"> Version {{ item.package_version }} </a>
              <span
                *ngIf="item.version_description"
                class="version-message"
                [matTooltip]="item.version_description"
                matTooltipPosition="right"
                matTooltipClass="right wide"
              >
                - {{ item.version_description | xpLengthCheck: ((isWorkspacesFeatureEnabled$ | async) ? 50 : 150) }}
              </span>
            </span>
          </div>
        </div>
        <div class="package-workspace" *ngIf="(isWorkspacesFeatureEnabled$ | async) && !isWorkspacesItemView">
          <a
            (click)="goToWorkspace(item.workspace, $event)"
            (auxclick)="goToWorkspace(item.workspace, { isMiddleClick: true })"
            *ngIf="item.workspace"
            >{{ item.workspace.name }}</a
          >
          <span *ngIf="!item.workspace"></span>
        </div>
        <div class="package-owner">
          <a
            (click)="goToOwner(item, $event)"
            (auxclick)="goToOwner(item, { isMiddleClick: true })"
            *ngIf="item.owner"
            >{{ item.owner.name || item.owner.email }}</a
          >
        </div>
        <div class="package-date">
          <span date-tooltip="item.updated_at">{{ item.updated_at | xpPrettyDate }}</span>
        </div>
        <div class="package-run-job">
          <button class="btn btn-success" type="button" (click)="runJob.emit(item)" *ngxPermissionsOnly="'createJob'">
            <svg class="icon icon-play">
              <use href="#icon-play"></use>
            </svg>
            {{ 'package.generic-list.item.labels.run_job' | translate }}
          </button>
        </div>
        <div class="dropdown xp-dropdown-full" *ngxPermissionsOnly="'updatePackage'">
          <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
        </div>
        <mat-menu #dropdown="matMenu" class="full-dropdown-menu">
          <li mat-menu-item (click)="goToPackage(item, $event)" *ngxPermissionsOnly="'updatePackage'">
            <span>{{ 'package.generic-list.item.actions.edit' | translate }}</span>
          </li>
          <li mat-menu-item (click)="editPackage(item)" *ngxPermissionsOnly="'updatePackage'">
            <span>{{ 'package.generic-list.item.actions.rename' | translate }}</span>
          </li>
          <li mat-menu-item (click)="duplicatePackage(item)" *ngxPermissionsOnly="'createPackage'">
            <span>{{ 'package.generic-list.item.actions.duplicate' | translate }}</span>
          </li>
          <li mat-menu-item (click)="editPackageWorkspace(item)" *ngIf="isWorkspacesFeatureEnabled$ | async">
            <span *ngIf="!item.workspace_id">{{
              'package.generic-list.item.actions.add-to-workspace' | translate
            }}</span>
            <span *ngIf="item.workspace_id">{{
              'package.generic-list.item.actions.change-workspace' | translate
            }}</span>
          </li>
          <li
            mat-menu-item
            [hidden]="item.status === 'archived'"
            (click)="archivePackage(item)"
            *ngxPermissionsOnly="'deletePackage'"
            class="top-separator"
          >
            <a>
              <span class="text-danger">{{ 'package.generic-list.item.actions.archive' | translate }}</span>
            </a>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class PackageListItemComponent implements OnInit {
  @Input() item: Partial<Package> = {};
  @Input() searchValue: string;
  @Output() edit = new EventEmitter<Partial<Package>>();
  @Output() editVersion = new EventEmitter<Partial<Package>>();
  @Output() editWorkspace = new EventEmitter<Partial<Package>>();
  @Output() runJob = new EventEmitter<Partial<Package>>();
  isWorkspacesItemView = false;
  isWorkspacesFeatureEnabled$ = this.store.select(selectWorkspacesFeatureFlag);

  constructor(
    private translate: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private authGuard: AuthorizationGuard,
  ) {}

  ngOnInit() {
    if (!this.item) {
      this.item = {};
    }

    this.isWorkspacesItemView = this.router.url.includes('/workspaces');
  }

  editPackage(item: Partial<Package>) {
    this.edit.emit(item);
  }

  editPackageWorkspace(item: Partial<Package>) {
    this.editWorkspace.emit(item);
  }

  editPackageVersion(item: Partial<Package>) {
    this.editVersion.emit(item);
  }

  goToPackage(item: Partial<Package>, event: MouseEvent | CustomMiddleClickEvent): void {
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent?.metaKey || mouseEvent?.ctrlKey || customEvent?.isMiddleClick;

    if (openInNewTab) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/${accountId}/packages/${item.id}/edit`]));

      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${accountId}/packages/${item.id}/edit`]);
    }
  }

  goToOwner(item: Partial<Package>, event: MouseEvent | CustomMiddleClickEvent): void {
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent?.metaKey || mouseEvent?.ctrlKey || customEvent?.isMiddleClick;

    if (openInNewTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/${accountId}/settings/account/members/${item.owner.id}`]),
      );

      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${accountId}/settings/account/members/${item.owner.id}`]);
    }
  }

  goToWorkspace(workspace: Partial<Workspace>, event: MouseEvent | CustomMiddleClickEvent): void {
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent?.metaKey || mouseEvent?.ctrlKey || customEvent?.isMiddleClick;

    if (openInNewTab) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/${accountId}/workspaces/${workspace.id}`]));

      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${accountId}/workspaces/${workspace.id}`]);
    }
  }

  archivePackage(item: Partial<Package>): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('package.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('package.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('package.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('package.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.package,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removePackage({ packageId: item.id }));
      }
    });
  }

  duplicatePackage(item: Partial<Package>): void {
    this.store.dispatch(duplicatePackage({ packageId: item.id, packageName: item.name }));
  }
}
