export const CONTEXT_HELP = {
  Connection: {
    index: [
      {
        title: 'Defining connections',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/defining-connections/',
      },
      {
        title: 'How to import connections from Heroku',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/',
      },
    ],
    new: [
      {
        title: 'Defining connections',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/defining-connections/',
      },
    ],
  },
  Package: {
    edit: [
      {
        title: 'Working in the package designer',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/working-in-the-package-designer/',
      },
      {
        title: 'Validating a package',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/validating-a-package/',
      },
      {
        title: 'How to use variables',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/using-and-setting-variables-in-your-packages/',
      },
      {
        title: 'How to use expressions',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/using-expressions-in-xplenty/',
      },
      {
        title: 'How to process JSON data',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/how-do-i-process-json-data/',
      },
    ],
    index: [
      {
        title: 'Creating a new package',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/creating-a-new-package/',
      },
      {
        title: 'Create a package from a template',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/create-a-package-from-a-template/',
      },
    ],
  },
  Cluster: {
    index: [
      {
        title: 'Creating a cluster',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/creating-a-cluster/',
      },
      {
        title: 'Editing and scaling clusters',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/editing-and-scaling-clusters/',
      },
      {
        title: 'Terminating a cluster',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/terminating-a-cluster/',
      },
      {
        title: 'Running jobs',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/running-jobs/',
      },
    ],
    show: [
      {
        title: 'Editing and scaling clusters',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/editing-and-scaling-clusters/',
      },
      {
        title: 'Terminating a cluster',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/terminating-a-cluster/',
      },
      {
        title: 'Running jobs',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/running-jobs/',
      },
    ],
  },
  Schedule: {
    index: [
      {
        title: 'Scheduling package execution',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/scheduling-package-execution/',
      },
    ],
    show: [
      {
        title: 'Scheduling package execution',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/scheduling-package-execution/',
      },
    ],
  },
  Member: {
    index: [
      {
        title: 'Managing your Prepforce by Integrate.io account members',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/managing-your-xplenty-account-members/',
      },
      {
        title: 'Setting account member roles',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/setting-account-member-roles/',
      },
    ],
  },
  Plan: {
    edit: [
      {
        title: 'Viewing and modifying your billing information',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/viewing-and-modifying-your-billing-information-and-account-usage/',
      },
    ],
  },
  Account: {
    edit: [
      {
        title: 'Managing your account settings',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/managing-your-account-settings/',
      },
    ],
  },
  Hook: {
    index: [
      {
        title: 'Defining Webhooks',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/service-hooks/',
      },
      {
        title: 'Defining Slack hooks',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/slack-hooks/',
      },
      {
        title: 'Defining PagerDuty hooks',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/pagerduty-hooks/',
      },
      {
        title: 'Defining Email hooks',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/email-hooks/',
      },
      {
        title: "ETL & Reverse ETL's API reference",
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/xplentys-api/',
      },
    ],
  },
  Notification: {
    show: [
      {
        title: 'Viewing and setting notifications',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/viewing-and-setting-web-and-email-notifications/',
      },
    ],
  },
  User: {
    edit: [
      {
        title: 'Modifying your personal settings',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/modifying-your-personal-settings/',
      },
      {
        title: "Use ETL & Reverse ETL's API",
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/xplentys-api/',
      },
    ],
    edit_password: [
      {
        title: 'Changing your password',
        type: 'article',
        url: 'https://www.integrate.io/docs/etl/changing-your-password/',
      },
    ],
  },
};
