import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { DB2Connection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';

@Component({
  selector: 'connection-form-db2',
  template: `
    <xp-form-validation type="Db2DatabaseConnection" [name]="formName">
      <form id="connectionFormDb2" name="connectionFormDb2" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <connection-component-access-type
              [connection]="connection"
              (tunnelTypeChange)="onTunnelTypeChange($event)"
            ></connection-component-access-type>
            <div class="connection-ssl">
              <xp-form-group [validationDisabled]="true">
                <xp-input-checkbox
                  name="ssl"
                  [(ngModel)]="connection.ssl"
                  [labelText]="'connections.form.labels.connect_using_ssl' | translate"
                ></xp-input-checkbox>
              </xp-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="database"
                >{{ 'connections.form.labels.database' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="text"
                class="form-control"
                name="database"
                id="database"
                [(ngModel)]="connection.database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormDb2Component extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<DB2Connection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() tunnelTypeChange = new EventEmitter<String>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormDb2';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  onTunnelTypeChange(value: string) {
    this.tunnelTypeChange.emit(value);
  }
}
