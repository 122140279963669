import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

export interface Tag {
  name: string;
}

export interface Step {
  title: string;
  activeTitle: string;
  tags: Tag[];
  active?: boolean;
  valid: boolean;
  lock: boolean;
  disable: boolean;
  index?: number;
  isLast?: boolean;
  isError?: boolean;
  isLoading?: boolean;
}

@Component({
  selector: 'xp-steps',
  template: `
    <div class="steps">
      <ng-content></ng-content>
    </div>
  `,
})
export class XpStepsComponent {
  steps = new BehaviorSubject<Step[]>([]);

  public setActiveFlag(stepIndex: number, isActive: boolean): void {
    this.steps.pipe(first()).subscribe((steps) => {
      const step = steps[stepIndex];
      const newStep = {
        ...step,
        active: isActive,
      };

      let newSteps = steps;

      if (isActive) {
        newSteps = steps.map((item) => ({ ...item, active: false }));
      }

      newSteps = newSteps.map((item) => (item.index === stepIndex ? newStep : item));

      this.steps.next(newSteps);
    });
  }

  public addStep(step: Step): Observable<number> {
    return this.steps.pipe(
      first(),
      map((steps) => {
        const newSteps = steps.map((item) => ({ ...item, isLast: false }));

        const newStep = {
          ...step,
          index: steps.length,
          isLast: true,
        };

        this.steps.next([...newSteps, newStep]);
        return newSteps;
      }),
      map((steps) => steps.length),
    );
  }

  public listenChanges(stepIndex: number): Observable<Step> {
    return this.steps.pipe(map((steps) => steps.find((item) => item.index === stepIndex)));
  }

  public activatePreviousStep(stepIndex: number): void {
    this.steps.pipe(first()).subscribe((steps) => {
      const newSteps = [...steps];
      newSteps[stepIndex] = { ...steps[stepIndex], active: false };
      newSteps[stepIndex - 1] = { ...steps[stepIndex - 1], active: true };

      this.steps.next(newSteps);
    });
  }

  public activateNextStep(stepIndex: number): void {
    this.steps.pipe(first()).subscribe((steps) => {
      const newSteps = [...steps];
      newSteps[stepIndex] = { ...steps[stepIndex], active: false };
      newSteps[stepIndex + 1] = { ...steps[stepIndex + 1], active: true };

      this.steps.next(newSteps);
    });
  }

  public updateStepParams(step: Step, stepIndex: number): void {
    this.steps.pipe(first()).subscribe((steps) => {
      const newSteps = [...steps];
      newSteps[stepIndex] = { ...steps[stepIndex], ...step };

      this.steps.next(newSteps);
    });
  }
}
