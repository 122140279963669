import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../store';
import { selectIntegrateIOID } from '../../account/store/account.selectors';
import { IntegrateioTemplateComponent } from '../services/components/integrateio-template.component';

@Component({
  selector: 'integrateio-notification',
  template: ``,
})
export class IntegrateioNotificationComponent implements OnInit, OnDestroy {
  userSubscription: Subscription;
  constructor(private store: Store<AppState>, public dialog: MatDialog) {}

  ngOnInit() {
    this.userSubscription = this.store.pipe(selectIntegrateIOID).subscribe(() => {
      // this.dialog.open(IntegrateioTemplateComponent, {
      //   position: {
      //     top: '150px',
      //   },
      //   maxWidth: '500px',
      // });
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
