import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DatabaseDefaultConnectionWithSSL } from '../../connection.models';

@Component({
  selector: 'connection-component-ssh-host-port-user',
  template: `
    <div class="connection-component-ssh-host-port-user row">
      <div class="col-sm-12">
        <xp-form-group>
          <label for="ssh_username">{{ 'connections.form.labels.ssh_username' | translate }}</label>
          <xp-input
            type="text"
            class="form-control"
            name="ssh_username"
            id="ssh_username"
            [(ngModel)]="connection.ssh_username"
          ></xp-input>
        </xp-form-group>
      </div>
      <div class="col-sm-9">
        <xp-form-group>
          <label for="ssh_host">{{ 'connections.form.labels.ssh_host' | translate }}</label>
          <xp-input
            type="text"
            class="form-control"
            name="ssh_host"
            id="ssh_host"
            [(ngModel)]="connection.ssh_host"
          ></xp-input>
        </xp-form-group>
      </div>
      <div class="col-sm-3">
        <xp-form-group>
          <label for="ssh_port">{{ 'connections.form.labels.ssh_port' | translate }}</label>
          <xp-input
            type="number"
            class="form-control"
            name="ssh_port"
            id="ssh_port"
            [(ngModel)]="connection.ssh_port"
          ></xp-input>
        </xp-form-group>
      </div>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentSshHostPortUserComponent {
  @Input() connection: Partial<DatabaseDefaultConnectionWithSSL> = {};
}
