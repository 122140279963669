import { Injectable } from '@angular/core';
import { Variables } from '../package.models';

@Injectable({
  providedIn: 'root',
})
export class DynamicConnectionService {
  variables: Variables;

  get dynamicConnectionVariables(): Variables {
    return this.variables;
  }

  set dynamicConnectionVariables(variables: Variables) {
    this.variables = variables;
  }
}
