import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Job } from '../../jobs.models';

@Component({
  selector: 'job-tasks',
  template: `
    <div class="job-progress job-content">
      <job-progress-task
        [progressTask]="progressTask"
        [item]="item"
        (childOpen)="childOpen.emit($event)"
        *ngFor="let progressTask of childJobs"
      ></job-progress-task>
      <div class="job-empty" *ngIf="childJobs.length === 0 && isLoaded">
        {{ 'job-view.tasks.empty-text' | translate }}
      </div>
      <xp-loader *ngIf="isLoadingChildren"></xp-loader>
    </div>
  `,
})
export class JobTasksComponent {
  @Input() childJobs: Job[];
  @Input() item: Job;
  @Input() isLoaded: boolean;
  @Input() isLoadingChildren: boolean;
  @Output() childOpen = new EventEmitter<Job>();
}
