import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import {
  selectHasSelection,
  selectIsPackageDirtyFlag,
  selectIsRedoAvailable,
  selectIsRunningValidationFlag,
  selectIsUndoAvailable,
  selectPackage,
} from '../../store/package-designer.selectors';
import {
  createNoteAction,
  duplicateComponentsAction,
  exportJSON,
  openComponentsModal,
  openPackageImportModal,
  openPackageVariablesModal,
  openPackageVersionMessageModal,
  redoAction,
  removeComponents,
  savePackage,
  selectAllComponents,
  selectConnectedComponents,
  undoAction,
  unselectAllComponents,
} from '../../store/package-designer.actions';
import { openJobsModal } from '../../../jobs/store/jobs.actions';
import { openPackagesModal, openPackageVersionsModal } from '../../../packages/store/packages.actions';
import { NotifyService } from '../../../common/services/notify.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { selectAccountPermissions } from '../../../account/store/account.selectors';
import { PermissionsService } from '../../../common/services/permissions.service';
import { UnlockFeaturesDialogService } from '../../../common/services/unlock-features-dialog.service';

@Component({
  selector: 'designer-controls',
  template: `
    <fieldset [disabled]="isVersionView" *ngIf="package$ | async as packageItem">
      <div id="designer-controls" class="designer-controls">
        <div class="pull-left designer-controls-main">
          <button
            type="button"
            class="name-button"
            (click)="editPackage()"
            [disabled]="!(hasPermission$('updatePackage') | async)"
          >
            {{ packageItem.name | xpLengthCheck: 35 }}
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngxPermissionsOnly="'updatePackage'"
            >
              >
              <path
                d="M6 10H11M8.5 1.3266C8.72101 1.11748 9.02077 1 9.33333 1C9.4881 1 9.64135 1.02884 9.78433 1.08488C9.92731 1.14092 10.0572 1.22306 10.1667 1.3266C10.2761 1.43015 10.3629 1.55308 10.4221 1.68837C10.4814 1.82366 10.5118 1.96866 10.5118 2.11509C10.5118 2.26153 10.4814 2.40653 10.4221 2.54182C10.3629 2.67711 10.2761 2.80004 10.1667 2.90358L3.22222 9.47434L1 10L1.55556 7.89736L8.5 1.3266Z"
              />
            </svg>
          </button>
          <button
            *ngxPermissionsOnly="'versionControl'"
            type="button"
            class="version-button"
            (click)="openVersionsModal()"
            matTooltip="Rollback to previous version"
            matTooltipPosition="right"
            matTooltipClass="right"
            [disabled]="!(hasPermission$('updatePackage') | async)"
          >
            Ver. {{ packageItem.package_version }}
            <svg
              width="20"
              height="20"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngxPermissionsOnly="'updatePackage'"
            >
              <path
                d="M1 1.45347V4.18074M1 4.18074H3.72727M1 4.18074L3.10909 2.19892C3.59761 1.71016 4.20199 1.35311 4.86582 1.16109C5.52966 0.96908 6.23131 0.948357 6.90532 1.10086C7.57933 1.25336 8.20373 1.57412 8.72024 2.03321C9.23676 2.49229 9.62856 3.07474 9.85909 3.7262M11 8.7262V5.99893M11 5.99893H8.27273M11 5.99893L8.89091 7.98074C8.40239 8.46951 7.79801 8.82656 7.13418 9.01857C6.47034 9.21059 5.76869 9.23131 5.09468 9.07881C4.42067 8.92631 3.79627 8.60555 3.27976 8.14646C2.76324 7.68738 2.37144 7.10493 2.14091 6.45347"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right">
          <button
            *ngIf="isPackageDirty$ | async as isPackageDirty"
            [hidden]="!(canRunJob | async)"
            [disabled]="!(hasPermission$('updatePackage') | async)"
            type="button"
            class="btn btn-success btn-lg btn-run-job"
            (click)="runJob(isPackageDirty)"
          >
            {{ 'Save & Run job' }}
          </button>
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            *ngIf="!(isPackageDirty$ | async)"
            type="button"
            class="btn btn-success btn-lg btn-run-job"
            [hidden]="!(canRunJob | async)"
            (click)="runJob()"
          >
            {{ 'Run job' }}
          </button>
        </div>
        <div *ngxPermissionsExcept="'versionControl'" class="btn-group btn-group-lg pull-right margin-right">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            type="button"
            class="btn btn-default btn-save"
            (click)="save()"
            [ngClass]="{ 'btn-info': isPackageDirty$ | async }"
          >
            Save
          </button>
        </div>
        <div *ngxPermissionsOnly="'versionControl'" class="btn-group btn-group-lg pull-right margin-right dropdown">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            id="import-export-select"
            type="button"
            class="btn btn-default dropdown-toggle btn-save"
            [ngClass]="{ 'btn-info': isPackageDirty$ | async }"
            [matMenuTriggerFor]="saveDropdown"
          >
            Save
          </button>
          <mat-menu
            xPosition="before"
            #saveDropdown="matMenu"
            class="dropdown-menu-new wide-elements"
            aria-labelledby="import-export-select"
          >
            <li mat-menu-item [ngClass]="{ disabled: !(isPackageDirty$ | async) }">
              <a class="link-save" (click)="save()">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.77778 11V6.55556H3.22222V11M3.22222 1V3.77778H7.66667M9.88889 11H2.11111C1.81643 11 1.53381 10.8829 1.32544 10.6746C1.11706 10.4662 1 10.1836 1 9.88889V2.11111C1 1.81643 1.11706 1.53381 1.32544 1.32544C1.53381 1.11706 1.81643 1 2.11111 1H8.22222L11 3.77778V9.88889C11 10.1836 10.8829 10.4662 10.6746 10.6746C10.4662 10.8829 10.1836 11 9.88889 11Z"
                    stroke="#3D4651"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Quick save
              </a>
            </li>
            <li mat-menu-item>
              <a (click)="createMajorVersion()">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.25628 1.29289C5.42038 1.10536 5.64294 1 5.875 1H11C11.5523 1 12 1.44772 12 2V10C12 10.2652 11.9078 10.5196 11.7437 10.7071C11.5796 10.8946 11.3571 11 11.125 11H5.875C5.64294 11 5.42038 10.8946 5.25628 10.7071C5.09219 10.5196 5 10.2652 5 10V2C5 1.73478 5.09219 1.48043 5.25628 1.29289Z"
                    stroke="#3D4651"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 2H1.63158C1.46407 2 1.30343 2.08429 1.18499 2.23431C1.06654 2.38434 1 2.58783 1 2.8V9.2C1 9.41217 1.06654 9.61566 1.18499 9.76569C1.30343 9.91571 1.46407 10 1.63158 10H3"
                    stroke="#3D4651"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Create Major Version
              </a>
            </li>
          </mat-menu>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right" *ngIf="!(isPackageValidating$ | async)">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            type="button"
            class="btn btn-default btn-validate"
            (click)="validate()"
            matTooltip="Save and validate"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 7.36V8.004C14.9991 9.5135 14.5103 10.9823 13.6065 12.1913C12.7027 13.4003 11.4323 14.2847 9.98475 14.7127C8.5372 15.1407 6.99008 15.0893 5.57413 14.5662C4.15818 14.0431 2.94926 13.0763 2.12767 11.8099C1.30609 10.5436 0.915854 9.04565 1.01517 7.53942C1.11449 6.0332 1.69804 4.59943 2.6788 3.45196C3.65955 2.30448 4.98495 1.50477 6.45733 1.17211C7.92971 0.839444 9.47018 0.991643 10.849 1.60601M15 2.40401L8 9.411L5.9 7.311"
                stroke="#3D4651"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right" *ngIf="isPackageValidating$ | async">
          <button
            type="button"
            class="btn btn-default btn-validate"
            matTooltip="Validation in progress"
            matTooltipPosition="below"
            matTooltipClass="below"
            [disabled]="true"
          >
            <i class="fa fa-refresh fa-spin fa-fw validation-icon"></i>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right">
          <button
            type="button"
            class="btn btn-default btn-lg btn-settings"
            (click)="packageVariables()"
            matTooltip="Set variables"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="4px" viewBox="0 0 20 4">
              <g>
                <circle style="fill: #3f3f3f;" cx="2" cy="2" r="2" />
                <circle style="fill: #3f3f3f;" cx="10" cy="2" r="2" />
                <circle style="fill: #3f3f3f;" cx="18" cy="2" r="2" />
              </g>
            </svg>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right">
          <button
            type="button"
            class="btn btn-default btn-undo"
            (click)="undo()"
            [disabled]="!(isUndoAvailable$ | async)"
            matTooltip="Undo"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16">
              <path
                d="M4.75 9L1 5.25L4.75 1.5"
                stroke="#3D4651"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="transparent"
              />
              <path
                d="M2 5.5H7C11.4183 5.5 15 9.08172 15 13.5V14.5"
                stroke="#3D4651"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="transparent"
              />
            </svg>
          </button>
          <button
            type="button"
            class="btn btn-default btn-redo"
            (click)="redo()"
            [disabled]="!(isRedoAvailable$ | async)"
            matTooltip="Redo"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16">
              <path
                d="M11.25 9L15 5.25L11.25 1.5"
                stroke="#3D4651"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="transparent"
              />
              <path
                d="M14 5.5H9C4.58172 5.5 1 9.08172 1 13.5V14.5"
                stroke="#3D4651"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="transparent"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right has-selection" *ngIf="hasSelection$ | async">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            type="button"
            class="btn btn-default"
            (click)="duplicateSelectedComponents()"
            matTooltip="Copy"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <i class="fa fa-copy"></i>
          </button>
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            type="button"
            class="btn btn-default"
            (click)="deleteSelectedComponents()"
            matTooltip="Remove"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <i class="fa fa-trash text-danger"></i>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right dropdown">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            id="components-select"
            type="button"
            class="btn btn-default dropdown-toggle"
            [matMenuTriggerFor]="selectDropdown"
          >
            Select <span class="badge components-select-badge"></span>
          </button>
          <mat-menu #selectDropdown="matMenu" class="dropdown-menu-new" aria-labelledby="components-select">
            <li mat-menu-item><a (click)="selectAllComponents()">All</a></li>
            <li mat-menu-item><a (click)="selectConnectedComponents()">Connected</a></li>
            <li mat-menu-item><a (click)="unselectAllComponents()">None</a></li>
          </mat-menu>
        </div>
        <div *ngxPermissionsExcept="'sourceControl'" class="btn-group btn-group-lg pull-right margin-right dropdown">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            id="import-export-select"
            type="button"
            class="btn btn-default dropdown-toggle"
            [matMenuTriggerFor]="importDropdown"
          >
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
              <use class="icon-import-export" width="22" height="22" href="#icon-import-export"></use>
            </svg>
            Import/Export
          </button>
          <mat-menu #importDropdown="matMenu" class="dropdown-menu-new" aria-labelledby="import-export-select">
            <li mat-menu-item>
              <a (click)="promoteSourceControlFeature()"><i class="fa fa-file-import"></i> Import JSON</a>
            </li>
            <li mat-menu-item>
              <a (click)="promoteSourceControlFeature()"><i class="fa fa-file-export"></i> Export JSON</a>
            </li>
          </mat-menu>
        </div>
        <div *ngxPermissionsOnly="'sourceControl'" class="btn-group btn-group-lg pull-right margin-right dropdown">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            id="import-export-select"
            type="button"
            class="btn btn-default dropdown-toggle"
            [matMenuTriggerFor]="importSourceControlDropdown"
          >
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
              <use class="icon-import-export" width="22" height="22" href="#icon-import-export"></use>
            </svg>
            Import/Export
          </button>
          <mat-menu
            #importSourceControlDropdown="matMenu"
            class="dropdown-menu-new"
            aria-labelledby="import-export-select"
          >
            <li mat-menu-item>
              <a (click)="importJSON()"><i class="fa fa-file-import"></i> Import JSON</a>
            </li>
            <li mat-menu-item>
              <a class="link-version-validate" (click)="exportJSON()"><i class="fa fa-file-export"></i> Export JSON</a>
            </li>
          </mat-menu>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            type="button"
            class="btn btn-default btn-comment"
            (click)="addNote()"
            matTooltip="Add a Note"
            matTooltipPosition="below"
            matTooltipClass="below"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 14 14">
              <g id="icon-package-comment">
                <path
                  d="M13 9C13 9.35362 12.8595 9.69276 12.6095 9.94281C12.3594 10.1929 12.0203 10.3333 11.6667 10.3333H3.66667L1 13V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V9Z"
                  stroke="#3D4651"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="transparent"
                />
              </g>
            </svg>
          </button>
        </div>
        <div class="btn-group btn-group-lg pull-right margin-right">
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            *ngIf="packageItem.flow_type === 'dataflow'"
            type="button"
            class="btn btn-default btn-add-component"
            (click)="addComponent()"
            id="add-component"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="13px" height="13px" viewBox="0 0 13 13">
              <polygon style="fill: #3f3f3f;" points="10,4 6,4 6,0 4,0 4,4 0,4 0,6 4,6 4,10 6,10 6,6 10,6 " />
            </svg>
            Add component
          </button>
          <button
            [disabled]="!(hasPermission$('updatePackage') | async)"
            *ngIf="packageItem.flow_type === 'workflow'"
            type="button"
            class="btn btn-default btn-add-component"
            (click)="addComponent(true)"
            id="add-component"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="13px" height="13px" viewBox="0 0 13 13">
              <polygon style="fill: #3f3f3f;" points="10,4 6,4 6,0 4,0 4,4 0,4 0,6 4,6 4,10 6,10 6,6 10,6 " />
            </svg>
            Add task
          </button>
        </div>
      </div>
    </fieldset>
  `,
})
export class DesignerControlsComponent implements OnInit, OnDestroy {
  package$ = this.store.select(selectPackage);
  hasSelection$ = this.store.select(selectHasSelection);
  isUndoAvailable$ = this.store.select(selectIsUndoAvailable);
  isRedoAvailable$ = this.store.select(selectIsRedoAvailable);
  isPackageDirty$ = this.store.select(selectIsPackageDirtyFlag);
  isPackageValidating$ = this.store.select(selectIsRunningValidationFlag);
  accountPermissions$ = this.store.select(selectAccountPermissions);
  isVersionView = false;
  routeSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private notifyService: NotifyService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private unlockFeaturesDialog: UnlockFeaturesDialogService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(({ version }) => {
      this.isVersionView = !!version;
    });
  }

  addComponent(isWorkflow = false) {
    this.store.dispatch(
      openComponentsModal({
        flags: {
          hasSources: !isWorkflow,
          hasTransformations: !isWorkflow,
          hasDestinations: !isWorkflow,
          hasWorkflow: isWorkflow,
        },
      }),
    );
  }

  duplicateSelectedComponents() {
    this.store.dispatch(duplicateComponentsAction({}));
  }

  deleteSelectedComponents() {
    this.store.dispatch(removeComponents({}));
  }

  undo() {
    this.store.dispatch(undoAction());
  }

  redo() {
    this.store.dispatch(redoAction());
  }

  selectAllComponents() {
    this.store.dispatch(selectAllComponents());
  }

  selectConnectedComponents() {
    this.store.dispatch(selectConnectedComponents());
  }

  unselectAllComponents() {
    this.store.dispatch(unselectAllComponents());
  }

  packageVariables() {
    this.store.dispatch(openPackageVariablesModal());
  }

  runJob(isPackageDirty?: boolean) {
    if (isPackageDirty) {
      this.store.dispatch(savePackage({ openJobModal: true }));
    } else {
      this.store.dispatch(openJobsModal());
    }
  }

  editPackage() {
    this.store.dispatch(openPackagesModal());
  }

  openVersionsModal() {
    this.store.dispatch(openPackageVersionsModal());
  }

  addNote() {
    this.store.dispatch(createNoteAction());
  }

  promoteSourceControlFeature() {
    this.notifyService.info(
      `Please contact <a class="support-link">Support</a> or your Account Manager to activate Source Control feature.`,
      'Source Control is disabled',
    );

    setTimeout(() => {
      document.querySelector('.support-link').addEventListener('click', function () {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (window['Intercom']) {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          window['Intercom']('show');
        }
      });
    }, 100);
  }

  exportJSON() {
    this.unlockFeaturesDialog.openDialog({});
  }

  importJSON() {
    this.unlockFeaturesDialog.openDialog({});
  }

  createMajorVersion() {
    this.store.dispatch(openPackageVersionMessageModal());
  }

  save() {
    this.store.dispatch(savePackage({}));
  }

  validate() {
    this.store.dispatch(savePackage({ runValidation: true }));
  }

  get canRunJob() {
    return this.accountPermissions$.pipe(map((permissions) => permissions.includes('createJob')));
  }

  hasPermission$(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
