import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../store';
import { getComponentParents } from '../helpers/component-schema.helpers';
import { PackageDesignerState } from './state.model';

export const selectPackageDesignerState = createFeatureSelector<PackageDesignerState>('packageDesigner');
export const selectPackage = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => state.package);
export const selectPackageId = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.package.id,
);
export const selectComponent = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.component,
);

export const selectHasSelection = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.hasSelection,
);
export const selectIsUndoAvailable = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isUndoAvailable,
);
export const selectIsRedoAvailable = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isRedoAvailable,
);
export const selectComponentsFromPackage = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => (state.package || {}).components || [],
);

export const selectFixedComponentById = (componentId: string) =>
  createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
    return state.fixedComponents[componentId];
  });

export const selectTempComponentPositionById = (componentId: string) =>
  createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
    return state.componentsPositions[componentId];
  });

export const selectTempComponenSizeById = (componentId: string) =>
  createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
    return state.componentsSizes[componentId];
  });

export const selectFixedEdgeById = (edgeId: string) =>
  createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
    return state.fixedEdges[edgeId];
  });

export const selectEdgesFromPackage = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => (state.package || {}).edges || [],
);

export const selectRawComponent = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.rawComponent,
);

export const selectPackageAndComponent = createSelector(selectPackage, selectComponent, (packageItem, component) => ({
  packageItem,
  component,
}));

export const selectVariables = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  return state.flags.didUserSaveVariables ? state.variables : state.package.variables;
});

export const selectSecretVariables = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  return state.flags.didUserSaveVariables ? state.secret_variables : state.package.secret_variables;
});

export const selectIsComponentsModalOpen = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isComponentsModalOpen,
);

export const selectIsPackageVariablesModalOpen = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isPackageVariablesModalOpen,
);

export const selectIsPackageImportModalOpen = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isPackageImportModalOpen,
);

export const selectIsPackageVersionMessageModalOpen = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isPackageVersionMessageModalOpen,
);

export const selectComponentFormValidFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => {
    return state.isComponentFormValid && state.isComponentNameFormValid;
  },
);

export const selectIsPackageDirtyFlag = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  return state.isPackageDirty;
});

export const selectComponentParentSchemas = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => {
    return state.componentParentSchemas;
  },
);

export const selectIsDraggingFlag = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  return state.isDragging;
});

export const selectComponentParents = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  if (!state.component) {
    return [];
  }
  return getComponentParents(state.component.id, state.package.components, state.package.edges);
});

export const selectComponentInputEdgesLength = (componentId: string) =>
  createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
    return (state.package.edges || []).filter((edge) => edge.target === componentId).length;
  });

export const selectComponentOutputEdgesLength = (componentId: string) =>
  createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
    return (state.package.edges || []).filter((edge) => edge.source === componentId).length;
  });

export const selectComponentsModalFlags = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  return state.componentsModalFlags;
});

export const selectIsErrorsViewerVisibleFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => {
    return state.isErrorsViewerVisible;
  },
);

export const selectIsRunningValidationFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => {
    return state.isValidatingPackage;
  },
);

export const selectValidationErrors = createSelector(selectPackageDesignerState, (state: PackageDesignerState) => {
  return state.validationErrors;
});

export const selectIsExpressionOpenFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.isExpressionEditorOpen,
);

export const selectExpressionData = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.expressionEditorData,
);

export const selectExpressionEditorSaveCode = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.expressionEditorSaveCode,
);

export const isAnyModalOpen = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) =>
    state.isComponentsModalOpen ||
    state.isPackageImportModalOpen ||
    state.isPackageVariablesModalOpen ||
    state.isPackageVersionMessageModalOpen ||
    state.isExpressionEditorOpen,
);
