import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { FieldsCollectionValidationsService } from '../../../../common/services/fields-collection-validations.service';
import { ValidationsEnum } from '../../../../common/services/validations.enum';
import { ValidatorData } from '../../../../common/validators/helpers/validator-data.model';

@Component({
  selector: 'xp-database-column-editor',
  template: `
    <div class="database-column-editor alias-editor" [ngClass]="state">
      <div class="editor-button">
        <div class="base-icon">
          <i class="fa fa-magic" (click)="autoGenerate()"></i>
        </div>
      </div>
      <xp-input
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        [state]="state"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        [tooltip]="tooltip"
        [isFocused]="focusedProp === propName"
        [autoCompleteOptions]="availableColumns"
        [autoCompleteDescription]="availableColumns.length ? 'Existing columns on the destination table' : ''"
        placeholder=""
        name="alias"
      ></xp-input>
    </div>
  `,
  providers: [],
})
export class XpDatabaseColumnEditorComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() index: number;
  @Input() focusedProp: string;
  @Input() propName: string;
  @Input() isDuplicateError: boolean;
  @Input() outsideValidationMessage: string;
  @Input() availableColumns: string[];
  @Output() fieldChange = new EventEmitter();
  validate: (...args: any) => ValidatorData;
  tooltip = '';
  placeholder: string;
  fields: any[];
  schema: any;
  state: string;
  isFirstChange = true;

  constructor(
    private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.validate = this.fieldsCollectionValidationsService.getValidationFunction(
      ValidationsEnum.DATABASE_COLUMN_EDITOR,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.validityFn(changes.value.currentValue);
    }

    if (!changes.value && (changes.isDuplicateError || changes.isOutsideDuplicateError)) {
      this.validityFn(this.value);
    }
  }

  onValueChange(value: string) {
    if (this.value === value) {
      return;
    }
    this.value = value;

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.fieldChange.emit(this.value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }

  validityFn(value: string): boolean {
    if (this.xpFieldsCollectionComponent && this.xpFieldsCollectionComponent.isRecordPristine(this.index)) {
      return false;
    }

    if (typeof this.validate === 'function') {
      const validityObject = this.validate(value, this.schema);
      this.setValidity(validityObject);
      return validityObject.valid;
    }

    return false;
  }

  setValidity(validityObject: Partial<ValidatorData>) {
    const isValid = validityObject.valid && !this.isDuplicateError;

    if (!isValid) {
      this.state = 'error';
      if (this.isDuplicateError) {
        this.tooltip = this.translate.instant('fields-collection.errors.duplicate_field', {
          fieldName: 'Alias',
        });
      } else {
        this.tooltip = validityObject.message;
      }
    } else {
      this.state = 'editable';
      this.tooltip = '';
    }

    this.registerError(isValid, this.tooltip);
  }

  registerError(valid: boolean, message: string) {
    if (this.xpFieldsCollectionComponent) {
      if (!valid) {
        this.xpFieldsCollectionComponent.registerError(this.index, message, this.propName);
      } else {
        this.xpFieldsCollectionComponent.removeError(this.index, this.propName);
      }
    }
  }

  autoGenerate() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.autoGenerate(this.index);
    }
  }
}
