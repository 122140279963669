export enum HookTypesEnum {
  slack = 'slack',
  email = 'email',
  pagerduty = 'pagerduty',
  web = 'web',
}

export interface Hook {
  active: boolean;
  events: string[];
  id: number;
  name: string;
  salt: string;
  settings: {
    emails?: string;
    room?: string;
    auth_token?: string;
    url?: string;
    pd_account?: string;
    service_key?: string;
    service_name?: string;
    channel?: string;
    team?: string;
  };
  type: HookTypesEnum;
  pingLoading?: boolean;
  pingError?: string;
  pingSuccess?: boolean;
  isRemoved?: boolean;
}

interface Group {
  group_type: string;
  group_name: string;
}
export interface HookType {
  description: string;
  groups: Group[];
  icon_url: string;
  name: string;
  type: HookTypesEnum;
}

export interface HookEvent {
  group_name: string;
  id: string;
  name: string;
}
