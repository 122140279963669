import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { functionsList } from '../helpers/functions-list';

@Component({
  selector: 'xplenty-console-functions',
  template: `
    <div id="functions-modal">
      <div class="modal-header">
        <h3 class="title">Functions <small>click on a function to submit a sample command</small></h3>
      </div>
      <div class="modal-body">
        <p>
          <input
            type="text"
            class="input-full search-query filter"
            placeholder="Filter functions..."
            (input)="onSearch($event)"
          />
        </p>
        <div class="functions-list">
          <ul class="nav nav-tabs nav-stacked functions">
            <li *ngFor="let functionItem of functionsList" class="function">
              <div (click)="close(functionItem.console)" class="function-container">
                <span class="truncate title">{{ functionItem.name }}</span>
                <small *ngIf="functionItem.category" class="light-gray pull-right">{{ functionItem.category }}</small>
                <div class="ignore-filter description muted">{{ functionItem.description }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <footer class="modal-footer">
        <button (click)="close()" class="cancel btn btn-primary">Close</button>
        <a
          class="btn btn-link help pull-left"
          href="https://www.integrate.io/docs/etl/using-expressions-in-xplenty/"
          target="_blank"
          ><i class="icon fa fa-question-circle pull-left on-left"></i> Need help?</a
        >
      </footer>
    </div>
  `,
})
export class XplentyConsoleFunctionsComponent {
  query = '';
  functionsList = functionsList.filter((item) => item.console).sort((a, b) => (a.name > b.name ? 1 : -1));
  functionsListRaw = this.functionsList.slice();

  constructor(public dialogRef: MatDialogRef<XplentyConsoleFunctionsComponent>) {}

  onSearch(event: any) {
    const queryLC = event.target.value.toLowerCase();
    this.functionsList = this.functionsListRaw.filter((functionItem) => {
      return (
        functionItem.name.toLowerCase().includes(queryLC) ||
        functionItem.category.toLowerCase().includes(queryLC) ||
        functionItem.description.toLowerCase().includes(queryLC)
      );
    });
  }

  close(functionName?: string) {
    this.dialogRef.close(functionName);
  }
}
