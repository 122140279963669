import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import './resources/packages.resource';
import { packagesReducer } from './store/packages.reducer';
import { MY_WINDOW_TOKEN, PackagesEffects } from './store/packages.effects';
import { CommonModule } from '../common/common.module';
import { ClustersModule } from '../clusters/clusters.module';
import { JobsModule } from '../jobs/jobs.module';
import { PackagesListComponent } from './components/packages-list.component';
import { PackageListItemComponent } from './components/package-list-item.component';
import { PackageWorkspaceFormComponent } from './components/package-workspace-form.component';
import { PackagesComponent } from './packages.component';

@NgModule({
  declarations: [PackagesListComponent, PackageListItemComponent, PackageWorkspaceFormComponent, PackagesComponent],
  imports: [
    BrowserModule,
    FormsModule,
    StoreModule.forFeature('packages', packagesReducer),
    EffectsModule.forFeature([PackagesEffects]),
    CommonModule,
    TranslateModule,
    NgxPermissionsModule,
    MatTooltipModule,
    MatMenuModule,
    ClustersModule,
    JobsModule,
  ],
  providers: [{ provide: MY_WINDOW_TOKEN, useValue: window }, provideHttpClient(withInterceptorsFromDi())],
})
export class PackagesModule {}
