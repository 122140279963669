import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'xpPrettyDate' })
export class XpPrettyDatePipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): string {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return value ? moment(value).utcOffset(window['DEFAULT_TIMEZONE']).fromNow() : '';
  }
}
