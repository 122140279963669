import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { logout, updateAccountInfo } from './account.actions';
import { AuthService } from '../../common/services/auth.service';
import { BoomerangService } from '../services/boomerang.service';

@Injectable({
  providedIn: 'root',
})
export class AccountEffects {
  loadPermissions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAccountInfo),
        mergeMap(({ account }) => {
          if (account.permissions) {
            this.permissionsService.loadPermissions(account.permissions);
          }
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.boomerangService.logout();
          this.authService.logout().subscribe(() => {
            this.router.navigate(['/auth/login']);
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private permissionsService: NgxPermissionsService,
    private authService: AuthService,
    private router: Router,
    private boomerangService: BoomerangService,
  ) {}
}
