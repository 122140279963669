import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { Job, JobLog, JobOutputPreview } from '../jobs.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class JobsResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  get(job_id: number, params?: ListParams): Observable<Job> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Job>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}`, { params: params as QueryParams }),
      ),
    );
  }

  save(body: Partial<Job>, params?: ListParams): Observable<Job> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Job>(`${this.apiRoot}/${account_id}/api/jobs`, body, { params: params as QueryParams }),
      ),
    );
  }

  update(job_id: number, body: Partial<Job>, params?: ListParams): Observable<Job> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<Job>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}`, body, { params: params as QueryParams }),
      ),
    );
  }

  remove(job_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<Job>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}`, { params: params as QueryParams }),
      ),
    );
  }

  query(params?: ListParams): Observable<Job[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Job[]>(`${this.apiRoot}/${account_id}/api/jobs`, { params: params as QueryParams }),
      ),
    );
  }

  search(params?: ListParams): Observable<Job[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Job[]>(`${this.apiRoot}/${account_id}/api/jobs/search`, { params: params as QueryParams }),
      ),
    );
  }

  children(job_id: number, params?: ListParams): Observable<Job[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Job[]>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}/children`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  log(job_id: number, params?: ListParams): Observable<JobLog> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<JobLog>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}/log`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  variables(job_id: number, params?: ListParams): Observable<Job> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Job>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}/variables`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  explain(job_id: number, params?: ListParams): Observable<{ message: string }> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<{ message: string }>(`${this.apiRoot}/${account_id}/api/jobs/${job_id}/explain`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  output(output_id: number, job_id: number, params?: ListParams): Observable<JobOutputPreview> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<JobOutputPreview>(
          `${this.apiRoot}/${account_id}/api/jobs/${job_id}/outputs/${output_id}/preview`,
          { params: params as QueryParams },
        ),
      ),
    );
  }

  vote(job_id: number, vote: 'disliked' | 'liked', params?: ListParams): Observable<JobLog> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<JobLog>(
          `${this.apiRoot}/${account_id}/api/jobs/${job_id}/vote`,
          { vote },
          {
            params: params as QueryParams,
          },
        ),
      ),
    );
  }
}
