import * as VS from '../helpers/validators.helpers';
import { ValidatorRunner } from './models';

const settings = [
  { name: 'in', fn: VS.isNotIncludedIn },
  { name: 'within', fn: VS.isNotIncludedIn },
];

export const ExclusionValidator: ValidatorRunner = {
  name: 'ExclusionValidator',
  message: 'errors.ExclusionValidator',
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    // common validator options
    if (VS.isTrue(options.allow_nil) && VS.isNull(value)) return true;
    if (VS.isTrue(options.allow_blank) && VS.isEmpty(value)) return true;

    return settings
      .map(function (o) {
        return VS.isUndefined(options[o.name]) || o.fn.call(null, value, options[o.name]);
      })
      .every(function (result) {
        return result;
      });
  },
};
