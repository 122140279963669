import { HttpErrorResponse } from '@angular/common/http';
import { ValidationError } from '../../config/validation-error.model';

export function getErrorFromResponse(response: HttpErrorResponse): ValidationError[] {
  if (response.error?.errors) {
    return response.error.errors;
  }

  if (response.error?.message) {
    return [{ message: response.error.message }];
  }

  if (response.error?.error_message) {
    return [{ message: response.error.error_message }];
  }

  return [{ message: response.message }];
}
