import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { selectMember, selectMembersLoadingFlag } from '../../store/members/members.selectors';
import { AppState } from '../../../store';
import { getMember } from '../../store/members/members.actions';

@Component({
  selector: 'members-item',
  template: `
    <div class="members-item">
      <xp-loader *ngIf="memberLoading$ | async"></xp-loader>
      <div *ngIf="member$ | async as member">
        <img class="img-circle" [src]="member.avatar_url" [alt]="member.name" />
        <h1>{{ member.name || member.email }}</h1>
        <p>{{ member.email }}</p>
        <p *ngIf="member.confirmed_at">
          Joined on
          <span class="date-tooltip" [matTooltip]="member.confirmed_at | xpDateUTC" matTooltipPosition="above">{{
            member.confirmed_at | xpDate
          }}</span>
        </p>
        <p *ngIf="!member.confirmed_at">Not Confirmed</p>
      </div>
    </div>
  `,
})
export class MembersItemComponent {
  memberLoading$ = this.store.select(selectMembersLoadingFlag);
  member$ = this.store.select(selectMember);

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.store.dispatch(getMember({ memberId: this.route.snapshot.params.member_id }));
  }
}
