import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { map } from 'lodash';
import { AggregateComponentData, AllComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { CollectionSettings } from '../fields-collection/fields-collection.models';

@Component({
  selector: 'aggregate-editor',
  template: `
    <div class="aggregate-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::AggregateComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'aggregate-editor.form.labels.group_by' | translate }}</strong></label
              >
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.grouping_type"
                    name="group-by"
                    id="group-all"
                    value="all"
                    (ngModelChange)="onGroupingTypeChange($event)"
                  />
                  <label for="group-all">{{ 'aggregate-editor.form.radiolist.group_by.group_all' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.grouping_type"
                    name="group-by"
                    id="group-by"
                    value="group_by"
                    (ngModelChange)="onGroupingTypeChange($event)"
                  />
                  <label for="group-by">{{ 'aggregate-editor.form.radiolist.group_by.group_by' | translate }}</label>
                </div>
              </div>
              <div *ngIf="rawComponent.grouping_type === 'group_by'">
                <group-by-collection
                  [records]="rawComponent.grouped_fields"
                  (recordsChange)="onGroupedRecordsChange($event)"
                  [collectionSettings]="groupByCollectionSettings"
                  [parentSchemas]="parentSchemas"
                  fieldsName="grouped_fields"
                  [isVisible]="rawComponent.grouping_type === 'group_by'"
                  (validityChange)="onGroupedValidityChange($event)"
                ></group-by-collection>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'aggregate-editor.form.labels.aggregate_fields' | translate }}</strong></label
              >
              <br />
              <aggregate-collection
                [records]="rawComponent.aggregated_fields"
                (recordsChange)="onAggregatedRecordsChange($event)"
                [collectionSettings]="aggregateCollectionSettings"
                [parentSchemas]="parentSchemas"
                fieldsName="aggregated_fields"
                (validityChange)="onAggregateValidityChange($event)"
              ></aggregate-collection>
            </div>
          </div>
        </form>
      </xp-form-validation>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class AggregateEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: AggregateComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  aggregateCollectionValid = false;
  groupByCollectionValid = false;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  groupByCollectionSettings: Partial<CollectionSettings> = {
    outsideSourceValidation: {
      unique: {
        validation: [],
        message: this.translate.instant('aggregate-editor.form.errors.duplicate_fields'),
      },
    },
  };

  aggregateCollectionSettings: Partial<CollectionSettings> = {
    outsideSourceValidation: {
      unique: {
        validation: [],
        message: this.translate.instant('aggregate-editor.form.errors.duplicate_fields'),
      },
    },
  };

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });
  }

  onAggregatedRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { aggregated_fields: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { aggregated_fields: records } }));
    this.groupByCollectionSettings.outsideSourceValidation.unique.validation = map(records, 'alias');
    this.groupByCollectionSettings = { ...this.groupByCollectionSettings };
  }

  onGroupedRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { grouped_fields: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { grouped_fields: records } }));
    this.aggregateCollectionSettings.outsideSourceValidation.unique.validation = map(records, 'field_name');
    this.aggregateCollectionSettings = { ...this.aggregateCollectionSettings };
  }

  onValidityChange() {
    const isValid =
      !(this.rawComponent.grouping_type === 'group_by' && !this.groupByCollectionValid) &&
      this.aggregateCollectionValid &&
      this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onAggregateValidityChange(value) {
    this.aggregateCollectionValid = value;

    this.onValidityChange();
  }

  onGroupedValidityChange(value) {
    this.groupByCollectionValid = value;

    this.onValidityChange();
  }

  onGroupingTypeChange(grouping_type: string) {
    const previousValue = this.rawComponent.grouping_type;
    if (grouping_type === 'all') {
      this.aggregateCollectionSettings.outsideSourceValidation.unique.validation = [];
      this.aggregateCollectionSettings = { ...this.aggregateCollectionSettings };
    } else if (previousValue && previousValue !== grouping_type) {
      this.aggregateCollectionSettings.outsideSourceValidation.unique.validation = map(
        this.rawComponent.grouped_fields,
        'field_name',
      );
      this.aggregateCollectionSettings = { ...this.aggregateCollectionSettings };
    }

    const component: any = { grouping_type };
    if (grouping_type === 'all') {
      component.grouped_fields = [];
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { ...component },
      }),
    );
    this.store.dispatch(updateComponent({ component }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
