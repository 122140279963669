import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Connection, DatabaseDestinationComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { hasTableSchemaEndpointsHelper } from '../../../connections/helpers/connection-types.helpers';
import { getDatabaseColumns } from '../../../connections/store/connections.actions';

@Component({
  selector: 'database-destination-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
          <dynamic-connection [component]="rawComponent" *ngxPermissionsOnly="'dynamicConnection'"></dynamic-connection>
        </xp-step>
        <xp-step [step]="componentBaseStep">
          <div class="database-destination-editor destination-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::DatabaseDestinationComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <database-destination
                  [rawComponent]="rawComponent"
                  [component]="component"
                  [hasTableSchemaSelect]="hasTableSchemaSelect"
                ></database-destination>
                <database-advanced-options-destination
                  [rawComponent]="rawComponent"
                  [component]="component"
                  [schemaFields]="parentSchemas[0]?.fields || []"
                ></database-advanced-options-destination>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaMappingStep" (activate)="onSchemaStepActivate()">
          <schema-mapping-database-collection
            [records]="rawComponent.column_mappings"
            [parentSchemas]="parentSchemas"
            (validityChange)="onFieldsValidityChange($event)"
            (recordsChange)="onRecordsChange($event)"
            [operationType]="rawComponent.operation_type"
            [componentType]="component.componentType"
          ></schema-mapping-database-collection>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class DatabaseDestinationEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: DatabaseDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  fieldsCollectionValid = false;
  hasTableSchemaSelect = false;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaMappingStep: Step = getStep({});

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: isFormValid,
        tags: this.componentFormTagsService.getTags(
          {
            ...this.rawComponent,
            schema_name: !this.rawComponent.schema_name ? 'Default' : this.rawComponent.schema_name,
          },
          this.component,
        ),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      tags: this.componentFormTagsService.getTags(
        {
          ...this.rawComponent,
          schema_name: !this.rawComponent.schema_name ? 'Default' : this.rawComponent.schema_name,
        },
        this.component,
      ),
    });

    this.schemaMappingStep = getStep({
      title: this.translate.instant(`component-editor.step-schema-mapping.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(
        `component-editor.step-schema-mapping.${this.component.componentType}.active`,
      ),
      tags: (this.rawComponent.column_mappings || []).map((record) => ({
        name: escape(record.column_name),
      })),
    });

    if (typeof this.rawComponent.parallel === 'string') {
      this.store.dispatch(
        updateRawComponent({
          rawComponent: { parallel: parseInt(this.rawComponent.parallel, 10) },
        }),
      );
    }

    if (typeof this.rawComponent.batch_size === 'string') {
      this.store.dispatch(
        updateRawComponent({
          rawComponent: { batch_size: parseInt(this.rawComponent.batch_size, 10) },
        }),
      );
    }

    if (this.rawComponent.connection?.id) {
      this.hasTableSchemaSelect = hasTableSchemaEndpointsHelper(this.rawComponent.connection.type);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.rawComponent?.currentValue?.dynamic_connection !== changes.rawComponent?.previousValue?.dynamic_connection
    ) {
      if (this.rawComponent.dynamic_connection) {
        if (!this.connectionStep.tags.find((tag) => tag.name === this.component.dynamic_connection)) {
          this.connectionStep.tags = [
            ...this.connectionStep.tags,
            {
              name: this.component.dynamic_connection,
            },
          ];
        }
      } else {
        this.connectionStep.tags = this.connectionStep.tags.filter(
          (tag) => tag.name !== changes.component.previousValue.dynamic_connection,
        );
      }
      this.connectionStep = { ...this.connectionStep };
    }

    setTimeout(() => {
      this.componentBaseStep = {
        ...this.componentBaseStep,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
    });
  }

  onValidityChange() {
    const isValid = this.fieldsCollectionValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { column_mappings: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { column_mappings: records } }));

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      tags: this.fieldsCollectionValid
        ? records.map((record) => ({
            name: escape(record.column_name),
          }))
        : [],
    };
  }

  onFieldsValidityChange(value) {
    this.fieldsCollectionValid = value;

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      valid: this.fieldsCollectionValid && this.connectionStep.valid && this.isFormValid,
      isError: !this.fieldsCollectionValid,
    };

    this.onValidityChange();
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    if (this.rawComponent.dynamic_connection) {
      this.connectionStep.tags = [...this.connectionStep.tags, { name: this.rawComponent.dynamic_connection }];
    }

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.hasTableSchemaSelect = hasTableSchemaEndpointsHelper(connection.type);
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onSchemaStepActivate() {
    if (this.rawComponent.connection?.id) {
      this.store.dispatch(
        getDatabaseColumns({
          schemaName: this.rawComponent.schema_name,
          tableName: this.rawComponent.table_name,
          connectionId: this.rawComponent.connection?.id,
          connectionType: this.rawComponent.connection?.type,
        }),
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
