import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { FunctionPickerType, FUNCTION_PICKER } from '../../../../constants/function_picker';

@Component({
  selector: 'xp-function-picker',
  template: `
    <div class="function-picker">
      <xp-select
        [value]="value"
        (valueChange)="onValueChange($event)"
        [options]="functions"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        [preventEmpty]="true"
        style="width: 100%;"
      ></xp-select>
    </div>
  `,
  providers: [],
})
export class XpFunctionPickerComponent implements OnInit {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Input() componentType: FunctionPickerType;
  @Output() fieldChange = new EventEmitter();
  functions: SelectOption[] = [];
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  ngOnInit() {
    this.functions = FUNCTION_PICKER[this.componentType].map(({ translationPath, value }) => ({
      text: translationPath,
      value,
      translate: true,
    }));
  }

  onValueChange(value: ConditionOperator) {
    if (this.value === value || (!value && !this.value)) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.fieldChange.emit(value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
