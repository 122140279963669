import * as VS from '../helpers/validators.helpers';
import { ValidatorRunner } from './models';

const settings = [
  { name: 'only_integer', fn: VS.isInteger },
  { name: 'greater_than', fn: VS.isGreater },
  { name: 'greater_than_or_equal_to', fn: VS.isGreaterOrEqual },
  { name: 'equal_to', fn: VS.isEqual },
  { name: 'less_than', fn: VS.isLess },
  { name: 'less_than_or_equal_to', fn: VS.isLessOrEqual },
  { name: 'odd', fn: VS.isOdd },
  { name: 'even', fn: VS.isEven },
];

export const NumericalityValidator: ValidatorRunner = {
  name: 'NumericalityValidator',
  message: 'errors.NumericalityValidator',
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    const numValue = Number(value);

    // common validator options
    if (VS.isTrue(options)) return VS.isNumeric(numValue);
    if (VS.isFalse(options)) return VS.isNotNumeric(numValue);

    if (VS.isObject(options)) {
      // common validator options
      if (VS.isTrue(options.allow_nil) && VS.isNull(numValue)) return true;
      if (VS.isTrue(options.allow_blank) && VS.isEmpty(numValue)) return true;
    }

    return (
      VS.isNumeric(numValue) &&
      settings
        .map(function (o) {
          return VS.isUndefined(options[o.name]) || o.fn.call(null, Number(numValue), options[o.name]);
        })
        .every(function (result) {
          return result;
        })
    );
  },
};
