import { createReducer, on } from '@ngrx/store';
import * as dagre from 'dagre';
import { flatten, keyBy, uniq } from 'lodash';
import { setPackageFromJson, updateComponentInPackage, updatePackage } from './package.actions';
import {
  setComponent,
  setComponentNameFormValidity,
  setComponentValidity,
  updateComponent,
  updateRawComponent,
} from './component.actions';
import { addVariable, removeVariable, updateVariables } from './variables.actions';
import { setDidUserSaveVariablesFlag } from './flags.actions';
import { componentPreviewerReducer } from './component-previewer.reducer';
import { ComponentsSizes, PackageDesignerState } from './state.model';
import {
  autoAlign,
  clearHistory,
  clearPackageData,
  closeComponentsModal,
  closePackageImportModal,
  closePackageVariablesModal,
  closePackageVersionMessageModal,
  copyAction,
  createComponentAction,
  createEdge,
  createEdgeEndDrag,
  createNoteAction,
  cutAction,
  duplicateComponentsAction,
  hideErrorsViewer,
  hoverComponent,
  openComponentsModal,
  openPackageImportModal,
  openPackageVariablesModal,
  openPackageVersionMessageModal,
  pasteAction,
  redoAction,
  removeComponents,
  removeEdge,
  runValidationAction,
  runValidationError,
  selectAllComponents,
  selectComponents,
  selectConnectedComponents,
  switchParentSchemasOrder,
  undoAction,
  unselectAllComponents,
  updateComponentPosition,
  updateComponentTempPosition,
  updateEdgeTempPosition,
  validationStatusError,
  validationStatusSuccess,
  updateEdge,
  openExpressionEditor,
  closeExpressionEditor,
  resizeNoteComponent,
  resizeNoteComponentTemp,
} from './package-designer.actions';
import {
  updateComponentInPackageComponents,
  updateComponentsInPackageComponents,
  updateComponentsOrder,
  updateSchemaFromParent,
} from '../helpers/package.helpers';
import { savePackageResponse, updatePackageResponse } from '../../packages/store/packages.actions';
import { getAllComponentAncestors, getComponentParentsSchema } from '../helpers/component-schema.helpers';
import {
  createComponent,
  createNote,
  duplicateComponent,
  findComponentById,
  fixComponentData,
  getComponentId,
  getDataFromComponent,
  snapToGrid,
  uid,
  areComponentFieldsValid,
  updateFieldsAfterCopyPaste,
  getComponentType,
} from '../helpers/components.helpers';
import { updateEdgesPositions } from '../helpers/edges.helpers';
import { AllComponentData, Edge, TempComponent, EdgeEvent } from '../package.models';
import { DESIGNER_SETTINGS } from '../../constants/designer_settings';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { CLOUD_STORAGE_CONNECTION_TYPES } from '../../connections/connection.models';
import { closeAllModals } from '../../account/store/account.actions';

export const initialState: PackageDesignerState = {
  package: {},
  component: null,
  rawComponent: null,
  variables: {},
  secret_variables: {},
  flags: { didUserSaveVariables: false },
  isComponentFormValid: false,
  isComponentNameFormValid: false,
  componentPreviewer: {
    isError: false,
    isLoading: false,
    isInfo: false,
    logs: [],
    progress: 0,
    isValid: false,
    preview_id: null,
  },
  isComponentsModalOpen: false,
  isPackageVariablesModalOpen: false,
  isPackageImportModalOpen: false,
  isPackageVersionMessageModalOpen: false,
  isExpressionEditorOpen: false,
  expressionEditorData: {
    code: '',
    category: '',
  },
  expressionEditorSaveCode: '',
  isPackageDirty: false,
  componentParentSchemas: [],
  componentsPositions: {},
  componentsSizes: {},
  fixedComponents: {},
  fixedEdges: {},
  isDragging: false,
  parentComponentId: '',
  componentsModalFlags: {
    hasSources: false,
    hasTransformations: false,
    hasDestinations: false,
    hasWorkflow: false,
  },
  hasSelection: false,
  isUndoAvailable: false,
  isRedoAvailable: false,
  validationErrors: [],
  isErrorsViewerVisible: false,
  isValidatingPackage: false,
  copyData: {},
};

function mapArrayToObjectById(array: any[]): any {
  return keyBy(array, 'id');
}

const STATE_HISTORY = {
  undo: [],
  redo: [],
};

export const packageDesignerReducer = createReducer<PackageDesignerState>(
  initialState,
  on(setPackageFromJson, (state, { packageItem }) => {
    const dataFlowJSON = JSON.parse(packageItem.data_flow_json) || {};
    const packageVariables = { ...(dataFlowJSON.variables || {}), ...(packageItem.variables || {}) };

    const newPackageItem = packageItem ? { ...state.package, ...packageItem, ...dataFlowJSON } : state.package;
    newPackageItem.variables = packageVariables;

    const edgesWithoutId = (newPackageItem.edges || []).filter((edge) => !edge.id);

    if (edgesWithoutId.length > 0) {
      newPackageItem.edges = newPackageItem.edges.map((edge) => ({ ...edge, id: `edge-${uid()}` }));
    }

    return {
      ...state,
      package: newPackageItem,
      variables: {},
      secret_variables: {},
      componentPreviewer: {
        isError: false,
        isLoading: false,
        isInfo: false,
        logs: [],
        progress: 0,
        isValid: false,
      },
      fixedComponents: mapArrayToObjectById((newPackageItem.components || []).map(fixComponentData)),
      fixedEdges: mapArrayToObjectById(
        (newPackageItem.edges || []).map(updateEdgesPositions(newPackageItem.components, state.componentsPositions)),
      ),
      flags: {
        didUserSaveVariables: false,
      },
    };
  }),
  on(clearPackageData, (state) => ({
    ...state,
    package: {},
    variables: {},
    componentPreviewer: {
      isError: false,
      isLoading: false,
      isInfo: false,
      logs: [],
      progress: 0,
      isValid: false,
    },
    fixedComponents: {},
    fixedEdges: {},
  })),
  on(updatePackage, (state, { packageItem }) => {
    const newPackageItem = { ...state.package, ...packageItem };
    const isPackageDirty = packageItem.name !== state.package.name;

    if (isPackageDirty) {
      STATE_HISTORY.undo.push(state);
    }

    return {
      ...state,
      package: newPackageItem,
      isPackageDirty,
      fixedComponents: mapArrayToObjectById((newPackageItem.components || []).map(fixComponentData)),
      fixedEdges: mapArrayToObjectById(
        (newPackageItem.edges || [])
          .map(updateEdgesPositions(newPackageItem.components, state.componentsPositions))
          .filter(Boolean),
      ),
    };
  }),
  on(updateComponentInPackage, (state, { changedComponentsOrder }) => {
    let rawComponent = { ...state.rawComponent };
    if (rawComponent.connection) {
      rawComponent.connection = {
        id: rawComponent.connection.id,
        type: rawComponent.connection.type,
        name: rawComponent.connection.name,
        unique_id: rawComponent.connection.unique_id,
      };
      const connectionAttributePrefix = CLOUD_STORAGE_CONNECTION_TYPES.includes(rawComponent.connection.type)
        ? 'cloud_storage_'
        : 'database_';
      const connectionAttributeName = `${connectionAttributePrefix}connection_id`;
      rawComponent[connectionAttributeName] = String(rawComponent.connection.id);
    }

    const oldComponent =
      state.package.components.find((item) => getDataFromComponent(item).id === state.rawComponent.id) || ({} as any);
    const hasNameChanged = rawComponent.name !== oldComponent.name;
    const hasPackageChanged = rawComponent.package_id !== oldComponent.package_id;
    const hasQueryChanged = rawComponent.query !== oldComponent.query;

    let components = updateComponentInPackageComponents(state.package.components, rawComponent);

    const fileStorageDestinationComponents = components.filter(
      (component) => getComponentType(component) === COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT,
    );
    const fileStorageDestinationComponentsData = fileStorageDestinationComponents.map((component) =>
      updateSchemaFromParent(component, components, state.package.edges),
    );

    fileStorageDestinationComponentsData.forEach((component) => {
      components = updateComponentInPackageComponents(components, component);
    });

    const newPackageItem = {
      ...state.package,
      components,
      edges: changedComponentsOrder
        ? updateComponentsOrder(state.package.edges, changedComponentsOrder)
        : state.package.edges,
    };
    const isNoteComponent = state.rawComponent.id.includes('note');
    let { fixedComponents } = state;

    if (isNoteComponent) {
      fixedComponents = {
        ...fixedComponents,
        [state.rawComponent.id]: {
          ...fixedComponents[state.rawComponent.id],
          description: state.rawComponent.description,
        },
      };
    }

    if (hasNameChanged || hasPackageChanged || hasQueryChanged) {
      fixedComponents = {
        ...fixedComponents,
        [state.rawComponent.id]: {
          ...fixedComponents[state.rawComponent.id],
          name: state.rawComponent.name,
          package_id: state.rawComponent.package_id,
          query: state.rawComponent.query,
        },
      };
    }

    return {
      ...state,
      package: newPackageItem,
      fixedComponents,
      isPackageDirty: true,
    };
  }),
  on(setComponent, (state, { component, rawComponent }) => ({
    ...state,
    component,
    rawComponent,
    componentPreviewer: {
      isError: false,
      isLoading: false,
      isInfo: false,
      logs: [],
      progress: 0,
      isValid: false,
    },
    componentParentSchemas: getComponentParentsSchema(
      (state.package.components || []).find(findComponentById(component.id)),
      state.package.components,
      state.package.edges,
    ),
  })),
  on(openExpressionEditor, (state, data) => ({
    ...state,
    isExpressionEditorOpen: true,
    expressionEditorData: data,
  })),
  on(closeExpressionEditor, (state, { code }) => ({
    ...state,
    isExpressionEditorOpen: false,
    data: {},
    expressionEditorSaveCode: code,
  })),
  on(switchParentSchemasOrder, (state) => ({
    ...state,
    componentParentSchemas: [...state.componentParentSchemas].reverse(),
  })),
  on(setComponentValidity, (state, { isComponentFormValid }) => {
    const isComponentFormValidValue = isComponentFormValid && areComponentFieldsValid(state.rawComponent);

    return {
      ...state,
      isComponentFormValid: isComponentFormValidValue,
    };
  }),
  on(setComponentNameFormValidity, (state, { isComponentNameFormValid }) => ({
    ...state,
    isComponentNameFormValid,
  })),
  on(updateComponent, (state, { component }) => {
    const newComponent = { ...state.component, ...component };
    if (component.predicates) {
      newComponent.predicates = { ...state.component.predicates, ...component.predicates };
    }
    return {
      ...state,
      component: newComponent,
    };
  }),
  on(updateRawComponent, (state, { rawComponent }) => {
    const newComponent = { ...state.rawComponent, ...rawComponent };
    return {
      ...state,
      rawComponent: newComponent,
    };
  }),
  on(updateVariables, (state, { variables, secretVariables, secretVariablesToUpdate }) => ({
    ...state,
    variables,
    secret_variables: secretVariables,
    package: { ...state.package, variables, secret_variables: secretVariablesToUpdate },
    isPackageDirty: true,
  })),
  on(addVariable, (state, { variables }) => ({
    ...state,
    variables: {
      ...state.variables,
      ...variables,
    },
    package: {
      ...state.package,
      variables: {
        ...state.package.variables,
        ...variables,
      },
    },
    isPackageDirty: true,
  })),
  on(removeVariable, (state, { variableName }) => {
    const variables = { ...state.variables };
    delete variables[variableName];
    const packageVariables = { ...state.package.variables };
    delete packageVariables[variableName];
    return {
      ...state,
      variables,
      package: {
        ...state.package,
        variables: {
          ...variables,
          ...packageVariables,
        },
      },
      isPackageDirty: true,
    };
  }),
  on(setDidUserSaveVariablesFlag, (state, { value }) => ({
    ...state,
    flags: {
      ...state.flags,
      didUserSaveVariables: value,
    },
  })),
  on(openComponentsModal, (state, { flags, parentComponentId }) => ({
    ...state,
    isComponentsModalOpen: true,
    componentsModalFlags: flags || state.componentsModalFlags,
    parentComponentId,
  })),
  on(closeComponentsModal, (state) => ({
    ...state,
    isComponentsModalOpen: false,
    component: null,
    rawComponent: null,
    componentParentSchemas: [],
  })),
  on(openPackageVariablesModal, (state) => ({
    ...state,
    isPackageVariablesModalOpen: true,
  })),
  on(closePackageVariablesModal, (state) => ({
    ...state,
    isPackageVariablesModalOpen: false,
  })),
  on(openPackageImportModal, (state) => ({
    ...state,
    isPackageImportModalOpen: true,
  })),
  on(closePackageImportModal, (state) => ({
    ...state,
    isPackageImportModalOpen: false,
  })),
  on(openPackageVersionMessageModal, (state) => ({
    ...state,
    isPackageVersionMessageModalOpen: true,
  })),
  on(closePackageVersionMessageModal, (state) => ({
    ...state,
    isPackageVersionMessageModalOpen: false,
  })),
  on(closeAllModals, (state) => ({
    ...state,
    isComponentsModalOpen: false,
    component: null,
    rawComponent: null,
    componentParentSchemas: [],
    isPackageVariablesModalOpen: false,
    isPackageImportModalOpen: false,
    isPackageVersionMessageModalOpen: false,
  })),
  on(savePackageResponse, (state) => ({
    ...state,
    isPackageDirty: false,
  })),
  on(clearHistory, (state) => {
    STATE_HISTORY.redo = [];
    STATE_HISTORY.redo = [];
    return {
      ...state,
      isRedoAvailable: false,
      isUndoAvailable: false,
      isPackageDirty: false,
    };
  }),
  on(undoAction, (state) => {
    const lastState = STATE_HISTORY.undo.pop();

    if (lastState) {
      STATE_HISTORY.redo.push(state);
      return {
        ...lastState,
        isRedoAvailable: !!STATE_HISTORY.redo.length,
        isUndoAvailable: !!STATE_HISTORY.undo.length,
      };
    }

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      isPackageDirty: !!STATE_HISTORY.undo.length,
    };
  }),
  on(redoAction, (state) => {
    const lastState = STATE_HISTORY.redo.pop();

    if (lastState) {
      STATE_HISTORY.undo.push(state);
      return {
        ...lastState,
        isRedoAvailable: !!STATE_HISTORY.redo.length,
        isUndoAvailable: !!STATE_HISTORY.undo.length,
      };
    }

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
    };
  }),
  on(updateComponentPosition, (state, { distanceX, distanceY, id }) => {
    let selectedComponentIds = Object.keys(state.fixedComponents)
      .filter((key) => state.fixedComponents[key].isSelected)
      .concat(id);
    selectedComponentIds = uniq(selectedComponentIds);

    const componentsToUpdate: Partial<AllComponentData>[] = selectedComponentIds.map((item) => ({
      id: item,
      xy: [
        snapToGrid(state.fixedComponents[item].xy[0] + distanceX),
        snapToGrid(state.fixedComponents[item].xy[1] + distanceY),
      ],
    }));
    const newPackageItem = {
      ...state.package,
      components: updateComponentsInPackageComponents(state.package.components, componentsToUpdate),
    };

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      package: newPackageItem,
      componentsPositions: {},
      isPackageDirty: true,
      fixedComponents: {
        ...state.fixedComponents,
        ...mapArrayToObjectById(
          (newPackageItem.components || [])
            .map(fixComponentData)
            .filter((item) => item.id === id || selectedComponentIds.includes(item.id))
            .map((item) => (state.fixedComponents[item.id].isSelected ? { ...item, isSelected: true } : item)),
        ),
      },
      fixedEdges: {
        ...state.fixedEdges,
        ...mapArrayToObjectById(
          (newPackageItem.edges || [])
            .map(updateEdgesPositions(newPackageItem.components))
            .filter(
              (item) =>
                item &&
                (item.source === id ||
                  item.target === id ||
                  selectedComponentIds.includes(item.source) ||
                  selectedComponentIds.includes(item.target)),
            ),
        ),
      },
    };
  }),
  on(resizeNoteComponentTemp, (state, { height, width, id }) => {
    const componentsSizes: ComponentsSizes = {
      [id]: [height, width],
    };

    return {
      ...state,
      componentsSizes,
    };
  }),
  on(resizeNoteComponent, (state, { height, width, id }) => {
    const component = state.package.components.find((component) => getDataFromComponent(component).id === id);
    const componentData = getDataFromComponent(component);

    const componentToUpdate: AllComponentData = {
      ...componentData,
      noteHeight: height,
      noteWidth: width,
    };

    const newPackageItem = {
      ...state.package,
      components: updateComponentInPackageComponents(state.package.components, componentToUpdate),
    };

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      isPackageDirty: true,
      package: newPackageItem,
      fixedComponents: {
        ...state.fixedComponents,
        [id]: {
          ...state.fixedComponents[id],
          noteHeight: height,
          noteWidth: width,
        },
      },
      componentsSizes: {},
    };
  }),
  on(updateComponentTempPosition, (state, { distanceX, distanceY, id }) => {
    const componentsPositions = {};
    const selectedComponentIds = Object.keys(state.fixedComponents).filter(
      (key) => state.fixedComponents[key].isSelected,
    );

    state.package.components.forEach((component) => {
      const componentData = getDataFromComponent(component);
      if (componentData.id === id || selectedComponentIds.includes(componentData.id)) {
        componentsPositions[componentData.id] = [componentData.xy[0] + distanceX, componentData.xy[1] + distanceY];
      }
    });

    return {
      ...state,
      componentsPositions,
      fixedEdges: {
        ...state.fixedEdges,
        ...mapArrayToObjectById(
          (state.package.edges || [])
            .map(updateEdgesPositions(state.package.components, state.componentsPositions))
            .filter(Boolean)
            .filter(
              (item) =>
                item.source === id ||
                item.target === id ||
                selectedComponentIds.includes(item.source) ||
                selectedComponentIds.includes(item.target),
            ),
        ),
      },
    };
  }),
  on(updateEdge, (state, { edgeId, event }) => {
    const newEdge: Edge = {
      ...state.package.edges.find((item) => item.id === edgeId),
      event,
    };
    const packageItem = {
      ...state.package,
      edges: state.package.edges.map((item) => (item.id === edgeId ? newEdge : item)),
    };
    const fixedEdges = { ...state.fixedEdges };
    fixedEdges[newEdge.id] = {
      ...fixedEdges[newEdge.id],
      event,
    };

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isPackageDirty: true,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      package: packageItem,
      fixedEdges,
    };
  }),
  on(createEdge, (state, { sourceComponentId, x, y }) => {
    const id = `edge-${uid()}`;
    const isWorkflow = state.package.flow_type === 'workflow';

    const newEdge: Edge = {
      source: sourceComponentId,
      target: 'temp',
      order: 1,
      source_index: 1,
      id,
      label: id,
    };

    if (isWorkflow) {
      newEdge.event = EdgeEvent.success;
    }

    const packageItem = {
      ...state.package,
      edges: (state.package.edges || []).concat(newEdge),
    };
    const fixedEdges = { ...state.fixedEdges };
    fixedEdges[newEdge.id] = updateEdgesPositions(
      state.package.components.concat({
        component_type: { id: 'temp', xy: [x, y] } as Partial<AllComponentData>,
      } as TempComponent),
    )(newEdge);

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isPackageDirty: true,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      package: packageItem,
      fixedEdges,
      isDragging: true,
    };
  }),
  on(removeEdge, (state, { edge }) => {
    const packageItem = {
      ...state.package,
      edges: (state.package.edges || []).filter((item) => item.id !== edge.id),
    };
    const fixedEdges = { ...state.fixedEdges };
    delete fixedEdges[edge.id];

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      isPackageDirty: true,
      package: packageItem,
      fixedEdges,
      fixedComponents: {
        ...state.fixedComponents,
        ...mapArrayToObjectById(
          (packageItem.components || [])
            .map(fixComponentData)
            .filter((item) => item.id === edge.source || item.id === edge.target),
        ),
      },
    };
  }),
  on(updateEdgeTempPosition, (state, { x, y, id }) => {
    const edge = state.package.edges.find((item) => item.source === id && item.target === 'temp');

    const fixedEdges = { ...state.fixedEdges };
    fixedEdges[edge.id] = updateEdgesPositions(
      state.package.components.concat({ component_type: { id: 'temp', xy: [x, y] } } as TempComponent),
    )(edge);

    return {
      ...state,
      fixedEdges,
    };
  }),
  on(createEdgeEndDrag, (state, { sourceComponentId }) => {
    const edge = state.package.edges.find((item) => item.source === sourceComponentId && item.target === 'temp');
    const targetComponentId = Object.keys(state.fixedComponents).find((key) => state.fixedComponents[key].isHovered);
    const fixedEdges = { ...state.fixedEdges };

    if (sourceComponentId === targetComponentId) {
      delete fixedEdges[edge.id];
      const packageItem = {
        ...state.package,
        edges: state.package.edges.filter((item) => item.id !== edge.id),
      };

      return {
        ...state,
        fixedEdges,
        package: packageItem,
      };
    }

    let packageItem;
    const fixedComponent = state.fixedComponents[targetComponentId];
    const inputsLength = state.package.edges.filter((item) => item.target === targetComponentId).length;

    const componentAncestors = getAllComponentAncestors(
      sourceComponentId,
      state.package.components,
      state.package.edges,
    );
    const componentAncestorsIds = componentAncestors.map(getComponentId);

    if (
      targetComponentId &&
      fixedComponent.inputMax > inputsLength &&
      !componentAncestorsIds.includes(targetComponentId)
    ) {
      const newEdge: Edge = {
        ...edge,
        target: targetComponentId,
      };
      fixedEdges[newEdge.id] = updateEdgesPositions(state.package.components)(newEdge);

      packageItem = {
        ...state.package,
        edges: state.package.edges.map((item) => (item.id === edge.id ? newEdge : item)),
      };
    } else {
      delete fixedEdges[edge.id];
      packageItem = {
        ...state.package,
        edges: state.package.edges.filter((item) => item.id !== edge.id),
      };
    }

    return {
      ...state,
      fixedEdges,
      package: packageItem,
      isDragging: false,
    };
  }),
  on(removeComponents, (state, { componentId }) => {
    const selectedComponentIds = Object.keys(state.fixedComponents).filter(
      (key) => state.fixedComponents[key].isSelected,
    );
    const componentIds = componentId ? [componentId] : selectedComponentIds;

    const edgesToRemove = (state.package.edges || []).filter(
      (item) => componentIds.includes(item.source) || componentIds.includes(item.target),
    );
    const packageItem = {
      ...state.package,
      edges: (state.package.edges || []).filter(
        (item) => !componentIds.includes(item.source) && !componentIds.includes(item.target),
      ),
      components: state.package.components.filter((item) => !componentIds.includes(getComponentId(item))),
    };

    const fixedComponents = { ...state.fixedComponents };
    componentIds.forEach((componentIdItem) => {
      delete fixedComponents[componentIdItem];
    });

    const fixedEdges = { ...state.fixedEdges };
    edgesToRemove.forEach((edge) => {
      delete fixedEdges[edge.id];
    });

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      isPackageDirty: true,
      package: packageItem,
      fixedComponents,
      fixedEdges,
    };
  }),
  on(duplicateComponentsAction, (state, { componentId }) => {
    const selectedComponentIds = Object.keys(state.fixedComponents).filter(
      (key) => state.fixedComponents[key].isSelected,
    );
    const componentIds = componentId ? [componentId] : selectedComponentIds;
    const componentsIdsMap = {};
    const newComponents = componentIds.map((id) => {
      const duplicatedComponent = duplicateComponent(id, state.package.components);
      componentsIdsMap[id] = getComponentId(duplicatedComponent);
      return duplicatedComponent;
    });

    const newEdges = (state.package.edges || [])
      .filter((item) => componentIds.includes(item.source) && componentIds.includes(item.target))
      .map((item) => {
        const id = `edge-${uid()}`;
        return {
          ...item,
          id,
          label: id,
          source: componentsIdsMap[item.source],
          target: componentsIdsMap[item.target],
        };
      });

    const packageItem = {
      ...state.package,
      components: [...state.package.components, ...newComponents],
      edges: [...(state.package.edges || []), ...newEdges],
    };

    const fixedEdges = { ...state.fixedEdges };
    newEdges.forEach((newEdge) => {
      fixedEdges[newEdge.id] = updateEdgesPositions(packageItem.components)(newEdge);
    });

    const fixedComponents = { ...state.fixedComponents };
    newComponents.forEach((newComponent) => {
      const componentType = Object.keys(newComponent)[0];
      fixedComponents[newComponent[componentType].id] = fixComponentData(newComponent);
    });

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isPackageDirty: true,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      package: packageItem,
      fixedComponents,
      fixedEdges,
    };
  }),
  on(createComponentAction, (state, { component }) => {
    const { componentType } = component;
    const newComponent = createComponent(component, state.package.components, state.parentComponentId);
    let newEdge: Edge;
    let { edges } = state.package;
    let { fixedEdges } = state;
    const components = state.package.components ? state.package.components.concat(newComponent) : [newComponent];

    if (state.parentComponentId) {
      const id = `edge-${uid()}`;
      const isWorkflow = state.package.flow_type === 'workflow';

      newEdge = {
        source: state.parentComponentId,
        target: newComponent[componentType].id,
        order: 1,
        source_index: 1,
        id,
        label: id,
      };

      if (isWorkflow) {
        newEdge.event = EdgeEvent.success;
      }

      edges = (state.package.edges || []).concat(newEdge);
      fixedEdges = { ...state.fixedEdges };
      fixedEdges[newEdge.id] = updateEdgesPositions(components)(newEdge);
    }

    const packageItem = {
      ...state.package,
      components,
      edges,
    };

    const fixedComponents = { ...state.fixedComponents };
    fixedComponents[newComponent[componentType].id] = fixComponentData(newComponent);

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isPackageDirty: true,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      parentComponentId: '',
      package: packageItem,
      fixedComponents,
      fixedEdges,
    };
  }),
  on(hoverComponent, (state, { componentId, isHovered }) => {
    const fixedComponents = { ...state.fixedComponents };
    fixedComponents[componentId] = { ...fixedComponents[componentId], isHovered };

    return {
      ...state,
      fixedComponents,
    };
  }),
  on(selectComponents, (state, { componentIds }) => {
    const fixedComponents = { ...state.fixedComponents };
    let hasSelection = false;
    Object.keys(fixedComponents).forEach((componentId) => {
      const isSelected = componentIds.includes(componentId);
      if (isSelected) {
        hasSelection = true;
      }
      fixedComponents[componentId] = { ...fixedComponents[componentId], isSelected };
    });

    return {
      ...state,
      fixedComponents,
      hasSelection,
    };
  }),
  on(selectAllComponents, (state) => {
    const fixedComponents = { ...state.fixedComponents };
    const hasSelection = true;
    Object.keys(fixedComponents).forEach((componentId) => {
      fixedComponents[componentId] = { ...fixedComponents[componentId], isSelected: true };
    });

    return {
      ...state,
      fixedComponents,
      hasSelection,
    };
  }),
  on(unselectAllComponents, (state) => {
    const fixedComponents = { ...state.fixedComponents };
    const hasSelection = false;
    Object.keys(fixedComponents).forEach((componentId) => {
      fixedComponents[componentId] = { ...fixedComponents[componentId], isSelected: false };
    });

    return {
      ...state,
      fixedComponents,
      hasSelection,
    };
  }),
  on(selectConnectedComponents, (state) => {
    const fixedComponents = { ...state.fixedComponents };
    const connectedComponentIds = uniq(flatten((state.package.edges || []).map((edge) => [edge.source, edge.target])));
    let hasSelection = false;
    Object.keys(fixedComponents).forEach((componentId) => {
      const isSelected = connectedComponentIds.includes(componentId);
      if (isSelected) {
        hasSelection = true;
      }
      fixedComponents[componentId] = { ...fixedComponents[componentId], isSelected };
    });

    return {
      ...state,
      fixedComponents,
      hasSelection,
    };
  }),
  on(autoAlign, (state, { width }) => {
    const graph = new dagre.graphlib.Graph();
    const size = DESIGNER_SETTINGS.CELL;

    graph.setGraph({
      marginy: size,
      edgesep: size * 3,
      nodesep: size * 3,
      ranksep: size * 3,
    });

    graph.setDefaultEdgeLabel(function () {
      return {};
    });

    if (!state.package.components) {
      return state;
    }

    state.package.components.forEach((component) => {
      const componentData = getDataFromComponent(component);
      const isNote = componentData.id.includes('note');
      if (!isNote) {
        graph.setNode(componentData.id, {
          x: componentData.xy[0],
          y: componentData.xy[1],
          width: DESIGNER_SETTINGS.COMPONENT_WIDTH,
          height: DESIGNER_SETTINGS.COMPONENT_HEIGHT,
        });
      }
    });

    (state.package.edges || []).forEach((edge) => {
      graph.setEdge(edge.source, edge.target);
    });

    dagre.layout(graph);

    const gwidth = graph.graph().width;
    const dwidth = width;
    let offsetX = 0;

    if (gwidth < dwidth) {
      offsetX = (dwidth - gwidth) / 2 - DESIGNER_SETTINGS.COMPONENT_WIDTH / 2;
      offsetX = offsetX > 0 ? offsetX : 0;
    }

    const componentsToUpdate: Partial<AllComponentData>[] = state.package.components
      .filter((component) => {
        const componentData = getDataFromComponent(component);
        return !componentData.id.includes('note');
      })
      .map((component) => {
        const componentData = getDataFromComponent(component);
        const node = graph.node(componentData.id);

        return {
          id: componentData.id,
          xy: [snapToGrid(node.x + offsetX), snapToGrid(node.y)],
        };
      });
    const newPackageItem = {
      ...state.package,
      components: updateComponentsInPackageComponents(state.package.components, componentsToUpdate),
    };

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isPackageDirty: true,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      package: newPackageItem,
      fixedComponents: {
        ...state.fixedComponents,
        ...mapArrayToObjectById((newPackageItem.components || []).map(fixComponentData)),
      },
      fixedEdges: {
        ...state.fixedEdges,
        ...mapArrayToObjectById((newPackageItem.edges || []).map(updateEdgesPositions(newPackageItem.components))),
      },
    };
  }),
  on(createNoteAction, (state) => {
    const noteComponent = createNote(state.package.components);
    const components = (state.package.components || []).concat(noteComponent);
    const packageItem = {
      ...state.package,
      components,
    };

    const fixedComponents = { ...state.fixedComponents };
    fixedComponents[noteComponent[COMPONENT_TYPE.STICKY_NOTE_COMPONENT].id] = fixComponentData(noteComponent);

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isPackageDirty: true,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      parentComponentId: '',
      package: packageItem,
      fixedComponents,
    };
  }),
  on(validationStatusSuccess, (state) => ({
    ...state,
    isErrorsViewerVisible: true,
    isValidatingPackage: false,
    validationErrors: [],
  })),
  on(hideErrorsViewer, (state) => ({
    ...state,
    isErrorsViewerVisible: false,
    validationErrors: [],
  })),
  on(runValidationAction, (state) => ({
    ...state,
    isValidatingPackage: true,
    isErrorsViewerVisible: false,
    validationErrors: [],
  })),
  on(runValidationError, (state) => ({
    ...state,
    isValidatingPackage: false,
    validationErrors: [],
  })),
  on(validationStatusError, (state, { err }) => ({
    ...state,
    validationErrors: err,
    isErrorsViewerVisible: true,
    isValidatingPackage: false,
  })),
  on(copyAction, (state) => {
    const selectedComponentIds = Object.keys(state.fixedComponents).filter(
      (key) => state.fixedComponents[key].isSelected,
    );
    const components = selectedComponentIds.map((id) =>
      (state.package.components || []).find((item) => getComponentId(item) === id),
    );
    const edges = (state.package.edges || []).filter(
      (item) => selectedComponentIds.includes(item.source) && selectedComponentIds.includes(item.target),
    );

    const copyData = {
      components,
      edges,
    };
    window.localStorage.setItem('copyData', JSON.stringify(copyData));

    return {
      ...state,
      copyData,
    };
  }),
  on(cutAction, (state) => {
    const selectedComponentIds = Object.keys(state.fixedComponents).filter(
      (key) => state.fixedComponents[key].isSelected,
    );
    const components = selectedComponentIds.map((id) =>
      (state.package.components || []).find((item) => getComponentId(item) === id),
    );
    const edges = (state.package.edges || []).filter(
      (item) => selectedComponentIds.includes(item.source) && selectedComponentIds.includes(item.target),
    );

    const copyData = {
      components,
      edges,
    };
    window.localStorage.setItem('copyData', JSON.stringify(copyData));

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      isPackageDirty: true,
      copyData,
    };
  }),
  on(pasteAction, (state) => {
    const copyData = JSON.parse(window.localStorage.getItem('copyData'));
    const isAnyModalOpen =
      state.isComponentsModalOpen ||
      state.isPackageImportModalOpen ||
      state.isPackageVariablesModalOpen ||
      state.isPackageVersionMessageModalOpen;

    if (!copyData || !copyData.components || isAnyModalOpen) {
      return state;
    }

    const componentsIdsMap = {};
    let newComponents = [...copyData.components];

    newComponents.forEach((item, index) => {
      const id = getComponentId(item);
      const duplicatedComponent = duplicateComponent(id, [...newComponents, ...(state.package.components || [])]);
      componentsIdsMap[id] = getComponentId(duplicatedComponent);

      newComponents[index] = duplicatedComponent;
    });

    const newEdges = copyData.edges.map((item) => {
      const id = `edge-${uid()}`;
      return {
        ...item,
        id,
        label: id,
        source: componentsIdsMap[item.source],
        target: componentsIdsMap[item.target],
      };
    });

    newComponents = updateFieldsAfterCopyPaste(newComponents, copyData, newEdges, componentsIdsMap);

    const packageItem = {
      ...state.package,
      components: [...(state.package.components || []), ...newComponents],
      edges: [...(state.package.edges || []), ...newEdges],
    };

    const fixedEdges = { ...state.fixedEdges };
    newEdges.forEach((newEdge) => {
      fixedEdges[newEdge.id] = updateEdgesPositions(packageItem.components)(newEdge);
    });

    const fixedComponents = { ...state.fixedComponents };

    Object.keys(fixedComponents).forEach((componentId) => {
      fixedComponents[componentId] = { ...fixedComponents[componentId], isSelected: false };
    });

    newComponents.forEach((newComponent) => {
      const componentType = Object.keys(newComponent)[0];
      fixedComponents[newComponent[componentType].id] = { ...fixComponentData(newComponent), isSelected: true };
    });

    STATE_HISTORY.undo.push(state);

    return {
      ...state,
      isRedoAvailable: !!STATE_HISTORY.redo.length,
      isUndoAvailable: !!STATE_HISTORY.undo.length,
      isPackageDirty: true,
      copyData: {},
      package: packageItem,
      fixedEdges,
      fixedComponents,
      hasSelection: true,
    };
  }),
  on(updatePackageResponse, (state, { data }) =>
    data.id === state.package.id
      ? {
          ...state,
          package: {
            ...state.package,
            name: data.name,
            description: data.description,
            package_version: data.package_version,
            version: data.version,
            version_description: data.version_description,
            updated_at: data.updated_at,
          },
        }
      : state,
  ),
  ...componentPreviewerReducer,
);
