import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  scrollYReachEnd$ = new Subject<any>();

  public scrollYReachEnd(event) {
    this.scrollYReachEnd$.next(event);
  }
}
