const cloudStorageConstraint = {
  container: {
    PresenceValidator: {},
    LengthValidator: {
      minimum: 3,
      maximum: 63,
    },
    FormatValidator: {
      with: /^[{}$a-z0-9.-]+$/,
      message:
        'Container may only contain any combination of uppercase letters, lowercase letters, numbers, periods (.) and dashes (-)',
    },
  },
  bucket: {
    PresenceValidator: {},
    LengthValidator: {
      minimum: 3,
      maximum: 63,
    },
    FormatValidator: {
      with: /^[{}$A-Za-z\d\-.]+$/,
      message:
        'Bucket may only contain any combination of uppercase letters, lowercase letters, numbers, periods (.) and dashes (-)',
    },
  },
  bucket_gc: {
    PresenceValidator: {},
    LengthValidator: {
      minimum: 3,
      maximum: 63,
    },
    FormatValidator: {
      with: /^[ֿֿ{}$A-Za-z\d\-._]+$/,
      message:
        'Bucket may only contain any combination of uppercase letters, lowercase letters, numbers, periods (.), underscores (_), and dashes (-)',
    },
  },
  container_azureblobstorage: {
    PresenceValidator: {},
    LengthValidator: {
      minimum: 3,
      maximum: 63,
    },
    FormatValidator: {
      with: /^[ֿֿ{}$a-z\d-]+$/,
      message: 'Container may only contain any combination of lowercase letters, numbers and dashes (-)',
    },
  },
  path: {
    PresenceValidator: {},
    LengthValidator: {
      minimum: 1,
      maximum: 1024,
    },
  },
  char_encoding: {},
  record_delimiter: {},
  record_type: {},
  source_path_field_alias: {},
  before_action: {},
};

export const defaultValidators = {
  UserPasswordChange: {
    current_password: {
      PresenceValidator: {},
    },
    new_password: {
      PresenceValidator: {},
      MinLengthValidator: {
        minimum: 12,
      },
    },
  },
  Component: {
    name: {
      PresenceValidator: {},
      FormatValidator: {
        with: /^[a-zA-Z]+[\w]*(::[a-zA-Z]+)?[\w]*$/,
        message: 'Component name contains illegal keyword or characters',
      },
      KeywordsValidator: {},
      UniqueComponentNameValidator: {},
    },
  },
  NewSchedule: {
    packages: {
      PresenceValidator: {},
    },
  },
  RankComponent: {
    field_alias: {
      FieldNamePresenceValidator: {
        message: 'Field name already exist',
      },
      PresenceValidator: {},
      AliasValidator: {},
    },
  },
  CloudStorageSource: cloudStorageConstraint,
  CloudStorageDestination: cloudStorageConstraint,
  ExecuteSqlComponent: {
    query: {
      PresenceValidator: {},
    },
  },
};
