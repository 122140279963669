import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Cluster } from '../../../../clusters/clusters.models';

@Component({
  selector: 'xp-select-picker-cluster',
  template: `
    <div class="select-picker-cluster">
      <div class="select-picker-item">
        <div class="select-picker-header">
          <div class="select-picker-icon">
            <common-icon iconId="icon-cluster"></common-icon>
          </div>
          <div class="select-picker-name" [innerHTML]="cluster.name | xpHighLight : highlightValue"></div>
        </div>
        <div class="select-picker-body">
          <span class="small">{{ nodes_withtext }} / {{ cluster.type }}</span>
          <span class="small">created by {{ cluster.owner ? cluster.owner.display_name : '' }}</span>
          <span class="small"
            >created at:
            <span class="date-tooltip" [matTooltip]="cluster.updated_at | xpDateUTC">{{
              cluster.created_at | xpPrettyDate
            }}</span></span
          >
        </div>
      </div>
    </div>
  `,
})
export class XpSelectPickerClusterComponent implements OnChanges {
  @Input() cluster: Cluster;
  @Input() highlightValue: string = '';
  nodes_withtext = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cluster && changes.cluster.currentValue) {
      if (this.cluster && this.cluster.nodes) {
        this.nodes_withtext = Number(this.cluster.nodes) === 1 ? '1 node' : `${this.cluster.nodes} nodes`;
      }
    }
  }
}
