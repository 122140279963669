import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JobsState } from './jobs.reducer';

export const selectJobsState = createFeatureSelector<JobsState>('jobs');

export const selectJobs = createSelector(selectJobsState, (state: JobsState) => state.items);
export const selectJobsErrors = createSelector(selectJobsState, (state: JobsState) => state.errors);
export const selectJobsLoadingFlag = createSelector(selectJobsState, (state: JobsState) => state.isLoading);
export const selectJobsErrorFlag = createSelector(selectJobsState, (state: JobsState) => state.isResponseError);
export const selectJobsIsSubmittingFlag = createSelector(selectJobsState, (state: JobsState) => state.isFormSubmitting);
export const selectJobsIsModalOpenFlag = createSelector(selectJobsState, (state: JobsState) => state.isModalOpen);
export const selectJobIsDetailsModalOpenFlag = createSelector(
  selectJobsState,
  (state: JobsState) => state.isDetailsModalOpen,
);
export const selectChildJobIsDetailsModalOpenFlag = createSelector(
  selectJobsState,
  (state: JobsState) => state.isChildDetailsModalOpen,
);

export const selectAllJobsLoadedFlag = createSelector(selectJobsState, (state: JobsState) => state.areAllItemsLoaded);
export const selectIsJobRemoved = (id: number) =>
  createSelector(selectJobsState, (state: JobsState) => !state.items.find((item) => item.id === id));

export const isAnyModalOpen = createSelector(
  selectJobsState,
  (state: JobsState) => state.isModalOpen || state.isDetailsModalOpen || state.isChildDetailsModalOpen,
);
