import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { AllComponentData, RunPackageComponentData, Schema, Variables } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { Package } from '../../../packages/package.models';
import { ListParams } from '../../../common/helper/query-params-generic-list.helper';
import { SelectPickerValue } from '../../../common/components/forms/select-picker/xp-select-picker.component';

@Component({
  selector: 'run-package-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="packageStep">
          <xp-select-picker-editable
            id="package-picker-component"
            [type]="selectPickerTypes.package"
            [value]="rawComponent.package_id"
            [params]="query"
            [hideNew]="true"
            [emitInitial]="true"
            placeholder="Select package"
            emptyPlaceholder="Packages list is empty"
            (valueChange)="onSelectPackage($event)"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="variablesStep" (activate)="onBaseStepActivation()">
          <div class="run-package-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::RunPackageComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <package-variables-editor
                  class="package-variables-editor-wrapper no-padding"
                  parentType="workflow"
                  [package]="package"
                  [variables]="variables || {}"
                  [secretVariables]="secretVariables"
                  [variablesToSet]="(package || {}).variables"
                  [hideFooter]="true"
                  [hideAdd]="true"
                  [disableKey]="true"
                  [usePackageDefault]="true"
                  [disableSecrets]="true"
                  (variablesChange)="onVariablesChange($event)"
                  (secretVariablesChange)="onSecretVariablesChange($event)"
                ></package-variables-editor>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="operatorEditorStep">
          <div class="workflow-operator-editor">
            <div class="form-group">
              <div>
                <label
                  ><strong>{{ 'run-package-editor.labels.operator' | translate }}</strong></label
                >
              </div>
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.operator"
                    (ngModelChange)="onValueChange($event, 'operator')"
                    name="operator"
                    id="operator-and"
                    value="and"
                  />
                  <label for="operator-and">{{ 'run-package-editor.labels.and' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.operator"
                    (ngModelChange)="onValueChange($event, 'operator')"
                    name="operator"
                    id="operator-or"
                    value="or"
                  />
                  <label for="operator-or">{{ 'run-package-editor.labels.or' | translate }}</label>
                </div>
              </div>
            </div>
          </div>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class RunPackageEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: RunPackageComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createPackage = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;
  isFormValid = true;
  isBaseComponentStepActivated = false;
  validationChangeSubscription: Subscription;

  packageStep: Step = getStep({ active: true });
  variablesStep: Step = getStep({});
  operatorEditorStep: Step = getStep({});

  query: ListParams = {
    flow_type: 'dataflow',
    current_package: null,
  };
  package: Partial<Package> = {};
  variables: Variables = {};
  secretVariables: Variables = {};

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.variablesStep = {
        ...this.variablesStep,
        valid: !!this.rawComponent.package_id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent as any, this.component),
      };
      this.onValidityChange();
    });

    this.packageStep = getStep({
      title: 'Package to run',
      activeTitle: 'Choose a package',
      valid: !!this.rawComponent.package_id,
      active: true,
    });

    this.variablesStep = getStep({
      title: 'Variables',
      activeTitle: 'Edit variables',
      valid: !!this.rawComponent.package_id,
      tags: [],
    });

    this.operatorEditorStep = getStep({
      title: 'Execute task if',
      activeTitle: 'Choose task execution',
      valid: true,
      tags: this.getComponentOperatorTags(this.rawComponent.operator),
    });

    this.variables = { ...((this.package || {}).variables || {}), ...(this.rawComponent.variables || {}) };
    this.secretVariables = {
      ...((this.package || {}).secret_variables || {}),
      ...(this.rawComponent.secret_variables || {}),
    };
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  onValidityChange() {
    const isValid = this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectPackage(value: SelectPickerValue) {
    const packageItem = value as Package;
    const component: any = {
      package_id: packageItem.id,
      package_name: packageItem.name,
    };
    this.package = packageItem;

    if (this.rawComponent.package_id !== packageItem.id) {
      this.store.dispatch(
        updateRawComponent({
          rawComponent: component,
        }),
      );
      this.store.dispatch(updateComponent({ component }));

      this.onValueChange(packageItem.variables || {}, 'variables');
    }

    this.packageStep.tags = [
      {
        name: escape(packageItem.name),
      },
    ];
    if (this.rawComponent.package_id === packageItem.id) {
      this.variables = { ...((this.package || {}).variables || {}), ...(this.rawComponent.variables || {}) };
      this.secretVariables = {
        ...((this.package || {}).secret_variables || {}),
        ...(this.rawComponent.secret_variables || {}),
      };
    } else {
      this.variables = { ...(packageItem.variables || {}) };
      this.secretVariables = { ...(packageItem.secret_variables || {}) };
    }

    this.packageStep = { ...this.packageStep, valid: true };
    this.variablesStep = { ...this.variablesStep, valid: true, tags: this.generateVariableTags(this.variables) };
  }

  // eslint-disable-next-line class-methods-use-this
  generateVariableTags(variables: Variables) {
    return Object.keys(variables).map((key) => ({
      name: `<strong>${escape(key)}</strong>: ${escape(variables[key])}`,
    }));
  }

  onCreateNewPackage(params) {
    this.createPackage.emit(params);
  }

  onValueChange(value: any, key: string) {
    if (key === 'operator') {
      this.operatorEditorStep = { ...this.operatorEditorStep, tags: this.getComponentOperatorTags(value) };
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onVariablesChange(variables: Variables) {
    this.variablesStep = { ...this.variablesStep, tags: this.generateVariableTags(variables) };
    this.onValueChange(variables, 'variables');
  }

  onSecretVariablesChange(secretVariables: Variables) {
    this.onValueChange(secretVariables, 'secret_variables');
  }

  // eslint-disable-next-line class-methods-use-this
  getComponentOperatorTags(operator: string) {
    if (operator === 'and') {
      return [
        {
          name: 'all preceding conditions evaluate to true (AND)',
        },
      ];
    }
    if (operator === 'or') {
      return [
        {
          name: 'one of the preceding conditions evaluate to true (OR)',
        },
      ];
    }

    return [];
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
