import { createReducer, on } from '@ngrx/store';
import { Package } from '../package.models';
import {
  closePackagesModal,
  closePackageVersionsModal,
  closePackageWorkspacesModal,
  duplicatePackageError,
  duplicatePackageResponse,
  getPackagesList,
  getPackagesListError,
  getPackagesListResponse,
  loadMorePackagesList,
  loadMorePackagesListResponse,
  openPackagesModal,
  openPackageVersionsModal,
  openPackageWorkspacesModal,
  removePackageItem,
  removePackageResponse,
  rollbackPackageResponse,
  savePackage,
  savePackageError,
  savePackageResponse,
  searchPackagesList,
  searchPackagesListResponse,
  updatePackage,
  updatePackageError,
  updatePackageResponse,
} from './packages.actions';
import { ValidationError } from '../../config/validation-error.model';
import { QueryParamsPackagesList } from '../../common/helper/query-params-generic-list.helper';
import { closeAllModals } from '../../account/store/account.actions';

export const initialState: PackagesState = {
  items: [],
  item: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  isVersionModalOpen: false,
  isWorkspaceModalOpen: false,
  errors: [],
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface PackagesState {
  items: Package[];
  item: Package;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  isVersionModalOpen: boolean;
  isWorkspaceModalOpen: boolean;
  errors: ValidationError[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const packagesReducer = createReducer<PackagesState>(
  initialState,

  on(openPackagesModal, (state) => ({ ...state, isModalOpen: true, errors: [] })),
  on(closePackagesModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(openPackageVersionsModal, (state) => ({ ...state, isVersionModalOpen: true })),
  on(closePackageVersionsModal, (state) => ({ ...state, isVersionModalOpen: false })),

  on(openPackageWorkspacesModal, (state) => ({ ...state, isWorkspaceModalOpen: true })),
  on(closePackageWorkspacesModal, (state) => ({ ...state, isWorkspaceModalOpen: false })),

  on(getPackagesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getPackagesListResponse, (state, { packages }) => ({
    ...state,
    isLoading: false,
    items: packages,
    areAllItemsLoaded: packages.length < QueryParamsPackagesList.limit,
  })),
  on(getPackagesListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(searchPackagesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(searchPackagesListResponse, (state, { packages }) => ({
    ...state,
    isLoading: false,
    items: packages,
    areAllItemsLoaded: packages.length < QueryParamsPackagesList.limit,
  })),

  on(loadMorePackagesList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMorePackagesListResponse, (state, { packages }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...packages],
    areAllItemsLoaded: packages.length < QueryParamsPackagesList.limit,
  })),

  on(savePackage, (state) => ({ ...state, isFormSubmitting: true })),
  on(savePackageResponse, (state, { data, closeModal }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: closeModal ? false : state.isModalOpen,
  })),
  on(savePackageError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(duplicatePackageResponse, (state, { data }) => ({
    ...state,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
  })),
  on(duplicatePackageError, (state, { errors }) => ({ ...state, errors })),

  on(updatePackage, (state) => ({ ...state, isFormSubmitting: true })),
  on(updatePackageResponse, (state, { data, closeModal }) => ({
    ...state,
    items: state.items.map((item) => (item.id === data.id ? data : item)),
    isFormSubmitting: false,
    isModalOpen: closeModal ? false : state.isModalOpen,
  })),
  on(updatePackageError, (state, { errors }) => ({
    ...state,
    isFormSubmitting: false,
    errors,
  })),

  on(rollbackPackageResponse, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id === data.id ? data : item)),
  })),

  on(removePackageItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),
  on(removePackageResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
