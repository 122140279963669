import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsPackageDirtyFlag } from './store/package-designer.selectors';
import { AppState } from '../store';

@Component({
  selector: 'app-package-designer',
  template: `<xp-designer></xp-designer>`,
})
export class PackageDesignerComponent implements OnDestroy {
  confirmationMessage =
    'The changes you made may not be saved.\n Click OK to leave this package without saving the changes';
  isPackageDirty$ = this.store.select(selectIsPackageDirtyFlag);
  isPackageDirty = false;
  isPackageDirtySubscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.isPackageDirtySubscription = this.isPackageDirty$.subscribe((isDirty) => {
      this.isPackageDirty = isDirty;
    });

    window.onbeforeunload = (e) => {
      if (this.isPackageDirty) {
        e.returnValue = this.confirmationMessage;
      }
    };
  }

  ngOnDestroy() {
    if (this.isPackageDirtySubscription) {
      this.isPackageDirtySubscription.unsubscribe();
    }
    window.onbeforeunload = null;
  }
}
