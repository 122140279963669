import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isEqual } from 'lodash';
import { Store } from '@ngrx/store';
import { updateVariables } from '../../../package-designer/store/variables.actions';
import { setDidUserSaveVariablesFlag } from '../../../package-designer/store/flags.actions';
import { AppState } from '../../../store';
import { VariableItem, Variables } from '../../../package-designer/package.models';
import { VariablesService } from '../../../jobs/services/variables.service';
import { closePackageVariablesModal } from '../../../package-designer/store/package-designer.actions';
import { DynamicConnectionService } from '../../../package-designer/services/dynamic-connection.service';
import { Package } from '../../../packages/package.models';
import { PermissionsService } from '../../services/permissions.service';
import { Validators } from '@angular/forms';

const regexpFormat = /^[a-zA-Z_]+[\w]*$/;

function isKeyFormatValid(alias) {
  return regexpFormat.test(alias);
}

function isValueValid(value) {
  return undefined !== value && value !== null && value !== '';
}

export enum VariableTabs {
  package = 'package',
  global = 'global',
  system = 'system',
  secrets = 'secrets',
}

function isOrderChanged(variables: Variables, defaultVariables: Variables): boolean {
  return !isEqual(Object.keys(variables), Object.keys(defaultVariables));
}

@Component({
  selector: 'package-variables-editor',
  template: `
    <div class="package-variables-editor variables-editor" [ngClass]="{ disabled: isDisabled }">
      <div class="package-variables-editor-body">
        <div class="package-variables-editor-tabs-box">
          <div class="package-variables-editor-tabs">
            <div [ngClass]="{ active: activeTab === VariableTabs.package }" (click)="activeTab = VariableTabs.package">
              <span class="tab-title">{{ 'package-variables-editor.tabs.user' | translate }}</span>
            </div>
            <div
              *ngIf="!isWorkflow"
              [ngClass]="{ active: activeTab === VariableTabs.secrets }"
              (click)="activeTab = VariableTabs.secrets"
            >
              <span class="tab-title">{{ 'package-variables-editor.tabs.secrets' | translate }}</span>
            </div>
            <div [ngClass]="{ active: activeTab === VariableTabs.global }" (click)="activeTab = VariableTabs.global">
              <span class="tab-title">{{ 'package-variables-editor.tabs.global' | translate }}</span>
            </div>
            <div [ngClass]="{ active: activeTab === VariableTabs.system }" (click)="activeTab = VariableTabs.system">
              <span class="tab-title">{{ 'package-variables-editor.tabs.system' | translate }}</span>
            </div>
          </div>
          <div class="package-variables-editor-content">
            <div *ngIf="activeTab === VariableTabs.package" [ngClass]="{ active: activeTab === VariableTabs.package }">
              <variables-editor
                class="user-variables"
                [items]="userVariables"
                type="user_variables"
                [hideAdd]="!editable || hideAdd || !(hasPermission$('updatePackage') | async)"
                [emptyMessage]="'variables-editor.empty-message.user-variables'"
                [disableSort]="!editable"
                [disableKey]="disableKey"
                keyPlaceholder="username"
                inputPlaceholder="'integrate-io'"
                [readonlyValue]="!editable"
                [ngClass]="{ editable: editable }"
                [hideRemove]="hideRemove || !(hasPermission$('updatePackage') | async)"
                [showUndo]="usePackageDefault"
                showPlaceholder="standard"
                [usePackageDefault]="usePackageDefault"
                (variablesChange)="onChange($event, 'userVariables')"
              ></variables-editor>
            </div>
            <div *ngIf="activeTab === VariableTabs.secrets" [ngClass]="{ active: activeTab === VariableTabs.secrets }">
              <variables-editor
                class="user-variables"
                [items]="secretVariablesTemp"
                type="secret_variables"
                [parentType]="parentType"
                [hideAdd]="!editable || hideAdd || !(hasPermission$('updatePackage') | async)"
                [emptyMessage]="'variables-editor.empty-message.secret-variables'"
                [disableSort]="!editable"
                [disableKey]="disableKey"
                keyPlaceholder="api_key"
                inputPlaceholder="some-api-key-here"
                [secretValue]="!disableSecrets"
                [readonlyValue]="!editable || disableSecrets"
                [ngClass]="{ editable: editable }"
                [hideRemove]="hideRemove || !(hasPermission$('updatePackage') | async)"
                [showUndo]="false"
                showPlaceholder="secret"
                [usePackageDefault]="usePackageDefault"
                [valueValidators]="[Validators.required, Validators.minLength(6)]"
                (variablesChange)="onChange($event, 'secretVariablesTemp')"
              ></variables-editor>
            </div>
            <div *ngIf="activeTab === VariableTabs.global" [ngClass]="{ active: activeTab === VariableTabs.global }">
              <variables-editor
                class="user-variables"
                [items]="globalVariables"
                type="global_variables"
                [hideAdd]="true"
                [readonlyValue]="true"
                [readonlyKey]="true"
                [hideRemove]="true"
                [showUndo]="false"
                [ngClass]="{ editable: false }"
                [usePackageDefault]="usePackageDefault"
                [disableKey]="disableKey"
                [hideTooltip]="true"
                (variablesChange)="onChange($event, 'globalVariables')"
              ></variables-editor>
            </div>
            <div *ngIf="activeTab === VariableTabs.system" [ngClass]="{ active: activeTab === VariableTabs.system }">
              <variables-editor
                class="system-variables"
                [items]="systemVariables"
                type="system_variables"
                [readonlyValue]="!editable"
                [hideRemove]="true"
                [hideAdd]="true"
                [showUndo]="editable"
                [ngClass]="{ editable: editable }"
                [usePackageDefault]="usePackageDefault"
                [disableKey]="true"
                (variablesChange)="onChange($event, 'systemVariables')"
              ></variables-editor>
            </div>
          </div>
        </div>
      </div>
      <div class="package-variables-editor-footer" *ngIf="editable && !hideFooter">
        <div class="modal-footer">
          <div class="modal-title-container">
            <h3 class="modal-title">{{ 'package-variables-editor.title' | translate }}</h3>
          </div>
          <button
            *ngxPermissionsOnly="'updatePackage'"
            type="button"
            id="connection-button-cancel"
            class="btn btn-lg btn-default pull-right"
            (click)="cancel()"
          >
            {{ 'package-variables-editor.buttons.cancel' | translate }}
          </button>
          <button
            *ngxPermissionsOnly="'updatePackage'"
            type="button"
            class="btn btn-lg pull-right modal-btn-save"
            [disabled]="!valid || !changed"
            (click)="save()"
            [ngClass]="{ 'btn-success': changed, 'btn-default': !changed }"
          >
            {{ 'package-variables-editor.buttons.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class PackageVariablesEditorComponent implements OnInit, OnChanges {
  @Input() package: Package;
  @Input() variables: Variables;
  @Input() secretVariables: Variables;
  @Input() variablesToSet: Variables;
  @Input() valid = true;
  @Input() error = false;
  @Input() usePackageDefault = false;
  @Input() editable = true;
  @Input() hideAdd = false;
  @Input() hideFooter = false;
  @Input() hideRemove = true;
  @Input() showUndo = false;
  @Input() disableKey = false;
  @Input() isDisabled = false;
  @Input() disableSecrets = false;
  @Input() parentType = '';
  @Output() variablesChange = new EventEmitter();
  @Output() secretVariablesChange = new EventEmitter();
  VariableTabs = VariableTabs;
  activeTab: VariableTabs = VariableTabs.package;
  Validators = Validators;

  userVariables: VariableItem[] = [];
  systemVariables: VariableItem[] = [];
  globalVariables: VariableItem[] = [];
  secretVariablesTemp: VariableItem[] = [];
  changed = false;
  default: Variables = {};
  defaultSecrets: Variables = {};
  exitingKeys = {};

  constructor(
    private variablesService: VariablesService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private dynamicConnectionService: DynamicConnectionService,
    private permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.default = { ...this.variables };
    this.defaultSecrets = { ...this.secretVariables };
    this.separateVariables();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.package && changes.package.currentValue) {
      this.separateVariables();
      this.default = { ...this.variables };
      this.defaultSecrets = { ...this.secretVariables };
    }
  }

  onChange(variables, key) {
    this[key] = variables;

    this.combine(() => {
      this.variablesChange.emit(this.variables);
      this.secretVariablesChange.emit(this.secretVariablesToUpdate());
    });
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index, item) {
    return item.key;
  }

  separateVariables() {
    this.variablesService
      .separateVariables(this.variables, this.variablesToSet, this.usePackageDefault, this.secretVariables)
      .subscribe((vars) => {
        this.userVariables = vars.userVariables;
        this.systemVariables = vars.systemVariables;
        this.globalVariables = vars.globalVariables;
        this.secretVariablesTemp = vars.secretVariables;
        this.combine(() => {});
        this.changeDetector.markForCheck();
      });
  }

  combine(callback: () => void) {
    this.variablesService
      .combineVariables(this.userVariables, this.systemVariables, this.secretVariablesTemp)
      .subscribe(({ variables, secretVariables }) => {
        this.variables = variables;
        this.secretVariables = secretVariables;
        this.changed =
          !isEqual(variables, this.default) ||
          isOrderChanged(variables, this.default) ||
          !isEqual(secretVariables, this.defaultSecrets) ||
          isOrderChanged(secretVariables, this.defaultSecrets);
        this.validate();
        callback();
      });
  }

  isKeyValid(alias) {
    let isFormatValid = isKeyFormatValid(alias);
    if (this.exitingKeys[alias]) {
      isFormatValid = false;
    } else {
      this.exitingKeys[alias] = true;
    }
    return isFormatValid && alias.length !== 0;
  }

  validateVariables(variable) {
    // eslint-disable-next-line no-param-reassign
    variable.valueInvalid = !isValueValid(variable.value);
    // eslint-disable-next-line no-param-reassign
    variable.keyInvalid = !this.isKeyValid(variable.key);
    if (variable.valueInvalid || variable.keyInvalid) {
      this.valid = false;
      this.error = !this.valid;
    }
  }

  validate() {
    this.exitingKeys = {};
    this.valid = true;
    this.error = !this.valid;
    if (this.activeTab === VariableTabs.package && this.editable) {
      this.userVariables.forEach(this.validateVariables.bind(this));
    }
    if (this.activeTab === VariableTabs.secrets && this.editable) {
      this.secretVariablesTemp.forEach(this.validateVariables.bind(this));
    }
    if (this.activeTab === VariableTabs.system && this.editable) {
      this.systemVariables.forEach(this.validateVariables.bind(this));
    }
    if (this.activeTab === VariableTabs.global && this.editable) {
      this.globalVariables.forEach(this.validateVariables.bind(this));
    }
  }

  cancel() {
    this.store.dispatch(closePackageVariablesModal());
  }

  save() {
    let variables = { ...this.variables };
    if (this.dynamicConnectionService.dynamicConnectionVariables) {
      variables = {
        ...variables,
        ...this.dynamicConnectionService.dynamicConnectionVariables,
      };
    }
    this.dynamicConnectionService.dynamicConnectionVariables = null;

    this.store.dispatch(
      updateVariables({
        variables,
        secretVariablesToUpdate: this.secretVariablesToUpdate(),
        secretVariables: this.secretVariables,
      }),
    );
    this.store.dispatch(setDidUserSaveVariablesFlag({ value: true }));
    this.store.dispatch(closePackageVariablesModal());
  }

  secretVariablesToUpdate(): Variables {
    const result = {};
    for (const key in this.secretVariables) {
      if (this.secretVariables[key] !== this.defaultSecrets[key]) {
        result[key] = this.secretVariables[key];
      }
    }

    for (const key in this.defaultSecrets) {
      if (!this.secretVariables[key]) {
        result[key] = null;
      }
    }

    return result;
  }

  hasPermission$(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  get isWorkflow(): boolean {
    return this.package?.flow_type === 'workflow';
  }
}
