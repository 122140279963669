import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { AllComponentData, Connection, GoogleSpannerDestinationComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';

@Component({
  selector: 'google-spanner-destination-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep">
          <div class="database-destination-editor destination-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::SpannerDestinationComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <database-destination
                  [rawComponent]="rawComponent"
                  [component]="component"
                  [hasTableSchemaSelect]="false"
                ></database-destination>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaMappingStep">
          <schema-mapping-database-collection
            [records]="rawComponent.column_mappings"
            [parentSchemas]="parentSchemas"
            (validityChange)="onFieldsValidityChange($event)"
            (recordsChange)="onRecordsChange($event)"
            [operationType]="rawComponent.operation_type"
            [componentType]="component.componentType"
          ></schema-mapping-database-collection>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class GoogleSpannerDestinationEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: GoogleSpannerDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  fieldsCollectionValid = false;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaMappingStep: Step = getStep({});

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    });

    this.schemaMappingStep = getStep({
      title: this.translate.instant(`component-editor.step-schema-mapping.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(
        `component-editor.step-schema-mapping.${this.component.componentType}.active`,
      ),
      tags: (this.rawComponent.column_mappings || []).map((record) => ({
        name: escape(record.column_name),
      })),
    });
  }

  onValidityChange() {
    const isValid = this.fieldsCollectionValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { column_mappings: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { column_mappings: records } }));

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      tags: this.fieldsCollectionValid
        ? records.map((record) => ({
            name: escape(record.column_name),
          }))
        : [],
    };
  }

  onFieldsValidityChange(value) {
    this.fieldsCollectionValid = value;

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      valid: this.fieldsCollectionValid && this.connectionStep.valid && this.isFormValid,
      isError: !this.fieldsCollectionValid,
    };

    this.onValidityChange();
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
