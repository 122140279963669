import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { Component as PackageComponent, Schema, UnionComponentData } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { getStep } from '../../../common/helper/get-step.helper';
import { switchParentSchemasOrder } from '../../store/package-designer.actions';

@Component({
  selector: 'union-editor',
  template: `
    <div class="union-editor">
      <xp-steps>
        <xp-step [step]="componentOrderEditorStep">
          <components-order-editor
            [components]="parentComponents"
            (switchComponents)="onSwitchComponents($event)"
          ></components-order-editor>
        </xp-step>
        <xp-step [step]="componentRecordsStep">
          <form name="componentForm" novalidate #form="ngForm">
            <label>{{ 'union_component.labels.fields_mapping' | translate }}</label>

            <union-collection
              [records]="rawComponent.fields || []"
              [parentSchemas]="parentSchemas"
              (recordsChange)="onRecordsChange($event)"
              (validityChange)="onUnionCollectionValidityChange($event)"
            ></union-collection>

            <div class="row">
              <component-previewer [componentId]="rawComponent.id"></component-previewer>
            </div>
          </form>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class UnionEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: UnionComponentData;
  @Input() parentSchemas: Schema[];
  @Input() parentComponents: PackageComponent[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() componentsOrderChange = new EventEmitter<PackageComponent[]>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  errorMessage = '';

  isUnionCollectionValid = false;

  componentOrderEditorStep = getStep({
    valid: true,
    title: this.translate.instant('component-editor.step-order.join_component.closed'),
    activeTitle: this.translate.instant('component-editor.step-order.join_component.active'),
  });
  componentRecordsStep = getStep({
    active: true,
    title: this.translate.instant('component-editor.step-editor.join_component.closed'),
    activeTitle: this.translate.instant('component-editor.step-editor.join_component.active'),
  });

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onValidityChange();
  }

  onRecordsChange(records) {
    this.store.dispatch(updateRawComponent({ rawComponent: { fields: records } }));
  }

  onUnionCollectionValidityChange(isValid) {
    this.isUnionCollectionValid = isValid;
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isUnionCollectionValid;

    this.componentRecordsStep = { ...this.componentRecordsStep, valid: isValid };

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSwitchComponents(components: PackageComponent[]) {
    this.componentOrderEditorStep = { ...this.componentOrderEditorStep, active: true };
    this.componentRecordsStep = { ...this.componentRecordsStep, active: false };
    this.store.dispatch(switchParentSchemasOrder());
    this.componentsOrderChange.emit(components);
  }
}
