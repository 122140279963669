import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { Workspace, WorkspacePackageItem } from '../workspaces.models';
import { NotifyService } from '../../common/services/notify.service';
import { AppState } from '../../store';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { closeWorkspacesModal, saveWorkspace, updateWorkspace } from '../store/workspaces.actions';
import {
  selectWorkspacesErrors,
  selectWorkspacesIsSubmittingFlag,
  selectWorkspacesLoadingFlag,
} from '../store/workspaces.selectors';
import { SelectPickerTypes } from '../../common/components/forms/select-picker/select-picker-types.enum';
import { Package } from '../../packages/package.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';
import { AuthorizationGuard } from '../../common/services/authorization.guard';

@Component({
  selector: 'workspace-form',
  template: `
    <div class="workspace-form">
      <div class="workspace-form-body">
        <xp-form-validation [type]="item.id ? 'Workspace' : 'NewWorkspace'" [name]="formName">
          <form id="workspaceForm" name="workspaceForm" #form="ngForm" class="form modal-form-container">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <xp-name-description-editor
                    [item]="item"
                    [optionalName]="!item.id"
                    namePlaceholder="workspace.form.new.placeholders.name"
                  ></xp-name-description-editor>
                </div>
              </div>
            </div>
            <xp-steps>
              <xp-step [step]="packagesStep">
                <div class="packages-picker">
                  <xp-select-picker-editable
                    id="package-picker-workspace-form"
                    [value]="packageSelectValue"
                    [type]="selectPickerTypes.package"
                    [filteredItems]="currentlySelected"
                    [addItemToList]="addItemToList"
                    placeholder="Add package"
                    [filterPackagesWithWorkspace]="true"
                    [hideNew]="true"
                    (valueChange)="addPackage($event)"
                  ></xp-select-picker-editable>
                  <small>{{ 'packages-picker.workspace-creation' | translate }}</small>

                  <div class="packages-picker-list" *ngIf="!(isLoading$ | async) && (item.packages || []).length">
                    <xp-package
                      class="package"
                      *ngFor="let packageItem of item.packages"
                      [package]="packageItem.package"
                      (remove)="removePackage($event)"
                      [hideEdit]="true"
                      [hideVersion]="true"
                    ></xp-package>
                  </div>
                  <xp-loader *ngIf="(isLoading$ | async) || isLoadingItem"></xp-loader>
                </div>
              </xp-step>
            </xp-steps>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="workspace-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-workspace" size="L"></common-icon>
          <h3 class="modal-title">{{ 'workspace.generic-object.title' | translate }}</h3>
        </div>

        <xp-submit-button
          (click)="saveWorkspace(item)"
          classNames="btn-lg btn-success pull-right modal-btn-save"
          [createText]="!item.id && 'workspace.form.buttons.create' | translate"
          [updateText]="item.id && 'workspace.form.buttons.update' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class WorkspaceFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Input() item: Partial<Workspace> = {};
  @Input() isLoadingItem: boolean;
  @ViewChild('form') form: NgForm;
  formName = 'workspaceForm';
  successMessageText = 'workspace.form.success_message';
  isSubmitting$ = this.store.select(selectWorkspacesIsSubmittingFlag);
  errors$ = this.store.select(selectWorkspacesErrors);
  isLoading$ = this.store.select(selectWorkspacesLoadingFlag);
  errorTexts = [];
  selectPickerTypes = SelectPickerTypes;
  addItemToList: Package;
  currentlySelected: number[] = [];
  packageSelectValue: Package;

  packagesStep = {
    title: 'Add packages to this workspace',
    activeTitle: 'Add packages to this workspace',
    tags: [],
    active: true,
    valid: true,
    lock: false,
    disable: false,
  };

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authGuard: AuthorizationGuard,
  ) {
    super();
  }

  removePackage(packageItem: Package) {
    this.item.packages = (this.item.packages as WorkspacePackageItem[]).filter(
      (item) => item.package.id !== packageItem.id,
    );
    this.addItemToList = packageItem;
    this.currentlySelected = this.item.packages.map((item) => item.package.id);
    this.packageSelectValue = null;
  }

  addPackage(packageItem: Package) {
    this.item.packages = [...(this.item.packages || []), { package: packageItem }] as WorkspacePackageItem[];
    this.currentlySelected = this.item.packages.map((item) => item.package.id);
    this.packageSelectValue = null;
  }

  saveWorkspace(workspace: Partial<Workspace>) {
    let newWorkspace: Partial<Workspace> = { ...workspace };
    const params: ListParams = {};

    if (!newWorkspace.name) {
      newWorkspace.name = 'Untitled workspace';
    }

    if (!workspace.id && newWorkspace.packages) {
      newWorkspace.jobs = newWorkspace.packages.map((item) => item.package);
      delete newWorkspace.packages;
    }

    if (workspace.id && newWorkspace.packages) {
      newWorkspace = { packages: newWorkspace.packages.map((item) => item.package.id) };
    }

    if (workspace.id) {
      params.name = workspace.name;
      if (workspace.description) {
        params.description = workspace.description;
      }
      this.store.dispatch(updateWorkspace({ workspace: newWorkspace, params, workspaceId: workspace.id }));
    } else {
      this.store.dispatch(saveWorkspace({ workspace: newWorkspace }));
    }
    this.store.dispatch(closeWorkspacesModal());

    const accountId = this.authGuard.account?.account_id;
    this.router.navigate([`/${accountId}/workspaces`]);
  }
}
