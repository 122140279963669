import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PermissionsService } from './permissions.service';
import { AuthService } from './auth.service';
import { updateAccountInfo } from '../../account/store/account.actions';
import { AppState } from '../../store';
import { AccountResource } from '../../account/resources/account.resource';
import { SegmentService } from './segment.service';
import { PusherService } from './pusher.service';
import { Account } from '../../account/account.models';
import { NotifyService } from './notify.service';

@Injectable()
export class AuthorizationGuard {
  account$ = new BehaviorSubject<Account>(null);
  account: Account;
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private accountResource: AccountResource,
    private segmentService: SegmentService,
    private pusherService: PusherService,
    private notify: NotifyService,
  ) {}

  public getAccount$(): Observable<Account> {
    return this.account$.asObservable().pipe(
      filter((account) => !!account),
      first(),
    );
  }

  public getAccount(account_id: string): void {
    const accountID = account_id || window.location.pathname.match(/[A-z0-9-]+/g)[0];

    this.authService.accountId = accountID;

    this.store.dispatch(updateAccountInfo({ account: { account_id: accountID } }));

    this.accountResource.get(accountID, { include: 'event_traits,partner' } as any).subscribe({
      next: (account) => {
        if (account.disable_ui_enabled) {
          this.router.navigate([`/not-allowed`]);
        }
        this.store.dispatch(updateAccountInfo({ account }));

        if (this.segmentService.analytics) {
          this.segmentService.analytics.group(account.id, account.event_traits);
        }

        this.pusherService.initAccount(account.id, accountID);
        this.account = account;
        this.account$.next(account);
      },
      error: (err) => {
        this.router.navigate([`/${err.status}`]);
        this.notify.error('Error occurred while getting account data.');
      },
    });
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (!this.account) {
      this.getAccount(route.params.account_id || route.parent.params.account_id);
    }
    return this.checkPermissions(route);
  }

  private checkPermissions(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const permissionName = route.data.permissions;

    if (permissionName) {
      return this.getAccount$().pipe(
        map((account: Account) => account.permissions.includes(permissionName)),
        tap((hasPermission: boolean) => {
          if (!hasPermission) {
            this.router.navigate([`/${this.authService.accountId}/401`]);
          }
        }),
      );
    }
    return true;
  }
}

export const canActivateAuthorizationFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(AuthorizationGuard).canActivate(route);
};
