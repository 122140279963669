/* eslint-disable no-param-reassign,object-shorthand */
// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { expand, map, mergeMap, reduce } from 'rxjs/operators';
import { extend, flatten } from 'lodash';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { ComponentTypeItem } from '../../constants/component_types';
import {
  AdwordsSourceComponentData,
  AnalyticsSourceComponentData,
  AllComponentData,
  BingAdsSourceComponentData,
  FacebookAdsInsightsSourceComponentData,
  Analytics4SourceComponentData,
} from '../package.models';
import { AppState } from '../../store';
import { updateComponent, updateRawComponent } from '../store/component.actions';
import { ConnectionItemsResource } from '../../connections/resources/connection-items.resource';
import { AccountsCampaignsSelectorComponentData } from '../components/component-editors-helpers/accounts-campaigns-selector.component';
import { GA4Account } from '../../connections/connection.models';

export interface AdoptedDataAdwords {
  campaigns: AdoptedDataAdwords[];
  collapsed: boolean;
  id: string;
  loaded: boolean;
  loading: boolean;
  name: string;
  selected: boolean;
  semiSelected: boolean;
  can_manage_clients?: boolean;
  parent_customer_id?: string;
  campaign: any;
  subViews: any[];
}

class AdaptedAccount {
  selected = false;
  semiSelected = false;
  collapsed = true;
  loaded = false;
  loading = false;
  campaign: any;
  subViews: any[];

  constructor(public name, public id, public campaigns?, public can_manage_clients?, public parent_customer_id?) {}
}

export interface AdaptedData {
  available: AdaptedAccount[];
  errors: string[];
}

class AdaptedBingCampaign {
  selected = false;

  constructor(public name, public id, public status) {}
}

class AdaptedAnalyticsProperty {
  selected = false;
  semiSelected = false;
  collapsed = true;

  constructor(public name, public id, public views) {}
}

function parseSelectedBingAccountsFromComponent(component) {
  const selectedAccountsObject = {};
  const selectedAccountsData = component.accounts_campaigns.split(',');
  selectedAccountsData.forEach((selectedAccount) => {
    const accountValue = selectedAccount.split(';');
    if (accountValue.length === 2) {
      if (selectedAccountsObject[accountValue[0]]) {
        selectedAccountsObject[accountValue[0]].push(Number(accountValue[1]));
      } else {
        selectedAccountsObject[accountValue[0]] = [Number(accountValue[1])];
      }
    } else {
      selectedAccountsObject[accountValue[0]] = [];
    }
  });
  return selectedAccountsObject;
}

function adaptBingCampaign(campaign): AdaptedBingCampaign {
  return new AdaptedBingCampaign(campaign.campaign_name, campaign.campaign_id, campaign.campaign_status);
}

function adaptBingAccount(account): AdaptedAccount {
  const adaptedCampaigns = [];
  account.campaigns.forEach((campaign) => {
    adaptedCampaigns.push(adaptBingCampaign(campaign));
  });
  return new AdaptedAccount(account.account_name, account.account_id, adaptedCampaigns);
}

function adaptAnalyticsCampaign(property): AdaptedAnalyticsProperty {
  return new AdaptedAnalyticsProperty(property.name, property.id, property.views);
}

function adaptAnalyticsAccount(account): AdaptedAccount {
  const adaptedProperties = [];
  account.properties.forEach((property) => {
    adaptedProperties.push(adaptAnalyticsCampaign(property));
  });
  return new AdaptedAccount(account.name, account.id, adaptedProperties);
}

function missingAccountError(accountId: string, name?: string): string {
  return `${name || 'Account'} with id: '${accountId}' could not be accessed`;
}

function setAccountSelection(account, selectedAccount) {
  account.loaded = true;
  account.collapsed = false;
  if (selectedAccount.length === 0) {
    account.selected = true;
    account.collapsed = true;
    account.campaigns.forEach((campaign) => {
      campaign.selected = true;
    });
  } else {
    selectedAccount.forEach((campaignId) => {
      const campaignIndex = account.campaigns.findIndex((item) => item.id === campaignId);
      account.campaigns[campaignIndex].selected = true;
    });
  }
}

function modifyDataForAdsV10(data: AdoptedDataAdwords[]): AdoptedDataAdwords[] {
  const newData = [];

  data.forEach((item) => {
    if (item.id === item.parent_customer_id && item.can_manage_clients) {
      item.collapsed = false;
      newData.push(item);
    } else {
      const parentItem = newData.find((parent) => parent.id === item.parent_customer_id);
      if (!parentItem) {
        return;
      }
      parentItem.campaigns.push(item);
    }
  });

  return newData;
}

export class AccountsCampaignsSelectorDataAdapterService {
  valueChanged$ = new Subject();
  isCompleted = false;

  constructor(private connectionItemsResource: ConnectionItemsResource, private store: Store<AppState>) {}

  onValueChange(value: any, key: string, rawComponent: AccountsCampaignsSelectorComponentData) {
    if (this.isCompleted) {
      return;
    }
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { ...rawComponent, [key]: value } as AllComponentData,
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
    this.valueChanged$.next({ [key]: value });
  }

  load(
    component: ComponentTypeItem,
    rawComponent: AccountsCampaignsSelectorComponentData,
    accountsToPopulate: any[],
  ): Observable<AdaptedData> {
    const adaptedData: AdaptedData = {
      available: [],
      errors: [],
    };

    const bindAdsComponent = rawComponent as BingAdsSourceComponentData;
    const analyticsComponent = rawComponent as AnalyticsSourceComponentData;
    const analytics4Component = rawComponent as Analytics4SourceComponentData;
    const facebookComponent = rawComponent as FacebookAdsInsightsSourceComponentData;
    const adwordsComponent = rawComponent as AdwordsSourceComponentData;
    let schemaRequestData;
    // eslint-disable-next-line default-case
    switch (component.componentType) {
      case COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT:
        // eslint-disable-next-line no-case-declarations
        let accountsParsed = false;

        // eslint-disable-next-line no-case-declarations
        let newSelectedAccounts = {};
        // eslint-disable-next-line no-case-declarations
        const newAccountsToPopulate = accountsToPopulate || [];
        if (!accountsParsed) {
          newSelectedAccounts = parseSelectedBingAccountsFromComponent(component);
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const accountId in newSelectedAccounts) {
            newAccountsToPopulate.push(accountId);
          }
          accountsParsed = true;
        }

        return this.loadBingAccounts(bindAdsComponent, newAccountsToPopulate).pipe(
          map((accounts: any[]) => {
            accounts.forEach((account) => {
              const adaptedAccount = adaptBingAccount(account);
              if (newSelectedAccounts[adaptedAccount.id]) {
                setAccountSelection(adaptedAccount, newSelectedAccounts[adaptedAccount.id]);
              }

              adaptedData.available.push(adaptedAccount);
            });
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const accountId in newSelectedAccounts) {
              const selectedAccount = adaptedData.available.find((item) => item.id === accountId);
              if (!selectedAccount && accountId !== '') adaptedData.errors.push(missingAccountError(accountId));
            }
            return adaptedData;
          }),
        );

      case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
        schemaRequestData = {
          api_version: facebookComponent.api_version,
        };

        if (facebookComponent.connection.type) {
          return this.connectionItemsResource
            .accounts(facebookComponent.connection.type, facebookComponent.connection.id, schemaRequestData)
            .pipe(
              map(function (ad_accounts) {
                const selectedAccounts = [...facebookComponent.ad_accounts];
                if (selectedAccounts[0] === 'any') selectedAccounts.splice(0, 1);
                ad_accounts.forEach((account) => {
                  const adaptedAccount = new AdaptedAccount(account.name, account.id);
                  const selectedIndex = selectedAccounts.indexOf(adaptedAccount.id);
                  if (selectedIndex > -1) {
                    adaptedAccount.selected = true;
                    selectedAccounts.splice(selectedIndex, 1);
                  }
                  adaptedData.available.push(adaptedAccount);
                });
                selectedAccounts.forEach((missingAccountId) => {
                  adaptedData.errors.push(missingAccountError(missingAccountId));
                });

                return adaptedData;
              }),
            );
        }
        return of(adaptedData);

      case COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT:
        if (adwordsComponent.connection.type) {
          return this.connectionItemsResource
            .customers(adwordsComponent.connection.type, adwordsComponent.connection.id, {
              api_version: adwordsComponent.api_version,
            } as any)
            .pipe(
              mergeMap((ad_accounts) => {
                ad_accounts.forEach((account) => {
                  let adaptedAccount = new AdaptedAccount(account.name, account.customer_id);
                  adaptedAccount = new AdaptedAccount(
                    account.name,
                    account.customer_id,
                    [],
                    account.can_manage_clients,
                    account.parent_customer_id,
                  );
                  adaptedData.available.push(adaptedAccount);
                });

                adaptedData.available = modifyDataForAdsV10(adaptedData.available as AdoptedDataAdwords[]);

                const viewsToLoad = flatten(
                  adaptedData.available.map((item) => item.campaigns.filter((campaign) => campaign.can_manage_clients)),
                );

                if (viewsToLoad?.length) {
                  return combineLatest(
                    viewsToLoad.map((campaign) => this.loadSubAccounts(adwordsComponent, campaign)),
                  ).pipe(
                    mergeMap((...viewsData) => {
                      viewsData[0].forEach((views, index) => {
                        viewsToLoad[index].views = views;
                        viewsToLoad[index].collapsed = false;
                      });

                      const subViewsToLoad = flatten(viewsData[0])
                        .map((view, index) => {
                          view.campaign = viewsToLoad[index];
                          return view;
                        })
                        .filter((view) => view.can_manage_clients);

                      if (subViewsToLoad?.length) {
                        return combineLatest(
                          subViewsToLoad.map((view) => this.loadSubAccounts(adwordsComponent, view, view.campaign)),
                        ).pipe(
                          mergeMap((...subViewsData) => {
                            subViewsData[0].forEach((subViews, index) => {
                              subViewsToLoad[index].subViews = subViews;
                              subViewsToLoad[index].collapsed = false;
                            });

                            return of(adaptedData);
                          }),
                        );
                      }

                      return of(adaptedData);
                    }),
                  );
                }

                return of(adaptedData);
              }),
              map((data) => {
                let selectedAccounts;

                if (adwordsComponent.customer_ids) {
                  selectedAccounts = adwordsComponent.customer_ids.replace(/-/g, '').split(',');
                } else {
                  selectedAccounts = [];
                }

                data.available.forEach((account) => {
                  const selectedIndex = selectedAccounts.indexOf(account.id);
                  if (selectedIndex > -1) {
                    account.selected = true;
                    selectedAccounts.splice(selectedIndex, 1);
                  }

                  if (account.campaigns?.length) {
                    account.campaigns.forEach((campaign) => {
                      const selectedIndexCampaign = selectedAccounts.indexOf(campaign.id);
                      if (selectedIndexCampaign > -1) {
                        campaign.selected = true;
                        selectedAccounts.splice(selectedIndexCampaign, 1);
                      }

                      if (campaign.views?.length) {
                        campaign.views.forEach((view) => {
                          const selectedIndexView = selectedAccounts.indexOf(view.id);
                          if (selectedIndexView > -1) {
                            view.selected = true;
                            selectedAccounts.splice(selectedIndexView, 1);
                          }

                          if (view.subViews?.length) {
                            view.subViews.forEach((subView) => {
                              const selectedIndexSubView = selectedAccounts.indexOf(subView.id);
                              if (selectedIndexSubView > -1) {
                                subView.selected = true;
                                selectedAccounts.splice(selectedIndexSubView, 1);
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });

                selectedAccounts.forEach((missingAccountId) => {
                  data.errors.push(missingAccountError(missingAccountId));
                });

                return data;
              }),
            );
        }
        return of(adaptedData);

      case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
        schemaRequestData = {
          api_version: analyticsComponent.api_version,
        };

        if (analyticsComponent.connection.type) {
          return this.connectionItemsResource
            .accounts(analyticsComponent.connection.type, analyticsComponent.connection.id, schemaRequestData)
            .pipe(
              map(function (ad_accounts) {
                let selectedViews;
                if (analyticsComponent.profile_ids) selectedViews = analyticsComponent.profile_ids.split(',');
                else selectedViews = [];
                ad_accounts.forEach((account) => {
                  const adaptedAccount = adaptAnalyticsAccount(account);
                  let allCampaignsSelected = adaptedAccount.campaigns && !!adaptedAccount.campaigns.length;
                  adaptedAccount.campaigns.forEach((campaign) => {
                    let someViewsSelected;
                    let allViewsSelected = campaign.views && campaign.views.length;
                    campaign.views.forEach((view) => {
                      const viewIndex = selectedViews.indexOf(view.id);
                      if (viewIndex > -1) {
                        view.selected = true;
                        someViewsSelected = true;
                        selectedViews.splice(viewIndex, 1);
                      } else {
                        allViewsSelected = false;
                      }
                      return view.selected;
                    });
                    if (allViewsSelected) {
                      campaign.selected = true;
                      return;
                    }
                    if (someViewsSelected) {
                      campaign.semiSelected = true;
                    }
                    allCampaignsSelected = false;
                  });
                  if (allCampaignsSelected) adaptedAccount.selected = true;
                  adaptedData.available.push(adaptedAccount);
                });
                selectedViews.forEach((missingViewId) => {
                  adaptedData.errors.push(missingAccountError(missingViewId, 'Profile'));
                });
                return adaptedData;
              }),
            );
        }
        return of(adaptedData);

      case COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT:
        schemaRequestData = {
          api_version: analytics4Component.api_version,
          force_fetch: true,
        };

        if (analytics4Component.connection.type) {
          return this.connectionItemsResource
            .properties(analytics4Component.connection.type, analytics4Component.connection.id, schemaRequestData)
            .pipe(
              expand((data) =>
                data.nextPageToken
                  ? this.connectionItemsResource.properties(
                      analytics4Component.connection.type,
                      analytics4Component.connection.id,
                      { ...schemaRequestData, next_page_token: data.nextPageToken },
                    )
                  : EMPTY,
              ),
              reduce((ad_accounts, res) => ad_accounts.concat(res.data), []),
              map(function (ad_accounts: GA4Account[]) {
                let selectedCampaigns;
                if (analytics4Component.property_ids) selectedCampaigns = analytics4Component.property_ids.split(',');
                else selectedCampaigns = [];
                ad_accounts.forEach((account) => {
                  const adaptedAccount = adaptAnalyticsAccount(account);
                  let allCampaignsSelected = adaptedAccount.campaigns && !!adaptedAccount.campaigns.length;
                  adaptedAccount.campaigns.forEach((campaign) => {
                    const campaignIndex = selectedCampaigns.indexOf(campaign.id);
                    if (campaignIndex > -1) {
                      campaign.selected = true;
                      selectedCampaigns.splice(campaignIndex, 1);
                    }
                    allCampaignsSelected = false;
                  });
                  if (allCampaignsSelected) adaptedAccount.selected = true;
                  adaptedData.available.push(adaptedAccount);
                });
                selectedCampaigns.forEach((missingViewId) => {
                  adaptedData.errors.push(missingAccountError(missingViewId, 'Profile'));
                });
                return adaptedData;
              }),
            );
        }
        return of(adaptedData);
    }

    return of(adaptedData);
  }

  // saving selected items in component in required format
  // eslint-disable-next-line class-methods-use-this
  save(component: ComponentTypeItem, rawComponent: AccountsCampaignsSelectorComponentData, adaptedData) {
    // eslint-disable-next-line default-case
    switch (component.componentType) {
      case COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT:
        // eslint-disable-next-line no-case-declarations
        let accounts_campaigns = '';
        accounts_campaigns = '';
        for (let i = adaptedData.selected.length - 1; i >= 0; i -= 1) {
          if (!adaptedData.selected[i].selected) {
            // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
            adaptedData.selected[i].campaigns.forEach((campaign) => {
              accounts_campaigns += `${adaptedData.selected[i].id};${campaign.id},`;
            });
          } else {
            accounts_campaigns += `${adaptedData.selected[i].id},`;
          }
        }
        accounts_campaigns = accounts_campaigns.substring(0, accounts_campaigns.length - 1);

        this.onValueChange(accounts_campaigns, 'accounts_campaigns', rawComponent);
        break;

      case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
        this.onValueChange(
          adaptedData.selected.map((account) => account.id),
          'ad_accounts',
          rawComponent,
        );

        break;

      case COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT:
        // eslint-disable-next-line no-case-declarations
        const data = adaptedData.selected
          .map((account) => {
            if (account.campaigns && account.campaigns.length) {
              return account.campaigns.map((campaign) => {
                if (campaign.views && campaign.views.length) {
                  return campaign.views.map((view) => {
                    if (view.subViews && view.subViews.length) {
                      return view.subViews.map((subView) => subView.id);
                    }
                    return view.id;
                  });
                }
                return campaign.id;
              });
            }

            return account.id;
          })
          .join(',');

        this.onValueChange(data, 'customer_ids', rawComponent);
        break;

      case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
        // eslint-disable-next-line no-case-declarations
        let viewsIds = '';

        adaptedData.selected.forEach((account) => {
          account.campaigns.forEach((campaign) => {
            campaign.views.forEach((view) => {
              viewsIds += `${view.id},`;
            });
          });
        });

        // remove trailing comma
        viewsIds = viewsIds.slice(0, -1);

        this.onValueChange(viewsIds, 'profile_ids', rawComponent);
        break;

      case COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT:
        // eslint-disable-next-line no-case-declarations
        let propertyIds = '';

        adaptedData.selected.forEach((account) => {
          account.campaigns.forEach((campaign) => {
            propertyIds += `${campaign.id},`;
          });
        });

        // remove trailing comma
        propertyIds = propertyIds.slice(0, -1);

        this.onValueChange(propertyIds, 'property_ids', rawComponent);
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  clear(component: ComponentTypeItem, rawComponent: AccountsCampaignsSelectorComponentData) {
    // eslint-disable-next-line default-case
    switch (component.componentType) {
      case COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT:
        this.onValueChange('', 'accounts_campaigns', rawComponent);
        break;
      case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
        this.onValueChange([], 'ad_accounts', rawComponent);
        break;
      case COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT:
        this.onValueChange('', 'customer_ids', rawComponent);
        break;
      case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
        this.onValueChange('', 'profile_ids', rawComponent);
        break;
      case COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT:
        this.onValueChange('', 'property_ids', rawComponent);
        break;
    }
  }

  loadBingAccounts(rawComponent: BingAdsSourceComponentData, accountsToPopulate): Observable<any> {
    const schemaRequestData: any = {
      api_version: rawComponent.api_version,
    };

    if (accountsToPopulate.length > 0) {
      schemaRequestData['accounts[]'] = accountsToPopulate;
    }

    if (rawComponent.connection.type) {
      return this.connectionItemsResource.accounts(
        rawComponent.connection.type,
        rawComponent.connection.id,
        schemaRequestData,
      );
    }

    return of([]);
  }

  loadAccountCampaigns(
    rawComponent: AccountsCampaignsSelectorComponentData,
    account,
  ): Observable<AdaptedBingCampaign[]> {
    const schemaRequestData: any = {
      api_version: rawComponent.api_version,
      'accounts[]': [account.id],
    };

    return this.connectionItemsResource
      .accounts(rawComponent.connection.type, rawComponent.connection.id, schemaRequestData)
      .pipe(
        map(function (accounts) {
          const foundAccount = accounts.find((item) => item.account_id === account.id);
          const campaigns = foundAccount.campaigns.map((campaign) => {
            return new AdaptedBingCampaign(campaign.campaign_name, campaign.campaign_id, campaign.campaign_status);
          });
          campaigns.forEach((campaign) => {
            campaign.selected = account.selected;
          });
          return campaigns;
        }),
      );
  }

  loadSubAccounts(
    rawComponent: AdwordsSourceComponentData,
    item: AdaptedAccount,
    parentItem?: AdaptedAccount,
  ): Observable<AdaptedAccount[]> {
    const schemaRequestData: any = {
      api_version: rawComponent.api_version,
      seed_account: parentItem ? parentItem.parent_customer_id : item.parent_customer_id,
      parent_account: item.id,
    };

    return this.connectionItemsResource
      .customers(rawComponent.connection.type, rawComponent.connection.id, schemaRequestData)
      .pipe(
        map((subAccounts) =>
          subAccounts.map(
            (acc) => new AdaptedAccount(acc.name, acc.customer_id, [], acc.can_manage_clients, acc.parent_customer_id),
          ),
        ),
      );
  }

  complete() {
    this.isCompleted = true;
    this.valueChanged$.complete();
  }
}
