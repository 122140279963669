import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { FunctionsResource } from '../resources/functions.resource';
import { loadPigFunctions, loadPigFunctionsError, updatePigFunctions } from './pig.actions';
import { PigFunctions } from '../pig.models';

@Injectable({
  providedIn: 'root',
})
export class PigEffects {
  loadFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPigFunctions),
      mergeMap(() =>
        this.functions.getFunctions().pipe(
          map((functions: PigFunctions) => updatePigFunctions({ functions })),
          catchError(() => of(loadPigFunctionsError())),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private functions: FunctionsResource) {}
}
