import { uniqBy } from 'lodash';
import {
  ComponentPreviewLogMessage,
  ComponentPreviewResultData,
  COMPONENT_PREVIEWER_STATUSES,
} from '../package.models';
import { LogMessage, PackageDesignerState } from '../store/state.model';
import { getComponentId, getDataFromComponent } from './components.helpers';

let isCreated = false;
let messagesCache = [];
let timeoutRef;

function getMessagesFromData(data: ComponentPreviewLogMessage, state: PackageDesignerState): LogMessage[] {
  if (!data.errors.length) {
    return [{ message: data.status_message, status: data.status }];
  }
  return data.errors.map((error) => {
    if (typeof error === 'string') {
      return { message: error, status: COMPONENT_PREVIEWER_STATUSES.failed };
    }

    const { message, ...restData } = error;
    const component = state.package.components.find(
      (componentItem) => getComponentId(componentItem) === restData.component_id,
    );
    if (component) {
      const componentName = getDataFromComponent(component).name;
      delete restData.component_id;
      (restData as any).componentName = componentName;
    }

    return {
      message: `${message} Details: ${JSON.stringify(restData).replace(/[{}"]/g, '').replace(/:/g, ' - ')}`,
      status: COMPONENT_PREVIEWER_STATUSES.failed,
    };
  });
}

export function generateLogMessages(state: PackageDesignerState, data: ComponentPreviewLogMessage): LogMessage[] {
  if (data.status === COMPONENT_PREVIEWER_STATUSES.creating) {
    isCreated = true;

    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    return [...getMessagesFromData(data, state), ...messagesCache];
  }

  if (!isCreated && !timeoutRef) {
    timeoutRef = setTimeout(() => {
      isCreated = true;
    }, 3000);
    messagesCache = uniqBy([...messagesCache, ...getMessagesFromData(data, state)], (item: LogMessage) => item.message);
    return [];
  }

  return uniqBy(
    [...state.componentPreviewer.logs, ...getMessagesFromData(data, state)],
    (item: LogMessage) => item.message,
  );
}

export function resetLogMessagesCache(): void {
  isCreated = false;
  messagesCache = [];
}

export function getLogsFromResults(data: ComponentPreviewResultData, state: PackageDesignerState) {
  if (data.error) {
    return [...state.componentPreviewer.logs, { message: data.error, status: COMPONENT_PREVIEWER_STATUSES.failed }];
  }

  if (data.info) {
    return [...state.componentPreviewer.logs, { message: data.info, status: COMPONENT_PREVIEWER_STATUSES.completed }];
  }

  return state.componentPreviewer.logs;
}
