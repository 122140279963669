import { Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { TimezonesResource } from '../../../settings/resources/timezones.resource';

@Component({
  selector: 'xp-timezone-picker',
  template: `
    <div class="timezone-picker">
      <xp-select
        [isSearchEnabled]="true"
        [value]="timezoneId"
        [options]="timezones$ | async"
        class="form-control xp-select"
        [name]="name"
        (valueChange)="onValueChange($event)"
      >
      </xp-select>
    </div>
  `,
})
export class XpTimezonePickerComponent {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  @Input() timezoneId: string = window['DEFAULT_TIMEZONE'];
  @Input() name: string;
  @Output() timezoneIdChange = new EventEmitter();

  timezones$ = this.timezonesResource
    .query()
    .pipe(map((timezones) => timezones.map(({ id, name }) => ({ value: id, text: name }))));

  constructor(private timezonesResource: TimezonesResource) {}

  onValueChange(value: string) {
    this.timezoneIdChange.emit(value);
  }
}
