import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AmazonRedshiftDestinationComponentData,
  BigQueryDestinationComponentData,
  DatabaseDestinationComponentData,
  Field,
  SnowflakeDestinationComponentData,
} from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';

export type DatabaseAdvancedOptionsDestinationComponentData =
  | AmazonRedshiftDestinationComponentData
  | BigQueryDestinationComponentData
  | DatabaseDestinationComponentData
  | SnowflakeDestinationComponentData;

@Component({
  selector: 'database-advanced-options-destination',
  template: `
    <div class="database-advanced-options-destination row">
      <div class="col-sm-6">
        <h5 style="margin-top: 2px;">{{ 'database-destination-advanced-options.title' | translate }}</h5>
        <div class="well">
          <xp-form-group *ngIf="hasIntermediateCompressionType">
            <label for="intermediate_compression_type">{{
              'database-destination-advanced-options.labels.intermediate_compression_type' | translate
            }}</label>
            <xp-select
              name="intermediate_compression_type"
              id="intermediate_compression_type"
              class="form-control xp-select"
              [value]="component.intermediate_compression_type"
              (valueChange)="onValueChange($event, 'intermediate_compression_type')"
              [options]="compressionTypesOptions"
            >
            </xp-select>
            <small>{{ 'database-destination-advanced-options.hint.intermediate_compression_type' | translate }}</small>
          </xp-form-group>
          <div class="row" *ngIf="hasMaxErrors">
            <div class="col-sm-12">
              <xp-form-group>
                <label for="max_errors">{{
                  'database-destination-advanced-options.labels.max_errors' | translate
                }}</label>
                <xp-input
                  type="number"
                  class="form-control"
                  name="max_errors"
                  id="max_errors"
                  [ngModel]="rawComponent.max_errors"
                  (ngModelChange)="onValueChange($event, 'max_errors')"
                  [placeholder]="'database-destination-advanced-options.placeholders.max_errors' | translate"
                  min="0"
                ></xp-input>
              </xp-form-group>
            </div>
          </div>
          <div class="row" *ngIf="hasMaxBadRecords">
            <div class="col-sm-12">
              <xp-form-group>
                <label for="max_bad_records">{{
                  'database-destination-advanced-options.labels.max_bad_records' | translate
                }}</label>
                <xp-input
                  type="number"
                  class="form-control"
                  name="max_bad_records"
                  id="max_bad_records"
                  [ngModel]="rawComponent.max_bad_records"
                  (ngModelChange)="onValueChange($event, 'max_bad_records')"
                  [placeholder]="'database-destination-advanced-options.placeholders.max_bad_records' | translate"
                ></xp-input>
              </xp-form-group>
            </div>
          </div>
          <div *ngIf="hasTruncateColumns" class="form-group-checkbox form-group">
            <xp-input-checkbox
              [ngModel]="rawComponent.truncate_columns"
              (ngModelChange)="onValueChange($event, 'truncate_columns')"
              [labelText]="'database-destination-advanced-options.labels.truncate_columns' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.truncate_columns' | translate
            }}</small>
          </div>

          <div *ngIf="hasTrimBlanks" class="form-group-checkbox form-group">
            <xp-input-checkbox
              [ngModel]="rawComponent.trim_blanks"
              (ngModelChange)="onValueChange($event, 'trim_blanks')"
              [labelText]="'database-destination-advanced-options.labels.trim_blanks' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.trim_blanks' | translate
            }}</small>
          </div>

          <div *ngIf="hasEmptyAsNull" class="form-group-checkbox form-group">
            <xp-input-checkbox
              [ngModel]="rawComponent.empty_as_null"
              (ngModelChange)="onValueChange($event, 'empty_as_null')"
              [labelText]="'database-destination-advanced-options.labels.empty_as_null' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.empty_as_null' | translate
            }}</small>
          </div>

          <div *ngIf="hasBlankAsNull" class="form-group-checkbox form-group">
            <xp-input-checkbox
              [ngModel]="rawComponent.blank_as_null"
              (ngModelChange)="onValueChange($event, 'blank_as_null')"
              [labelText]="'database-destination-advanced-options.labels.blank_as_null' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.blank_as_null' | translate
            }}</small>
          </div>

          <xp-form-group *ngIf="hasNullString">
            <label for="null_string">{{
              'database-destination-advanced-options.labels.null_string' | translate
            }}</label>
            <xp-input
              type="text"
              class="form-control"
              name="null_string"
              id="null_string"
              [ngModel]="rawComponent.null_string"
              (ngModelChange)="onValueChange($event, 'null_string')"
              [placeholder]="'database-destination-advanced-options.placeholders.null_string' | translate"
            ></xp-input>
            <small>{{ 'database-destination-advanced-options.hint.null_string' | translate }}</small>
          </xp-form-group>
          <xp-form-group *ngIf="hasReplacementCharacter">
            <label for="replacement_char">{{
              'database-destination-advanced-options.labels.replacement_char' | translate
            }}</label>
            <xp-input
              type="text"
              class="form-control"
              name="replacement_char"
              id="replacement_char"
              [ngModel]="rawComponent.replacement_char"
              (ngModelChange)="onValueChange($event, 'replacement_char')"
              [placeholder]="'database-destination-advanced-options.placeholders.replacement_char' | translate"
              maxlength="1"
            ></xp-input>
          </xp-form-group>

          <div *ngIf="hasRoundDecimals" class="form-group-checkbox form-group">
            <xp-input-checkbox
              [ngModel]="rawComponent.round_decimals"
              (ngModelChange)="onValueChange($event, 'round_decimals')"
              [labelText]="'database-destination-advanced-options.labels.round_decimals' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.round_decimals' | translate
            }}</small>
          </div>

          <div
            *ngIf="
              hasExplicitIds &&
              rawComponent.operation_type !== 'merge_update_and_insert' &&
              rawComponent.operation_type !== 'merge'
            "
            class="form-group-checkbox form-group"
          >
            <xp-input-checkbox
              [ngModel]="rawComponent.explicit_ids"
              (ngModelChange)="onValueChange($event, 'explicit_ids')"
              [labelText]="'database-destination-advanced-options.labels.explicit_ids' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.explicit_ids' | translate
            }}</small>
          </div>

          <xp-form-group *ngIf="hasCompUpdate">
            <label for="comp_update">{{
              'database-destination-advanced-options.labels.comp_update' | translate
            }}</label>
            <xp-select
              name="comp_update"
              id="comp_update"
              class="form-control xp-select"
              [value]="rawComponent.comp_update"
              (valueChange)="onValueChange($event, 'comp_update')"
              [options]="compUpdateOptions"
            >
            </xp-select>
            <small *ngIf="rawComponent.comp_update === 'auto'">{{
              'database-destination-advanced-options.selects.comp_update.hint.auto' | translate
            }}</small>
            <small *ngIf="rawComponent.comp_update === 'on'">{{
              'database-destination-advanced-options.selects.comp_update.hint.on' | translate
            }}</small>
            <small *ngIf="rawComponent.comp_update === 'off'">{{
              'database-destination-advanced-options.selects.comp_update.hint.off' | translate
            }}</small>
          </xp-form-group>

          <xp-form-group *ngIf="hasCompRows">
            <label for="comp_rows">{{ 'database-destination-advanced-options.labels.comp_rows' | translate }}</label>
            <xp-input
              type="number"
              class="form-control"
              name="comp_rows"
              id="comp_rows"
              [ngModel]="rawComponent.comp_rows"
              (ngModelChange)="onValueChange($event, 'comp_rows')"
              min="0"
              (keypress)="checkPositive($event)"
              [placeholder]="'database-destination-advanced-options.placeholders.comp_rows' | translate"
            ></xp-input>
            <small>{{ 'database-destination-advanced-options.hint.comp_rows' | translate }}</small>
          </xp-form-group>

          <div class="row" *ngIf="hasBatchSize">
            <div class="col-sm-6" *ngIf="hasBatchSize">
              <xp-form-group>
                <label for="batch_size">{{
                  'database-destination-advanced-options.labels.batch_size' | translate
                }}</label>
                <xp-input
                  type="number"
                  class="form-control"
                  name="batch_size"
                  id="batch_size"
                  [ngModel]="rawComponent.batch_size"
                  (ngModelChange)="onValueChange($event, 'batch_size')"
                  [placeholder]="'database-destination-advanced-options.placeholders.batch_size' | translate"
                ></xp-input>
              </xp-form-group>
            </div>
          </div>
          <div class="row" *ngIf="hasTransactions">
            <div class="col-sm-12">
              <label for="single_transaction_per_batch">{{
                'database-destination-advanced-options.labels.single_transaction_per_batch' | translate
              }}</label>
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.single_transaction_per_batch"
                    (ngModelChange)="onValueChange($event, 'single_transaction_per_batch')"
                    name="single_transaction_per_batch"
                    id="single_transaction_per_batch-false"
                    [value]="false"
                  />
                  <label for="single_transaction_per_batch-false">{{
                    'database-destination-advanced-options.radio.single_transaction_per_batch.transaction_per_connection'
                      | translate
                  }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.single_transaction_per_batch"
                    (ngModelChange)="onValueChange($event, 'single_transaction_per_batch')"
                    name="single_transaction_per_batch"
                    id="single_transaction_per_batch-true"
                    [value]="true"
                  />
                  <label for="single_transaction_per_batch-true">{{
                    'database-destination-advanced-options.radio.single_transaction_per_batch.transaction_per_batch'
                      | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="hasParallel">
            <div class="col-sm-9">
              <xp-form-group>
                <label for="parallel">{{ 'database-destination-advanced-options.labels.parallel' | translate }}</label>
                <xp-input
                  type="number"
                  class="form-control"
                  name="parallel"
                  id="parallel"
                  [ngModel]="rawComponent.parallel"
                  (ngModelChange)="onValueChange($event, 'parallel')"
                  [placeholder]="'database-destination-advanced-options.placeholders.parallel' | translate"
                ></xp-input>
              </xp-form-group>
            </div>
          </div>
          <xp-form-group *ngIf="hasSplitByField">
            <label for="split_by_field">{{
              'database-destination-advanced-options.labels.split_by_field' | translate
            }}</label>
            <xp-select
              id="split_by_field"
              name="split_by_field"
              class="form-control xp-select"
              [value]="rawComponent.split_by_field"
              (valueChange)="onValueChange($event, 'split_by_field')"
              [options]="schemaFieldsOptions"
            >
            </xp-select>
            <small>{{ 'database-destination-advanced-options.hint.input_data_will_be_spilt' | translate }}</small>
          </xp-form-group>

          <div
            *ngIf="
              rawComponent.connection &&
              (rawComponent.connection.type === 'sqlserver' || rawComponent.connection.type === 'azuresynapseanalytics')
            "
            class="form-group form-group-checkbox"
          >
            <xp-input-checkbox
              (ngModelChange)="onValueChange($event, 'identity_insert_sql_server')"
              [ngModel]="rawComponent.identity_insert_sql_server"
              [labelText]="'database-destination-advanced-options.labels.identity_insert' | translate"
            ></xp-input-checkbox>
            <small class="input-checkbox">{{
              'database-destination-advanced-options.hint.identity_insert' | translate
            }}</small>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class DatabaseAdvancedOptionsDestinationComponent implements OnInit {
  @Input() rawComponent: DatabaseAdvancedOptionsDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Input() schemaFields: Field[] = [];

  hasIntermediateCompressionType = false;
  hasTruncateColumns = false;
  hasTrimBlanks = false;
  hasNullString = false;
  hasEmptyAsNull = false;
  hasBlankAsNull = false;
  hasReplacementCharacter = false;
  hasRoundDecimals = false;
  hasExplicitIds = false;
  hasCompUpdate = false;

  hasBatchSize = false;
  hasParallel = false;
  hasTransactions = false;
  hasSplitByField = false;
  hasMaxErrors = false;
  hasMaxBadRecords = false;
  hasCompRows = false;

  schemaFieldsOptions = [];

  compressionTypesOptions = [
    {
      value: 'none',
      text: 'database-destination-advanced-options.selects.intermediate_compression_type.options.none',
      translate: true,
    },
    {
      value: 'gzip',
      text: 'database-destination-advanced-options.selects.intermediate_compression_type.options.gzip',
      translate: true,
    },
  ];

  compUpdateOptions = [
    {
      value: 'auto',
      text: 'database-destination-advanced-options.selects.comp_update.options.auto',
      translate: true,
    },

    {
      value: 'on',
      text: 'database-destination-advanced-options.selects.comp_update.options.on',
      translate: true,
    },

    {
      value: 'off',
      text: 'database-destination-advanced-options.selects.comp_update.options.off',
      translate: true,
    },
  ];

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    if (
      (!this.rawComponent.split_by_field && this.schemaFields && this.schemaFields.length) ||
      (!this.schemaFields.some((item) => item.name === this.rawComponent.split_by_field) &&
        this.schemaFields &&
        this.schemaFields.length)
    ) {
      this.onValueChange(this.schemaFields[0].name, 'split_by_field');
    }

    this.schemaFieldsOptions = (this.schemaFields || []).map(({ name }) => ({ text: name, value: name }));

    // eslint-disable-next-line default-case
    switch (this.component.componentType) {
      case COMPONENT_TYPE.AMAZON_REDSHIFT_DESTINATION_COMPONENT:
        this.hasIntermediateCompressionType = true;
        this.hasMaxErrors = true;
        this.hasTruncateColumns = true;
        this.hasTrimBlanks = true;
        this.hasNullString = true;
        this.hasEmptyAsNull = true;
        this.hasBlankAsNull = true;
        this.hasReplacementCharacter = true;
        this.hasRoundDecimals = true;
        this.hasExplicitIds = true;
        this.hasCompUpdate = true;
        this.hasCompRows = true;
        break;

      case COMPONENT_TYPE.DATABASE_DESTINATION_COMPONENT:
        this.hasBatchSize = true;
        this.hasParallel = true;
        this.hasTransactions = true;
        this.hasSplitByField = true;
        break;

      case COMPONENT_TYPE.BIG_QUERY_DESTINATION_COMPONENT:
        this.hasMaxBadRecords = true;
        break;

      case COMPONENT_TYPE.SNOWFLAKE_DESTINATION_COMPONENT:
        this.hasMaxErrors = true;
        this.hasTruncateColumns = true;
        this.hasEmptyAsNull = true;
        this.hasNullString = true;
        break;

      case COMPONENT_TYPE.SPANNER_DESTINATION_COMPONENT:
        break;

      case COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkPositive(event: any) {
    // disable "+,-,."
    if (event.charCode === 43 || event.charCode === 45 || event.charCode === 46) {
      event.preventDefault();
    }
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }
}
