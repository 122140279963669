import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../store';
import { UsersResource } from '../../account/resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { User } from '../../account/user.models';

@Component({
  selector: 'settings-notifications',
  template: `
    <div class="settings-body">
      <form name="userForm" #form="ngForm" (ngSubmit)="updateNotifications()">
        <p>{{ 'user.settings.notifications.lead' | translate }}</p>
        <div class="form-group">
          <mat-slide-toggle name="xplenty" id="xplenty" [(ngModel)]="user.notification_settings.web">
            {{ 'user.settings.notifications.checkboxes.xplenty' | translate }}</mat-slide-toggle
          >
        </div>
        <div class="form-group">
          <mat-slide-toggle name="email" id="email" [(ngModel)]="user.notification_settings.email">
            {{ 'user.settings.notifications.checkboxes.email' | translate }}</mat-slide-toggle
          >
        </div>
        <xp-submit-button
          classNames="btn-md btn-success"
          [updateText]="'user.settings.notifications.actions.submit' | translate"
          [isFormValid]="true"
          [isFormSubmitting]="isSubmitting"
        ></xp-submit-button>
      </form>
    </div>
  `,
})
export class SettingsNotificationsComponent extends BaseForm implements OnInit, BaseFormInterface {
  user: Partial<User> = {
    notification_settings: {},
  };
  @ViewChild('form') form: NgForm;
  isSubmitting = false;
  formName = 'userForm';
  successMessageText = 'user.settings.notifications.success_message';

  constructor(
    private usersResource: UsersResource,
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();

    usersResource.get().subscribe({
      next: (user) => {
        this.user = user;
      },
      error: () => {
        this.notify.error('An error occurred while loading user data.');
      },
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateNotifications() {
    this.isSubmitting = true;

    return this.usersResource.update(this.user).subscribe({
      next: () => {
        this.handleSuccess();
        this.isSubmitting = false;
      },
      error: (res: HttpErrorResponse) => {
        this.isSubmitting = false;
      },
    });
  }
}
