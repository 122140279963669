import { createSelector } from '@ngrx/store';
import { selectPackageDesignerState } from './package-designer.selectors';
import { PackageDesignerState } from './state.model';

export const selectPackagePreviewLoadingFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.isLoading,
);
export const selectPackagePreviewIsErrorFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.isError,
);
export const selectPackagePreviewIsInfoFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.isInfo,
);
export const selectPackagePreviewResult = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.resultData,
);
export const selectPackagePreviewIsValidFlag = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.isValid,
);

export const selectPackagePreviewLogs = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.logs,
);

export const selectPackagePreviewProgress = createSelector(
  selectPackageDesignerState,
  (state: PackageDesignerState) => state.componentPreviewer.progress,
);
