import { Component, ElementRef, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, fromEvent, merge, Subscription } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../../store';
import { XpFormValidationComponent } from './xp-form-validation.component';
import { selectFormFieldValidationMessage, selectValidators } from '../store/validators.selectors';
import { getValidatorData, serializeForm } from '../helpers/validator-data.helper';
import { FieldsCollectionValidationsService } from '../../services/fields-collection-validations.service';
import { removeField, setFormFieldValidity } from '../store/validators.actions';
import { ValidatorData } from '../helpers/validator-data.model';
import { transformErrorFieldName } from '../../helper/transform-error-field-name.helper';

@Component({
  selector: 'xp-form-group',
  template: `<div class="form-group {{ innerClass }}">
    <ng-content></ng-content>
    <div
      class="input-error-message"
      *ngIf="!isErrorMessageHidden"
      [ngClass]="{ hidden: !isError }"
      [attr.data-field-type]="fieldName"
    >
      <span>{{ errorMessage }}</span>
    </div>
  </div>`,
})
export class XpFormGroupComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() validationDisabled: boolean = false;
  @Input() innerClass: string = '';
  errorMessage: string;
  isError = false;
  validators$ = this.store.select(selectValidators);
  subscription;
  formElement: HTMLFormElement;
  services = {
    fieldsCollectionValidationsService: this.fieldsCollectionValidationsService,
    translate: this.translate,
  };
  fieldName: string;
  formName: string;
  isBlurred = false;
  isErrorMessageHidden = false;
  errorMessageSubscriber: Subscription;

  constructor(
    private elementRef: ElementRef,
    private formComponent: XpFormValidationComponent,
    private store: Store<AppState>,
    private translate: TranslateService,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formElement = this.formComponent.elementRef.nativeElement.querySelector('form');
      this.formName = this.formComponent.formName;
      const inputElement = this.elementRef.nativeElement.querySelector('input');
      const textareaElement = this.elementRef.nativeElement.querySelector('textarea');
      const selectElement = this.elementRef.nativeElement.querySelector('select');
      this.fieldName =
        (inputElement && inputElement.getAttribute('name')) ||
        (selectElement && selectElement.getAttribute('name')) ||
        (textareaElement && textareaElement.getAttribute('name'));

      if (this.fieldName && this.fieldName.includes('{{')) {
        const codeEditorElement =
          this.elementRef.nativeElement.querySelector('code-editor') ||
          this.elementRef.nativeElement.querySelector('.code-editor');
        if (codeEditorElement) {
          this.fieldName = codeEditorElement && codeEditorElement.getAttribute('name');
        }
      }

      if (this.validationDisabled) {
        this.formComponent.disableValidationForField(this.fieldName);
        return;
      }

      this.errorMessageSubscriber = this.store
        .pipe(selectFormFieldValidationMessage(this.formName, this.fieldName))
        .subscribe((message) => {
          if (!message) {
            return;
          }
          this.isError = true;
          this.errorMessage = `${this.getLabel()} ${message}`;
        });

      this.formComponent.validateForm(this.fieldName);
      this.listenChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.validationDisabled) {
      return;
    }

    if (!changes.validationDisabled.previousValue && changes.validationDisabled.currentValue && this.subscription) {
      this.subscription.unsubscribe();
    }

    if (changes.validationDisabled.previousValue && !changes.validationDisabled.currentValue) {
      this.listenChanges();
    }
  }

  private listenChanges() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const inputElement = this.elementRef.nativeElement.querySelector('input');
    const textareaElement = this.elementRef.nativeElement.querySelector('textarea');
    const selectElement = this.elementRef.nativeElement.querySelector('select');

    const blurListeners = [inputElement, selectElement, textareaElement].filter(Boolean).map((element) => {
      return fromEvent(element, 'blur');
    });

    merge(...blurListeners)
      .pipe(first())
      .subscribe(() => {
        this.isBlurred = true;

        if (this.isError) {
          this.store.dispatch(
            setFormFieldValidity({ formName: this.formName, isValid: !this.isError, fieldName: this.fieldName }),
          );
          this.isErrorMessageHidden = false;
        }
      });

    const listeners = [inputElement, selectElement, textareaElement].filter(Boolean).map((element) => {
      return fromEvent(element, 'input');
    });

    if (!listeners.length) {
      return;
    }

    this.subscription = combineLatest([...listeners, this.validators$])
      .pipe(
        withLatestFrom(this.store),
        map(([[event, validatorsObject], store]) => {
          const { name, value } = (event as any).target || event.path[0];
          const validatorObject = validatorsObject[this.formComponent.type];

          if (!validatorObject) {
            return [{ valid: true, order: 0 }] as ValidatorData[];
          }

          const validators = validatorObject[name];
          const formScope = serializeForm(this.formElement, this.formComponent.disabledValidationFields);

          return getValidatorData({
            validators,
            value,
            name,
            services: this.services,
            formScope,
            store,
            label: this.getLabel(),
          });
        }),
      )
      .subscribe((validatorData) => {
        this.isError = validatorData.some((item) => !item.valid);

        if (this.isError && validatorData.length) {
          this.errorMessage = validatorData.filter((item) => !item.valid).sort((a, b) => b.order - a.order)[0].message;
        } else {
          this.errorMessage = '';
        }

        if (this.isError && !this.isBlurred) {
          this.isErrorMessageHidden = true;
          return;
        }

        this.store.dispatch(
          setFormFieldValidity({ formName: this.formName, isValid: !this.isError, fieldName: this.fieldName }),
        );
      });
  }

  getLabel(): string {
    return (
      (this.elementRef.nativeElement.querySelector('label') || {}).innerText || transformErrorFieldName(this.fieldName)
    );
  }

  ngOnDestroy() {
    if (this.validationDisabled) {
      return;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.errorMessageSubscriber) {
      this.errorMessageSubscriber.unsubscribe();
    }

    this.store.dispatch(removeField({ formName: this.formName, fieldName: this.fieldName }));
  }
}
