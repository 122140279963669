/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { CONTEXT_HELP } from '../../constants/context-help';

interface ContextHelpOption {
  title: string;
  type: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContextHelpService {
  private stateToResourceMap = {
    'app.account.settings.account.members': { resource: 'Member', action: 'index' },
    'app.account.settings.account.plans': { resource: 'Plan', action: 'edit' },
    'app.account.settings.account.info': { resource: 'Account', action: 'edit' },
    'app.account.settings.account.hooks': { resource: 'Hook', action: 'index' },
    'app.account.settings.my.notifications': { resource: 'Notification', action: 'show' },
    'app.account.settings.my.password': { resource: 'User', action: 'edit_password' },
    'app.account.settings.my.info': { resource: 'User', action: 'edit' },
    'app.account.settings.developer.tokens': { resource: 'User', action: 'edit' },
  };

  public getHelpForState(url: string): ContextHelpOption[] {
    let help;

    if (url.includes('/connections')) {
      help = { resource: 'Connection', action: 'index' };
    }

    if (url.includes('/connections/new') || url.match(/\/connections\/\w+\/new/)) {
      help = { resource: 'Connection', action: 'new' };
    }

    if (url.includes('/packages')) {
      help = { resource: 'Package', action: 'index' };
    }

    if (url.includes('/schedules')) {
      help = { resource: 'Schedule', action: 'index' };
    }

    if (url.includes('/clusters')) {
      help = { resource: 'Cluster', action: 'index' };
    }

    if (url.match(/\/clusters\/\w+\/edit/)) {
      help = { resource: 'Cluster', action: 'show' };
    }

    if (url.match(/\/schedules\/\w+\/edit/)) {
      help = { resource: 'Schedule', action: 'show' };
    }

    if (url.match(/\/packages\/\w+\/edit/)) {
      help = { resource: 'Package', action: 'edit' };
    }

    if (help) {
      return ContextHelpService.getHelpForResource(help.resource, help.action);
    }
    return [];
  }

  private static getHelpForResource(resource: string, action: string): ContextHelpOption[] {
    return CONTEXT_HELP[resource][action];
  }
}
