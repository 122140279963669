import { Component, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../store';
import { UsersResource } from '../../account/resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { logout } from '../../account/store/account.actions';
import { PASSWORD_PATTERN } from '../../config/password-policy';

interface Credentials {
  current_password: string;
  new_password: string;
  new_password_confirm: string;
}

@Component({
  selector: 'settings-password',
  template: `
    <div class="settings-body settings-password">
      <xp-form-validation type="UserPasswordChange" [name]="formName">
        <form #form="ngForm" (ngSubmit)="changePassword()" novalidate>
          <div class="form-group">
            <xp-form-group>
              <label for="current_password">{{ 'user.settings.password.labels.current_password' | translate }}</label>
              <xp-input
                type="password"
                id="current_password"
                name="current_password"
                class="form-control"
                [(ngModel)]="credentials.current_password"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="form-group">
            <xp-form-group>
              <label for="new_password">{{ 'user.settings.password.labels.new_password' | translate }}</label>
              <xp-input
                type="password"
                id="new_password"
                name="new_password"
                class="form-control"
                [pattern]="passwordPattern"
                [(ngModel)]="credentials.new_password"
              ></xp-input>
              <!--                  <div class="gray-text">Password must be set via Integrate.io</div>-->
              <xp-password-strength [password]="credentials.new_password"></xp-password-strength>
            </xp-form-group>
          </div>
          <xp-submit-button
            classNames="btn-md btn-success"
            [updateText]="'user.settings.password.actions.submit' | translate"
            [isFormValid]="form.valid"
            [isFormSubmitting]="isSubmitting"
          ></xp-submit-button>
        </form>
      </xp-form-validation>
    </div>
  `,
})
export class SettingsPasswordComponent extends BaseForm implements OnInit, BaseFormInterface {
  credentials: Credentials = {
    current_password: '',
    new_password: '',
    new_password_confirm: '',
  };
  @ViewChild('form') form: NgForm;
  isSubmitting = false;
  formName = 'userForm';
  successMessageText = 'user.settings.password.success_message';
  passwordPattern = PASSWORD_PATTERN;
  // disablePassword$ = this.store.select(selectUser).pipe(map((user) => !/@xplenty\.com/.test(user.email)));

  constructor(
    private usersResource: UsersResource,
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  changePassword() {
    this.isSubmitting = true;

    return this.usersResource.update(this.credentials).subscribe({
      next: () => {
        this.store.dispatch(logout({ shouldRedirect: true }));
      },
      error: () => {
        this.isSubmitting = false;
      },
    });
  }
}
