import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { RankComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';

@Component({
  selector: 'rank-editor',
  template: `
    <div class="rank-editor">
      <xp-form-validation type="RankComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <div class="col-sm-6">
              <xp-form-group>
                <label for="field_alias"
                  ><strong>{{ 'rank-editor.form.labels.add_rank_field' | translate }}</strong></label
                >
                <xp-input
                  type="text"
                  class="form-control"
                  name="field_alias"
                  id="field_alias"
                  [ngModel]="rawComponent.field_alias || 'rank'"
                  placeholder="{{ 'rank-editor.form.placeholders.rank' | translate }}"
                  (ngModelChange)="onFieldAliasChange($event)"
                ></xp-input>
              </xp-form-group>
              <div class="form-group">
                <xp-input-checkbox
                  name="dense"
                  [ngModel]="rawComponent.dense"
                  [labelText]="'rank-editor.form.checkbox' | translate"
                  (ngModelChange)="onDenseChange($event)"
                ></xp-input-checkbox>
              </div>
              <hr />
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label for="data_order"
                  ><strong>{{ 'rank-editor.form.labels.data_order' | translate }}</strong></label
                >
                <div class="form-group-options">
                  <div class="radio">
                    <input
                      type="radio"
                      [ngModel]="rawComponent.data_order"
                      name="data_order"
                      value="no_sort"
                      id="data-order-no-sort"
                      (ngModelChange)="onDataOrderChange($event)"
                    />
                    <label for="data-order-no-sort">{{ 'rank-editor.form.data_order.no_sort' | translate }}</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      [ngModel]="rawComponent.data_order"
                      name="data_order"
                      value="sort"
                      id="data-order-sort"
                      (ngModelChange)="onDataOrderChange($event)"
                    />
                    <label for="data-order-sort">{{ 'rank-editor.form.data_order.sort' | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" [hidden]="rawComponent.data_order !== 'sort'">
              <sort-collection
                [records]="rawComponent.fields || []"
                [parentSchemas]="parentSchemas"
                (recordsChange)="onRecordsChange($event)"
                [isVisible]="rawComponent.data_order === 'sort'"
                (validityChange)="onSortCollectionValidityChange($event)"
              ></sort-collection>
            </div>
          </div>
          <div class="row">
            <component-previewer [componentId]="rawComponent.id"></component-previewer>
          </div>
        </form>
      </xp-form-validation>
    </div>
  `,
})
export class RankEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: RankComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  sortCollectionValid = false;
  isFormValid = false;
  validationChangeSubscription: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });

    if (!this.rawComponent.data_order) {
      this.onDataOrderChange(this.rawComponent.fields?.length ? 'sort' : 'no_sort');
    }

    if (!this.rawComponent.dense) {
      this.onDenseChange(false);
    }
  }

  onRecordsChange(records) {
    this.store.dispatch(updateRawComponent({ rawComponent: { fields: records } }));
  }

  onValidityChange() {
    const isValid = (this.rawComponent.data_order === 'no_sort' || this.sortCollectionValid) && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onDataOrderChange(data_order: string) {
    const component: any = { data_order };

    if (component.data_order === 'no_sort') {
      component.fields = [];
    }
    this.store.dispatch(updateComponent({ component }));
    this.store.dispatch(updateRawComponent({ rawComponent: { ...component } }));

    this.onValidityChange();
  }

  onDenseChange(dense: boolean) {
    if (dense == null) {
      return;
    }
    this.store.dispatch(updateRawComponent({ rawComponent: { dense } }));
    this.store.dispatch(updateComponent({ component: { dense } }));
  }

  onFieldAliasChange(field_alias) {
    this.store.dispatch(updateRawComponent({ rawComponent: { field_alias } }));
    this.store.dispatch(updateComponent({ component: { field_alias } }));
  }

  onSortCollectionValidityChange(value) {
    this.sortCollectionValid = value;

    this.onValidityChange();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
