import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { pigReducer } from './store/pig.reducer';
import { PigEffects } from './store/pig.effects';
import './services/xplenty-pig-functions.service';
import './services/xplenty-pig.service';

@NgModule({
  imports: [BrowserModule, StoreModule.forFeature('pig', pigReducer), EffectsModule.forFeature([PigEffects])],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class PigModule {}
