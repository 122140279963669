import * as VS from '../helpers/validators.helpers';
import { ValidatorRunner } from './models';

export const AbsenceValidator: ValidatorRunner = {
  name: 'AbsenceValidator',
  message: 'errors.AbsenceValidator',
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    return VS.isEmpty(value);
  },
};
