import { Injectable } from '@angular/core';

import { PackageDesignerComponent } from './package-designer.component';

@Injectable()
export class PackageDesignerDeactivateGuard {
  // eslint-disable-next-line class-methods-use-this
  canDeactivate(target: PackageDesignerComponent) {
    if (target.isPackageDirty) {
      return window.confirm(target.confirmationMessage);
    }
    return true;
  }
}
