import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { Usage } from '../usage.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class UsagesResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  get(params?: ListParams): Observable<Usage> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Usage>(`${this.apiRoot}/${account_id}/api/usage/statistics`, { params: params as QueryParams }),
      ),
    );
  }
}
