import { Component, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsersResource } from '../../account/resources/users.resource';
import { User } from '../../account/user.models';
import { NotifyService } from '../../common/services/notify.service';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { DialogType } from '../../common/services/components/dialog-template.component';
import { UnlockFeaturesDialogService } from '../../common/services/unlock-features-dialog.service';

@Component({
  selector: 'settings-tokens',
  template: `
    <div class="settings-body settings-tokens">
      <h2>{{ 'tokens.title' | translate }}</h2>
      <p [innerHTML]="'tokens.description' | translate"></p>
      <div>
        <div class="form-group">
          <pre class="input"><code>{{ isKeyHidden ? '••••••••••••••••••••' : user.api_key }}</code></pre>
        </div>
        <button type="button" class="btn btn-md btn-info" *ngIf="user.api_key" (click)="toggleKey()">
          {{ isKeyHidden ? 'Show' : 'Hide' }}
        </button>
        <button type="button" class="btn btn-md btn-danger" *ngIf="user.api_key" (click)="reset()">
          {{ 'tokens.form.button-reset' | translate }}
        </button>
        <button type="button" class="btn btn-md btn-success" *ngIf="!user.api_key" (click)="getApiKey()">
          Reveal API key
        </button>
      </div>
    </div>
  `,
})
export class SettingsTokensComponent {
  user: Partial<User> = {};
  @ViewChild('form') form: NgForm;
  isSubmitting = false;
  formName = 'userForm';
  isKeyHidden = true;

  constructor(
    private usersResource: UsersResource,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
    private unlockFeaturesDialog: UnlockFeaturesDialogService,
  ) {
    usersResource.get().subscribe({
      next: (user) => {
        this.user = user;
      },
      error: () => {
        this.notify.error('An error occurred while loading user data.');
      },
    });
  }

  getApiKey() {
    this.unlockFeaturesDialog.openDialog({});
  }

  reset() {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('tokens.confirm.title'),
      hint: this.translate.instant('tokens.confirm.hint'),
      yes: this.translate.instant('tokens.confirm.yes'),
      no: this.translate.instant('tokens.confirm.no'),
      type: DialogType.danger,
    });

    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.usersResource.regenerate_tokens().subscribe({
          next: (user) => {
            this.user.api_key = user.api_key;
          },
          error: () => {
            this.notify.error('An error occurred while getting data.');
          },
        });
      }
    });
  }

  toggleKey() {
    this.isKeyHidden = !this.isKeyHidden;
  }
}
