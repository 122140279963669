import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
  selector: '[templateName]',
})
export class TemplateNameDirective {
  constructor(public readonly template: TemplateRef<any>) {}

  @Input('templateName') templateName: string;
}
