import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Package } from '../../../../packages/package.models';

@Component({
  selector: 'xp-select-picker-package',
  template: `
    <div class="select-picker-package">
      <div class="select-picker-item">
        <div class="select-picker-header">
          <div class="select-picker-icon">
            <common-icon iconId="icon-workflow" *ngIf="package.flow_type === 'workflow'"></common-icon>
            <common-icon iconId="icon-package" *ngIf="package.flow_type === 'dataflow'"></common-icon>
          </div>
          <div class="select-picker-name">
            <div class="select-picker-name-title">
              <span [innerHTML]="package.name | xpLengthCheck : 50 | xpHighLight : highlightValue"></span>
              <button type="button" class="btn btn-sm btn-black" (click)="openPackage($event, package)">
                Open <i class="fa fa-external-link"></i>
              </button>
            </div>
            <span
              class="select-picker-name-description"
              [innerHTML]="package.description | xpLengthCheck : 120 | xpHighLight : highlightValue"
            ></span>
          </div>
        </div>
        <div class="select-picker-body">
          <span class="small" *ngIf="package.workspace"
            >Workspace: {{ package.workspace.name | xpLengthCheck : 40 }}</span
          >
          <span class="small"
            >last modified:
            <span class="date-tooltip" [matTooltip]="package.updated_at | xpDateUTC" matTooltipPosition="above">{{
              package.updated_at | xpPrettyDate
            }}</span></span
          >
        </div>
      </div>
    </div>
  `,
})
export class XpSelectPickerPackageComponent {
  @Input() package: Package;
  @Input() highlightValue: string = '';

  constructor(private route: ActivatedRoute) {}

  openPackage(event: MouseEvent, packageItem: Package) {
    window.open(`/${this.route.parent.snapshot.params.account_id}/packages/${packageItem.id}/edit`);
    event.stopPropagation();
  }
}
