import { Component, Input } from '@angular/core';

interface ColumnDefs {
  name: string;
}

interface Data {
  columnDefs?: ColumnDefs[];
  data?: { [key: string]: string }[];
}

@Component({
  selector: 'table-view',
  template: `
    <div class="table-view">
      <table class="table table-bordered table-striped xplenty-table data-preview-table">
        <thead>
          <tr>
            <th *ngFor="let column of data.columnDefs" [ngClass]="column.name">
              {{ column.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data.data">
            <td *ngFor="let column of data.columnDefs" [ngClass]="column.name">{{ item[column.name] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  `,
})
export class TableViewComponent {
  @Input() data: Data;
}
