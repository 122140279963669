import { Component, Inject, Input } from '@angular/core';
import { Package } from '../../../../packages/package.models';

@Component({
  selector: 'xp-select-picker-schedule-package-version',
  template: `
    <div class="select-picker-package">
      <div class="select-picker-item">
        <div class="select-picker-header">
          <div class="select-picker-icon">
            <common-icon iconId="icon-workflow" *ngIf="schedulePackageVersion.flow_type === 'workflow'"></common-icon>
            <common-icon iconId="icon-package" *ngIf="schedulePackageVersion.flow_type === 'dataflow'"></common-icon>
          </div>
          <div class="select-picker-name">
            <span
              class="select-picker-name-title"
              [innerHTML]="schedulePackageVersion.name | xpLengthCheck : 50 | xpHighLight : highlightValue"
            ></span>
          </div>
        </div>
        <div class="select-picker-body">
          <span class="small version">Version: {{ schedulePackageVersion.package_version }}</span>
          <span class="small">Author: {{ schedulePackageVersion.author }}</span>
          <span class="small"
            >Created:
            <span class="date-tooltip" [matTooltip]="schedulePackageVersion.updated_at | xpDateUTC">{{
              schedulePackageVersion.updated_at | xpPrettyDate
            }}</span></span
          >
          <span class="small" *ngIf="schedulePackageVersion.version_description !== null">{{
            packageDescription
          }}</span>
        </div>
      </div>
    </div>
  `,
})
export class XpSelectPickerSchedulePackageVersionComponent {
  @Input() schedulePackageVersion: Package;
  @Input() highlightValue: string = '';

  get packageDescription(): string {
    return this.schedulePackageVersion.version_description
      ? `Description: ${this.schedulePackageVersion.version_description}`
      : null;
  }
}
