import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { expand, reduce, tap, catchError, withLatestFrom, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../store';
import { AccountResource } from '../resources/account.resource';
import { Account } from '../account.models';
import { updateAccountsList } from '../store/account.actions';
import { getAccountsListError, openAccountsModal } from '../../settings/store/accounts/accounts.actions';
import { NotifyService } from '../../common/services/notify.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  loaded = false;
  accounts = [];
  public currentAccount;

  constructor(
    private store: Store<AppState>,
    private accountResource: AccountResource,
    private notify: NotifyService,
  ) {}

  loadCurrentAccount() {
    const URLRegexResults = window.location.pathname.match(/\/[A-z-0-9]+/) || [];
    const accountId = (URLRegexResults[0] || '').replace(/\//g, '');

    if (accountId && accountId !== 'auth' && accountId !== 'launchpad' && accountId !== 'partners') {
      return this.accountResource.get(accountId).pipe(
        tap((account) => {
          this.currentAccount = account;
        }),
      );
    }

    return of({});
  }

  queryCall(): Observable<Account[]> {
    return this.accountResource.query({
      sort: 'name',
      direction: 'asc',
      offset: 0,
      limit: 100,
    } as any);
  }

  loadAccounts(): Observable<Account[]> {
    this.accounts = [];
    return this.queryCall().pipe(
      tap((accounts) => {
        this.accounts = accounts;
        this.loaded = true;
        this.store.dispatch(updateAccountsList({ accounts }));
        if (this.accounts.length === 0) {
          this.store.dispatch(openAccountsModal());
        }
      }),
      catchError(() => {
        this.notify.error('Error occurred while loading accounts.');
        return EMPTY;
      }),
    );
  }
}
