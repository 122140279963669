import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ADVANCED_ROLES, Member, STANDARD_ROLES } from '../../members.models';

@Component({
  selector: 'member-edit-roles',
  template: `
    <div class="form-group member-roles">
      <xp-form-group [validationDisabled]="true">
        <xp-input-checkbox
          name="view_role"
          [(ngModel)]="view_role"
          (ngModelChange)="onRolesChange()"
          labelText="View"
          [readOnly]="true"
          matTooltip="View workspaces, packages, clusters, jobs, and schedules"
          matTooltipPosition="below"
          matTooltipClass="below"
        ></xp-input-checkbox>
      </xp-form-group>
      <xp-form-group [validationDisabled]="true">
        <xp-input-checkbox
          name="update_role"
          [(ngModel)]="update_role"
          (ngModelChange)="onRolesChange()"
          labelText="Update"
          [readOnly]="isAdmin"
          matTooltip="Update connections and packages"
          matTooltipPosition="below"
          matTooltipClass="below"
        ></xp-input-checkbox>
      </xp-form-group>
      <xp-form-group [validationDisabled]="true">
        <xp-input-checkbox
          name="operate_role"
          [(ngModel)]="operate_role"
          (ngModelChange)="onRolesChange()"
          labelText="Operate"
          [readOnly]="isAdmin"
          matTooltip="Run jobs, update schedules, and provision clusters"
          matTooltipPosition="below"
          matTooltipClass="below"
        ></xp-input-checkbox>
      </xp-form-group>
      <xp-form-group [validationDisabled]="true">
        <xp-input-checkbox
          name="manage_role"
          [(ngModel)]="manage_role"
          (ngModelChange)="onRolesChange()"
          labelText="Manage"
          [readOnly]="isAdmin"
          matTooltip="Manage account settings"
          matTooltipPosition="below"
          matTooltipClass="below"
        ></xp-input-checkbox>
      </xp-form-group>
    </div>
  `,
})
export class MemberEditRolesComponent implements OnInit {
  @Input() roles: string[] = [];
  @Input() role: string = '';
  @Output() rolesChange = new EventEmitter<ADVANCED_ROLES[]>();
  view_role: boolean = true;
  update_role: boolean = false;
  operate_role: boolean = false;
  manage_role: boolean = false;

  constructor() {}

  ngOnInit() {
    this.update_role = this.isAdmin || this.roles.includes(ADVANCED_ROLES.UPDATE);
    this.operate_role = this.isAdmin || this.roles.includes(ADVANCED_ROLES.OPERATE);
    this.manage_role = this.isAdmin || this.roles.includes(ADVANCED_ROLES.MANAGE);
  }

  onRolesChange() {
    const advanced_roles = [
      ADVANCED_ROLES.VIEW,
      this.update_role ? ADVANCED_ROLES.UPDATE : '',
      this.operate_role ? ADVANCED_ROLES.OPERATE : '',
      this.manage_role ? ADVANCED_ROLES.MANAGE : '',
    ].filter(Boolean) as ADVANCED_ROLES[];

    this.rolesChange.emit(advanced_roles);
  }

  get isAdmin(): boolean {
    return this.role === STANDARD_ROLES.ADMIN;
  }
}
