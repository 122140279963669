import { Component, Input } from '@angular/core';

@Component({
  selector: 'xp-loader',
  template: `
    <div class="loader">
      <div class="loader-inner line-scale-pulse-out">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  `,
})
export class XpLoaderComponent {
  @Input() type: string;
}
