import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { Package } from '../../packages/package.models';
import { PermissionsService } from '../services/permissions.service';
import { AuthorizationGuard } from '../services/authorization.guard';

@Component({
  selector: 'xp-package',
  template: `
    <div class="package-header">
      <div class="package-header-icon">
        <common-icon size="S" iconId="icon-workflow" *ngIf="package.flow_type === 'workflow'"></common-icon>
        <common-icon size="S" iconId="icon-package" *ngIf="package.flow_type === 'dataflow'"></common-icon>
      </div>
      <div class="package-header-info">
        <div class="package-header-name">
          {{ package.name | xpLengthCheck: 50 }}
        </div>
        <div class="package-header-version" *ngxPermissionsOnly="'versionControl'">
          <span class="version" *ngIf="package.paper_trail_package_version && !hideVersion"
            >Version: {{ package.package_version }}</span
          >
          <span
            class="version"
            *ngIf="(!package.paper_trail_package_version || package.paper_trail_package_version === 0) && !hideVersion"
            >Version: Always use latest</span
          >
        </div>
        <div class="package-header-workspace" *ngIf="package.workspace">
          <span class="workspace">Workspace: {{ package.workspace.name }}</span>
        </div>
      </div>
      <div class="package-header-buttons">
        <button type="button" class="btn btn-back btn-sm" (click)="openPackage(package)">
          <i class="fa fa-external-link"></i> open
        </button>
        <button *ngIf="!hideEdit" type="button" class="btn btn-default btn-sm" (click)="editPackage(package)">
          <i class="fa fa-edit"></i> edit
        </button>
        <button type="button" class="btn btn-default btn-sm" (click)="removePackage(package)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  `,
})
export class XpPackageComponent {
  @Input() package: Package;
  @Input() hideEdit: boolean;
  @Input() hideVersion: boolean;
  @Output() remove = new EventEmitter<Package>();
  @Output() edit = new EventEmitter<Package>();

  constructor(
    private permissionsService: PermissionsService,
    private authGuard: AuthorizationGuard,
  ) {}

  isVersionControlDisabled() {
    return this.permissionsService.hasPermission$('versionControl');
  }

  openPackage(packageItem): void {
    this.isVersionControlDisabled()
      .pipe(first())
      .subscribe((hasPermission) => {
        if (
          packageItem.paper_trailpackageItem_version === 0 ||
          packageItem.paper_trailpackageItem_version === undefined ||
          !hasPermission
        ) {
          window.open(`/${this.authGuard.account.account_id}/packages/${packageItem.id}/edit`);
        } else {
          window.open(
            `/${this.authGuard.account.account_id}/packages/${packageItem.id}/versions/${packageItem.paper_trail_package_version}`,
          );
        }
      });
  }

  removePackage(packageItem: Package) {
    this.remove.emit(packageItem);
  }

  editPackage(packageItem: Package) {
    this.edit.emit(packageItem);
  }
}
