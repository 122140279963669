import { SelectOption } from '../common/components/forms/xp-select.component';

export const JOIN_TYPES: SelectOption[] = [
  {
    text: 'join-editor.selects.join_type.options.inner',
    value: 'inner',
    translate: true,
  },
  {
    text: 'join-editor.selects.join_type.options.left',
    value: 'left',
    translate: true,
  },
  {
    text: 'join-editor.selects.join_type.options.right',
    value: 'right',
    translate: true,
  },
  {
    text: 'join-editor.selects.join_type.options.full',
    value: 'full',
    translate: true,
  },
];
