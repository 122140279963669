import { createAction, props } from '@ngrx/store';
import { Account } from '../../../account/account.models';
import { ValidationError } from '../../../config/validation-error.model';
import { ListParams } from '../../../common/helper/query-params-generic-list.helper';

export const openAccountsModal = createAction('[Accounts] Open modal');
export const closeAccountsModal = createAction('[Accounts] Close modal');

export const getAccountsList = createAction(
  '[Accounts] Get accounts list',
  props<{ params: ListParams; getAll?: boolean }>(),
);
export const getAccountsListResponse = createAction(
  '[Accounts] Get accounts list response',
  props<{ accounts: Account[]; limit: number }>(),
);
export const getAccountsListError = createAction(
  '[Accounts] Get accounts list error',
  props<{ errors: ValidationError[] }>(),
);

export const loadMoreAccountsList = createAction('[Accounts] Load more accounts list', props<{ params: ListParams }>());
export const loadMoreAccountsListResponse = createAction(
  '[Accounts] Load more accounts list response',
  props<{ accounts: Account[]; limit: number }>(),
);

export const saveAccount = createAction(
  '[Accounts] Save account',
  props<{ account: Partial<Account>; shouldRedirect?: boolean }>(),
);
export const saveAccountResponse = createAction('[Accounts] Save account response', props<{ data: Account }>());
export const saveAccountError = createAction('[Accounts] Save account error', props<{ errors: ValidationError[] }>());
