import { ValidationsEnum } from '../../services/validations.enum';
import { ValidatorRunner } from './models';

export const AliasValidator: ValidatorRunner = {
  name: 'AliasValidator',
  message: 'errors.AbsenceValidator',
  validate({ value, services }) {
    const validationFunction = services.fieldsCollectionValidationsService.getValidationFunction(
      ValidationsEnum.ALIAS_EDITOR,
    );
    const result = validationFunction(value);

    if (result.message) {
      this.message = result.message;
    }
    return result.valid;
  },
};
