import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { AnalyticsSourceComponentData, Connection, ConnectionSchemaField, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchemaResponse } from '../../../connections/store/connections.actions';
import { SelectOption } from '../../../common/components/forms/xp-select.component';
import { selectPackageId } from '../../store/package-designer.selectors';

const categoryMap = {
  DIMENSION: 'Dimensions',
  METRIC: 'Metrics',
};

const dateRangeOptionsMap = {
  TODAY: {
    min: 'today',
    max: 'today',
  },
  YESTERDAY: {
    min: 'yesterday',
    max: 'yesterday',
  },
  LAST_3_DAYS: {
    min: '3daysAgo',
    max: 'today',
  },
  LAST_7_DAYS: {
    min: '7daysAgo',
    max: 'today',
  },
  LAST_14_DAYS: {
    min: '14daysAgo',
    max: 'today',
  },
  LAST_30_DAYS: {
    min: '30daysAgo',
    max: 'today',
  },
  ALL_TIME: {
    min: '2005-01-01',
    max: 'today',
  },
  CUSTOM_DATE: {
    min: '',
    max: '',
  },
};

@Component({
  selector: 'analytics-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="adwords-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::AnalyticsSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="report_date_range_type">{{
                        'analytics-source-editor.form.labels.date_range' | translate
                      }}</label>
                      <xp-select
                        name="report_date_range_type"
                        id="report_date_range_type"
                        [value]="report_date_range_type"
                        [options]="dateRangeOptions"
                        [preventEmpty]="true"
                        (valueChange)="onDateRangeChange($event)"
                        class="form-control xp-select"
                      ></xp-select>
                    </xp-form-group>
                    <div *ngIf="report_date_range_type === 'CUSTOM_DATE'" class="date-range-container">
                      <date-range
                        [startDate]="rawComponent.report_date_range_min"
                        [endDate]="rawComponent.report_date_range_max"
                        (startDateChange)="onValueChange($event, 'report_date_range_min')"
                        (endDateChange)="onValueChange($event, 'report_date_range_max')"
                      ></date-range>
                      <i
                        class="fa fa-exclamation-circle form-control-info date-range-hint"
                        [matTooltip]="'adwords-source-editor.form.hints.date_range' | translate"
                        matTooltipPosition="after"
                        matTooltipClass="after"
                      ></i>
                    </div>
                    <xp-form-group [validationDisabled]="true" *ngIf="report_date_range_type === 'CUSTOM_DATE'">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.manual_breakdown === 'DayByDay'"
                        (ngModelChange)="onValueChange($event ? 'DayByDay' : 'Default', 'manual_breakdown')"
                        name="manual_breakdown"
                        [hint]="'analytics-source-editor.form.hints.manual_breakdown' | translate"
                        [labelText]="'analytics-source-editor.form.labels.manual_breakdown' | translate"
                      ></xp-input-checkbox>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="segment_type">{{ 'analytics-source-editor.form.labels.segment' | translate }}</label>
                      <xp-input
                        name="Segment"
                        id="Segment"
                        [ngModel]="rawComponent.segment"
                        (ngModelChange)="onValueChange($event, 'segment')"
                        [placeholder]="'analytics-source-editor.form.placeholders.segment_value' | translate"
                        class="form-control"
                      >
                      </xp-input>
                      <small
                        >Read more about segments
                        <a
                          href="https://developers.google.com/analytics/devguides/reporting/core/v3/segments"
                          target="_blank"
                          >here</a
                        >
                      </small>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="filters">{{ 'analytics-source-editor.form.labels.filters' | translate }}</label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="filters"
                        id="filters"
                        [ngModel]="rawComponent.filters"
                        (ngModelChange)="onValueChange($event, 'filters')"
                        [placeholder]="'analytics-source-editor.form.placeholders.filters' | translate"
                      ></xp-input>
                      <small
                        >Read more about filters
                        <a
                          href="https://developers.google.com/analytics/devguides/reporting/core/v3/reference#filters"
                          target="_blank"
                          >here</a
                        >
                      </small>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="sort">{{ 'analytics-source-editor.form.labels.sort' | translate }}</label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="sort"
                        id="sort"
                        [ngModel]="rawComponent.sort"
                        (ngModelChange)="onValueChange($event, 'sort')"
                        [placeholder]="'analytics-source-editor.form.placeholders.sort' | translate"
                      ></xp-input>
                      <small
                        >Read more about sorting
                        <a
                          href="https://developers.google.com/analytics/devguides/reporting/core/v3/reference#sort"
                          target="_blank"
                          >here</a
                        >
                      </small>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="report_sampling_level">{{
                        'analytics-source-editor.form.labels.sampling_level' | translate
                      }}</label>
                      <xp-select
                        class="form-control xp-select"
                        name="report_sampling_level"
                        id="report_sampling_level"
                        [value]="rawComponent.report_sampling_level"
                        [options]="reportSamplingLevelOptions"
                        (valueChange)="onValueChange($event, 'report_sampling_level')"
                      >
                      </xp-select>
                    </xp-form-group>
                    <div>
                      <xp-input-checkbox
                        [ngModel]="rawComponent.include_empty_rows"
                        (ngModelChange)="onValueChange($event, 'include_empty_rows')"
                        name="include_empty_rows"
                        [hint]="'analytics-source-editor.form.hints.include_empty_rows' | translate"
                        [labelText]="'analytics-source-editor.form.labels.include_empty_rows' | translate"
                      ></xp-input-checkbox>
                    </div>
                    <label for="report_on">{{ 'bing-ads-source-editor.form.labels.report_on' | translate }}</label>
                    <div class="form-group-options">
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="all"
                          id="report-on-all"
                        />
                        <label for="report-on-all">{{
                          'analytics-source-editor.form.labels.all_profiles' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="specific"
                          id="report-on-specific"
                        />
                        <label for="report-on-specific">{{
                          'analytics-source-editor.form.labels.specific_profiles' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="custom"
                          id="report-on-custom"
                        />
                        <label for="report-on-custom">{{
                          'analytics-source-editor.form.labels.custom' | translate
                        }}</label>
                      </div>
                    </div>
                    <xp-form-group *ngIf="rawComponent.report_on === 'custom'">
                      <label for="profile_ids">{{
                        'analytics-source-editor.form.labels.profile_ids' | translate
                      }}</label>
                      <xp-chips
                        name="profile_ids"
                        id="profile_ids"
                        [value]="rawComponent.profile_ids"
                        (valueChange)="onValueChange($event, 'profile_ids')"
                        class="form-control xp-select"
                        [placeholder]="'analytics-source-editor.form.placeholders.profile_ids' | translate"
                      ></xp-chips>
                      <i
                        class="fa fa-exclamation-circle form-control-info profile-ids-hint"
                        [matTooltip]="'analytics-source-editor.form.hints.profile_ids' | translate"
                        matTooltipPosition="right"
                        matTooltipClass="right"
                      ></i>
                    </xp-form-group>
                  </div>
                  <div class="col-sm-12" *ngIf="rawComponent.report_on === 'specific'">
                    <accounts-campaigns-selector
                      [component]="component"
                      [rawComponent]="rawComponent"
                    ></accounts-campaigns-selector>
                    <xp-input
                      type="text"
                      [ngModel]="rawComponent.accounts_campaigns"
                      (ngModelChange)="onValueChange($event, 'accounts_campaigns')"
                      name="accounts_campaigns"
                      style="visibility: hidden;"
                    ></xp-input>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            (updateValidation)="updateSchemaValidation($event)"
            [dataPreviewDisabled]="!this.isSchemaValid || !this.isFormValid"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class AnalyticsSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: AnalyticsSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  isSchemaValid = true;
  isFormValid = true;
  isBaseComponentStepActivated = false;
  validationChangeSubscription: Subscription;
  packageIdSubscription: Subscription;
  availableFields: ConnectionSchemaField[] = [];

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  dateRangeOptions: SelectOption[] = [
    {
      text: 'Today',
      value: 'TODAY',
    },
    {
      text: 'Yesterday',
      value: 'YESTERDAY',
    },
    {
      text: 'Last 3 days',
      value: 'LAST_3_DAYS',
    },
    {
      text: 'Last 7 days',
      value: 'LAST_7_DAYS',
    },
    {
      text: 'Last 14 days',
      value: 'LAST_14_DAYS',
    },
    {
      text: 'Last 30 days',
      value: 'LAST_30_DAYS',
    },
    {
      text: 'All time',
      value: 'ALL_TIME',
    },
    {
      text: 'Custom date range...',
      value: 'CUSTOM_DATE',
    },
  ];
  segmentOptions: SelectOption[] = [
    {
      text: 'All Visits',
      value: 'gaid::-1',
    },
    {
      text: 'New Visitors',
      value: 'gaid::-2',
    },
    {
      text: 'Returning Visitors',
      value: 'gaid::-3',
    },
    {
      text: 'Paid Search Traffic',
      value: 'gaid::-4',
    },
    {
      text: 'Non-paid Search Traffic',
      value: 'gaid::-5',
    },
    {
      text: 'Search Traffic',
      value: 'gaid::-6',
    },
    {
      text: 'Direct Traffic',
      value: 'gaid::-7',
    },
    {
      text: 'Referral Traffic',
      value: 'gaid::-8',
    },
    {
      text: 'Visits with Conversions',
      value: 'gaid::-9',
    },
    {
      text: 'Visits with Transactions',
      value: 'gaid::-10',
    },
    {
      text: 'Mobile and Tablet Traffic',
      value: 'gaid::-11',
    },
    {
      text: 'Non-bounce Visits',
      value: 'gaid::-12',
    },
    {
      text: 'Tablet traffic',
      value: 'gaid::-13',
    },
    {
      text: 'Mobile Traffic',
      value: 'gaid::-14',
    },
  ];
  reportSamplingLevelOptions: SelectOption[] = [
    { value: 'DEFAULT', text: 'Default' },
    { value: 'FASTER', text: 'Small' },
    { value: 'HIGHER_PRECISION', text: 'Large' },
  ];
  report_date_range_type = 'TODAY';

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private http: HttpClient,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    this.packageIdSubscription = this.store.select(selectPackageId).subscribe((packageId) => {
      if (this.rawComponent.quota_user !== packageId) {
        this.onValueChange(packageId, 'quota_user');
      }
    });

    this.report_date_range_type =
      Object.keys(dateRangeOptionsMap).find(
        (key) => dateRangeOptionsMap[key].min === this.rawComponent.report_date_range_min,
      ) || 'CUSTOM_DATE';

    this.getFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    const schemaFields = this.rawComponent.schema.fields.filter(
      (field) =>
        ![
          this.rawComponent.account_name_field_alias,
          this.rawComponent.property_name_field_alias,
          this.rawComponent.profile_name_field_alias,
          this.rawComponent.profile_id_field_alias,
        ].includes(field.name),
    );
    this.isSchemaValid = isSchemaValid && schemaFields.length > 0;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    const dimensions = fields
      .filter(
        (item) =>
          item.category === 'Dimensions' ||
          (this.availableFields.find((availableField) => availableField.name === item.name) || {}).category ===
            'Dimensions',
      )
      .map((item) => item.name);
    const metrics = fields
      .filter(
        (item) =>
          item.category === 'Metrics' ||
          (this.availableFields.find((availableField) => availableField.name === item.name) || {}).category ===
            'Metrics',
      )
      .map((item) => item.name);

    const newComponent: Partial<AnalyticsSourceComponentData> = { schema: { ...this.rawComponent.schema, fields } };

    if (this.availableFields.length > 0) {
      newComponent.dimensions = dimensions;
      newComponent.metrics = metrics;
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: newComponent,
      }),
    );
  }

  onDateRangeChange(value: string) {
    const selectedRange = dateRangeOptionsMap[value];
    this.report_date_range_type = value;

    if (value !== 'CUSTOM_DATE') {
      this.onValueChange('Default', 'manual_breakdown');
    }

    this.onValueChange(value, 'report_date_range_type');
    this.onValueChange(selectedRange.min, 'report_date_range_min');
    this.onValueChange(selectedRange.max, 'report_date_range_max');
  }

  getFields() {
    this.http
      .get('https://www.googleapis.com/analytics/v3/metadata/ga/columns?pp=1', {
        headers: {
          Authorization: '',
        },
      })
      .subscribe((data: any) => {
        const availableFields = [];
        data.items.forEach((analyticsItem) => {
          if (analyticsItem.attributes.status === 'PUBLIC') {
            if (
              typeof analyticsItem.attributes.minTemplateIndex === 'undefined' &&
              typeof analyticsItem.attributes.premiumMinTemplateIndex === 'undefined'
            ) {
              availableFields.push({
                name: analyticsItem.id,
                alias: analyticsItem.id.replace(':', '_'),
                data_type: 'string', // analyticsItem.attributes.dataType
                category: categoryMap[analyticsItem.attributes.type],
              });
            } else {
              let index = Number(
                analyticsItem.attributes.premiumMinTemplateIndex || analyticsItem.attributes.minTemplateIndex,
              );
              const end = Number(
                analyticsItem.attributes.premiumMaxTemplateIndex || analyticsItem.attributes.maxTemplateIndex,
              );
              while (index <= end) {
                availableFields.push({
                  name: analyticsItem.id.replace('XX', index),
                  alias: analyticsItem.id.replace('XX', index).replace(':', '_'),
                  data_type: 'string', // analyticsItem.attributes.dataType
                  category: categoryMap[analyticsItem.attributes.type],
                });
                index += 1;
              }
            }
          }
        });

        this.availableFields = availableFields;

        this.store.dispatch(
          getConnectionSchemaResponse({
            connectionSchema: availableFields,
            connectionSchemaData: { data: [], fields: [] },
          }),
        );
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }

    if (this.packageIdSubscription) {
      this.packageIdSubscription.unsubscribe();
    }
  }
}
