import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  getJobsList,
  getJobsListResponse,
  loadMoreJobsList,
  loadMoreJobsListResponse,
  removeJob,
  removeJobError,
  removeJobItem,
  removeJobResponse,
  saveJob,
  saveJobError,
  saveJobResponse,
  searchJobsList,
  searchJobsListResponse,
  updateJob,
  updateJobError,
  updateJobResponse,
  getJobsListError,
  getJobError,
} from './jobs.actions';
import { JobsResource } from '../resources/jobs.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../constants/animation-constants';
import { NotifyService } from '../../common/services/notify.service';
import { getErrorFromResponse } from '../../common/helper/response.helpers';
import { removeHTMLTags } from '../../common/pipes/xp-highlight.pipe';

@Injectable()
export class JobsEffects {
  getJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobsList),
      switchMap(({ params }) =>
        this.jobsResource.query(params).pipe(
          map((jobs) => getJobsListResponse({ jobs })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getJobError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  searchJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchJobsList),
      switchMap(({ params }) =>
        this.jobsResource.search(params).pipe(
          map((jobs) => searchJobsListResponse({ jobs })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getJobsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreJobsList),
      switchMap(({ params }) =>
        (params.q ? this.jobsResource.search(params) : this.jobsResource.query(params)).pipe(
          map((jobs) => loadMoreJobsListResponse({ jobs })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getJobsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveJob),
      switchMap(({ job }) =>
        this.jobsResource.save(job, { include: 'package,cluster' } as any).pipe(
          map((jobResponse) => {
            const message = this.translate.instant('job.notifications.submitted.message', {
              id: jobResponse.id,
              package: removeHTMLTags(jobResponse.package.name),
              cluster: removeHTMLTags(jobResponse.cluster.name),
              creator: removeHTMLTags(jobResponse.creator.display_name),
            });

            this.notify.info(message);

            return saveJobResponse({ data: jobResponse, closeModal: true });
          }),
          catchError((response) => of(saveJobError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  updateJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJob),
      switchMap(({ job, params, jobId }) =>
        this.jobsResource.update(jobId, job, { ...params, include: 'packages' } as any).pipe(
          map((jobResponse) => updateJobResponse({ data: jobResponse })),
          catchError((response) => of(updateJobError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeJob),
      switchMap(({ jobId }) =>
        this.jobsResource.remove(jobId).pipe(
          map((jobResponse) => removeJobResponse({ data: jobResponse })),
          catchError((response) => of(removeJobError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeJobResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeJobItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeJobResponse({ data })),
    ),
  );

  constructor(
    private actions$: Actions,
    private jobsResource: JobsResource,
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}
}
