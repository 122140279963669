import * as VS from '../helpers/validators.helpers';
import { ValidatorRunner } from './models';

const settings = [
  { name: 'minimum', fn: VS.hasMinimumLength },
  { name: 'maximum', fn: VS.hasMaximumLength },
  { name: 'in', fn: VS.hasLengthIn },
  { name: 'within', fn: VS.hasLengthIn },
  { name: 'is', fn: VS.hasExactLength },
];
const validatorMessageKey = 'errors.LengthValidator.';

export const LengthValidator: ValidatorRunner = {
  name: 'LengthValidator',
  message: `${validatorMessageKey}is`,
  order: 0,
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    // common validator options
    if (VS.isTrue(options.allow_nil) && VS.isNull(value)) return true;
    if (VS.isTrue(options.allow_blank) && VS.isEmpty(value)) return true;

    if (options.is) {
      this.message = `${validatorMessageKey}is`;
    }

    if (options.maximum) {
      this.message = `${validatorMessageKey}maximum`;
    }

    if (options.minimum) {
      this.message = `${validatorMessageKey}minimum`;
    }

    if (options.maximum && options.minimum) {
      this.message = `${validatorMessageKey}minimum_and_maximum`;
    }

    if (VS.hasNotLength(value)) return false;
    return settings
      .map(function (o) {
        return VS.isUndefined(options[o.name]) || o.fn.call(null, value, options[o.name]);
      })
      .every(function (result) {
        return result;
      });
  },
};
