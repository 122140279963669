import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsersResource } from '../resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import {PASSWORD_PATTERN} from "../../config/password-policy";

@Component({
  selector: 'members-invitation',
  template: `
    <div class="panel members-invitation">
      <div class="panel-header">
        <h3 class="panel-title">{{ 'members-invitation.title' | translate }}</h3>
      </div>
      <div class="panel-body">
        <xp-form-validation type="User">
          <form role="form" name="invitationForm" #form="ngForm">
            <fieldset>
              <xp-form-group class="form-group">
                <label>{{ 'members-invitation.labels.password' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="password"
                  [placeholder]="'members-invitation.placeholders.password' | translate"
                  name="password"
                  type="password"
                  [pattern]="passwordPattern"
                  autocomplete="off"
                />
              </xp-form-group>
              <xp-password-strength [password]="password"></xp-password-strength>
              <small>{{ 'members-invitation.hints.password' | translate }}</small>
            </fieldset>
            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              [ngClass]="{ disabled: !form.valid }"
              (click)="submit()"
            >
              {{ 'members-invitation.submit_button' | translate }}
            </button>
          </form>
        </xp-form-validation>
      </div>
    </div>
    <help-menu></help-menu>
  `,
})
export class MembersInvitationComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  password = '';
  passwordPattern = PASSWORD_PATTERN;

  constructor(
    private usersResource: UsersResource,
    private notify: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (!this.route.snapshot.queryParams.token) {
      this.router.navigate(['/auth/login']);
    }
  }

  submit() {
    this.usersResource
      .invitation({
        password: this.password,
        invitation_token: this.route.snapshot.queryParams.token,
      })
      .subscribe({
        next: () => {
          this.notify.success('User created successfully! Redirecting to log in');
          setTimeout(() => this.router.navigate(['/auth/login']), 3000);
        },
        error: ({ error }) => {
          const { errors } = error;
          if (errors && errors[0] && errors[0].field === 'invitation_token') {
            this.notify.error(`Token ${errors[0].message}`);
          }
        },
      });
  }
}
