!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.FitAddon = t() : e.FitAddon = t();
}(self, function () {
  return (() => {
    "use strict";

    var e = {};
    return (() => {
      var t = e;
      Object.defineProperty(t, "__esModule", {
        value: !0
      }), t.FitAddon = void 0, t.FitAddon = class {
        constructor() {}
        activate(e) {
          this._terminal = e;
        }
        dispose() {}
        fit() {
          const e = this.proposeDimensions();
          if (!e || !this._terminal || isNaN(e.cols) || isNaN(e.rows)) return;
          const t = this._terminal._core;
          this._terminal.rows === e.rows && this._terminal.cols === e.cols || (t._renderService.clear(), this._terminal.resize(e.cols, e.rows));
        }
        proposeDimensions() {
          if (!this._terminal) return;
          if (!this._terminal.element || !this._terminal.element.parentElement) return;
          const e = this._terminal._core,
            t = e._renderService.dimensions;
          if (0 === t.css.cell.width || 0 === t.css.cell.height) return;
          const r = 0 === this._terminal.options.scrollback ? 0 : e.viewport.scrollBarWidth,
            i = window.getComputedStyle(this._terminal.element.parentElement),
            o = parseInt(i.getPropertyValue("height")),
            s = Math.max(0, parseInt(i.getPropertyValue("width"))),
            n = window.getComputedStyle(this._terminal.element),
            l = o - (parseInt(n.getPropertyValue("padding-top")) + parseInt(n.getPropertyValue("padding-bottom"))),
            a = s - (parseInt(n.getPropertyValue("padding-right")) + parseInt(n.getPropertyValue("padding-left"))) - r;
          return {
            cols: Math.max(2, Math.floor(a / t.css.cell.width)),
            rows: Math.max(1, Math.floor(l / t.css.cell.height))
          };
        }
      };
    })(), e;
  })();
});
