import { Component, Input } from '@angular/core';
import { Job } from '../../jobs.models';

@Component({
  selector: 'job-outputs',
  template: `
    <div class="job-outputs job-content">
      <div class="job-outputs-header" *ngIf="item.outputs.length !== 0">
        <div class="job-outputs-header-name">
          <span>{{ 'job-view.labels.component' | translate }}</span>
        </div>
        <div class="job-outputs-header-records">
          <span>{{ 'job-view.labels.records' | translate }}</span>
        </div>
      </div>
      <job-output-item [output]="output" [job]="item" *ngFor="let output of item.outputs"></job-output-item>
      <div class="job-empty" *ngIf="item.outputs.length === 0">
        {{ 'job-view.outputs.empty-text' | translate }}
      </div>
    </div>
  `,
})
export class JobOutputsComponent {
  @Input() item: Job;
}
