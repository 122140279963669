import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { difference } from 'lodash';
import { AppState } from '../../../store';
import {
  isAnyModalOpen as isAnyModalOpenPackageDesigner,
  selectComponentsFromPackage,
  selectEdgesFromPackage,
} from '../../store/package-designer.selectors';
import { getComponentId, getComponentType, getDataFromComponent } from '../../helpers/components.helpers';
import { ShortcutsService } from '../../services/shortcuts.service';
import {
  clearPackageData,
  copyAction,
  cutAction,
  pasteAction,
  redoAction,
  savePackage,
  selectAllComponents,
  selectComponents,
  undoAction,
  unselectAllComponents,
} from '../../store/package-designer.actions';
import { DESIGNER_SETTINGS } from '../../../constants/designer_settings';
import { Component as PackageComponent } from '../../package.models';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { closeAllModals } from '../../../account/store/account.actions';
import { isAnyModalOpen as isAnyModalOpenClusters } from 'src/app/clusters/store/clusters.selectors';
import { isAnyModalOpen as isAnyModalOpenJobs } from 'src/app/jobs/store/jobs.selectors';
import { isAnyModalOpen as isAnyModalOpenPackages } from 'src/app/packages/store/packages.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'xp-designer',
  template: `
    <div class="designer" #designer>
      <div class="designer-scroll" #designerScroll>
        <div class="designer-stage" (mousedown)="createSelector($event)" (mouseup)="destroySelector()" #designerStage>
          <div class="designer-grid-rect" #designerRect></div>
          <xp-designer-package-item
            *ngFor="let component of components$ | async; trackBy: identifyComponent"
            [componentId]="getComponentId(component)"
          ></xp-designer-package-item>
          <xp-designer-edge
            *ngFor="let edge of edges$ | async; trackBy: identify"
            [edgeId]="edge.id"
          ></xp-designer-edge>
        </div>
      </div>
      <designer-modals></designer-modals>
      <designer-controls></designer-controls>
      <designer-map
        [components]="components$ | async"
        [edges]="edges$ | async"
        [designerPackageHeight]="designerPackageHeight"
        [designerPackageWidth]="designerPackageWidth"
      ></designer-map>
      <errors-viewer></errors-viewer>
    </div>
  `,
})
export class DesignerComponent implements AfterViewInit, OnDestroy {
  components$ = this.store.select(selectComponentsFromPackage);
  edges$ = this.store.select(selectEdgesFromPackage);

  components: PackageComponent[];
  componentsSubscription: Subscription;
  @ViewChild('designerStage') designerStage: ElementRef<HTMLElement>;
  @ViewChild('designerRect') designerRect: ElementRef<HTMLElement>;
  @ViewChild('designerScroll') designerScroll: ElementRef<HTMLElement>;
  @ViewChild('designer') designer: ElementRef<HTMLElement>;
  getComponentId = getComponentId;
  shortcutsSubscriptions: Subscription[] = [];
  selector: HTMLElement;
  selectorStartX = 0;
  selectorStartY = 0;
  previousComponentIds = [];
  designerPackageWidth = 0;
  designerPackageHeight = 0;
  isAnyModalOpen = false;
  isAnyModalOpenSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private shortcutsService: ShortcutsService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngAfterViewInit() {
    if (document.querySelector('.onboarding-toolbar')) {
      this.designerScroll.nativeElement.style.height = '100%';
    }

    this.store.dispatch(closeAllModals());

    this.componentsSubscription = this.components$.subscribe((components) => {
      this.components = components;

      this.setSize();
    });

    this.isAnyModalOpenSubscription = this.store
      .select(isAnyModalOpenPackageDesigner)
      .pipe(
        combineLatestWith(
          this.store.select(isAnyModalOpenClusters),
          this.store.select(isAnyModalOpenJobs),
          this.store.select(isAnyModalOpenPackages),
        ),
        map((isAnyModalOpenFlags) => isAnyModalOpenFlags.reduce((acc, curr) => acc || curr, false)),
      )
      .subscribe((isAnyModalOpen: boolean) => {
        this.isAnyModalOpen = isAnyModalOpen;
      });

    this.shortcutsSubscriptions = [
      this.shortcutsService.addShortcut({ keys: 'meta.z' }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(undoAction());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.shift.z' }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(redoAction());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.a', shouldNotPreventDefault: true }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(selectAllComponents());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'esc' }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(unselectAllComponents());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.shift.a' }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(unselectAllComponents());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.s' }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(savePackage({}));
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.x', shouldNotPreventDefault: true }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(cutAction());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.c', shouldNotPreventDefault: true }).subscribe(() => {
        if (!this.isAnyModalOpen) {
          this.store.dispatch(copyAction());
        }
      }),
      this.shortcutsService.addShortcut({ keys: 'meta.v', shouldNotPreventDefault: true }).subscribe((event) => {
        const isInputPaste =
          (event.target as HTMLElement).tagName === 'INPUT' || (event.target as HTMLElement).tagName === 'TEXTAREA';

        if (isInputPaste || this.isAnyModalOpen) {
          return;
        }

        this.store.dispatch(pasteAction());
      }),
    ];

    this.document.addEventListener('mouseup', this.destroySelector.bind(this));
    window.addEventListener('resize', this.setSize.bind(this));

    const topHeader = document.querySelector('.page-top-header') as HTMLElement;
    const topHeaderHeight = topHeader ? topHeader.offsetHeight : 0;
    this.designer.nativeElement.style.top = topHeaderHeight + 'px';
  }

  setSize() {
    setTimeout(() => {
      let designerRectWidth = 0;
      let designerRectHeight = 0;

      this.components.forEach((component) => {
        const componentData = getDataFromComponent(component);
        const isNote = getComponentType(component) === COMPONENT_TYPE.STICKY_NOTE_COMPONENT;
        const width =
          (isNote ? DESIGNER_SETTINGS.NOTE_WIDTH : DESIGNER_SETTINGS.COMPONENT_WIDTH) +
          DESIGNER_SETTINGS.PADDING +
          componentData.xy[0];
        const height =
          (isNote ? DESIGNER_SETTINGS.NOTE_HEIGHT : DESIGNER_SETTINGS.COMPONENT_HEIGHT) +
          DESIGNER_SETTINGS.PADDING * 2 +
          componentData.xy[1];

        if (designerRectWidth < width) designerRectWidth = width;
        if (designerRectHeight < height) designerRectHeight = height;
      });

      if (designerRectWidth < this.designer.nativeElement.offsetWidth) {
        designerRectWidth = this.designer.nativeElement.offsetWidth;
      }

      if (designerRectHeight < this.designer.nativeElement.offsetHeight) {
        designerRectHeight = this.designer.nativeElement.offsetHeight;
      }

      // Minimap fix -------------
      const designerRatio = this.designer.nativeElement.offsetWidth / this.designer.nativeElement.offsetHeight;
      const packageRatio = designerRectWidth / designerRectHeight;

      if (designerRatio > packageRatio) {
        designerRectWidth = designerRectHeight * designerRatio;
      } else {
        designerRectHeight = designerRectWidth / designerRatio;
      }

      this.designerRect.nativeElement.style.width = `${designerRectWidth}px`;
      this.designerRect.nativeElement.style.height = `${designerRectHeight}px`;
      this.designerPackageWidth = designerRectWidth;
      this.designerPackageHeight = designerRectHeight;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index: number, item: any) {
    return item.id;
  }

  // eslint-disable-next-line class-methods-use-this
  identifyComponent(index: number, item: any) {
    return getComponentId(item);
  }

  createSelector(e: MouseEvent): void {
    const targetClassList = Array.from((e.target as HTMLElement).classList);
    if (targetClassList.includes('cdk-drag-handle') || targetClassList.includes('designer-component')) {
      return;
    }

    this.store.dispatch(selectComponents({ componentIds: [] }));
    this.previousComponentIds = [];

    if (this.selector) {
      this.destroySelector();
    }

    this.selector = document.createElement('div');

    const gridOffset = { left: 50, top: 0 };

    this.selectorStartX = e.clientX - gridOffset.left + this.designerScroll.nativeElement.scrollLeft;
    this.selectorStartY = e.clientY - gridOffset.top + this.designerScroll.nativeElement.scrollTop;

    this.designerStage.nativeElement.appendChild(this.selector);
    this.designerStage.nativeElement.classList.add('selecting');

    this.selector.classList.add('designer-selector-new');

    this.designerStage.nativeElement.addEventListener('mousemove', this.moveSelector.bind(this));
  }

  moveSelector(e: MouseEvent) {
    if (!this.selector) {
      return;
    }

    let width = e.clientX - this.selectorStartX + this.designerScroll.nativeElement.scrollLeft;
    let height = e.clientY - this.selectorStartY + this.designerScroll.nativeElement.scrollTop;

    const x = width >= 50 ? this.selectorStartX : this.selectorStartX + width - 50;
    const y = height >= 0 ? this.selectorStartY : this.selectorStartY + height;

    width = width >= 50 ? width - 50 : Math.abs(50 - width);
    height = Math.abs(height);

    const topHeader = document.querySelector('.page-top-header') as HTMLElement;
    const topHeaderHeight = topHeader ? topHeader.offsetHeight : 0;

    this.selector.style.transform = `translate3d(${x}px, ${y - topHeaderHeight}px, 0)`;
    this.selector.style.width = `${width}px`;
    this.selector.style.height = `${height}px`;

    this.selectComponents({ x, y, width, height });
  }

  destroySelector() {
    if (!this.selector) {
      return;
    }
    this.designerStage.nativeElement.classList.remove('selecting');
    this.designerStage.nativeElement.removeEventListener('mousemove', this.moveSelector.bind(this));
    this.document.removeEventListener('mouseup', this.destroySelector.bind(this));
    window.removeEventListener('resize', this.setSize.bind(this));
    this.designerStage.nativeElement.querySelector('.designer-selector-new').remove();
    this.selector = null;
  }

  selectComponents({ x, y, width, height }) {
    const componentIds = this.components
      .filter((component) => {
        const componentData = getDataFromComponent(component);
        return (
          x + width > componentData.xy[0] &&
          x < componentData.xy[0] + DESIGNER_SETTINGS.COMPONENT_WIDTH &&
          y + height > componentData.xy[1] + DESIGNER_SETTINGS.COMPONENT_HEIGHT &&
          y < componentData.xy[1] + 2 * DESIGNER_SETTINGS.COMPONENT_HEIGHT
        );
      })
      .map(getComponentId);

    if (
      difference(this.previousComponentIds, componentIds).length ||
      difference(componentIds, this.previousComponentIds).length
    ) {
      this.store.dispatch(selectComponents({ componentIds }));
      this.previousComponentIds = componentIds;
    }
  }

  ngOnDestroy() {
    this.store.dispatch(clearPackageData());

    this.shortcutsSubscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

    if (this.componentsSubscription) {
      this.componentsSubscription.unsubscribe();
    }

    if (this.isAnyModalOpenSubscription) {
      this.isAnyModalOpenSubscription.unsubscribe();
    }
    window.onbeforeunload = null;
  }
}
