import { Injectable } from '@angular/core';

interface Settings {
  interval?: number;
  originalTitleInterval?: number;
  duration?: number;
  stopOnFocus?: boolean;
  requireBlur?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TitleAlertService {
  private defaults: Settings = {
    interval: 500,
    originalTitleInterval: null,
    duration: 0,
    stopOnFocus: true,
    requireBlur: false,
  };

  private hasFocus = true;
  private isRunning = false;
  private intervalToken = null;
  private timeoutToken = null;
  private initialText = null;
  private settings = null;

  constructor() {
    // bind focus and blur event handlers
    window.addEventListener('focus', this.focus.bind(this));
    window.addEventListener('blur', this.blur.bind(this));
  }

  notify(text: string, settings: Settings) {
    if (this.isRunning) this.stop();

    // override default settings with specified settings
    this.settings = { ...this.defaults, ...settings };

    // if it's required that the window doesn't have focus, and it has, just return
    if (this.settings.requireBlur && this.hasFocus) return;

    // originalTitleInterval defaults to interval if not set
    this.settings.originalTitleInterval = this.settings.originalTitleInterval || this.settings.interval;

    this.isRunning = true;
    this.initialText = document.title;
    document.title = text;
    let showingAlertTitle = true;
    const switchTitle = () => {
      // WTF! Sometimes Internet Explorer 6 calls the interval function an extra time!
      if (!this.isRunning) return;

      showingAlertTitle = !showingAlertTitle;
      document.title = showingAlertTitle ? text : this.initialText;
      this.intervalToken = setTimeout(
        switchTitle,
        showingAlertTitle ? this.settings.interval : this.settings.originalTitleInterval,
      );
    };
    this.intervalToken = setTimeout(switchTitle, this.settings.interval);

    // check if a duration is specified
    if (this.settings.duration > 0) {
      this.timeoutToken = setTimeout(() => {
        this.stop();
      }, settings.duration);
    }
  }

  stop() {
    if (!this.isRunning) return;

    clearTimeout(this.intervalToken);
    clearTimeout(this.timeoutToken);
    document.title = this.initialText;

    this.timeoutToken = null;
    this.intervalToken = null;
    this.initialText = null;
    this.isRunning = false;
    this.settings = null;
  }

  private focus() {
    this.hasFocus = true;

    if (this.isRunning && this.settings.stopOnFocus) {
      const { initialText } = this;
      this.stop();

      // ugly hack because of a bug in Chrome which causes a change of document.title immediately after tab switch
      // to have no effect on the browser title
      setTimeout(() => {
        if (this.isRunning) return;
        document.title = '.';
        document.title = initialText;
      }, 1000);
    }
  }

  private blur() {
    this.hasFocus = false;
  }
}
