import { createReducer, on } from '@ngrx/store';
import { updatePigFunctions } from './pig.actions';
import { PigFunctions } from '../pig.models';

export interface PigState {
  functions: PigFunctions;
}

export const initialState = { functions: {} };

export const pigReducer = createReducer<PigState>(
  initialState,
  on(updatePigFunctions, (state, { functions }) => ({ ...state, functions: { ...state.functions, ...functions } })),
);
