import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Account } from './account/account.models';
import { AuthorizationGuard } from './common/services/authorization.guard';

@Injectable({ providedIn: 'root' })
export class AccountResolver {
  constructor(private authorizationGuard: AuthorizationGuard) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Account> | Account {
    const { account_id } = route.params;
    const oldAccountId = (this.authorizationGuard.account || {}).account_id;
    if (account_id === oldAccountId) {
      return this.authorizationGuard.account;
    }

    this.authorizationGuard.getAccount(account_id);

    return this.authorizationGuard.account$.pipe(
      filter((account: Account) => account && account.account_id !== oldAccountId),
      first(),
    );
  }
}
