import { ValidatorRunner } from './models';

export const UniquenessValidator: ValidatorRunner = {
  name: 'UniquenessValidator',
  message: 'errors.UniquenessValidator',
  order: 2,
  validate() {
    return true;
  },
};
