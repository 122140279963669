import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../../common/services/permissions.service';
import { Hook } from '../../hook.models';
import { NotifyService } from '../../../common/services/notify.service';
import { ConfirmationDialogService } from '../../../common/services/confirmation-dialog.service';
import { AppState } from '../../../store';
import { ItemType } from '../../../common/components/lists/list-item-snippet.component';
import { pingHook, removeHook } from '../../store/hooks/hooks.actions';
import { loadHookImage } from '../../helpers/load-hook-image.helper';

@Component({
  selector: 'hook-list-item',
  template: `
    <div class="generic-list-item slider-animation" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="hook-list-item" [ngClass]="{ inactive: !item.active }">
        <div class="hook-container">
          <div class="hook-img">
            <img [src]="loadImage(item.type)" [alt]="item.type" />
          </div>
          <div class="hook-body">
            <div class="hook-body-container">
              <strong class="hook-name" (click)="goToHookEdit(item)">{{ item.name | xpLengthCheck : 40 }}</strong>
              <span *ngIf="!item.active" class="inactive"
                >({{ 'hook.generic-list.item.labels.inactive' | translate }})</span
              >
            </div>
            <p class="hook-type">{{ 'hook.types.' + item.type | translate }}</p>
          </div>
        </div>
        <div class="hook-container">
          <xp-loader *ngIf="item.pingLoading"></xp-loader>
          <div class="hook-message" *ngIf="(item.pingSuccess || item.pingError) && !item.pingLoading">
            <div class="alert alert-{{ item.pingSuccess ? 'success' : 'danger' }}" role="alert">
              {{ getPingMessage(item) }}
            </div>
          </div>
          <div class="dropdown xp-dropdown">
            <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
          </div>
          <mat-menu #dropdown="matMenu">
            <li mat-menu-item (click)="goToHookEdit(item)">
              <span class="">{{ 'hook.generic-list.item.actions.edit' | translate }}</span>
            </li>
            <li mat-menu-item (click)="pingHook()">
              <span class="">{{ 'hook.generic-list.item.actions.ping' | translate }}</span>
            </li>
            <li mat-menu-item class="top-separator" *ngxPermissionsOnly="'deleteHook'" (click)="deleteHook(item)">
              <span class="text-danger">{{ 'hook.generic-list.item.actions.delete' | translate }}</span>
            </li>
          </mat-menu>
        </div>
      </div>
    </div>
  `,
})
export class HookListItemComponent {
  @Input() item: Hook;
  @Output() editClick = new EventEmitter<Hook>();
  userId: number;
  loadImage = loadHookImage;

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private notify: NotifyService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
  ) {}

  goToHookEdit(item: Hook): void {
    this.editClick.emit(item);
  }

  deleteHook(item: Hook) {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('hook.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('hook.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('hook.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('hook.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.hook,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeHook({ hookId: item.id }));
      }
    });
  }

  pingHook() {
    this.store.dispatch(pingHook({ hookId: this.item.id }));
  }

  getPingMessage(item: Hook): string {
    if (item.pingSuccess) {
      return this.translate.instant('hook.generic-list.item.labels.success_ping');
    }
    return item.pingError;
  }
}
