import { createAction, props } from '@ngrx/store';

export const updateValidators = createAction('[Validators] Update validators', props<{ validators: any }>());
export const setFormValidity = createAction(
  '[Validators] Set form validity',
  props<{ formName: string; formData: any }>(),
);
export const updateFormValidity = createAction(
  '[Validators] Update form validity',
  props<{ formName: string; formData: any }>(),
);
export const setFormFieldValidity = createAction(
  '[Validators] Set form field validity',
  props<{ formName: string; fieldName: string; isValid: boolean }>(),
);
export const removeForm = createAction('[Validators] Remove form', props<{ formName: string }>());
export const removeField = createAction('[Validators] Remove field', props<{ formName: string; fieldName: string }>());
export const addField = createAction('[Validators] Add field', props<{ formName: string; fieldName: string }>());
export const setFormFieldMessage = createAction(
  '[Validators] Set form field message',
  props<{ formName: string; fieldName: string; message: string }>(),
);

// @TODO add to packageDesigner store
export const setSchemas = createAction('[Validators] Set schemas', props<{ schemas: any[] }>());
