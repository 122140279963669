import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Clipboard from 'clipboard';
import { AnyConnection } from '../connection.models';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { connectionIconUrlByType } from '../../common/helper/connection-icon-url-by-type.helper';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { removeConnection } from '../store/connections.actions';
import { selectConnectionExtendedTypes } from '../store/connections.selectors';
import { NotifyService } from '../../common/services/notify.service';
import { selectAccountPermissions } from '../../account/store/account.selectors';

@Component({
  selector: 'connection-list-item',
  template: `
    <div class="generic-list-item slider-animation connections" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="connection-list-item " id="connection-{{ item.id }}">
        <div class="connection-name">
          <img class="icon" [src]="getConnectionIcon(item.type)" [alt]="item.type" />
          <div>
            <strong>
              <a
                (click)="editConnection(item)"
                [matTooltip]="item.name"
                matTooltipPosition="right"
                matTooltipClass="right wide"
                >{{ item.name }}</a
              >
            </strong>
            <span
              [matTooltip]="'connection.generic-list.item.tooltips.copy-unique-id' | translate"
              matTooltipPosition="above"
              matTooltipClass="above"
              class="connection-key {{ item.unique_id }}"
              [attr.data-clipboard-text]="item.unique_id"
              >{{ item.unique_id }} <i class="fa fa-clipboard" aria-hidden="true"></i
            ></span>
          </div>
        </div>
        <div class="connection-type">
          <span>{{ getConnectionTypeName(item.type) | async }}</span>
        </div>
        <div class="connection-owner">
          <a ui-sref="app.account.settings.account.member(item.owner)">{{ item.owner.display_name }}</a>
        </div>
        <div class="connection-date">
          <span [matTooltip]="item.updated_at | xpDateUTC" matTooltipPosition="above" matTooltipClass="above">{{
            item.updated_at | xpPrettyDate
          }}</span>
        </div>

        <div class="dropdown xp-dropdown-full" *ngxPermissionsOnly="'updateConnection'">
          <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
        </div>
        <mat-menu #dropdown="matMenu">
          <li mat-menu-item (click)="editConnection(item)" *ngxPermissionsOnly="'updateConnection'">
            <span>{{ 'connection.generic-list.item.actions.edit' | translate }}</span>
          </li>
          <li mat-menu-item (click)="removeConnection(item)" *ngxPermissionsOnly="'deleteConnection'">
            <span class="text-danger">{{ 'connection.generic-list.item.actions.delete' | translate }}</span>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class ConnectionListItemComponent implements AfterViewInit, OnDestroy {
  @Input() item: AnyConnection;
  @Output() edit = new EventEmitter<AnyConnection>();

  getConnectionIcon = connectionIconUrlByType;
  extendedConnectionTypes$ = this.store.select(selectConnectionExtendedTypes);
  clipboard: Clipboard = null;
  accountPermissions$ = this.store.select(selectAccountPermissions);

  constructor(
    private translate: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private notify: NotifyService,
  ) {}

  ngAfterViewInit() {
    const copyableElemSelector = `.connection-key.${this.item.unique_id}`;
    this.clipboard = new Clipboard(copyableElemSelector);

    this.clipboard.on('success', this.notifyInfo.bind(this));
  }

  notifyInfo() {
    this.notify.info('Copied!');
  }

  getConnectionTypeName(connectionType: string): Observable<string> {
    return this.extendedConnectionTypes$.pipe(
      map((connections) => (connections[connectionType] ? connections[connectionType].name : '')),
    );
  }

  editConnection(item: AnyConnection) {
    this.accountPermissions$
      .pipe(
        map((permissions) => permissions.includes('updateConnection')),
        first(),
      )
      .subscribe((hasPermission) => {
        if (hasPermission) {
          this.edit.emit(item);
        }
      });
  }

  removeConnection(item: AnyConnection): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('connection.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('connection.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('connection.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('connection.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.connection,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeConnection({ connectionId: item.id, connectionType: item.type }));
      }
    });
  }

  ngOnDestroy() {
    this.clipboard.off('success', this.notifyInfo.bind(this));
  }
}
