import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setComponentValidity } from '../../store/component.actions';
import { AppState } from '../../../store';

@Component({
  selector: 'clone-editor',
  template: `
    <div class="clone-editor">
      <form name="componentForm" novalidate>
        <p class="lead">{{ 'clone-editor.title' | translate }}</p>
      </form>
    </div>
  `,
})
export class CloneEditorComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.dispatch(setComponentValidity({ isComponentFormValid: true }));
  }
}
