import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { WorkspacesResource } from '../../../workspaces/resources/workspaces.resource';
import { SearchConfig } from '../../services/assisted-search/search-config.model';
import { TemplateNameDirective } from '../template-name.directive';
import { ScrollService } from '../../services/scroll.service';
import { NotifyService } from '../../services/notify.service';
import { AuthorizationGuard } from '../../services/authorization.guard';
import { Member } from '../../../settings/members.models';
import { Hook } from '../../../settings/hook.models';
import { Package } from '../../../packages/package.models';
import { AnyConnection } from '../../../connections/connection.models';
import { Job } from '../../../jobs/jobs.models';
import { Schedule } from '../../../schedules/schedule.models';
import { Account } from '../../../account/account.models';
import { Key } from '../../../settings/key.models';
import { Workspace } from '../../../workspaces/workspaces.models';
import { Cluster } from '../../../clusters/clusters.models';
import { map } from 'rxjs/operators';
import { selectAccountPermissions } from '../../../account/store/account.selectors';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import { SearchValue } from './assisted-search/assisted-search.component';

export interface LoadMoreQuery {
  offset: number;
  limit: number;
}

export enum GenericListType {
  members = 'members',
  hooks = 'hooks',
  packages = 'packages',
  connections = 'connections',
  jobs = 'jobs',
  schedules = 'schedules',
  clusters = 'clusters',
  accounts = 'accounts',
  keys = 'keys',
  workspaces = 'workspaces',
}

export type GenericListItem =
  | Member
  | Hook
  | Package
  | AnyConnection
  | Job
  | Schedule
  | Cluster
  | Account
  | Key
  | Workspace;

interface ListTemplates {
  [key: string]: string | TemplateRef<any>;
}

@Component({
  selector: 'generic-list',
  template: `
    <div class="generic-list" [ngClass]="type + '-generic-list'">
      <div class="workspaces-header" *ngIf="isWorkspacePackagesView">
        <i class="fa fa-arrow-left"></i>
        <a (click)="goToWorkspaces()"
          ><span class="workspaces-back">{{ 'workspace.generic-list.item.labels.back' | translate }}</span></a
        >
        <span class="workspace-name">{{ workspaceName }}</span>
      </div>

      <div class="generic-list-header">
        <assisted-search
          *ngIf="hasSearch"
          [configHash]="searchConfig"
          (submit)="onSearchSubmit($event)"
          [inputValue]="searchQueryFromUrl"
          [placeholder]="getSearchPlaceholder()"
        >
          <assisted-search-help [type]="type"></assisted-search-help>
        </assisted-search>

        <div class="generic-list-search invisible" *ngIf="!hasSearch"></div>

        <button
          class="generic-list-new-button btn btn-info btn-lg"
          type="button"
          (click)="createClick.emit()"
          [disabled]="newBtnDisabled"
          *ngIf="hasNewButton && (canCreateNewItem | async)"
        >
          {{ newBtnText | translate }}
        </button>
      </div>

      <div
        *ngIf="!isEmptyList && !isWorkspacePackagesView && !isWorkspacesView && !isClustersView"
        class="generic-list-header-title"
      >
        {{ type }}
      </div>
      <div *ngIf="!isEmptyList && isWorkspacePackagesView" class="generic-list-header-title">
        {{ workspaceName }} packages
      </div>
      <ng-template *ngIf="!isEmptyList" [ngTemplateOutlet]="listTemplates.headers"></ng-template>

      <div *ngIf="items.length" class="generic-list-body folders {{ type }}">
        <ng-template
          *ngFor="let item of items; trackBy: identify"
          [ngTemplateOutlet]="listTemplates.listItem"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-template>
      </div>
      <div *ngIf="isEmptyList">
        <generic-list-empty
          [type]="type"
          [emptyDescription]="emptyDescription"
          [emptyTitle]="emptyTitle"
          [lastAction]="lastAction"
          [search]="search"
        ></generic-list-empty>
      </div>
      <div *ngIf="isError" class="generic-list-error">
        An error occurred while loading data. <br />Please try to refresh the page. <br />If the problem still persists,
        contact our support team.
      </div>
      <div class="generic-list-loader" *ngIf="isLoading">
        <xp-loader></xp-loader>
      </div>
    </div>
  `,
})
export class GenericListComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() type: GenericListType;
  @Input() items: GenericListItem[] = [];
  @Input() isLoading = false;
  @Input() hasNewButton = false;
  @Input() hasSearch = false;
  @Input() newBtnText = '';
  @Input() emptyDescription: string;
  @Input() emptyTitle: string;
  @Input() searchPlaceholder: string;
  @Input() searchConfig: SearchConfig;
  @Input() searchQueryFromUrl: string;
  @Input() limit = 10;
  @Input() allItemsLoaded = false;
  @Input() newBtnDisabled = false;
  @Input() isError = false;
  @Input() offset = 0;
  @Output() createClick = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() searchSubmit = new EventEmitter<SearchValue>();
  @ContentChildren(TemplateNameDirective) templates: QueryList<TemplateNameDirective>;

  isWorkspacesItemView = false;
  workspaceName = '';
  lastAction: 'search' | 'list' = 'list';
  search: 'search' | 'list' = 'list';
  listTemplates: ListTemplates = {};
  genericListTypes = GenericListType;
  scrollSubscription: Subscription;
  accountPermissions$ = this.store.select(selectAccountPermissions);
  typeName: string = '';

  constructor(
    private translate: TranslateService,
    private workspacesResource: WorkspacesResource,
    private router: Router,
    private route: ActivatedRoute,
    public scrollService: ScrollService,
    private notify: NotifyService,
    private authGuard: AuthorizationGuard,
    private store: Store<AppState>,
  ) {}

  ngAfterContentInit() {
    const templatesArray: TemplateNameDirective[] = this.templates.toArray();
    this.listTemplates = templatesArray.reduce(
      (acc, curr) => ({ ...acc, [curr.templateName]: curr.template }),
      {} as ListTemplates,
    );
  }

  ngOnInit() {
    this.isWorkspacesItemView =
      !!this.router.url.match(/\/workspaces\/\w+/) &&
      !this.router.url.match(/\/workspaces\/new/) &&
      !this.router.url.match(/\/workspaces\/\w+\/edit/);

    if (this.isWorkspacesItemView) {
      this.workspacesResource.get(this.route.snapshot.params.workspace_id).subscribe({
        next: (item) => {
          this.workspaceName = item.name;
        },
        error: () => {
          this.notify.error('An error occurred while loading workspace data');
        },
      });
    }

    this.scrollSubscription = this.scrollService.scrollYReachEnd$.subscribe((event) => {
      this.scrollReachEnd(event);
    });

    this.typeName = this.type.slice(0, -1);
    this.typeName = this.typeName.charAt(0).toUpperCase() + this.typeName.substr(1).toLowerCase();
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  get isEmptyList(): boolean {
    return !this.items.length && !this.isLoading && !this.isError;
  }

  get isWorkspacePackagesView(): boolean {
    return this.type === this.genericListTypes.packages && this.isWorkspacesItemView && !this.isLoading;
  }

  get isWorkspacesView(): boolean {
    return this.type === this.genericListTypes.workspaces;
  }

  get isClustersView(): boolean {
    return this.type === this.genericListTypes.clusters;
  }

  goToWorkspaces() {
    const accountId = this.authGuard.account?.account_id;

    this.router.navigate([`/${accountId}/workspaces`]);
  }

  onSearchSubmit({ query, text }) {
    this.lastAction = query ? 'search' : 'list';
    this.search = query;

    this.searchSubmit.emit({ query, text });
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index: number, item: any) {
    return item.id;
  }

  scrollReachEnd(event: Event) {
    if (this.isLoading || this.allItemsLoaded || (event.target as HTMLElement).scrollTop === 0) {
      return;
    }

    this.offset += this.limit;
    this.loadMore.emit({ offset: this.offset, limit: this.limit });
  }

  getSearchPlaceholder() {
    if (!this.workspaceName && this.type === 'packages' && this.isWorkspacesItemView) {
      return '';
    }

    return this.type === 'packages' && this.isWorkspacesItemView
      ? this.translate.instant('package.generic-object.placeholders.search-workspaces', {
          workspace: this.workspaceName,
        })
      : this.searchPlaceholder || this.translate.instant('generic-list.defaults.search_placeholder');
  }

  get canCreateNewItem(): Observable<boolean> {
    return this.accountPermissions$.pipe(
      map(
        (permissions) =>
          permissions.includes(`create${this.typeName}`) ||
          this.typeName === 'Key' ||
          this.typeName === 'Account' ||
          this.typeName === 'Member',
      ),
    );
  }
}
