import { Injectable } from '@angular/core';
import { CustomMiddleClickEvent } from '../helper/global-types.helper';
import { AuthorizationGuard } from './authorization.guard';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ChangeStateService {
  constructor(private authGuard: AuthorizationGuard, private router: Router) {}
  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent.metaKey || mouseEvent.ctrlKey || customEvent.isMiddleClick;

    if (openInNewTab) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/${accountId}/${stateName}`]));

      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${accountId}/${stateName}`]);
    }
  }
}
