import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppState } from '../../store';
import { selectAccountPermissions } from '../../account/store/account.selectors';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private accountPermissions$ = this.store.select(selectAccountPermissions);

  constructor(private store: Store<AppState>) {}

  hasPermission$(permissionName: string): Observable<boolean> {
    return this.accountPermissions$.pipe(map((permissions) => (permissions.includes(permissionName) ? true : null)));
  }
}
