import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from '../../store';
import { selectAccount, selectUser } from '../../account/store/account.selectors';
import { CustomMiddleClickEvent } from '../helper/global-types.helper';
import { ChangeStateService } from '../services/change-state.service';

@Component({
  selector: 'sidebar',
  template: `
    <div id="sidebar" class="sidebar" [ngClass]="{ 'is-boomerang': isBoomerang, open: isOpen }">
      <div class="sidebar-main">
        <ul class="list-unstyled menu-top">
          <li>
            <img class="logo-img" width="48" height="48" src="assets/img/xplenty-logo.svg" alt="Integrate.io" />
          </li>
          <li class="with-border">
            <div class="menu-icon"></div>
            <i class="arrow-icon fa fa-angle-right"></i>
          </li>
          <li [ngClass]="{ active: isStateActive('connections') }" *ngxPermissionsOnly="'listConnections'">
            <a
              (click)="changeState('connections', $event)"
              (auxclick)="changeState('connections', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.connections' | translate"
            >
              <svg class="icon icon-connection">
                <use href="#icon-connection"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.connections' | translate }}</span>
            </a>
          </li>
          <li *ngxPermissionsOnly="'listWorkspaces'" [ngClass]="{ active: isStateActive('workspaces') }">
            <a
              (click)="changeState('workspaces', $event)"
              (auxclick)="changeState('workspaces', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.workspaces' | translate"
            >
              <svg class="icon icons-master-expanded_icon-navmain-workspace">
                <use href="#icon-workspace"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.workspaces' | translate }}</span>
            </a>
          </li>
          <li *ngxPermissionsOnly="'listPackages'" [ngClass]="{ active: isStateActive('packages') }">
            <a
              (click)="changeState('packages', $event)"
              (auxclick)="changeState('packages', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.packages' | translate"
            >
              <svg class="icon icon-package">
                <use href="#icon-package"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.packages' | translate }}</span>
            </a>
          </li>
          <li *ngxPermissionsOnly="'listJobs'" [ngClass]="{ active: isStateActive('jobs') }">
            <a
              (click)="changeState('jobs', $event)"
              (auxclick)="changeState('jobs', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.jobs' | translate"
            >
              <svg class="icon icon-job">
                <use href="#icon-job"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.jobs' | translate }}</span>
            </a>
          </li>
          <li *ngxPermissionsOnly="'listClusters'" [ngClass]="{ active: isStateActive('clusters') }">
            <a
              (click)="changeState('clusters', $event)"
              (auxclick)="changeState('clusters', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.clusters' | translate"
            >
              <svg class="icon icon-cluster">
                <use href="#icon-cluster"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.clusters' | translate }}</span>
            </a>
          </li>
          <li *ngxPermissionsOnly="'listSchedules'" [ngClass]="{ active: isStateActive('schedules') }">
            <a
              (click)="changeState('schedules', $event)"
              (auxclick)="changeState('schedules', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.schedules' | translate"
            >
              <svg class="icon icon-schedules">
                <use href="#icon-schedules"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.schedules' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: isStateActive('settings') }">
            <a
              (click)="changeState('settings/my/profile', $event)"
              (auxclick)="changeState('settings/my/profile', { isMiddleClick: true })"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltip]="'sidebar.menu.items.settings' | translate"
            >
              <svg class="icon icon-settings">
                <use href="#icon-settings"></use>
              </svg>
              <span class="item-desc">{{ 'sidebar.menu.items.settings' | translate }}</span>
            </a>

            <sidebar-sub class="is-mobile" (toggleMenu)="toggleMenu.emit($event)"></sidebar-sub>
          </li>
        </ul>
        <div class="menu-bottom" *ngIf="user$ | async as user">
          <div class="user-name-placeholder"></div>
          <div class="user-name" *ngIf="account$ | async as account">
            <span>{{ user.name }} <span> / </span></span>
            <span>{{ account.name }}</span>
          </div>
          <xp-loader class="user-name-loader" *ngIf="!(account$ | async)"></xp-loader>
          <mat-menu #dropdown="matMenu" yPosition="above" xPosition="after" class="accounts-menu-panel">
            <div mat-menu-item class="accounts-menu-item" [disableRipple]="true">
              <accounts-menu [isVisible]="true"></accounts-menu>
            </div>
          </mat-menu>
          <img
            *ngIf="user.avatar_url"
            class="user-avatar"
            [matMenuTriggerFor]="dropdown"
            [src]="user.avatar_url"
            [alt]="user.name"
          />
          <svg *ngIf="!user.avatar_url" class="user-avatar" [matMenuTriggerFor]="dropdown">
            <use href="#icon-avatar"></use>
          </svg>
        </div>
      </div>
      <sidebar-sub class="is-desktop"></sidebar-sub>
    </div>
  `,
})
export class SidebarComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Output() toggleMenu = new EventEmitter<boolean>();
  isSubWrapperVisible = false;
  isWorkspacesItemActive = false;
  user$ = this.store.select(selectUser);
  account$ = this.store.select(selectAccount);
  isBoomerang = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private changeStateService: ChangeStateService,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setMenuFlags();
    });
  }

  ngOnInit() {
    this.isBoomerang = !!document.getElementById('heroku-boomerang');
    this.setMenuFlags();
  }

  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    this.changeStateService.changeState(stateName, event);
    this.toggleMenu.emit(false);
  }

  isStateActive(stateName: string): boolean {
    return this.router.url.includes(stateName);
  }

  setMenuFlags() {
    this.isWorkspacesItemActive = !!this.router.url.match(/\/workspaces\/\w+/);
    this.isSubWrapperVisible = this.router.url.includes('/settings');
  }
}
