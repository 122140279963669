import './global.events';
import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import moment from 'moment';
import { StoreModule } from '@ngrx/store';
import { Router } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule, TranslateCompiler } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AccountModule } from './account/account.module';
import { ClustersModule } from './clusters/clusters.module';
import { CommonModule } from './common/common.module';
import { ConnectionsModule } from './connections/connections.module';
import { PigModule } from './pig/pig.module';
import { PackagesModule } from './packages/packages.module';
import { CustomHttpInterceptor } from './config/custom-http.interceptor';
import { SettingsModule } from './settings/settings.module';
import { AppComponent } from './app.component';
import { JobsModule } from './jobs/jobs.module';
import { SchedulesModule } from './schedules/schedules.module';
import { WorkspacesModule } from './workspaces/workspaces.module';
import { HelpModule } from './help/help.module';
import { PackageDesignerModule } from './package-designer/package-designer.module';
import { AppRoutingModule } from './app-routing.module';
import { AccountComponent } from './account.component';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';

const calendarCallback = function callback(now) {
  // If we are in current year, do not include it.
  if (now.year() === this.year()) {
    return 'MMM Do [at] LTS';
  }
  return 'MMM Do, YYYY [at] LTS';
};

// eslint-disable-next-line @typescript-eslint/dot-notation
window['DEFAULT_TIMEZONE'] = '+0000';

moment.updateLocale('en', {
  // Override  all times to be 24hr based
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY LT',
    LLLL: 'dddd, MMMM D, YYYY LT',
  },
  calendar: {
    sameDay: '[Today] [at] LTS',
    nextDay: '[Tomorrow] [at] LTS',
    nextWeek: calendarCallback,
    lastDay: '[Yesterday] [at] LTS',
    lastWeek: calendarCallback,
    sameElse: calendarCallback,
  },
});
// Set moment thresholds
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 31);
moment.relativeTimeThreshold('M', 12);

moment.locale('en');

@NgModule({
  declarations: [AppComponent, AccountComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AccountModule,
    PackageDesignerModule,
    ClustersModule,
    CommonModule,
    ConnectionsModule,
    HelpModule,
    JobsModule,
    PackagesModule,
    PigModule,
    SettingsModule,
    SchedulesModule,
    WorkspacesModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    EffectsModule.forRoot(),
    TranslateModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    ...(environment.SENTRY_ENABLED
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
        ]
      : []),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
