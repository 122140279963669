import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { escape } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../../store';
import { ComponentTypeItem, REST_API_RESPONSE_TYPES } from '../../../constants/component_types';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import {
  CloudStorageSourceComponentData,
  RECORD_DELIMITER,
  RECORD_TYPE,
  RestApiSourceComponentData,
  SchemaResponse,
} from '../../package.models';
import {
  selectConnectionSchemaErrors,
  selectConnectionsIsLoadingSchemaDataFlag,
} from '../../../connections/store/connections.selectors';
import { SchemaImporterComponentData } from './schema-importer.component';

interface PreviewType {
  id: PreviewTypes;
  name: string;
  isSelected: boolean;
  isVisible: boolean;
}

enum PreviewTypes {
  raw = 'raw',
  json = 'json',
  table = 'table',
}

const TABLE_FIELDS_COLUMNS_LIMIT = 500;

@Component({
  selector: 'schema-importer-data-preview',
  template: `
    <div class="schema-importer data-preview">
      <div class="alert alert-warning errors-notify" *ngIf="dataPreviewError">
        <button type="button" class="close" (click)="removeDataPreviewError()">
          <span aria-hidden="true">&times;</span>
        </button>
        <span [innerHTML]="dataPreviewError"></span>
      </div>
      <div class="alert alert-warning errors-notify" *ngIf="schemaPreviewError$ | async">
        <span>{{ (schemaPreviewError$ | async).message }}</span>
      </div>
      <div class="data-preview-header">
        <label
          >Data Preview<i
            class="fa fa-exclamation-circle"
            matTooltip="Previews up to the first 500 fields"
            matTooltipPosition="after"
            matTooltipClass="after"
          ></i
        ></label>
        <div class="btn-group btn-group-xs pull-right" *ngIf="data">
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ active: previewType.isSelected, hidden: !previewType.isVisible }"
            (click)="selectPreviewType(previewType.id)"
            *ngFor="let previewType of previewTypes"
          >
            {{ previewType.name }}
          </button>
        </div>
        <button
          class="btn btn-xs btn-default pull-right"
          (click)="refreshDataLoading()"
          [disabled]="(isLoading$ | async) || isPreviewLoading || dataPreviewDisabled"
          *ngIf="component.hasDataPreviewRefreshButton && !isComponentPreviewer"
        >
          <i class="fa fa-refresh" [ngClass]="{ 'fa-spin fa-fw': (isLoading$ | async) || isPreviewLoading }"></i>
          Refresh
        </button>
      </div>
      <div
        class="well"
        *ngIf="
          fields.length === 0 && selectedType === previewTypesDefs.table && !((isLoading$ | async) || isPreviewLoading)
        "
      >
        <span *ngIf="!component.hasDataPreviewRefreshButton || isComponentPreviewer">Empty.</span>
        <span *ngIf="component.hasDataPreviewRefreshButton && !isComponentPreviewer"
          >Select fields and click "Refresh" to preview data.</span
        >
      </div>
      <xp-loader *ngIf="(isLoading$ | async) || isPreviewLoading"></xp-loader>
      <div
        class="data-preview-table"
        *ngIf="
          selectedType === previewTypesDefs.table && fields.length > 0 && !((isLoading$ | async) || isPreviewLoading)
        "
      >
        <div class="data-preview-table-wrapper">
          <table class="table" *ngIf="fields.length > 0">
            <thead>
              <tr>
                <th>Row</th>
                <th *ngFor="let field of fields">{{ field.alias }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items; let index = index">
                <th class="data-preview-table-index">{{ index + 1 }}</th>
                <td *ngFor="let value of item.values">{{ value | showNullValues }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="data-preview-json"
        *ngIf="selectedType === previewTypesDefs.json && !((isLoading$ | async) || isPreviewLoading)"
      >
        <json-explorer [data]="json" [collapsed]="true"></json-explorer>
      </div>
      <div
        class="data-preview-raw"
        *ngIf="selectedType === previewTypesDefs.raw && !((isLoading$ | async) || isPreviewLoading)"
      >
        <pre>{{ raw }}</pre>
      </div>
    </div>
  `,
})
export class SchemaImporterDataPreviewComponent implements OnChanges {
  @Input() options: any;
  @Input() data: SchemaResponse | string;
  @Input() component: ComponentTypeItem;
  @Input() rawComponent: SchemaImporterComponentData;
  @Input() isComponentPreviewer: boolean;
  @Input() isPreviewLoading: boolean;
  @Input() dataPreviewDisabled: boolean;
  @Output() refreshData = new EventEmitter();

  schemaPreviewError$ = this.store.select(selectConnectionSchemaErrors);
  isLoading$ = this.store.select(selectConnectionsIsLoadingSchemaDataFlag);

  previewTypesDefs = PreviewTypes;
  dataPreviewError = '';
  selectedType: PreviewTypes = PreviewTypes.raw;
  previewTypes: PreviewType[] = [
    {
      id: PreviewTypes.table,
      name: 'Table',
      isSelected: false,
      isVisible: false,
    },
    {
      id: PreviewTypes.json,
      name: 'JSON',
      isSelected: false,
      isVisible: false,
    },
    {
      id: PreviewTypes.raw,
      name: 'RAW',
      isSelected: false,
      isVisible: false,
    },
  ];
  fields = [];
  items = [];
  json: any = [];
  raw = '';

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.component?.currentValue) {
      this.initView();
    }
    if (changes.data?.currentValue) {
      this.initData();
    }
  }

  selectPreviewType(type: PreviewTypes) {
    this.previewTypes = this.previewTypes.map((previewType) => ({
      ...previewType,
      isSelected: type === previewType.id,
    }));
    this.selectedType = type;
  }

  setPreviewTypesVisibility(types: PreviewTypes[]) {
    this.previewTypes = this.previewTypes.map((previewType) => ({
      ...previewType,
      isVisible: types.includes(previewType.id),
    }));
  }

  refreshDataLoading() {
    this.dataPreviewError = '';
    this.refreshData.emit();
  }

  removeDataPreviewError() {
    this.dataPreviewError = '';
  }

  clear() {
    this.fields = [];
    this.items = [];
    this.json = [];
    this.raw = '';
  }

  setFields(fields) {
    const newFields = [];
    const fieldsObject = {};
    fields.forEach((field, index) => {
      if (index > TABLE_FIELDS_COLUMNS_LIMIT) return;
      const fieldName = Object.keys(field)[0];
      fieldsObject[fieldName] = field[fieldName];
      newFields.push({
        name: fieldName,
        alias: fieldName,
        data_type: field[fieldName],
      });
    });
    this.fields = newFields;
  }

  setItems(items) {
    const newItems = [];
    items.forEach((item, index) => {
      if (index > TABLE_FIELDS_COLUMNS_LIMIT) return;
      newItems.push({
        values: item.slice(0, TABLE_FIELDS_COLUMNS_LIMIT),
      });
    });
    this.items = newItems;
  }

  setRaw(raw) {
    let newJson: any = [];
    let newRaw = '';

    if (typeof this.data === 'string') {
      this.data = JSON.parse(this.data);
    }

    if (
      this.isComponentPreviewer &&
      this.data &&
      (this.data as SchemaResponse).raw &&
      !(this.data as SchemaResponse).fields
    ) {
      newRaw = raw;
      try {
        newJson = JSON.parse(raw);
      } catch (e) {
        newJson = {};
        if (raw.includes('},{')) {
          newRaw = raw
            .split(',')
            .map((rawItem) => `${rawItem}\n`)
            .join();
        }
      }
    } else if (
      this.component.record_delimiter === RECORD_DELIMITER.EOF &&
      this.component.record_type === RECORD_TYPE.JSON
    ) {
      raw.forEach((rawItem) => {
        newRaw = rawItem;
        try {
          newJson = JSON.parse(rawItem);
        } catch (e) {
          newJson = {};
        }
      });
    } else {
      raw.forEach((rawItem) => {
        newRaw += `${rawItem}\n`;
        let newJsonRaw;
        try {
          newJsonRaw = JSON.parse(rawItem);
        } catch (e) {
          newJsonRaw = {};
        }
        newJson.push(newJsonRaw);
      });
    }
    this.raw = newRaw;
    this.json = newJson;
  }

  setJson(data) {
    const newJson = {};
    data.fields.forEach((field, index) => {
      const key = Object.keys(field)[0];
      if (data.data && data.data[0] && data.data[0].length) {
        newJson[key] = data.data[0][index];
      }
    });
    this.json = newJson;
  }

  buildJson(data) {
    this.clear();
    if (data && data.raw) {
      this.setRaw(data.raw);
      return;
    }
    if (data && data.data) {
      this.setJson(data);
    }
  }

  initData() {
    if (typeof this.data === 'string') {
      // eslint-disable-next-line no-param-reassign
      this.data = JSON.parse(this.data);
    }

    if (
      this.isComponentPreviewer &&
      this.data &&
      (this.data as SchemaResponse).raw &&
      !(this.data as SchemaResponse).fields
    ) {
      this.setPreviewTypesVisibility([PreviewTypes.raw]);
      this.selectPreviewType(PreviewTypes.raw);
    }

    if (
      this.component.componentType === COMPONENT_TYPE.REST_API_SOURCE_COMPONENT &&
      this.component.response_type !== REST_API_RESPONSE_TYPES.line_delimited_data &&
      !this.isComponentPreviewer
    ) {
      if (this.component.response_type === REST_API_RESPONSE_TYPES.raw) {
        // if raw response is bigger than 5MB than throw an error
        if (
          this.data &&
          (this.data as SchemaResponse).data &&
          (this.data as SchemaResponse).data[0] &&
          (this.data as SchemaResponse).data[0][1] &&
          ((this.data as SchemaResponse).data[0][1] as any[]).length > 1024 * 1024 * 5
        ) {
          this.dataPreviewError = escape(this.translate.instant('data-preview.errors.response_too_big'));
          return;
        }
      }
      this.buildJson(this.data);
      return;
    }

    if (this.data) {
      if ((this.data as SchemaResponse).fields) {
        this.setFields((this.data as SchemaResponse).fields);
        this.setItems((this.data as SchemaResponse).data);
      }
      if ((this.data as SchemaResponse).data.length === 0 && (this.data as SchemaResponse).raw) {
        this.selectPreviewType(PreviewTypes.raw);
        this.setRaw((this.data as SchemaResponse).raw);
      }
      if ((this.data as SchemaResponse).raw && (this.data as SchemaResponse).raw.length) {
        this.setRaw((this.data as SchemaResponse).raw);
      }
      this.dataPreviewError = null;
    } else {
      this.clear();
    }
  }

  initView() {
    // eslint-disable-next-line default-case
    const cloudStorageSourceComponent = this.rawComponent as CloudStorageSourceComponentData;
    const restAPISourceComponent = this.rawComponent as RestApiSourceComponentData;
    switch (this.component.componentType) {
      case COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT:
        if (
          cloudStorageSourceComponent.record_type === RECORD_TYPE.EXCEL ||
          cloudStorageSourceComponent.record_type === RECORD_TYPE.PARQUET
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.table]);
          this.selectPreviewType(PreviewTypes.table);
        }

        if (
          cloudStorageSourceComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE &&
          cloudStorageSourceComponent.record_type === RECORD_TYPE.DELIMITED
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.table, PreviewTypes.raw]);
          this.selectPreviewType(PreviewTypes.table);
        }

        if (
          cloudStorageSourceComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE &&
          cloudStorageSourceComponent.record_type === RECORD_TYPE.JSON &&
          !this.isComponentPreviewer
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.json, PreviewTypes.raw]);
          this.selectPreviewType(PreviewTypes.json);
        }

        if (
          cloudStorageSourceComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE &&
          cloudStorageSourceComponent.record_type === RECORD_TYPE.RAW
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.raw]);
          this.selectPreviewType(PreviewTypes.raw);
        }

        if (
          cloudStorageSourceComponent.record_delimiter === RECORD_DELIMITER.EOF &&
          cloudStorageSourceComponent.record_type === RECORD_TYPE.JSON &&
          !this.isComponentPreviewer
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.json, PreviewTypes.raw]);
          this.selectPreviewType(PreviewTypes.json);
        }

        if (
          cloudStorageSourceComponent.record_delimiter === RECORD_DELIMITER.EOF &&
          cloudStorageSourceComponent.record_type === RECORD_TYPE.RAW
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.raw]);
          this.selectPreviewType(PreviewTypes.raw);
        }

        if (this.isComponentPreviewer && cloudStorageSourceComponent.record_type === RECORD_TYPE.JSON) {
          this.setPreviewTypesVisibility([PreviewTypes.table, PreviewTypes.raw]);
          this.selectPreviewType(PreviewTypes.table);
        }
        break;

      case COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT:
        this.setPreviewTypesVisibility([]);
        this.selectPreviewType(PreviewTypes.table);
        break;

      case COMPONENT_TYPE.REST_API_SOURCE_COMPONENT:
        if (
          restAPISourceComponent.response_type === REST_API_RESPONSE_TYPES.line_delimited_data ||
          this.isComponentPreviewer
        ) {
          this.setPreviewTypesVisibility([PreviewTypes.raw, PreviewTypes.table]);
          this.selectPreviewType(PreviewTypes.table);
        } else {
          this.selectPreviewType(PreviewTypes.json);
        }

        break;

      case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
        this.selectPreviewType(PreviewTypes.table);
        break;

      case COMPONENT_TYPE.EXECUTE_SQL_COMPONENT:
        if (this.rawComponent.connection?.type === 'bigquery') {
          this.selectPreviewType(PreviewTypes.raw);
        } else {
          this.selectPreviewType(PreviewTypes.table);
        }
        break;
      case COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT:
      case COMPONENT_TYPE.NET_SUITE_SOURCE_COMPONENT:
      case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
      case COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT:
      case COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT:
      case COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT:
        this.selectPreviewType(PreviewTypes.table);
        break;
    }
  }
}
