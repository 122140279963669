import { Component } from '@angular/core';

@Component({
  selector: 'connection-form-restapi',
  template: `
    <form class="form">
      <div class="row">
        <p>
          Xplenty provides a Rest API component from within the package designer which can be used to call any Rest API
          endpoint.
        </p>
        <p>Please refer to our documentation for more information.</p>
        <ul>
          <li>
            <a target="_blank" href="https://www.integrate.io/docs/etl/using-components-rest-api-source/"
              >Using components: Rest API Source</a
            >
          </li>
          <li>
            <a target="_blank" href="https://www.integrate.io/docs/etl/using-components-rest-api-destination/"
              >Using components: Rest API Destination</a
            >
          </li>
        </ul>
      </div>
    </form>
  `,
})
export class ConnectionFormRestApiComponent {}
