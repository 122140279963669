import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Clipboard from 'clipboard';

import { HttpClient } from '@angular/common/http';
import { NotifyService } from '../services/notify.service';

function isRaw(value: any): boolean {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value === null;
}

function isArray(arr: any): boolean {
  return Array.isArray(arr) || arr instanceof Array;
}
function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}

function parseRaw(k: string, v: string): string {
  let key = '';
  if (k) {
    key = `<span class="property"><span class="property-value">${k}</span> <span class="copy-path">Copy JSONPath</span></span>: `;
  }
  if (typeof v === 'string') {
    const elem = document.createElement('div');
    elem.innerText = v;

    return `${key}<span class="string">"${elem.outerHTML}" </span>`;
  }
  if (typeof v === 'number') {
    return `${key}<span class="num">${v}</span>`;
  }
  if (typeof v === 'boolean') {
    return `${key}<span class="bool">${v}</span>`;
  }
  return `${key}<span class="null">${v}</span>`;
}

function orderByNumber(n1, n2) {
  return n1 - n2;
}
function orderByLength(s1, s2) {
  return s1.length - s2.length;
}

// eslint-disable-next-line no-undef
function parents(node: Node & ParentNode, elementTag: string): HTMLElement[] {
  let current = node;
  const list = [];
  while (current.parentElement != null && current.parentElement !== document.documentElement) {
    if (current.parentElement.matches(elementTag)) {
      list.push(current.parentElement);
    }
    current = current.parentElement;
  }
  return list;
}

@Component({
  selector: 'json-explorer',
  template: ` <div class="angular-json-explorer" [innerHTML]="html"></div> `,
})
export class JsonExplorerComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() jsonData: any;
  @Input() url: string;
  @Input() collapsed: boolean;
  @Input() sortBy: string;

  collapser = '+';
  ellipsis = '';
  contents = 'hidden';
  clipboard: Clipboard;
  html = '';

  constructor(
    private notify: NotifyService,
    private elementRef: ElementRef,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    if (this.collapsed) {
      this.collapser = '-';
      this.ellipsis = 'hidden';
      this.contents = '';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url && changes.url.currentValue) {
      this.http.get(this.url).subscribe((response) => {
        this.parse(response);
      });
    }

    if (changes.data && changes.data.currentValue) {
      this.parse(this.data);
    }

    if (!changes.data && changes.jsonData && changes.jsonData.currentValue) {
      this.parse(this.jsonData);
    }

    if (changes.collapsed && changes.collapsed.currentValue) {
      const collections = this.elementRef.nativeElement.getElementsByTagName('a');
      for (let i = 0; i < collections.length; i += 1) {
        const collectionItem = collections[i];
        const el = collectionItem.parentElement.nextElementSibling;
        const d = collectionItem.parentElement.nextElementSibling.nextElementSibling;

        if (this.collapsed === true) {
          if (collectionItem.innerHTML === '-') {
            el.classList.remove('hidden');
            d.classList.add('hidden');
            collectionItem.innerHTML = '+';
          }
        } else if (collectionItem.innerHTML === '+') {
          el.classList.add('hidden');
          d.classList.remove('hidden');
          collectionItem.innerHTML = '-';
        }
      }
    }
  }

  parseObject(key: string, value: any): string {
    let html = `<span class="property"><a href="#" class="collapser">${this.collapser}</a></span> {`;
    if (key) {
      html = `<span class="property"><a href="#" class="collapser">${this.collapser}</a><span class="property-value">${key}</span> <span class="copy-path">Copy JSONPath</span></span>: {`;
    }
    html += `<span class="ellipsis ${this.ellipsis}">...</span>`;
    html += `<ul class="object collapsible ${this.contents}">`;
    // eslint-disable-next-line no-restricted-syntax
    for (const item in value) {
      if (isRaw(value[item])) {
        html += `<li>${parseRaw(item, value[item])},</li>`;
      } else if (isArray(value[item])) {
        html += `<li>${this.parseArray(item, value[item])},</li>`;
      } else if (isObject(value[item])) {
        html += `<li>${this.parseObject(item, value[item])},</li>`;
      }
    }
    html = html.replace(/,<\/li>$/gim, '</li>');
    html += '</ul>';
    html += '}';
    return html;
  }

  parseArray(key: string, value: any[]): string {
    let html = `<span class="property"><a href="#" class="collapser">${this.collapser}</a></span> [`;
    if (key) {
      html = `<span class="property"><a href="#" class="collapser">${this.collapser}</a> <span class="property-value">${key}</span> <span class="copy-path">Copy JSONPath</span></span>: [`;
    }
    html += `<span class="ellipsis ${this.ellipsis}">...</span>`;
    html += `<ul class="array collapsible ${this.contents}">`;
    for (let i = 0; i < value.length; i += 1) {
      if (isRaw(value[i])) {
        html += `<li>${parseRaw(null, value[i])},</li>`;
      } else if (isArray(value[i])) {
        html += `<li>${this.parseArray(null, value[i])},</li>`;
      } else if (isObject(value[i])) {
        html += `<li>${this.parseObject(null, value[i])},</li>`;
      }
    }
    html = html.replace(/,<\/li>$/gim, '</li>');
    html += '</ul>';
    html += ']';
    return html;
  }

  parse(val: any): string {
    const oldCollections = this.elementRef.nativeElement.getElementsByTagName('a');

    for (let i = 0; i < oldCollections.length; i += 1) {
      const collectionItem = oldCollections[i];
      collectionItem.removeEventListener('click', this.clickListener.bind(this));
    }

    let data;
    if (!isObject(val)) {
      try {
        data = JSON.parse(val);
      } catch (e) {
        data = { error: 'invalid json' };
        return;
      }
    } else {
      data = val;
    }
    if (isArray(data)) {
      if (this.sortBy) {
        data = data.sort((a, b) => {
          const sort = this.sortBy.split(':');
          const field = sort[0];
          const order = sort[1];
          const aField = a[field];
          const bField = b[field];

          let method = null;
          if (typeof aField === 'number' && typeof bField === 'number') {
            method = orderByNumber;
            // eslint-disable-next-line no-prototype-builtins
          } else if (aField.hasOwnProperty('length') && bField.hasOwnProperty('length')) {
            method = orderByLength;
          } else {
            return 0;
          }
          if (order === 'asc') {
            return method(aField, bField);
          }
          if (order === 'desc') {
            return method(bField, aField);
          }
          return method(aField, bField);
        });
      }
    }
    if (isArray(data)) {
      this.html = '[<ul class="array">';
    } else if (isObject(data)) {
      this.html = '{<ul class="object">';
    }
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const item in data) {
      const key = item;
      const value = data[item];
      if (isRaw(value)) {
        this.html += `<li>${parseRaw(key, value)},</li>`;
      } else if (isArray(value)) {
        this.html += `<li>${this.parseArray(key, value)},</li>`;
      } else if (isObject(value)) {
        this.html += `<li>${this.parseObject(key, value)},</li>`;
      }
    }
    this.html = this.html.replace(/,<\/li>$/gim, '</li>');
    if (isArray(data)) {
      this.html += '</ul>]';
    } else if (isObject(data)) {
      this.html += '</ul>}';
    }

    setTimeout(() => {
      const collections = this.elementRef.nativeElement.getElementsByTagName('a');

      for (let i = 0; i < collections.length; i += 1) {
        const collectionItem = collections[i];
        collectionItem.addEventListener('click', this.clickListener.bind(this));
      }
    });

    if (this.clipboard && this.clipboard.destroy) {
      this.clipboard.destroy();
    }

    this.clipboard = new Clipboard('.copy-path', {
      text(trigger: HTMLElement) {
        let path = '';
        parents(trigger.closest('.property'), 'li').forEach(function (element: HTMLElement, index) {
          const $property = element.querySelector('.property');
          const $ul = element.parentElement;
          if ($property) {
            const propertyValueElement = $property.querySelector('.property-value');
            if (propertyValueElement) {
              let propertyValue = propertyValueElement.textContent;
              if ($ul.classList.contains('array')) {
                propertyValue = `[${index}]`;
              }
              path = propertyValue + (!path.length ? path : `.${path}`);
            }
          }
        });
        path = `$.${path}`;
        return path;
      },
    });

    this.clipboard.on('success', (e) => {
      clearTimeout(e.trigger.timer);
      e.trigger.timer = setTimeout(() => {
        this.notify.info('Copied!', e.text);
      }, 500);
      e.clearSelection();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  clickListener(e: MouseEvent) {
    e.preventDefault();
    const element: HTMLElement = e.target as HTMLElement;
    const el = element.parentElement.nextElementSibling;
    const d = element.parentElement.nextElementSibling.nextElementSibling;
    if (element.innerHTML === '+') {
      el.classList.add('hidden');
      d.classList.remove('hidden');
      element.innerHTML = '-';
    } else {
      el.classList.remove('hidden');
      d.classList.add('hidden');
      element.innerHTML = '+';
    }
  }
}
