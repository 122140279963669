import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setComponentValidity } from '../../store/component.actions';
import { AppState } from '../../../store';
import { DistinctComponentData } from '../../package.models';

@Component({
  selector: 'distinct-editor',
  template: `
    <div class="distinct-editor">
      <form>
        <p class="lead">{{ 'distinct-editor.title' | translate }}<br />{{ 'distinct-editor.title_' | translate }}</p>
        <small>{{ 'distinct-editor.hint' | translate }}</small>
      </form>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class DistinctEditorComponent implements OnInit {
  @Input() rawComponent: DistinctComponentData;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.dispatch(setComponentValidity({ isComponentFormValid: true }));
  }
}
