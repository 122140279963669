import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const symbols = {
  command: '&#8984;',
  option: '&#8997;',
  shift: '&#8679;',
  meta: 'fn',
  control: 'ctrl',
  ctrl: 'ctrl',
  alt: 'alt',
  backspace: '&#9224;',
  tab: '&#8677;',
  enter: '&#9166;',
  return: '&#9166;',
  capslock: '&#8682;',
  esc: 'esc',
  escape: 'esc',
  space: '&#9251;',
  pageup: '&#8670;',
  pagedown: '&#8671;',
  end: 'end',
  home: 'home',
  left: '&#8592;',
  up: '&#8593;',
  right: '&#8594;',
  down: '&#8595;',
  ins: 'ins',
  del: '&#9003;',
  plus: '+',
  mod: /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? '&#8984;' : 'ctrl',
};

function packWithinSpan(s: string): string {
  return `<span class="shortcut-symbol">${s.trim()}</span>`;
}

@Pipe({ name: 'shortcutSymbols' })
export class ShortcutSymbolsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: any): string {
    return value
      .map((s) => {
        let a = s;
        Object.keys(symbols).forEach(function (symbol) {
          a = a.replace(symbol, symbols[symbol]);
        });
        a = a
          .split(' ')
          .map(packWithinSpan)
          .join('')
          .split('+')
          .map(function (str) {
            return str.trim();
          })
          .join(' + ');

        return a;
      })
      .join(` ${this.translate.instant('shortcuts.labels.or')} `);
  }
}
