import { Component, ElementRef, OnDestroy, AfterViewInit, ViewChild, NgZone } from '@angular/core';

import { merge, escape } from 'lodash';
import CodeMirror from 'codemirror';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/tern/tern';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import { VariablesService } from '../../jobs/services/variables.service';
import { AppState } from '../../store';
import {
  selectComponentParentSchemas,
  selectExpressionData,
  selectIsExpressionOpenFlag,
  selectPackage,
} from '../../package-designer/store/package-designer.selectors';
import {
  CategoryItem,
  FunctionItem,
  XplentyPigFunctionsService,
} from '../../pig/services/xplenty-pig-functions.service';
import { XplentyPigService } from '../../pig/services/xplenty-pig.service';
import { parser, resetParserMemory } from '../../services/pig-parser';
import { ParserI } from '../../services/pig-parser.interface';
import { closeExpressionEditor } from '../../package-designer/store/package-designer.actions';
import { ExpressionEditorData } from '../../package-designer/store/state.model';
import { CdkDragMove } from '@angular/cdk/drag-drop';

interface TabItem {
  id: string;
  name: string;
  active: boolean;
}

interface PreviewTypeItem {
  id: string;
  name: string;
  selected: boolean;
  active: boolean;
}

@Component({
  selector: 'xp-expression-editor',
  template: `
    <div
      class="expression-editor panel panel-default ui-widget-content"
      cdkDrag
      #resizeBox
      (cdkDragEnded)="moveEnded()"
      style="width: 760px; height: 660px; "
    >
      <span
        #dragHandleRight
        cdkDragLockAxis="x"
        class="dragHandle right"
        cdkDrag
        (cdkDragMoved)="dragMove(dragHandleRight, $event, 'x')"
      ></span>
      <span
        #dragHandleBottom
        cdkDragLockAxis="y"
        class="dragHandle bottom"
        cdkDrag
        (cdkDragMoved)="dragMove(dragHandleBottom, $event, 'y')"
      ></span>
      <span
        #dragHandleCorner
        class="dragHandle corner"
        cdkDrag
        (cdkDragMoved)="dragMove(dragHandleCorner, $event, 'xy')"
      ></span>
      <div class="expression-editor-wrapper">
        <div class="expression-editor-header panel-heading" cdkDragHandle>
          <strong>{{ 'Expression editor' }}</strong>
          <div class="btn-group btn-group-xs pull-right">
            <button
              type="button"
              class="btn btn-default"
              [hidden]="!previewType.active"
              [ngClass]="{ active: previewType.selected }"
              (click)="selectPreviewType(previewType)"
              *ngFor="let previewType of previewTypes"
            >
              {{ previewType.name }}
            </button>
          </div>
        </div>
        <div class="expression-editor-body panel-body">
          <div class="expression-editor-menu">
            <div class="expression-editor-menu-wrapper">
              <div class="expression-editor-menu-search">
                <div class="expression-editor-menu-search-wrapper">
                  <input
                    type="text"
                    [(ngModel)]="search.name"
                    (ngModelChange)="onSearch($event)"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div class="expression-editor-menu-tabs">
                <div
                  class="expression-editor-menu-tab"
                  [hidden]="type === 'console'"
                  [ngClass]="{ active: tab.active }"
                  *ngFor="let tab of tabs"
                  (click)="selectTab(tab)"
                >
                  <span>{{ tab.name }}</span>
                </div>
              </div>
              <div class="expression-editor-categories">
                <div class="expression-editor-category" *ngFor="let category of categories">
                  <strong (click)="category.expanded = !category.expanded" [ngClass]="{ expanded: category.expanded }"
                    ><i
                      class="fa"
                      [ngClass]="{ 'fa-folder-open': category.expanded, 'fa-folder': !category.expanded }"
                    ></i>
                    {{ category.name }}</strong
                  >
                  <div class="expression-editor-category-items" *ngIf="category.expanded">
                    <div
                      class="expression-editor-category-item"
                      *ngFor="let item of category.items"
                      (mouseenter)="showHoverInfo($event)"
                      (mouseleave)="hideHoverInfo()"
                    >
                      <small (click)="addCode(item)" [innerHTML]="highlight(item.name, search.name)"></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="expression-editor-code">
            <form [hidden]="type === 'console'">
              <div class="expression-editor-textarea" #codeMirrorEditor></div>
            </form>
            <xplenty-console
              *ngIf="editor && isConsoleOpen"
              [hidden]="type === 'editor'"
              [hideBar]="true"
              [fullSize]="false"
              [hideShortcutsIcons]="true"
              [hideWelcomeText]="true"
              [inputText]="terminalInput"
              [isFocused]="type === 'console'"
              [isHidden]="type === 'editor'"
              [resizeWindowSubject]="resizeWindowSubject"
            ></xplenty-console>
          </div>
        </div>
        <div class="expression-editor-footer panel-footer clearfix">
          <button class="btn btn-success pull-right" (click)="save()" [disabled]="saveDisabled">Save</button>
          <button class="btn btn-default pull-right" (click)="cancel()">
            {{ 'Cancel' }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class XpExpressionEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('codeMirrorEditor') codeMirrorEditorElement: ElementRef<HTMLTextAreaElement>;
  @ViewChild('resizeBox') resizeBox: ElementRef;
  @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;
  saveDisabled = true;
  editor: CodeMirror.Editor = null;
  isConsoleOpen = false;
  resizeWindowSubject = new Subject<void>();

  search = {
    name: '',
  };
  terminalInput = '';

  tabs: TabItem[] = [
    {
      id: 'function',
      name: 'Functions',
      active: true,
    },
    {
      id: 'variable',
      name: 'Variables',
      active: false,
    },
    {
      id: 'field',
      name: 'Fields',
      active: false,
    },
  ];

  scroll: HTMLElement;
  editorBody: HTMLElement;
  codeElement: HTMLElement;
  gutters: HTMLElement;
  categoriesElement: HTMLElement;
  completionInfo: HTMLElement;
  completionInfoHeader: HTMLElement;
  completionInfoBody: HTMLElement;
  completionInfoExample: HTMLElement;
  overlay: HTMLElement;
  hints: HTMLElement;
  categories: CategoryItem[] = [];
  allCategories: CategoryItem[] = [];
  scrollTop = 0;
  widgets = [];
  availableVariables = {};
  code = '';
  type = 'editor';
  origin = '';
  editorOptions: any = {
    lineNumbers: true,
    lineWrapping: true,
    matchBrackets: true,
    autoCloseBrackets: true,
    indentUnit: 4,
    mode: 'text/x-pig',
    showTips: true,
    showParameters: true,
    fields: [],
    tipsContainer: 'tip',
    hideTipsAfter: 5000,
    autocompleteDelay: 10,
    extraKeys: {
      'Ctrl-Space': 'autocomplete',
    },
  };
  timeoutId: any;

  previewTypes: PreviewTypeItem[] = [
    {
      id: 'editor',
      name: 'Editor',
      selected: true,
      active: true,
    },
    {
      id: 'console',
      name: 'X-Console',
      selected: false,
      active: true,
    },
  ];

  expressionEditorOpenSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private variablesService: VariablesService,
    private store: Store<AppState>,
    private xplentyPigFunctions: XplentyPigFunctionsService,
    private xplentyPig: XplentyPigService,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit() {
    this.scroll = this.elementRef.nativeElement.querySelector('.CodeMirror-scroll');
    this.editorBody = this.elementRef.nativeElement.querySelector('.expression-editor-body');
    this.codeElement = this.elementRef.nativeElement.querySelector('.expression-editor-code');
    this.gutters = this.elementRef.nativeElement.querySelector('.CodeMirror-gutters');
    this.categoriesElement = this.elementRef.nativeElement.querySelector('.expression-editor-categories');
    this.overlay = document.createElement('div');
    this.overlay.classList.add('expression-editor-overlay');

    this.completionInfo = document.createElement('div');
    this.completionInfo.classList.add('expression-editor-completion-info');
    this.completionInfoHeader = document.createElement('div');
    this.completionInfoHeader.classList.add('expression-editor-completion-info-header');
    this.completionInfoBody = document.createElement('div');
    this.completionInfoBody.classList.add('expression-editor-completion-info-body');
    this.completionInfoExample = document.createElement('div');
    this.completionInfoExample.classList.add('expression-editor-completion-info-example');

    this.completionInfo.style.display = 'none';
    document.body.appendChild(this.completionInfo);
    this.completionInfo.appendChild(this.completionInfoHeader);
    this.completionInfo.appendChild(this.completionInfoBody);
    this.completionInfo.appendChild(this.completionInfoExample);

    this.categoriesElement.addEventListener('scroll', (event) => {
      this.scrollTop = (event.target as HTMLElement).scrollTop;
      this.hideCompletionInfo();
    });

    this.categoriesElement.addEventListener('mouseleave', () => {
      this.hideCompletionInfo();
    });

    if (this.expressionEditorOpenSubscription) {
      this.expressionEditorOpenSubscription.unsubscribe();
    }

    this.expressionEditorOpenSubscription = this.store
      .select(selectIsExpressionOpenFlag)
      .pipe(filter(Boolean), withLatestFrom(this.store.select(selectExpressionData)))
      .subscribe(([, data]) => {
        this.editorBody.setAttribute('style', '');

        this.availableVariables = {};

        this.code = (data.code || '').trim();

        this.origin = data.origin;

        this.getPackageVariables(data, (functions: FunctionItem[]) => {
          this.xplentyPigFunctions.load();
          this.xplentyPigFunctions
            .getCategories(functions)
            .pipe(first())
            .subscribe((categories) => {
              this.allCategories = categories
                .sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })
                .map((category) => ({
                  ...category,
                  items: category.items.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  }),
                }));
              this.categories = this.allCategories;

              this.xplentyPig.init(functions);

              this.show();

              if (this.editor) {
                this.editor.setValue(this.code);
                this.editor.refresh();
              } else {
                setTimeout(() => {
                  this.editor = CodeMirror(this.codeMirrorEditorElement.nativeElement, this.editorOptions);

                  if (this.code) {
                    this.editor.setValue(this.code);
                  }

                  this.codemirrorLoaded();
                });
              }
            });
        });
      });

    this.hide();
  }

  selectPreviewType(currentPreviewType: PreviewTypeItem) {
    this.previewTypes = this.previewTypes.map((previewType) => ({
      ...previewType,
      selected: previewType.id === currentPreviewType.id,
    }));

    this.type = currentPreviewType.id;
    if (this.type === 'editor') {
      setTimeout(() => {
        if (this.editor) {
          this.editor.focus();
        }
      });
    } else {
      this.selectTab(this.tabs[0]);
    }
    this.isConsoleOpen = currentPreviewType.id === 'console';

    setTimeout(() => {
      this.setAllHandleTransform(true);
    }, 100);
  }

  getPackageVariables(data: ExpressionEditorData, callback: (functions: FunctionItem[]) => void) {
    let variablesToAdd = {};
    let secretVariablesToAdd = {};

    let currentPackage;

    this.store
      .select(selectPackage)
      .pipe(first())
      .subscribe((package_) => {
        currentPackage = package_ || {};
        variablesToAdd = { ...(currentPackage.variables || {}) };
        secretVariablesToAdd = { ...(currentPackage.secret_variables || {}) };

        const functionItems: FunctionItem[] = [];

        merge(variablesToAdd, data.extraVariables);

        this.variablesService
          .separateVariables(variablesToAdd, {}, null, secretVariablesToAdd)
          .subscribe((variablesTypes) => {
            const addVariablesList = (list, category) => {
              Object.keys(list).forEach((index) => {
                const variable = list[index];
                if (data.category === category && Number(index) >= data.index) return;

                if (data.category === null && variable.key.indexOf('$') !== 0) variable.key = `$${variable.key}`;

                this.availableVariables[variable.key] = true;

                const variableToAdd: any = {
                  itemclass: 'variable',
                  category,
                  type: 'variable',
                  help: {
                    header: `<h3>${variable.key}</h3>`,
                    body: `<p>${variable.help || category}</p>`,
                    example: `Example: ${variable.key}`,
                  },
                };

                if (this.origin === 'fields-collection') {
                  variableToAdd.autocomplete = `$${variable.key}`;
                  variableToAdd.name = `$${variable.key}`;
                  variableToAdd.key = `$${variable.key}`;
                } else {
                  variableToAdd.autocomplete = variable.key;
                  variableToAdd.name = variable.key;
                  variableToAdd.key = variable.key;
                }

                functionItems.push(variableToAdd);
              });
            };

            addVariablesList({ ...variablesTypes.predefinedVariables }, 'Predefined');

            if (
              data.category === 'User' ||
              data.category === 'System' ||
              data.category === 'Workflow' ||
              data.category === null
            ) {
              addVariablesList(variablesTypes.systemVariables, 'System');
            }

            if (
              data.category === 'User' ||
              data.category === 'System' ||
              data.category === 'Workflow' ||
              data.category === null
            ) {
              addVariablesList(variablesTypes.globalVariables, 'Global');
            }

            if (data.category === 'User' || data.category === 'Workflow' || data.category === null) {
              addVariablesList(variablesTypes.userVariables, 'User');
              addVariablesList(variablesTypes.secretVariables, 'Secrets');
            }

            this.store
              .select(selectComponentParentSchemas)
              .pipe(first())
              .subscribe((schemas) => {
                schemas.forEach((schema) => {
                  (schema.fields || []).forEach((field) => {
                    this.availableVariables[field.name] = true;
                    functionItems.push({
                      key: field.name,
                      name: field.name,
                      autocomplete: field.name,
                      itemclass: 'datatype',
                      category: 'Schema fields',
                      type: 'field',
                      help: {
                        header: `<h3>${field.name}</h3>`,
                        body: `<p>Schema field type [${field.data_type}].</p>`,
                        example: `Example: ${field.name}`,
                      },
                    });
                  });
                });

                callback(functionItems);
              });
          });
      });
  }

  codemirrorLoaded() {
    this.editor.on('startCompletion', this.showCompletionInfo.bind(this));
    this.editor.on('keypress', () => {
      this.completionInfo.style.display = 'none';
      this.clearErrors();
    });
    this.editor.on('change', (cm) => {
      const value = cm.getValue();
      this.saveDisabled = value.length === 0;
      this.completionInfo.style.display = 'none';
      this.clearErrors();
      this.code = value;
    });
    setTimeout(() => {
      this.editor.focus();
      this.editor.setCursor(this.editor.lineCount(), this.editor.getLine(this.editor.lineCount() - 1).length);
    });
    this.gutters = this.elementRef.nativeElement.querySelector('.CodeMirror-gutters');
    this.setEditorSize();
  }

  setEditorSize() {
    if (this.editor && this.editor.setSize) {
      this.scroll = this.elementRef.nativeElement.querySelector('.CodeMirror-scroll');
      this.editor.setSize(this.codeElement.offsetWidth, this.codeElement.offsetHeight);
      this.gutters.style.minHeight = `${this.codeElement.offsetHeight}px`;
      this.scroll.style.minHeight = `${this.codeElement.offsetHeight}px`;
      this.scroll.style.maxHeight = `${this.codeElement.offsetHeight}px`;
    }
  }

  selectTab(tab: TabItem) {
    this.tabs = this.tabs.map((item) => ({
      ...item,
      active: item.name === tab.name,
    }));

    this.categories = this.allCategories.filter((category) => category.type === tab.id);
  }

  onSearch(searchValue: string) {
    if (!searchValue) {
      this.categories = this.allCategories;
      return;
    }

    this.categories = this.allCategories
      .map((category) => {
        const items = category.items.filter((item) => {
          const itemNameUpperCased = item.name.toUpperCase();
          const searchNameUpperCased = searchValue.toUpperCase();
          return itemNameUpperCased.indexOf(searchNameUpperCased) > -1;
        });

        return {
          ...category,
          items,
          expanded: items.length > 0,
        };
      })
      .filter((category) => category.items.length > 0);
  }

  // eslint-disable-next-line class-methods-use-this
  highlight(text: string, search: string): string {
    if (!search) {
      return text;
    }
    return text.replace(new RegExp(search, 'gi'), '<span class="highlighted">$&</span>');
  }

  show() {
    document.body.append(this.overlay);
    this.elementRef.nativeElement.classList.remove('closed');
    this.selectPreviewType(this.previewTypes[0]);
    setTimeout(() => {
      this.setAllHandleTransform();
    });
  }

  hide() {
    this.overlay.remove();
    this.elementRef.nativeElement.classList.add('closed');
    this.hideCompletionInfo();
    resetParserMemory();
    this.reset();
    this.codeMirrorEditorElement.nativeElement.innerHTML = '';
    this.editor = null;
  }

  reset() {
    this.selectTab(this.tabs[0]);
    this.search.name = '';
  }

  setCompletionInfo(functionId: string) {
    const xplentyFunction = this.xplentyPigFunctions.get(functionId);
    if (xplentyFunction && functionId.length !== 0) {
      this.completionInfoHeader.innerHTML = xplentyFunction.help.header;
      this.completionInfoBody.innerHTML = xplentyFunction.help.body;
      this.completionInfoExample.innerHTML = xplentyFunction.help.example;
      this.completionInfo.style.display = 'block';
    } else {
      this.completionInfo.style.display = 'none';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getOffsetLeft(elem: HTMLElement) {
    let offsetLeft = 0;
    do {
      if (!Number.isNaN(elem.offsetLeft)) {
        offsetLeft += elem.offsetLeft;
      }
      // eslint-disable-next-line no-cond-assign, no-param-reassign
    } while ((elem = elem.offsetParent as HTMLElement));
    return offsetLeft;
  }

  // eslint-disable-next-line class-methods-use-this
  getOffsetTop(elem: HTMLElement) {
    let offsetTop = 0;
    do {
      if (!Number.isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop;
      }
      // eslint-disable-next-line no-cond-assign, no-param-reassign
    } while ((elem = elem.offsetParent as HTMLElement));
    return offsetTop;
  }

  initActiveHint(e) {
    const code = e.keyCode ? e.keyCode : e.which;
    if (this.hints && (code === 40 || code === 38)) {
      this.setCompletionInfo((this.hints.querySelector('.CodeMirror-hint-active') as HTMLElement).innerText);
    }
  }

  save() {
    this.clearErrors();

    this.code = this.editor.getValue();

    this.selectPreviewType(this.previewTypes[0]);

    setTimeout(() => {
      try {
        (parser as ParserI).parse(this.code);
        const notValidVariables = [];

        let skipNext;
        Array.from(this.codeElement.querySelectorAll('.cm-pig-word')).forEach((span: HTMLElement) => {
          if (skipNext) {
            skipNext = false;
            return;
          }
          let variable;
          const nextToken = (span.nextElementSibling || {}).innerHTML || '';
          // fields that are generated by join components can contain '::' in their name and since code mirror treats ':' as operators and splits the tag in 2, we need to treat 2 adjacent CM tags as 1
          if (nextToken === '::') {
            skipNext = true;
            variable = `${span.innerText}::${span.nextElementSibling.nextElementSibling.innerHTML}`;
          } else {
            variable = span.innerText;
          }
          if (!this.availableVariables[variable]) {
            notValidVariables.push(variable);
          }
        });

        if (notValidVariables.length > 0) {
          const msg = document.createElement('div');
          msg.classList.add('lint-error');

          msg.innerHTML = `<span class="fa fa-exclamation-triangle"></span>Parse error on line 1: \nThe following fields are not available: <strong>${notValidVariables.join(
            ', ',
          )}</strong>`;

          this.widgets.push(
            this.editor.addLineWidget(0, msg, {
              coverGutter: false,
              noHScroll: true,
            }),
          );
        } else {
          this.store.dispatch(closeExpressionEditor({ code: this.code }));
          this.hide();
        }
      } catch (error) {
        const errorMessage = error.toString().split('\n');
        let line = errorMessage[0].match(/\w\sline (\d+)/i) || [];
        line = (Number.parseInt(line[1], 10) || 1) - 1;
        const msg = document.createElement('div');
        msg.classList.add('lint-error');
        const spanEl = document.createElement('span');
        spanEl.classList.add('fa');
        spanEl.classList.add('fa-exclamation-triangle');
        msg.appendChild(spanEl);

        for (let i = 0; i < errorMessage.length; i += 1) {
          const errorMessageElement = document.createElement('span');
          errorMessageElement.innerHTML = escape(errorMessage[i]);
          errorMessageElement.style.marginLeft = i === 0 ? '' : '24px';
          msg.appendChild(errorMessageElement);
          msg.appendChild(document.createElement('br'));
        }
        this.widgets.push(
          this.editor.addLineWidget(line, msg, {
            coverGutter: false,
            noHScroll: true,
          }),
        );
      }
    });
  }

  showCompletionInfo(elem: HTMLElement[]) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      let position: { top: number; left: number };
      let functionId;
      document.body.appendChild(this.completionInfo);
      if (window.CodeMirror && elem instanceof window.CodeMirror) {
        this.hints = document.body.querySelector('.CodeMirror-hints');
        if (!this.hints) {
          return;
        }

        position = { left: this.hints.offsetLeft, top: this.hints.offsetTop };
        position.left += this.hints.offsetWidth - 1;
        functionId = this.hints.querySelector('.CodeMirror-hint-active').innerHTML;
        this.completionInfo.style.left = `${position.left}px`;
        this.completionInfo.style.top = `${position.top}px`;
        document.addEventListener('keydown', this.initActiveHint.bind(this));
      } else {
        const element = elem[0];
        if (!element) {
          return;
        }
        const width = element.offsetWidth;
        position = {
          left: this.getOffsetLeft(element) + width - 25,
          top: this.getOffsetTop(element) - this.scrollTop - 8,
        };
        this.completionInfo.style.left = `${position.left}px`;
        this.completionInfo.style.top = `${position.top}px`;
        functionId = element.innerText;
      }
      this.timeoutId = null;
      this.setCompletionInfo(functionId);
    });
  }

  hideCompletionInfo() {
    document.removeEventListener('keydown', this.initActiveHint.bind(this));

    this.completionInfo.remove();
  }

  showHoverInfo(e) {
    this.showCompletionInfo([e.target as HTMLElement]);
  }

  hideHoverInfo() {
    this.hideCompletionInfo();
  }

  clearErrors() {
    while (this.widgets.length > 0) {
      this.editor.removeLineWidget(this.widgets[0]);
      this.widgets.pop();
    }
  }

  addCode(item: FunctionItem) {
    if (this.type === 'editor') {
      this.editor.replaceSelection(item.autocomplete || item.console);
      this.editor.focus();
    } else {
      this.terminalInput = item.autocomplete || item.console;
    }
  }

  cancel() {
    this.clearErrors();
    this.store.dispatch(closeExpressionEditor({ code: '' }));
    this.hide();
  }

  get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  get dragHandleCornerElement(): HTMLElement {
    return this.dragHandleCorner.nativeElement;
  }

  get dragHandleRightElement(): HTMLElement {
    return this.dragHandleRight.nativeElement;
  }

  get dragHandleBottomElement(): HTMLElement {
    return this.dragHandleBottom.nativeElement;
  }

  moveEnded() {
    setTimeout(() => {
      this.setAllHandleTransform();
    });
  }

  setAllHandleTransform(ignoreResize = false) {
    const rect = this.resizeBoxElement.getBoundingClientRect();
    this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
    this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
    this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');

    if (this.editor) {
      this.editor.setSize(this.codeElement.offsetWidth - 20, this.codeElement.offsetHeight - 20);
    }

    if (!ignoreResize) {
      this.resizeWindowSubject.next();
    }
  }

  setHandleTransform(dragHandle: HTMLElement, targetRect: DOMRect, position: 'x' | 'y' | 'both') {
    const dragRect = dragHandle.getBoundingClientRect();
    const translateX = targetRect.width - dragRect.width;
    const translateY = targetRect.height - dragRect.height;

    if (position === 'x') {
      dragHandle.style.transform = `translate3d(${translateX}px, 0, 0)`;
    }

    if (position === 'y') {
      dragHandle.style.transform = `translate3d(0, ${translateY}px, 0)`;
    }

    if (position === 'both') {
      dragHandle.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
    }
  }

  dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>, axis: string) {
    this.ngZone.runOutsideAngular(() => {
      this.resize(dragHandle, this.resizeBoxElement, axis);
    });
  }

  resize(dragHandle: HTMLElement, target: HTMLElement, axis: string) {
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const width = dragRect.left - targetRect.left + dragRect.width;
    const height = dragRect.top - targetRect.top + dragRect.height;

    if (axis.includes('x')) {
      target.style.width = width + 'px';
    }
    if (axis.includes('y')) {
      target.style.height = height + 'px';
    }

    this.setAllHandleTransform();
  }

  ngOnDestroy() {
    if (this.expressionEditorOpenSubscription) {
      this.expressionEditorOpenSubscription.unsubscribe();
    }
  }
}
