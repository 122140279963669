import { on } from '@ngrx/store';
import { generateLogMessages, getLogsFromResults } from '../helpers/component-previewer.helpers';
import { COMPONENT_PREVIEWER_STATUSES, ComponentPreviewResultData } from '../package.models';
import {
  componentPreviewerLog,
  componentPreviewerDataError,
  componentPreviewerResultError,
  componentPreviewerResultResponse,
  componentPreviewerStatusError,
  postComponentPreviewerData,
  componentPreviewerSetValidation,
  componentPreviewerDataResponse,
} from './component-previewer.actions';
import { PackageDesignerState, ResultData } from './state.model';

function getResultData(resultData: ComponentPreviewResultData): ResultData {
  if (resultData.info && resultData.data) {
    return { raw: resultData.data, data: [] };
  }
  return resultData.error || resultData.info ? undefined : resultData;
}

export const componentPreviewerReducer: any = [
  on(postComponentPreviewerData, (state: PackageDesignerState, { package_id }) => ({
    ...state,
    componentPreviewer:
      package_id !== state.package.id
        ? state.componentPreviewer
        : {
            ...state.componentPreviewer,
            isLoading: true,
            resultData: undefined,
            isInfo: false,
            logs: [],
            progress: 0.1,
          },
  })),
  on(componentPreviewerDataResponse, (state: PackageDesignerState, { preview_id }) => ({
    ...state,
    componentPreviewer: {
      ...state.componentPreviewer,
      preview_id,
    },
  })),
  on(componentPreviewerResultResponse, (state: PackageDesignerState, { data }) => ({
    ...state,
    componentPreviewer: {
      ...state.componentPreviewer,
      isLoading: false,
      isError: !!data.error,
      resultData: getResultData(data),
      isInfo: !!data.info,
      logs: getLogsFromResults(data, state),
    },
  })),
  on(componentPreviewerDataError, (state: PackageDesignerState, { err }) => ({
    ...state,
    componentPreviewer: {
      ...state.componentPreviewer,
      isLoading: false,
      logs: [
        ...state.componentPreviewer.logs,
        { message: err.error.error || err.message, status: COMPONENT_PREVIEWER_STATUSES.failed },
      ],
      isError: true,
    },
  })),
  on(componentPreviewerStatusError, (state: PackageDesignerState, { err }) => ({
    ...state,
    componentPreviewer: {
      ...state.componentPreviewer,
      isLoading: false,
      logs: [
        ...state.componentPreviewer.logs,
        { message: err.error.error || err.message, status: COMPONENT_PREVIEWER_STATUSES.failed },
      ],
      isError: true,
    },
  })),
  on(componentPreviewerResultError, (state: PackageDesignerState, { err }) => ({
    ...state,
    componentPreviewer: {
      ...state.componentPreviewer,
      isLoading: false,
      logs: [
        ...state.componentPreviewer.logs,
        { message: err.error.error || err.message, status: COMPONENT_PREVIEWER_STATUSES.failed },
      ],
      isError: true,
    },
  })),
  on(componentPreviewerLog, (state: PackageDesignerState, { data }) => ({
    ...state,
    componentPreviewer:
      data.id !== state.componentPreviewer.preview_id
        ? state.componentPreviewer
        : {
            ...state.componentPreviewer,
            logs: generateLogMessages(state, data),
            progress: Math.min(Number(data.progress) + 0.1, 1),
            isLoading: ![
              COMPONENT_PREVIEWER_STATUSES.failed,
              COMPONENT_PREVIEWER_STATUSES.completed,
              COMPONENT_PREVIEWER_STATUSES.stopped,
            ].includes(data.status),
            isError: [COMPONENT_PREVIEWER_STATUSES.failed, COMPONENT_PREVIEWER_STATUSES.stopped].includes(data.status),
          },
  })),
  on(componentPreviewerSetValidation, (state: PackageDesignerState, { isValid }) => ({
    ...state,
    componentPreviewer: {
      ...state.componentPreviewer,
      isValid,
    },
  })),
];
