import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DatabaseDefaultConnection } from '../../connection.models';

@Component({
  selector: 'connection-component-host-port',
  template: `
    <div class="connection-component-host-port row">
      <div class="col-sm-9">
        <xp-form-group>
          <label for="host">{{ 'connections.form.labels.hostname' | translate }}</label>
          <xp-input type="text" class="form-control" name="host" id="host" [(ngModel)]="connection.host"> </xp-input>
        </xp-form-group>
      </div>
      <div class="col-sm-3" *ngIf="!hidePort">
        <xp-form-group>
          <label for="port">{{ 'connections.form.labels.port' | translate }}</label>
          <xp-input
            type="number"
            class="form-control"
            name="port"
            id="port"
            [(ngModel)]="connection.port"
            (ngModelChange)="portChange.emit($event)"
          ></xp-input>
        </xp-form-group>
      </div>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentHostPortComponent {
  @Input() connection: Partial<DatabaseDefaultConnection> = {};
  @Input() hidePort = false;
  @Output() portChange = new EventEmitter<number>();
}
