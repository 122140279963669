import { SelectOption } from '../common/components/forms/xp-select.component';

export const OPTIMIZATION_TYPES: SelectOption[] = [
  {
    text: 'join-editor.selects.optimization_type.options.default',
    value: 'default',
    tooltip: 'join-editor.selects.optimization_type.options.default_desc',
    translate: true,
    translateTooltip: true,
  },
  {
    text: 'join-editor.selects.optimization_type.options.replicated',
    value: 'replicated',
    tooltip: 'join-editor.selects.optimization_type.options.replicated_desc',
    translate: true,
    translateTooltip: true,
  },
  {
    text: 'join-editor.selects.optimization_type.options.skewed',
    value: 'skewed',
    tooltip: 'join-editor.selects.optimization_type.options.skewed_desc',
    translate: true,
    translateTooltip: true,
  },
];
