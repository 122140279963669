import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { CUBE_ROLLUP_PICKER } from '../../../../constants/cube_rollup_picker';
import { Schema } from '../../../package.models';

export interface AvailableField {
  text: string;
  value: string;
  record: DesignerSchemaFieldI;
}

@Component({
  selector: 'cube-rollup-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['group_type', 'fields']"
      duplicationValidationProp="fields"
    >
      <ng-template templateName="group_type" let-item>
        <xp-cube-rollup-picker
          [value]="item.record.group_type"
          [index]="item.index"
          (fieldChange)="onFieldChange($event, item.record, 'group_type')"
          class="fields-collection-editor"
        ></xp-cube-rollup-picker>
      </ng-template>
      <ng-template templateName="group_type-header" let-item>
        {{ 'fields-collection.headers.cube-rollup-picker' | translate }}
      </ng-template>

      <ng-template templateName="fields" let-item>
        <xp-ordered-fields-picker
          [value]="item.record.fields"
          [index]="item.index"
          class="fields-collection-editor"
          [fields]="availableFields"
          [schema]="schema"
          [record]="item.record"
          [isOutsideDuplicateError]="item.record.isOutsideDuplicateError"
          [outsideValidationMessage]="((collectionSettings.outsideSourceValidation || {}).unique || {}).message"
          (fieldChange)="onFieldChange($event, item.record, 'fields')"
        ></xp-ordered-fields-picker>
      </ng-template>
      <ng-template templateName="fields-header" let-item>
        {{ 'fields-collection.headers.ordered-fields-picker' | translate }}
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class CubeRollupCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: Partial<CollectionSettings>;
  @Input() parentSchemas: Schema[];
  @Input() fieldsName: string;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  availableFields: AvailableField[] = [];
  fields: DesignerSchemaFieldI[] = [];
  schema: Schema;
  recordsCopy: DesignerSchemaFieldI[] = [];

  defaultCollectionSettings: CollectionSettings = {
    itemsPerPage: 20,
    hideSearch: true,
    emptyRecord: { fields: [], group_type: String(CUBE_ROLLUP_PICKER[0].value), FC_pristine: true },
    addRecord: this.addRecord.bind(this),
  };

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    [this.schema] = this.parentSchemas;
    this.fields = (this.schema || {}).fields || [];
    this.availableFields = this.fields.map(({ name }) => ({
      text: name,
      value: name,
      record: this.recordsCopy.find((record) => record.fields.find((field) => field === name)),
    }));

    this.collectionSettings = {
      ...this.defaultCollectionSettings,
      ...(this.collectionSettings || {}),
      parentSchemas: this.parentSchemas,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.collectionSettings && changes.collectionSettings.currentValue) {
      this.collectionSettings = {
        ...this.defaultCollectionSettings,
        ...(this.collectionSettings || {}),
        parentSchemas: this.parentSchemas,
      };
    }
  }

  addRecord(index: number) {
    const newRecord = { ...this.collectionSettings.emptyRecord, id: uuidv4() };
    if (this.recordsCopy[index - 1]) {
      newRecord.group_type = this.recordsCopy[index - 1].group_type;
    }
    this.recordsCopy.splice(index, 0, newRecord);
    this.recordsCopy = this.recordsCopy.slice();
    this.onRecordChange({ records: this.recordsCopy });
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { fields: record.fields, group_type: record.group_type };
    });
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fieldsName = this.fieldsName || 'fields';
    const cubeFields = records.map(({ fields, group_type }) => ({
      fields,
      group_type,
    }));
    this.store.dispatch(
      updateComponent({
        component: {
          [fieldsName]: cubeFields,
        },
      }),
    );

    this.recordsChange.emit(cubeFields);
  }

  onFieldChange({ value, added, removed }, record, prop) {
    this.availableFields = this.availableFields.map((field) => {
      if (added && added.includes(field.text)) {
        return {
          ...field,
          record,
        };
      }

      if (removed && removed.includes(field.text)) {
        return {
          ...field,
          record: undefined,
        };
      }
      return field;
    });

    this.onRecordChange({
      records: this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            [prop]: value,
          };
        }
        return item;
      }),
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
