export function getMatch(subString, array, key) {
  return array
    .filter(function (string) {
      if (key) {
        // eslint-disable-next-line no-param-reassign
        string = string[key];
      }
      return (
        (string || '').toLowerCase().indexOf((subString || '').toLowerCase()) > -1 && subString.length < string.length
      );
    })
    .sort(function (a, b) {
      return a.length - b.length;
    });
}

// trims insignificant time part from iso datetime string
export function trimTime(isoDateString, force?) {
  if (force || isoDateString.indexOf('T00:00:00Z') === isoDateString.length - 10) {
    return isoDateString.substring(0, isoDateString.length - 10);
  }
  return isoDateString;
}
