import { Component } from '@angular/core';

@Component({
  selector: 'error-401',
  template: `
    <alert-box
      [data]="{
        icon: 'icon-general-401',
        title: 'response.401.title' | translate,
        description: 'response.401.message' | translate
      }"
    ></alert-box>
  `,
})
export class Error401Component {}
