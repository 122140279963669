import { createAction, props } from '@ngrx/store';
import { ValidationError } from '../../config/validation-error.model';
import { Package } from '../package.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

export const openPackagesModal = createAction('[Packages] Open modal');
export const closePackagesModal = createAction('[Packages] Close modal');

export const openPackageVersionsModal = createAction('[Packages] Open versions modal');
export const closePackageVersionsModal = createAction('[Packages] Close versions modal');

export const openPackageWorkspacesModal = createAction('[Packages] Open workspace modal');
export const closePackageWorkspacesModal = createAction('[Packages] Close workspace modal');

export const getPackagesList = createAction('[Packages] Get packages list', props<{ params: ListParams }>());
export const getPackagesListResponse = createAction(
  '[Packages] Get packages list response',
  props<{ packages: Package[] }>(),
);
export const getPackagesListError = createAction(
  '[Packages] Get packages list error',
  props<{ errors: ValidationError[] }>(),
);

export const searchPackagesList = createAction('[Packages] Search packages list', props<{ params: ListParams }>());
export const searchPackagesListResponse = createAction(
  '[Packages] Search packages list response',
  props<{ packages: Package[] }>(),
);

export const loadMorePackagesList = createAction('[Packages] Load more packages list', props<{ params: ListParams }>());
export const loadMorePackagesListResponse = createAction(
  '[Packages] Load more packages list response',
  props<{ packages: Package[] }>(),
);

export const savePackage = createAction(
  '[Packages] Save package',
  props<{ packageItem: Partial<Package>; redirect?: boolean }>(),
);
export const savePackageResponse = createAction(
  '[Packages] Save package response',
  props<{ data: Package; closeModal?: boolean }>(),
);
export const savePackageError = createAction('[Packages] Save package error', props<{ errors: ValidationError[] }>());

export const duplicatePackage = createAction(
  '[Packages] Duplicate package',
  props<{ packageId: number; packageName: string }>(),
);
export const duplicatePackageResponse = createAction(
  '[Packages] Duplicate package response',
  props<{ data: Package }>(),
);
export const duplicatePackageError = createAction(
  '[Packages] Duplicate package error',
  props<{ errors: ValidationError[] }>(),
);

export const rollbackPackage = createAction(
  '[Packages] Rollback package',
  props<{ packageId: number; versionId: number }>(),
);
export const rollbackPackageResponse = createAction('[Packages] Rollback package response', props<{ data: Package }>());
export const rollbackPackageError = createAction(
  '[Packages] Rollback package error',
  props<{ errors: ValidationError[] }>(),
);

export const updatePackage = createAction(
  '[Packages] Update package',
  props<{ packageItem: Partial<Package>; params: ListParams; packageId: number }>(),
);
export const updatePackageResponse = createAction(
  '[Packages] Update package response',
  props<{ data: Package; closeModal?: boolean }>(),
);
export const updatePackageError = createAction(
  '[Packages] Update package error',
  props<{ errors: ValidationError[] }>(),
);

export const removePackage = createAction('[Packages] Remove package', props<{ packageId: number }>());
export const removePackageItem = createAction('[Packages] Remove package item', props<{ data: Package }>());
export const removePackageResponse = createAction('[Packages] Remove package response', props<{ data: Package }>());
export const removePackageError = createAction(
  '[Packages] Remove package error',
  props<{ errors: ValidationError[] }>(),
);
