import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Job } from '../../jobs.models';

enum TabLabels {
  Variables = 'Variables',
  Outputs = 'Outputs',
  Errors = 'Errors',
  Tasks = 'Tasks',
}

@Component({
  selector: 'job-view',
  template: `
    <div class="job-view">
      <div class="job-view-body">
        <xp-loader *ngIf="!isLoaded"></xp-loader>
        <mat-tab-group
          animationDuration="0ms"
          dynamicHeight
          (selectedTabChange)="onTabChange($event)"
          [selectedIndex]="selectedTabIndex"
        >
          <mat-tab [label]="tabLabels.Variables"><job-variables [item]="item"></job-variables></mat-tab>
          <mat-tab *ngIf="areOutputsAvailable && !isWorkflow" [label]="tabLabels.Outputs"
            ><job-outputs [item]="item"></job-outputs
          ></mat-tab>
          <mat-tab *ngIf="isWorkflow" [label]="tabLabels.Tasks"
            ><job-tasks
              (childOpen)="childOpen.emit($event)"
              [childJobs]="childJobs"
              [isLoaded]="isLoaded"
              [isLoadingChildren]="isLoadingChildren"
              [item]="item"
            ></job-tasks
          ></mat-tab>
          <mat-tab *ngIf="areErrorsAvailable" [label]="tabLabels.Errors"
            ><job-errors [item]="item"></job-errors
          ></mat-tab>
        </mat-tab-group>
      </div>
      <div class="job-view-footer">
        <div class="job-list-item-job">
          <div class="job">{{ 'generic-list.jobs.id' | translate }}</div>
          <div class="package">{{ 'generic-list.jobs.package' | translate }}</div>
          <div class="cluster">{{ 'generic-list.jobs.cluster' | translate }}</div>
          <div class="runtime">{{ 'generic-list.jobs.runtime' | translate }}</div>
          <div class="status">{{ 'generic-list.jobs.status' | translate }}</div>
          <div class="details"></div>
          <div class="dropdown"></div>
        </div>
        <job-list-item [item]="item" [hideDropDown]="true"></job-list-item>
      </div>
    </div>
  `,
})
export class JobViewComponent implements OnInit {
  @Input() item: Job;
  @Input() isLoaded: boolean;
  @Input() childJobs: Job[];
  @Input() isLoadingChildren: boolean;
  @Output() childOpen = new EventEmitter<Job>();
  @Output() loadMoreTasks = new EventEmitter();

  tabLabels = TabLabels;
  selectedTabIndex = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  get areOutputsAvailable(): boolean {
    return this.item.outputs && this.item.outputs.length > 0;
  }

  get areErrorsAvailable(): boolean {
    return this.item.status && (this.item.status === 'failed' || !!this.item.errors);
  }

  get isWorkflow(): boolean {
    return this.item.package.flow_type === 'workflow';
  }

  get childUrl(): string {
    return `${this.route.firstChild.snapshot.params.job_id}/children/${this.route.firstChild.firstChild.snapshot.params.child_id}/`;
  }

  ngOnInit() {
    if (this.areOutputsAvailable) {
      if (this.router.url.includes('/children')) {
        this.router.navigate([`${this.childUrl}outputs`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${this.item.id}/outputs`], { relativeTo: this.route });
      }
    }

    if (this.areErrorsAvailable) {
      if (this.router.url.includes('/children')) {
        this.router.navigate([`${this.childUrl}errors`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${this.item.id}/errors`], { relativeTo: this.route });
      }
      if (this.isWorkflow) {
        this.selectedTabIndex = 2;
      }
    }

    if (!this.areErrorsAvailable && this.isWorkflow) {
      if (!this.router.url.includes('/children')) {
        this.router.navigate([`${this.item.id}/tasks`], { relativeTo: this.route });
      }
    }
  }

  onTabChange(event: MatTabChangeEvent): void {
    const tabName = event.tab.textLabel;

    if (tabName === TabLabels.Variables) {
      if (this.router.url.includes('/children')) {
        this.router.navigate([`${this.childUrl}variables`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${this.item.id}/variables`], { relativeTo: this.route });
      }
    }

    if (tabName === TabLabels.Outputs) {
      if (this.router.url.includes('/children')) {
        this.router.navigate([`${this.childUrl}outputs`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${this.item.id}/outputs`], { relativeTo: this.route });
      }
    }

    if (tabName === TabLabels.Errors) {
      if (this.router.url.includes('/children')) {
        this.router.navigate([`${this.childUrl}errors`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${this.item.id}/errors`], { relativeTo: this.route });
      }
    }

    if (tabName === TabLabels.Tasks) {
      if (this.router.url.includes('/children')) {
        this.router.navigate([`${this.childUrl}tasks`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${this.item.id}/tasks`], { relativeTo: this.route });
      }
    }
  }
}
