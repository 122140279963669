import { createAction, props } from '@ngrx/store';
import { ValidationError } from '../../config/validation-error.model';
import { Schedule } from '../schedule.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

export const openSchedulesModal = createAction('[Schedules] Open modal');
export const closeSchedulesModal = createAction('[Schedules] Close modal');

export const openPackagePickerModal = createAction(
  '[Schedules] Open package picker modal',
  props<{ isEdit: boolean }>(),
);
export const closePackagePickerModal = createAction('[Schedules] Close package picker modal');

export const getSchedulesList = createAction('[Schedules] Get schedules list', props<{ params: ListParams }>());
export const getSchedulesListResponse = createAction(
  '[Schedules] Get schedules list response',
  props<{ schedules: Schedule[] }>(),
);
export const getSchedulesListError = createAction(
  '[Schedules] Get schedules list error',
  props<{ errors: ValidationError[] }>(),
);

export const searchSchedulesList = createAction('[Schedules] Search schedules list', props<{ params: ListParams }>());
export const searchSchedulesListResponse = createAction(
  '[Schedules] Search schedules list response',
  props<{ schedules: Schedule[] }>(),
);

export const loadMoreSchedulesList = createAction(
  '[Schedules] Load more schedules list',
  props<{ params: ListParams }>(),
);
export const loadMoreSchedulesListResponse = createAction(
  '[Schedules] Load more schedules list response',
  props<{ schedules: Schedule[] }>(),
);

export const saveSchedule = createAction('[Schedules] Save schedule', props<{ schedule: Partial<Schedule> }>());
export const duplicateSchedule = createAction(
  '[Schedules] Duplicate schedule',
  props<{ schedule: Partial<Schedule> }>(),
);
export const saveScheduleResponse = createAction(
  '[Schedules] Save schedule response',
  props<{ data: Schedule; closeModal?: boolean }>(),
);
export const saveScheduleError = createAction(
  '[Schedules] Save schedule error',
  props<{ errors: ValidationError[] }>(),
);

export const runSchedule = createAction('[Schedules] Run schedule', props<{ schedule: Partial<Schedule> }>());

export const updateSchedule = createAction(
  '[Schedules] Update schedule',
  props<{ schedule: Partial<Schedule>; params: ListParams; scheduleId: number }>(),
);
export const updateScheduleResponse = createAction(
  '[Schedules] Update schedule response',
  props<{ data: Schedule; closeModal?: boolean }>(),
);
export const updateScheduleError = createAction(
  '[Schedules] Update schedule error',
  props<{ errors: ValidationError[] }>(),
);

export const removeSchedule = createAction('[Schedules] Remove schedule', props<{ scheduleId: number }>());
export const removeScheduleItem = createAction('[Schedules] Remove schedule item', props<{ data: Schedule }>());
export const removeScheduleResponse = createAction('[Schedules] Remove schedule response', props<{ data: Schedule }>());
export const removeScheduleError = createAction(
  '[Schedules] Remove schedule error',
  props<{ errors: ValidationError[] }>(),
);
