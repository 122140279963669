import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { combineLatestWith, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Edge, Component as PackageComponent } from '../../package.models';
import { getComponentId } from '../../helpers/components.helpers';
import { DESIGNER_SETTINGS } from '../../../constants/designer_settings';
import { AppState } from '../../../store';
import { autoAlign } from '../../store/package-designer.actions';
import { ShortcutsService } from '../../services/shortcuts.service';
import { isAnyModalOpen as isAnyModalOpenPackageDesigner } from '../../store/package-designer.selectors';
import { isAnyModalOpen as isAnyModalOpenClusters } from '../../../clusters/store/clusters.selectors';
import { isAnyModalOpen as isAnyModalOpenJobs } from '../../../jobs/store/jobs.selectors';
import { isAnyModalOpen as isAnyModalOpenPackages } from '../../../packages/store/packages.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'designer-map',
  template: `
    <div class="designer-map-new" #designerMap>
      <div class="designer-map-wrapper" #designerMapWrapper>
        <div
          class="designer-map-viewport-new"
          #viewport
          cdkDrag
          cdkDragBoundary=".designer-map-wrapper"
          (cdkDragStarted)="onDragStarted()"
          (cdkDragMoved)="onDragMoved($event)"
          (cdkDragEnded)="onDragEnded($event)"
        ></div>
        <svg id="svg-map" class="designer-map-svg" version="1.1" #svgMap>
          <g class="designer-map-stage">
            <line
              designer-map-edge
              class="designer-map-edge"
              *ngFor="let edge of edges; trackBy: identify"
              [edgeId]="edge.id"
              [scale]="scale"
            ></line>
            <rect
              designer-map-component
              [ngClass]="getComponentId(component).includes('note') ? 'designer-map-note' : 'designer-map-component'"
              *ngFor="let component of components; trackBy: identifyComponent"
              [componentId]="getComponentId(component)"
              [scale]="scale"
            ></rect>
          </g>
          <rect class="designer-map-overlay designer-map-overlay-top" #overlayTop></rect>
          <rect class="designer-map-overlay designer-map-overlay-right" #overlayRight></rect>
          <rect class="designer-map-overlay designer-map-overlay-bottom" #overlayBottom></rect>
          <rect class="designer-map-overlay designer-map-overlay-left" #overlayLeft></rect>
        </svg>
        <div class="designer-map-buttons">
          <button
            id="button-align"
            matTooltipPosition="left"
            matTooltipClass="left"
            [matTooltip]="'designer.controls.actions.auto_align' | translate"
            (click)="autoAlign()"
          >
            <svg width="30" height="30" viewBox="0 0 25 25">
              <rect x="12" y="4" width="1" height="17" />
              <g>
                <rect x="7" y="7" style="fill: #5d5d5d;" width="10" height="4" />
                <path d="M17,7v3H8V7H17 M18,6H7v5h11V6L18,6z" />
              </g>
              <g>
                <rect x="7" y="14" style="fill: #5d5d5d;" width="10" height="4" />
                <path d="M17,14v3H8v-3H17 M18,13H7v5h11V13L18,13z" />
              </g>
            </svg>
          </button>
          <a (click)="openConsole()">
            <button
              id="button-terminal"
              matTooltipPosition="left"
              matTooltipClass="left"
              [matTooltip]="'designer.controls.actions.x_console' | translate"
            >
              <svg width="30" height="30" viewBox="0 0 24 25">
                <g>
                  <path d="M5,13.1v-0.8L10,9v0.9l-4,2.7l4,2.7v0.9L5,13.1z" />
                </g>
                <g>
                  <path d="M19,12.2V13l-5,3.3v-0.9l4-2.7l-4-2.7V9L19,12.2z" />
                </g>
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  `,
})
export class DesignerMapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() components: PackageComponent[];
  @Input() edges: Edge[];
  @Input() designerPackageWidth: number;
  @Input() designerPackageHeight: number;

  @ViewChild('designerMap') designerMap: ElementRef<HTMLElement>;
  @ViewChild('designerMapWrapper') designerMapWrapper: ElementRef<HTMLElement>;
  @ViewChild('svgMap') svgMap: ElementRef<HTMLElement>;
  @ViewChild('viewport') viewport: ElementRef<HTMLElement>;
  @ViewChild('overlayTop') overlayTop: ElementRef<HTMLElement>;
  @ViewChild('overlayBottom') overlayBottom: ElementRef<HTMLElement>;
  @ViewChild('overlayLeft') overlayLeft: ElementRef<HTMLElement>;
  @ViewChild('overlayRight') overlayRight: ElementRef<HTMLElement>;

  getComponentId = getComponentId;
  scale = 1;
  width = 0;
  height = 0;
  x = 0;
  y = 0;
  lastPositionX = 0;
  lastPositionY = 0;
  bottomOverlayHeightValue = 0;
  rightOverlayWidthValue = 0;
  dragPosition = { x: 0, y: 0 };
  shortcutSubscription: Subscription;
  isAnyModalOpen = false;
  isAnyModalOpenSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private shortcutsService: ShortcutsService,
  ) {}

  ngAfterViewInit() {
    document.querySelector('.designer-scroll').addEventListener('scroll', this.setViewPosition.bind(this));

    this.isAnyModalOpenSubscription = this.store
      .select(isAnyModalOpenPackageDesigner)
      .pipe(
        combineLatestWith(
          this.store.select(isAnyModalOpenClusters),
          this.store.select(isAnyModalOpenJobs),
          this.store.select(isAnyModalOpenPackages),
        ),
        map((isAnyModalOpenFlags) => isAnyModalOpenFlags.reduce((acc, curr) => acc || curr, false)),
      )
      .subscribe((isAnyModalOpen: boolean) => {
        this.isAnyModalOpen = isAnyModalOpen;
      });

    this.shortcutSubscription = this.shortcutsService
      .addShortcut({ keys: 'meta.f', shouldNotPreventDefault: true })
      .subscribe((event) => {
        if (!this.isAnyModalOpen) {
          event.preventDefault();
          this.autoAlign();
        }
      });

    this.setSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.designerPackageWidth?.currentValue !== changes.designerPackageWidth?.previousValue ||
      changes.designerPackageHeight?.currentValue !== changes.designerPackageHeight?.previousValue
    ) {
      setTimeout(() => {
        if (this.designerMap?.nativeElement) {
          this.setSize();
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index: number, item: any) {
    return item.id;
  }

  // eslint-disable-next-line class-methods-use-this
  identifyComponent(index: number, item: any) {
    return getComponentId(item);
  }

  setSize() {
    const designerElement = document.querySelector('.designer') as HTMLElement;

    if (!designerElement) {
      return;
    }

    const designerWidth = designerElement.offsetWidth;
    const designerHeight = designerElement.offsetHeight;
    this.width = Math.round(designerWidth * DESIGNER_SETTINGS.PANEL_SCALE);
    this.height = Math.round(designerHeight * DESIGNER_SETTINGS.PANEL_SCALE);

    this.designerMap.nativeElement.style.width = `${this.width + 32}px`;
    this.designerMap.nativeElement.style.height = `${this.height + 2}px`;

    this.designerMapWrapper.nativeElement.style.width = `${this.width}px`;
    this.designerMapWrapper.nativeElement.style.height = `${this.height}px`;

    this.svgMap.nativeElement.setAttribute('width', String(this.width));
    this.svgMap.nativeElement.setAttribute('height', String(this.height));
    this.svgMap.nativeElement.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);

    const designerRatio = this.designerPackageWidth / this.designerPackageHeight;
    const mapRatio = this.width / this.height;
    this.scale = 1;

    if (designerRatio < mapRatio) {
      if (this.designerPackageHeight !== 0) {
        this.scale = this.height / (this.designerPackageHeight * DESIGNER_SETTINGS.PANEL_SCALE);
      }
    } else {
      if (this.designerPackageWidth !== 0) {
        this.scale = this.width / (this.designerPackageWidth * DESIGNER_SETTINGS.PANEL_SCALE);
      }
    }

    this.viewport.nativeElement.style.width = `${Math.round(this.width * this.scale)}px`;
    this.viewport.nativeElement.style.height = `${Math.round(this.height * this.scale)}px`;

    if (this.designerPackageHeight && this.designerPackageWidth) {
      this.setViewPosition();
    }
  }

  setViewPosition() {
    const { scrollTop, scrollLeft } = document.querySelector('.designer-scroll');
    this.x = scrollLeft * this.scale * DESIGNER_SETTINGS.PANEL_SCALE;
    this.y = scrollTop * this.scale * DESIGNER_SETTINGS.PANEL_SCALE;

    const overlayLeft = {
      x: 0,
      y: 0,
      width: this.x - 1 < 0 ? 0 : this.x - 1,
      height: this.height,
    };
    this.overlayLeft.nativeElement.setAttribute('x', String(overlayLeft.x));
    this.overlayLeft.nativeElement.setAttribute('y', String(overlayLeft.y));
    this.overlayLeft.nativeElement.setAttribute('width', String(overlayLeft.width));
    this.overlayLeft.nativeElement.setAttribute('height', String(overlayLeft.height));

    const overlayTop = {
      x: this.x - 1 < 0 ? 0 : this.x - 1,
      y: 0,
      width: this.width * this.scale + 1,
      height: this.y - 1 < 0 ? 0 : this.y - 1,
    };
    this.overlayTop.nativeElement.setAttribute('x', String(overlayTop.x));
    this.overlayTop.nativeElement.setAttribute('y', String(overlayTop.y));
    this.overlayTop.nativeElement.setAttribute('width', String(overlayTop.width));
    this.overlayTop.nativeElement.setAttribute('height', String(overlayTop.height));

    const overlayBottom = {
      x: this.x - 1 < 0 ? 0 : this.x - 1,
      y: this.y + this.height * this.scale,
      width: this.width * this.scale + 1,
      height: this.height - this.y - this.height * this.scale,
    };
    overlayBottom.height = overlayBottom.height < 0 ? 0 : overlayBottom.height;
    this.overlayBottom.nativeElement.setAttribute('x', String(overlayBottom.x));
    this.overlayBottom.nativeElement.setAttribute('y', String(overlayBottom.y));
    this.overlayBottom.nativeElement.setAttribute('width', String(overlayBottom.width));
    this.overlayBottom.nativeElement.setAttribute('height', String(overlayBottom.height));

    const overlayRight = {
      x: this.x + this.width * this.scale,
      y: 0,
      width: this.width - this.width * this.scale - this.x,
      height: this.height,
    };
    overlayRight.width = overlayRight.width < 0 ? 0 : overlayRight.width;
    this.overlayRight.nativeElement.setAttribute('x', String(overlayRight.x));
    this.overlayRight.nativeElement.setAttribute('y', String(overlayRight.y));
    this.overlayRight.nativeElement.setAttribute('width', String(overlayRight.width));
    this.overlayRight.nativeElement.setAttribute('height', String(overlayRight.height));

    this.viewport.nativeElement.style.transform = `translate(${Math.round(this.x) - 0.5}px, ${
      Math.round(this.y) - 0.5
    }px)`;
    this.dragPosition = { x: Math.round(this.x) - 0.5, y: Math.round(this.y) - 0.5 };
  }

  onDragStarted() {
    this.bottomOverlayHeightValue = Number(this.overlayBottom.nativeElement.getAttribute('height'));
    this.rightOverlayWidthValue = Number(this.overlayRight.nativeElement.getAttribute('width'));
  }

  onDragMoved(event) {
    const { distance } = event;

    const currentX = this.lastPositionX + distance.x;
    const currentY = this.lastPositionY + distance.y;

    const top = currentY / this.scale / DESIGNER_SETTINGS.PANEL_SCALE;
    const left = currentX / this.scale / DESIGNER_SETTINGS.PANEL_SCALE;

    const designerScroll = document.querySelector('.designer-scroll') as HTMLElement;

    designerScroll.scrollLeft = left;
    designerScroll.scrollTop = top;
    this.setViewPosition();
  }

  onDragEnded(event) {
    const { distance } = event;

    this.lastPositionX = this.lastPositionX + distance.x;
    this.lastPositionY = this.lastPositionY + distance.y;

    if (this.lastPositionX < 0) {
      this.lastPositionX = 0;
    }

    if (this.lastPositionY < 0) {
      this.lastPositionY = 0;
    }

    if (this.lastPositionY > this.bottomOverlayHeightValue) {
      this.lastPositionY = this.bottomOverlayHeightValue;
    }

    if (this.lastPositionX > this.rightOverlayWidthValue) {
      this.lastPositionX = this.rightOverlayWidthValue;
    }
  }

  openConsole() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.route.parent.snapshot.params.account_id}/console`]),
    );
    window.open(url, '_blank');
  }

  autoAlign() {
    this.store.dispatch(autoAlign({ width: this.width }));
  }

  ngOnDestroy() {
    document.querySelector('.designer-scroll').removeEventListener('scroll', this.setViewPosition.bind(this));

    if (this.shortcutSubscription) {
      this.shortcutSubscription.unsubscribe();
    }

    if (this.isAnyModalOpenSubscription) {
      this.isAnyModalOpenSubscription.unsubscribe();
    }
  }
}
