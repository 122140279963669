import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Connection, ExecuteSQLComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { defaultConnections } from '../../../connections/helpers/connection-types.helpers';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { openPackageVariablesModal } from '../../store/package-designer.actions';
import { selectVariables } from '../../store/package-designer.selectors';

@Component({
  selector: 'execute-sql-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
          <dynamic-connection [component]="rawComponent" *ngxPermissionsOnly="'dynamicConnection'"></dynamic-connection>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="execute-sql-editor">
            <xp-form-validation type="ExecuteSqlComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="alert alert-warning" *ngIf="errorMessage">{{ errorMessage }}</div>
                <div class="row">
                  <div class="col-sm-12">
                    <xp-form-group>
                      <label for="query">{{ 'execute-sql-editor.form.labels.query' | translate }}</label>
                      <code-editor
                        [value]="rawComponent.query"
                        [options]="editorOptions"
                        name="query"
                        (valueChange)="onValueChange($event, 'query')"
                      ></code-editor>
                      <small class="hint"
                        >Use No Result result type if you’re running statements that don’t return results (e.g.
                        INSERT/UPDATE/CREATE). Use the other result types to assign a scalar value (i.e. first column in
                        first row of returned result set) into a variable.</small
                      >
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="result_type">{{ 'execute-sql-editor.form.labels.result_type' | translate }}</label>
                      <xp-select
                        [value]="rawComponent.result_type"
                        [options]="resultTypes"
                        (valueChange)="onValueChange($event, 'result_type')"
                        name="result_type"
                        id="result_type"
                        class="form-control xp-select"
                      ></xp-select>
                    </xp-form-group>
                  </div>
                  <div class="col-sm-6">
                    <div>
                      <button
                        class="btn btn-info btn-md pull-right"
                        style="margin-top: 26px;"
                        data-action="submit"
                        [ngClass]="{ loading: isQueryLoading }"
                        (click)="testQuery()"
                        [disabled]="!rawComponent.query || rawComponent.query.length === 0"
                      >
                        {{ 'execute-sql-editor.form.buttons.test_query' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group *ngIf="rawComponent.result_type !== 'none'">
                      <label for="result_variable">{{
                        'execute-sql-editor.form.labels.result_variable' | translate
                      }}</label>
                      <xp-select
                        [value]="rawComponent.result_variable"
                        [options]="variablesOptions$ | async"
                        (valueChange)="onValueChange($event, 'result_variable')"
                        name="result_variable"
                        id="result_variable"
                        placeholder="Select a variable"
                        class="form-control xp-select"
                      ></xp-select>
                      <a class="btn btn-sm btn-link pull-right" (click)="editVariables()">{{
                        'execute-sql-editor.form.buttons.edit_variables' | translate
                      }}</a>
                    </xp-form-group>
                  </div>
                </div>
                <schema-importer-data-preview
                  *ngIf="showData"
                  [data]="data"
                  [component]="component"
                  [rawComponent]="rawComponent"
                  [isPreviewLoading]="isQueryLoading"
                ></schema-importer-data-preview>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="operatorEditorStep">
          <div class="workflow-operator-editor">
            <div class="form-group">
              <div>
                <label
                  ><strong>{{ 'run-package-editor.labels.operator' | translate }}</strong></label
                >
              </div>
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.operator"
                    (ngModelChange)="onValueChange($event, 'operator')"
                    name="operator"
                    id="operator-and"
                    value="and"
                  />
                  <label for="operator-and">{{ 'run-package-editor.labels.and' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.operator"
                    (ngModelChange)="onValueChange($event, 'operator')"
                    name="operator"
                    id="operator-or"
                    value="or"
                  />
                  <label for="operator-or">{{ 'run-package-editor.labels.or' | translate }}</label>
                </div>
              </div>
            </div>
          </div>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class ExecuteSqlEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: ExecuteSQLComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;
  isFormValid = true;
  validationChangeSubscription: Subscription;
  isBaseComponentStepActivated = false;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  operatorEditorStep: Step = getStep({});

  isQueryLoading = false;
  data = null;
  showData = true;
  errorMessage = null;
  editorOptions = {
    mode: 'text/x-sql',
    placeholder: 'SELECT * FROM table',
  };
  variablesOptions$ = this.store
    .select(selectVariables)
    .pipe(map((variables) => Object.keys(variables).map((key) => ({ value: key, text: key }))));

  resultTypes = [
    { value: 'none', text: 'execute-sql-editor.form.selects.result_type.options.none', translate: true },
    { value: 'string', text: 'execute-sql-editor.form.selects.result_type.options.string', translate: true },
    { value: 'int', text: 'execute-sql-editor.form.selects.result_type.options.int', translate: true },
    { value: 'long', text: 'execute-sql-editor.form.selects.result_type.options.long', translate: true },
    { value: 'float', text: 'execute-sql-editor.form.selects.result_type.options.float', translate: true },
    { value: 'double', text: 'execute-sql-editor.form.selects.result_type.options.double', translate: true },
    { value: 'boolean', text: 'execute-sql-editor.form.selects.result_type.options.boolean', translate: true },
    {
      value: 'datetime',
      text: 'execute-sql-editor.form.selects.result_type.options.datetime',
      translate: true,
    },
  ];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private connectionItemsResource: ConnectionItemsResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: 'Query connection',
      activeTitle: 'Choose input connection',
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: 'Query',
      activeTitle: 'Define your query',
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.operatorEditorStep = getStep({
      title: 'Execute task if',
      activeTitle: 'Choose task execution',
      valid: true,
      tags: this.getComponentOperatorTags(this.rawComponent.operator),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  onValidityChange() {
    const isValid = this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    if (key === 'operator') {
      this.operatorEditorStep = { ...this.operatorEditorStep, tags: this.getComponentOperatorTags(value) };
    }
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  testQuery() {
    this.errorMessage = null;

    if (!this.rawComponent.connection || !this.rawComponent.connection.type) return;

    const connection = defaultConnections[this.rawComponent.connection.type];

    if (connection) {
      this.isQueryLoading = true;

      const query: any = {
        query: this.rawComponent.query,
        result_type: this.rawComponent.result_type,
        access_mode: 'query',
      };

      query.no_result = this.rawComponent.result_type === 'none';

      this.connectionItemsResource
        .schema(this.rawComponent.connection.type, this.rawComponent.connection.id, query)
        .subscribe({
          next: (res) => {
            this.data = res;
            this.isQueryLoading = false;
          },
          error: (res) => {
            if (res.error && res.error.error_message) {
              this.errorMessage = res.error.error_message;
            } else {
              this.errorMessage = this.translate.instant('execute-sql-editor.query_error_message');
            }
            this.isQueryLoading = false;
          },
        });
    }
  }

  editVariables() {
    this.store.dispatch(openPackageVariablesModal());
  }

  // eslint-disable-next-line class-methods-use-this
  getComponentOperatorTags(operator: string) {
    if (operator === 'and') {
      return [
        {
          name: 'all preceding conditions evaluate to true (AND)',
        },
      ];
    }
    if (operator === 'or') {
      return [
        {
          name: 'one of the preceding conditions evaluate to true (OR)',
        },
      ];
    }

    return [];
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
