import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { NotifyService } from '../../common/services/notify.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  handler = null;
  invoicesHandler = null;

  constructor(
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}

  open(options: any, billing_email: string) {
    if (!this.handler) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      if (!window['StripeCheckout']) {
        this.notify.error('There was an error with loading Stripe library. Please contact support.');
        return;
      }
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.handler = window['StripeCheckout'].configure({
        key: environment.STRIPE_PUBLIC_KEY,
        image: `https://www.integrate.io/logo/xplenty-logo-icon.png`,
        locale: 'en', // current locale
        panelLabel: this.translate.instant('plan.checkout.labels.subscribe'),
        email: billing_email,
        allowRememberMe: false,
      });
    }

    this.handler.open(options);
  }

  close() {
    if (!this.handler) return;
    this.handler.close();
  }

  openInvoicesModal(options: any, billing_customer_token: string) {
    if (billing_customer_token) {
      if (!this.invoicesHandler) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (!window['AccountDock']) {
          this.notify.error('There was an error with loading AccountDock library. Please contact support.');
          return;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.invoicesHandler = window['AccountDock'].configure({
          key: environment.ACCOUNT_DOCK_PUBLIC_KEY,
          customer: billing_customer_token,
          container: 'modal',
        });
      }

      this.invoicesHandler.open(options);
    }
  }
}
