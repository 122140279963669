/* eslint-disable no-prototype-builtins */
import { Injectable } from '@angular/core';

import CodeMirror, { StringStream } from 'codemirror';
import { FunctionItem, XplentyPigFunctionsService } from './xplenty-pig-functions.service';

const isOperatorChar = /[*+\-%<>=&?:/!|# ]/;

interface State {
  tokenize: (...args: any[]) => any;
}

function chain(stream: StringStream, state: State, f: (...args: any[]) => any) {
  // eslint-disable-next-line no-param-reassign
  state.tokenize = f;
  return f(stream, state);
}

function getKeywords(str: string): any {
  const obj: any = {};
  const words = str.toUpperCase().split(' ');
  for (let i = 0; i < words.length; i += 1) obj[words[i]] = true;
  return obj;
}

@Injectable({
  providedIn: 'root',
})
export class XplentyPigService {
  constructor(private xplentyPigFunctions: XplentyPigFunctionsService) {}

  public init(functions: FunctionItem[]): void {
    CodeMirror.defineMode('pig', function (_config, parserConfig) {
      const { keywords } = parserConfig;
      const { builtins } = parserConfig;
      const { types } = parserConfig;
      const { multiLineStrings } = parserConfig;

      function tokenComment(stream: StringStream, state: State) {
        let isEnd = false;
        let ch;
        // eslint-disable-next-line no-cond-assign
        while ((ch = stream.next())) {
          if (ch === '/' && isEnd) {
            // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-use-before-define
            state.tokenize = tokenBase;
            break;
          }
          isEnd = ch === '*';
        }
        return 'comment';
      }

      function tokenString(quote: string, multiLineStringsData: boolean) {
        return function (stream: StringStream, state: State) {
          let escaped = false;
          let next;
          let end = false;
          // eslint-disable-next-line no-cond-assign
          while ((next = stream.next()) != null) {
            if (next === quote && !escaped) {
              end = true;
              break;
            }
            escaped = !escaped && next === '\\';
          }
          // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-use-before-define
          if (end || !(escaped || multiLineStringsData)) state.tokenize = tokenBase;
          return 'error';
        };
      }

      function tokenBase(stream: StringStream, state: State) {
        const ch = stream.next();

        // is a start of string?
        if (ch === '"' || ch === "'") return chain(stream, state, tokenString(ch, multiLineStrings));
        // is it one of the special chars
        // eslint-disable-next-line no-useless-escape
        if (/[\[\]{}\(\),;\.]/.test(ch)) return null;
        // is it a number?
        if (/\d/.test(ch)) {
          // eslint-disable-next-line no-useless-escape
          stream.eatWhile(/[\w\.]/);
          return 'number';
        }
        // multi line comment or operator
        if (ch === '/') {
          if (stream.eat('*')) {
            return chain(stream, state, tokenComment);
          }

          stream.eatWhile(isOperatorChar);
          return 'operator';
        }
        // single line comment or operator
        if (ch === '-') {
          if (stream.eat('-')) {
            stream.skipToEnd();
            return 'comment';
          }

          stream.eatWhile(isOperatorChar);
          return 'operator';
        }
        // is it an operator
        if (isOperatorChar.test(ch)) {
          stream.eatWhile(isOperatorChar);
          return 'operator';
        }

        // get the while word

        // eslint-disable-next-line no-useless-escape
        stream.eatWhile(/[\w_\.]/);
        // is it one of the listed keywords?
        if (keywords && keywords.propertyIsEnumerable(stream.current().toUpperCase())) {
          // keywords can be used as variables like flatten(group), group.$0 etc..
          if (!stream.eat(')') && !stream.eat('.')) return 'keyword';
        }
        // is it one of the builtin functions?
        if (builtins && builtins.propertyIsEnumerable(stream.current().toUpperCase())) return 'variable-2';
        // is it one of the listed types?
        if (types && types.propertyIsEnumerable(stream.current().toUpperCase())) return 'variable-3';
        if (/\$./.test(stream.current().toUpperCase())) return 'variable';
        stream.backUp(stream.current().length);
        // get the while word
        stream.next();
        // eslint-disable-next-line no-useless-escape
        stream.eatWhile(/[\w\$_]/);

        // default is a 'variable'
        return 'pig-word';
      }

      // Interface
      return {
        startState() {
          return {
            tokenize: tokenBase,
            startOfLine: true,
          };
        },

        token(stream, state) {
          if (stream.eatSpace()) return null;
          const style = state.tokenize(stream, state);
          return style;
        },
      };
    });

    let datatypes = '';
    let keywords = '';
    let variables = '';

    this.xplentyPigFunctions.items.subscribe((items) => {
      items.concat(functions).forEach(function (func) {
        switch (func.itemclass) {
          case 'datatype':
            datatypes = `${datatypes + func.name} `;
            break;
          case 'keyword':
            keywords = `${keywords + func.name} `;
            break;
          case 'variable':
            variables = `${variables + func.name} `;
            break;
          default:
            break;
        }
      });

      const pBuiltins = 'IS NOT NULL NOT OR AND FLATTEN CASE WHEN THEN ELSE END MATCHES TRUE FALSE';

      // taken from QueryLexer.g
      const pKeywords = keywords; // "VOID IMPORT RETURNS DEFINE LOAD FILTER FOREACH ORDER CUBE DISTINCT COGROUP " + "JOIN CROSS UNION SPLIT INTO IF OTHERWISE ALL AS BY USING INNER OUTER ONSCHEMA PARALLEL " + "PARTITION GROUP AND OR NOT GENERATE FLATTEN ASC DESC IS STREAM THROUGH STORE MAPREDUCE " + "SHIP CACHE INPUT OUTPUT STDERROR STDIN STDOUT LIMIT SAMPLE LEFT RIGHT FULL EQ GT LT GTE LTE " + "NEQ MATCHES TRUE FALSE DUMP";

      // data types
      const pTypes = 'BOOLEAN INT LONG FLOAT DOUBLE CHARARRAY BYTEARRAY BAG TUPLE MAP ';

      CodeMirror.defineMIME('text/x-pig', {
        name: 'pig',
        builtins: getKeywords(pBuiltins),
        keywords: getKeywords(pKeywords),
        types: getKeywords(pTypes),
        variables: getKeywords(variables),
      } as any);

      CodeMirror.registerHelper(
        'hintWords',
        'pig',
        /* pBuiltins + pTypes + */ (pKeywords + datatypes).split(' ').sort(),
      );
    });
  }
}
