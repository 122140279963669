import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  duplicatePackage,
  duplicatePackageError,
  duplicatePackageResponse,
  getPackagesList,
  getPackagesListError,
  getPackagesListResponse,
  loadMorePackagesList,
  loadMorePackagesListResponse,
  removePackage,
  removePackageError,
  removePackageItem,
  removePackageResponse,
  rollbackPackage,
  rollbackPackageError,
  rollbackPackageResponse,
  savePackage,
  savePackageError,
  savePackageResponse,
  searchPackagesList,
  searchPackagesListResponse,
  updatePackage,
  updatePackageError,
  updatePackageResponse,
} from './packages.actions';
import { PackagesResource } from '../resources/packages.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../constants/animation-constants';
import { NotifyService } from '../../common/services/notify.service';
import { getErrorFromResponse } from '../../common/helper/response.helpers';
import { setPackageFromJson } from '../../package-designer/store/package.actions';

export const MY_WINDOW_TOKEN = new InjectionToken<Window>('Window');

@Injectable()
export class PackagesEffects {
  getPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPackagesList),
      switchMap(({ params }) =>
        this.packagesResource.query(params).pipe(
          map((packages) => getPackagesListResponse({ packages })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getPackagesListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  searchPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchPackagesList),
      switchMap(({ params }) =>
        this.packagesResource.search(params).pipe(
          map((packages) => searchPackagesListResponse({ packages })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getPackagesListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMorePackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMorePackagesList),
      switchMap(({ params }) =>
        (params.q ? this.packagesResource.search(params) : this.packagesResource.query(params)).pipe(
          map((packages) => loadMorePackagesListResponse({ packages })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getPackagesListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  savePackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePackage),
      switchMap(({ packageItem, redirect }) =>
        this.packagesResource.save(packageItem, { include: 'owner,workspace' } as any).pipe(
          map((packageResponse) => {
            if (redirect) {
              this.router.navigate([
                `/${this.route.firstChild.snapshot.params.account_id}/packages/${packageResponse.id}/edit`,
              ]);
            }
            return savePackageResponse({ data: packageResponse, closeModal: true });
          }),
          catchError((response) => of(savePackageError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  duplicatePackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicatePackage),
      switchMap(({ packageId, packageName }) =>
        this.packagesResource
          .save({ source_package_id: packageId, source_package_name: packageName }, {
            include: 'owner,workspace',
          } as any)
          .pipe(
            map((packageResponse) => duplicatePackageResponse({ data: packageResponse })),
            catchError((response) => of(duplicatePackageError({ errors: getErrorFromResponse(response) }))),
          ),
      ),
    ),
  );

  rollbackPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rollbackPackage),
      switchMap(({ packageId, versionId }) =>
        this.packagesResource.rollback(packageId, versionId, { include: 'owner,workspace,flow' } as any).pipe(
          map((packageResponse) => rollbackPackageResponse({ data: packageResponse })),
          catchError((response) => of(rollbackPackageError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  updatePackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePackage),
      switchMap(({ packageItem, params, packageId }) =>
        this.packagesResource.update(packageId, packageItem, { ...params, include: 'owner,workspace' } as any).pipe(
          map((packageResponse) => {
            this.notify.success(this.translate.instant('designer.controls.actions.save_package.success'));
            return updatePackageResponse({ data: packageResponse, closeModal: true });
          }),
          catchError((response) => of(updatePackageError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removePackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePackage),
      switchMap(({ packageId }) =>
        this.packagesResource.remove(packageId).pipe(
          map((packageResponse) => removePackageItem({ data: packageResponse })),
          catchError((response) => of(removePackageError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removePackageResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePackageItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removePackageResponse({ data })),
    ),
  );

  rollbackPackageResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rollbackPackageResponse),
      map(({ data }) => setPackageFromJson({ packageItem: data })),
    ),
  );

  constructor(
    private actions$: Actions,
    private packagesResource: PackagesResource,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotifyService,
    private translate: TranslateService,
    @Inject(MY_WINDOW_TOKEN) private window: Window,
  ) {}
}
