import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { AuthMethod, SwiftConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';

@Component({
  selector: 'connection-form-swift',
  template: `
    <xp-form-validation type="SwiftConnection" [name]="formName">
      <form id="connectionFormSwift" name="connectionFormSwift" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                readonly
              ></xp-input>
            </div>
            <xp-form-group>
              <label for="username">{{ 'connections.form.swift.labels.username' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="username"
                id="username"
                [(ngModel)]="connection.username"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="password">{{ 'connections.form.swift.labels.password' | translate }}</label>
              <xp-input
                type="password"
                class="form-control"
                name="password"
                id="password"
                [(ngModel)]="connection.password"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="authentication_service">{{
                'connections.form.swift.labels.authentication_service' | translate
              }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="authentication_service"
                id="authentication_service"
                [(ngModel)]="connection.authentication_service"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="auth_method">{{ 'connections.form.swift.labels.auth_method' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                name="auth_method"
                id="auth_method"
                [value]="connection.auth_method"
                (valueChange)="onAuthMethodChange($event)"
                [options]="authMethodOptions"
              >
              </xp-select>
            </xp-form-group>
            <xp-form-group>
              <label for="tenant_name"
                >{{ 'connections.form.swift.labels.tenant_name' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="text"
                class="form-control"
                name="tenant_name"
                id="tenant_name"
                [(ngModel)]="connection.tenant_name"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="col-md-6"></div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormSwiftComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<SwiftConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormSwift';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  authMethodOptions = [
    { value: 'keystone', text: 'connections.form.sftp.select.options.keystone', translate: true },
    { value: 'swauth', text: 'connections.form.sftp.select.options.swauth', translate: true },
  ];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  onAuthMethodChange(authMethod: AuthMethod) {
    this.connection.auth_method = authMethod;
  }
}
