import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';
import { intersection } from 'lodash';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { Schema } from '../../../package.models';

@Component({
  selector: 'union-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['left', 'right', 'alias']"
      duplicationValidationProp="alias"
      duplicationValidationPropName="Alias"
    >
      <ng-template templateName="left" let-item>
        <xp-field-picker
          [value]="item.record.left"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [index]="item.index"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'left')"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="left-header" let-item>
        {{ (parentSchemas[0] || {}).name | xpLengthCheck: 35 }}
      </ng-template>

      <ng-template templateName="right" let-item>
        <xp-field-picker
          [value]="item.record.right"
          [schema]="parentSchemas[1]"
          [fields]="(parentSchemas[1] || {}).fields || []"
          [index]="item.index"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'right')"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="right-header" let-item>
        {{ (parentSchemas[1] || {}).name | xpLengthCheck: 35 }}
      </ng-template>

      <ng-template templateName="alias" let-item>
        <xp-alias-editor
          [value]="item.record.alias"
          [index]="item.index"
          propName="alias"
          [focusedProp]="item.focusedProp"
          [isDuplicateError]="item.record.isDuplicateError"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'alias')"
        ></xp-alias-editor>
      </ng-template>
      <ng-template templateName="alias-header" let-item>
        <xp-alias-editor-title [title]="'fields-collection.headers.alias-editor' | translate"></xp-alias-editor-title>
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class UnionCollectionComponent implements OnInit {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: CollectionSettings;
  @Input() parentSchemas: Schema[];
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const autoFillFns = [
      {
        text: 'By order',
        func: this.autoFill('order'),
      },
      {
        text: 'By name',
        func: this.autoFill('name'),
      },
    ];

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 20,
      emptyRecord: { name: '', projected_name: '', FC_pristine: true },
      parentSchemas: this.parentSchemas,
      autoGenerateFn: this.autoGenerateAliasAndUpdateRecords.bind(this),
      autoFillFns,
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  autoFill(type: string): () => DesignerSchemaFieldI[] {
    return () => {
      this.recordsCopy = [];
      if (!this.parentSchemas[0] || !this.parentSchemas[1]) {
        return [this.collectionSettings.emptyRecord];
      }
      if (type === 'order') {
        const shorterSchemasLength =
          this.parentSchemas[0].fields.length < this.parentSchemas[1].fields.length
            ? this.parentSchemas[0].fields.length
            : this.parentSchemas[1].fields.length;
        for (let i = 0; i < shorterSchemasLength; i += 1) {
          const left = this.parentSchemas[0].fields[i].name;
          const right = this.parentSchemas[1].fields[i].name;
          this.recordsCopy.push({ left, right, alias: left, id: uuidv4() });
        }
      } else if (type === 'name') {
        const matchedRecordsNames = intersection(
          this.parentSchemas[0].fields.map((field) => field.name),
          this.parentSchemas[1].fields.map((field) => field.name),
        );
        matchedRecordsNames.forEach((matchedRecordName) => {
          const record = { left: matchedRecordName, right: matchedRecordName, alias: matchedRecordName, id: uuidv4() };
          this.recordsCopy.push(record);
        });
      }
      this.recordsCopy = this.recordsCopy.slice();
      return this.recordsCopy;
    };
  }

  autoGenerateAliasAndUpdateRecords(record: DesignerSchemaFieldI) {
    const alias = record.left;

    if (alias) {
      this.recordsCopy = this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            projected_name: alias,
            alias,
          };
        }
        return item;
      });
    }

    this.onRecordChange({ records: this.recordsCopy });
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { name: record.name, projected_name: record.projected_name };
    });
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fields = records.map(({ left, right, alias }) => ({ left, right, alias }));
    this.store.dispatch(updateComponent({ component: { fields } }));
    this.recordsChange.emit(fields);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    this.recordsCopy = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });

    this.onRecordChange({
      records: this.recordsCopy,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
