import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FunctionsResource {
  private apiRoot: string = environment.API_URL;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  getFunctions() {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) => this.http.get<any>(`${this.apiRoot}/functions`, { params: { account_id } as any })),
    );
  }
}
