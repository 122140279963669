import { Component, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../store';
import { UsersResource } from '../../account/resources/users.resource';
import { User } from '../../account/user.models';
import { NotifyService } from '../../common/services/notify.service';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { ValidationError } from '../../config/validation-error.model';
import { hasUserIntegrateIOID } from '../../account/store/account.selectors';

@Component({
  selector: 'settings-profile',
  template: `
    <div class="settings-body">
      <xp-form-validation type="User" [name]="formName">
        <form #form="ngForm" (ngSubmit)="updateUser()" novalidate>
          <div class="form-group">
            <xp-form-group>
              <label
                >{{ 'user.settings.info.labels.name' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input type="text" name="name" class="form-control" [(ngModel)]="userToEdit.name"></xp-input>
            </xp-form-group>
          </div>
          <div class="form-group" *ngIf="!(hasUserIntegrateIOID$ | async)">
            <xp-form-group>
              <label for="email">{{ 'user.settings.info.labels.email' | translate }}</label>
              <xp-input
                type="text"
                id="email"
                name="email"
                class="form-control"
                [(ngModel)]="userToEdit.email"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="form-group">
            <xp-form-group>
              <label>{{ 'user.settings.info.labels.time_zone' | translate }}</label>
              <xp-timezone-picker name="timezone" [(timezoneId)]="userToEdit.time_zone"></xp-timezone-picker>
              <small>{{ 'user.settings.info.hints.time_zone' | translate }}</small>
            </xp-form-group>
          </div>
          <div class="form-group">
            <xp-form-group>
              <label
                >{{ 'user.settings.info.labels.location' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input type="text" name="location" class="form-control" [(ngModel)]="userToEdit.location"></xp-input>
              <small>{{ 'user.settings.info.hints.location' | translate }}</small>
            </xp-form-group>
          </div>
          <div class="form-group">
            <label [innerHTML]="'user.settings.info.labels.gravatar_email' | translate">
              <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
            >
            <xp-form-group>
              <xp-input
                type="email"
                name="gravatar_email"
                class="form-control"
                [(ngModel)]="userToEdit.gravatar_email"
              ></xp-input>
            </xp-form-group>
            <small [innerHTML]="'user.settings.info.hints.gravatar_email' | translate"></small>
          </div>
          <div class="form-group">
            <xp-submit-button
              classNames="btn-md btn-success"
              [updateText]="'user.settings.info.actions.submit' | translate"
              [isFormValid]="form.valid"
              [isFormSubmitting]="isSubmitting"
            ></xp-submit-button>
          </div>
        </form>
      </xp-form-validation>
    </div>
  `,
})
export class SettingsProfileComponent extends BaseForm implements OnInit, BaseFormInterface {
  userToEdit: Partial<User> = {};
  initialUser: Partial<User> = {};
  @ViewChild('form') form: NgForm;
  isSubmitting = false;
  formName = 'userForm';
  successMessageText = 'user.settings.info.success_message';
  errors$ = new Subject<ValidationError[]>();
  hasUserIntegrateIOID$ = this.store.select(hasUserIntegrateIOID);

  constructor(
    private usersResource: UsersResource,
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();

    usersResource.get().subscribe({
      next: (user) => {
        this.initialUser = { ...user };
        this.userToEdit = user;
      },
      error: () => {
        this.notify.error('An error occurred while loading user data.');
      },
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateUser() {
    this.isSubmitting = true;

    return this.usersResource.update(this.userToEdit).subscribe({
      next: (user) => {
        if (this.initialUser.email !== this.userToEdit.email) {
          this.notify.info(this.translate.instant('user.settings.info.email_update_message'), '', 10000);
        } else {
          this.handleSuccess();
        }
        this.userToEdit = user;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        window['DEFAULT_TIMEZONE'] = user.time_zone_info.offset;
        this.isSubmitting = false;
      },
      error: (res: HttpErrorResponse) => {
        this.isSubmitting = false;

        if (res.error) {
          if (res.error.errors) {
            this.errors$.next(res.error.errors);
          }
        }
      },
    });
  }
}
