import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  getClustersList,
  getClustersListError,
  getClustersListResponse,
  loadMoreClustersList,
  loadMoreClustersListResponse,
  removeCluster,
  removeClusterError,
  removeClusterItem,
  removeClusterResponse,
  saveCluster,
  saveClusterError,
  saveClusterResponse,
  searchClustersList,
  searchClustersListResponse,
  updateCluster,
  updateClusterError,
  updateClusterResponse,
} from './clusters.actions';
import { ClustersResource } from '../resources/clusters.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../constants/animation-constants';
import { NotifyService } from '../../common/services/notify.service';
import { getErrorFromResponse } from '../../common/helper/response.helpers';

@Injectable()
export class ClustersEffects {
  getClusters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClustersList),
      switchMap(({ params }) =>
        this.clustersResource.query(params).pipe(
          map((clusters) => getClustersListResponse({ clusters })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getClustersListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  searchClusters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchClustersList),
      switchMap(({ params }) =>
        this.clustersResource.search(params).pipe(
          map((clusters) => searchClustersListResponse({ clusters })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getClustersListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreClusters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreClustersList),
      switchMap(({ params }) =>
        (params.q ? this.clustersResource.search(params) : this.clustersResource.query(params)).pipe(
          map((clusters) => loadMoreClustersListResponse({ clusters })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getClustersListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCluster),
      switchMap(({ cluster }) =>
        this.clustersResource.save(cluster, { include: 'packages' } as any).pipe(
          map((clusterResponse) => saveClusterResponse({ data: clusterResponse, closeModal: true })),
          catchError((response) => of(saveClusterError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  updateCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCluster),
      switchMap(({ cluster, params, clusterId }) =>
        this.clustersResource.update(clusterId, cluster, { ...params, include: 'packages' } as any).pipe(
          map((clusterResponse) => updateClusterResponse({ data: clusterResponse, closeModal: true })),
          catchError((response) => of(updateClusterError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCluster),
      switchMap(({ clusterId }) =>
        this.clustersResource.remove(clusterId).pipe(
          map((clusterResponse) => removeClusterItem({ data: clusterResponse })),
          catchError((response) => of(removeClusterError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeClusterResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeClusterItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeClusterResponse({ data })),
    ),
  );

  constructor(
    private actions$: Actions,
    private clustersResource: ClustersResource,
    private notify: NotifyService,
    private translate: TranslateService,
  ) {}
}
