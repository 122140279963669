export interface FunctionListItem {
  key: string;
  name: string;
  autocomplete: string;
  itemclass: string;
  description?: string;
  category: string;
  console?: string;
  help: {
    header: string;
    body: string;
    example: string;
  };
}
export const functionsList: FunctionListItem[] = [
  {
    key: 'int',
    name: 'int',
    autocomplete: 'int',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>int</h3>',
      body: '<p>Signed 32-bit integer.</p>',
      example: 'Example: 10, 234, 14562',
    },
  },
  {
    key: 'long',
    name: 'long',
    autocomplete: 'long',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>long</h3>',
      body: '<p>Signed 64-bit integer.</p>',
      example: 'Example: 34359738368, 10, 234, 14562',
    },
  },
  {
    key: 'float',
    name: 'float',
    autocomplete: 'float',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>float</h3>',
      body: '<p>Signed 32-bit floating point.</p>',
      example: 'Example: 10.3445, 10015.345, 200.24',
    },
  },
  {
    key: 'double',
    name: 'double',
    autocomplete: 'double',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>double</h3>',
      body: '<p>Signed 64-bit floating point.</p>',
      example: 'Example: 343597383.3445, 10.3445, 10015.345, 200.24',
    },
  },
  {
    key: 'chararray',
    name: 'chararray',
    autocomplete: 'chararray',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>chararray</h3>',
      body: '<p>Character array (string) in Unicode UTF-8 format.</p>',
      example: 'Example: "Hello World!"\n',
    },
  },
  {
    key: 'bytearray',
    name: 'bytearray',
    autocomplete: 'bytearray',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>bytearray</h3>',
      body: '<p>Byte array (blob).</p>',
      example: 'Example: ["H", "e", "l", "l", "o", "!"]\n',
    },
  },
  {
    key: 'datetime',
    name: 'datetime',
    autocomplete: 'datetime',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>datetime</h3>',
      body: '<p>DateTime object.</p>',
      example: 'Example: 1970-01-01T00:00:00.000+00:00',
    },
  },
  {
    key: 'boolean',
    name: 'boolean',
    autocomplete: 'boolean',
    itemclass: 'datatype',
    category: 'Datatype',
    help: {
      header: '<h3>boolean</h3>',
      body: '<p>true or false</p>',
      example: 'Example: true/false (case insensitive)',
    },
  },
  {
    key: 'DeviceFamily',
    name: 'DeviceFamily',
    autocomplete: 'DeviceFamily',
    itemclass: 'keyword',
    description: 'Extract device family (device name) from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "DeviceFamily('Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0; Crebergallery spider robot)')",
    help: {
      header: '<h3>DeviceFamily</h3>',
      body: '<p>Extract device family (device name) from user agent string, based on ua_parser.</p><p>Syntax: DeviceFamily(string_expression)</p>',
      example:
        "Example: DeviceFamily('Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0; Crebergallery spider robot)') returns 'Spider'",
    },
  },
  {
    key: 'PlatformFamily',
    name: 'PlatformFamily',
    autocomplete: 'PlatformFamily',
    itemclass: 'keyword',
    description: 'Extract platform family (device category) from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "PlatformFamily('Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0; Crebergallery spider robot)')",
    help: {
      header: '<h3>PlatformFamily</h3>',
      body: '<p>Extract platform family (device category) from user agent string, based on ua_parser.</p><p>Syntax: PlatformFamily(string_expression)</p>',
      example:
        "Example: PlatformFamily('Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0; Crebergallery spider robot)') returns 'Bot'",
    },
  },
  {
    key: 'OsFamily',
    name: 'OsFamily',
    autocomplete: 'OsFamily',
    itemclass: 'keyword',
    description: 'Extract operating system family (OS name) from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsFamily('Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0; Crebergallery spider robot)')",
    help: {
      header: '<h3>OsFamily</h3>',
      body: '<p>Extract operating system family (OS name) from user agent string, based on ua_parser.</p><p>Syntax: OsFamily(string_expression)</p>',
      example:
        "Example: OsFamily('Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0; Crebergallery spider robot)') returns 'Mac OS X'",
    },
  },
  {
    key: 'OsFullName',
    name: 'OsFullName',
    autocomplete: 'OsFullName',
    itemclass: 'keyword',
    description: 'Extract operating system family and version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsFullName('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>OsFullName</h3>',
      body: '<p>Extract operating system family and version from user agent string, based on ua_parser.</p><p>Syntax: OsFullName(string_expression)</p>',
      example:
        "Example: OsFullName('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns 'Mac OS X 10.6.8'",
    },
  },
  {
    key: 'OsMajor',
    name: 'OsMajor',
    autocomplete: 'OsMajor',
    itemclass: 'keyword',
    description: 'Extract operating system major version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsMajor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>OsMajor</h3>',
      body: '<p>Extract operating system major version from user agent string, based on ua_parser.</p><p>Syntax: OsMajor(string_expression)</p>',
      example:
        "Example: OsMajor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '10'",
    },
  },
  {
    key: 'OsMinor',
    name: 'OsMinor',
    autocomplete: 'OsMinor',
    itemclass: 'keyword',
    description: 'Extract operating system minor version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsMinor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>OsMinor</h3>',
      body: '<p>Extract operating system minor version from user agent string, based on ua_parser.</p><p>Syntax: OsMinor(string_expression)</p>',
      example:
        "Example: OsMinor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '6'",
    },
  },
  {
    key: 'OsPatch',
    name: 'OsPatch',
    autocomplete: 'OsPatch',
    itemclass: 'keyword',
    description: 'Extract operating system patch/build number from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsPatch('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>OsPatch</h3>',
      body: '<p>Extract operating system patch/build number from user agent string, based on ua_parser.</p><p>Syntax: OsPatch(string_expression)</p>',
      example:
        "Example: OsPatch('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '8'",
    },
  },
  {
    key: 'OsPatchMinor',
    name: 'OsPatchMinor',
    autocomplete: 'OsPatchMinor',
    itemclass: 'keyword',
    description: 'Extract operating system patch minor version number from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsPatchMinor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>OsPatchMinor</h3>',
      body: '<p>Extract operating system patch minor version number from user agent string, based on ua_parser.</p><p>Syntax: OsPatchMinor(string_expression)</p>',
      example:
        "Example: OsPatchMinor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') Returns string. Returns null if Os patch minor number can't be extracted from string.",
    },
  },
  {
    key: 'OsVersion',
    name: 'OsVersion',
    autocomplete: 'OsVersion',
    itemclass: 'keyword',
    description: 'Extract operating system full version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "OsVersion('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>OsVersion</h3>',
      body: '<p>Extract operating system full version from user agent string, based on ua_parser.</p><p>Syntax: OsVersion(string_expression)</p>',
      example:
        "Example: OsVersion('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '10.6.8'",
    },
  },
  {
    key: 'BrowserFamily',
    name: 'BrowserFamily',
    autocomplete: 'BrowserFamily',
    itemclass: 'keyword',
    description: 'Extract browser family (name) from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "BrowserFamily('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>BrowserFamily</h3>',
      body: '<p>Extract browser family (name) from user agent string, based on ua_parser.</p><p>Syntax: BrowserFamily(string_expression)</p>',
      example:
        "Example: BrowserFamily('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns 'Chrome'",
    },
  },
  {
    key: 'BrowserFullName',
    name: 'BrowserFullName',
    autocomplete: 'BrowserFullName',
    itemclass: 'keyword',
    description: 'Extract browser family (name) and version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "BrowserFullName('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>BrowserFullName</h3>',
      body: '<p>Extract browser family (name) and version from user agent string, based on ua_parser.</p><p>Syntax: BrowserFullName(string_expression)</p>',
      example:
        "Example: BrowserFullName('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns 'Chrome 27.0.1453'",
    },
  },
  {
    key: 'BrowserMajor',
    name: 'BrowserMajor',
    autocomplete: 'BrowserMajor',
    itemclass: 'keyword',
    description: 'Extract browser major version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "BrowserMajor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>BrowserMajor</h3>',
      body: '<p>Extract browser major version from user agent string, based on ua_parser.</p><p>Syntax: BrowserMajor(string_expression)</p>',
      example:
        "Example: BrowserMajor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '27'",
    },
  },
  {
    key: 'BrowserMinor',
    name: 'BrowserMinor',
    autocomplete: 'BrowserMinor',
    itemclass: 'keyword',
    description: 'Extract browser minor version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "BrowserMinor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>BrowserMinor</h3>',
      body: '<p>Extract browser minor version from user agent string, based on ua_parser.<p>Syntax: BrowserMinor(string_expression)</p>',
      example:
        "Example: BrowserMinor('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '0'",
    },
  },
  {
    key: 'BrowserPatch',
    name: 'BrowserPatch',
    autocomplete: 'BrowserPatch',
    itemclass: 'keyword',
    description: 'Extract browser patch/build number from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "BrowserPatch('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>BrowserPatch</h3>',
      body: '<p>Extract browser patch/build number from user agent string, based on ua_parser.</p><p>Syntax: BrowserPatch(string_expression)</p>',
      example:
        "Example: BrowserPatch('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '1453'",
    },
  },
  {
    key: 'BrowserVersion',
    name: 'BrowserVersion',
    autocomplete: 'BrowserVersion',
    itemclass: 'keyword',
    description: 'Extract browser full version from user agent string, based on ua_parser',
    category: 'User Agent',
    console:
      "BrowserVersion('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36')",
    help: {
      header: '<h3>BrowserVersion</h3>',
      body: '<p>Extract browser full version from user agent string, based on ua_parser</p><p>Syntax: BrowserVersion(string_expression)</p>',
      example:
        "Example: BrowserVersion('Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_8) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.93 Safari/537.36') returns '27.0.1453'",
    },
  },
  {
    key: 'base64DecodeToString',
    name: 'Base64DecodeToString',
    autocomplete: 'Base64DecodeToString',
    itemclass: 'keyword',
    description: 'Decodes a Base64 string into a string',
    category: 'String',
    console: "Base64DecodeToString('aGVsbG8gd29ybGQ=', 'UTF-8')",
    help: {
      header: '<h3>Base64DecodeToString</h3>',
      body: '<p>Decodes a Base64 string into a string.</p><p>Syntax: Base64DecodeToString(stringToDecode[, charset])</p>',
      example: "Example: Base64DecodeToString('aGVsbG8gd29ybGQ=', 'UTF-8') returns 'hello world'",
    },
  },
  {
    key: 'base64decode',
    name: 'Base64Decode',
    autocomplete: 'Base64Decode',
    itemclass: 'keyword',
    description: 'Decodes a Base64 string into a bytearray',
    category: 'String',
    console: "Base64Decode('aGVsbG8gd29ybGQ=')",
    help: {
      header: '<h3>Base64Decode</h3>',
      body: '<p>Decodes a Base64 string into a bytearray.</p><p>Syntax: Base64Decode(stringToDecode)</p>',
      example: "Example: Base64Decode('aGVsbG8gd29ybGQ=') <br/>returns bytearray",
    },
  },
  {
    key: 'ceil',
    name: 'CEIL',
    autocomplete: 'CEIL',
    itemclass: 'keyword',
    description: 'Rounds the expression up to the nearest integer',
    category: 'Math',
    console: 'CEIL(1.3)',
    help: {
      header: '<h3>CEIL</h3>',
      body: '<p>Rounds the expression up to the nearest integer.</p><p>Syntax: CEIL(numeric_expression)</p>',
      example: 'Example: CEIL(1.3) returns 2<br/>CEIL(1.5) returns 2',
    },
  },
  {
    key: 'citynamefromip',
    name: 'CityNameFromIP',
    autocomplete: 'CityNameFromIP',
    itemclass: 'keyword',
    description: 'Extracts the city name from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "CityNameFromIP('69.59.196.211')",
    help: {
      header: '<h3>CityNameFromIP</h3>',
      body: '<p>Extracts the city name from a given IPv4 string (dot-decimal notation).</p><p>Syntax: CityNameFromIP(ipaddress)</p>',
      example: "Example: CityNameFromIP('69.59.196.211') returns 'Corvallis'",
    },
  },
  {
    key: 'concat',
    name: 'CONCAT',
    autocomplete: 'CONCAT',
    itemclass: 'keyword',
    description: 'Concatenates a variable number of strings and returns them as one string.',
    category: 'String',
    console: "CONCAT('username','@email.com')",
    help: {
      header: '<h3>CONCAT</h3>',
      body: '<p>Concatenates a variable number of strings and returns them as one string..</p><p>Syntax: CONCAT(string_expression1, string_expression2[…, string_expressionN])</p>',
      example: "Example: CONCAT('username','@email.com') returns 'username@xyzmail.com'",
    },
  },
  {
    key: 'countrycodefromip',
    name: 'CountryCodeFromIP',
    autocomplete: 'CountryCodeFromIP',
    itemclass: 'keyword',
    description: 'Extracts the country code (ISO-3166-1 alpha2) from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "CountryCodeFromIP('69.59.196.211')",
    help: {
      header: '<h3>CountryCodeFromIP</h3>',
      body: '<p>Extracts the country code (ISO-3166-1 alpha2) from a given IPv4 string (dot-decimal notation).</p><p>Syntax: CountryCodeFromIP(ipaddress)</p>',
      example: "Example: CountryCodeFromIP('69.59.196.211')<br/>returns 'US'",
    },
  },
  {
    key: 'countrynamefromip',
    name: 'CountryNameFromIP',
    autocomplete: 'CountryNameFromIP',
    itemclass: 'keyword',
    description: 'Extracts the country name from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "CountryNameFromIP('69.59.196.211')",
    help: {
      header: '<h3>CountryNameFromIP</h3>',
      body: '<p>Extracts the country name from a given IPv4 string (dot-decimal notation).</p><p>Syntax: CountryNameFromIP(ipaddress)</p>',
      example: "Example: CountryNameFromIP('69.59.196.211')<br/>returns 'United States'",
    },
  },
  {
    key: 'datediffdays',
    name: 'DateDiffDays',
    autocomplete: 'DateDiffDays',
    itemclass: 'keyword',
    description: 'Returns the number of days between the end_datetime and the start_datetime',
    category: 'ISO 8601 String',
    console: "DateDiffDays('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z')",
    help: {
      header: '<h3>DateDiffDays</h3>',
      body: '<p>Returns the number of days between the end_datetime and the start_datetime.</p><p>Syntax: DateDiffDays(end_datetime, start_datetime)</p>',
      example: "Example: DateDiffDays('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z') returns 341",
    },
  },
  {
    key: 'datediffhours',
    name: 'DateDiffHours',
    autocomplete: 'DateDiffHours',
    itemclass: 'keyword',
    description: 'Returns the number of hours between the end_datetime and the start_datetime',
    category: 'ISO 8601 String',
    console: "DateDiffHours('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z')",
    help: {
      header: '<h3>DateDiffHours</h3>',
      body: '<p>Returns the number of hours between the end_datetime and the start_datetime.</p><p>Syntax: DateDiffHours(end_datetime, start_datetime)</p>',
      example: "Example: DateDiffHours('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z') returns 8185",
    },
  },
  {
    key: 'datediffminutes',
    name: 'DateDiffMinutes',
    autocomplete: 'DateDiffMinutes',
    itemclass: 'keyword',
    description: 'Returns the number of minutes between the end_datetime and the start_datetime',
    category: 'ISO 8601 String',
    console: "DateDiffMinutes('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z')",
    help: {
      header: '<h3>DateDiffMinutes</h3>',
      body: '<p>Returns the number of minutes between the end_datetime and the start_datetime.</p><p>Syntax: DateDiffMinutes(end_datetime, start_datetime)</p>',
      example: "Example: DateDiffMinutes('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z') returns 491107",
    },
  },
  {
    key: 'datediffmonths',
    name: 'DateDiffMonths',
    autocomplete: 'DateDiffMonths',
    itemclass: 'keyword',
    description: 'Returns the number of months between the end_datetime and the start_datetime',
    category: 'ISO 8601 String',
    console: "DateDiffMonths('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z')",
    help: {
      header: '<h3>DateDiffMonths</h3>',
      body: '<p>Returns the number of months between the end_datetime and the start_datetime.</p><p>Syntax: DateDiffMonths(end_datetime, start_datetime)</p>',
      example: "Example: DateDiffMonths('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z') returns 11",
    },
  },
  {
    key: 'datediffseconds',
    name: 'DateDiffSeconds',
    autocomplete: 'DateDiffSeconds',
    itemclass: 'keyword',
    description: 'Returns the number of seconds between the end_datetime and the start_datetime',
    category: 'ISO 8601 String',
    console: "DateDiffSeconds('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z')",
    help: {
      header: '<h3>DateDiffSeconds</h3>',
      body: '<p>Returns the number of seconds between the end_datetime and the start_datetime.</p><p>Syntax: DateDiffSeconds(end_datetime, start_datetime)</p>',
      example: "Example: DateDiffSeconds('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z') returns 29466421",
    },
  },
  {
    key: 'datediffyears',
    name: 'DateDiffYears',
    autocomplete: 'DateDiffYears',
    itemclass: 'keyword',
    description: 'Returns the number of years between the end_datetime and the start_datetime',
    category: 'ISO 8601 String',
    console: "DateDiffYears('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z')",
    help: {
      header: '<h3>DateDiffYears</h3>',
      body: '<p>Returns the number of years between the end_datetime and the start_datetime.</p><p>Syntax: DateDiffYears(end_datetime, start_datetime)</p>',
      example: "Example: DateDiffYears('2009-01-07T01:07:01.000Z','2008-02-01T00:00:00.000Z') returns 0",
    },
  },
  {
    key: 'exp',
    name: 'EXP',
    autocomplete: 'EXP',
    itemclass: 'keyword',
    description: "Returns Euler's number e raised to the power of the expression",
    category: 'Math',
    console: 'EXP(1)',
    help: {
      header: '<h3>EXP</h3>',
      body: "<p>Returns Euler's number e raised to the power of the expression</p><p>Syntax: EXP(numeric_expression)</p>",
      example: "Example: EXP(1) returns Euler's number (2.71828...) If input is null, returns null.",
    },
  },
  {
    key: 'extractquerystringparam',
    name: 'ExtractQueryStringParam',
    autocomplete: 'ExtractQueryStringParam',
    itemclass: 'keyword',
    description: 'Extracts the value from a specified query string parameter for a url field',
    category: 'String',
    console: "ExtractQueryStringParam('http://someurl/?origin=135&origin=246,id=321', 'origin')",
    help: {
      header: '<h3>ExtractQueryStringParam</h3>',
      body: '<p>Extracts the value from a specified query string parameter for a url field.</p><p>Syntax: ExtractQueryStringParam(url, paramname)</p>',
      example:
        "Example: ExtractQueryStringParam('http://someurl/?origin=135&origin=246,id=321', 'origin') returns 135,246",
    },
  },
  {
    key: 'extractsearchquery',
    name: 'ExtractSearchQuery',
    autocomplete: 'ExtractSearchQuery',
    itemclass: 'keyword',
    description: 'Returns the search string from a search engine query url',
    category: 'String',
    console: "ExtractSearchQuery('https://www.google.com/search?q=Xplenty')",
    help: {
      header: '<h3>ExtractSearchQuery</h3>',
      body: '<p>Returns the search string from a search engine query url.</p><p>Syntax: ExtractSearchQuery(url)</p>',
      example: "Example: ExtractSearchQuery('https://www.google.com/search?q=Xplenty') returns 'Xplenty'",
    },
  },
  {
    key: 'extractuabrowserid',
    name: 'ExtractUABrowserID',
    autocomplete: 'ExtractUABrowserID',
    itemclass: 'keyword',
    description: 'Returns a browser ID for a given user agent string',
    category: 'User Agent',
    console: "ExtractUABrowserID('chrome/25')",
    help: {
      header: '<h3>ExtractUABrowserID</h3>',
      body: '<p>Returns a browser ID for a given user agent string.</p><p>Syntax: ExtractUABrowserID(useragentstring)</p>',
      example: "Example: ExtractUABrowserID('chrome/25') returns 325",
    },
  },
  {
    key: 'extractuabrowsername',
    name: 'ExtractUABrowserName',
    autocomplete: 'ExtractUABrowserName',
    itemclass: 'keyword',
    description: 'Returns a browser name for a given user agent string',
    category: 'User Agent',
    console: "ExtractUABrowserName('chrome/25')",
    help: {
      header: '<h3>ExtractUABrowserName</h3>',
      body: '<p>Returns a browser name for a given user agent string.</p><p>Syntax: ExtractUABrowserName(useragentstring)</p>',
      example: "Example: ExtractUABrowserName('chrome/25') returns Chrome",
    },
  },
  {
    key: 'floor',
    name: 'FLOOR',
    autocomplete: 'FLOOR',
    itemclass: 'keyword',
    description: 'Rounds the expression down to the nearest integer',
    category: 'Math',
    console: 'FLOOR(1.3)',
    help: {
      header: '<h3>FLOOR</h3>',
      body: '<p>Rounds the expression down to the nearest integer.</p><p>Syntax: FLOOR(numeric_expression)</p>',
      example: 'Example: FLOOR(1.3) returns 1<br/>FLOOR(1.5) returns 1',
    },
  },
  {
    key: 'indexof',
    name: 'INDEXOF',
    autocomplete: 'INDEXOF',
    itemclass: 'keyword',
    description:
      'Returns the position of the first occurrence of a search string in a string field, when searching forward from the start index',
    category: 'String',
    console: "INDEXOF('What wonderful weather in a wonderful world','wonderful',15)",
    help: {
      header: '<h3>INDEXOF</h3>',
      body: "<p>Returns the position of the first occurrence of a search string in a string field, when searching forward from the start index.</p><p>Syntax: INDEXOF(string_field, 'search_string', startIndex)</p>",
      example: "Example: INDEXOF('What wonderful weather in a wonderful world','wonderful',15) returns 28.",
    },
  },
  {
    key: 'isisodatetime',
    name: 'IsISODatetime',
    autocomplete: 'IsISODatetime',
    itemclass: 'keyword',
    description: 'Checks if the datetime_field is in ISO 8601 date time format',
    category: 'ISO 8601 String',
    console: "IsISODatetime('aksjhfkasjhfkjashf')",
    help: {
      header: '<h3>IsISODatetime</h3>',
      body: '<p>Checks if the datetime_field is in ISO 8601 date time format.</p><p>Syntax: IsISODatetime(datetime_field)</p>',
      example: "Example: IsISODatetime('aksjhfkasjhfkjashf') returns false",
    },
  },
  {
    key: 'isoaddduration',
    name: 'ISOAddDuration',
    autocomplete: 'ISOAddDuration',
    itemclass: 'keyword',
    description:
      'Adds the value represented by the duration field to the datetime_field.ISO8601 Duration Format: ISO_8601#Durations',
    category: 'ISO 8601 String',
    console: "ISOAddDuration('2007-03-05T03:05:03.000Z', 'P1D')",
    help: {
      header: '<h3>ISOAddDuration</h3>',
      body: '<p>Adds the value represented by the duration field to the datetime_field.<br/><br/>ISO8601 Duration Format: ISO_8601#Durations</p><p>Syntax: ISOAddDuration(datetime_field, duration)</p>',
      example: "Example: ISOAddDuration('2007-03-05T03:05:03.000Z', 'P1D') returns 2007-03-06T03:05:03.000Z",
    },
  },
  {
    key: 'isosubtractduration',
    name: 'ISOSubtractDuration',
    autocomplete: 'ISOSubtractDuration',
    itemclass: 'keyword',
    description:
      'Substracts the value represented by the duration field from the datetime_field.ISO8601 Duration Format: ISO_8601#Durations',
    category: 'ISO 8601 String',
    console: "ISOSubtractDuration('2007-03-05T03:05:03.000Z', 'P1D')",
    help: {
      header: '<h3>ISOSubtractDuration</h3>',
      body: '<p>Substracts the value represented by the duration field from the datetime_field.<br/><br/>ISO8601 Duration Format: ISO_8601#Durations</p><p>Syntax: ISOSubtractDuration(datetime_field, duration)</p>',
      example: "Example: ISOSubtractDuration('2007-03-05T03:05:03.000Z', 'P1D') returns 2007-03-04T03:05:03.000Z",
    },
  },
  {
    key: 'isotoday',
    name: 'ISOToDay',
    autocomplete: 'ISOToDay',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the day specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToDay('2009-01-07T01:07:01.000Z')",
    help: {
      header: '<h3>ISOToDay</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the day specified in the string.</p><p>Syntax: ISOToDay(datetime_field)</p>',
      example: "Example: ISOToDay('2009-01-07T01:07:01.000Z') returns 2009-01-07T00:00:00.000Z",
    },
  },
  {
    key: 'isotohour',
    name: 'ISOToHour',
    autocomplete: 'ISOToHour',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the hour specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToHour('2009-01-07T01:07:01.000Z')",
    help: {
      header: '<h3>ISOToHour</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the hour specified in the string.</p><p>Syntax: ISOToHour(datetime_field)</p>',
      example: "Example: ISOToHour('2009-01-07T01:07:01.000Z') returns 2009-01-07T01:00:00.000Z",
    },
  },
  {
    key: 'isotominute',
    name: 'ISOToMinute',
    autocomplete: 'ISOToMinute',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the minute specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToMinute('2009-01-07T01:07:01.000Z')",
    help: {
      header: '<h3>ISOToMinute</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the minute specified in the string.</p><p>Syntax: ISOToMinute(datetime_field)</p>',
      example: "Example: ISOToMinute('2009-01-07T01:07:01.000Z') returns 2009-01-07T01:07:00.000Z",
    },
  },
  {
    key: 'isotomonth',
    name: 'ISOToMonth',
    autocomplete: 'ISOToMonth',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the month specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToMonth('2009-01-07T01:07:01.000Z')",
    help: {
      header: '<h3>ISOToMonth</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the month specified in the string.</p><p>Syntax: ISOToMonth(datetime_field)</p>',
      example: "Example: ISOToMonth('2009-01-07T01:07:01.000Z') returns 2009-01-01T00:00:00.000Z",
    },
  },
  {
    key: 'isotosecond',
    name: 'ISOToSecond',
    autocomplete: 'ISOToSecond',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the second specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToSecond('2009-01-07T01:07:01.132Z')",
    help: {
      header: '<h3>ISOToSecond</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the second specified in the string.</p><p>Syntax: ISOToSecond(datetime_field)</p>',
      example: "Example: ISOToSecond('2009-01-07T01:07:01.132Z') returns 2009-01-07T01:07:01.000Z",
    },
  },
  {
    key: 'isotoweek',
    name: 'ISOToWeek',
    autocomplete: 'ISOToWeek',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the week (Monday) specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToWeek('2009-01-07T01:07:01.000Z')",
    help: {
      header: '<h3>ISOToWeek</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the week (Monday) specified in the string.</p><p>Syntax: ISOToWeek(datetime_field)</p>',
      example: "Example: ISOToWeek('2009-01-07T01:07:01.000Z') returns 2009-01-05T00:00:00.000Z",
    },
  },
  {
    key: 'isotoyear',
    name: 'ISOToYear',
    autocomplete: 'ISOToYear',
    itemclass: 'keyword',
    description: 'Resets the ISO datetime string to the beginning of the year specified in the string',
    category: 'ISO 8601 String',
    console: "ISOToYear('2009-01-07T01:07:01.000Z')",
    help: {
      header: '<h3>ISOToYear</h3>',
      body: '<p>Resets the ISO datetime string to the beginning of the year specified in the string.</p><p>Syntax: ISOToYear(datetime_field)</p>',
      example: "Example: ISOToYear('2009-01-07T01:07:01.000Z') returns 2009-01-01T00:00:00.000Z",
    },
  },
  {
    key: 'ISODatetimeToString',
    name: 'ISODatetimeToString',
    autocomplete: 'ISODatetimeToString',
    itemclass: 'keyword',
    description: 'Convert datetime string in ISO 8601 format to custom format datetime string',
    category: 'ISO 8601 String',
    console: "ISODatetimeToString('2013-10-17T00:00:00.000Z','MM-dd-yy')",
    help: {
      header: '<h3>ISODatetimeToString</h3>',
      body: '<p>Convert datetime string in ISO 8601 format to custom format datetime string.</p><p>Syntax: ISODatetimeToString(ISODatetimeString, formatString)</p>',
      example: "Example: ISODatetimeToString('2013-10-17T00:00:00.000Z','MM-dd-yy') returns 10-17-13",
    },
  },
  {
    key: 'TimestampFormatToISODatetime',
    name: 'TimestampFormatToISODatetime',
    autocomplete: 'TimestampFormatToISODatetime',
    itemclass: 'keyword',
    description: 'Convert datetime string in JDBC timestamp string format to ISO format',
    category: 'ISO 8601 String',
    console: "TimestampFormatToISODatetime('2013-10-17 23:59:54.432')",
    help: {
      header: '<h3>TimestampFormatToISODatetime</h3>',
      body: '<p>Convert datetime string in JDBC timestamp string format to ISO format.</p><p>Syntax: TimestampFormatToISODatetime(TimestampString)</p>',
      example: "Example: TimestampFormatToISODatetime('2013-10-17 23:59:54.432') returns 2013-10-17T23:59:54.432Z",
    },
  },
  {
    key: 'ISODatetimeToTimestampFormat',
    name: 'ISODatetimeToTimestampFormat',
    autocomplete: 'ISODatetimeToTimestampFormat',
    itemclass: 'keyword',
    description: 'Convert datetime string in ISO 8601 format to JDBC timestamp string format',
    category: 'ISO 8601 String',
    console: "ISODatetimeToTimestampFormat('2013-10-17T23:59:54.432Z')",
    help: {
      header: '<h3>ISODatetimeToTimestampFormat</h3>',
      body: '<p>Convert datetime string in ISO 8601 format to JDBC timestamp string format.</p><p>Syntax: ISODatetimeToTimestampFormat(ISODatetimeString)</p>',
      example: "Example: ISODatetimeToTimestampFormat('2013-10-17T23:59:54.432Z') returns 2013-10-17 23:59:54.432",
    },
  },
  {
    key: 'ToDate',
    name: 'ToDate',
    autocomplete: 'ToDate',
    itemclass: 'keyword',
    description: 'Convert datetime string to datetime data type',
    category: 'Cast & Parse',
    console: "ToDate('2013-10-17T23:59:54.432Z')",
    help: {
      header: '<h3>ToDate</h3>',
      body: '<p>Convert datetime string to datetime data type.</p><p>Syntax: ToDate(milliseconds) - milliseconds is the number of milliseconds from 1970-01-01T00:00:00.000Z</p><p>ToDate(ISOstring) - ISOstring is in the ISO 8601 format.</p><p>ToDate(customstring, format) - customstring contains datetime value in a string column in a custom format. format defines that format.</p><p>ToDate(customstring, format, timezone) -  customstring contains datetime value in a string column in a custom format. format defines that format. timezone contains timezone information in either UTC offset or location based format. The returned value is in the UTC offset format.</p>',
      example: "Example: ToDate('2013-10-17T23:59:54.432Z') returns datetime value 2013-10-17 23:59:54.432",
    },
  },
  {
    key: 'jsonstringtobag',
    name: 'JsonStringToBag',
    autocomplete: 'JsonStringToBag',
    itemclass: 'keyword',
    description: 'Converts a string field that contains a json array to a bag (array)',
    category: 'JSON',
    console: "JsonStringToBag('[1,2,3,4,5]')",
    help: {
      header: '<h3>JsonStringToBag</h3>',
      body: '<p>Converts a string field that contains a json array to a bag (array).</p><p>Syntax: JsonStringToBag(string_field)</p>',
      example: "Example: JsonStringToBag('[1,2,3,4,5]') returns a bag that contains the numbers 1,2,3,4,5 as items",
    },
  },
  {
    key: 'jsonstringtomap',
    name: 'JsonStringToMap',
    autocomplete: 'JsonStringToMap',
    itemclass: 'keyword',
    description:
      "Converts a string field that contains a Json object to a map (key:value pairs). From the map you can extract fields using the notation map#'fieldname'.",
    category: 'JSON',
    console:
      'JsonStringToMap(JsonStringToMap(\'{"userID": 1, "userName": {"lastName": "Doe", "firstName" : "John"} }\')#\'userName\')#\'lastName\'',
    help: {
      header: '<h3>JsonStringToMap</h3>',
      body: null,
      example:
        'Example: JsonStringToMap(\'{"userID": 1, "userName": {"lastName": "Doe", "firstName" : "John"} }\') returns a map with two key-value pairs:<br/>userID: 1 <br/>userName: {"lastName":"Doe","firstName":"John"}\n',
    },
  },
  {
    key: 'last_index_of',
    name: 'LAST_INDEX_OF',
    autocomplete: 'LAST_INDEX_OF',
    itemclass: 'keyword',
    description:
      'Returns the position of the last occurrence of a search string in a string field, when searching backward from the end of the string',
    category: 'String',
    console: "LAST_INDEX_OF('Ventura Beach,93009,CA',',')",
    help: {
      header: '<h3>LAST_INDEX_OF</h3>',
      body: "<p>Returns the position of the last occurrence of a search string in a string field, when searching backward from the end of the string.</p><p>Syntax: LAST_INDEX_OF(string_field, 'search_string')</p>",
      example: "Example: LAST_INDEX_OF('Ventura Beach,93009,CA',',') returns 19",
    },
  },
  {
    key: 'latitudefromip',
    name: 'LatitudeFromIP',
    autocomplete: 'LatitudeFromIP',
    itemclass: 'keyword',
    description: 'Extracts the latitude from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "LatitudeFromIP('69.59.196.211')",
    help: {
      header: '<h3>LatitudeFromIP</h3>',
      body: '<p>Extracts the latitude from a given IPv4 string (dot-decimal notation).</p><p>Syntax: LatitudeFromIP(ipaddress)</p>',
      example: "Example: LatitudeFromIP('69.59.196.211') returns 44.4698",
    },
  },
  {
    key: 'length',
    name: 'Length',
    autocomplete: 'Length',
    itemclass: 'keyword',
    description: 'Returns the number of characters in a string field',
    category: 'String',
    console: "Length('many words')",
    help: {
      header: '<h3>Length</h3>',
      body: '<p>Returns the number of characters in a string field.</p><p>Syntax: Length(string_field)</p>',
      example: "Example: Length('many words') returns 10",
    },
  },
  {
    key: 'longitudefromip',
    name: 'LongitudeFromIP',
    autocomplete: 'LongitudeFromIP',
    itemclass: 'keyword',
    description: 'Extracts the longitude from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "LongitudeFromIP('69.59.196.211')",
    help: {
      header: '<h3>LongitudeFromIP</h3>',
      body: '<p>Extracts the longitude from a given IPv4 string (dot-decimal notation).</p><p>Syntax: LatitudeFromIP(ipaddress)</p>',
      example: "Example: LongitudeFromIP('69.59.196.211') returns -123.343",
    },
  },
  {
    key: 'lower',
    name: 'LOWER',
    autocomplete: 'LOWER',
    itemclass: 'keyword',
    description: 'Converts the characters in a string_field to lower case',
    category: 'String',
    console: "LOWER('JohnDoe@email.COM')",
    help: {
      header: '<h3>LOWER</h3>',
      body: '<p>Converts the characters in a string_field to lower case.</p><p>Syntax: LOWER(string_field)</p>',
      example: "Example: LOWER('JohnDoe@email.COM') returns 'johndoe@email.com'",
    },
  },
  {
    key: 'parsedoubleordefault',
    name: 'ParseDoubleOrDefault',
    autocomplete: 'ParseDoubleOrDefault',
    itemclass: 'keyword',
    description:
      'Attempts to convert a string representation of a number to a double-precision numeric equivalent. If successful, returns the string as a double-precision numeric value, otherwise returns the default value.',
    category: 'Cast & Parse',
    console:
      "string1 = '635.1'\nParseDoubleOrDefault(string1,1.1)\nstring2 = 'not-numeric'\nParseDoubleOrDefault(string2,1.1)",
    help: {
      header: '<h3>ParseDoubleOrDefault</h3>',
      body: '<p>Attempts to convert a string representation of a number to a double-precision numeric equivalent. If successful, returns the string as a double-precision numeric value, otherwise returns the default value.</p><p>Syntax: ParseDoubleOrDefault(string,default_value)</p>',
      example: "Example: ParseDoubleOrDefault('635.1',1.1) returns 635.1",
    },
  },
  {
    key: 'parsefloatordefault',
    name: 'ParseFloatOrDefault',
    autocomplete: 'ParseFloatOrDefault',
    itemclass: 'keyword',
    description:
      'Attempts to convert a string representation of a number to a floating-point numeric equivalent. If successful, returns the string as a floating point numeric value, otherwise returns the default value.',
    category: 'Cast & Parse',
    console:
      "string1 = '0.551'\nParseFloatOrDefault(string1,1.1F)\nstring2 = 'not-numeric'\nParseFloatOrDefault(string2,1.1F)",
    help: {
      header: '<h3>ParseFloatOrDefault</h3>',
      body: '<p>Attempts to convert a string representation of a number to a floating-point numeric equivalent. If successful, returns the string as a floating point numeric value, otherwise returns the default value.</p><p>Syntax: ParseFloatOrDefault(string,default_value)</p>',
      example: "Example: ParseFloatOrDefault('0.551',1.1F) returns 0.551",
    },
  },
  {
    key: 'parseintordefault',
    name: 'ParseIntOrDefault',
    autocomplete: 'ParseIntOrDefault',
    itemclass: 'keyword',
    description:
      'Attempts to convert a string representation of a number to a integer numeric equivalent. If successful, returns the string as a integer numeric value, otherwise returns the default value.',
    category: 'Cast & Parse',
    console: "string1 = '2016'\nParseIntOrDefault(string1,42)\nstring2 = 'not-numeric'\nParseIntOrDefault(string2,42)",
    help: {
      header: '<h3>ParseIntOrDefault</h3>',
      body: '<p>Attempts to convert a string representation of a number to a integer numeric equivalent. If successful, returns the string as a integer numeric value, otherwise returns the default value.</p><p>Syntax: ParseIntOrDefault(string,default_value)</p>',
      example: "Example: ParseIntOrDefault('2016',42) returns 2016",
    },
  },
  {
    key: 'parselongordefault',
    name: 'ParseLongOrDefault',
    autocomplete: 'ParseLongOrDefault',
    itemclass: 'keyword',
    description:
      'Attempts to convert a string representation of a number to a long integer numeric equivalent. If successful, returns the string as a long integer value, otherwise returns the default value.',
    category: 'Cast & Parse',
    console:
      "string1 = '14607340342631'\nParseLongOrDefault(string1,42L)\nstring2 = 'not-numeric'\nParseLongOrDefault(string2,42L)",
    help: {
      header: '<h3>ParseLongOrDefault</h3>',
      body: '<p>Attempts to convert a string representation of a number to a long integer numeric equivalent. If successful, returns the string as a long integer value, otherwise returns the default value.</p><p>Syntax: ParseLongOrDefault(string_expression, default_value)</p>',
      example: "Example: ParseLongOrDefault('14607340342631',42L) returns 14607340342631",
    },
  },
  {
    key: 'random',
    name: 'RANDOM',
    autocomplete: 'RANDOM',
    itemclass: 'keyword',
    description: 'Returns a pseudo-random number between 0.0 and 1.0',
    category: 'Math',
    console: 'RANDOM()',
    help: {
      header: '<h3>RANDOM</h3>',
      body: '<p>Returns a pseudo-random number between 0.0 and 1.0</p><p>Syntax: RANDOM()</p>',
      example: "Example: RANDOM() - it's hard to tell what it returns",
    },
  },
  {
    key: 'pow',
    name: 'POW',
    autocomplete: 'POW',
    itemclass: 'keyword',
    description: 'Returns x raised to the power of y',
    category: 'Math',
    console: 'POW(2,3)',
    help: {
      header: '<h3>POW</h3>',
      body: '<p>Returns x raised to the power of y</p><p>Syntax: POW(x_numeric_expression, y_numeric_expression)</p>',
      example: 'Example: POW(2,3) returns 8',
    },
  },
  {
    key: 'uuid',
    name: 'UUID',
    autocomplete: 'UUID',
    itemclass: 'keyword',
    description: 'Returns a universally unique identifier (string)',
    category: 'Math',
    console: 'UUID()',
    help: {
      header: '<h3>UUID</h3>',
      body: '<p>Returns a universally unique identifier (string).</p><p>Syntax: UUID()</p>',
      example: "Example: UUID() - It's hard to tell what it will return",
    },
  },
  {
    key: 'coalesce',
    name: 'COALESCE',
    autocomplete: 'COALESCE',
    itemclass: 'keyword',
    description:
      'The COALESCE function returns the first of its arguments that is not null. Null is returned only if all arguments are null. It is often used to substitute a default value for null values when data is retrieved for display.',
    category: 'Logical',
    console: "COALESCE(null, null, 'cool!')",
    help: {
      header: '<h3>COALESCE</h3>',
      body: '<p>The COALESCE function returns the first of its arguments that is not null. Null is returned only if all arguments are null. It is often used to substitute a default value for null values when data is retrieved for display.</p><p>Syntax: COALESCE(value [, ...])</p>',
      example:
        "Example: COALESCE(description, short_description, '(none)') returns first non-null value ignoring the arguments right to it.",
    },
  },
  {
    key: 'regex_extract',
    name: 'REGEX_EXTRACT',
    autocomplete: 'REGEX_EXTRACT',
    itemclass: 'keyword',
    description: 'Returns the nth occurrence (specified by index) of a string that matches the regular expression',
    category: 'String',
    console: "REGEX_EXTRACT('213.131.343.135:5020', '(.*)\\\\:(.*)', 1)",
    help: {
      header: '<h3>REGEX_EXTRACT</h3>',
      body: '<p>Returns the nth occurrence (specified by index) of a string that matches the regular expression.</p><p>Syntax: REGEX_EXTRACT(string_field, regex, index)</p>',
      example: "Example: REGEX_EXTRACT('213.131.343.135:5020', '(.*)\\:(.*)', 1) returns '213.131.343.135'\n",
    },
  },
  {
    key: 'replace',
    name: 'REPLACE',
    autocomplete: 'REPLACE',
    itemclass: 'keyword',
    description: 'In a string field, replaces all occurrences of a string with a different string',
    category: 'String',
    console: "REPLACE('President John Smith','President','Ex-President')",
    help: {
      header: '<h3>REPLACE</h3>',
      body: "<p>In a string field, replaces all occurrences of a string with a different string.</p><p>Syntax: REPLACE(string_field, 'oldChar', 'newChar')</p>",
      example: "Example: REPLACE('President John Smith','President','Ex-President') returns 'ex-President John Smith'",
    },
  },
  {
    key: 'reverse',
    name: 'Reverse',
    autocomplete: 'Reverse',
    itemclass: 'keyword',
    description: 'Reverses the order of characters in a string field',
    category: 'String',
    console: "Reverse('abcd')",
    help: {
      header: '<h3>Reverse</h3>',
      body: '<p>Reverses the order of characters in a string field.</p><p>Syntax: Reverse(string_field)</p>',
      example: "Example: Reverse('abcd') returns 'dcba'",
    },
  },
  {
    key: 'round',
    name: 'ROUND',
    autocomplete: 'ROUND',
    itemclass: 'keyword',
    description: 'Rounds the expression up or down to the nearest integer according to standard math rules',
    category: 'Math',
    console: 'ROUND(1.3)',
    help: {
      header: '<h3>ROUND</h3>',
      body: '<p>Rounds the expression up or down to the nearest integer according to standard math rules.</p><p>Syntax: ROUND(numeric_expression)</p>',
      example: 'Example: ROUND(1.3) returns 1<br/>ROUND(1.5) returns 2',
    },
  },
  {
    key: 'stringtoisodatetime',
    name: 'StringToISODatetime',
    autocomplete: 'StringToISODatetime',
    itemclass: 'keyword',
    description:
      'Converts a non-ISO datetime string whose structure is defined by a format string into an ISO 8601 format',
    category: 'ISO 8601 String',
    console: "StringToISODatetime('10-17-13','MM-dd-yy')",
    help: {
      header: '<h3>StringToISODatetime</h3>',
      body: '<p>Converts a non-ISO datetime string whose structure is defined by a format string into an ISO 8601 format.</p><p>Syntax: StringToISODatetime(string_field,format)</p>',
      example: "Example: StringToISODatetime('10-17-13','MM-dd-yy') returns 2013-10-17T00:00:00.000Z",
    },
  },
  {
    key: 'substring',
    name: 'SUBSTRING',
    autocomplete: 'SUBSTRING',
    itemclass: 'keyword',
    description: 'Returns part of a string field',
    category: 'String',
    console: "SUBSTRING('California',0,3)",
    help: {
      header: '<h3>SUBSTRING</h3>',
      body: '<p>Returns part of a string field.</p><p>Syntax: SUBSTRING(string_field, startIndex, stopIndex)</p>',
      example: "Example: SUBSTRING('California',0,3) returns Cal",
    },
  },
  {
    key: 'trim',
    name: 'TRIM',
    autocomplete: 'TRIM',
    itemclass: 'keyword',
    description: 'Removes leading and trailing spaces from a string field',
    category: 'String',
    console: "TRIM('  John Smith   ')",
    help: {
      header: '<h3>TRIM</h3>',
      body: '<p>Removes leading and trailing spaces from a string field.</p><p>Syntax: TRIM(string_field)</p>',
      example: "Example: TRIM('  John Smith   ') returns 'John Smith'",
    },
  },
  {
    key: 'upper',
    name: 'UPPER',
    autocomplete: 'UPPER',
    itemclass: 'keyword',
    description: 'Converts the characters in a string_field to upper case',
    category: 'String',
    console: "UPPER('u.s.a.')",
    help: {
      header: '<h3>UPPER</h3>',
      body: '<p>Converts the characters in a string_field to upper case.</p><p>Syntax: UPPER(string_field)</p>',
      example: "Example: UPPER('u.s.a.') returns 'U.S.A.'",
    },
  },
  {
    key: 'urldecode',
    name: 'URLDecode',
    autocomplete: 'URLDecode',
    itemclass: 'keyword',
    description: 'Decodes a string field containing an encoded url',
    category: 'String',
    console: "URLDecode('a%20b%20c')",
    help: {
      header: '<h3>URLDecode</h3>',
      body: '<p>Decodes a string field containing an encoded url.</p><p>Syntax: URLDecode(string)</p>',
      example: "Example: URLDecode('a%20b%20c') returns 'a b c'",
    },
  },
  {
    key: 'querystringtomap',
    name: 'QueryStringToMap',
    autocomplete: 'QueryStringToMap',
    itemclass: 'keyword',
    description:
      "Converts a string field that contains a query string (...?key1=value1&key2=value2) to a map of key:value pairs. From the map you can extract fields using the notation map#'fieldname'.",
    category: 'String',
    console: "QueryStringToMap('http://someurl/?origin=135&origin=246,id=321')",
    help: {
      header: '<h3>QueryStringToMap</h3>',
      body: "<p>Converts a string field that contains a query string (...?key1=value1&key2=value2) to a map of key:value pairs. From the map you can extract fields using the notation map#'fieldname'.</p><p>Syntax: QueryStringToMap(string)</p>",
      example:
        "Example: QueryStringToMap('http://someurl/?origin=135&origin=246,id=321') returns [origin#'135,246',id#'321']",
    },
  },
  {
    key: 'tokenize',
    name: 'TOKENIZE',
    autocomplete: 'TOKENIZE',
    itemclass: 'keyword',
    description:
      "Generates a bag from delimited strings in string field. If you don't specify a single-character delimiter, tokenize will use any of the following characters:spacedouble-quotecommaparenthesisstar",
    category: 'String',
    console: "TOKENIZE('a,b,c', ',')",
    help: {
      header: '<h3>TOKENIZE</h3>',
      body: "<p>Generates a bag from delimited strings in string field. If you don't specify a single-character delimiter, tokenize will use any of the following characters:<ul><li>space</li><li>double-quote</li><li>comma</li><li>parenthesis</li><li>star</li></ul></p><p>Syntax: TOKENIZE(stringfield, [delimiter])</p>",
      example: "Example: TOKENIZE('a,b,c', ',') returns a bag containing a, b, c",
    },
  },
  {
    key: 'size',
    name: 'SIZE',
    autocomplete: 'SIZE',
    itemclass: 'keyword',
    description: 'Calculates the number of elements based on the data type',
    category: 'Structural',
    console: "SIZE('Australia')",
    help: {
      header: '<h3>SIZE</h3>',
      body: '<p>Calculates the number of elements based on the data type.</p><p>Syntax: SIZE(expression)</p>',
      example: "Example: SIZE('Australia') returns 9",
    },
  },
  {
    key: 'addduration',
    name: 'AddDuration',
    autocomplete: 'AddDuration',
    itemclass: 'keyword',
    description: 'Returns the result of a DateTime object plus a Duration object',
    category: 'Datetime',
    console: "AddDuration(ToDate('2013-09-10T08:02:31.667Z'),'PT3M')",
    help: {
      header: '<h3>AddDuration</h3>',
      body: '<p>Returns the result of a DateTime object plus a Duration object.</p><p>Syntax: AddDuration(datetime, duration)</p>',
      example: "Example: AddDuration(ToDate('2013-09-10T08:02:31.667Z'),'PT3M') returns 2013-09-10T08:05:31.667Z",
    },
  },
  {
    key: 'currenttime',
    name: 'CurrentTime',
    autocomplete: 'CurrentTime',
    itemclass: 'keyword',
    description:
      'Returns the datetime value when a job was submitted. Use ClockTime() to get a value per record in the set. ',
    category: 'Datetime',
    console: 'CurrentTime()',
    help: {
      header: '<h3>CurrentTime</h3>',
      body: '<p>Returns the datetime value when a job was submitted. Use ClockTime() to get a value per record in the set. </p><p>Syntax: CurrentTime()</p>',
      example: 'Example: CurrentTime() Returns the DateTime object of the current time.',
    },
  },
  {
    key: 'daysbetween',
    name: 'DaysBetween',
    autocomplete: 'DaysBetween',
    itemclass: 'keyword',
    description: 'Returns the number of days between two DateTime objects',
    category: 'Datetime',
    console: "DaysBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>DaysBetween</h3>',
      body: '<p>Returns the number of days between two DateTime objects.</p><p>Syntax: DaysBetween(datetime1, datetime2)</p>',
      example:
        "Example: DaysBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 555.",
    },
  },
  {
    key: 'getday',
    name: 'GetDay',
    autocomplete: 'GetDay',
    itemclass: 'keyword',
    description: 'Returns the day of a month from a DateTime object',
    category: 'Datetime',
    console: "GetDay(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetDay</h3>',
      body: '<p>Returns the day of a month from a DateTime object.</p><p>Syntax: GetDay(datetime)</p>',
      example: "Example: GetDay(ToDate('2013-09-10T08:02:31.667Z')) returns 10.",
    },
  },
  {
    key: 'gethour',
    name: 'GetHour',
    autocomplete: 'GetHour',
    itemclass: 'keyword',
    description: 'Returns the hour of a day from a DateTime object',
    category: 'Datetime',
    console: "GetHour(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetHour</h3>',
      body: '<p>Returns the hour of a day from a DateTime object.</p><p>Syntax: GetHour(datetime)</p>',
      example: "Example: GetHour(ToDate('2013-09-10T08:02:31.667Z')) returns 8.",
    },
  },
  {
    key: 'getmillisecond',
    name: 'GetMilliSecond',
    autocomplete: 'GetMilliSecond',
    itemclass: 'keyword',
    description: 'Returns the millisecond of a second from a DateTime object',
    category: 'Datetime',
    console: "GetMilliSecond(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetMilliSecond</h3>',
      body: '<p>Returns the millisecond of a second from a DateTime object.</p><p>Syntax: GetMilliSecond(datetime)</p>',
      example: "Example: GetMilliSecond(ToDate('2013-09-10T08:02:31.667Z')) returns 667.",
    },
  },
  {
    key: 'getminute',
    name: 'GetMinute',
    autocomplete: 'GetMinute',
    itemclass: 'keyword',
    description: 'Returns the minute of a hour from a DateTime object',
    category: 'Datetime',
    console: "GetMinute(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetMinute</h3>',
      body: '<p>Returns the minute of a hour from a DateTime object.</p><p>Syntax: GetMinute(datetime)</p>',
      example: "Example: GetMinute(ToDate('2013-09-10T08:02:31.667Z')) returns 2.",
    },
  },
  {
    key: 'getmonth',
    name: 'GetMonth',
    autocomplete: 'GetMonth',
    itemclass: 'keyword',
    description: 'Returns the month of a year from a DateTime object',
    category: 'Datetime',
    console: "GetMonth(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetMonth</h3>',
      body: '<p>Returns the month of a year from a DateTime object.</p><p>Syntax: GetMonth(datetime)</p>',
      example: "Example: GetMonth(ToDate('2013-09-10T08:02:31.667Z')) returns 9.",
    },
  },
  {
    key: 'getsecond',
    name: 'GetSecond',
    autocomplete: 'GetSecond',
    itemclass: 'keyword',
    description: 'Returns the second of a minute from a DateTime object',
    category: 'Datetime',
    console: "GetSecond(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetSecond</h3>',
      body: '<p>Returns the second of a minute from a DateTime object.</p><p>Syntax: GetSecond(datetime)</p>',
      example: "Example: GetSecond(ToDate('2013-09-10T08:02:31.667Z')) returns 31.",
    },
  },
  {
    key: 'getweek',
    name: 'GetWeek',
    autocomplete: 'GetWeek',
    itemclass: 'keyword',
    description: 'Returns the week of a week year from a DateTime object',
    category: 'Datetime',
    console: "GetWeek(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetWeek</h3>',
      body: '<p>Returns the week of a week year from a DateTime object.</p><p>Syntax: GetWeek(datetime)</p>',
      example: "Example: GetWeek(ToDate('2013-09-10T08:02:31.667Z')) returns 37.",
    },
  },
  {
    key: 'getweekyear',
    name: 'GetWeekYear',
    autocomplete: 'GetWeekYear',
    itemclass: 'keyword',
    description: 'Returns the week year from a DateTime object',
    category: 'Datetime',
    console: "GetWeekYear(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetWeekYear</h3>',
      body: '<p>Returns the week year from a DateTime object.</p><p>Syntax: GetWeekYear(datetime)</p>',
      example: "Example: GetWeekYear(ToDate('2013-09-10T08:02:31.667Z')) returns 2013.",
    },
  },
  {
    key: 'getyear',
    name: 'GetYear',
    autocomplete: 'GetYear',
    itemclass: 'keyword',
    description: 'Returns the year from a DateTime object',
    category: 'Datetime',
    console: "GetYear(ToDate('2013-09-10T08:02:31.667Z'))",
    help: {
      header: '<h3>GetYear</h3>',
      body: '<p>Returns the year from a DateTime object.</p><p>Syntax: GetYear(datetime)</p>',
      example: "Example: GetYear(ToDate('2013-09-10T08:02:31.667Z')) returns 2013.",
    },
  },
  {
    key: 'hoursbetween',
    name: 'HoursBetween',
    autocomplete: 'HoursBetween',
    itemclass: 'keyword',
    description: 'Returns the number of hours between two DateTime objects',
    category: 'Datetime',
    console: "HoursBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>HoursBetween</h3>',
      body: '<p>Returns the number of hours between two DateTime objects.</p><p>Syntax: HoursBetween(datetime1, datetime2)</p>',
      example:
        "Example: HoursBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 13338",
    },
  },
  {
    key: 'millisecondsbetween',
    name: 'MilliSecondsBetween',
    autocomplete: 'MilliSecondsBetween',
    itemclass: 'keyword',
    description: 'Returns the number of milliseconds between two DateTime objects',
    category: 'Datetime',
    console: "MilliSecondsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>MilliSecondsBetween</h3>',
      body: '<p>Returns the number of milliseconds between two DateTime objects.</p><p>Syntax: MilliSecondsBetween(datetime1, datetime2)</p>',
      example:
        "Example: MilliSecondsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 48018472019",
    },
  },
  {
    key: 'minutesbetween',
    name: 'MinutesBetween',
    autocomplete: 'MinutesBetween',
    itemclass: 'keyword',
    description: 'Returns the number of minutes between two DateTime objects',
    category: 'Datetime',
    console: "MinutesBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>MinutesBetween</h3>',
      body: '<p>Returns the number of minutes between two DateTime objects.</p><p>Syntax: MinutesBetween(datetime1, datetime2)</p>',
      example:
        "Example: MinutesBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 800307",
    },
  },
  {
    key: 'monthsbetween',
    name: 'MonthsBetween',
    autocomplete: 'MonthsBetween',
    itemclass: 'keyword',
    description: 'Returns the number of months between two DateTime objects',
    category: 'Datetime',
    console: "MonthsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>MonthsBetween</h3>',
      body: '<p>Returns the number of months between two DateTime objects.</p><p>Syntax: MonthsBetween(datetime1, datetime2)</p>',
      example:
        "Example: MonthsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 18",
    },
  },
  {
    key: 'secondsbetween',
    name: 'SecondsBetween',
    autocomplete: 'SecondsBetween',
    itemclass: 'keyword',
    description: 'Returns the number of seconds between two DateTime objects',
    category: 'Datetime',
    console: "SecondsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>SecondsBetween</h3>',
      body: '<p>Returns the number of seconds between two DateTime objects.</p><p>Syntax: SecondsBetween(datetime1, datetime2)</p>',
      example:
        "Example: SecondsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 48018472",
    },
  },
  {
    key: 'subtractduration',
    name: 'SubtractDuration',
    autocomplete: 'SubtractDuration',
    itemclass: 'keyword',
    description: 'Returns the result of a DateTime object minus a Duration object',
    category: 'Datetime',
    console: "SubtractDuration(ToDate('2013-09-10T08:02:31.667Z'),'PT3H')",
    help: {
      header: '<h3>SubtractDuration</h3>',
      body: '<p>Returns the result of a DateTime object minus a Duration object.</p><p>Syntax: SubtractDuration(datetime, duration)</p>',
      example: "Example: SubtractDuration(ToDate('2013-09-10T08:02:31.667Z'),'PT3H') returns 2013-09-10T05:02:31.667Z",
    },
  },
  {
    key: 'weeksbetween',
    name: 'WeeksBetween',
    autocomplete: 'WeeksBetween',
    itemclass: 'keyword',
    description: 'Returns the number of weeks between two DateTime objects',
    category: 'Datetime',
    console: "WeeksBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>WeeksBetween</h3>',
      body: '<p>Returns the number of weeks between two DateTime objects.</p><p>Syntax: WeeksBetween(datetime1, datetime2)</p>',
      example:
        "Example: WeeksBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 79",
    },
  },
  {
    key: 'yearsbetween',
    name: 'YearsBetween',
    autocomplete: 'YearsBetween',
    itemclass: 'keyword',
    description: 'Returns the number of years between two DateTime objects',
    category: 'Datetime',
    console: "YearsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z'))",
    help: {
      header: '<h3>YearsBetween</h3>',
      body: '<p>Returns the number of years between two DateTime objects.</p><p>Syntax: YearsBetween(datetime1, datetime2)</p>',
      example: "Example: YearsBetween(ToDate('2013-09-10T08:02:31.667Z'),ToDate('2012-03-03T13:34:39.648Z')) returns 1",
    },
  },
  {
    key: 'tostring',
    name: 'ToString',
    autocomplete: 'ToString',
    itemclass: 'keyword',
    description: 'Convert datetime field to ISO-8601 string or a custom date time format',
    category: 'Cast & Parse',
    console: "ToString(ToDate('2013-10-17T00:00:00.000Z'),'MM-dd-yy')",
    help: {
      header: '<h3>ToString</h3>',
      body: '<p>Convert datetime field to ISO-8601 string or a custom date time format.</p><p>Syntax: ToString(Datetime_field)<br>ToString(Datetime_field, formatString)<br><br>formatString - the output date time format pattern </p>',
      example: "Example: ToString(ToDate('2013-10-17T00:00:00.000Z'),'MM-dd-yy') returns 10-17-13.",
    },
  },
  {
    key: 'regionnamefromip',
    name: 'RegionNameFromIP',
    autocomplete: 'RegionNameFromIP',
    itemclass: 'keyword',
    description: 'Extracts the region or state (US), province (Canada) from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "RegionNameFromIP('69.59.196.211')",
    help: {
      header: '<h3>RegionNameFromIP</h3>',
      body: '<p>Extracts the region or state (US), province (Canada) from a given IPv4 string (dot-decimal notation).</p><p>Syntax: RegionNameFromIP(ipaddress)</p>',
      example: "Example: RegionNameFromIP('69.59.196.211')<br/>returns 'Virginia'",
    },
  },
  {
    key: 'regioncodefromip',
    name: 'RegionCodeFromIP',
    autocomplete: 'RegionCodeFromIP',
    itemclass: 'keyword',
    description:
      'Extracts a two character ISO-3166-2 or FIPS 10-4 code for the region or state (US), province (Canada) from a given IPv4 string (dot-decimal notation)',
    category: 'IP to Location',
    console: "RegionCodeFromIP('69.59.196.211')",
    help: {
      header: '<h3>RegionCodeFromIP</h3>',
      body: '<p>Extracts a two character ISO-3166-2 or FIPS 10-4 code for the region or state (US), province (Canada) from a given IPv4 string (dot-decimal notation).</p><p>Syntax: RegionCodeFromIP(ipaddress)</p>',
      example: "Example: RegionCodeFromIP('69.59.196.211')<br/>returns 'VA'",
    },
  },
  {
    key: 'jsonextract',
    name: 'JsonExtract',
    autocomplete: 'JsonExtract',
    itemclass: 'keyword',
    category: 'JSON',
    description:
      'Extracts the value referenced by JSONPath-like expression json_path from a given json string (a string containing JSON) and returns the result as a JSON string',
    console: 'JsonExtract(\'{ "store": { "book": "book1" } }\', \'$.store.book\')',
    help: {
      header: '<h3>JsonExtract</h3>',
      body: '<p>Extracts the value referenced by JSONPath-like expression json_path from a given json string (a string containing JSON) and returns the result as a JSON string.</p><p>Syntax: JsonExtract(json_string, json_path)</p>',
      example: 'Example: JsonExtract(\'{ "store": { "book": "book1" } }\', \'$.store.book\')<br/>returns \'"book1"\'\n',
    },
  },
  {
    key: 'jsonextractscalar',
    name: 'JsonExtractScalar',
    autocomplete: 'JsonExtractScalar',
    itemclass: 'keyword',
    category: 'JSON',
    description:
      'Extracts the value referenced by JSONPath-like expression json_path from a given json string (a string containing JSON) and returns the result as a string. The value referenced by json_path must be a scalar (boolean, numeric or string).',
    console: 'JsonExtractScalar(\'{ "store": { "book": "book1" } }\', \'$.store.book\')',
    help: {
      header: '<h3>JsonExtractScalar</h3>',
      body: '<p>Extracts the value referenced by JSONPath-like expression json_path from a given json string (a string containing JSON) and returns the result as a string. The value referenced by json_path must be a scalar (boolean, numeric or string).</p><p>Syntax: JsonExtractScalar(json_string, json_path)</p>',
      example:
        'Example: JsonExtractScalar(\'{ "store": { "book": "book1" } }\', \'$.store.book\')<br/>returns \'book1\'\n',
    },
  },
  {
    key: 'abs',
    name: 'ABS',
    autocomplete: 'ABS',
    itemclass: 'keyword',
    description: 'Returns the absolute value of a number',
    category: 'Math',
    console: 'ABS(-343)',
    help: {
      header: '<h3>ABS</h3>',
      body: '<p>Returns the absolute value of a number.</p><p>Syntax: ABS(numeric_expression)</p>',
      example: 'Example: ABS(-343)<br/>returns 343\n',
    },
  },
  {
    key: 'acos',
    name: 'ACOS',
    autocomplete: 'ACOS',
    itemclass: 'keyword',
    description: 'Returns the arc cosine of a number',
    category: 'Math',
    console: 'ACOS(1)',
    help: {
      header: '<h3>ACOS</h3>',
      body: '<p>Returns the arc cosine of a number.</p><p>Syntax: ACOS(numeric_expression)</p>',
      example: 'Example: ACOS(1)<br/>returns 0\n',
    },
  },
  {
    key: 'asin',
    name: 'ASIN',
    autocomplete: 'ASIN',
    itemclass: 'keyword',
    description: 'Returns the arc sine of a number',
    category: 'Math',
    console: 'ASIN(1)',
    help: {
      header: '<h3>ASIN</h3>',
      body: '<p>Returns the arc sine of a number.</p><p>Syntax: ASIN(numeric_expression)</p>',
      example: 'Example: ASIN(1)<br/>returns π/2.\n',
    },
  },
  {
    key: 'atan',
    name: 'ATAN',
    autocomplete: 'ATAN',
    itemclass: 'keyword',
    description: 'Returns the arc tangent of a number',
    category: 'Math',
    console: 'ATAN(1)',
    help: {
      header: '<h3>ATAN</h3>',
      body: '<p>Returns the arc tangent of a number.</p><p>Syntax: ATAN(numeric_expression)</p>',
      example: 'Example: ATAN(1)<br/>returns π/4\n',
    },
  },
  {
    key: 'cbrt',
    name: 'CBRT',
    autocomplete: 'CBRT',
    itemclass: 'keyword',
    description: 'Returns the cube root of a number',
    category: 'Math',
    console: 'CBRT(27)',
    help: {
      header: '<h3>CBRT</h3>',
      body: '<p>Returns the cube root of a number.</p><p>Syntax: CBRT(numeric_expression)</p>',
      example: 'Example: CBRT(27)<br/>returns 3\n',
    },
  },
  {
    key: 'cos',
    name: 'COS',
    autocomplete: 'COS',
    itemclass: 'keyword',
    description: 'Returns the trigonometric cosine of a number',
    category: 'Math',
    console: 'COS(0)',
    help: {
      header: '<h3>COS</h3>',
      body: '<p>Returns the trigonometric cosine of a number.</p><p>Syntax: COS(numeric_expression)</p>',
      example: 'Example: COS(0)<br/>returns 1\n',
    },
  },
  {
    key: 'cosh',
    name: 'COSH',
    autocomplete: 'COSH',
    itemclass: 'keyword',
    description: 'Returns the hyperbolic cosine of a number',
    category: 'Math',
    console: 'COSH(0)',
    help: {
      header: '<h3>COSH</h3>',
      body: '<p>Returns the hyperbolic cosine of a number.</p><p>Syntax: COSH(numeric_expression)</p>',
      example: 'Example: COSH(0)<br/>returns 1\n',
    },
  },
  {
    key: 'lcfirst',
    name: 'LCFIRST',
    autocomplete: 'LCFIRST',
    itemclass: 'keyword',
    description: 'Converts the first character in a string to lower case',
    category: 'String',
    console: "LCFIRST('HELLO')",
    help: {
      header: '<h3>LCFIRST</h3>',
      body: '<p>Converts the first character in a string to lower case.</p><p>Syntax: LCFIRST(string_expression)</p>',
      example: "Example: LCFIRST('HELLO')<br/>returns 'hELLO'\n",
    },
  },
  {
    key: 'log',
    name: 'LOG',
    autocomplete: 'LOG',
    itemclass: 'keyword',
    description: 'Returns the natural logarithm of a number',
    category: 'Math',
    console: 'LOG(100)',
    help: {
      header: '<h3>LOG</h3>',
      body: '<p>Returns the natural logarithm of a number.</p><p>Syntax: LOG(numeric_expression)</p>',
      example: 'Example: LOG(100)<br/>returns 4.605\n',
    },
  },
  {
    key: 'log10',
    name: 'LOG10',
    autocomplete: 'LOG10',
    itemclass: 'keyword',
    description: 'Returns the base 10 logarithm of a number',
    category: 'Math',
    console: 'LOG10(100)',
    help: {
      header: '<h3>LOG10</h3>',
      body: '<p>Returns the base 10 logarithm of a number.</p><p>Syntax: LOG10(numeric_expression)</p>',
      example: 'Example: LOG10(100)<br/>returns 2\n',
    },
  },
  {
    key: 'sin',
    name: 'SIN',
    autocomplete: 'SIN',
    itemclass: 'keyword',
    description: 'Returns the trigonometric sine of a number',
    category: 'Math',
    console: 'SIN(0)',
    help: {
      header: '<h3>SIN</h3>',
      body: '<p>Returns the trigonometric sine of a number.</p><p>Syntax: SIN(numeric_expression)</p>',
      example: 'Example: SIN(0)<br/>returns 0\n',
    },
  },
  {
    key: 'sinh',
    name: 'SINH',
    autocomplete: 'SINH',
    itemclass: 'keyword',
    description: 'Returns the hyperbolic sine of a number',
    category: 'Math',
    console: 'SINH(0)',
    help: {
      header: '<h3>SINH</h3>',
      body: '<p>Returns the hyperbolic sine of a number.</p><p>Syntax: SINH(numeric_expression)</p>',
      example: 'Example: SINH(0)<br/>returns 0\n',
    },
  },
  {
    key: 'sqrt',
    name: 'SQRT',
    autocomplete: 'SQRT',
    itemclass: 'keyword',
    description: 'Returns the square root of a number',
    category: 'Math',
    console: 'SQRT(16)',
    help: {
      header: '<h3>SQRT</h3>',
      body: '<p>Returns the square root of a number.</p><p>Syntax: SQRT(numeric_expression)</p>',
      example: 'Example: SQRT(16)<br/>returns 4\n',
    },
  },
  {
    key: 'strsplit',
    name: 'STRSPLIT',
    autocomplete: 'STRSPLIT',
    itemclass: 'keyword',
    description: 'Splits a string around matches of a delimiter',
    category: 'String',
    console: "STRSPLIT('Mary:had:a little:lamb',':',2)",
    help: {
      header: '<h3>STRSPLIT</h3>',
      body: "<p>Splits a string around matches of a delimiter.</p><p>Syntax: STRSPLIT(string_expression[, delimiter_regex[, limit]])<br/><br/>delimiter_regex - Optional regular expression with default '\\s'<br/>limit - numeric_expression - the number of times the regular expression pattern is applied. Optional with default 0.</p>",
      example: "Example: STRSPLIT('Mary:had:a little:lamb',':',2)<br/>returns (Mary,had,a little:lamb)\n",
    },
  },
  {
    key: 'tan',
    name: 'TAN',
    autocomplete: 'TAN',
    itemclass: 'keyword',
    description: 'Returns the trigonometric tangent of a number',
    category: 'Math',
    console: 'TAN(0)',
    help: {
      header: '<h3>TAN</h3>',
      body: '<p>Returns the trigonometric tangent of a number.</p><p>Syntax: TAN(numeric_expression)</p>',
      example: 'Example: TAN(0)<br/>returns 0\n',
    },
  },
  {
    key: 'tanh',
    name: 'TANH',
    autocomplete: 'TANH',
    itemclass: 'keyword',
    description: 'Returns the hyperbolic tangent of a number',
    category: 'Math',
    console: 'TANH(0)',
    help: {
      header: '<h3>TANH</h3>',
      body: '<p>Returns the hyperbolic tangent of a number.</p><p>Syntax: TANH(numeric_expression)</p>',
      example: 'Example: TANH(0)<br/>returns 0\n',
    },
  },
  {
    key: 'totuple',
    name: 'TOTUPLE',
    autocomplete: 'TOTUPLE',
    itemclass: 'keyword',
    description: 'Converts one or more expressions to type tuple',
    category: 'Complex Data Types',
    console: "TOTUPLE('Alan','64','1.88')",
    help: {
      header: '<h3>TOTUPLE</h3>',
      body: '<p>Converts one or more expressions to type tuple.</p><p>Syntax: TOTUPLE(expression[,expression...])</p>',
      example: "Example: TOTUPLE('Alan','64','1.88')<br/>returns (Alan,64,1.88)\n",
    },
  },
  {
    key: 'tobag',
    name: 'TOBAG',
    autocomplete: 'TOBAG',
    itemclass: 'keyword',
    description: 'Converts one or more expressions to type bag',
    category: 'Complex Data Types',
    console: "TOBAG('Alan','64','1.88')",
    help: {
      header: '<h3>TOBAG</h3>',
      body: '<p>Converts one or more expressions to type bag.</p><p>Syntax: TOBAG(expression[,expression...])</p>',
      example: "Example: TOBAG('Alan','64','1.88')<br/>returns {(Alan),(64),(1.88)}\n",
    },
  },
  {
    key: 'tomap',
    name: 'TOMAP',
    autocomplete: 'TOMAP',
    itemclass: 'keyword',
    description: 'Converts key/value expression pairs to type map',
    category: 'Complex Data Types',
    console: "TOMAP('Alan','64','Carol','63')",
    help: {
      header: '<h3>TOMAP</h3>',
      body: '<p>Converts key/value expression pairs to type map.</p><p>Syntax: TOMAP(key-expression,value-expression[,key-expression,value-expression...])</p>',
      example: "Example: TOMAP('Alan','64','Carol','63')<br/>returns [Alan#64,Carol#63]\n",
    },
  },
  {
    key: 'ucfirst',
    name: 'UCFIRST',
    autocomplete: 'UCFIRST',
    itemclass: 'keyword',
    description: 'Converts the first character in a string to upper case',
    category: 'String',
    console: "UCFIRST('hello')",
    help: {
      header: '<h3>UCFIRST</h3>',
      body: '<p>Converts the first character in a string to upper case.</p><p>Syntax: UCFIRST(string_expression)</p>',
      example: "Example: UCFIRST('hello')<br/>returns Hello\n",
    },
  },
  {
    key: 'extracturlpart',
    name: 'ExtractUrlPart',
    autocomplete: 'ExtractUrlPart',
    itemclass: 'keyword',
    description: 'Extracts a specified part from a url field',
    category: 'String',
    console:
      "url = 'http://someurl.com:80/path1/path2/file.html?origin=135#PORTION1'\nExtractUrlPart(url, 'protocol')\nExtractUrlPart(url, 'host')\nExtractUrlPart(url, 'port')\nExtractUrlPart(url, 'path')\nExtractUrlPart(url, 'query')\nExtractUrlPart(url, 'fragment')",
    help: {
      header: '<h3>ExtractUrlPart</h3>',
      body: '<p>Extracts a specified part from a url field.</p><p>Syntax: ExtractUrlPart(url, part)<br/><br/>part String - with these possible values to retrieve the corresponding component of the url:<br/>scheme or protocol returns protocol identifier<br/>host returns host name<br/>port returns port number<br/>path returns path<br/>query returns query<br/>ref or fragment returns reference</p>',
      example:
        "Example: ExtractUrlPart('http://someurl.com:80/path1/path2/file.html?origin=135#PORTION1', 'host')<br/>returns 'someurl.com'\n",
    },
  },
  {
    key: 'flatten',
    name: 'Flatten',
    autocomplete: 'Flatten',
    itemclass: 'keyword',
    description:
      'For each item in a bag, flatten creates a new record containing the item and the data of all the other fields in the component.',
    category: 'Structural',
    help: {
      header: '<h3>Flatten</h3>',
      body: '<p>For each item in a bag, flatten creates a new record containing the item and the data of all the other fields in the component.</p><p>Syntax: Flatten(bag)</p>',
      example: "Example: Flatten(Tokenize('a,b,c',',')) returns 3 records.\n",
    },
  },
  {
    key: 'isbase64',
    name: 'IsBase64',
    autocomplete: 'IsBase64',
    itemclass: 'keyword',
    description: 'Checks if the string_expression is Base64 encoded.',
    category: 'String',
    console: "string_expression = 'eHBsZW50eS5jb20gLSBIYXNzbGUgRnJlZSBIYWRvb3A='\nIsBase64(string_expression)",
    help: {
      header: '<h3>IsBase64</h3>',
      body: '<p>Checks if the string_expression is Base64 encoded.</p><p>Syntax: IsBase64(bag)</p>',
      example: "Example: IsBase64('eHBsZW50eS5jb20gLSBIYXNzbGUgRnJlZSBIYWRvb3A=') returns true.\n",
    },
  },
  {
    key: 'md5',
    name: 'MD5',
    autocomplete: 'MD5',
    itemclass: 'keyword',
    description: 'Calculates the MD5 hash of a string.',
    category: 'Hash',
    console: "MD5('hello world')",
    help: {
      header: '<h3>MD5</h3>',
      body: '<p>Calculates the MD5 hash of a string.</p><p>Syntax: MD5(string)</p>',
      example: "Example: MD5('hello world') returns 5eb63bbbe01eeed093cb22bb8f5acdc3\n",
    },
  },
  {
    key: 'switchtimezone',
    name: 'SwitchTimeZone',
    autocomplete: 'SwitchTimeZone',
    itemclass: 'keyword',
    description:
      "Changes a datetime value's time zone from the value's original time zone to the time zone given in tz_string and returns the value.",
    category: 'Datetime',
    console: "SwitchTimeZone(ToDate('2014-11-01T23:30:00+02:00'),'America/Chicago')",
    help: {
      header: '<h3>SwitchTimeZone</h3>',
      body: "<p>Changes a datetime value's time zone from the value's original time zone to the time zone given in tz_string and returns the value.</p><p>Syntax: SwitchTimeZone(datetime_value, tz_value)</p>",
      example:
        "Example: SwitchTimeZone(ToDate('2014-11-01T23:30:00+02:00'),'America/Chicago') returns 2014-11-01T15:30:00-06:00\n",
    },
  },
  {
    key: 'curl',
    name: 'Curl',
    autocomplete: 'Curl',
    itemclass: 'keyword',
    description:
      'Makes a REST API call and returns the response received from the server. Requests are made either anonymous or with basic authentication.',
    category: 'Util',
    console:
      "res = Curl('http://itsthisforthat.com/api.php?json', 'GET')\nres#'status'\nres#'headers'\nbody = res#'body'\nthis = JsonExtractScalar(body,'$.this')\nthat = JsonExtractScalar(body,'$.that')\nCONCAT('it\\'s ', this, ' for ', that)",
    help: {
      header: '<h3>Curl</h3>',
      body: '<p>Makes a REST API call and returns the response received from the server. Requests are made either anonymous or with basic authentication.</p><p>Syntax: Curl(url, method, headers, request_body, username, password)</p>',
      example:
        "Example: Curl('http://api.myapp.com/users/','POST','{\"Accept\":\"text/json\"}','{\"name\":\"John\",\"age\":34}','myuser','mypass')<br/>\n  Curl('http://api.myapp.com/users/','GET') <br/>\n  status - response code (integer)<br/>\n  body - the body of the response (string)<br/>\n  headers - map object of response headers\n",
    },
  },
  {
    key: 'distinmileshaversine',
    name: 'DistInMilesHaversine',
    autocomplete: 'DistInMilesHaversine',
    itemclass: 'keyword',
    description:
      'Computes the distance (in miles) between two latitude-longitude pairs using the Haversine formula, base on Apache DataFu.',
    category: 'Geolocation',
    console: 'DistInMilesHaversine(48.8567,-2.3508,51.5072,0.1275)',
    help: {
      header: '<h3>DistInMilesHaversine</h3>',
      body: '<p>Computes the distance (in miles) between two latitude-longitude pairs using the Haversine formula, base on Apache DataFu.</p><p>Syntax: DistInMilesHaversine(lat1, long1, lat2, long2)</p>',
      example: 'Example: DistInMilesHaversine(48.8567,-2.3508,51.5072,0.1275) returns 213.41919465445437\n',
    },
  },
  {
    key: 'urlencode',
    name: 'URLEncode',
    autocomplete: 'URLEncode',
    itemclass: 'keyword',
    description: 'The function URL-encodes a given string.',
    category: 'String',
    console: "URLEncode('a b c')",
    help: {
      header: '<h3>URLEncode</h3>',
      body: '<p>The function URL-encodes a given string.</p><p>Syntax: URLEncode(string)</p>',
      example: "Example: URLEncode('a b c') returns 'a+b+c'",
    },
  },
  {
    key: 'tomilliseconds',
    name: 'ToMilliSeconds',
    autocomplete: 'ToMilliSeconds',
    itemclass: 'keyword',
    description: 'Returns the number of milliseconds elapsed since 1970-01-01T00:00:00Z for a datetime value.',
    category: 'Datetime',
    console: "ToMilliSeconds(ToDate('2015-02-04T13:27:23.950Z'))",
    help: {
      header: '<h3>ToMilliSeconds</h3>',
      body: '<p>Returns the number of milliseconds elapsed since 1970-01-01T00:00:00Z for a datetime value.</p><p>Syntax: ToMilliSeconds(datetime_value)﻿</p>',
      example: "ToMilliSeconds(ToDate('2015-02-04T13:27:23.950Z')) returns ﻿1423056443950",
    },
  },
  {
    key: 'tounixtime',
    name: 'ToUnixTime',
    autocomplete: 'ToUnixTime',
    itemclass: 'keyword',
    description: 'Returns the Unix Time for a datetime value.',
    category: 'Datetime',
    console: "ToUnixTime(ToDate('2014-11-01T23:30:00+02:00'))",
    help: {
      header: '<h3>ToUnixTime</h3>',
      body: '<p>Returns the Unix Time for a datetime value.</p><p>Syntax: ToMilliSeconds(datetime_value)﻿</p>',
      example: "ToUnixTime(ToDate('2014-11-01T23:30:00+02:00')) returns 1414877400﻿",
    },
  },
  {
    key: 'todatecustomformats',
    name: 'ToDateCustomFormats',
    autocomplete: 'ToDateCustomFormats',
    itemclass: 'keyword',
    description: 'Convert input that matches one of multiple formats to datetime data type.',
    category: 'Cast & Parse',
    console: "ToDateCustomFormats('1987-01-11 19:04', 'yyyy-MM-dd','yyyy-MM-dd HH:mm','yyyy-MM-dd HH:mm:ss')",
    help: {
      header: '<h3>ToDateCustomFormats</h3>',
      body: '<p>Convert input that matches one of multiple formats to datetime data type.</p><p>Syntax: ToDateCustomFormats(custom_string, format1 [, format2] [, ... , formatN])﻿</p>',
      example:
        "ToDateCustomFormats('1987-01-11 19:04', 'yyyy-MM-dd','yyyy-MM-dd HH:mm','yyyy-MM-dd HH:mm:ss') returns 1987-01-11T19:04:00Z",
    },
  },
  {
    key: 'maptobag',
    name: 'MapToBag',
    autocomplete: 'MapToBag',
    itemclass: 'keyword',
    description: 'Transforms a hash map to a bag of hash maps where each map contains two keys: key and value.',
    category: 'Complex Data Types',
    console: "MapToBag(TOMAP('a',1,'b',2,'c',3))",
    help: {
      header: '<h3>MapToBag</h3>',
      body: '<p>Transforms a hash map to a bag of hash maps where each map contains two keys: key and value.</p><p>Syntax: MapToBag(map_expression)</p>',
      example:
        "MapToBag(TOMAP('a',1,'b',2,'c',3)) returns a bag with the following items: [key#a,value#1],[key#b,value#2],[key#c,value#3],[key#c,value#3]",
    },
  },
  {
    key: 'regex_find_all',
    name: 'REGEX_FIND_ALL',
    autocomplete: 'REGEX_FIND_ALL',
    itemclass: 'keyword',
    description: 'Returns all occurrences of strings that match a regular expression in a bag.',
    category: 'String',
    console: "REGEX_FIND_ALL('abcdefg','[a-d]')",
    help: {
      header: '<h3>REGEX_FIND_ALL</h3>',
      body: '<p>Returns all occurrences of strings that match a regular expression in a bag.</p><p>REGEX_FIND_ALL(string_expression, regExp)</p>',
      example: "Example: REGEX_FIND_ALL('abcdefg','[a-d]') returns a bag with the following items: a,b,c,d.\n",
    },
  },
  {
    key: 'round_to',
    name: 'ROUND_TO',
    autocomplete: 'ROUND_TO',
    itemclass: 'keyword',
    description: 'Rounds the expression to a fixed number of decimal digits.',
    category: 'Math',
    console: 'ROUND_TO(3.14151976,3)',
    help: {
      header: '<h3>ROUND_TO</h3>',
      body: '<p>Rounds the expression to a fixed number of decimal digits.</p><p>Syntax: ROUND_TO(numeric_expression, digits [, mode])</p>',
      example:
        'Example: ROUND_TO(3.14151976,3) returns 3.141 <br/>ROUND_TO(42.1,-1) returns 40.0<br/>ROUND_TO(3.5,0,4) returns 4.0 ',
    },
  },
  {
    key: 'intervalintersection',
    name: 'IntervalIntersection',
    autocomplete: 'IntervalIntersection',
    itemclass: 'keyword',
    description: 'Returns the duration in milliseconds of the overlap between all provided intervals.',
    category: 'Datetime',
    console:
      "start1 = ToDate('2013-09-10T08:00:00.000Z')\nend1 = AddDuration(start1,'PT10M')\nstart2 = ToDate('2013-09-10T08:05:00.000Z')\nend2 = AddDuration(start2,'PT10M')\nIntervalIntersection(start1, end1, start2, end2)",
    help: {
      header: '<h3>IntervalIntersection</h3>',
      body: '<p>Returns the duration in milliseconds of the overlap between all provided intervals.</p><p>Syntax: IntervalIntersection(interval1_start, interval1_end[, interval2_start, interval2_end[, ...]])</p>',
      example:
        "Example: IntervalIntersection(ToDate('2013-09-10T08:00:00.000Z'), AddDuration(ToDate('2013-09-10T08:00:00.000Z'),'PT10M'), ToDate('2013-09-10T08:05:00.000Z'), AddDuration(ToDate('2013-09-10T08:05:00.000Z'),'PT10M'))﻿returns 300000 ",
    },
  },
  {
    key: 'xpath',
    name: 'XPath',
    autocomplete: 'XPath',
    itemclass: 'keyword',
    description: 'Extracts part of an XML document using an XPath expression.',
    category: 'XML',
    console: "XPath('<customer><firstname>John</firstname><lastname>Doe</lastname></customer>','/customer/firstname')",
    help: {
      header: '<h3>XPath</h3>',
      body: '<p>Extracts part of an XML document using an XPath expression.</p><p>Syntax: XPath(xml_string, xpath_string[, cache_xml_doc[, namespaces]])</p>',
      example:
        'Example: XPath(&apos;&lt;customer&gt;&lt;firstname&gt;John&lt;/firstname&gt;&lt;lastname&gt;Doe&lt;/lastname&gt;&lt;/customer&gt;&apos;,&apos;/customer/firstname&apos;) returns &lt;firstname&gt;John&lt;/firstname&gt;',
    },
  },
  {
    key: 'xpathtobag',
    name: 'XPathToBag',
    autocomplete: 'XPathToBag',
    itemclass: 'keyword',
    description: 'Extracts part of an XML document using an XPath expression and returns a bag of all matches.',
    category: 'XML',
    console: 'XPathToBag(\'<products><p id="1"/><p id="2"/></products>\',\'/products/p/@id\')',
    help: {
      header: '<h3>XPathToBag</h3>',
      body: '<p>Extracts part of an XML document using an XPath expression and returns a bag of all matches.</p><p>Syntax: XPathToBag(xml_string, xpath_string[, cache_xml_doc[, namespaces]])</p>',
      example:
        'Example: XPathToBag(&apos;&lt;products&gt;&lt;p id=&quot;1&quot;/&gt;&lt;p id=&quot;2&quot;/&gt;&lt;/products&gt;&apos;,&apos;/products/p/@id&apos;) returns a bag with two items: 1 and 2.\n',
    },
  },
  {
    key: 'base64encodetostring',
    name: 'Base64EncodeToString',
    autocomplete: 'Base64EncodeToString',
    itemclass: 'keyword',
    description: 'Encodes a byte array argument into a string using Base64 encoding scheme.',
    category: 'Cast & Parse',
    console: "Base64EncodeToString(GetBytes('hello world'))",
    help: {
      header: '<h3>Base64EncodeToString</h3>',
      body: '<p>Encodes a byte array argument into a string using Base64 encoding scheme.</p><p>Syntax: Base64EncodeToString(bytearray_expression[, chunked])</p>',
      example: "Example: Base64EncodeToString(GetBytes('hello world')) returns 'aGVsbG8gd29ybGQ='.\n",
    },
  },
  {
    key: 'base64encode',
    name: 'Base64Encode',
    autocomplete: 'Base64Encode',
    itemclass: 'keyword',
    description: 'Encodes a byte array argument into a Base64 byte array.',
    category: 'String',
    console: "Base64Encode(GetBytes('hello world'))",
    help: {
      header: '<h3>Base64EncodeToString</h3>',
      body: '<p>Encodes a byte array argument into a Base64 byte array.</p><p>Syntax: Base64Encode(bytearray_expression[, chunked])</p>',
      example:
        "Example: Base64Encode(GetBytes('hello world')) returns the byte array of the Base 64 encoded string 'aGVsbG8gd29ybGQ='.\n",
    },
  },
  {
    key: 'getbytes',
    name: 'GetBytes',
    autocomplete: 'GetBytes',
    itemclass: 'keyword',
    description: 'Returns a byte array for a string input.',
    category: 'Cast & Parse',
    console: "GetBytes('hello world')",
    help: {
      header: '<h3>GetBytes</h3>',
      body: '<p>Returns a byte array for a string input.</p><p>Syntax: GetBytes(string_expression[, charset_expression])</p>',
      example: "Example: GetBytes('hello world') returns the byte array of the UTF-8 string 'hello world'\n",
    },
  },
  {
    key: 'sprintf',
    name: 'SPRINTF',
    autocomplete: 'SPRINTF',
    itemclass: 'keyword',
    description: 'Uses printf-style template to formats a set of values using the native Java formatter library.',
    category: 'String',
    console: "SPRINTF('%d is a %s',50,'number')",
    help: {
      header: '<h3>SPRINTF</h3>',
      body: '<p>Uses printf-style template to formats a set of values using the native Java formatter library.</p><p>Syntax: SPRINTF(format_expression[, val1] […, valN])</p>',
      example: "Example: SPRINTF('%d is a %s',50,'number') returns '50 is a number'.\n",
    },
  },
  {
    key: 'sha1',
    name: 'SHA1',
    autocomplete: 'SHA1',
    itemclass: 'keyword',
    description: 'Calculates the SHA1 hash of a string.',
    category: 'Hash',
    console: "SHA1('hello world')",
    help: {
      header: '<h3>SHA1</h3>',
      body: '<p>Calculates the SHA1 hash of a string.</p><p>Syntax: SHA1(string)</p>',
      example: "Example: SHA1('hello world') returns 2aae6c35c94fcfb415dbe95f408b9ce91ee846ed\n",
    },
  },
  {
    key: 'executesqllong',
    name: 'ExecuteSqlLong',
    autocomplete: 'ExecuteSqlLong',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar long value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console:
      "ExecuteSqlLong('mysql_55','SELECT MAX(epochtime) FROM events')\nExecuteSqlLong('bq_58', 'SELECT MAX(epochtime) FROM events','{\"useQueryCache\" : false}')",
    help: {
      header: '<h3>ExecuteSqlLong</h3>',
      body: '<p>Execute a SQL query that returns a scalar long value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlLong(connection_id, sql_query[, param1] [,…paramN])</p>',
      example:
        "Examples:\nExecuteSqlLong('mysql_55','SELECT MAX(epochtime) FROM events')\nExecuteSqlLong('bq_58', 'SELECT MAX(epochtime) FROM events','{\"useQueryCache\" : false}')\n",
    },
  },
  {
    key: 'executesqlfloat',
    name: 'ExecuteSqlFloat',
    autocomplete: 'ExecuteSqlFloat',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar float value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console: "ExecuteSqlFloat('mysql_55','SELECT price FROM products WHERE product_id = ?', product_id)",
    help: {
      header: '<h3>ExecuteSqlFloat</h3>',
      body: '<p>Execute a SQL query that returns a scalar float value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlFloat(connection_id, sql_query[, param1] [,…paramN])</p>',
      example: "Example: ExecuteSqlFloat('mysql_55','SELECT price FROM products WHERE product_id = ?', product_id)\n",
    },
  },
  {
    key: 'executesqldouble',
    name: 'ExecuteSqlDouble',
    autocomplete: 'ExecuteSqlDouble',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar double value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console:
      "ExecuteSqlDouble('mysql_55','SELECT price FROM products WHERE product_id = ?', product_id)\nExecuteSqlDouble('bq_58', 'SELECT SUM(total) FROM transactions','{\"useQueryCache\" : false}')",
    help: {
      header: '<h3>ExecuteSqlDouble</h3>',
      body: '<p>Execute a SQL query that returns a scalar double value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlDouble(connection_id, sql_query[, param1] [,…paramN])</p>',
      example:
        "Examples:\nExecuteSqlDouble('mysql_55','SELECT price FROM products WHERE product_id = ?', product_id)\nExecuteSqlDouble('bq_58', 'SELECT SUM(total) FROM transactions','{\"useQueryCache\" : false}')\n",
    },
  },
  {
    key: 'executesqldatetime',
    name: 'ExecuteSqlDateTime',
    autocomplete: 'ExecuteSqlDateTime',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar datetime value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console:
      "ExecuteSqlDateTime('mysql_55','SELECT MAX(updated_at) FROM events')\nExecuteSqlDateTime('mysql_55','SELECT birthdate FROM customers WHERE id = ?',user_id)\nExecuteSqlDateTime('bq_58', 'SELECT MAX(updated_at) FROM events','{\"useQueryCache\" : false}')",
    help: {
      header: '<h3>ExecuteSqlDateTime</h3>',
      body: '<p>Execute a SQL query that returns a scalar datetime value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlDateTime(connection_id, sql_query[, param1] [,…paramN])</p>',
      example:
        "Examples:\nExecuteSqlDateTime('mysql_55','SELECT MAX(updated_at) FROM events')\nExecuteSqlDateTime('bq_58', 'SELECT MAX(updated_at) FROM events','{\"useQueryCache\" : false}')\n",
    },
  },
  {
    key: 'executesqlint',
    name: 'ExecuteSqlInt',
    autocomplete: 'ExecuteSqlInt',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar integer value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console:
      "ExecuteSqlInt('mysql_55','SELECT MAX(event_id) FROM events')\nExecuteSqlInt('mysql_55','SELECT age FROM customers WHERE id = ?',user_id)",
    help: {
      header: '<h3>ExecuteSqlInt</h3>',
      body: '<p>Execute a SQL query that returns a scalar integer value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlInt(connection_id, sql_query[, param1] [,…paramN])</p>',
      example: "Example: ExecuteSqlInt('mysql_55','SELECT age FROM customers WHERE id = ?',user_id)\n",
    },
  },
  {
    key: 'executesqlstring',
    name: 'ExecuteSqlString',
    autocomplete: 'ExecuteSqlString',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar string value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console:
      "ExecuteSqlString('mysql_55','SELECT name FROM users WHERE user_id = ?',user_id)\nExecuteSqlString('bq_58', 'SELECT name FROM users ORDER BY rating DESC LIMIT 1;','{\"useQueryCache\" : false}')",
    help: {
      header: '<h3>ExecuteSqlString</h3>',
      body: '<p>Execute a SQL query that returns a scalar string value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlString(connection_id, sql_query[, param1] [,…paramN])</p>',
      example:
        "Examples: \nExecuteSqlString('mysql_55','SELECT name FROM users WHERE user_id = ?',user_id)\nExecuteSqlString('bq_58', 'SELECT name FROM users ORDER BY rating DESC LIMIT 1;','{\"useQueryCache\" : false}')\n",
    },
  },
  {
    key: 'executesqlboolean',
    name: 'ExecuteSqlBoolean',
    autocomplete: 'ExecuteSqlBoolean',
    itemclass: 'keyword',
    description:
      'Execute a SQL query that returns a scalar boolean value. If the query returns a dataset, the value of the first column in the first row is returned.',
    category: 'Query',
    console: "ExecuteSqlBoolean('mysql_55','SELECT IsActive FROM users WHERE user_id = ?',user_id)",
    help: {
      header: '<h3>ExecuteSqlBoolean</h3>',
      body: '<p>Execute a SQL query that returns a scalar boolean value. If the query returns a dataset, the value of the first column in the first row is returned.</p><p>Syntax: ExecuteSqlBoolean(connection_id, sql_query[, param1] [,…paramN])</p>',
      example: "Example: ExecuteSqlBoolean('mysql_55','SELECT IsActive FROM users WHERE user_id = ?',user_id)\n",
    },
  },
  {
    key: 'curlwithpagination',
    name: 'CurlWithPagination',
    autocomplete: 'CurlWithPagination',
    itemclass: 'keyword',
    description:
      'Makes one or more REST API calls and returns the responses received from all the pages requested from the server. Requests are made either anonymous or with basic authentication.',
    category: 'Util',
    console: "CurlWithPagination('https://api.github.com/repos/xplenty/xplenty-api-doc-v2/events','GET')",
    help: {
      header: '<h3>CurlWithPagination</h3>',
      body: '<p>Makes one or more REST API calls and returns the responses received from all the pages requested from the server. Requests are made either anonymous or with basic authentication.</p><p>Syntax: CurlWithPagination(url, method[, headers[, request_body[, username[, password[, strategy[, sleep_interval]]]]]])</p>',
      example: "Example: CurlWithPagination('https://api.github.com/repos/xplenty/xplenty-api-doc-v2/events','GET')\n",
    },
  },
  {
    key: 'tohexstring',
    name: 'ToHexString',
    autocomplete: 'ToHexString',
    itemclass: 'keyword',
    description: 'Returns a string representation of the integer argument in base 16.',
    category: 'Cast & Parse',
    console: 'ToHexString(123123)',
    help: {
      header: '<h3>ToHexString</h3>',
      body: '<p>Returns a string representation of the integer argument in base 16.</p><p>Syntax: ToHexString(int_expression)<br>int_expression - integer (or long) argument </p>',
      example: 'ToHexString(123123) returns 1E0F3.',
    },
  },
  {
    key: 'executesqlnonquery',
    name: 'ExecuteSqlNonQuery',
    autocomplete: 'ExecuteSqlNonQuery',
    itemclass: 'keyword',
    description:
      'Execute a SQL statement or a batch of SQL statements that modify the data or the schema (DML or DDL).',
    category: 'Query',
    console:
      "ExecuteSqlNonQuery('mysql_55','INSERT INTO events (description) VALUES (?)',event_data)\nExecuteSqlNonQuery('bq_58', 'INSERT INTO events_stg (id, ts, edata) SELECT * FROM events_old WHERE ts < \\'2017-01-01\\';','{\"useQueryCache\" : false}')",
    help: {
      header: '<h3>ExecuteSqlNonQuery</h3>',
      body: '<p>Execute a SQL statement or a batch of SQL statements that modify the data or the schema (DML or DDL).</p><p>Syntax: ExecuteSqlNonQuery(connection_id, sql_statement[, param1] [,…paramN])</p>',
      example:
        "Examples: \nExecuteSqlNonQuery('mysql_55','INSERT INTO events (description) VALUES (?)',event_data)\nExecuteSqlNonQuery('bq_58', 'INSERT INTO events_stg (id, ts, edata) SELECT * FROM events_old WHERE ts < \\'2017-01-01\\';','{\"useQueryCache\" : false}')\n",
    },
  },
  {
    key: 'sleep',
    name: 'Sleep',
    autocomplete: 'Sleep',
    itemclass: 'keyword',
    description: 'Causes the process to sleep for a specified number of milliseconds.',
    category: 'Util',
    console: 'Sleep(1000)',
    help: {
      header: '<h3>Sleep</h3>',
      body: '<p>Causes the process to sleep for a specified number of milliseconds.</p><p>Syntax: Sleep(long_expression)</p>',
      example: 'Example: Sleep(1000)\n',
    },
  },
  {
    key: 'maptoquerystring',
    name: 'MapToQueryString',
    autocomplete: 'MapToQueryString',
    itemclass: 'keyword',
    description: 'Converts a map to a URL query string (key1=value1&key2=value2)',
    category: 'Complex Data Types',
    console: "MapToQueryString(TOMAP('k1','v1','k2','v2'))",
    help: {
      header: '<h3>MapToQueryString</h3>',
      body: '<p>Converts a map to a URL query string (key1=value1&key2=value2)</p><p>Syntax: MapToQueryString(map_expression)</p>',
      example: "Example: MapToQueryString(TOMAP('k1','v1','k2','v2')) returns k1=v1&k2=v2",
    },
  },
  {
    key: 'sha256',
    name: 'SHA256',
    autocomplete: 'SHA256',
    itemclass: 'keyword',
    description: 'Calculates the SHA256 hash of a string.',
    category: 'Hash',
    console: "SHA256('hello world')",
    help: {
      header: '<h3>SHA256</h3>',
      body: '<p>Calculates the SHA256 hash of a string.</p><p>Syntax: SHA256(string)</p>',
      example:
        "Example: SHA256('hello world') returns b94d27b9934d3e08a52e52d7da7dabfac484efe37a5380ee9088f7ace2efcde9\n",
    },
  },
  {
    key: 'sha384',
    name: 'SHA384',
    autocomplete: 'SHA384',
    itemclass: 'keyword',
    description: 'Calculates the SHA384 hash of a string.',
    category: 'Hash',
    console: "SHA384('hello world')",
    help: {
      header: '<h3>SHA384</h3>',
      body: '<p>Calculates the SHA384 hash of a string.</p><p>Syntax: SHA384(string)</p>',
      example:
        "Example: SHA384('hello world') returns fdbd8e75a67f29f701a4e040385e2e23986303ea10239211af907fcbb83578b3e417cb71ce646efd0819dd8c088de1bd\n",
    },
  },
  {
    key: 'sha512',
    name: 'SHA512',
    autocomplete: 'SHA512',
    itemclass: 'keyword',
    description: 'Calculates the SHA512 hash of a string.',
    category: 'Hash',
    console: "SHA512('hello world')",
    help: {
      header: '<h3>SHA512</h3>',
      body: '<p>Calculates the SHA512 hash of a string.</p><p>Syntax: SHA512(string)</p>',
      example:
        "Example: SHA512('hello world') returns 309ecc489c12d6eb4cc40f50c902f2b4d0ed77ee511a7c7a9bcd3ca86d4cd86f989dd35bc5ff499670da34255b45b0cfd830e81f605dcf7dc5542e93ae9cd76f\n",
    },
  },
  {
    key: 'md2',
    name: 'MD2',
    autocomplete: 'MD2',
    itemclass: 'keyword',
    description: 'Calculates the MD2 hash of a string.',
    category: 'Hash',
    console: "MD2('hello world')",
    help: {
      header: '<h3>MD2</h3>',
      body: '<p>Calculates the MD2 hash of a string.</p><p>Syntax: MD2(string)</p>',
      example: "Example: MD2('hello world') returns d9cce882ee690a5c1ce70beff3a78c77\n",
    },
  },
  {
    key: 'bagtostring',
    name: 'BagToString',
    autocomplete: 'BagToString',
    itemclass: 'keyword',
    description: "Concatenates a bag into a string. If the delimiter argument is omitted, the character '_' is used.",
    category: 'Complex Data Types',
    console: "BagToString(JsonStringToBag('[1,2,3]'),';')",
    help: {
      header: '<h3>BagToString</h3>',
      body: "<p>Concatenates a bag into a string. If the delimiter argument is omitted, the character '_' is used.</p><p>Syntax: BagToString(bag_expression[, delimiter_string_expression])</p>",
      example: "Example: BagToString(JsonStringToBag('[1,2,3]'),';') returns 1;2;3\n",
    },
  },
  {
    key: 'strsplittobag',
    name: 'STRSPLITTOBAG',
    autocomplete: 'STRSPLITTOBAG',
    itemclass: 'keyword',
    description: 'Splits a string into a bag around matches of a delimiter.',
    category: 'String',
    console: "STRSPLITTOBAG('Mary:had:a little:lamb',':',3)",
    help: {
      header: '<h3>STRSPLITTOBAG</h3>',
      body: '<p>Splits a string into a bag around matches of a delimiter.</p><p>Syntax: STRSPLITTOBAG(string[, delimiter_regex[, limit]])</p>',
      example: "Example: STRSPLITTOBAG('Mary:had:a little:lamb',':',3) returns a bag {Mary,had,a little:lamb}\n",
    },
  },
  {
    key: 'regex_extract_all',
    name: 'REGEX_EXTRACT_ALL',
    autocomplete: 'REGEX_EXTRACT_ALL',
    itemclass: 'keyword',
    description: 'Returns a tuple with all matched groups. If there are no matches, an empty tuple is returned.',
    category: 'String',
    console: "REGEX_EXTRACT_ALL('213.131.343.135:5020', '(.*)\\\\:(.*)')",
    help: {
      header: '<h3>REGEX_EXTRACT_ALL</h3>',
      body: '<p>Returns a tuple with all matched groups. If there are no matches, an empty tuple is returned.</p><p>Syntax: REGEX_EXTRACT_ALL(string_expression, regex)</p>',
      example:
        "Example: REGEX_EXTRACT_ALL('213.131.343.135:5020', '(.*)\\\\:(.*)') returns a tuple ('213.131.343.135','5020')\n",
    },
  },
  {
    key: 'bagtotuple',
    name: 'BagToTuple',
    autocomplete: 'BagToTuple',
    itemclass: 'keyword',
    description:
      'Flattens a bag into a tuple. Once the bag is flattened into a tuple, items can be extracted by referencing tuple items.',
    category: 'Complex Data Types',
    console: "t = BagToTuple(JsonStringToBag('[1,2,3]'))\nt.$0",
    help: {
      header: '<h3>BagToTuple</h3>',
      body: '<p>Flattens a bag into a tuple. Once the bag is flattened into a tuple, items can be extracted by referencing tuple items.</p><p>Syntax: BagToTuple(bag_expression)</p>',
      example: "Example: BagToTuple(JsonStringToBag('[1,2,3]')) returns tuple.\n",
    },
  },
  {
    key: 'clocktime',
    name: 'ClockTime',
    autocomplete: 'ClockTime',
    itemclass: 'keyword',
    description: 'For each record, returns the datetime value when the function is called.',
    category: 'Datetime',
    console: 't = ClockTime()',
    help: {
      header: '<h3>ClockTime</h3>',
      body: '<p>For each record, returns the datetime value when the function is called.</p><p>Syntax: ClockTime()</p>',
      example: 'Example: ClockTime() returns current time.\n',
    },
  },
  {
    key: 'parsedate',
    name: 'ParseDate',
    autocomplete: 'ParseDate',
    itemclass: 'keyword',
    description: 'Convert input datetime string to datetime data type, using the first format that matches the string.',
    category: 'Datetime',
    console: "t = ParseDate('2015-05-02','dd/MM/yyyy','yyyy-MM-dd')",
    help: {
      header: '<h3>ParseDate</h3>',
      body: '<p>Convert input datetime string to datetime data type, using the first format that matches the string.</p><p>Syntax: ParseDate(datetime_string, format_string_1, ..., format_string_n)</p>',
      example:
        "Example: ParseDate('2015-05-02','dd/MM/yyyy','yyyy-MM-dd') returns a datetime expression 2015-05-02T00:00:00Z\n",
    },
  },
  {
    key: 'executebqinsert',
    name: 'ExecuteBQInsert',
    autocomplete: 'ExecuteBQInsert',
    itemclass: 'keyword',
    description: 'Starts a query job in Google BigQuery and writes the query results to a destination table.',
    category: 'Query',
    console:
      "ExecuteBQInsert('bq_58', 'SELECT * FROM events_old WHERE ts < \\'2017-01-01\\';','{\"destinationTable\" : {\"tableId\" : \"events_stg\"}}')",
    help: {
      header: '<h3>ExecuteBQInsert</h3>',
      body: '<p>Starts a query job in Google BigQuery and writes the query results to a destination table.</p><p>Syntax: ExecuteBQInsert(connection_id, sql_query, configuration_json)</p>',
      example:
        "Example: ExecuteBQInsert('bq_58', 'SELECT * FROM events_old WHERE ts < \\'2017-01-01\\';','{\"destinationTable\" : {\"tableId\" : \"events_stg\"}}')\n",
    },
  },
  {
    key: 'sha256withrsa',
    name: 'SHA256WithRSA',
    autocomplete: 'SHA256WithRSA',
    itemclass: 'keyword',
    description:
      'Sign an input string with an RSA private key using the SHA256withRSA signature algorithm. Useful with JWT authentication.',
    category: 'Hash',
    console:
      "SHA256WithRSA('my string', '-----BEGIN PRIVATE KEY-----\\nMIIBVQIBADANBgkqhkiG9w0BAQEFAASCAT8wggE7AgEAAkEA0SC5BIYpanOv6wSm\\ndHVVMRa+6iw/0aJpT9/LKcZ0XYQ43P9Vwn8c46MDvFJ+Uy41FwbxT+QpXBoLlp8D\\nsJY/dQIDAQABAkAesoL2GwtxSNIF2YTli2OZ9RDJJv2nNAPpaZxU4YCrST1AXGPB\\ntFm0LjYDDlGJ448syKRpdypAyCR2LidwrVRxAiEA+YU5Zv7bOwODCsmtQtIfBfhu\\n6SMBGMDijK7OYfTtjQsCIQDWjvly6b6doVMdNjqqTsnA8J1ShjSb8bFXkMels941\\nfwIhAL4Rr7I3PMRtXmrfSa325U7k+Yd59KHofCpyFiAkNLgVAiB8JdR+wnOSQAOY\\nloVRgC9LXa6aTp9oUGxeD58F6VK9PwIhAIDhSxkrIatXw+dxelt8DY0bEdDbYzky\\nr9nicR5wDy2W\\n-----END PRIVATE KEY-----')",
    help: {
      header: '<h3>SHA256WithRSA</h3>',
      body: '<p>Sign an input string with an RSA private key using the SHA256withRSA signature algorithm. Useful with JWT authentication.</p><p>Syntax: SHA256WithRSA(input_string, key)</p>',
      example:
        "Example: SSHA256WithRSA('my string', '-----BEGIN PRIVATE KEY-----\\n...\\n-----END PRIVATE KEY-----')\n",
    },
  },
  {
    key: 'isempty',
    name: 'IsEmpty',
    autocomplete: 'IsEmpty',
    itemclass: 'keyword',
    description:
      'Returns true if the input map or bag is empty, false if the input contains items, or null if the input is null.',
    category: 'Complex Data Types',
    console: "IsEmpty(JsonStringToBag('[1,2,3]'))\nIsEmpty(JsonStringToBag('[]'))\nIsEmpty(JsonStringToBag(''))",
    help: {
      header: '<h3>IsEmpty</h3>',
      body: '<p>Returns true if the input map or bag is empty, false if the input contains items, or null if the input is null.</p><p>Syntax: IsEmpty(input_arg)</p>',
      example: "IsEmpty(JsonStringToBag('[1,2,3]')) returns false",
    },
  },
  {
    key: 'ccurl',
    name: 'CCurl',
    autocomplete: 'CCurl',
    itemclass: 'keyword',
    description:
      'Makes a REST API call and returns the response received from the server. Requests are made either anonymous or with basic authentication.',
    category: 'Util',
    console:
      'res = CCurl(\'http://api.myapp.com/users/\',\'POST\',\'{"Accept":"text/json"}\',\'{"name":"John","age":34}\',\'myapp_83\')',
    help: {
      header: '<h3>Curl</h3>',
      body: '<p>Makes a REST API call and returns the response received from the server. Requests are made either anonymous or with basic authentication.</p><p>Syntax: CCurl(url, [method, [headers, [request_body, [connection_id]]]])</p>',
      example:
        'Examples: CCurl(\'http://api.myapp.com/users/\',\'POST\',\'{"Accept":"text/json"}\',\'{"name":"John","age":34}\',\'myapp_83\')<br/>\n',
    },
  },
  {
    key: 'ccurlwithpagination',
    name: 'CCurlWithPagination',
    autocomplete: 'CCurlWithPagination',
    itemclass: 'keyword',
    description:
      'Makes one or more REST API calls and returns the responses received from all the pages requested from the server. Requests are authenticated using an Xplenty connection.',
    category: 'Util',
    console:
      "CCurlWithPagination('https://api.github.com/repos/xplenty/xplenty-api-doc-v2/events','GET','','','gitub_86')",
    help: {
      header: '<h3>CurlWithPagination</h3>',
      body: '<p>Makes one or more REST API calls and returns the responses received from all the pages requested from the server. Requests are authenticated using an Xplenty connection.</p><p>Syntax: CCurlWithPagination(url, method[, headers[, request_body[, connection_id[, strategy[, sleep_interval[, max_pages]]]]]])</p>',
      example:
        "Example: CCurlWithPagination('https://api.github.com/repos/xplenty/xplenty-api-doc-v2/events','GET','','','gitub_86')\n",
    },
  },
  {
    key: 'bytearraytostring',
    name: 'ByteArrayToString',
    autocomplete: 'ByteArrayToString',
    itemclass: 'keyword',
    description: 'Converts a byte array to a string based on the given encoding.',
    category: 'Cast & Parse',
    console: "ByteArrayToString(GetBytes('Hello World','UTF-8'),'UTF-8')",
    help: {
      header: '<h3>ByteArrayToString</h3>',
      body: '<p>Converts a byte array to a string based on the given encoding.</p><p>Syntax: ByteArrayToString(byte_array[, charset_expression])</p>',
      example: "Example: ByteArrayToString(GetBytes('Hello World','UTF-16'),'UTF-8') returns 'Hello World'\n",
    },
  },
  {
    key: 'binarycurl',
    name: 'BinaryCurl',
    autocomplete: 'BinaryCurl',
    itemclass: 'keyword',
    description:
      'Makes a REST API call and returns the binary response received from the server. Requests are made either anonymous or with basic authentication.',
    category: 'Util',
    console:
      "res = BinaryCurl('http://itsthisforthat.com/api.php?json', 'GET')\nres#'status'\nres#'headers'\nbody = ByteArrayToString(res#'body','UTF-8')\nthis = JsonExtractScalar(body,'$.this')\nthat = JsonExtractScalar(body,'$.that')\nCONCAT('it\\'s ', this, ' for ', that)",
    help: {
      header: '<h3>BinaryCurl</h3>',
      body: '<p>Makes a REST API call and returns the binary response received from the server. Requests are made either anonymous or with basic authentication.</p><p>Syntax: BinaryCurl(url, method[, headers[, request_body[, username[, password]]]])</p>',
      example:
        "Example: BinaryCurl('http://api.myapp.com/users/','POST','{\"Accept\":\"text/json\"}','{\"name\":\"John\",\"age\":34}','myuser','mypass')<br/>\n  BinaryCurl('http://api.myapp.com/users/','GET') <br/>\n  status - response code (integer)<br/>\n  body - the body of the response (bytearray)<br/>\n  headers - map object of response headers\n",
    },
  },
  {
    key: 'binaryccurl',
    name: 'BinaryCCurl',
    autocomplete: 'BinaryCCurl',
    itemclass: 'keyword',
    description:
      'Makes a REST API call and returns the binary response received from the server. Requests are authenticated using an Xplenty connection.',
    category: 'Util',
    console:
      "res = BinaryCCurl('http://itsthisforthat.com/api.php?json', 'GET')\nres#'status'\nres#'headers'\nbody = ByteArrayToString(res#'body','UTF-8')\nthis = JsonExtractScalar(body,'$.this')\nthat = JsonExtractScalar(body,'$.that')\nCONCAT('it\\'s ', this, ' for ', that)",
    help: {
      header: '<h3>BinaryCCurl</h3>',
      body: '<p>Makes a REST API call and returns the binary response received from the server. Requests are authenticated using an Xplenty connection.</p><p>Syntax: BinaryCCurl(url, [method, [headers, [request_body, [connection_id]]]])</p>',
      example:
        "Example: BinaryCCurl('http://api.myapp.com/users/','POST','{\"Accept\":\"text/json\"}','{\"name\":\"John\",\"age\":34}','myuser','mypass')<br/>\n  status - response code (integer)<br/>\n  body - the body of the response (bytearray)<br/>\n  headers - map object of response headers\n",
    },
  },
  {
    key: 'csvsplit',
    name: 'CSVSPLIT',
    autocomplete: 'CSVSPLIT',
    itemclass: 'keyword',
    description: 'Splits a CSV string into a tuple around matches of a field delimiter.',
    category: 'String',
    console: "CSVSPLIT('\"Mary,had\",a,little,lamb',',','\"')",
    help: {
      header: '<h3>CSVSPLIT</h3>',
      body: '<p>Splits a CSV string into a tuple around matches of a field delimiter.</p><p>Syntax: CSVSPLIT(csv_record, field_delimiter, [string_qualifier])</p>',
      example: "Example: CSVSPLIT('Mary,had,a,little,lamb',',')<br/>returns (Mary,had,a,little,lamb)\n",
    },
  },
  {
    key: 'continentnamefromip',
    name: 'ContinentNameFromIP',
    autocomplete: 'ContinentNameFromIP',
    itemclass: 'keyword',
    description: 'Extracts the continent name from a given IPv4 string (dot-decimal notation).',
    category: 'IP to Location',
    console: "ContinentNameFromIP('69.59.196.211')",
    help: {
      header: '<h3>ContinentNameFromIP</h3>',
      body: '<p>Extracts the continent name from a given IPv4 string (dot-decimal notation)..</p><p>Syntax: ContinentNameFromIP(ip_address)</p>',
      example: "Example: ContinentNameFromIP('69.59.196.211')<br/>returns 'North America'",
    },
  },
  {
    key: 'continentcodefromip',
    name: 'ContinentCodeFromIP',
    autocomplete: 'ContinentCodeFromIP',
    itemclass: 'keyword',
    description: 'Extracts the continent code from a given IPv4 string (dot-decimal notation).',
    category: 'IP to Location',
    console: "ContinentCodeFromIP('69.59.196.211')",
    help: {
      header: '<h3>ContinentCodeFromIP</h3>',
      body: '<p>Extracts the continent code from a given IPv4 string (dot-decimal notation)..</p><p>Syntax: ContinentCodeFromIP(ip_address)</p>',
      example: "Example: ContinentCodeFromIP('69.59.196.211')<br/>returns 'NA'",
    },
  },
  {
    key: 'tojson',
    name: 'ToJson',
    autocomplete: 'ToJson',
    itemclass: 'keyword',
    description: 'Returns the input as a JSON string.',
    category: 'JSON',
    console: "ToJson(TOMAP('a',1,'b','c'), false)",
    help: {
      header: '<h3>ToJson</h3>',
      body: '<p>Returns the input as a JSON string..</p><p>Syntax: ToJson(input[, try_parse])</p>',
      example: "Example: ToJson(TOMAP('a',1,'b','c'), false)<br/>returns '{\"a\":1,\"b\":\"c\"}'\n",
    },
  },
  {
    key: 'curlpoll',
    name: 'CurlPoll',
    autocomplete: 'CurlPoll',
    itemclass: 'keyword',
    description:
      "Use this function to make a REST API request continuously until a regular expression is matched or timeout is reached. You'll find it useful with asynchronous API calls that require polling for a status / response URL.",
    category: 'Util',
    console:
      "res = CurlPoll('\"this\"', 1000, 20000, 'http://itsthisforthat.com/api.php?json', 'GET')\nres#'status'\nres#'headers'\nbody = res#'body'\nthis = JsonExtractScalar(body,'$.this')\nthat = JsonExtractScalar(body,'$.that')\nCONCAT('it\\'s ', this, ' for ', that)",
    help: {
      header: '<h3>CurlPoll</h3>',
      body: "<p>Use this function to make a REST API request continuously until a regular expression is matched or timeout is reached. You'll find it useful with asynchronous API calls that require polling for a status / response URL.</p><p>Syntax: CurlPoll(regex_string,interval, timeout, url, method[, headers[, request_body[, username[, password]]]])</p>",
      example:
        "Example: CurlPoll('status=\"(completed|failed)',1000,60000, 'http://api.myapp.com/report/1234/status','GET','{\"Accept\":\"text/json\"}','','','')<br/>\n  status - response code (integer)<br/>\n  body - the body of the response (string)<br/>\n  headers - map object of response headers\n",
    },
  },
  {
    key: 'ccurlpoll',
    name: 'CCurlPoll',
    autocomplete: 'CCurlPoll',
    itemclass: 'keyword',
    description:
      "Use this function to make a REST API request continuously until a regular expression is matched or timeout is reached. You'll find it useful with asynchronous API calls that require polling for a status / response URL. Requests are authenticated using an Xplenty connection.",
    category: 'Util',
    console:
      "res = CCurlPoll('\"this\"', 1000, 20000, 'http://itsthisforthat.com/api.php?json', 'GET')\nres#'status'\nres#'headers'\nbody = res#'body'\nthis = JsonExtractScalar(body,'$.this')\nthat = JsonExtractScalar(body,'$.that')\nCONCAT('it\\'s ', this, ' for ', that)",
    help: {
      header: '<h3>CCurlPoll</h3>',
      body: "<p>Use this function to make a REST API request continuously until a regular expression is matched or timeout is reached. You'll find it useful with asynchronous API calls that require polling for a status / response URL. Requests are authenticated using an Xplenty connection.</p><p>Syntax: CCurlPoll(regex_string,interval, timeout, url, [method, [headers, [request_body, [connection_id]]]])</p>",
      example:
        "Example: CCurlPoll('status=\"(completed|failed)',1000,60000, 'http://api.myapp.com/report/1234/status','GET','{\"Accept\":\"text/json\"}','','myapp_83')<br/>\n  status - response code (integer)<br/>\n  body - the body of the response (string)<br/>\n  headers - map object of response headers\n",
    },
  },
  {
    key: 'encrypt',
    name: 'Encrypt',
    autocomplete: 'Encrypt',
    itemclass: 'keyword',
    description: 'Encrypts the provided data',
    category: 'Encryption',
    console: "Encrypt('data_to_encrypt', 'provider_key_id', 'encryption_context', 'encryption_strength')",
    help: {
      header: '<h3>Encrypt</h3>',
      body: '<p>Encrypts a string field using the customer’s provided AWS KMS key’s ARN.</p><p>Syntax: Encrypt(plaintext_data, keyARN, encryption_context, encryption_strength)</p>\nplaintext_data - plaintext data to encrypt</br>\nkeyARN - Customer’s AWS KMS keyARN</br>\nencryption_context - map of key values for additional security</br>\nencryption_strength - AES256 (default), AES192 or AES128.',
      example:
        "Example: Encrypt(‘plaintext_data’, ‘keyARN’, TOMAP('key1','val1','key2','val2'), ‘AES192’) returns the encrypted message (ciphertext + encrypted data key).\n",
    },
  },
  {
    key: 'decrypt',
    name: 'Decrypt',
    autocomplete: 'Decrypt',
    itemclass: 'keyword',
    description: 'Decrypt the provided data',
    category: 'Encryption',
    console: "Decrypt('data', 'encryption_key_id', 'decryption_context')",
    help: {
      header: '<h3>Decrypt</h3>',
      body: '<p>Decrypts an encrypted message using the customer provided AWS KMS key’s ARN and the original encryption context.</p><p>Syntax: Syntax: Decrypt(encrypted_message, keyARN, encryption_context)</p>\nencrypted_message - text to decrypt</br>\nkeyARN - AWS KMS key’s ARN</br>\nencryption_context - map of key values that were used for the encryption',
      example:
        "Example: Decrypt(‘encrypted_message’, ‘keyARN’, TOMAP('key1','val1','key2','val2') returns the decrypted message.\n",
    },
  },
];
