import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import {
  Connection,
  ConnectionSchemaField,
  Schema,
  Analytics4SourceComponentData,
  AnalyticsSourceComponentData,
} from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchemaResponse } from '../../../connections/store/connections.actions';
import { SelectOption } from '../../../common/components/forms/xp-select.component';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { selectPackageId } from '../../store/package-designer.selectors';

function filterFields(fields: ConnectionSchemaField[] = [], fieldsToRemove: ConnectionSchemaField[]) {
  const fieldNamesToRemove = fieldsToRemove.map((item) => item.name);

  return fields.filter((field) => !fieldNamesToRemove.includes(field.name));
}

const categoryMap = {
  DIMENSION: 'Dimensions',
  METRIC: 'Metrics',
};

const dateRangeOptionsMap = {
  TODAY: {
    min: 'today',
    max: 'today',
  },
  YESTERDAY: {
    min: 'yesterday',
    max: 'yesterday',
  },
  LAST_3_DAYS: {
    min: '3daysAgo',
    max: 'today',
  },
  LAST_7_DAYS: {
    min: '7daysAgo',
    max: 'today',
  },
  LAST_14_DAYS: {
    min: '14daysAgo',
    max: 'today',
  },
  LAST_30_DAYS: {
    min: '30daysAgo',
    max: 'today',
  },
  ALL_TIME: {
    min: '2005-01-01',
    max: 'today',
  },
  CUSTOM_DATE: {
    min: '',
    max: '',
  },
};

@Component({
  selector: 'analytics4-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="adwords-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::AnalyticsGa4SourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div class="alert alert-warning" *ngIf="customFieldsErrorMessage">
                    <button type="button" class="close" (click)="customFieldsErrorMessage = null">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <span [innerHTML]="customFieldsErrorMessage"></span>
                  </div>
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="api_version">{{ 'adwords-source-editor.form.labels.api_version' | translate }}</label>
                      <xp-select
                        name="api_version"
                        id="api_version"
                        [value]="rawComponent.api_version"
                        [options]="apiVersions"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onValueChange($event, 'api_version')"
                      ></xp-select>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="report_date_range_type">{{
                        'analytics-source-editor.form.labels.date_range' | translate
                      }}</label>
                      <xp-select
                        name="report_date_range_type"
                        id="report_date_range_type"
                        [value]="report_date_range_type"
                        [options]="dateRangeOptions"
                        [preventEmpty]="true"
                        (valueChange)="onDateRangeChange($event)"
                        class="form-control xp-select"
                      ></xp-select>
                    </xp-form-group>
                    <div *ngIf="report_date_range_type === 'CUSTOM_DATE'" class="date-range-container">
                      <date-range
                        [startDate]="rawComponent.report_date_range_min"
                        [endDate]="rawComponent.report_date_range_max"
                        (startDateChange)="onValueChange($event, 'report_date_range_min')"
                        (endDateChange)="onValueChange($event, 'report_date_range_max')"
                        format="YYYY-MM-DD"
                      ></date-range>
                    </div>

                    <div>
                      <xp-input-checkbox
                        [ngModel]="rawComponent.custom_fields"
                        (ngModelChange)="onValueChange($event, 'custom_fields')"
                        name="custom_fields"
                        [labelText]="'analytics-source-editor.form.labels.custom_fields' | translate"
                      ></xp-input-checkbox>
                    </div>
                    <div>
                      <xp-input-checkbox
                        [ngModel]="rawComponent.include_empty_rows"
                        (ngModelChange)="onValueChange($event, 'include_empty_rows')"
                        name="include_empty_rows"
                        [hint]="'analytics-source-editor.form.hints.include_empty_rows' | translate"
                        [labelText]="'analytics-source-editor.form.labels.include_empty_rows' | translate"
                      ></xp-input-checkbox>
                    </div>
                    <label for="report_on">{{ 'bing-ads-source-editor.form.labels.report_on' | translate }}</label>
                    <div class="form-group-options">
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="all"
                          id="report-on-all"
                        />
                        <label for="report-on-all">{{
                          'analytics-source-editor.form.labels.all_profiles' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="specific"
                          id="report-on-specific"
                        />
                        <label for="report-on-specific">{{
                          'analytics-source-editor.form.labels.specific_profiles' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="custom"
                          id="report-on-custom"
                        />
                        <label for="report-on-custom">{{
                          'analytics-source-editor.form.labels.custom' | translate
                        }}</label>
                      </div>
                    </div>
                    <xp-form-group *ngIf="rawComponent.report_on === 'custom'">
                      <label for="profile_ids">{{
                        'analytics-source-editor.form.labels.property_ids' | translate
                      }}</label>
                      <xp-chips
                        name="property_ids"
                        id="property_ids"
                        [value]="rawComponent.property_ids"
                        (valueChange)="onValueChange($event, 'property_ids')"
                        class="form-control xp-select"
                        [placeholder]="'analytics-source-editor.form.placeholders.property_ids' | translate"
                      ></xp-chips>
                      <i
                        class="fa fa-exclamation-circle form-control-info profile-ids-hint"
                        [matTooltip]="'analytics-source-editor.form.hints.property_ids' | translate"
                        matTooltipPosition="right"
                        matTooltipClass="right"
                      ></i>
                    </xp-form-group>
                  </div>
                  <div class="col-sm-12" *ngIf="rawComponent.report_on === 'specific'">
                    <accounts-campaigns-selector
                      [component]="component"
                      [rawComponent]="rawComponent"
                    ></accounts-campaigns-selector>
                    <xp-input
                      type="text"
                      [ngModel]="rawComponent.property_ids"
                      (ngModelChange)="onValueChange($event, 'property_ids')"
                      name="accounts_campaigns"
                      style="visibility: hidden;"
                    ></xp-input>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            [isConnectionSchemaLoading]="areCustomFieldsLoading"
            (updateValidation)="updateSchemaValidation($event)"
            [dataPreviewDisabled]="!this.isSchemaValid || !this.isFormValid"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class Analytics4SourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: Analytics4SourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  areCustomFieldsLoading = false;

  selectPickerTypes = SelectPickerTypes;

  isSchemaValid = true;
  isFormValid = true;
  isBaseComponentStepActivated = false;
  customFieldsErrorMessage = '';
  validationChangeSubscription: Subscription;
  packageIdSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});
  availableFields: ConnectionSchemaField[] = [];
  customFields: ConnectionSchemaField[] = [];

  apiVersions: SelectOption[] = [
    {
      text: 'Data API v1',
      value: 'v1beta',
    },
  ];

  dateRangeOptions: SelectOption[] = [
    {
      text: 'Today',
      value: 'TODAY',
    },
    {
      text: 'Yesterday',
      value: 'YESTERDAY',
    },
    {
      text: 'Last 3 days',
      value: 'LAST_3_DAYS',
    },
    {
      text: 'Last 7 days',
      value: 'LAST_7_DAYS',
    },
    {
      text: 'Last 14 days',
      value: 'LAST_14_DAYS',
    },
    {
      text: 'Last 30 days',
      value: 'LAST_30_DAYS',
    },
    {
      text: 'Custom date range...',
      value: 'CUSTOM_DATE',
    },
  ];
  report_date_range_type = 'TODAY';

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private connectionItemsResource: ConnectionItemsResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    this.packageIdSubscription = this.store.select(selectPackageId).subscribe((packageId) => {
      if (this.rawComponent.quota_user !== packageId) {
        this.onValueChange(packageId, 'quota_user');
      }
    });

    this.report_date_range_type =
      Object.keys(dateRangeOptionsMap).find(
        (key) => dateRangeOptionsMap[key].min === this.rawComponent.report_date_range_min,
      ) || 'CUSTOM_DATE';

    this.getFields();
    this.handleCustomFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    const schemaFields = this.rawComponent.schema.fields.filter(
      (field) =>
        ![
          this.rawComponent.account_name_field_alias,
          this.rawComponent.property_name_field_alias,
          this.rawComponent.property_id_field_alias,
          this.rawComponent.account_id_field_alias,
        ].includes(field.name),
    );
    this.isSchemaValid = isSchemaValid && schemaFields.length > 0;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    const componentToUpdate = { [key]: value };

    if (key === 'report_on') {
      if (value === 'all') {
        componentToUpdate.property_ids = '';
      }

      this.handleCustomFields();
    }

    if (key === 'custom_fields') {
      this.handleCustomFields(value);
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: componentToUpdate,
      }),
    );
    this.store.dispatch(updateComponent({ component: componentToUpdate }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    const dimensions = fields
      .filter(
        (item) =>
          item.category === 'Dimensions' ||
          (this.availableFields.find((availableField) => availableField.name === item.name) || {}).category ===
            'Dimensions',
      )
      .map((item) => item.name);
    const metrics = fields
      .filter(
        (item) =>
          item.category === 'Metrics' ||
          (this.availableFields.find((availableField) => availableField.name === item.name) || {}).category ===
            'Metrics',
      )
      .map((item) => item.name);

    const newComponent: Partial<AnalyticsSourceComponentData> = { schema: { ...this.rawComponent.schema, fields } };

    if (this.availableFields.length > 0) {
      newComponent.dimensions = dimensions;
      newComponent.metrics = metrics;
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: newComponent,
      }),
    );
  }

  onDateRangeChange(value: string) {
    const selectedRange = dateRangeOptionsMap[value];
    this.report_date_range_type = value;

    this.onValueChange(value, 'report_date_range_type');
    this.onValueChange(selectedRange.min, 'report_date_range_min');
    this.onValueChange(selectedRange.max, 'report_date_range_max');
  }

  handleCustomFields(value?: boolean) {
    const isValueDefined = value != null;
    let isCustomFields = this.rawComponent.custom_fields;

    if (isValueDefined) {
      isCustomFields = value;
    }

    this.customFieldsErrorMessage = '';

    if (isCustomFields) {
      this.areCustomFieldsLoading = true;
      this.connectionItemsResource
        .customFields(this.rawComponent.connection.type, this.rawComponent.connection.id, {
          force_fetch: true,
          api_version: this.rawComponent.api_version || 'v1beta',
          property_ids: this.rawComponent.property_ids || '',
        } as any)
        .subscribe({
          next: (fields) => {
            if (this.customFields.length) {
              this.onFieldsChange(filterFields(this.rawComponent.schema.fields, this.customFields));
              this.availableFields = filterFields(this.availableFields, this.customFields);
            }

            const customFields = fields.map((item) => ({
              name: item.id,
              alias: item.id,
              data_type: 'string',
              category: categoryMap[item.type],
            }));

            this.customFields = customFields;
            this.availableFields = [...this.availableFields, ...customFields];

            this.store.dispatch(
              getConnectionSchemaResponse({
                connectionSchema: this.availableFields,
                connectionSchemaData: { data: [], fields: [] },
              }),
            );
            this.areCustomFieldsLoading = false;
          },
          error: (error) => {
            if (error.message) {
              this.customFieldsErrorMessage = error.message;
            }
            this.notify.error(`An error occurred while calling custom fields endpoint.`);
            this.areCustomFieldsLoading = false;
          },
        });
    } else {
      this.onFieldsChange(filterFields(this.rawComponent.schema.fields, this.customFields));
      this.availableFields = filterFields(this.availableFields, this.customFields);
      this.customFields = [];

      this.store.dispatch(
        getConnectionSchemaResponse({
          connectionSchema: this.availableFields,
          connectionSchemaData: { data: [], fields: [] },
        }),
      );
    }

    this.store.dispatch(
      getConnectionSchemaResponse({
        connectionSchema: this.availableFields,
        connectionSchemaData: { data: [], fields: [] },
      }),
    );
  }

  getFields() {
    this.connectionItemsResource.ga4Fields(this.rawComponent.api_version).subscribe((fields) => {
      const availableFields = fields.map((item) => ({
        name: item.id,
        alias: item.id,
        data_type: 'string',
        category: categoryMap[item.type],
      }));

      this.availableFields = [...availableFields, ...this.customFields];

      this.store.dispatch(
        getConnectionSchemaResponse({
          connectionSchema: availableFields,
          connectionSchemaData: { data: [], fields: [] },
        }),
      );
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }

    if (this.packageIdSubscription) {
      this.packageIdSubscription.unsubscribe();
    }
  }
}
