import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '../../common/components/forms/xp-select.component';

export enum IdleTimeValues {
  OneMinute = 60,
  TenMinutes = 600,
  ThirtyMinutes = 1800,
  OneHour = 3600,
  FourHours = 14400,
  EightHours = 28800,
  TwelveHours = 43200,
  OneDay = 86400,
  ThreeDays = 259200,
  FiveDays = 432000,
  OneWeek = 604800,
}

@Injectable({
  providedIn: 'root',
})
export class IdleTimesService {
  idleTimesSandbox: SelectOption[] = [
    {
      value: IdleTimeValues.ThirtyMinutes,
      name: this.translate.instant('cluster.form.select.values.minutes', { number: 30 }),
    },
    {
      value: IdleTimeValues.OneHour,
      name: this.translate.instant('cluster.form.select.values.hours', { number: 1 }),
    },
  ];
  idleTimesProduction: SelectOption[] = [
    {
      value: IdleTimeValues.OneMinute,
      name: this.translate.instant('cluster.form.select.values.minutes', { number: 1 }),
    },
    {
      value: IdleTimeValues.TenMinutes,
      name: this.translate.instant('cluster.form.select.values.minutes', { number: 10 }),
    },
    {
      value: IdleTimeValues.ThirtyMinutes,
      name: this.translate.instant('cluster.form.select.values.minutes', { number: 30 }),
    },
    {
      value: IdleTimeValues.OneHour,
      name: this.translate.instant('cluster.form.select.values.hours', { number: 1 }),
    },
    {
      value: IdleTimeValues.FourHours,
      name: this.translate.instant('cluster.form.select.values.hours', { number: 4 }),
    },
    {
      value: IdleTimeValues.EightHours,
      name: this.translate.instant('cluster.form.select.values.hours', { number: 8 }),
    },
    {
      value: IdleTimeValues.TwelveHours,
      name: this.translate.instant('cluster.form.select.values.hours', { number: 12 }),
    },
    {
      value: IdleTimeValues.OneDay,
      name: this.translate.instant('cluster.form.select.values.days', { number: 1 }),
    },
    {
      value: IdleTimeValues.ThreeDays,
      name: this.translate.instant('cluster.form.select.values.days', { number: 3 }),
    },
    {
      value: IdleTimeValues.FiveDays,
      name: this.translate.instant('cluster.form.select.values.days', { number: 5 }),
    },
    {
      value: IdleTimeValues.OneWeek,
      name: this.translate.instant('cluster.form.select.values.days', { number: 7 }),
    },
  ];

  constructor(private translate: TranslateService) {}
}
