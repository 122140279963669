import { SelectOption } from '../common/components/forms/xp-select.component';

export const MATCH_TYPES: SelectOption[] = [
  {
    text: 'filter_component.selects.match_types.all',
    translate: true,
    value: 'all',
  },
  {
    text: 'filter_component.selects.match_types.any',
    translate: true,
    value: 'any',
  },
  {
    text: 'filter_component.selects.match_types.none',
    translate: true,
    value: 'none',
  },
];
