import { clone, cloneDeep } from 'lodash';
import {
  AllComponentData,
  ColumnMap,
  Component,
  Edge,
  Field,
  Position,
  PredicateExpression,
  Schema,
} from '../package.models';
import { COMPONENT_TYPES, ComponentTypeItem } from '../../constants/component_types';
import {
  COMPONENT_TYPE,
  isDestinationComponentType,
  isSourceComponentType,
  SOURCE_COMPONENT_TYPES,
} from '../../constants/component_type';
import { Package } from '../../packages/package.models';
import { DESIGNER_SETTINGS } from '../../constants/designer_settings';
import { ConditionOperator } from '../../constants/conditions_operator_picker';
import { COMPONENT_SCHEMA_PARENT_REQUIRED_TYPES, getComponentParentsSchema } from './component-schema.helpers';

export function getComponentDefaultDataByType(componentType: COMPONENT_TYPE): ComponentTypeItem {
  return clone(
    COMPONENT_TYPES.find((component: ComponentTypeItem) => {
      return component.componentType === componentType;
    }),
  );
}

export function fixComponentData(data?: Component): ComponentTypeItem | null {
  if (!data) return null;

  const keys = Object.keys(data);
  let newData;

  if (keys.length === 1) {
    const componentType = keys[0];

    const dataToMarge = getComponentDefaultDataByType(componentType as COMPONENT_TYPE);

    newData = dataToMarge !== undefined ? { ...dataToMarge, ...data[componentType] } : data[componentType];
  }

  return newData;
}

export function getComponentType(component: Component): keyof Component {
  return Object.keys(component || {}).find((key) =>
    // eslint-disable-next-line no-prototype-builtins
    component.hasOwnProperty(key),
  ) as keyof Component;
}

export function getDataFromComponent(component: Component): AllComponentData {
  const componentName: keyof Component = getComponentType(component);
  return component[componentName] as AllComponentData;
}

export function findComponentById(componentId: string) {
  return (component: Component): boolean => getDataFromComponent(component).id === componentId;
}

export function getComponentId(component: Component): string {
  return getDataFromComponent(component).id;
}

export function findComponent(components: Component[], id: string): Component {
  return (components || []).find((component: Component) => getComponentId(component) === id);
}

function getComponentData(componentData: ComponentTypeItem): Partial<ComponentTypeItem> {
  return {
    fields: componentData.fields,
    name: componentData.name,
    field_alias: componentData.field_alias,
    dense: componentData.dense,
    data_order: componentData.data_order,
    partitioning: componentData.partitioning,
    order: componentData.order,
    n: componentData.n,
    partitioned_fields: componentData.partitioned_fields,
    ordered_fields: componentData.ordered_fields,
    grouped_fields: componentData.grouped_fields,
    aggregated_fields: componentData.aggregated_fields,
    windowed_fields: componentData.windowed_fields,
    grouping_type: componentData.grouping_type,
    percentage: componentData.percentage,
    relations: componentData.relations,
    predicates: componentData.predicates,
    message: componentData.message,
  };
}

export function updateComponent(baseComponent: Component, componentData: ComponentTypeItem): Component {
  return {
    ...baseComponent,
    [componentData.componentType]: {
      // @ts-ignore
      ...baseComponent[componentData.componentType],
      ...getComponentData(componentData),
    },
  };
}

export interface ComponentPreviewerData {
  components: Component[];
  edges: Edge[];
  destination_schema: Schema;
}

export function getSourceComponentsFromPartialGrap(packageData: Package | ComponentPreviewerData): Component[] {
  return packageData.components.filter((item) => SOURCE_COMPONENT_TYPES.includes(getComponentType(item)));
}

export function getComponentInputs(
  packageData: Partial<Package> | ComponentPreviewerData,
  componentId: string,
): Component[] {
  const inputComponentIds = packageData.edges.filter((edge) => edge.target === componentId).map((edge) => edge.source);
  return packageData.components.filter((item) => inputComponentIds.includes(getDataFromComponent(item).id));
}

const fieldsAttributeNames = [
  'partitioned_fields',
  'ordered_fields',
  'windowed_fields',
  'grouped_fields',
  'aggregated_fields',
  'fields',
  'predicates.expressions',
];

const optionalFieldAttributes = ['default_value', 'ntiles', 'offset', 'range_after', 'range_before'];

const fieldsOptionalValidation = {
  partitioned_fields: [
    { attr: 'partitioning', value: 'all' },
    { attr: 'grouping_type', value: 'all' },
  ],
  ordered_fields: [
    {
      attr: 'order',
      value: 'no_sort',
    },
    { attr: 'data_order', value: 'no_sort' },
  ],
  fields: [
    {
      attr: 'data_order',
      value: 'no_sort',
    },
  ],
  grouped_fields: [
    { attr: 'partitioning', value: 'all' },
    { attr: 'grouping_type', value: 'all' },
  ],
};

function isOptionalAttribute(attrName: string, component: ComponentTypeItem | {}): boolean {
  const optionalAttributeData = fieldsOptionalValidation[attrName];
  return optionalAttributeData && optionalAttributeData.some((item) => component[item.attr] === item.value);
}

export function areComponentFieldsValid(component: ComponentTypeItem | {}): boolean {
  if (!component) {
    return true;
  }
  return fieldsAttributeNames.every((attrName) => {
    const attribute = attrName.includes('.')
      ? attrName.split('.').reduce((acc, key) => (acc ? acc[key] : null), component)
      : component[attrName];

    if (!attribute) {
      return true;
    }

    if (!attribute.length && !isOptionalAttribute(attrName, component)) {
      return false;
    }

    return attribute.every((field) => {
      let isRightIgnored = false;

      if (
        [
          ConditionOperator.isnn,
          ConditionOperator.isn,
          ConditionOperator.tise,
          ConditionOperator.tisne,
          ConditionOperator.ist,
        ].includes(field.operator)
      ) {
        isRightIgnored = true;
      }

      return Object.keys(field)
        .filter(
          (key) =>
            !optionalFieldAttributes.includes(key) && field[key] !== null && !(key === 'right' && isRightIgnored),
        )
        .every((key) => (typeof field[key] === 'object' ? field[key].length > 0 : field[key] !== ''));
    });
  });
}

export function uid(): string {
  return Math.floor((1 + Math.random()) * 0x1000000)
    .toString(16)
    .substring(1);
}

export function generateComponentPosition(position: Position, components: Component[] = []): Position {
  const samePositionComponent = (components || []).find((item) => {
    const component = getDataFromComponent(item);
    return component.xy[0] === position.x && component.xy[1] === position.y;
  });
  if (samePositionComponent) {
    return generateComponentPosition(
      { x: position.x + DESIGNER_SETTINGS.CELL, y: position.y + DESIGNER_SETTINGS.CELL },
      components,
    );
  }

  return position;
}

export function generateDuplicatedComponentName(name: string, components: Component[] = []): string {
  const sameNameComponent = (components || []).find((item) => {
    const component = getDataFromComponent(item);
    return component.name === name;
  });
  if (sameNameComponent) {
    return generateDuplicatedComponentName(`copy_of_${name}`, components);
  }

  return name;
}

export function generateNewComponentName(name: string, components: Component[] = [], count = 1): string {
  const sameNameComponent = (components || []).find((item) => {
    const component = getDataFromComponent(item);
    return component.name === name;
  });
  if (sameNameComponent) {
    const newCount = count + 1;
    return generateNewComponentName(`${name.replace(String(count), String(newCount))}`, components, newCount);
  }

  return name;
}

export function snapToGrid(value: number): number {
  return Math.round(value / DESIGNER_SETTINGS.CELL) * DESIGNER_SETTINGS.CELL;
}

export function generateComponentName(componentOriginalName: string, componentsCounter?: number): string {
  let name = componentOriginalName
    .toLowerCase()
    .replaceAll(/[(\-)]/g, '')
    .replace(/ /g, '_');

  if (componentsCounter != null) {
    name += `_${componentsCounter + 1}`;
  }

  return name;
}

export function createComponent(
  component: Partial<ComponentTypeItem>,
  components: Component[],
  parentComponentId?: string,
): Component {
  let parentComponent;
  let parentComponentData;

  if (parentComponentId) {
    parentComponent = components.find((item) => getComponentId(item) === parentComponentId);
    parentComponentData = getDataFromComponent(parentComponent);
  }

  const { componentType } = component;
  const newComponent: any = { [componentType]: cloneDeep(component.defaults || {}) };
  newComponent[componentType].id = `component-${uid()}`;
  const componentsCounter = components ? components.filter((item) => item[componentType]).length : 0;
  const componentName = generateComponentName(component.originalName, componentsCounter);
  newComponent[componentType].name = generateNewComponentName(componentName, components);
  const componentPosition = generateComponentPosition(
    {
      x: parentComponentData
        ? parentComponentData.xy[0]
        : snapToGrid((document.querySelector('.designer') as HTMLElement).offsetWidth / 2),
      y: parentComponentData
        ? parentComponentData.xy[1] + DESIGNER_SETTINGS.COMPONENT_HEIGHT + DESIGNER_SETTINGS.CELL * 3
        : DESIGNER_SETTINGS.CELL * 3,
    },
    components,
  );
  newComponent[componentType].xy = [];
  newComponent[componentType].xy[0] = componentPosition.x;
  newComponent[componentType].xy[1] = componentPosition.y;

  newComponent[componentType].connection = {};
  newComponent[componentType].is_new = true;

  if (
    !newComponent[componentType].schema &&
    (isSourceComponentType(componentType) || isDestinationComponentType(componentType))
  ) {
    newComponent[componentType].schema = {
      fields: [],
      valid: true,
    };
  }

  return newComponent;
}

export function duplicateComponent(componentId: string, components: Component[]): Component {
  const newComponent = cloneDeep(components.find((item) => getComponentId(item) === componentId));
  const componentType = Object.keys(newComponent)[0];
  if (componentType === COMPONENT_TYPE.STICKY_NOTE_COMPONENT) {
    newComponent[componentType].id = `note-${uid()}`;
  } else {
    newComponent[componentType].id = `component-${uid()}`;
    newComponent[componentType].name = generateDuplicatedComponentName(newComponent[componentType].name, components);
    newComponent[componentType].alias = newComponent[componentType].name;
  }

  const componentPosition = generateComponentPosition(
    {
      x: newComponent[componentType].xy[0] + DESIGNER_SETTINGS.COMPONENT_WIDTH + DESIGNER_SETTINGS.CELL,
      y: newComponent[componentType].xy[1],
    },
    components,
  );
  newComponent[componentType].xy[0] = componentPosition.x;
  newComponent[componentType].xy[1] = componentPosition.y;

  return newComponent;
}

export function createNote(components: Component[]): Component {
  const componentType = COMPONENT_TYPE.STICKY_NOTE_COMPONENT;
  const newComponent: any = { [componentType]: {} };
  newComponent[componentType].id = `note-${uid()}`;

  const componentPosition = generateComponentPosition(
    {
      x: snapToGrid((document.querySelector('.designer') as HTMLElement).offsetWidth / 2),
      y: DESIGNER_SETTINGS.CELL * 3,
    },
    components,
  );
  newComponent[componentType].description = '';
  newComponent[componentType].xy = [];
  newComponent[componentType].xy[0] = componentPosition.x;
  newComponent[componentType].xy[1] = componentPosition.y;

  return newComponent;
}

function getComponentChild(componentId: string, components: Component[], edges: Edge[]): Component {
  const childrenEdges = edges.filter((edge) => edge.source === componentId);
  const componentIds = childrenEdges.map((edge) => edge.target);

  if (childrenEdges.length) {
    const childrenItems = components.filter((item) => componentIds.includes(getDataFromComponent(item).id));

    return childrenItems[0];
  } else {
    return null;
  }
}

export interface CopyData {
  components: Component[];
  edges: Edge[];
}

function updatecomponentUsingPartialData(
  component: Component,
  partialComponentData: Partial<AllComponentData>,
): Component {
  return {
    [getComponentType(component)]: {
      ...getDataFromComponent(component),
      ...partialComponentData,
    },
  } as any;
}

function updateFields(component: Component, newSchema: Schema, oldSchema: Schema): Component {
  const componentData = getDataFromComponent(component);

  if (componentData && componentData.fields) {
    const newFields: Field[] = componentData.fields.map((field) => {
      const oldFieldIndex = ((oldSchema || {}).fields || []).findIndex((oldField) => oldField.name === field.name);

      if (oldFieldIndex > -1) {
        return {
          ...field,
          name: newSchema.fields[oldFieldIndex].name,
        } as Field;
      }

      return field;
    });

    return updatecomponentUsingPartialData(component, { fields: newFields as any });
  }

  if (componentData && componentData.predicates) {
    const newExpressions: PredicateExpression[] = componentData.predicates.expressions.map((expression) => {
      const oldFieldIndexLeft = (oldSchema.fields || []).findIndex((oldField) => oldField.name === expression.left);
      const oldFieldIndexRight = (oldSchema.fields || []).findIndex((oldField) => oldField.name === expression.right);

      if (oldFieldIndexLeft > -1) {
        return {
          ...expression,
          left: newSchema.fields[oldFieldIndexLeft].name,
        } as PredicateExpression;
      }

      if (oldFieldIndexRight > -1) {
        return {
          ...expression,
          right: newSchema.fields[oldFieldIndexLeft].name,
        } as PredicateExpression;
      }

      return expression;
    });

    return updatecomponentUsingPartialData(component, {
      predicates: { ...componentData.predicates, expressions: newExpressions as any },
    });
  }

  let newComponent = { ...component };

  [
    'partitioned_fields',
    'ordered_fields',
    'windowed_fields',
    'grouped_fields',
    'aggregated_fields',
    'column_mappings',
  ].forEach((attributeName) => {
    if (componentData && componentData[attributeName] && componentData[attributeName].length) {
      const newItems = componentData[attributeName].map((item) => {
        const oldFieldIndex = (oldSchema.fields || []).findIndex((oldField) => oldField.name === item.field_name);

        if (oldFieldIndex > -1) {
          return {
            ...item,
            field_name: newSchema.fields[oldFieldIndex].name,
          } as ColumnMap;
        }

        return item;
      });

      newComponent = updatecomponentUsingPartialData(component, { [attributeName]: newItems });
    }
  });

  return newComponent;
}

export function updateFieldsAfterCopyPaste(
  components: Component[],
  copyData: CopyData,
  newEdges: Edge[],
  componentsIdsMap: { [key: string]: string },
): Component[] {
  const componentTypes = components.map((item) => getComponentType(item)) as COMPONENT_TYPE[];

  const isJoinComponent =
    componentTypes.includes(COMPONENT_TYPE.JOIN_COMPONENT) ||
    componentTypes.includes(COMPONENT_TYPE.CROSS_JOIN_COMPONENT);

  if (!isJoinComponent) {
    return components;
  }

  let newComponents = [...components];

  components
    .filter((item) =>
      [COMPONENT_TYPE.JOIN_COMPONENT, COMPONENT_TYPE.CROSS_JOIN_COMPONENT].includes(getComponentType(item)),
    )
    .forEach((joinComponent) => {
      let joinComponentChild = getComponentChild(getDataFromComponent(joinComponent).id, components, newEdges);
      let componentsToUpdate = [joinComponentChild];

      if (joinComponentChild) {
        while (COMPONENT_SCHEMA_PARENT_REQUIRED_TYPES.includes(getComponentType(joinComponentChild))) {
          joinComponentChild = getComponentChild(getDataFromComponent(joinComponentChild).id, components, newEdges);
          componentsToUpdate = [...componentsToUpdate, joinComponentChild];
        }

        componentsToUpdate.filter(Boolean).forEach((componentToUpdate) => {
          const newComponentParentSchema = getComponentParentsSchema(componentToUpdate, components, newEdges);

          const oldComponentToUpdateId = Object.keys(componentsIdsMap).find(
            (oldId) => componentsIdsMap[oldId] === getDataFromComponent(componentToUpdate).id,
          );
          const oldComponentToUpdate = copyData.components.find(
            (item) => getDataFromComponent(item).id === oldComponentToUpdateId,
          );
          const oldComponentParentSchema = getComponentParentsSchema(
            oldComponentToUpdate,
            copyData.components,
            copyData.edges,
          );

          const newComponent = updateFields(
            componentToUpdate,
            newComponentParentSchema[0],
            oldComponentParentSchema[0],
          );

          newComponents = newComponents.map((component) =>
            getDataFromComponent(component).id === getDataFromComponent(newComponent).id ? newComponent : component,
          );
        });
      }
    });

  return newComponents;
}
