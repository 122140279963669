import { createReducer, on } from '@ngrx/store';
import { closeShortcutsModal, openShortcutsModal } from './app-common.actions';

export const initialState = {
  isShortcutsModalOpen: false,
};

export interface AppCommonState {
  isShortcutsModalOpen: boolean;
}

export const appCommonReducer = createReducer<AppCommonState>(
  initialState,

  on(openShortcutsModal, (state) => ({
    ...state,
    isShortcutsModalOpen: true,
  })),
  on(closeShortcutsModal, (state) => ({
    ...state,
    isShortcutsModalOpen: false,
  })),
);
