import { AfterViewInit, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first, skip, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closeConnectionsModal,
  getConnectionItem,
  getConnectionsList,
  loadMoreConnectionsList,
  openConnectionsModal,
} from '../store/connections.actions';
import {
  selectAreAllConnectionsLoadedFlag,
  selectConnections,
  selectConnectionsErrorFlag,
  selectConnectionsIsModalOpenFlag,
  selectConnectionsLoadingFlag,
} from '../store/connections.selectors';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { AnyConnection } from '../connection.models';
import { QueryParamsConnectionsList } from '../../common/helper/query-params-generic-list.helper';
import { UnlockFeaturesDialogService } from '../../common/services/unlock-features-dialog.service';

@Component({
  selector: 'connections-list',
  template: `
    <generic-list
      [type]="type"
      [items]="connections$ | async"
      [isLoading]="connectionsLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openConnectionCreateModal()"
      [newBtnText]="'connection.generic-object.buttons.new' | translate"
      [emptyTitle]="'connection.generic-object.empty.title' | translate"
      [emptyDescription]="'connection.generic-object.empty.description' | translate"
      (loadMore)="loadMoreConnections($event)"
      [limit]="queryParams.limit"
      [allItemsLoaded]="areAllConnectionsLoaded$ | async"
      [searchPlaceholder]="'connection.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="false"
      [isError]="connectionsError$ | async"
    >
      <ng-template templateName="listItem" let-item>
        <connection-list-item [item]="item" (edit)="editConnection($event)"></connection-list-item>
      </ng-template>
      <ng-template templateName="headers">
        <div class="generic-list-headers connections">
          <div class="name">
            <span (click)="changeOrder('name')">
              <span class="sorting">{{ 'generic-list.connections.name' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'name',
                  'fa-chevron-down': queryParams.direction === 'asc' && queryParams.sort === 'name',
                  'fa-chevron-up': queryParams.direction === 'desc' && queryParams.sort === 'name'
                }"
              ></i>
            </span>
          </div>

          <div class="type">
            <span (click)="changeOrder('type')">
              <span class="sorting">{{ 'generic-list.connections.type' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'type',
                  'fa-chevron-down': queryParams.direction === 'asc' && queryParams.sort === 'type',
                  'fa-chevron-up': queryParams.direction === 'desc' && queryParams.sort === 'type'
                }"
              ></i>
            </span>
          </div>

          <div class="owner">{{ 'generic-list.connections.owner' | translate }}</div>
          <div class="date">
            <span (click)="changeOrder('updated')">
              <span class="sorting">{{ 'generic-list.connections.date' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'updated',
                  'fa-chevron-down': queryParams.direction === 'desc' && queryParams.sort === 'updated',
                  'fa-chevron-up': queryParams.direction === 'asc' && queryParams.sort === 'updated'
                }"
              ></i>
            </span>
          </div>
          <div class="dropdown-menu-header"></div>
        </div>
      </ng-template>
    </generic-list>
    <xp-modal
      [isOpen]="isConnectionModalOpen$ | async"
      [closeButtonText]="'connection.generic-object.buttons.close' | translate"
      [saveButtonText]="'connection.generic-object.buttons.save' | translate"
      [titleText]="'connection.generic-object.title' | translate"
      (close)="onConnectionModalClose()"
    >
      <ng-template>
        <connection-form [item]="formItem"></connection-form>
      </ng-template>
    </xp-modal>
  `,
})
export class ConnectionsListComponent implements AfterViewInit {
  type = GenericListType.connections;
  connectionsLoading$ = this.store.select(selectConnectionsLoadingFlag);
  connectionsError$ = this.store.select(selectConnectionsErrorFlag);
  isConnectionModalOpen$ = this.store.select(selectConnectionsIsModalOpenFlag).pipe(
    skip(1),
    tap((isOpen) => {
      if (!isOpen) {
        this.router.navigate(['./'], { relativeTo: this.route });
      }
    }),
  );
  connections$ = this.store.select(selectConnections);
  areAllConnectionsLoaded$ = this.store.select(selectAreAllConnectionsLoadedFlag);
  queryParams = QueryParamsConnectionsList;
  formItem: Partial<AnyConnection> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private unlockFeaturesDialog: UnlockFeaturesDialogService,
  ) {
    this.store.dispatch(getConnectionsList({ params: this.queryParams }));
  }

  ngAfterViewInit() {
    const isTypeNewState = !!this.router.url.match(/\/connections\/\w+\/new/);
    if (this.router.url.includes('/connections/new') || this.router.url.match(/\/connections\/\w+\/new/)) {
      this.openConnectionCreateModal(isTypeNewState);
    }

    if (this.router.url.match(/\/connections\/\w+\/\w+\/edit/)) {
      const { type, connection_id } = this.route.firstChild?.firstChild?.snapshot.params;
      this.editConnection({ type, id: connection_id });
    }
  }

  changeOrder(field: string) {
    let { direction } = this.queryParams;
    if (field === this.queryParams.sort) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    }
    this.queryParams = { ...this.queryParams, sort: field, direction };
    this.store.dispatch(getConnectionsList({ params: this.queryParams }));
  }

  editConnection(item: Partial<AnyConnection>) {
    this.store.dispatch(getConnectionItem({ connectionId: item.id, connectionType: item.type }));
    this.formItem = { ...item };
    this.router.navigate([`${item.type}/${item.id}/edit`], { relativeTo: this.route });
    this.store.dispatch(openConnectionsModal());
  }

  openConnectionCreateModal(isTypeSelected = false): void {
    this.connections$.pipe(first()).subscribe((connections) => {
      if (connections.length >= 2) {
        this.unlockFeaturesDialog.openDialog({});
      } else {
        this.formItem = {};
        if (isTypeSelected) {
          const { type } = this.route.firstChild?.firstChild?.snapshot.params;
          this.router.navigate([`${type}/new`], { relativeTo: this.route });
        } else {
          this.router.navigate(['new'], { relativeTo: this.route });
        }
        this.store.dispatch(openConnectionsModal());
      }
    });
  }

  onConnectionModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeConnectionsModal());
  }

  loadMoreConnections(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreConnectionsList({ params }));
  }
}
