import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SampleComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';

@Component({
  selector: 'sample-editor',
  template: `
    <div class="sample-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::SampleComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <xp-form-group class="col-md-4">
              <label for="percentage"
                ><b>{{ 'sample-editor.labels.sample' | translate }}</b></label
              >
              <input
                type="number"
                class="form-control"
                id="percentage"
                name="percentage"
                [ngModel]="rawComponent.percentage"
                placeholder="{{ 'sample-editor.placeholders.percentage' | translate }}"
                (ngModelChange)="onPercentageChange($event)"
              />
            </xp-form-group>
            <div class="col-md-3 input-text">
              {{ 'sample-editor.labels.percentage' | translate }}
            </div>
          </div>
        </form>
      </xp-form-validation>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class SampleEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: SampleComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  isFormValid = false;
  validationChangeSubscription: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });
  }

  onValidityChange() {
    const isValid =
      !!this.rawComponent.percentage && this.rawComponent.percentage >= 0 && this.rawComponent.percentage <= 100;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onPercentageChange(percentage: number) {
    this.store.dispatch(updateRawComponent({ rawComponent: { percentage } }));
    this.store.dispatch(updateComponent({ component: { percentage } }));
    this.onValidityChange();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
