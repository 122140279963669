import { createAction, props } from '@ngrx/store';
import { AnyConnection, ConnectionType, ConnectionTypeName } from '../connection.models';
import { ValidationError } from '../../config/validation-error.model';
import { ConnectionSchemaField, SchemaResponse } from '../../package-designer/package.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

export const getConnectionTypes = createAction('[Connections] Get connection types');
export const getConnectionTypesResponse = createAction(
  '[Connections] Get connection types response',
  props<{ types: ConnectionType[] }>(),
);
export const getConnectionTypesError = createAction(
  '[Connections] Get connection types error',
  props<{ errors: ValidationError[] }>(),
);
export const getConnectionError = createAction(
  '[Connections] Get connection error',
  props<{ errors: ValidationError[] }>(),
);
export const selectConnectionType = createAction(
  '[Connections] Select connection type',
  props<{ selectedType: ConnectionTypeName }>(),
);

export const openConnectionsModal = createAction('[Connections] Open modal');
export const closeConnectionsModal = createAction('[Connections] Close modal');

export const getConnectionsList = createAction('[Connections] Get connections list', props<{ params: ListParams }>());
export const getConnectionsListResponse = createAction(
  '[Connections] Get connections list response',
  props<{ connections: AnyConnection[] }>(),
);
export const getConnectionsListError = createAction(
  '[Connections] Get connections list error',
  props<{ errors: ValidationError[] }>(),
);

export const getConnectionItem = createAction(
  '[Connections] Get connection item',
  props<{ connectionId: number; connectionType: ConnectionTypeName }>(),
);
export const getConnectionItemResponse = createAction(
  '[Connections] Get connection item response',
  props<{ connection: AnyConnection }>(),
);

export const searchConnectionsList = createAction(
  '[Connections] Search connections list',
  props<{ params: ListParams }>(),
);
export const searchConnectionsListResponse = createAction(
  '[Connections] Search connections list response',
  props<{ connections: AnyConnection[] }>(),
);

export const loadMoreConnectionsList = createAction(
  '[Connections] Load more connections list',
  props<{ params: ListParams }>(),
);
export const loadMoreConnectionsListResponse = createAction(
  '[Connections] Load more connections list response',
  props<{ connections: AnyConnection[] }>(),
);

export const saveConnection = createAction(
  '[Connections] Save connection',
  props<{ connection: Partial<AnyConnection>; connectionType: string }>(),
);
export const saveConnectionResponse = createAction(
  '[Connections] Save connection response',
  props<{ data: AnyConnection; closeModal?: boolean; shouldNotModifyFormData?: boolean }>(),
);
export const saveConnectionError = createAction(
  '[Connections] Save connection error',
  props<{ errors: ValidationError[] }>(),
);

export const updateConnection = createAction(
  '[Connections] Update connection',
  props<{
    connection: Partial<AnyConnection>;
    params: ListParams;
    connectionId: number;
    connectionType: ConnectionTypeName;
  }>(),
);
export const updateConnectionResponse = createAction(
  '[Connections] Update connection response',
  props<{ data: AnyConnection; shouldCloseModal: boolean; shouldNotModifyFormData?: boolean }>(),
);
export const updateConnectionError = createAction(
  '[Connections] Update connection error',
  props<{ errors: ValidationError[] }>(),
);

export const testConnection = createAction(
  '[Connections] Test connection',
  props<{ testData: any; connectionId: number; connectionType: ConnectionTypeName }>(),
);
export const testConnectionResponse = createAction(
  '[Connections] Test connection response',
  props<{ message: string[] }>(),
);
export const testConnectionError = createAction(
  '[Connections] Test connection error',
  props<{ errors: ValidationError[] }>(),
);

export const removeConnection = createAction(
  '[Connections] Remove connection',
  props<{ connectionId: number; connectionType: ConnectionTypeName }>(),
);
export const removeConnectionItem = createAction(
  '[Connections] Remove connection item',
  props<{ data: AnyConnection }>(),
);
export const removeConnectionResponse = createAction(
  '[Connections] Remove connection response',
  props<{ data: AnyConnection }>(),
);
export const removeConnectionError = createAction(
  '[Connections] Remove connection error',
  props<{ errors: ValidationError[] }>(),
);

export const getConnectionSchema = createAction(
  '[Connections] Get connection schema',
  props<{
    onlyData?: boolean;
    connectionId?: number;
    connectionType: ConnectionTypeName | 'curl';
    schemaRequestData: any;
    hardRefresh?: boolean;
    access_mode?: string;
  }>(),
);
export const getConnectionSchemaResponse = createAction(
  '[Connections] Get connection schema response',
  props<{
    connectionSchemaData: SchemaResponse;
    connectionSchema: ConnectionSchemaField[];
    onlyData?: boolean;
    hardRefresh?: boolean;
    access_mode?: string;
  }>(),
);
export const getConnectionSchemaError = createAction(
  '[Connections] Get connection schema error',
  props<{ errors: ValidationError[] }>(),
);

export const setConnectionOAuthToken = createAction(
  '[Connections] Set Connection OAuth Token',
  props<{ chunk: string; index: number; size: number }>(),
);

export const clearConnectionOAuthToken = createAction('[Connections] Clear Connection OAuth Token');

export const getDatabaseColumns = createAction(
  '[Connections] Get database columns',
  props<{
    tableName: string;
    schemaName: string;
    connectionId?: number;
    connectionType: ConnectionTypeName | 'curl';
  }>(),
);

export const getDatabaseColumnsResponse = createAction(
  '[Connections] Get database columns response',
  props<{
    columns: string[];
  }>(),
);

export const getDatabaseColumnsError = createAction(
  '[Connections] Get connection columns error',
  props<{ errors: ValidationError[] }>(),
);
