import { createReducer, on } from '@ngrx/store';
import { defaultValidators } from '../helpers/default-constraints.helper';
import {
  removeForm,
  setFormFieldValidity,
  setFormValidity,
  updateValidators,
  removeField,
  setSchemas,
  updateFormValidity,
  setFormFieldMessage,
  addField,
} from './validators.actions';

export interface ValidatorState {
  validators: any;
  forms: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  formMessages: {
    [key: string]: {
      [key: string]: string;
    };
  };
  schemas: any[];
}

export const initialState = { validators: defaultValidators, forms: {}, formMessages: {}, schemas: [] };

export const validatorsReducer = createReducer<ValidatorState>(
  initialState,
  on(updateValidators, (state, { validators }) => ({ ...state, validators: { ...state.validators, ...validators } })),
  on(setFormValidity, (state, { formName, formData }) => ({
    ...state,
    forms: { ...state.forms, [formName]: formData },
    formMessages: {
      ...state.formMessages,
      [formName]: Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
    },
  })),
  on(updateFormValidity, (state, { formName, formData }) => ({
    ...state,
    forms: { ...state.forms, [formName]: { ...state.forms[formName], ...formData } },
  })),
  on(setFormFieldValidity, (state, { formName, isValid, fieldName }) => {
    const newFormState = { ...state.forms[formName], [fieldName]: isValid };
    return { ...state, forms: { ...state.forms, [formName]: newFormState } };
  }),
  on(setFormFieldMessage, (state, { formName, message, fieldName }) => {
    const newFormMessagesState = { ...state.formMessages[formName], [fieldName]: message };
    return { ...state, formMessages: { ...state.formMessages, [formName]: newFormMessagesState } };
  }),
  on(removeForm, (state, { formName }) => ({ ...state, forms: { ...state.forms, [formName]: undefined } })),
  on(removeField, (state, { formName, fieldName }) => {
    const newForm = { ...state.forms[formName] };
    delete newForm[fieldName];

    const newFormMessages = { ...state.formMessages[formName] };
    delete newFormMessages[fieldName];

    return {
      ...state,
      forms: { ...state.forms, [formName]: newForm },
      formMessages: { ...state.formMessages, [formName]: newFormMessages },
    };
  }),
  on(addField, (state, { formName, fieldName }) => {
    const newForm = { ...state.forms[formName] };
    newForm[fieldName] = false;

    const newFormMessages = { ...state.formMessages[formName] };
    newFormMessages[fieldName] = '';

    return {
      ...state,
      forms: { ...state.forms, [formName]: newForm },
      formMessages: { ...state.formMessages, [formName]: newFormMessages },
    };
  }),
  on(setSchemas, (state, { schemas }) => ({ ...state, schemas })),
);
