import { Component, Input } from '@angular/core';

@Component({
  selector: 'errors-notify',
  template: `
    <div
      [ngClass]="{ 'show-notify': !!errors.length }"
      class="mat-mdc-snack-bar-container errors-notify alert notify-template warning no-shadow"
    >
      <div class="mdc-snackbar__surface">
        <span class="fa-stack" *ngIf="errors.length === 1">
          <i class="fa fa-circle-thin fa-stack-2x"></i>
          <i class="fa fa-exclamation fa-stack-1x"></i>
        </span>
        <div
          class="message-container"
          *ngFor="let error of errors"
          [ngStyle]="{ display: errors.length === 1 ? 'inline-block' : 'block' }"
        >
          <p class="message" [innerHTML]="error"></p>
        </div>
      </div>
    </div>
  `,
})
export class ErrorsNotifyComponent {
  @Input() errors: string[];
}
