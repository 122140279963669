import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Job } from '../../jobs.models';
import { JobsResource } from '../../resources/jobs.resource';
import { NotifyService } from '../../../common/services/notify.service';

@Component({
  selector: 'job-details',
  template: `
    <div class="job-preview">
      <job-view
        [item]="loadedJob"
        [childJobs]="childJobs"
        [isLoaded]="isLoaded"
        *ngIf="isLoaded"
        [isLoadingChildren]="isLoadingChildren"
        (childOpen)="childOpen.emit($event)"
        (loadMoreTasks)="loadMoreTasks()"
      ></job-view>
      <xp-loader *ngIf="!isLoaded || isLoadingChildren" class="job-preview-loader"></xp-loader>
    </div>
  `,
})
export class JobDetailsComponent implements OnInit {
  @Input() item: Partial<Job>;
  @Input() isChildren: boolean;
  @Output() childOpen = new EventEmitter<Job>();

  isLoaded = false;
  loadedJob: Job;
  currentPage = 0;
  itemsPerPage = 20;
  loadedAllChildren = false;
  childJobs: Job[];
  isLoadingChildren = false;

  constructor(
    private jobsResource: JobsResource,
    private notify: NotifyService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.jobsResource.get(this.item.id, { include: 'package,cluster,owner' } as any).subscribe({
      next: (item) => {
        if (item.package.flow_type === 'workflow') {
          this.jobsResource
            .children(this.item.id, {
              include: 'package',
              offset: this.currentPage * this.itemsPerPage,
              limit: this.itemsPerPage,
            } as any)
            .subscribe({
              next: (progressTasks) => {
                this.loadedJob = item;
                this.loadedAllChildren = progressTasks.length < this.itemsPerPage;
                this.isLoaded = true;
                this.childJobs = progressTasks;

                if (!this.loadedAllChildren) {
                  this.loadMoreTasks();
                }
              },
              error: (error) => {
                this.notify.error(this.translate.instant(`response.${error.status}.message`));
              },
            });
        } else {
          this.loadedJob = item;
          this.isLoaded = true;
        }
      },
      error: (error) => {
        this.notify.error(this.translate.instant(`response.${error.status}.message`));
      },
    });
  }

  loadMoreTasks() {
    if (this.loadedAllChildren) {
      this.isLoadingChildren = false;
      return;
    }
    this.isLoadingChildren = true;

    this.currentPage += 1;
    this.jobsResource
      .children(this.item.id, {
        include: 'package,cluster,owner',
        offset: this.currentPage * this.itemsPerPage,
        limit: this.itemsPerPage,
      } as any)
      .subscribe({
        next: (progressTasks) => {
          this.loadedAllChildren = progressTasks.length < this.itemsPerPage;
          this.childJobs = [...(this.childJobs || []), ...(progressTasks || [])];
          this.isLoadingChildren = false;

          if (!this.loadedAllChildren) {
            this.loadMoreTasks();
          }
        },
        error: () => {
          this.notify.error('An error occured while loading job data.');
          this.isLoadingChildren = false;
        },
      });
  }
}
