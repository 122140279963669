import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'showNullValues' })
export class ShowNullValuesPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: any): string {
    if (typeof value === 'undefined' || value === null) {
      return 'null';
    }

    return value;
  }
}
