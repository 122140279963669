import { Component } from '@angular/core';
import { groupBy, uniq } from 'lodash';
import { SHORTCUTS_LIST } from '../services/navigation-hotkeys.service';

@Component({
  selector: 'xp-shortcuts',
  template: `
    <div class="shortcut-list">
      <div class="shortcut-group" *ngFor="let category of shortcutsMap.categories">
        <div class="shortcut-item header">
          <strong class="shortcuts-header"> {{ category | translate }}</strong>
        </div>
        <div *ngFor="let shortcut of shortcutsMap.groups[category]" class="shortcut-item">
          <span class="shortcuts-description">{{ shortcut.description | translate }}</span>
          <span class="shortcuts-commands" [innerHTML]="shortcut.command | shortcutSymbols"></span>
        </div>
      </div>
    </div>
  `,
})
export class ShortcutsComponent {
  shortcutsMap = {
    groups: groupBy(SHORTCUTS_LIST, 'category'),
    categories: uniq(SHORTCUTS_LIST.map((item) => item.category)),
  };
}
