import { COMPONENT_TYPE } from '../../constants/component_type';
import { ComponentTypeItem, REST_API_RESPONSE_TYPES } from '../../constants/component_types';
import { AllComponentData, DatabaseSourceComponentData, RECORD_DELIMITER, RECORD_TYPE } from '../package.models';

export const DEFAULT_SCHEMA_SETTINGS = {
  // Auto select all available fields (when selected fields=0)
  autoSelectAllAvailableFields: false,

  // Automatically detect available fields
  automaticallyDetectAvailableFields: false,

  // Pre-defined fields list
  preDefinedFields: [],

  // Allow to sort selected fields
  allowToSortSelectedFields: false,

  // Allow select all available fields
  allowSelectAllAvailableFields: false,

  // Allow Complex data types
  allowComplexDataTypes: false,

  // Preview options
  previewOptions: [],

  // Show Field number
  showFieldNumber: false,

  // Available fields tabs
  availableFieldsTabs: [],

  // Source desginator name
  sourceDesginatorName: 'Column',

  // Show source designator in selected fields
  showSourceDesignatorInSelectedFields: false,

  // Allow to add field between fields
  allowToAddFieldBetweenFields: false,

  // Allow to add fields at the end
  allowToAddFieldsAtTheEnd: false,

  // Allow to remove selected fields
  allowToRemoveSelectedFields: false,

  // Allow to edit source designator
  allowToEditSourceDesignator: false,

  // Allow to edit alias
  allowToEditAlias: false,

  // Allow remove-all
  allowRemoveAll: false,

  // Allow to change data type
  allowToChangeDataType: false,

  // Allow to change data type to string and binary only
  allowToChangeDataTypeToStringBinaryOnly: false,

  // Show preview before field selection
  showPreviewBeforeFieldSelection: false,

  unchangeableDatatypeFields: [],
};

export interface SchemaImporterSettings {
  autoSelectAllAvailableFields: boolean;

  automaticallyDetectAvailableFields: boolean;

  preDefinedFields: any[];

  allowToSortSelectedFields: boolean;

  allowSelectAllAvailableFields: boolean;

  allowComplexDataTypes: boolean;

  previewOptions: string[];

  showFieldNumber: boolean;

  availableFieldsTabs: string[];

  sourceDesginatorName: string;

  showSourceDesignatorInSelectedFields: boolean;

  allowToAddFieldBetweenFields: boolean;

  allowToAddFieldsAtTheEnd: boolean;

  allowToRemoveSelectedFields: boolean;

  allowToEditSourceDesignator: boolean;

  allowToEditAlias: boolean;

  allowRemoveAll: boolean;

  allowToChangeDataType: boolean;

  allowToChangeDataTypeToStringBinaryOnly: boolean;

  showPreviewBeforeFieldSelection: boolean;

  unchangeableDatatypeFields: string[];

  add: boolean;

  showAlias: boolean;

  hasEditableFields: boolean;
}

export function getSettingsForComponent(
  component: ComponentTypeItem,
  rawComponent: Partial<AllComponentData> = {},
): Partial<SchemaImporterSettings> {
  const settings: Partial<SchemaImporterSettings> = { ...DEFAULT_SCHEMA_SETTINGS };

  switch (component.componentType) {
    case COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT:
      settings.sourceDesginatorName = 'Key';
      settings.allowToEditAlias = true;
      settings.allowToChangeDataType = true;
      settings.showAlias = true;
      settings.allowToSortSelectedFields = false;
      settings.allowToAddFieldsAtTheEnd = false;
      settings.hasEditableFields = false;
      settings.add = false;
      settings.allowToAddFieldBetweenFields = false;
      settings.allowToRemoveSelectedFields = false;

      if (
        (rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE &&
          (rawComponent.record_type === RECORD_TYPE.DELIMITED || rawComponent.record_type === RECORD_TYPE.RAW)) ||
        (rawComponent.record_delimiter === RECORD_DELIMITER.EOF && rawComponent.record_type === RECORD_TYPE.RAW)
      ) {
        settings.autoSelectAllAvailableFields = true;
      }

      if (
        (rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE ||
          rawComponent.record_delimiter === RECORD_DELIMITER.EOF) &&
        rawComponent.record_type === RECORD_TYPE.JSON
      ) {
        settings.allowComplexDataTypes = true;
        settings.allowToEditSourceDesignator = true;
      }

      settings.allowSelectAllAvailableFields = !(
        rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE && rawComponent.record_type === RECORD_TYPE.RAW
      );

      if (
        rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE &&
        rawComponent.record_type === RECORD_TYPE.DELIMITED
      ) {
        settings.showFieldNumber = true;
      } else {
        settings.showSourceDesignatorInSelectedFields = true;
      }

      if (
        (rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE ||
          rawComponent.record_delimiter === RECORD_DELIMITER.EOF) &&
        (rawComponent.record_type === RECORD_TYPE.JSON || rawComponent.record_type === RECORD_TYPE.DELIMITED)
      ) {
        settings.allowRemoveAll = true;
      }

      if (
        (rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE ||
          rawComponent.record_delimiter === RECORD_DELIMITER.EOF) &&
        rawComponent.record_type === RECORD_TYPE.RAW
      ) {
        settings.allowToChangeDataTypeToStringBinaryOnly = true;
      }

      if (rawComponent.record_type === RECORD_TYPE.DELIMITED) {
        settings.allowToRemoveSelectedFields = false;
      }

      if (rawComponent.record_type === RECORD_TYPE.RAW) {
        settings.preDefinedFields = [
          {
            name: 'file_path',
            alias: 'file_path',
            data_type: 'string',
            category: 'Meta Data',
            disableSortable: true,
            disableTypeEdit: true,
            required: false,
            shouldBeUnshifted: true,
            predefined: true,
            editable: true,
          },
          {
            name: 'data',
            alias: 'data',
            data_type: 'string',
            category: 'Schema',
            required: true,
            predefined: false,
          },
        ];
      }
      if (rawComponent.record_type === RECORD_TYPE.XML) {
        settings.preDefinedFields = [];
      } else {
        settings.preDefinedFields = [
          {
            name: 'file_path',
            alias: 'file_path',
            data_type: 'string',
            category: 'Meta Data',
            disableSortable: true,
            disableTypeEdit: true,
            required: false,
            shouldBeUnshifted: true,
            predefined: true,
            editable: true,
          },
        ];
      }

      if (rawComponent.record_type === RECORD_TYPE.PARQUET) {
        settings.allowToSortSelectedFields = true;
        settings.allowToAddFieldBetweenFields = true;
        settings.allowToRemoveSelectedFields = true;
        settings.allowToAddFieldsAtTheEnd = true;
        settings.preDefinedFields = [];
      }

      break;

    //  switch will fall through until break statement
    case COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT:
    case COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT:
      settings.allowComplexDataTypes = true;
      settings.allowSelectAllAvailableFields = true;
    // break left out to allow fall through
    case COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT:
    case COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.allowSelectAllAvailableFields = true;
      settings.previewOptions = ['table'];
      settings.availableFieldsTabs = ['schema'];
      settings.sourceDesginatorName = 'Column';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToAddFieldsAtTheEnd = true;
      settings.allowToEditAlias = true;
      settings.allowRemoveAll = true;
      settings.allowToChangeDataType = true;
      settings.showPreviewBeforeFieldSelection = true;
      settings.allowToEditSourceDesignator = true;
      settings.showAlias = true;

      if ((rawComponent as DatabaseSourceComponentData).access_mode === 'table') {
        settings.allowToSortSelectedFields = true;
        settings.allowToAddFieldBetweenFields = true;
        settings.allowToRemoveSelectedFields = true;
      } else if ((rawComponent as DatabaseSourceComponentData).access_mode === 'query') {
        settings.autoSelectAllAvailableFields = true;
      }

      break;

    case COMPONENT_TYPE.MONGO_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.allowToSortSelectedFields = true;
      settings.allowSelectAllAvailableFields = true;
      settings.allowComplexDataTypes = true;
      settings.sourceDesginatorName = 'Key';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToAddFieldBetweenFields = true;
      settings.allowToAddFieldsAtTheEnd = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowToEditSourceDesignator = true;
      settings.allowToEditAlias = true;
      settings.allowRemoveAll = true;
      settings.allowToChangeDataType = true;
      settings.showPreviewBeforeFieldSelection = true;
      settings.showAlias = true;

      break;

    case COMPONENT_TYPE.NET_SUITE_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.allowToSortSelectedFields = true;
      settings.allowSelectAllAvailableFields = true;
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToAddFieldBetweenFields = true;
      settings.allowToAddFieldsAtTheEnd = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowToEditAlias = true;
      settings.allowRemoveAll = true;
      settings.allowToChangeDataType = true;
      settings.showPreviewBeforeFieldSelection = true;

      break;

    case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.allowToSortSelectedFields = true;
      settings.previewOptions = ['table'];
      settings.sourceDesginatorName = 'Field';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowToEditAlias = true;
      settings.allowRemoveAll = true;
      settings.allowToChangeDataType = true;
      settings.showAlias = true;
      break;

    case COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT:
      settings.availableFieldsTabs = ['Fields', 'Meta Data'];
      settings.sourceDesginatorName = 'Field';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowToEditAlias = true;
      settings.allowRemoveAll = true;
      settings.allowToChangeDataType = true;
      settings.showAlias = true;
      settings.preDefinedFields = [
        {
          name: 'customer_id',
          alias: rawComponent.customer_id_field_alias || component.defaults.customer_id_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
      ];
      break;

    case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.previewOptions = ['table'];
      settings.availableFieldsTabs = ['Dimensions', 'Metrics', 'Meta Data'];
      settings.sourceDesginatorName = 'Field';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowRemoveAll = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowToEditAlias = true;
      settings.allowToChangeDataType = true;
      settings.preDefinedFields = [
        {
          name: 'account_name',
          alias: rawComponent.account_name_field_alias || component.defaults.account_name_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
        {
          name: 'property_name',
          alias: rawComponent.property_name_field_alias || component.defaults.property_name_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
        {
          name: 'profile_name',
          alias: rawComponent.profile_name_field_alias || component.defaults.profile_name_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
        {
          name: 'profile_id',
          alias: rawComponent.profile_id_field_alias || component.defaults.profile_id_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
      ];

      break;
    case COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.previewOptions = ['table'];
      settings.availableFieldsTabs = ['Dimensions', 'Metrics', 'Meta Data'];
      settings.sourceDesginatorName = 'Field';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowRemoveAll = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowToEditAlias = true;
      settings.allowToChangeDataType = true;
      settings.preDefinedFields = [
        {
          name: 'account_name',
          alias: rawComponent.account_name_field_alias || component.defaults.account_name_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
        {
          name: 'property_name',
          alias: rawComponent.property_name_field_alias || component.defaults.property_name_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
        {
          name: 'property_id',
          alias: rawComponent.property_id_field_alias || component.defaults.property_id_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
        {
          name: 'account_id',
          alias: rawComponent.account_id_field_alias || component.defaults.account_id_field_alias,
          data_type: 'string',
          category: 'Meta Data',
          disableSortable: true,
          disableTypeEdit: true,
          required: true,
          predefined: true,
        },
      ];

      break;
    case COMPONENT_TYPE.REST_API_SOURCE_COMPONENT:
      if (rawComponent.response_type === REST_API_RESPONSE_TYPES.raw) {
        settings.autoSelectAllAvailableFields = true;
        settings.sourceDesginatorName = 'Field';
        settings.showAlias = true;
        settings.preDefinedFields = [
          {
            name: 'headers',
            alias: 'headers',
            data_type: 'json',
            category: 'Schema',
            required: true,
            predefined: true,
          },
          {
            name: 'body',
            alias: 'body',
            data_type: 'string',
            category: 'Schema',
            required: true,
            predefined: true,
          },
          {
            name: 'status_code',
            alias: 'status_code',
            data_type: 'int',
            category: 'Schema',
            required: true,
            predefined: true,
          },
        ];
        settings.allowToChangeDataType = true;
        settings.allowToChangeDataTypeToStringBinaryOnly = true;
        settings.unchangeableDatatypeFields = ['status_code', 'headers'];
      } else {
        settings.allowToSortSelectedFields = true;
        settings.allowSelectAllAvailableFields = true;
        settings.sourceDesginatorName = 'Key';
        settings.showSourceDesignatorInSelectedFields = true;
        settings.allowToAddFieldBetweenFields = true;
        settings.allowToAddFieldsAtTheEnd = true;
        settings.allowToRemoveSelectedFields = true;
        settings.allowToEditSourceDesignator = true;
        settings.allowRemoveAll = true;
        settings.allowToChangeDataType = true;
        settings.preDefinedFields = [];
      }

      if (rawComponent.response_type === REST_API_RESPONSE_TYPES.line_delimited_data) {
        settings.allowToChangeDataTypeToStringBinaryOnly = true;
      } else if (rawComponent.response_type === REST_API_RESPONSE_TYPES.raw) {
        settings.allowComplexDataTypes = false;
      } else {
        settings.allowComplexDataTypes = true;
      }

      settings.automaticallyDetectAvailableFields = true;
      settings.previewOptions = ['json'];
      settings.allowToEditAlias = true;
      settings.showPreviewBeforeFieldSelection = true;

      break;
    case COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.allowToSortSelectedFields = rawComponent.access_mode !== 'query';
      settings.allowSelectAllAvailableFields = true;
      settings.previewOptions = ['table'];
      settings.sourceDesginatorName = 'Field';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToEditAlias = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowRemoveAll = true;

      if (rawComponent.access_mode === 'query') {
        settings.allowToRemoveSelectedFields = false;
        settings.hasEditableFields = false;
      }

      break;
    case COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT:
      settings.automaticallyDetectAvailableFields = true;
      settings.sourceDesginatorName = 'Field';
      settings.showSourceDesignatorInSelectedFields = true;
      settings.allowToRemoveSelectedFields = true;
      settings.allowRemoveAll = true;
      settings.allowToChangeDataType = true;
      settings.allowToEditAlias = true;

      if (rawComponent.hasTimePeriodField) {
        switch (rawComponent.report_aggregation_type) {
          case 'HOURLY':
            settings.preDefinedFields = [
              {
                name: 'time_period_date',
                alias: rawComponent.time_period_date_field_alias || 'DATE',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
              {
                name: 'time_period_hour',
                alias: rawComponent.time_period_hour_field_alias || 'HOUR',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
            ];

            break;

          case 'DAILY':
          case 'WEEKLY':
            settings.preDefinedFields = [
              {
                name: 'time_period_date',
                alias: rawComponent.time_period_date_field_alias || 'DATE',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
            ];
            break;

          case 'MONTHLY':
            settings.preDefinedFields = [
              {
                name: 'time_period_date',
                alias: rawComponent.time_period_date_field_alias || 'MONTH',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
            ];
            break;

          case 'YEARLY':
            settings.preDefinedFields = [
              {
                name: 'time_period_date',
                alias: rawComponent.time_period_date_field_alias || 'YEAR',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
            ];
            break;

          case 'HOUR_OF_DAY':
            settings.preDefinedFields = [
              {
                name: 'time_period_date',
                alias: rawComponent.time_period_date_field_alias || 'HOUR_OF_DAY',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
            ];
            break;

          case 'DAY_OF_WEEK':
            settings.preDefinedFields = [
              {
                name: 'time_period_date',
                alias: rawComponent.time_period_date_field_alias || 'DAY_OF_WEEK',
                data_type: 'string',
                category: 'Meta Data',
                disableSortable: true,
                disableTypeEdit: true,
                required: true,
                predefined: true,
              },
            ];
            break;
          default:
            break;
        }
      } else {
        settings.preDefinedFields = [];
      }

      break;
    default:
      break;
  }

  return settings;
}
