import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../../common/services/permissions.service';
import { Key } from '../../key.models';
import { NotifyService } from '../../../common/services/notify.service';
import { ConfirmationDialogService } from '../../../common/services/confirmation-dialog.service';
import { AppState } from '../../../store';
import { ItemType } from '../../../common/components/lists/list-item-snippet.component';
import { removeKey } from '../../store/keys/keys.actions';

@Component({
  selector: 'key-list-item',
  template: `
    <div class="generic-list-item slider-animation" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="key-list-item">
        <div class="key-body">
          <strong>{{ item.name }}</strong>
          <code class="ssh-key-fingerprint">{{ item.fingerprint }}</code>
          <div>
            <span>{{ 'ssh_key.generic-list.item.labels.added' | translate }}</span>
            <span
              class="date-tooltip"
              [matTooltip]="item.created_at | xpDateUTC"
              matTooltipPosition="above"
              matTooltipClass="above"
              >{{ item.created_at | xpPrettyDate }}</span
            >
          </div>
        </div>
        <svg
          class="icon icon-delete"
          (click)="onRemove(item)"
          matTooltipPosition="above"
          matTooltipClass="above"
          [matTooltip]="'ssh_key.generic-list.item.actions.delete' | translate"
        >
          <use href="#icon-delete"></use>
        </svg>
      </div>
    </div>
  `,
})
export class KeyListItemComponent {
  @Input() item: Key;
  isRemoved = false;

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private notify: NotifyService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
  ) {}

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  onRemove(item: Key): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('ssh_key.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('ssh_key.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('ssh_key.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('ssh_key.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.key,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.isRemoved = true;
        this.store.dispatch(removeKey({ keyId: item.id }));
      }
    });
  }
}
