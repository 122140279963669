import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConnectionsState } from './connections.reducer';

export const selectConnectionsState = createFeatureSelector<ConnectionsState>('connections');

export const selectConnectionTypes = createSelector(selectConnectionsState, (state: ConnectionsState) => state.types);
export const selectConnectionExtendedTypes = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.extendedTypes,
);
export const selectConnectionGroupTypes = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.groups,
);
export const selectConnectionSelectedType = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.extendedTypes[state.selectedType] || { defaults: {}, button: {} },
);

export const selectConnections = createSelector(selectConnectionsState, (state: ConnectionsState) => state.items);
export const selectConnectionItem = createSelector(selectConnectionsState, (state: ConnectionsState) => state.item);
export const selectConnectionsErrors = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.errors,
);
export const selectConnectionsLoadingFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isLoading,
);
export const selectConnectionsErrorFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isResponseError,
);
export const selectConnectionsLoadingItemFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isLoadingItem,
);
export const selectConnectionsTestingFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isTesting,
);
export const selectConnectionsTestingSuccessMessage = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.testSuccessMessage,
);
export const selectConnectionsIsSubmittingFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isFormSubmitting,
);
export const selectConnectionsIsModalOpenFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isModalOpen,
);
export const selectLastlyCreatedConnection = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.lastlyCreatedConnection,
);

export const selectConnectionsIsLoadingSchemaFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isLoadingSchema,
);
export const selectConnectionsIsLoadingSchemaDataFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.isLoadingSchemaData,
);
export const selectConnectionSchema = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.connectionSchema,
);
export const selectConnectionSchemaData = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.connectionSchemaData,
);
export const selectConnectionSchemaErrors = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.connectionSchemaError,
);

export const selectIsConnectionRemoved = (id: number) =>
  createSelector(selectConnectionsState, (state: ConnectionsState) => !state.items.find((item) => item.id === id));

export const selectConnectionOAuthToken = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.token,
);

export const selectAreAllConnectionsLoadedFlag = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.areAllItemsLoaded,
);

export const selectDatabaseColumns = createSelector(
  selectConnectionsState,
  (state: ConnectionsState) => state.databaseColumns,
);
