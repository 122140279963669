import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PackagesState } from './packages.reducer';

export const selectPackagesState = createFeatureSelector<PackagesState>('packages');

export const selectPackages = createSelector(selectPackagesState, (state: PackagesState) => state.items);
export const selectPackagesErrors = createSelector(selectPackagesState, (state: PackagesState) => state.errors);
export const selectPackagesLoadingFlag = createSelector(selectPackagesState, (state: PackagesState) => state.isLoading);
export const selectPackagesErrorFlag = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.isResponseError,
);
export const selectPackagesIsSubmittingFlag = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.isFormSubmitting,
);
export const selectPackagesIsModalOpenFlag = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.isModalOpen,
);
export const selectPackagesIsVersionModalOpenFlag = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.isVersionModalOpen,
);
export const selectPackagesIsWorkspaceModalOpenFlag = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.isWorkspaceModalOpen,
);
export const selectIsPackageRemoved = (id: number) =>
  createSelector(selectPackagesState, (state: PackagesState) => !state.items.find((item) => item.id === id));
export const selectAreAllPackagesLoadedFlag = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.areAllItemsLoaded,
);

export const isAnyModalOpen = createSelector(
  selectPackagesState,
  (state: PackagesState) => state.isWorkspaceModalOpen || state.isVersionModalOpen || state.isModalOpen,
);
