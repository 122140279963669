/* eslint-disable @typescript-eslint/no-implied-eval,no-new-func */

import { isUndefined as isUndefinedLodash, isEmpty as isEmptyLodash, isNumber as isNumberLodash } from 'lodash';

export const isUndefined = isUndefinedLodash;
export const isNumeric = isNumberLodash;
export const isDefined = (value) => {
  return !isUndefined(value);
};

export const isNull = (value) => {
  return value === null;
};

export const isNotNull = (value) => {
  return !isNull(value);
};

export const isTrue = (value) => {
  return value === true;
};

export const hasLength = (value) => {
  return isDefined(value) && isNotNull(value) && isNumeric(value.length);
};

export const hasNotLength = (value) => {
  return !hasLength(value);
};

export const isFalse = (value) => {
  return value === false;
};

export const isTruthy = (value) => {
  return !!value;
};

export const isFalsy = (value) => {
  return !isTruthy(value);
};

export const isFunction = (value) => {
  return isTruthy(value) && isTruthy(value.constructor) && isTruthy(value.call) && isTruthy(value.apply);
};

export const isEqual = (v1, v2) => {
  return v1 === v2;
};

export const isObject = (value) => {
  return isEqual(typeof value, 'object') && isNotNull(value);
};

export const isBool = (value) => {
  return isTrue(value) || isFalse(value);
};

export const isEmpty = isEmptyLodash;

export const isNotEmpty = (value) => {
  return !isEmpty(value);
};

export const isString = (value) => {
  return isEqual(typeof value, 'string') || value instanceof String;
};

export const isNotNumeric = (value) => {
  return !isNumeric(value);
};

export const isInteger = (value) => {
  return isNumeric(value) && Number(value) % 1 === 0;
};

export const isFloat = (value) => {
  return isNumeric(value) && !isInteger(value);
};

export const isGreater = (value, n) => {
  return value > n;
};

export const isLess = (value, n) => {
  return !(isGreater(value, n) || isEqual(value, n));
};

export const isGreaterOrEqual = (v1, v2) => {
  return isGreater(v1, v2) || isEqual(v1, v2);
};

export const isLessOrEqual = (v1, v2) => {
  return isLess(v1, v2) || isEqual(v1, v2);
};

export const minLength = (value, length) => {
  return isGreaterOrEqual(value.length, length);
};

export const maxLength = (value, length) => {
  return isLessOrEqual(value.length, length);
};

export const exactLength = (value, length) => {
  return isEqual(value.length, length);
};

export const hasLengthIn = (value, min, max, inclusively) => {
  return (inclusively ? isLessOrEqual(value, max) : isLess(value, max)) && isGreaterOrEqual(value, min);
};

export const doesMatch = (value, re) => {
  return re.test(value);
};

export const doesNotMatch = (value, re) => {
  return !doesMatch(value, re);
};

export const isIncludedWithin = (value, collection) => {
  if (typeof value === 'string' && Number.isNaN(Number(value))) {
    return collection.includes(value);
  }
  return isGreaterOrEqual(Number(value), collection[0]) && isLessOrEqual(Number(value), collection[1]);
};

export const isIncludedIn = (value, collection) => {
  return collection.includes(value);
};

export const isNotIncludedIn = (value, collection) => {
  return !isIncludedIn(value, collection);
};

export const isEven = (value) => {
  return isNumeric(value) && Number(value) % 2 === 0;
};

export const isOdd = (value) => {
  return !isEven(value);
};

export const shouldBeExecuted = (options, scope) => {
  if (!isObject(options)) {
    return true;
  }

  return (
    (isDefined(options.if) ? isTruthy(new Function(`return window.${options.if}`).call(scope)) : true) &&
    (isDefined(options.unless) ? isFalsy(new Function(`return window.${options.unless}`).call(scope)) : true)
  );
};

export const shouldNotBeExecuted = (options, scope) => {
  return !shouldBeExecuted(options, scope);
};

export const hasExactLength = (value, expectedLength) => {
  return value.length === expectedLength;
};

export const hasMinimumLength = (value, expectedLength) => {
  return value.length >= expectedLength;
};

export const hasMaximumLength = (value, expectedLength) => {
  return value.length < expectedLength;
};
