import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { Hook, HookEvent, HookType } from '../hook.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class HooksResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  get(hook_id: number, params?: ListParams): Observable<Hook> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Hook>(`${this.apiRoot}/${account_id}/api/hooks/${hook_id}`, { params: params as QueryParams }),
      ),
    );
  }

  save(body: Partial<Hook>, params?: ListParams): Observable<Hook> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Hook>(`${this.apiRoot}/${account_id}/api/hooks`, body, { params: params as QueryParams }),
      ),
    );
  }

  update(hook_id: number, body: Partial<Hook>, params?: ListParams): Observable<Hook> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<Hook>(`${this.apiRoot}/${account_id}/api/hooks/${hook_id}`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  remove(hook_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<Hook>(`${this.apiRoot}/${account_id}/api/hooks/${hook_id}`, { params: params as QueryParams }),
      ),
    );
  }

  query(params?: ListParams): Observable<Hook[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Hook[]>(`${this.apiRoot}/${account_id}/api/hooks`, { params: params as QueryParams }),
      ),
    );
  }

  search(params?: ListParams): Observable<Hook[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Hook[]>(`${this.apiRoot}/${account_id}/api/hooks/search`, { params: params as QueryParams }),
      ),
    );
  }

  ping(hook_id: number, params?: ListParams): Observable<Hook> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Hook>(`${this.apiRoot}/${account_id}/api/hooks/${hook_id}/ping`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  types(params?: ListParams): Observable<HookType[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<HookType[]>(`${this.apiRoot}/${account_id}/api/hooks/types`, { params: params as QueryParams }),
      ),
    );
  }

  hookEvents(params?: ListParams): Observable<HookEvent[]> {
    return this.http.get<HookEvent[]>(`${this.apiRoot}/hook_events`, { params: params as QueryParams });
  }
}
