import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Credentials } from '../../common/services/auth.service';
import { UsersResource } from '../resources/users.resource';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sign-up-free-trial',
  template: `
    <div class="panel">
      <div class="panel-header">
        <div class="panel-logo">
          <img class="logo-img" width="80" height="80" src="assets/img/xplenty-logo.svg" alt="Prepforce" />
        </div>
        <h3 class="panel-title">{{ 'auth.sign_up.form.title' | translate }}</h3>
      </div>
      <div class="panel-body">
        <xp-form-validation type="User">
          <form
            role="form"
            name="signupForm"
            #form="ngForm"
            [attr.data-action-url]="PIPEDRIVE_URL"
            id="free-trial-form"
          >
            <input type="hidden" name="retURL" [value]="PIPEDRIVE_RETURN_URL" />
            <input type="hidden" name="formId" value="dashboard_free_trial" />
            <fieldset>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.name' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.name"
                  [placeholder]="'auth.sign_up.form.placeholders.name' | translate"
                  name="ad437a8f5ec7297efb125cca8d4792d7f34ec617"
                  type="text"
                  autofocus
                />
              </xp-form-group>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.email' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.email"
                  [placeholder]="'auth.sign_up.form.placeholders.email' | translate"
                  name="a53b75318b9a01eddadd7d3353bbd5d832d022"
                  type="email"
                  maxlength="254"
                />
              </xp-form-group>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.company' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.company"
                  [placeholder]="'auth.sign_up.form.placeholders.email' | translate"
                  name="7361f587f91f5330d9b17472abe6244fd9cd3da6"
                  type="text"
                  maxlength="254"
                />
              </xp-form-group>
            </fieldset>
            <xp-submit-button
              (submitForm)="submit()"
              classNames="btn-lg btn-block btn-primary"
              [isFormValid]="form.valid"
              [isFormSubmitting]="isLoading"
              [updateText]="'auth.sign_up.form.actions.sign_up' | translate"
            ></xp-submit-button>
          </form>
        </xp-form-validation>
      </div>
      <div class="panel-footer text-center panel-links">
        <span>{{ 'auth.sign_up.form.labels.have_account' | translate }}</span>
        <a (click)="goToSignIn()">{{ 'auth.sign_up.form.actions.sign_in' | translate }}</a>
      </div>
    </div>
    <help-menu></help-menu>
  `,
})
export class SignUpFreeTrialComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  credentials: Credentials = {
    email: '',
    password: '',
  };
  isLoading = false;
  PIPEDRIVE_URL = environment.PIPEDRIVE_URL;
  PIPEDRIVE_RETURN_URL = environment.PIPEDRIVE_RETURN_URL;
  utm_campaign = '';
  utm_medium = '';
  utm_source = '';

  constructor(
    private usersResource: UsersResource,
    private router: Router,
  ) {
    // @ts-ignore
    window.email_required = true;
  }

  // eslint-disable-next-line class-methods-use-this
  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.utm_campaign = urlParams.get('utm_campaign');
    this.utm_medium = urlParams.get('utm_medium');
    this.utm_source = urlParams.get('utm_source');

    if (document.referrer.includes('appexchange.salesforce.com') && this.utm_source) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].identify({
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
      });

      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].track('appexchange.salesforce.com visitor', {
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
      });
    }
  }

  submit() {
    const form = document.querySelector('#free-trial-form') as HTMLFormElement;
    this.isLoading = true;

    if (form) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].identify({
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
      });

      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].track('Free Trial Sign Up', {
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
        name: this.credentials.name,
        email: this.credentials.email,
        company: this.credentials.company,
      });

      fetch(form.getAttribute('data-action-url'), {
        method: 'POST',
        body: new URLSearchParams(new FormData(form) as any),
        mode: 'no-cors',
      }).then(() => {
        this.usersResource.save({ ...this.credentials, grant_type: 'password' }).subscribe({
          next: () => {
            this.isLoading = false;
            this.router.navigate(['/auth/thank-you']);
          },
          error: () => {
            this.isLoading = false;
          },
        });
      });
    }
  }

  goToSignIn() {
    this.router.navigate(['/auth/login']);
  }
}
