import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenericListType } from './generic-list.component';

@Component({
  selector: 'generic-list-empty',
  template: `
    <div class="generic-list-empty">
      <div
        *ngIf="lastAction === 'list'"
        [ngSwitch]="
          type === types.connections ||
          type === types.jobs ||
          type === types.packages ||
          type === types.clusters ||
          type === types.schedules ||
          type === types.workspaces
        "
      >
        <div class="generic-list-empty-search text-left about-box" *ngSwitchCase="true">
          <div class="row">
            <div class="col-md-6 left-pane {{ type }}">
              <h4 class="clearfix" *ngIf="lastAction === 'list' && emptyTitle">
                <div class="icon-container pull-left">
                  <svg class="empty-list-icon icon" [ngSwitch]="type">
                    <use
                      *ngSwitchCase="types.packages"
                      class="icon-package"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-package"
                    ></use>
                    <use
                      *ngSwitchCase="types.connections"
                      class="icon-connection"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-connection"
                    ></use>
                    <use
                      *ngSwitchCase="types.jobs"
                      class="icon-job"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-job"
                    ></use>
                    <use
                      *ngSwitchCase="types.clusters"
                      class="icon-cluster"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-cluster"
                    ></use>
                    <use
                      *ngSwitchCase="types.workspaces"
                      class="icon-workspace"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-workspace"
                    ></use>
                    <use
                      *ngSwitchCase="types.schedules"
                      class="icon-schedules"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-schedules"
                    ></use>
                    <use
                      *ngSwitchCase="types.members"
                      class="icons-master-expanded_icon-list-members"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icons-master-expanded_icon-list-members"
                    ></use>
                    <use
                      *ngSwitchCase="types.hooks"
                      class="icon-settings"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-settings"
                    ></use>
                    <use
                      *ngSwitchCase="types.accounts"
                      class="icon-settings"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-settings"
                    ></use>
                    <use
                      *ngSwitchCase="types.keys"
                      class="icon-settings"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      href="#icon-settings"
                    ></use>
                  </svg>
                </div>
                <div class="pull-left">{{ emptyTitle | translate }}</div>
              </h4>
              <hr />
              <dl>
                <dt>{{ 'about.' + type + '.sections.1.title' | translate }}</dt>
                <dd>{{ 'about.' + type + '.sections.1.description' | translate }}</dd>
                <dt>{{ 'about.' + type + '.sections.2.title' | translate }}</dt>
                <dd>{{ 'about.' + type + '.sections.2.description' | translate }}</dd>
                <dt>{{ 'about.' + type + '.sections.3.title' | translate }}</dt>
                <dd>{{ 'about.' + type + '.sections.3.description' | translate }}</dd>
              </dl>
            </div>
            <div class="col-md-6 right-pane {{ type }}">
              <img class="hero-img" [src]="getListImage(type)" alt="{{ type }}" />
            </div>
          </div>
        </div>
        <div class="generic-list-empty-search text-center" *ngSwitchCase="false">
          <svg class="empty-list-icon icon" [ngSwitch]="type">
            <use
              *ngSwitchCase="types.packages"
              class="icon-package"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-package"
            ></use>
            <use
              *ngSwitchCase="types.connections"
              class="icon-connection"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-connection"
            ></use>
            <use
              *ngSwitchCase="types.jobs"
              class="icon-job"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-job"
            ></use>
            <use
              *ngSwitchCase="types.clusters"
              class="icon-cluster"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-cluster"
            ></use>
            <use
              *ngSwitchCase="types.schedules"
              class="icon-schedules"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-schedules"
            ></use>
            <use
              *ngSwitchCase="types.members"
              class="icons-master-expanded_icon-list-members"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icons-master-expanded_icon-list-members"
            ></use>
            <use
              *ngSwitchCase="types.hooks"
              class="icon-settings"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-settings"
            ></use>
            <use
              *ngSwitchCase="types.accounts"
              class="icon-settings"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-settings"
            ></use>
            <use
              *ngSwitchCase="types.keys"
              class="icon-settings"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              href="#icon-settings"
            ></use>
          </svg>
          <h3 class="lead" *ngIf="lastAction === 'list' && emptyTitle">{{ emptyTitle | translate }}</h3>
          <p *ngIf="lastAction === 'list' && emptyDescription">{{ emptyDescription | translate }}</p>
        </div>
      </div>
      <div *ngIf="lastAction === 'search'" class="generic-list-empty-search text-center">
        <svg class="empty-list-icon icon" [ngSwitch]="type">
          <use
            *ngSwitchCase="types.packages"
            class="icon-package"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-package"
          ></use>
          <use
            *ngSwitchCase="types.connections"
            class="icon-connection"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-connection"
          ></use>
          <use
            *ngSwitchCase="types.jobs"
            class="icon-job"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-job"
          ></use>
          <use
            *ngSwitchCase="types.clusters"
            class="icon-cluster"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-cluster"
          ></use>
          <use
            *ngSwitchCase="types.schedules"
            class="icon-schedules"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-schedules"
          ></use>
          <use
            *ngSwitchCase="types.members"
            class="icons-master-expanded_icon-list-members"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icons-master-expanded_icon-list-members"
          ></use>
          <use
            *ngSwitchCase="types.hooks"
            class="icon-settings"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-settings"
          ></use>
          <use
            *ngSwitchCase="types.accounts"
            class="icon-settings"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-settings"
          ></use>
          <use
            *ngSwitchCase="types.keys"
            class="icon-settings"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            href="#icon-settings"
          ></use>
        </svg>
        <h3 class="lead" *ngIf="emptyTitle">
          {{ 'generic-list.labels.empty_search_description' | translate : { type: translateType(type) } }}
          <strong>"{{ search }}"</strong>
        </h3>
      </div>
    </div>
  `,
})
export class GenericListEmptyComponent {
  @Input() type: GenericListType;
  @Input() emptyTitle: string;
  @Input() emptyDescription: string;
  @Input() lastAction: 'search' | 'list' = 'list';
  @Input() search = '';
  types = GenericListType;

  constructor(private translate: TranslateService) {}

  translateType(type: GenericListType) {
    return this.translate.instant(`generic-list.resources.${type}`).toLowerCase();
  }

  // eslint-disable-next-line class-methods-use-this
  getListImage(type: GenericListType) {
    return `assets/img/lists/${type}@2x.png`;
  }
}
