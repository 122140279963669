import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'xpLengthCheck' })
export class XpLengthCheckPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string = '', len: number): string {
    const newValue = (value || '').replaceAll(/<a[^>]*>(.*?)<\/a>/gi, '$1');

    return (newValue || '').length > len ? `${newValue.substring(0, len)}...` : newValue;
  }
}
