import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemType, ListItemSnippetType } from '../../components/lists/list-item-snippet.component';

export enum DialogType {
  danger = 'danger',
  success = 'success',
  info = 'info',
  warning = 'warning',
}

export interface DialogData {
  title: string;
  hint: string;
  yes: string;
  no: string;
  type: DialogType;
  itemState: string;
  item?: ListItemSnippetType;
  itemType?: ItemType;
  hideSnippet?: boolean;
  maxWidth?: string;
  minWidth?: string;
}

@Component({
  selector: 'unlock-features-dialog-template',
  template: `
    <div class="dialog confirm alert alert-danger text-center">
      <div class="dialog-text">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="danger-icon"
        >
          <path
            d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke="#cccccc"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <p style="    margin-top: 20px; font-size: 20px;">
          This feature is available on our Integrate.io platform.
          <span (click)="openIntercom()" class="link">Speak with one of our team now</span> to get access or schedule a
          time for a tailored demo <a href="https://www.integrate.io/demo/?utm_source=prepforce" class="link">here</a>.
        </p>
      </div>
      <div class="dialog-close" (click)="cancel()"></div>
    </div>
  `,
})
export class UnlockFeaturesDialogTemplateComponent {
  constructor(public dialogRef: MatDialogRef<UnlockFeaturesDialogTemplateComponent>) {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

  openIntercom() {
    if (window['Intercom']) {
      window['Intercom']('show');
    }
  }
}
