import { createReducer, on } from '@ngrx/store';
import { Member } from '../../members.models';
import {
  closeEditRolesMembersModal,
  closeMembersModal,
  getMember,
  getMemberResponse,
  getMembersList,
  getMembersListError,
  getMembersListResponse,
  loadMoreMembersList,
  loadMoreMembersListResponse,
  openEditRolesMembersModal,
  openMembersModal,
  removeMemberItem,
  removeMemberResponse,
  saveMember,
  saveMemberError,
  saveMemberResponse,
  updateMemberResponse,
} from './members.actions';
import { ValidationError } from '../../../config/validation-error.model';
import { closeAllModals } from '../../../account/store/account.actions';
import { QueryParamsMembersList } from '../../../common/helper/query-params-generic-list.helper';

export const initialState: MembersState = {
  items: [],
  item: null,
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  isEditRolesModalOpen: false,
  errors: [],
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface MembersState {
  items: Member[];
  item: Member;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  isEditRolesModalOpen: boolean;
  errors: ValidationError[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const membersReducer = createReducer<MembersState>(
  initialState,

  on(openMembersModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeMembersModal, (state) => ({ ...state, isModalOpen: false })),
  on(openEditRolesMembersModal, (state) => ({ ...state, isEditRolesModalOpen: true })),
  on(closeEditRolesMembersModal, (state) => ({ ...state, isEditRolesModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false, isEditRolesModalOpen: false })),

  on(getMember, (state) => ({ ...state, isLoading: true })),
  on(getMemberResponse, (state, { member }) => ({ ...state, isLoading: false, item: member })),

  on(getMembersList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getMembersListResponse, (state, { members }) => ({
    ...state,
    isLoading: false,
    items: members,
    areAllItemsLoaded: members.length < QueryParamsMembersList.limit,
  })),
  on(getMembersListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(loadMoreMembersList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreMembersListResponse, (state, { members }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...members],
    areAllItemsLoaded: members.length < QueryParamsMembersList.limit,
  })),

  on(saveMember, (state) => ({ ...state, isFormSubmitting: true })),
  on(saveMemberResponse, (state, { data }) => ({
    ...state,
    isFormSubmitting: false,
    items: [data, ...state.items],
    isModalOpen: false,
  })),
  on(saveMemberError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(updateMemberResponse, (state, { data }) => ({
    ...state,
    items: data ? state.items.map((item) => (item.id === data.id ? data : item)) : state.items,
  })),

  on(removeMemberItem, (state, { member }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== member.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeMemberResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
