import { SelectOption } from '../common/components/forms/xp-select.component';

export enum SALESFORCE_API_VERSION {
  V1 = 'BulkV1',
  V2 = 'BulkV2',
}

export const SALESFORCE_API_VERSIONS: SelectOption[] = [
  {
    text: 'Bulk API 2.0',
    value: SALESFORCE_API_VERSION.V2,
  },
  {
    text: 'Bulk API 1.0',
    value: SALESFORCE_API_VERSION.V1,
  },
];
