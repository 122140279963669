import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { filter, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  camelCase,
  getFullUrlPath,
  joinUrl,
  prepareOptions,
  stringifyOptions,
} from '../helpers/connections-authentication.helpers';
import {
  connectionsAuthenticationConfig,
  ConnectionsAuthenticationProvider,
  OAuthName,
  PopupOptions,
} from '../helpers/connections-authentication.config';
import { selectConnectionOAuthToken } from '../store/connections.selectors';
import { AppState } from '../../store';
import { lastValueFrom } from 'rxjs';

export interface AuthenticationResponse {
  token?: string;
  access_token?: string | { data: any };
  config?: {
    data: {
      hmac: string;
      timestamp: string;
      redirectUri: string;
      code: string;
    };
  };
  data?: any;
}

interface UserData {
  access_token: string;
  shop: string;
  environment: string;
  state?: string;
  account_id?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionAuthenticationService {
  oAuth2Defaults: Partial<ConnectionsAuthenticationProvider> = {
    defaultUrlParams: ['response_type', 'client_id', 'redirect_uri', 'account_id'],
    responseType: 'code',
    responseParams: {
      code: 'code',
      clientId: 'clientId',
      redirectUri: 'redirectUri',
    },
  };

  popup = {
    url: '',
    popupWindow: null,
  };

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  private openPopup(url: string, name: string, options: PopupOptions) {
    this.popup.url = url;

    const stringifiedOptions = stringifyOptions(prepareOptions(options));
    const UA = window.navigator.userAgent;
    const windowName = UA.indexOf('CriOS') > -1 ? '_blank' : name;

    this.popup.popupWindow = window.open(url, windowName, stringifiedOptions);

    if (this.popup.popupWindow && this.popup.popupWindow.focus) {
      this.popup.popupWindow.focus();
    }

    return this.pollPopup.bind(this);
  }

  private pollPopup(redirectUri: string): Promise<{ token: string }> {
    return new Promise((resolve, reject) => {
      const redirectUriParser = document.createElement('a');
      redirectUriParser.href = redirectUri;

      const redirectUriPath = getFullUrlPath(redirectUriParser as any);

      const polling = setInterval(() => {
        if (!this.popup.popupWindow || this.popup.popupWindow.closed || this.popup.popupWindow.closed === undefined) {
          reject(new Error('The popup window was closed.'));
          clearInterval(polling);
        }

        try {
          const popupWindowPath = getFullUrlPath(this.popup.popupWindow.location);

          // Redirect has occurred.
          if (popupWindowPath === redirectUriPath) {
            // Contains query/hash parameters as expected.
            this.store
              .select(selectConnectionOAuthToken)
              .pipe(filter(Boolean), first())
              .subscribe((token: string) => {
                resolve({ token });
              });

            clearInterval(polling);
            this.popup.popupWindow.close();
          }
        } catch (error) {
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
          // A hack to get around same-origin security policy errors in IE.
        }
      }, 20);
    });
  }

  private buildQueryString(userData: Partial<UserData>): string {
    const keyValuePairs = [];
    const urlParamsCategories = ['defaultUrlParams', 'requiredUrlParams', 'optionalUrlParams'];

    urlParamsCategories.forEach((paramsCategory) => {
      (this.oAuth2Defaults[paramsCategory] || []).forEach((paramName) => {
        const camelizedName = camelCase(paramName);
        let paramValue =
          typeof this.oAuth2Defaults[paramName] === 'function'
            ? this.oAuth2Defaults[paramName]()
            : this.oAuth2Defaults[camelizedName];

        if (paramName === 'redirect_uri' && !paramValue) {
          return;
        }

        if (paramName === 'account_id') {
          paramValue = userData.account_id;
        }

        if (paramName === 'state') {
          const stateName = `${this.oAuth2Defaults.name}_state`;
          paramValue = encodeURIComponent(window.localStorage.getItem(stateName));
        }

        if (paramName === 'scope' && Array.isArray(paramValue)) {
          paramValue = paramValue.join(this.oAuth2Defaults.scopeDelimiter);

          if (this.oAuth2Defaults.scopePrefix) {
            paramValue = [this.oAuth2Defaults.scopePrefix, paramValue].join(this.oAuth2Defaults.scopeDelimiter);
          }
        }

        keyValuePairs.push([paramName, paramValue]);
      });
    });

    return keyValuePairs
      .map(function (pair) {
        return pair.join('=');
      })
      .join('&');
  }

  private exchangeForToken(
    oauthData: ConnectionsAuthenticationProvider,
    userData: Partial<UserData>,
  ): Promise<AuthenticationResponse> {
    const data = { ...userData };

    Object.keys(this.oAuth2Defaults.responseParams).forEach((key) => {
      const value = this.oAuth2Defaults.responseParams[key];
      switch (key) {
        case 'code':
          data[value] = oauthData.code;
          break;
        case 'clientId':
          data[value] = this.oAuth2Defaults.clientId;
          break;
        case 'redirectUri':
          data[value] = this.oAuth2Defaults.redirectUri;
          break;
        default:
          data[value] = oauthData[key];
      }
    });

    if (oauthData.state) {
      data.state = oauthData.state;
    }

    const exchangeForTokenUrl = connectionsAuthenticationConfig.baseUrl
      ? joinUrl(connectionsAuthenticationConfig.baseUrl, this.oAuth2Defaults.url)
      : this.oAuth2Defaults.url;

    return lastValueFrom(
      this.http.post(exchangeForTokenUrl, data, { withCredentials: connectionsAuthenticationConfig.withCredentials }),
    );
  }

  private openOAuth2(
    options: ConnectionsAuthenticationProvider,
    userData: Partial<UserData>,
  ): Promise<AuthenticationResponse> {
    this.oAuth2Defaults = merge({ ...this.oAuth2Defaults }, { ...options });

    const stateName = `${this.oAuth2Defaults.name}_state`;

    window.localStorage.setItem(stateName, this.oAuth2Defaults.state);

    const url = [this.oAuth2Defaults.authorizationEndpoint, this.buildQueryString(userData)].join('?');

    return this.openPopup(
      url,
      this.oAuth2Defaults.name,
      this.oAuth2Defaults.popupOptions,
    )(this.oAuth2Defaults.redirectUri).then((oauthData: any) => {
      // When no server URL provided, return popup params as-is.
      // This is for a scenario when someone wishes to opt out from
      // Satellizer's magic by doing authorization code exchange and
      // saving a token manually.
      if (this.oAuth2Defaults.responseType === 'token' || !this.oAuth2Defaults.url) {
        return oauthData;
      }

      if (oauthData.state && oauthData.state !== window.localStorage.getItem(stateName)) {
        throw new Error(
          'The value returned in the state parameter does not match the state value from your original ' +
            'authorization code request.',
        );
      }

      return this.exchangeForToken(oauthData, userData);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private setToken(response: AuthenticationResponse): void {
    const tokenName = connectionsAuthenticationConfig.tokenPrefix
      ? [connectionsAuthenticationConfig.tokenPrefix, connectionsAuthenticationConfig.tokenName].join('_')
      : connectionsAuthenticationConfig.tokenName;

    const accessToken = response && response.access_token;
    let token;

    if (accessToken) {
      if (typeof accessToken === 'object' && typeof accessToken.data === 'object') {
        // eslint-disable-next-line no-param-reassign
        response = accessToken;
      } else if (typeof accessToken === 'string') {
        token = accessToken;
      }
    }

    if (!token && response) {
      const tokenRootData =
        connectionsAuthenticationConfig.tokenRoot &&
        connectionsAuthenticationConfig.tokenRoot.split('.').reduce(function (o, x) {
          return o[x];
        }, response.data);
      token = tokenRootData
        ? tokenRootData[connectionsAuthenticationConfig.tokenName]
        : response.data && response.data[connectionsAuthenticationConfig.tokenName];
    }

    window.localStorage.setItem(tokenName, token);
  }

  public authenticate(name: OAuthName, userData: Partial<UserData> = {}): Promise<AuthenticationResponse> {
    const providerData: ConnectionsAuthenticationProvider = { ...connectionsAuthenticationConfig.providers[name] };

    if (name === OAuthName.shopify) {
      providerData.shop = userData.shop;
    }
    if (name === OAuthName.salesforce || name === OAuthName.salesforceSandbox) {
      providerData.environment = userData.environment;
    }
    providerData.accessToken = userData.access_token;

    return this.openOAuth2(providerData, userData).then((response) => {
      if (connectionsAuthenticationConfig.providers[name].url) {
        this.setToken(response);
      }
      return response;
    });
  }
}
