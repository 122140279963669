import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { AppState } from '../../store';
import { closePackagePickerModal, openPackagePickerModal } from '../store/schedules.actions';
import { selectPackagePickerIsModalOpenFlag } from '../store/schedules.selectors';
import { Package } from '../../packages/package.models';
import { PackagesResource } from '../../packages/resources/packages.resource';
import { NotifyService } from '../../common/services/notify.service';

@Component({
  selector: 'package-picker',
  template: `
    <div class="packages-picker">
      <div class="packages-picker-list">
        <xp-package
          class="package"
          *ngFor="let packageItem of packages"
          [package]="packageItem"
          (remove)="removePackage($event)"
          (edit)="editPackage($event)"
        ></xp-package>
      </div>
      <small>{{ 'packages-picker.list-comment' | translate }}</small>
      <a class="btn btn-info pull-right" (click)="addPackage()">{{ 'packages-picker.add-package' | translate }}</a>
    </div>
    <xp-modal [isOpen]="isModalOpen$ | async" (close)="onModalClose()">
      <ng-template>
        <package-picker-form
          [item]="formItem"
          (packageAdded)="onPackageAdded($event)"
          (packageEdited)="onPackageEdited($event)"
        ></package-picker-form>
      </ng-template>
    </xp-modal>
  `,
})
export class PackagePickerComponent implements OnInit {
  @Input() packages: Package[] = [];
  @Output() packagesChange = new EventEmitter<Partial<Package>[]>();
  isModalOpen$ = this.store.select(selectPackagePickerIsModalOpenFlag);
  formItem: Partial<Package> = {};

  constructor(
    private store: Store<AppState>,
    private packagesResource: PackagesResource,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.packages = this.packages.map((item) => ({ ...item, uuid: uuidv4() }));
  }

  onPackageAdded(packageItem: Partial<Package>) {
    const packages = [...this.packages, { ...packageItem, uuid: uuidv4() }];
    this.packagesChange.emit(packages);
  }

  onPackageEdited(packageItem: Partial<Package>) {
    let packages;

    if (this.packages.find((item) => item.uuid === packageItem.uuid)) {
      packages = this.packages.map((item) => (item.uuid === packageItem.uuid ? packageItem : item));
    } else {
      packages = this.packages.map((item) => (item.uuid === this.formItem.uuid ? packageItem : item));
    }
    this.packagesChange.emit(packages);
  }

  editPackage(packageItem: Package) {
    this.packagesResource.get(packageItem.id).subscribe({
      next: (packageItemResponse) => {
        this.formItem = { ...packageItem, variables: { ...packageItemResponse.variables, ...packageItem.variables } };
        this.store.dispatch(openPackagePickerModal({ isEdit: true }));
      },
      error: () => {
        this.notify.error('An error occurred while loading package data.');
      },
    });
  }

  addPackage() {
    this.formItem = {};
    this.store.dispatch(openPackagePickerModal({ isEdit: false }));
  }

  removePackage(item: Package) {
    const packages = this.packages.filter((packageItem) => packageItem.id !== item.id);

    this.packagesChange.emit(packages);
  }

  onModalClose(): void {
    this.store.dispatch(closePackagePickerModal());
  }
}
