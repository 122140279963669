import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../store';
import { SettingsState } from '../settings.state';

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectMember = createSelector(selectSettingsState, (state: SettingsState) => state.members.item);
export const selectMembers = createSelector(selectSettingsState, (state: SettingsState) => state.members.items);
export const selectareAllMembersLoaded = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.areAllItemsLoaded,
);
export const selectMembersErrors = createSelector(selectSettingsState, (state: SettingsState) => state.members.errors);
export const selectMembersLoadingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.isLoading,
);
export const selectMembersErrorFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.isResponseError,
);
export const selectMembersIsSubmittingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.isFormSubmitting,
);
export const selectMembersIsModalOpenFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.isModalOpen,
);
export const selectMembersIsEditRoleModalOpenFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.isEditRolesModalOpen,
);
export const selectAreAllMembersLoadedFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.members.areAllItemsLoaded,
);
export const selectIsMemberRemoved = (id: number) =>
  createSelector(selectSettingsState, (state: SettingsState) => !state.members.items.find((item) => item.id === id));
