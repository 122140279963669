import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../store';
import { AppCommonState } from './app-common.reducer';

export const selectAppCommonState = createFeatureSelector<AppCommonState>('appCommon');

export const selectIsShortcutsModalOpenFlag = createSelector(
  selectAppCommonState,
  (state: AppCommonState) => state.isShortcutsModalOpen,
);
