import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Step } from './xp-steps.component';

@Component({
  selector: 'xp-step-index',
  template: `
    <div
      class="step-header-index"
      [ngClass]="{
        valid: step.valid,
        active: step.active,
        lock: step.lock,
        error: step.isError,
        loading: step.isLoading
      }"
    >
      <span>0{{ step.index + 1 }}</span>
      <!--      <i *ngIf="step.lock && !step.valid && !step.isError && !step.isLoading" class="fa fa-lock"></i>-->
      <!--      <i *ngIf="step.valid && !step.isError && !step.isLoading" class="fa fa-check"></i>-->
      <!--      <i *ngIf="step.isError && !step.isLoading" class="fa fa-exclamation-triangle"></i>-->
      <!--      <svg class="circleFill" [ngClass]="{ filled: addFilledClass }">-->
      <!--        <circle class="path" cx="22" cy="22" r="20" fill="none"></circle>-->
      <!--      </svg>-->
      <!--      <svg class="circleTrack">-->
      <!--        <circle cx="22" cy="22" r="20" fill="none"></circle>-->
      <!--      </svg>-->
    </div>
  `,
})
export class XpStepIndexComponent implements OnChanges {
  @Input() step: Partial<Step>;
  addFilledClass = true;

  ngOnChanges(changes: SimpleChanges) {
    const currentStep = changes.step.currentValue;
    const previousStep = changes.step.previousValue;

    if (currentStep && currentStep.valid && (!previousStep || !previousStep.valid)) {
      setTimeout(() => {
        this.addFilledClass = false;
      }, 500);
    }

    if (currentStep && !currentStep.valid) {
      setTimeout(() => {
        this.addFilledClass = true;
      }, 500);
    }
  }
}
