import { Component, OnInit } from '@angular/core';
import { VariableItem, Variables } from '../../../package-designer/package.models';
import { VariablesResource } from '../../../account/resources/variables.resource';
import { forIn, cloneDeep } from 'lodash';
import { parser } from '../../../services/pig-parser';
import { NotifyService } from '../../../common/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, zip } from 'rxjs';
import { LabServicesResource, LabServicesResponse } from '../../../help/resources/lab-services.resource';
import { map, tap } from 'rxjs/operators';
import { ParserI } from '../../../services/pig-parser.interface';
import { PermissionsService } from '../../../common/services/permissions.service';

@Component({
  selector: 'global-variables',
  template: `
    <div class="global-variables-container settings-body">
      <h2>{{ 'global_variables.title' | translate }}</h2>
      <p [innerHTML]="'global_variables.description' | translate"></p>

      <variables-editor
        class="user-variables"
        [items]="globalVariables"
        type="global_variables"
        [emptyMessage]="'variables-editor.empty-message.global-variables'"
        [ngClass]="{ editable: true }"
        [showUndo]="false"
        [usePackageDefault]="false"
        [hideTooltip]="true"
        [hideGlobalVariablesMessage]="true"
        showPlaceholder="standard"
        [readonlyValue]="!(hasPermission$('updateGlobalVariables') | async)"
        [readonlyKey]="!(hasPermission$('updateGlobalVariables') | async)"
        (variablesChange)="onChange($event)"
      ></variables-editor>

      <div class="form-group">
        <button
          *ngxPermissionsOnly="'updateGlobalVariables'"
          class="btn btn-md btn-success"
          data-action="submit"
          [ngClass]="isFormSubmitting ? ' loading' : ''"
          [disabled]="isFormSubmitting"
          (click)="saveVariables()"
        >
          <span>Validate and Save</span>
        </button>
      </div>
    </div>
  `,
})
export class GlobalVariablesComponent implements OnInit {
  globalVariables: VariableItem[] = [];
  initialGlobalVariables: VariableItem[] = [];
  isFormSubmitting = false;

  constructor(
    private variablesResource: VariablesResource,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private LabServices: LabServicesResource,
    private permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.variablesResource.getGlobalVariables().subscribe({
      next: (response) => {
        this.globalVariables = this.prepareVariables(response.global_variables);
        this.initialGlobalVariables = cloneDeep(this.globalVariables);
      },
      error: () => {
        this.notify.error('An error occurred while getting global variables.');
      },
    });
  }

  saveVariables() {
    this.isFormSubmitting = true;

    this.validateVariables().subscribe((areVariablesValid) => {
      if (areVariablesValid) {
        const variablesToSave = {};
        this.globalVariables.forEach((v) => {
          variablesToSave[v.key] = v.value;
        });

        this.variablesResource.setGlobalVariables(variablesToSave).subscribe({
          next: () => {
            this.isFormSubmitting = false;
            this.notify.success(this.translate.instant('global_variables.success_message'), '');
          },
          error: () => {
            this.notify.error('An error occurred while setting global variables. Please try again.');
          },
        });
      } else {
        this.isFormSubmitting = false;
      }
    });
  }

  cleanErrors() {
    this.globalVariables = this.globalVariables.map((variable) => {
      return {
        ...variable,
        error: '',
      };
    });
  }

  validateVariables(): Observable<boolean> {
    this.cleanErrors();

    const changedVariables = this.changedVariables();
    if (changedVariables.length === 0) {
      return of(true);
    }

    const variablesToValidate = changedVariables.map((variable) => {
      try {
        return { value: (parser as ParserI).parse(variable.value), index: variable.index };
      } catch (e) {
        this.globalVariables[variable.index].error = e.message;
      }
    });

    if (variablesToValidate.filter(Boolean).length === 0) {
      return of(false);
    }

    return zip(
      ...variablesToValidate.filter((item) => item.value).map((variable) => this.LabServices.evaluate(variable.value)),
    ).pipe(
      tap((results: LabServicesResponse[]) => {
        this.globalVariables = this.globalVariables.map((variable, index) => {
          const variableToValidate = variablesToValidate.find((item) => item.index === index);
          const variableToValidateIndex = variablesToValidate.findIndex((item) => item.index === index);

          if (variableToValidate && variableToValidate.index === index && results[variableToValidateIndex].error) {
            variable.error = results[variableToValidateIndex].error;
          }

          return variable;
        });
      }),
      map((results: LabServicesResponse[]) => {
        return results.every((result) => result.result_object !== undefined);
      }),
    );
  }

  changedVariables(): { value: string; index: number }[] {
    return this.globalVariables
      .map((variable, index) => ({ value: variable.value, key: variable.key, index }))
      .filter(
        (variable, index) =>
          !this.initialGlobalVariables[index] ||
          this.initialGlobalVariables[index].value !== variable.value ||
          this.initialGlobalVariables[index].key !== variable.key,
      );
  }

  onChange(variables: VariableItem[]) {
    this.globalVariables = variables;
    this.cleanErrors();
  }

  prepareVariables(variables: Variables): VariableItem[] {
    const newVariables = [];

    forIn(variables, (value, key) => {
      newVariables.push({
        key,
        value: value,
        default: value,
        type: 'string',
      });
    });

    return newVariables;
  }

  hasPermission$(permissionName): Observable<boolean> {
    return this.permissionsService.hasPermission$(permissionName);
  }
}
