import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { mapTo, scan, startWith, switchMap, takeUntil, takeWhile } from 'rxjs/operators';

function isApproachingRange(endRange: number, currentNumber: number): (val: any) => boolean {
  return endRange > currentNumber ? (val) => val <= endRange : (val) => val >= endRange;
}

function positiveOrNegative(endRange: number, currentNumber: number): 1 | -1 {
  return endRange > currentNumber ? 1 : -1;
}

@Component({
  selector: 'xp-number-tracker',
  template: ` <span> {{ currentNumber }}</span> `,
})
export class XpNumberTrackerComponent implements OnInit, OnDestroy {
  @Input()
  set end(endRange: number) {
    this.counterSub$.next(endRange);
  }
  @Input() countInterval = 20;
  @Input() isFinished = false;
  public currentNumber: number;
  private counterSub$ = new Subject<number>();
  private onDestroy$ = new Subject();

  ngOnInit() {
    this.currentNumber = this.isFinished ? 100 : 0;
    this.counterSub$
      .pipe(
        switchMap((endRange) => {
          return timer(0, this.countInterval).pipe(
            mapTo(positiveOrNegative(endRange, this.currentNumber)),
            startWith(this.currentNumber),
            scan((acc: number, curr: number) => acc + curr),
            takeWhile(isApproachingRange(endRange, this.currentNumber)),
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((val: number) => {
        this.currentNumber = val;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
