export interface Member {
  avatar_url: string;
  confirmed: boolean;
  confirmed_at?: string;
  created_at: string;
  display_name: string;
  email: string;
  gravatar_email: string;
  html_url: string;
  id: number;
  location?: string;
  name: string;
  owner: boolean;
  role: STANDARD_ROLES;
  advanced_roles: ADVANCED_ROLES[];
  updated_at: string;
  url: string;
  isRemoved?: boolean;
}

export enum STANDARD_ROLES {
  MEMBER = 'member',
  ADMIN = 'admin',
  OWNER = 'owner',
  READER = 'reader',
  EDITOR = 'editor',
  OPERATOR = 'operator',
}
export enum ADVANCED_ROLES {
  VIEW = 'view_role',
  UPDATE = 'update_role',
  OPERATE = 'operate_role',
  MANAGE = 'manage_role',
}
