import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '../common/common.module';
import { connectionsReducer } from './store/connections.reducer';
import { ConnectionsEffects } from './store/connections.effects';
import { ConnectionsListComponent } from './components/connections-list.component';
import { ConnectionListItemComponent } from './components/connection-list-item.component';
import { ConnectionFormComponent } from './components/connection-form.component';
import { ConnectionFormPostgresComponent } from './components/forms/connection-form-postgres.component';
import { ConnectionComponentUsernamePasswordComponent } from './components/forms/connection-component-username-password.component';
import { ConnectionComponentAccesssTypeComponent } from './components/forms/connection-component-accesss-type.component';
import { ConnectionComponentHostPortComponent } from './components/forms/connection-component-host-port.component';
import { ConnectionComponentSshHostPortUserComponent } from './components/forms/connection-component-ssh-host-port-user.component';
import { ConnectionComponentReverseTunnelComponent } from './components/forms/connection-component-reverse-tunnel.component';
import { ConnectionComponentSshTunnelComponent } from './components/forms/connection-component-ssh-tunnel.component';
import { ConnectionFormMysqlComponent } from './components/forms/connection-form-mysql.component';
import { ConnectionFormOracleComponent } from './components/forms/connection-form-oracle.component';
import { ConnectionFormSqlServerComponent } from './components/forms/connection-form-sql-server.component';
import { ConnectionFormHerokuPostgresComponent } from './components/forms/connection-form-heroku-postgres.component';
import { ConnectionFormGoogleCloudPostgresComponent } from './components/forms/connection-form-google-cloud-postgres.component';
import { ConnectionFormGoogleCloudSqlComponent } from './components/forms/connection-form-google-cloud-sql.component';
import { ConnectionFormAzureSynapseAnalyticsComponent } from './components/forms/connection-form-azure-synapse-analytics.component';
import { ConnectionFormVerticaComponent } from './components/forms/connection-form-vertica.component';
import { ConnectionFormDb2Component } from './components/forms/connection-form-db2.component';
import { ConnectionFormSftpComponent } from './components/forms/connection-form-sftp.component';
import { ConnectionFormHdfsComponent } from './components/forms/connection-form-hdfs.component';
import { ConnectionFormFtpsComponent } from './components/forms/connection-form-ftps.component';
import { ConnectionFormRestApiComponent } from './components/forms/connection-form-restapi.component';
import { ConnectionFormAthenaComponent } from './components/forms/connection-form-athena.component';
import { ConnectionFormRedshiftComponent } from './components/forms/connection-form-redshift.component';
import { ConnectionFormBigQueryComponent } from './components/forms/connection-form-big-query.component';
import { ConnectionFormSnowflakeComponent } from './components/forms/connection-form-snowflake.component';
import { ConnectionFormS3Component } from './components/forms/connection-form-s3.component';
import { ConnectionFormAzureBlobStorageComponent } from './components/forms/connection-form-azureblobstorage.component';
import { ConnectionFormGSComponent } from './components/forms/connection-form-gs.component';
import { ConnectionFormMongoComponent } from './components/forms/connection-form-mongo.component';
import { ConnectionFormAdwordsComponent } from './components/forms/connection-form-adwords.component';
import { ConnectionFormAnalyticsComponent } from './components/forms/connection-form-analytics.component';
import { ConnectionFormGoogleCloudSpannerComponent } from './components/forms/connection-form-google-cloud-spanner.component';
import { ConnectionFormGoogleSheetsComponent } from './components/forms/connection-form-google-sheets.component';
import { ConnectionFormYouTubeComponent } from './components/forms/connection-form-youtube.component';
import { ConnectionFormFacebookAdsInsightsComponent } from './components/forms/connection-form-facebook-ads-insights.component';
import { ConnectionFormFacebookAdsComponent } from './components/forms/connection-form-facebook-ads.component';
import { ConnectionFormBingAdsComponent } from './components/forms/connection-form-bing-ads.component';
import { ConnectionFormNetsuiteComponent } from './components/forms/connection-form-netsuite.component';
import { ConnectionFormRackspaceComponent } from './components/forms/connection-form-rackspace.component';
import { ConnectionFormSalesforceComponent } from './components/forms/connection-form-salesforce.component';
import { ConnectionFormSoftlayerComponent } from './components/forms/connection-form-softlayer.component';
import { ConnectionFormSwiftComponent } from './components/forms/connection-form-swift.component';
import { ConnectionFormIntercomComponent } from './components/forms/connection-form-intercom.component';
import { ConnectionFormPipedriveComponent } from './components/forms/connection-form-pipedrive.component';
import { ConnectionFormXeroComponent } from './components/forms/connection-form-xero.component';
import { ConnectionFormLinkedinComponent } from './components/forms/connection-form-linkedin.component';
import { ConnectionFormInstagramComponent } from './components/forms/connection-form-instagram.component';
import { ConnectionFormShopifyComponent } from './components/forms/connection-form-shopify.component';
import { ConnectionFormMarketingCloudComponent } from './components/forms/connection-form-marketing-cloud.component';
import { ConnectionFormMarketingCloudRestComponent } from './components/forms/connection-form-marketing-cloud-rest.component';
import { ConnectionFormGoogleDriveComponent } from './components/forms/connection-form-google-drive.component';
import { ConnectionsComponent } from './connections.component';
import { ConnectionFormNetsuiteDestinationComponent } from './components/forms/connection-form-nsuite.component';
import { ConnectionFormBigQueryV2Component } from './components/forms/connection-form-big-query-v2.component';
import { ConnectionFormSalesforceSoapComponent } from './components/forms/connection-form-salesforce-soap.component';
import { ConnectionFormAlloyComponent } from './components/forms/connection-form-alloy.component';
import { ConnectionFormAs400Component } from './components/forms/connection-form-as400.component';
import { ConnectionFormAnalyticsV4Component } from './components/forms/connection-form-analytics-v4.component';
import { ConnectionFormTiktokAdsComponent } from './components/forms/connection-form-tiktok-ads.component';
import { ConnectionFormHubspotComponent } from './components/forms/connection-form-hubspot.component';
import { ConnectionFormGSV2Component } from './components/forms/connection-form-gs2.component';
import { ConnectionFormOracleADWComponent } from './components/forms/connection-form-oracle-adw.component';
import { ConnectionFormHubspotOAuthComponent } from './components/forms/connection-form-hubspot-oauth.component';

@NgModule({
  exports: [ConnectionFormComponent],
  declarations: [
    ConnectionsListComponent,
    ConnectionFormComponent,
    ConnectionListItemComponent,
    ConnectionFormPostgresComponent,
    ConnectionFormMysqlComponent,
    ConnectionFormOracleComponent,
    ConnectionFormOracleADWComponent,
    ConnectionFormSqlServerComponent,
    ConnectionFormHerokuPostgresComponent,
    ConnectionFormGoogleCloudPostgresComponent,
    ConnectionFormGoogleCloudSqlComponent,
    ConnectionFormAzureSynapseAnalyticsComponent,
    ConnectionFormVerticaComponent,
    ConnectionFormDb2Component,
    ConnectionFormAs400Component,
    ConnectionFormSftpComponent,
    ConnectionFormHdfsComponent,
    ConnectionFormFtpsComponent,
    ConnectionFormRestApiComponent,
    ConnectionFormAthenaComponent,
    ConnectionFormRedshiftComponent,
    ConnectionFormBigQueryComponent,
    ConnectionFormBigQueryV2Component,
    ConnectionFormAnalyticsV4Component,
    ConnectionFormSnowflakeComponent,
    ConnectionFormS3Component,
    ConnectionFormAzureBlobStorageComponent,
    ConnectionFormGSComponent,
    ConnectionFormMongoComponent,
    ConnectionFormAdwordsComponent,
    ConnectionFormAnalyticsComponent,
    ConnectionFormGoogleCloudSpannerComponent,
    ConnectionFormGoogleSheetsComponent,
    ConnectionFormGoogleDriveComponent,
    ConnectionFormYouTubeComponent,
    ConnectionFormFacebookAdsInsightsComponent,
    ConnectionFormFacebookAdsComponent,
    ConnectionFormBingAdsComponent,
    ConnectionFormNetsuiteComponent,
    ConnectionFormNetsuiteDestinationComponent,
    ConnectionFormRackspaceComponent,
    ConnectionFormSalesforceComponent,
    ConnectionFormSoftlayerComponent,
    ConnectionFormSwiftComponent,
    ConnectionFormTiktokAdsComponent,
    ConnectionFormIntercomComponent,
    ConnectionFormPipedriveComponent,
    ConnectionFormXeroComponent,
    ConnectionFormLinkedinComponent,
    ConnectionFormInstagramComponent,
    ConnectionFormShopifyComponent,
    ConnectionComponentUsernamePasswordComponent,
    ConnectionComponentAccesssTypeComponent,
    ConnectionComponentHostPortComponent,
    ConnectionComponentSshHostPortUserComponent,
    ConnectionComponentReverseTunnelComponent,
    ConnectionComponentSshTunnelComponent,
    ConnectionFormMarketingCloudComponent,
    ConnectionFormMarketingCloudRestComponent,
    ConnectionFormNetsuiteDestinationComponent,
    ConnectionsComponent,
    ConnectionFormSalesforceSoapComponent,
    ConnectionFormAlloyComponent,
    ConnectionFormHubspotComponent,
    ConnectionFormHubspotOAuthComponent,
    ConnectionFormGSV2Component,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    NgxPermissionsModule,
    MatTooltipModule,
    MatMenuModule,
    StoreModule.forFeature('connections', connectionsReducer),
    EffectsModule.forFeature([ConnectionsEffects]),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ConnectionsModule {}
