import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { importJSON } from '../../store/package-designer.actions';

@Component({
  selector: 'package-json-import-form',
  template: `
    <div class="package-json-import-form row">
      <span class="col-md-10 col-md-offset-1">
        Package JSON<br />
        <textarea class="form-control" [(ngModel)]="newPackageJson"></textarea>
      </span>
    </div>
    <div class="modal-footer">
      <div class="modal-title-container">
        <h3 class="modal-title">{{ 'package.controller.modals.import_json.actions.title' | translate }}</h3>
      </div>
      <button
        type="button"
        class="btn btn-lg btn-success pull-right modal-btn-save"
        [disabled]="!newPackageJson"
        (click)="importJSON()"
      >
        {{ 'package.controller.modals.import_json.actions.save' | translate }}
      </button>
    </div>
  `,
})
export class PackageJsonImportFormComponent {
  newPackageJson: string = '';

  constructor(private store: Store<AppState>) {}

  importJSON() {
    this.store.dispatch(importJSON({ body: this.newPackageJson }));
  }
}
