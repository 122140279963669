import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import { ExtendedEdge } from '../../package.models';
import { selectFixedEdgeById } from '../../store/package-designer.selectors';
import { DESIGNER_SETTINGS } from '../../../constants/designer_settings';

@Directive({
  selector: '[designer-map-edge]',
})
export class DesignerMapEdgeDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() edgeId: string;
  @Input() scale: number;

  panelScale = DESIGNER_SETTINGS.PANEL_SCALE;
  edgeSubscription: Subscription;
  edge: Partial<ExtendedEdge> = {};

  constructor(private store: Store<AppState>, private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.edgeSubscription = this.store
      .select(selectFixedEdgeById(this.edgeId))
      .pipe(filter(Boolean))
      .subscribe((edge: ExtendedEdge) => {
        this.edge = edge;
        this.updateAttrs();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scale?.currentValue !== changes.scale?.previousValue && this.edge?.sourcePosition) {
      if (this.elementRef.nativeElement && this.edge && this.scale !== Infinity) {
        this.updateAttrs();
      }
    }
  }

  updateAttrs() {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'x1',
      String(this.edge.sourcePosition.x * this.panelScale * this.scale),
    );
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'y1',
      String(this.edge.sourcePosition.y * this.panelScale * this.scale),
    );
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'x2',
      String(this.edge.targetPosition.x * this.panelScale * this.scale),
    );
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'y2',
      String(this.edge.targetPosition.y * this.panelScale * this.scale),
    );
  }

  ngOnDestroy() {
    if (this.edgeSubscription) {
      this.edgeSubscription.unsubscribe();
    }
  }
}
