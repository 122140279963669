import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import moment from 'moment';
import {
  Connection,
  ConnectionSchemaField,
  FacebookAdsInsightsSourceComponentData,
  Schema,
} from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { FACEBOOK_API_VERSIONS } from '../../../constants/facebook_api_versions';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { getConnectionSchemaResponse } from '../../../connections/store/connections.actions';
import { environment } from '../../../../environments/environment';

interface FacebookInsightsResponseItem {
  value: string;
  name: string;
}

interface FacebookInsightsResponse {
  BREAKDOWNS: FacebookInsightsResponseItem[];
  DATE_PRESETS: FacebookInsightsResponseItem[];
  LEVELS: FacebookInsightsResponseItem[];
  TIME_INCREMENTS: FacebookInsightsResponseItem[];
}

function markDeadApiVersions(apiVersions) {
  // get supported api versions from validation service
  return apiVersions.map((apiVersion) => {
    let dead = false;
    let deprecated = false;
    if (apiVersion.sunset_date && moment(apiVersion.sunset_date).diff(moment(), 'days') < 0) {
      dead = true;
    } else if (apiVersion.deprecation_date && moment(apiVersion.deprecation_date).diff(moment(), 'days') < 0) {
      deprecated = true;
    }

    return {
      ...apiVersion,
      dead,
      deprecated,
    };
  });
}

@Component({
  selector: 'facebook-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="facebook-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::FacebookAdsInsightsSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div class="row" *ngFor="let error of errors">
                    <div class="col-md-12">
                      <div class="alert alert-warning">
                        <button type="button" class="close" (click)="removeError(error)">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <span>{{ error }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="api_version">{{
                        'facebook_ads_insights_source_component.form.labels.api_version' | translate
                      }}</label>
                      <xp-select
                        name="api_version"
                        id="api_version"
                        [value]="rawComponent.api_version"
                        [options]="apiVersions"
                        [isSearchEnabled]="true"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onApiVersionChange($event)"
                        emptyPlaceholder="No api version found"
                      ></xp-select>
                    </div>
                    <div class="form-group">
                      <label for="level">{{
                        'facebook_ads_insights_source_component.form.labels.level' | translate
                      }}</label>
                      <xp-select
                        name="level"
                        id="level"
                        [value]="rawComponent.level"
                        [options]="levels"
                        [isSearchEnabled]="true"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onValueChange($event, 'level')"
                      ></xp-select>
                    </div>
                    <div class="form-group">
                      <label for="report_date_range_type">{{
                        'facebook_ads_insights_source_component.form.labels.date_range' | translate
                      }}</label>
                      <xp-select
                        name="report_date_range_type"
                        id="report_date_range_type"
                        class="form-control xp-select"
                        [value]="rawComponent.report_date_range_type"
                        [options]="date_presets"
                        (valueChange)="onValueChange($event, 'report_date_range_type')"
                      ></xp-select>
                    </div>
                    <div *ngIf="rawComponent.report_date_range_type === 'CUSTOM_DATE'" class="date-range-container">
                      <date-range
                        [startDate]="rawComponent.report_date_range_min"
                        [endDate]="rawComponent.report_date_range_max"
                        (startDateChange)="onValueChange($event, 'report_date_range_min')"
                        (endDateChange)="onValueChange($event, 'report_date_range_max')"
                      ></date-range>
                    </div>
                    <xp-form-group
                      [validationDisabled]="true"
                      *ngIf="rawComponent.report_date_range_type === 'CUSTOM_DATE'"
                    >
                      <xp-input-checkbox
                        [ngModel]="rawComponent.manual_breakdown === 'DayByDay'"
                        (ngModelChange)="onValueChange($event ? 'DayByDay' : 'Default', 'manual_breakdown')"
                        name="manual_breakdown"
                        [hint]="'facebook_ads_insights_source_component.form.hints.manual_breakdown' | translate"
                        [labelText]="'facebook_ads_insights_source_component.form.labels.manual_breakdown' | translate"
                      ></xp-input-checkbox>
                    </xp-form-group>
                    <div class="form-group">
                      <label for="time_increment">{{
                        'facebook_ads_insights_source_component.form.labels.time_increment' | translate
                      }}</label>
                      <xp-select
                        name="time_increment"
                        id="time_increment"
                        class="form-control xp-select"
                        [value]="rawComponent.time_increment"
                        [options]="time_increments"
                        [disabled]="isTimeIncrementDisabled"
                        (valueChange)="onValueChange($event, 'time_increment')"
                      ></xp-select>
                    </div>
                    <xp-form-group>
                      <label for="filtering">{{
                        'facebook_ads_insights_source_component.form.labels.filtering' | translate
                      }}</label>
                      <code-editor
                        [value]="rawComponent.filtering"
                        [options]="filteringEditorOptions"
                        name="filtering"
                        (valueChange)="onValueChange($event, 'filtering')"
                      ></code-editor>
                    </xp-form-group>
                    <div class="form-group">
                      <label for="action_report_time">{{
                        'facebook_ads_insights_source_component.form.labels.action_report_time' | translate
                      }}</label>
                      <div class="btn-group btn-group-md btn-group-select action-report-time">
                        <button
                          type="button"
                          value="impression"
                          class="btn btn-default"
                          [ngClass]="{ 'active btn-primary': rawComponent.action_report_time === 'impression' }"
                          (click)="onValueChange('impression', 'action_report_time')"
                        >
                          Impression
                        </button>
                        <button
                          type="button"
                          value="conversion"
                          class="btn btn-default"
                          [ngClass]="{ 'active btn-primary': rawComponent.action_report_time === 'conversion' }"
                          (click)="onValueChange('conversion', 'action_report_time')"
                        >
                          Conversion
                        </button>
                      </div>
                    </div>
                    <label for="report_on">{{
                      'facebook_ads_insights_source_component.form.labels.report_on' | translate
                    }}</label>
                    <div class="form-group-options">
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="all"
                          id="report-on-all"
                        />
                        <label for="report-on-all">{{
                          'facebook_ads_insights_source_component.form.labels.all_accounts' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="specific"
                          id="report-on-specific"
                        />
                        <label for="report-on-specific">{{
                          'facebook_ads_insights_source_component.form.labels.specific_accounts' | translate
                        }}</label>
                      </div>
                      <div class="input-checkbox-wrapper">
                        <xp-input-checkbox
                          [ngModel]="rawComponent.with_custom_fields"
                          (ngModelChange)="onValueChange($event, 'with_custom_fields')"
                          name="with_custom_fields"
                          [labelText]="
                            'facebook_ads_insights_source_component.form.labels.with_custom_fields' | translate
                          "
                        ></xp-input-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="rawComponent.report_on === 'specific'">
                    <accounts-campaigns-selector
                      [component]="component"
                      [rawComponent]="rawComponent"
                    ></accounts-campaigns-selector>
                    <label for="ad_accounts" style="visibility: hidden;"></label>
                    <xp-input
                      type="text"
                      [ngModel]="rawComponent.ad_accounts"
                      (ngModelChange)="onValueChange($event, 'ad_accounts')"
                      name="ad_accounts"
                      id="ad_accounts"
                      style="visibility: hidden;"
                    ></xp-input>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            (updateValidation)="updateSchemaValidation($event)"
            [dataPreviewDisabled]="!this.isSchemaValid || !this.isFormValid"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class FacebookSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: FacebookAdsInsightsSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  isSchemaValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});
  isBaseComponentStepActivated = false;

  apiVersions = FACEBOOK_API_VERSIONS;
  levels: FacebookInsightsResponseItem[] = [];
  time_increments: FacebookInsightsResponseItem[] = [];
  date_presets: FacebookInsightsResponseItem[] = [];
  errors: string[] = [];
  apiErrorMessage = '';
  filteringEditorOptions = {
    placeholder: "[{'field':'ad.effective_status','operator':'IN','value':['ARCHIVED','ACTIVE']}]",
    mode: 'application/json',
    autoCloseBrackets: true,
    matchBrackets: true,
  };
  isTimeIncrementDisabled = false;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private connectionItemsResource: ConnectionItemsResource,
    private http: HttpClient,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    this.apiVersions = markDeadApiVersions(this.apiVersions);
    this.setApiData(this.rawComponent.api_version);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };

    this.checkConnection(changes);

    if (
      changes.rawComponent?.currentValue?.manual_breakdown &&
      changes.rawComponent?.currentValue?.manual_breakdown !== changes.rawComponent?.previousValue?.manual_breakdown
    ) {
      this.checkDayByDayBreakdown(this.rawComponent.manual_breakdown);
    }

    if (
      changes.rawComponent?.currentValue?.report_date_range_type !== 'CUSTOM_DATE' &&
      changes.rawComponent?.currentValue?.report_date_range_type !==
        changes.rawComponent?.previousValue?.report_date_range_type
    ) {
      this.onValueChange('', 'report_date_range_min');
      this.onValueChange('', 'report_date_range_max');
      this.onValueChange('Default', 'manual_breakdown');
    }
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  checkCurrentApiVersion(currentApiVersion) {
    if (!currentApiVersion) {
      this.apiErrorMessage = `Selected api version ${this.rawComponent.api_version} is not found.`;
      this.onValueChange(this.apiVersions[0].value, 'api_version');
    } else if (currentApiVersion.dead) {
      const error = this.translate.instant('adwords-source-editor.form.errors.api_version', {
        api_version: currentApiVersion.text,
      });
      this.errors = [...this.errors, error];
    }
  }

  convertFields(fields) {
    this.store.dispatch(
      getConnectionSchemaResponse({
        connectionSchema: fields.map((field) => ({
          name: field.id,
          alias: field.default_alias,
          data_type: field.data_type,
          category: field.category,
          pig_data_type: field.pig_data_type,
          request: field.request,
          response: field.response,
        })),
        connectionSchemaData: { data: [], fields: [] },
      }),
    );
  }

  handleError(res) {
    if (res.data) {
      this.errors.push(res.error.error_message);
    }
  }

  loadFields() {
    if (this.rawComponent.connection && this.rawComponent.connection.type) {
      const schemaRequestData: any = {
        api_version: this.rawComponent.api_version,
        with_custom_fields: this.rawComponent.with_custom_fields,
      };

      if (this.rawComponent.with_custom_fields) {
        schemaRequestData['ad_accounts[]'] =
          this.rawComponent.report_on === 'all' ? ['any'] : this.rawComponent.ad_accounts;
      }

      this.connectionItemsResource
        .fieldsAsArray(this.rawComponent.connection.type, this.rawComponent.connection.id, schemaRequestData)
        .subscribe({
          next: this.convertFields.bind(this),
          error: this.handleError.bind(this),
        });
    }
  }

  checkConnection(changes: SimpleChanges) {
    const {
      connection: previousConnection,
      ad_accounts: previousAddAccounts,
      report_on: previousReportOn,
      with_custom_fields: previousWitCustomFields,
    } = changes.rawComponent.previousValue || {};
    const {
      connection: currentConnection,
      ad_accounts: currentAddAccounts,
      report_on: currentReportOn,
      with_custom_fields: currentWitCustomFields,
    } = changes.rawComponent.currentValue;

    if (currentConnection && currentConnection.id) {
      if (!previousConnection || previousConnection.id !== currentConnection.id) {
        this.errors = [];
        this.loadFields();
      } else if (this.rawComponent.with_custom_fields) {
        if (
          currentAddAccounts.length !== previousAddAccounts.length ||
          currentReportOn !== previousReportOn ||
          currentWitCustomFields !== previousWitCustomFields
        ) {
          this.errors = [];
          this.loadFields();
        }
      } else if (currentWitCustomFields !== previousWitCustomFields) {
        this.errors = [];
        this.loadFields();
      }
    } else if (changes.rawComponent.currentValue && !changes.rawComponent.previousValue) {
      this.errors = [];
      this.loadFields();
    }
  }

  onApiVersionChange(apiVersion: string) {
    this.onValueChange(apiVersion, 'api_version');
    if (apiVersion) {
      this.errors = [];
      this.setApiData(apiVersion);
      this.checkCurrentApiVersion(this.apiVersions.find((item) => item.value === apiVersion));
    }
  }

  checkDayByDayBreakdown(breakdown: string) {
    if (breakdown === 'DayByDay') {
      this.onValueChange('1', 'time_increment');
      this.isTimeIncrementDisabled = true;
    } else {
      this.isTimeIncrementDisabled = false;
    }
  }

  setApiData(apiVersion: string) {
    if (apiVersion) {
      this.http
        .get(`${environment.API_URL}/facebook/insights/${apiVersion.replace('.', '_')}.json`)
        .subscribe((FACEBOOK_API: FacebookInsightsResponse) => {
          if (!this.checkFields(FACEBOOK_API.LEVELS, 'level', this.rawComponent.level)) {
            this.onValueChange(FACEBOOK_API.LEVELS[0].value, 'level');
          }
          this.levels = FACEBOOK_API.LEVELS;

          if (!this.checkFields(FACEBOOK_API.TIME_INCREMENTS, 'time breakdown', this.rawComponent.time_increment)) {
            this.onValueChange(FACEBOOK_API.TIME_INCREMENTS[0].value, 'time_increment');
          }
          this.time_increments = FACEBOOK_API.TIME_INCREMENTS;

          if (
            this.rawComponent.report_date_range_type !== 'CUSTOM_DATE' &&
            !this.checkFields(FACEBOOK_API.DATE_PRESETS, 'date range', this.rawComponent.report_date_range_type)
          ) {
            this.onValueChange(FACEBOOK_API.DATE_PRESETS[0].value, 'report_date_range_type');
          }
          this.date_presets = [
            ...FACEBOOK_API.DATE_PRESETS,
            {
              name: 'Custom date',
              value: 'CUSTOM_DATE',
            },
          ];
        });
    }
  }

  checkFields(array, fieldName: string, value: string) {
    const field = array.find((item) => item.value === value);
    if (!field) {
      const error = this.translate.instant('facebook_ads_insights_source_component.form.errors.missing_field', {
        name: fieldName,
        value,
      });
      this.errors = [...this.errors, error];
    }
    return !!field;
  }

  removeError(error: string) {
    this.errors = this.errors.filter((item) => item !== error);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
