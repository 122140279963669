import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PermissionsService } from '../../../services/permissions.service';
import { SelectPickerTypes } from './select-picker-types.enum';
import { AppState } from '../../../../store';
import { selectConnectionTypes } from '../../../../connections/store/connections.selectors';
import { ListParams } from '../../../helper/query-params-generic-list.helper';
import { SelectPickerValue } from './xp-select-picker.component';
import { AnyConnection } from '../../../../connections/connection.models';
import { connectionIconUrlByType } from '../../../helper/connection-icon-url-by-type.helper';
import { ConnectionType } from '../../../../connections/connection.models';
import { Observable, of } from 'rxjs';
import { FILTERED_CONNECTION_TYPES } from '../../../../connections/helpers/connection-types.helpers';

@Component({
  selector: 'xp-select-picker-editable',
  template: `
    <div class="select-picker-editable form-group" [ngClass]="{ disabled: isDisabled }">
      <div class="select-picker-container">
        <xp-select-picker
          [id]="id"
          [type]="type"
          [value]="value"
          (valueChange)="valueChange.emit($event)"
          [filterPackagesWithWorkspace]="filterPackagesWithWorkspace"
          [filteredItems]="filteredItems"
          [addItemToList]="addItemToList"
          [placeholder]="placeholder"
          [emptyPlaceholder]="emptyPlaceholder"
          [ngClass]="{ 'has-right-margin': !hideNew }"
          [isDisabled]="isDisabled"
          [params]="params"
          [definedId]="definedId"
          [emitInitial]="true"
          (editConnection)="onCreateNew($event)"
          [preventEmpty]="preventEmpty"
        ></xp-select-picker>
      </div>
      <div class="select-picker-editable-buttons">
        <button
          type="button"
          class="btn btn-info btn-create-new"
          (click)="onCreateNew(connectionTypesMapped[0])"
          *ngIf="connectionTypesMapped.length <= 1 && !hideNew"
          [disabled]="!(hasPermission$(createPermission) | async)"
        >
          <i class="fa fa-plus"></i>
          <span>New</span>
        </button>
        <button
          type="button"
          class="btn btn-info dropdown-toggle btn-create-new"
          [matMenuTriggerFor]="dropdown"
          *ngIf="connectionTypesMapped.length > 1"
          [disabled]="!(hasPermission$(createPermission) | async)"
        >
          <i class="fa fa-plus"></i> New <span class="caret"></span>
        </button>
        <mat-menu #dropdown="matMenu">
          <li
            class="mat-dropdown-menu-item"
            mat-menu-item
            *ngFor="let connectionType of connectionTypesMapped"
            (click)="onCreateNew(connectionType)"
          >
            <img [src]="getConnectionIcon(connectionType?.type)" [alt]="connectionType?.name" />
            <span class="mat-menu-item-text">{{ connectionType?.name }}</span>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class XpSelectPickerEditableComponent implements OnInit, OnChanges {
  @Input() type: SelectPickerTypes;
  @Input() id: string;
  @Input() value: SelectPickerValue;
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Input() params: ListParams;
  @Input() filteredItems: Array<number | string>;
  @Input() addItemToList: SelectPickerValue;
  @Input() placeholder: string;
  @Input() emptyPlaceholder: string;
  @Input() filterPackagesWithWorkspace: boolean;
  @Input() hideNew: boolean;
  @Input() preventEmpty: boolean;
  @Input() emitInitial: boolean;
  @Input() definedId: string;
  @Input() connectionTypes: string[] = [];
  @Output() valueChange = new EventEmitter<SelectPickerValue>();
  @Output() createNew = new EventEmitter<{ connection?: AnyConnection; type: SelectPickerTypes }>();

  connectionTypesMapped: ConnectionType[] = [];
  createPermission = '';
  editPermission = '';
  getConnectionIcon = connectionIconUrlByType;

  constructor(
    private permissionsService: PermissionsService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    switch (this.type) {
      case SelectPickerTypes.connection:
        this.createPermission = 'createConnection';
        this.editPermission = 'updateConnection';
        break;
      case SelectPickerTypes.cluster:
        this.createPermission = 'createCluster';
        this.editPermission = 'updateCluster';
        break;
      default:
        this.createPermission = '';
        this.editPermission = '';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connectionTypes && changes.connectionTypes.currentValue) {
      this.store
        .select(selectConnectionTypes)
        .pipe(first())
        .subscribe((connectionTypes) => {
          this.connectionTypesMapped = changes.connectionTypes.currentValue
            .filter((item) => !FILTERED_CONNECTION_TYPES.includes(item))
            .map((item) => connectionTypes.find((cType) => item === cType.type));
        });
    }
  }

  hasPermission$(permissionName: string): Observable<boolean> {
    if (!permissionName) {
      return of(true);
    }
    return this.permissionsService.hasPermission$(permissionName);
  }

  onCreateNew(connection: AnyConnection) {
    this.createNew.emit({ connection, type: this.type });
  }
}
