import { Component, Input } from '@angular/core';
import { COMPONENTS } from '../../constants/components';

@Component({
  selector: 'component-icon',
  template: ` <common-icon [iconId]="getIconHref()"></common-icon> `,
})
export class ComponentIconComponent {
  @Input() type: string;

  getIconHref(): string {
    return (COMPONENTS[this.type] || {}).icon || '';
  }
}
