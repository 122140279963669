import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogData, DialogTemplateComponent, DialogType } from './components/dialog-template.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  defaults: DialogData;

  constructor(public dialog: MatDialog, private translate: TranslateService) {
    this.defaults = {
      title: this.translate.instant('confirmation-dialog.title'),
      hint: this.translate.instant('confirmation-dialog.hint'),
      yes: this.translate.instant('confirmation-dialog.actions.yes'),
      no: this.translate.instant('confirmation-dialog.actions.no'),
      type: DialogType.danger,
      itemState: 'pending_removal',
    };
  }

  openDialog(data: Partial<DialogData>): MatDialogRef<DialogTemplateComponent> {
    return this.dialog.open(DialogTemplateComponent, {
      data: { ...this.defaults, ...data },
      position: {
        top: '150px',
      },
      maxWidth: data.maxWidth || '500px',
      minWidth: data.minWidth || '',
    });
  }
}
