import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'xp-time-picker',
  template: `
    <div class="xp-time-picker">
      <div class="time-control">
        <button (click)="incrementHours()"><i class="fa fa-chevron-up"></i></button>
        <input type="text" [value]="hours | number: '2.0'" readonly />
        <button (click)="decrementHours()"><i class="fa fa-chevron-down"></i></button>
      </div>

      <span>:</span>

      <div class="time-control">
        <button (click)="incrementMinutes()"><i class="fa fa-chevron-up"></i></button>
        <input type="text" [value]="minutes | number: '2.0'" readonly />
        <button (click)="decrementMinutes()"><i class="fa fa-chevron-down"></i></button>
      </div>
    </div>
  `,
})
export class XpTimePickerComponent implements OnChanges {
  @Input() value: string;
  @Output() timeChange = new EventEmitter<string>();

  hours: number = 12;
  minutes: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const timeParts = changes.value.currentValue.split(':');
      this.hours = parseInt(timeParts[0]);
      this.minutes = parseInt(timeParts[1]);

      if (isNaN(this.hours)) {
        this.hours = 0;
      }

      if (isNaN(this.minutes)) {
        this.minutes = 0;
      }
    }
  }

  incrementHours() {
    if (this.hours === 23) {
      this.hours = 0;
    } else {
      this.hours++;
    }
    this.emitTimeChange();
  }

  decrementHours() {
    if (this.hours === 0) {
      this.hours = 23;
    } else {
      this.hours--;
    }
    this.emitTimeChange();
  }

  incrementMinutes() {
    if (this.minutes === 59) {
      this.minutes = 0;
      this.incrementHours();
    } else {
      this.minutes++;
    }
    this.emitTimeChange();
  }

  decrementMinutes() {
    if (this.minutes === 0) {
      this.minutes = 59;
      this.decrementHours();
    } else {
      this.minutes--;
    }
    this.emitTimeChange();
  }

  emitTimeChange() {
    const timeString = `${this.hours < 10 ? '0' : ''}${this.hours}:${this.minutes < 10 ? '0' : ''}${this.minutes}`;
    this.timeChange.emit(timeString);
  }
}
