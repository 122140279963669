import { Component, Input } from '@angular/core';
import { Cluster, ClusterTypes } from '../../../clusters/clusters.models';

@Component({
  selector: 'cluster-cat',
  template: `
    <div class="cluster-cat">
      <svg
        version="1.1"
        id="cat-0"
        *ngIf="cluster.type === ClusterTypes.sandbox"
        xmlns="http://www.w3.org/2000/svg"
        width="141px"
        height="141px"
        viewBox="0 0 141 141"
      >
        <title>xplenty-cluster-cat-1</title>
        <path
          style="fill: #ffd261;"
          d="M70.5,140.5c32.4,0,60.3-22.6,67.1-54.3c-44.1-10.8-90.2-10.8-134.3,0
    C10.2,117.9,38.1,140.5,70.5,140.5z"
        />
        <g>
          <path
            style="fill: #0ca3e5;"
            d="M70.4,139.5c-10.4,0-20.8-2.3-30.1-6.9v-1.7c-0.1-3.6-0.2-7.2-0.4-10.6c-0.1-3.4-0.3-6.8-0.5-10
        c-0.2-3.2-0.3-6.3-0.5-9.2s-0.3-5.8-0.5-8.4s-0.4-5.4-0.6-7.8c-0.2-2.6-0.5-4.9-0.7-7.2c-0.2-1-0.5-3.1-1.9-3.4
        c0-2.3,0.2-4.5,0.5-6.8c0.6-2.4,1.4-4.7,2.4-6.9c0.6-1.3,1.2-2.6,1.9-3.8l0.1-0.2v-0.3C39.9,50.5,38.1,45,35,36.9
        c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.4-0.2c3.6,0.2,8.8,3,15,8.2l0.5,0.4l0.5-0.2c1.2-0.5,2.5-0.9,3.7-1.3
        c1.9-0.6,3.8-1.2,5.7-1.6c1.4-0.4,2.9-0.6,4.4-0.9c1.3-0.1,2.6-0.2,3.8-0.2l0,0c2.1,0,3.1-0.5,3.2-1.4c0-0.2,0-0.4-0.1-0.6h0.5
        c2.3,0.2,4.6,0.7,6.7,1.7c0.4,0.2,0.9,0.5,1.4,0.7l0.6,0.3l0.5-0.5c4-3.7,11.6-10,17.2-10.6l0,0c0.3,0,0.5,0.1,0.7,0.3
        c0.2,0.2,0.2,0.5,0.1,0.8c-1.8,4.8-3.3,9.7-4.3,14.7c-1.7,8.3-0.3,9.1,0.3,9.5c0.3,0.2,0.6,0.2,0.9,0.2c0.2,0,0.5,0,0.7-0.1
        c0.3,0.6,0.6,1.2,0.8,1.8c0.4,1.2,0.9,2.5,1.3,3.9s0.8,2.8,1.1,4.1s0.5,2.6,0.7,3.7c0.4,3.3,0.8,6.9,1.1,10.6s0.7,7.5,0.9,11.4
        s1.2,20.3,1.4,24.8c0.2,4.2,0.3,8.6,0.5,13.1v0.9C94.5,136.3,82.6,139.5,70.4,139.5z"
          />
          <path
            style="fill: #3f3f3f;"
            d="M98.8,31.6c-1.8,4.7-3.2,9.6-4.3,14.6c-1.5,7.5-0.8,9.6,0.8,10.5c0.4,0.2,0.9,0.4,1.4,0.4h0.1
        c0.2,0.3,0.3,0.7,0.4,1c0.4,1.2,0.9,2.5,1.3,3.8s0.8,2.7,1,4c0.3,1.2,0.5,2.4,0.6,3.6c0.4,3.3,0.8,6.9,1.1,10.6s0.6,7.4,0.9,11.4
        s1.2,20.2,1.4,24.7c0.2,4.2,0.3,8.6,0.5,13.1v0.3c-19.3,10.9-42.7,11.8-62.7,2.3v-1.1c-0.1-3.7-0.2-7.2-0.4-10.6s-0.3-6.8-0.5-10
        s-0.3-6.3-0.5-9.2c-0.2-3.2-0.3-6-0.5-8.4c-0.2-2.8-0.4-5.4-0.6-7.8c-0.2-2.6-0.5-4.9-0.7-7.2v-0.1c-0.1-0.8-0.5-2.9-1.8-3.8
        c0-2,0.2-3.9,0.5-5.9c0.6-2.3,1.4-4.6,2.3-6.8c0.6-1.3,1.2-2.5,1.9-3.7l0.3-0.5v-0.6c-0.3-5.6-1.9-11-4.9-18.7
        c3.3,0.5,8.1,3.3,13.5,7.9l0.9,0.8l1.1-0.4c1.2-0.5,2.4-0.9,3.7-1.3c1.9-0.6,3.8-1.1,5.6-1.6c1.4-0.3,2.8-0.6,4.2-0.8
        c1.2-0.1,2.5-0.2,3.7-0.2h0.1c2.4,0,3.8-0.6,4.1-1.9c2,0.2,3.9,0.7,5.7,1.5c0.4,0.2,0.9,0.5,1.4,0.7l1.2,0.7l1-1
        C89.1,35.9,94.8,32.2,98.8,31.6 M99.1,29.5h-0.2c-5.8,0.7-13.3,6.8-17.7,10.9c-0.5-0.3-1-0.5-1.6-0.8c-2.2-1-4.6-1.6-7-1.7h-0.3
        c-1.3,0.1-2.5,0.3-3.7,0.7c5.1,0.8,1.1,1.2,0.4,1.2H69c-1.3,0-2.7,0.1-4,0.2c-1.5,0.2-3,0.5-4.5,0.8c-1.8,0.4-3.7,1-5.7,1.6
        c-1.3,0.4-2.5,0.8-3.8,1.3c-4.6-3.9-10.8-8.2-15.5-8.5h0c-0.8,0-1.5,0.6-1.5,1.4c0,0.2,0,0.4,0.1,0.6c3,7.8,4.8,13.3,5,19
        c-0.7,1.3-1.4,2.6-1.9,3.9c-1,2.3-1.8,4.7-2.5,7.1c-0.5,2.1-0.6,8.5-0.6,8.5c0.1-0.2,0.4-0.4,0.7-0.5c0.4,0,0.9,0.5,1.2,2.6
        c0.3,2.3,0.5,4.7,0.7,7.1s0.4,5,0.6,7.7s0.3,5.5,0.5,8.4s0.3,6,0.5,9.2s0.3,6.6,0.5,10s0.3,7,0.4,10.6c0,0.8,0,1.5,0.1,2.3
        c21.2,10.5,46.3,9.5,66.7-2.5c0-0.5,0-1,0-1.5c-0.1-4.4-0.3-8.8-0.5-13.1c-0.2-4.3-1.1-20.9-1.4-24.8s-0.6-7.8-0.9-11.4
        s-0.7-7.2-1.1-10.7c-0.2-1.3-0.4-2.5-0.7-3.8c-0.3-1.4-0.7-2.8-1.1-4.2s-0.8-2.7-1.3-4c-0.5-1.2-1.1-2.3-1.7-3.5
        c0.2,0.4,0,0.9-0.5,1.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4-0.1c-1.9-1.2,0.9-13.6,4.5-22.9c0.4-0.9-0.1-2-1-2.4
        C99.5,29.5,99.3,29.5,99.1,29.5L99.1,29.5L99.1,29.5z"
          />
        </g>
        <path
          style="fill: #3f3f3f; stroke: #3f3f3f; stroke-miterlimit: 10;"
          d="M70.5,1.8c37.9,0,68.7,30.7,68.7,68.7s-30.7,68.7-68.7,68.7
    S1.8,108.4,1.8,70.5C1.9,32.6,32.6,1.9,70.5,1.8 M70.5,0.5c-38.7,0-70,31.3-70,70s31.3,70,70,70s70-31.3,70-70S109.2,0.5,70.5,0.5
    L70.5,0.5z"
        />
        <path
          style="fill: #002e40;"
          d="M86.8,41.6c1.6-1.5,4.8-4.2,7.1-4.5c0.4,0,0.7,0.2,0.7,0.6c0,0.1,0,0.2,0,0.3c-1,2.3-1.7,4.7-1.9,7.2
    c0,0.5-0.5,0.9-1,0.9c-0.2,0-0.3-0.1-0.4-0.1L87,43.4c-0.5-0.3-0.7-1-0.4-1.5C86.7,41.7,86.7,41.6,86.8,41.6z"
        />
        <path
          style="fill: #7acefe;"
          d="M69.2,53.6c-0.7,0-1.5,0-2.2,0.1c-6.7,0.8-12.4,5.2-14.8,11.5c-1.3,3.5,0.5,7.3,3.9,8.6
    c0.7,0.3,1.5,0.4,2.3,0.4c7.3,0,14.7-0.8,21.8-2.6c3.6-0.9,5.8-4.5,4.9-8.1c-0.2-0.8-0.5-1.5-1-2.2C80.7,56.5,75.1,53.6,69.2,53.6z"
        />
        <path
          style="opacity: 0.3; fill: #002e40; enable-background: new;"
          d="M52.7,52.4c-0.6,0-1-0.5-1-1c0-0.3,0.1-0.6,0.4-0.8
    c4-3.2,8.9-5.2,14-5.8l0.9-0.1l0.8-0.1h0.8c4.8-0.2,9.7,0.9,13.9,3.2c0.5,0.2,0.8,0.8,0.6,1.3c-0.2,0.5-0.8,0.8-1.3,0.6
    c-0.1,0-0.1-0.1-0.2-0.1c-4-2.1-8.4-3.1-12.9-3H68l-0.8,0.1l-0.8,0.1c-4.8,0.6-9.3,2.4-13,5.4C53.2,52.4,53,52.4,52.7,52.4z"
        />
        <path style="fill: none; stroke: #002e40; stroke-width: 0.66; stroke-miterlimit: 10;" d="M52.7,51.4" />
        <circle style="fill: #002e40;" cx="61.8" cy="63.5" r="2.1" />
        <circle style="fill: #002e40;" cx="73.1" cy="62.4" r="2.1" />
        <path
          style="fill: #ffd261;"
          d="M64,74.1c-0.4-0.4-0.4-1.2,0-1.6c0.2-0.2,0.4-0.3,0.7-0.3l8.3-0.6l0,0c0.6,0,1.1,0.5,1.1,1.1
    c0,0.2-0.1,0.5-0.2,0.7l-4.5,6.2L64,74.1z"
        />
        <path
          style="fill: #373842;"
          d="M73,72.2c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.1,5.6l-4.9-4.9c-0.2-0.2-0.2-0.5,0-0.7
    c0.1-0.1,0.2-0.1,0.3-0.1L73,72.2L73,72.2 M73,70.8h-0.1l-8.2,0.7c-1,0.1-1.7,0.9-1.6,1.9c0,0.4,0.2,0.8,0.5,1.1l4.9,4.9l1.1,1.1
    l0.9-1.2l4.1-5.6c0.6-0.8,0.4-1.9-0.4-2.5C73.8,71,73.4,70.8,73,70.8L73,70.8z"
        />
        <rect
          x="69"
          y="79.5"
          transform="matrix(0.9969 -7.880708e-02 7.880708e-02 0.9969 -6.2432 5.7417)"
          style="fill: #002e40;"
          width="1.3"
          height="5"
        />
        <path
          style="fill: #002e40;"
          d="M62.8,90.2c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0,0,0,0,0,0c1.5-4.5,6.4-7,11-5.5
    c2.1,0.7,3.9,2.2,4.9,4.2c0.2,0.3,0,0.7-0.3,0.9c-0.3,0.2-0.7,0-0.9-0.3c-1.9-3.6-6.3-5-9.9-3.2c-1.7,0.9-3,2.4-3.6,4.2
    C63.3,90.1,63.1,90.2,62.8,90.2z"
        />
        <path
          style="fill: #002e40;"
          d="M46.5,77.2c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0.2-0.5,0.4-0.6c0.3-0.1,7.7-3.2,14.1-2.2
    c0.3,0.1,0.5,0.5,0.4,0.8c-0.1,0.3-0.4,0.5-0.7,0.5c-6.1-1-13.3,2.1-13.4,2.1C46.7,77.1,46.6,77.2,46.5,77.2z"
        />
        <path
          style="fill: #002e40;"
          d="M52.5,82.2c-0.4,0-0.6-0.3-0.6-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,2.5-3.5,8.1-3.4
    c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0c0,0.4-0.3,0.6-0.6,0.7c0,0,0,0,0,0h-0.2c-4.8,0-6.8,2.8-6.8,2.9C52.9,82.1,52.7,82.2,52.5,82.2z
    "
        />
        <path
          style="fill: #002e40;"
          d="M78.2,73.8c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0.2-0.5,0.5-0.6c4.4-1.4,8.9-2.2,13.5-2.6
    c0.4,0,0.7,0.2,0.7,0.6s-0.2,0.7-0.6,0.7c-4.5,0.4-8.9,1.2-13.1,2.6C78.4,73.7,78.3,73.8,78.2,73.8z"
        />
        <path
          style="fill: #002e40;"
          d="M87.5,78.2c-0.1,0-0.2,0-0.3-0.1c-2.6-0.9-5.4-1-8.1-0.4c-0.4,0.1-0.7-0.2-0.8-0.5
    c-0.1-0.4,0.2-0.7,0.5-0.8l0,0c2.9-0.7,6-0.5,8.9,0.5c0.3,0.1,0.5,0.5,0.4,0.9C88,78,87.8,78.2,87.5,78.2z"
        />
        <path
          style="fill: #002e40;"
          d="M47.6,46.2c-1.4-1.3-5-4.5-7.5-4.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0,0.1,0,0.2c1.2,3.1,1.9,5.2,2,7.4
    c0,0.4,0.3,0.7,0.7,0.6c0.1,0,0.2,0,0.3-0.1l4.9-3.1c0.3-0.2,0.4-0.6,0.2-0.9C47.7,46.3,47.7,46.2,47.6,46.2z"
        />
      </svg>
      <svg
        version="1.1"
        id="cat-1"
        *ngIf="(cluster.type === ClusterTypes.production || !cluster.type) && cluster.nodes <= 5"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="141px"
        height="141px"
        viewBox="0 0 141 141"
        style="enable-background: new 0 0 141 141;"
        xml:space="preserve"
      >
        <title>xplenty-cluster-cat-2</title>
        <g>
          <path
            style="fill: #0ca3e5;"
            d="M70.4,139.5c-10.4,0-20.8-2.3-30.1-6.9v-1.7c-0.1-3.6-0.2-7.2-0.4-10.6c-0.1-3.4-0.3-6.8-0.5-10
        c-0.2-3.2-0.3-6.3-0.5-9.2s-0.3-5.8-0.5-8.4s-0.4-5.4-0.6-7.8c-0.2-2.6-0.5-4.9-0.7-7.2c-0.2-1-0.5-3.1-1.9-3.4
        c0-2.3,0.2-4.5,0.5-6.8c0.6-2.4,1.4-4.7,2.4-6.9c0.6-1.3,1.2-2.6,1.9-3.8l0.1-0.2v-0.3c-0.3-5.7-2-11.2-5.1-19.3
        c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.4-0.2c3.6,0.2,8.8,3,15,8.2l0.5,0.4l0.5-0.2c1.2-0.5,2.5-0.9,3.7-1.3
        c1.9-0.6,3.8-1.2,5.7-1.6c1.4-0.4,2.9-0.6,4.3-0.8c1.3-0.1,2.6-0.2,3.8-0.2l0,0c2.1,0,3.1-0.5,3.2-1.4c0-0.2,0-0.4-0.1-0.6h0.5
        c2.3,0.2,4.6,0.7,6.7,1.7c0.4,0.2,0.9,0.5,1.4,0.7l0.6,0.3l0.5-0.5c4-3.7,11.6-10,17.2-10.6l0,0c0.3,0,0.5,0.1,0.7,0.3
        c0.2,0.2,0.2,0.5,0.1,0.8c-1.8,4.8-3.2,9.7-4.3,14.7c-1.7,8.3-0.3,9.1,0.3,9.5c0.3,0.2,0.6,0.2,0.9,0.2c0.2,0,0.5,0,0.7-0.1
        c0.3,0.6,0.6,1.2,0.8,1.8c0.4,1.2,0.9,2.5,1.3,3.9s0.7,2.8,1.1,4.1s0.5,2.6,0.7,3.7c0.4,3.3,0.8,6.9,1.1,10.6s0.7,7.5,0.9,11.4
        s1.1,20.3,1.3,24.7c0.2,4.2,0.3,8.6,0.5,13.1v0.9C94.5,136.3,82.6,139.5,70.4,139.5z"
          />
          <path
            style="fill: #3f3f3f;"
            d="M98.8,31.6c-1.8,4.7-3.2,9.6-4.3,14.6c-1.5,7.5-0.8,9.6,0.8,10.5c0.4,0.2,0.9,0.4,1.4,0.4h0.1
        c0.2,0.3,0.3,0.7,0.4,1c0.4,1.2,0.9,2.5,1.3,3.8s0.8,2.7,1,4c0.3,1.2,0.5,2.4,0.6,3.6c0.4,3.3,0.8,6.9,1.1,10.6s0.6,7.4,0.9,11.4
        s1.2,20.2,1.4,24.7c0.2,4.2,0.3,8.6,0.5,13.1v0.3c-19.3,10.9-42.7,11.8-62.7,2.3v-1.1c-0.1-3.6-0.2-7.2-0.4-10.6s-0.3-6.8-0.5-10
        s-0.3-6.3-0.5-9.2c-0.2-3.2-0.3-6-0.5-8.4c-0.2-2.8-0.4-5.4-0.6-7.8c-0.2-2.6-0.5-4.9-0.7-7.2v-0.1c-0.1-0.8-0.5-2.9-1.8-3.8
        c0-2,0.1-3.9,0.5-5.9c0.6-2.3,1.4-4.6,2.4-6.8c0.6-1.3,1.2-2.5,1.9-3.7l0.3-0.5v-0.6c-0.3-5.6-1.9-11-4.9-18.7
        c3.3,0.5,8.1,3.3,13.5,7.9l0.9,0.8l1.1-0.4c1.2-0.5,2.4-0.9,3.7-1.3c1.9-0.6,3.8-1.1,5.6-1.6c1.4-0.3,2.8-0.6,4.2-0.8
        c1.2-0.1,2.5-0.2,3.7-0.2h0.1c2.4,0,3.8-0.6,4.1-1.9c2,0.2,3.9,0.7,5.7,1.5c0.4,0.2,0.9,0.5,1.4,0.7l1.2,0.7l1-1
        C89.1,35.9,94.8,32.2,98.8,31.6 M99.1,29.5h-0.2c-5.8,0.7-13.3,6.8-17.7,10.9c-0.5-0.3-1-0.5-1.6-0.8c-2.2-1-4.6-1.6-7-1.7h-0.2
        c-1.3,0.1-2.5,0.3-3.7,0.7c5.1,0.8,1.1,1.2,0.4,1.2H69c-1.3,0-2.7,0.1-4,0.2c-1.5,0.2-3,0.5-4.5,0.8c-1.8,0.4-3.7,1-5.7,1.6
        c-1.3,0.4-2.5,0.8-3.8,1.3c-4.5-3.9-10.7-8.2-15.5-8.4h0c-0.8,0-1.5,0.6-1.5,1.4c0,0.2,0,0.4,0.1,0.6c3,7.8,4.8,13.3,5,19
        c-0.7,1.3-1.4,2.6-1.9,3.9c-1,2.3-1.8,4.7-2.5,7.1c-0.5,2.1-0.6,8.5-0.6,8.5c0.1-0.2,0.4-0.4,0.7-0.5c0.4,0,0.9,0.5,1.2,2.6
        c0.3,2.3,0.5,4.7,0.7,7.1s0.4,5,0.6,7.7s0.3,5.5,0.5,8.4s0.3,6,0.5,9.2s0.3,6.6,0.5,10s0.3,7,0.4,10.6c0,0.8,0,1.5,0.1,2.3
        c21.2,10.5,46.3,9.6,66.7-2.4c0-0.5,0-1,0-1.5c-0.1-4.4-0.3-8.8-0.5-13.1c-0.2-4.3-1.1-20.9-1.4-24.8s-0.6-7.8-0.9-11.4
        s-0.8-7.3-1.2-10.7c-0.2-1.3-0.4-2.5-0.7-3.8c-0.3-1.4-0.7-2.8-1.1-4.2s-0.8-2.7-1.3-4c-0.5-1.2-1.1-2.3-1.7-3.5
        c0.2,0.4,0,0.9-0.5,1.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4-0.1c-1.9-1.2,0.9-13.6,4.5-22.9c0.4-0.9-0.1-2-1-2.4
        C99.5,29.5,99.3,29.5,99.1,29.5L99.1,29.5L99.1,29.5z"
          />
        </g>
        <path
          style="fill: #3f3f3f; stroke: #3f3f3f; stroke-miterlimit: 10;"
          d="M70.5,1.8c37.9,0,68.7,30.7,68.7,68.7s-30.7,68.7-68.7,68.7
    S1.8,108.4,1.8,70.5c0,0,0,0,0,0C1.9,32.6,32.6,1.9,70.5,1.8 M70.5,0.5c-38.7,0-70,31.3-70,70s31.3,70,70,70s70-31.3,70-70l0,0
    C140.5,31.8,109.2,0.5,70.5,0.5C70.5,0.5,70.5,0.5,70.5,0.5z"
        />
        <path
          style="fill: #002e40;"
          d="M86.8,41.6c1.6-1.5,4.6-4.1,6.9-4.5c0.4-0.1,0.8,0.2,0.9,0.7c0,0.1,0,0.2,0,0.3
    c-1,2.3-1.6,4.7-1.8,7.1c0,0.5-0.5,0.9-1,0.9c-0.2,0-0.3-0.1-0.4-0.1L87,43.4c-0.5-0.3-0.7-1-0.4-1.5C86.7,41.7,86.7,41.6,86.8,41.6
    z"
        />
        <path
          style="fill: #7acefe;"
          d="M69.2,53.6c-0.7,0-1.5,0-2.2,0.1c-6.7,0.8-12.4,5.2-14.8,11.6c-1.3,3.5,0.5,7.3,3.9,8.6
    c0.7,0.3,1.5,0.4,2.3,0.4c7.3,0.1,14.7-0.8,21.8-2.6c3.6-0.9,5.8-4.5,4.9-8.1c-0.2-0.8-0.5-1.5-1-2.2C80.7,56.6,75.1,53.6,69.2,53.6
    z"
        />
        <path
          style="opacity: 0.3; fill: #002e40; enable-background: new;"
          d="M52.7,52.4c-0.6,0-1-0.5-1-1c0-0.3,0.1-0.6,0.4-0.8
    c4-3.2,8.9-5.2,14-5.8l0.9-0.1h0.8h0.8c4.8-0.1,9.7,1,13.9,3.3c0.5,0.2,0.8,0.8,0.6,1.3c-0.2,0.5-0.8,0.8-1.3,0.6
    c-0.1,0-0.1-0.1-0.2-0.1c-4-2.1-8.4-3.1-12.9-3h-0.8l-0.8,0l-0.8,0.1c-4.8,0.6-9.3,2.4-13,5.4C53.2,52.4,53,52.4,52.7,52.4z"
        />
        <path style="fill: none; stroke: #002e40; stroke-width: 0.66; stroke-miterlimit: 10;" d="M52.7,51.4" />
        <circle style="fill: #002e40;" cx="61.3" cy="63" r="2.6" />
        <circle style="fill: #002e40;" cx="73.6" cy="61.9" r="2.6" />
        <path
          style="fill: #ffd261;"
          d="M64,74.1c-0.4-0.4-0.4-1.2,0-1.6c0.2-0.2,0.4-0.3,0.7-0.3l8.2-0.7H73c0.6,0,1.1,0.5,1.1,1.1
    c0,0.2-0.1,0.5-0.2,0.7l-4.5,6.2L64,74.1z"
        />
        <path
          style="fill: #373842;"
          d="M73,72.2c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.1,5.6l-4.8-4.8c-0.2-0.2-0.2-0.5,0-0.7
    c0.1-0.1,0.2-0.1,0.3-0.1L73,72.2L73,72.2 M73,70.8h-0.1l-8.2,0.7c-1,0.1-1.7,0.9-1.7,1.9c0,0.4,0.2,0.8,0.5,1.1l4.9,4.9l1.1,1.1
    l0.9-1.2l4.1-5.5c0.6-0.8,0.4-1.9-0.4-2.5C73.8,71,73.4,70.9,73,70.8L73,70.8L73,70.8z"
        />
        <path
          style="fill: #002e40;"
          d="M69.1,83.8l-0.3-4.3l1.3-0.1l0.3,4.3c0,0.4-0.2,0.7-0.6,0.7c0,0,0,0,0,0l0,0
    C69.5,84.5,69.2,84.2,69.1,83.8z"
        />
        <path
          style="fill: #002e40;"
          d="M68.5,90.3c-1.2,0-2.4-0.1-3.6-0.3c-1.5-0.3-3-0.8-4.4-1.6c-1.2-0.7-2.3-1.6-3.2-2.7
    c-0.2-0.3-0.2-0.7,0.1-0.9c0.3-0.2,0.7-0.2,0.9,0.1l0,0c0.7,1,1.7,1.7,2.8,2.3c1.3,0.7,2.6,1.2,4,1.4c3.2,0.6,6.5,0.3,9.6-0.8
    c1.3-0.5,2.6-1.1,3.8-2c1-0.7,1.8-1.7,2.4-2.7c0.2-0.3,0.6-0.4,0.9-0.3c0.3,0.2,0.4,0.6,0.3,0.9l0,0c-0.7,1.2-1.6,2.3-2.7,3.1
    c-1.2,1-2.6,1.7-4.1,2.2c-1.6,0.6-3.4,1-5.1,1.1C69.6,90.3,69,90.3,68.5,90.3z"
        />
        <path
          style="fill: #002e40;"
          d="M46.5,76.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0.2-0.5,0.4-0.6c0.3-0.1,7.7-3.2,14.1-2.2
    c0.4,0.1,0.6,0.4,0.5,0.8c-0.1,0.4-0.4,0.6-0.8,0.5l0,0c-6.1-1-13.3,2.1-13.4,2.1C46.7,76.4,46.6,76.4,46.5,76.4z"
        />
        <path
          style="fill: #002e40;"
          d="M52.5,81.4c-0.4,0-0.6-0.3-0.6-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,2.5-3.5,8.1-3.4
    c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0c-4.9-0.1-7,2.8-7,2.9C52.9,81.4,52.7,81.5,52.5,81.4z"
        />
        <path
          style="fill: #002e40;"
          d="M78.2,73c-0.4,0-0.7-0.2-0.7-0.6c0-0.3,0.2-0.6,0.5-0.7c4.4-1.4,8.9-2.2,13.5-2.6
    c0.4,0,0.7,0.2,0.7,0.6s-0.2,0.7-0.6,0.7c-4.5,0.4-8.9,1.2-13.1,2.6C78.4,73,78.3,73,78.2,73z"
        />
        <path
          style="fill: #002e40;"
          d="M87.5,77.4c-0.1,0-0.2,0-0.3-0.1c-2.6-0.9-5.4-1-8.1-0.4c-0.4,0.1-0.7-0.2-0.8-0.5
    c-0.1-0.4,0.2-0.7,0.5-0.8c2.9-0.7,6-0.5,8.9,0.5c0.3,0.1,0.5,0.5,0.4,0.9C88,77.3,87.8,77.4,87.5,77.4z"
        />
        <path
          style="fill: #002e40;"
          d="M47.6,46.2c-1.4-1.3-4.9-4.4-7.4-4.6c-0.3,0-0.6,0.2-0.6,0.5c0,0.1,0,0.2,0,0.2
    c1.2,3,1.9,5.1,1.9,7.3c0,0.4,0.3,0.7,0.7,0.6c0.1,0,0.2,0,0.3-0.1l4.9-3.1c0.3-0.2,0.4-0.6,0.2-0.9C47.7,46.3,47.7,46.2,47.6,46.2z
    "
        />
      </svg>
      <svg
        version="1.1"
        id="cat-2"
        *ngIf="(cluster.type === ClusterTypes.production || !cluster.type) && cluster.nodes > 5"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="141px"
        height="141px"
        viewBox="0 0 141 141"
        style="enable-background: new 0 0 141 141;"
        xml:space="preserve"
      >
        <title>xplenty-cluster-cat-3</title>
        <g>
          <g>
            <path
              style="fill: #0ca3e5;"
              d="M70.4,139.5c-10.4,0-20.8-2.3-30.1-6.9v-1.7c-0.1-3.6-0.2-7.2-0.4-10.6c-0.1-3.4-0.3-6.8-0.5-10
            c-0.2-3.2-0.3-6.3-0.5-9.2s-0.3-5.8-0.5-8.4s-0.4-5.4-0.6-7.8c-0.2-2.6-0.5-4.9-0.7-7.2c-0.2-1-0.5-3.1-1.9-3.4
            c0-2.3,0.2-4.5,0.5-6.8c0.6-2.4,1.4-4.7,2.4-6.9c0.6-1.3,1.2-2.6,1.9-3.8l0.1-0.2v-0.3C39.9,50.5,38.1,45,35,36.9
            c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.4-0.2c3.6,0.2,8.8,3,15,8.2l0.5,0.4l0.5-0.2c1.2-0.5,2.5-0.9,3.7-1.3
            c1.9-0.6,3.8-1.2,5.7-1.6c1.4-0.4,2.9-0.6,4.4-0.9c1.3-0.1,2.6-0.2,3.8-0.2l0,0c2.1,0,3.1-0.5,3.2-1.4c0-0.2,0-0.4-0.1-0.6h0.5
            c2.3,0.2,4.6,0.7,6.7,1.7c0.4,0.2,0.9,0.5,1.4,0.7l0.6,0.3l0.5-0.5c4-3.7,11.6-10,17.2-10.6l0,0c0.3,0,0.5,0.1,0.7,0.3
            c0.2,0.2,0.2,0.5,0.1,0.8c-1.8,4.8-3.3,9.7-4.3,14.7c-1.7,8.3-0.3,9.1,0.3,9.5c0.3,0.2,0.6,0.2,0.9,0.2c0.2,0,0.5,0,0.7-0.1
            c0.3,0.6,0.6,1.2,0.8,1.8c0.4,1.2,0.9,2.5,1.3,3.9s0.8,2.8,1.1,4.1s0.5,2.6,0.7,3.7c0.4,3.3,0.8,6.9,1.1,10.6s0.7,7.5,0.9,11.4
            s1.2,20.3,1.4,24.8c0.2,4.2,0.3,8.6,0.5,13.1v0.9C94.5,136.3,82.6,139.5,70.4,139.5z"
            />
            <path
              style="fill: #3f3f3f;"
              d="M98.8,31.6c-1.8,4.7-3.2,9.6-4.3,14.6c-1.5,7.5-0.8,9.6,0.8,10.5c0.4,0.2,0.9,0.4,1.4,0.4h0.1
            c0.2,0.3,0.3,0.7,0.4,1c0.4,1.2,0.9,2.5,1.3,3.8s0.8,2.7,1,4c0.3,1.2,0.5,2.4,0.6,3.6c0.4,3.3,0.8,6.9,1.1,10.6s0.6,7.4,0.9,11.4
            s1.2,20.2,1.4,24.7c0.2,4.2,0.3,8.6,0.5,13.1v0.3c-19.3,10.9-42.7,11.8-62.7,2.3v-1.1c-0.1-3.7-0.2-7.2-0.4-10.6s-0.3-6.8-0.5-10
            s-0.3-6.3-0.5-9.2c-0.2-3.2-0.3-6-0.5-8.4c-0.2-2.8-0.4-5.4-0.6-7.8c-0.2-2.6-0.5-4.9-0.7-7.2v-0.1c-0.1-0.8-0.5-2.9-1.8-3.8
            c0-2,0.2-3.9,0.5-5.9c0.6-2.3,1.4-4.6,2.3-6.8c0.6-1.3,1.2-2.5,1.9-3.7l0.3-0.5v-0.6c-0.3-5.6-1.9-11-4.9-18.7
            c3.3,0.5,8.1,3.3,13.5,7.9l0.9,0.8l1.1-0.4c1.2-0.5,2.4-0.9,3.7-1.3c1.9-0.6,3.8-1.1,5.6-1.6c1.4-0.3,2.8-0.6,4.2-0.8
            c1.2-0.1,2.5-0.2,3.7-0.2h0.1c2.4,0,3.8-0.6,4.1-1.9c2,0.2,3.9,0.7,5.7,1.5c0.4,0.2,0.9,0.5,1.4,0.7l1.2,0.7l1-1
            C89.1,35.9,94.8,32.2,98.8,31.6 M99.1,29.5h-0.2c-5.8,0.7-13.3,6.8-17.7,10.9c-0.5-0.3-1-0.5-1.6-0.8c-2.2-1-4.6-1.6-7-1.7h-0.3
            c-1.3,0.1-2.5,0.3-3.7,0.7c5.1,0.8,1.1,1.2,0.4,1.2H69c-1.3,0-2.7,0.1-4,0.2c-1.5,0.2-3,0.5-4.5,0.8c-1.8,0.4-3.7,1-5.7,1.6
            c-1.3,0.4-2.5,0.8-3.8,1.3c-4.6-3.9-10.8-8.2-15.5-8.5h0c-0.8,0-1.5,0.6-1.5,1.4c0,0.2,0,0.4,0.1,0.6c3,7.8,4.8,13.3,5,19
            c-0.7,1.3-1.4,2.6-1.9,3.9c-1,2.3-1.8,4.7-2.5,7.1c-0.5,2.1-0.6,8.5-0.6,8.5c0.1-0.2,0.4-0.4,0.7-0.5c0.4,0,0.9,0.5,1.2,2.6
            c0.3,2.3,0.5,4.7,0.7,7.1s0.4,5,0.6,7.7s0.3,5.5,0.5,8.4s0.3,6,0.5,9.2s0.3,6.6,0.5,10s0.3,7,0.4,10.6c0,0.8,0,1.5,0.1,2.3
            c21.2,10.5,46.3,9.5,66.7-2.5c0-0.5,0-1,0-1.5c-0.1-4.4-0.3-8.8-0.5-13.1c-0.2-4.3-1.1-20.9-1.4-24.8s-0.6-7.8-0.9-11.4
            s-0.7-7.2-1.1-10.7c-0.2-1.3-0.4-2.5-0.7-3.8c-0.3-1.4-0.7-2.8-1.1-4.2s-0.8-2.7-1.3-4c-0.5-1.2-1.1-2.3-1.7-3.5
            c0.2,0.4,0,0.9-0.5,1.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4-0.1c-1.9-1.2,0.9-13.6,4.5-22.9c0.4-0.9-0.1-2-1-2.4
            C99.5,29.5,99.3,29.5,99.1,29.5L99.1,29.5L99.1,29.5z"
            />
          </g>
          <path
            style="fill: #002e40;"
            d="M86.8,41.6c1.9-1.8,5.8-5.1,8.1-4.4c-1.3,3.4-2.1,5.7-2.2,8.1c0,0.5-0.4,0.9-0.9,0.9
        c-0.2,0-0.3-0.1-0.4-0.1L87,43.4c-0.5-0.3-0.7-1-0.4-1.5C86.7,41.7,86.7,41.6,86.8,41.6z"
          />
          <path
            style="fill: #7acefe;"
            d="M69.2,51.6c-0.8,0-1.6,0-2.4,0.1c-7.3,0.9-13.6,5.7-16.3,12.6c-1.4,3.8,0.5,8,4.3,9.4
        c0.8,0.3,1.7,0.5,2.5,0.5c8.1,0,16.1-0.9,23.9-2.8c3.9-1,6.3-4.9,5.4-8.9c-0.2-0.9-0.6-1.7-1.1-2.4C81.9,54.8,75.8,51.6,69.2,51.6z
        "
          />
          <path
            style="opacity: 0.3; fill: #002e40; enable-background: new;"
            d="M52.7,52.4c-0.6,0-1-0.5-1-1c0-0.3,0.1-0.6,0.4-0.8
        c4-3.2,8.9-5.2,14-5.8l0.9-0.1l0.8-0.1h0.8c4.8-0.2,9.7,0.9,13.9,3.2c0.5,0.2,0.8,0.8,0.6,1.3c-0.2,0.5-0.8,0.8-1.3,0.6
        c-0.1,0-0.1-0.1-0.2-0.1c-4-2.1-8.4-3.1-12.9-3H68l-0.8,0.1l-0.8,0.1c-4.8,0.6-9.3,2.4-13,5.4C53.2,52.4,53,52.4,52.7,52.4z"
          />
          <path style="fill: none; stroke: #002e40; stroke-width: 0.66; stroke-miterlimit: 10;" d="M52.7,51.4" />
          <path
            style="fill: #002e40;"
            d="M62.8,65.8c1.1-0.2,2.2-0.2,3.2,0c0.1-0.5,0.1-1.1,0-1.6c-0.5-1.9-2.4-3-4.3-2.5
        c-1.8,0.5-3,2.3-2.6,4.2c0.1,0.5,0.4,1,0.7,1.4C60.7,66.5,61.7,66,62.8,65.8z"
          />
          <path
            style="fill: #002e40;"
            d="M71.6,64.4c1.8,0,3.5,0.4,5.2,1.2c0.3-0.5,0.4-1,0.4-1.6c0.1-2-1.5-3.6-3.4-3.7s-3.6,1.5-3.7,3.4
        c0,0.3,0,0.6,0.1,0.8C70.6,64.4,71.1,64.4,71.6,64.4z"
          />
          <path
            style="fill: #ffd261;"
            d="M64,74.1c-0.4-0.4-0.4-1.2,0-1.6c0.2-0.2,0.4-0.3,0.7-0.3l8.3-0.6l0,0c0.6,0,1.1,0.5,1.1,1.1
        c0,0.2-0.1,0.5-0.2,0.7l-4.5,6.2L64,74.1z"
          />
          <path
            style="fill: #373842;"
            d="M73,72.2c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.1,5.6l-4.9-4.9c-0.2-0.2-0.2-0.5,0-0.7
        c0.1-0.1,0.2-0.1,0.3-0.1L73,72.2L73,72.2 M73,70.8h-0.1l-8.2,0.7c-1,0.1-1.7,0.9-1.6,1.9c0,0.4,0.2,0.8,0.5,1.1l4.9,4.9l1.1,1.1
        l0.9-1.2l4.1-5.6c0.6-0.8,0.4-1.9-0.4-2.5C73.8,71,73.4,70.8,73,70.8L73,70.8z"
          />
          <rect
            x="69"
            y="79.5"
            transform="matrix(0.9969 -7.880708e-02 7.880708e-02 0.9969 -6.2432 5.7417)"
            style="fill: #002e40;"
            width="1.3"
            height="5"
          />
          <path
            style="fill: #002e40;"
            d="M46.5,77.2c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0.2-0.5,0.4-0.6c0.3-0.1,7.7-3.2,14.1-2.2
        c0.3,0.1,0.5,0.5,0.4,0.8c-0.1,0.3-0.4,0.5-0.7,0.5c-6.1-1-13.3,2.1-13.4,2.1C46.7,77.1,46.6,77.2,46.5,77.2z"
          />
          <path
            style="fill: #002e40;"
            d="M52.5,82.2c-0.4,0-0.6-0.3-0.6-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,2.5-3.5,8.1-3.4
        c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0c-4.9-0.1-7,2.8-7,2.9C52.9,82.1,52.7,82.2,52.5,82.2z"
          />
          <path
            style="fill: #002e40;"
            d="M78.2,73.8c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0.2-0.5,0.5-0.6c4.4-1.4,8.9-2.2,13.5-2.6
        c0.4,0,0.7,0.2,0.7,0.6s-0.2,0.7-0.6,0.7c-4.5,0.4-8.9,1.2-13.1,2.6C78.4,73.7,78.3,73.8,78.2,73.8z"
          />
          <path
            style="fill: #002e40;"
            d="M87.5,78.2c-0.1,0-0.2,0-0.3-0.1c-2.6-0.9-5.4-1-8.1-0.4c-0.4,0.1-0.7-0.2-0.8-0.5
        c-0.1-0.4,0.2-0.7,0.5-0.8l0,0c2.9-0.7,6-0.5,8.9,0.5c0.3,0.1,0.5,0.5,0.4,0.9C88,78,87.8,78.2,87.5,78.2z"
          />
          <path
            style="fill: #002e40;"
            d="M47.6,46.2c-1.6-1.5-5.8-5.2-8.2-4.5c1.4,3.4,2.2,5.6,2.2,8c0,0.4,0.3,0.7,0.7,0.7
        c0.1,0,0.2,0,0.3-0.1l4.9-3.1C47.8,47,47.9,46.6,47.6,46.2C47.7,46.3,47.6,46.2,47.6,46.2z"
          />
          <path
            style="fill: #3f3f3f; stroke: #3f3f3f; stroke-miterlimit: 10;"
            d="M70.5,1.8c37.9,0,68.7,30.7,68.7,68.7s-30.7,68.7-68.7,68.7
        S1.8,108.4,1.8,70.5C1.9,32.6,32.6,1.9,70.5,1.8 M70.5,0.5c-38.7,0-70,31.3-70,70s31.3,70,70,70s70-31.3,70-70S109.2,0.5,70.5,0.5
        L70.5,0.5z"
          />
          <path
            style="fill: #002e40;"
            d="M69.8,84.6c-2.5,0.2-5-0.3-7.2-1.3c-0.4-0.2-1,0-1.2,0.4c0,0.1-0.1,0.2-0.1,0.3
        c-0.1,1.2,0.1,2.5,0.5,3.6c1.7,4.4,6.6,6.6,11,5c3.9-1.5,6.2-5.6,5.3-9.7c-0.1-0.5-0.5-0.8-1-0.7c-0.1,0-0.2,0.1-0.3,0.1
        C74.7,83.6,72.3,84.4,69.8,84.6z"
          />
        </g>
      </svg>
    </div>
  `,
})
export class ClusterCatComponent {
  @Input() cluster: Partial<Cluster>;

  ClusterTypes = ClusterTypes;
}
