import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { SnowflakeConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { RegionsResource } from '../../resources/regions.resource';

@Component({
  selector: 'connection-form-snowflake',
  template: `
    <xp-form-validation type="SnowflakeDatabaseConnection" [name]="formName">
      <form id="connectionFormSnowflake" name="connectionFormSnowflake" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <xp-form-group>
              <label for="account_name">{{ 'connections.form.labels.account_name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="account_name"
                id="account_name"
                [(ngModel)]="connection.account_name"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="col-md-6">
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
            <xp-form-group>
              <label for="database">{{ 'connections.form.labels.database' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="database"
                id="database"
                [(ngModel)]="connection.database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="warehouse">{{ 'connections.form.labels.warehouse' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="warehouse"
                id="warehouse"
                [(ngModel)]="connection.warehouse"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="region">{{ 'connections.form.athena.labels.region' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                id="region"
                name="region"
                [value]="connection.region"
                [options]="snowflakeRegions"
                [isGrouped]="true"
                [isSearchEnabled]="true"
                panelClass="connection-select-box"
                (valueChange)="onRegionChange($event)"
                emptyPlaceholder="No region found"
              >
              </xp-select>
            </xp-form-group>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormSnowflakeComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<SnowflakeConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormSnowflake';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  snowflakeRegions = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,

    private regionsResource: RegionsResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.regionsResource.query('snowflake').subscribe((snowflakeRegions) => {
      this.snowflakeRegions = snowflakeRegions.map(({ id, short_name, group_name }) => ({
        text: short_name,
        value: id,
        group_name,
      }));
    });
  }

  onRegionChange(region: string) {
    this.connection.region = region;
  }
}
