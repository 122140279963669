import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogData } from './components/dialog-template.component';
import { UnlockFeaturesDialogTemplateComponent } from './components/unlock-features-dialog-template.component';

@Injectable({
  providedIn: 'root',
})
export class UnlockFeaturesDialogService {
  defaults: DialogData;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  openDialog(data: Partial<DialogData>): MatDialogRef<UnlockFeaturesDialogTemplateComponent> {
    return this.dialog.open(UnlockFeaturesDialogTemplateComponent, {
      data: { ...this.defaults, ...data },
      position: {
        top: '150px',
      },
      maxWidth: data.maxWidth || '500px',
      minWidth: data.minWidth || '',
    });
  }
}
