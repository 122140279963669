import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { Schema } from '../../../package.models';

function getRelations(records: DesignerSchemaFieldI[]): DesignerSchemaFieldI[] {
  const relationsHash = {};
  records.forEach((record) => {
    Object.keys(record).forEach((key) => {
      const value = record[key];
      if (key.includes('input_')) {
        if (!relationsHash[key]) {
          relationsHash[key] = [];
        }
        relationsHash[key].push(value);
      }
    });
  });

  const relations = [];
  const keys = Object.keys(relationsHash).sort((a, b) => {
    const aInputNumber = Number(a.split('_')[1]);
    const bInputNumber = Number(b.split('_')[1]);

    if (aInputNumber > bInputNumber) return 1;
    return -1;
  });

  keys.forEach((key) => {
    relations.push({ fields: relationsHash[key] });
  });

  return relations;
}

@Component({
  selector: 'join-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['input_0', 'input_1']"
    >
      <ng-container *ngFor="let parentSchema of parentSchemas; let index = index">
        <ng-template [templateName]="'input_' + index" let-item>
          <xp-field-picker
            [value]="item.record['input_' + index]"
            [schema]="parentSchemas[index]"
            [fields]="(parentSchemas[index] || {}).fields || []"
            [index]="item.index"
            [preventEmpty]="false"
            propName="'input_' + index"
            (fieldChange)="onFieldChange($event, item.record, 'input_' + index)"
            class="fields-collection-editor"
          ></xp-field-picker>
        </ng-template>
        <ng-template [templateName]="'input_' + index + '-header'" let-item>
          {{ (parentSchemas[haveSchemasSwitched ? 1 - index : index] || {}).name | xpLengthCheck: 50 }}
        </ng-template>
      </ng-container>
    </xp-fields-collection>
  `,
  providers: [],
})
export class JoinCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: CollectionSettings;
  @Input() parentSchemas: Schema[];
  @Input() fieldsName: string;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  recordsCopy: DesignerSchemaFieldI[] = [];
  haveSchemasSwitched = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentSchemas?.currentValue) {
      const previousSchemas = changes.parentSchemas.previousValue;
      const currentSchemas = changes.parentSchemas.currentValue;
      if (!previousSchemas) {
        return;
      }

      this.haveSchemasSwitched =
        !this.haveSchemasSwitched &&
        previousSchemas[0]?.name === currentSchemas[1]?.name &&
        previousSchemas[1]?.name === currentSchemas[0]?.name;
      this.init();
    }
  }

  init() {
    const inputsLength = this.records.length;
    const fieldsLength = this.records.length ? this.records[0].fields.length : 0;
    this.recordsCopy = [];

    for (let i = 0; i < fieldsLength; i += 1) {
      const record = {};
      for (let j = 0; j < inputsLength; j += 1) {
        record[`input_${j}`] = this.records[j].fields[i];
      }
      this.recordsCopy.push(record);
    }

    this.recordsCopy = [...this.recordsCopy].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      hideSearch: true,
      itemsPerPage: 10,
      emptyRecord: (() => {
        const record = {};
        this.parentSchemas.forEach((input, index) => {
          record[`input_${index}`] = '';
        });
        return record;
      })(),
      parentSchemas: this.parentSchemas,
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { field_name: record.field_name };
    });
  }

  onRecordChange({ records }) {
    const fieldsName = this.fieldsName || 'relations';
    this.recordsCopy = records;
    const fields = getRelations(records);
    this.store.dispatch(
      updateComponent({
        component: {
          [fieldsName]: fields,
        },
      }),
    );
    this.recordsChange.emit(fields);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: string) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    const areFieldsValid = this.recordsCopy.reduce((acc, curr) => {
      return curr['input_0'] && curr['input_1'] && acc;
    }, true);
    this.validityChange.emit(value && areFieldsValid);
  }
}
