import { Component, EventEmitter, Input, Output } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { SORT_DIRECTION } from '../../../../constants/sort_direction';

@Component({
  selector: 'xp-sort-direction-picker',
  template: `
    <div style="display: flex; flex-flow: row nowrap; align-items: stretch; width: 100%;">
      <xp-select
        [value]="value"
        (valueChange)="onValueChange($event)"
        [options]="sortOrders"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        [preventEmpty]="true"
        style="width: 100%;"
      ></xp-select>
    </div>
  `,
  providers: [],
})
export class XpSortDirectionPickerComponent {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Output() fieldChange = new EventEmitter();
  sortOrders: SelectOption[] = SORT_DIRECTION;
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  onValueChange(value: ConditionOperator) {
    if (this.value === value) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }
    this.fieldChange.emit(value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
