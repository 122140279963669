import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { escape, forIn } from 'lodash';
import { first } from 'rxjs/operators';
import { NotifyService } from '../../common/services/notify.service';
import { AppState } from '../../store';
import { closePackagePickerModal } from '../store/schedules.actions';
import { SelectPickerTypes } from '../../common/components/forms/select-picker/select-picker-types.enum';
import { getStep } from '../../common/helper/get-step.helper';
import { Package } from '../../packages/package.models';
import { Variables } from '../../package-designer/package.models';
import { PermissionsService } from '../../common/services/permissions.service';
import { selectIsEditingPackage } from '../store/schedules.selectors';

function getPackageIcon(_package: Partial<Package>): string {
  const dataflowIcon = `<common-icon iconId="icon-package"></common-icon>`;
  const workflowIcon = `<common-icon iconId="icon-workflow"></common-icon>`;
  return _package.flow_type === 'dataflow' ? dataflowIcon : workflowIcon;
}

export function areVariablesOverridden(packageItem: Partial<Package>): boolean {
  if (!packageItem || !packageItem.variables) {
    return false;
  }
  return Object.keys(packageItem.variables).some(
    (key) => !packageItem.defaultVariables || packageItem.variables[key] !== packageItem.defaultVariables[key],
  );
}

@Component({
  selector: 'package-picker-form',
  template: `
    <form class="packages-picker-form schedule-form">
      <div class="packages-picker-form-body">
        <div class="packages-picker-form-body-content">
          <xp-steps>
            <xp-step [step]="packageStep">
              <xp-select-picker-editable
                id="package-picker"
                [type]="selectPickerTypes.package"
                [value]="item"
                placeholder="Select package"
                emptyPlaceholder="The list of packages is empty"
                (valueChange)="onPackageChange($event)"
                [hideNew]="true"
              ></xp-select-picker-editable>
            </xp-step>
            <xp-step [step]="packageVersionStep" id="packageVersionStep">
              <xp-input-checkbox
                [(ngModel)]="useLatest"
                name="Always run the latest version of the package"
                labelText="Always run the latest version of the package"
                (ngModelChange)="onCheckboxChange(useLatest)"
              ></xp-input-checkbox>
              <xp-select-picker-editable
                id="package-version-picker"
                *ngIf="item"
                [type]="selectPickerTypes['schedule-package-version']"
                [value]="paper_trail_package_version_object"
                [params]="{ package_id: item.id }"
                placeholder="Select package version"
                emptyPlaceholder="The list of package versions is empty"
                (valueChange)="onPackageVersionChange($event)"
                [hideNew]="true"
                [isDisabled]="isPackageVersionDisabled || useLatest"
                definedId="version"
              ></xp-select-picker-editable>
            </xp-step>
            <xp-step [step]="packageVariablesStep">
              <div class="variables-override-container">
                <xp-input-checkbox
                  [(ngModel)]="overridePackageVariables"
                  name="Override package variables"
                  labelText="Override package variables"
                  (ngModelChange)="onVariablesCheckboxChange(overridePackageVariables)"
                ></xp-input-checkbox>
                <i
                  class="fa fa-exclamation-circle form-control-info"
                  matTooltip="Useful if you would like to run the same package from multiple schedules (e.g. different time ranges, etc.)"
                  matTooltipPosition="right"
                  matTooltipClass="hide-arrow"
                ></i>
              </div>
              <package-variables-editor
                [isDisabled]="!overridePackageVariables"
                [variables]="variables"
                [secretVariables]="secretVariables"
                [package]="item"
                [disableKey]="true"
                [hideFooter]="true"
                [hideAdd]="true"
                [usePackageDefault]="true"
                [disableSecrets]="true"
                parentType="schedule"
                [variablesToSet]="variablesToSet"
                [valid]="packageVariablesStep.valid"
                [error]="packageVariablesStep.isError"
                (variablesChange)="onVariablesChange($event)"
                (secretVariablesChange)="onSecretVariablesChange($event)"
              ></package-variables-editor>
            </xp-step>
          </xp-steps>
        </div>
      </div>
      <div class="packages-picker-form-footer schedule-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-schedules" size="L"></common-icon>
          <h3 class="modal-title">
            <span *ngIf="isNew">{{ 'schedule.form.titles.new_task' | translate }}</span>
            <span *ngIf="!isNew">{{ 'schedule.form.titles.edit_task' | translate }}</span>
          </h3>
        </div>
        <button class="btn btn-lg btn-default pull-right" (click)="cancel()">
          {{ 'packages-picker.form.buttons.cancel' | translate }}
        </button>
        <button
          class="btn btn-lg btn-success pull-right"
          (click)="savePackage()"
          [ngClass]="{ disabled: !packageVariablesStep.valid || !packageStep.valid || !packageVersionStep.valid }"
        >
          {{ 'packages-picker.form.buttons.save' | translate }}
        </button>
      </div>
    </form>
  `,
})
export class PackagePickerFormComponent implements OnChanges {
  @Input() item: Partial<Package> = {};
  @Output() packageAdded = new EventEmitter<Partial<Package>>();
  @Output() packageEdited = new EventEmitter<Partial<Package>>();

  selectPickerTypes = SelectPickerTypes;
  packageStep = getStep({
    title: this.translate.instant('job-form.steps.package'),
    activeTitle: this.translate.instant('job-form.steps.package-edit'),
    valid: false,
    active: true,
  });
  packageVersionStep = getStep({
    title: this.translate.instant('job-form.steps.schedule-package-version'),
    activeTitle: this.translate.instant('job-form.steps.schedule-package-version-edit'),
    valid: true,
  });
  packageVariablesStep = getStep({
    title: this.translate.instant('job-form.steps.variables'),
    activeTitle: this.translate.instant('job-form.steps.variables-edit'),
  });
  package_name = '';
  package_id;
  variables: Variables;
  secretVariables: Variables = {};
  variablesToSet: Variables;
  isNew = true;
  paper_trail_package_version_object;
  paper_trail_package_version;
  useLatest = true;
  isPackageVersionDisabled = false;
  isInitialSet = false;
  overridePackageVariables = false;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,

    private permissionsService: PermissionsService,
  ) {
    this.checkPermissions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue) {
      this.package_id = this.item && this.item.id ? this.item.id : null;
      this.package_name = this.item && this.item.name ? this.item.name : null;
      this.variables = this.item && this.item.variables ? this.item.variables : {};
      this.secretVariables = this.item && this.item.secret_variables ? this.item.secret_variables : {};
      this.variablesToSet = this.item && this.item.variables ? this.item.variables : {};
      this.isNew = this.item === undefined;
      this.useLatest = !this.item.paper_trail_package_version;

      this.paper_trail_package_version_object = { ...this.item, version: this.item.paper_trail_package_version };
      this.isInitialSet = !!this.item.id;
      this.overridePackageVariables = areVariablesOverridden(this.item);
    }
  }

  onPackageChange(packageItem: Package) {
    if (this.isInitialSet) {
      this.isInitialSet = false;
      this.setPackageStep(this.item);
      return;
    }
    this.item = { ...packageItem };
    this.package_id = packageItem.id;
    this.setPackageStep({ ...packageItem });
  }

  setPackageStep(packageItem: Partial<Package>) {
    const packageIcon = getPackageIcon(packageItem);
    const tags = [
      {
        name: `${packageIcon} <b>${escape(packageItem.name)}</b>`,
      },
    ];
    this.packageStep = { ...this.packageStep, tags, valid: true };
    this.variables = packageItem.variables;
    this.secretVariables = packageItem.secret_variables;
    this.variablesToSet = { ...this.variables };
    this.setVariablesStep(this.variables);
  }

  checkPermissions() {
    return this.permissionsService
      .hasPermission$('versionControl')
      .pipe(first())
      .subscribe((hasPermission) => {
        this.packageVersionStep = { ...this.packageVersionStep, disable: !hasPermission };
        this.isPackageVersionDisabled = !hasPermission;
      });
  }

  setPackageVersionStep(newVersion: Package) {
    if (newVersion) {
      this.useLatest = false;
      this.packageVersionStep = { ...this.packageVersionStep, valid: this.isPackageVersionStepValid(false) };
    }
    if (!newVersion) {
      this.paper_trail_package_version = null;
      this.packageVersionStep = { ...this.packageVersionStep, valid: this.isPackageVersionStepValid(true) };
    }
  }

  isPackageVersionStepValid(useLatestValue: boolean) {
    return (
      this.useLatest ||
      useLatestValue ||
      (this.paper_trail_package_version !== null && this.paper_trail_package_version !== undefined)
    );
  }

  onPackageVersionChange(packageVersionItem: Package) {
    this.paper_trail_package_version_object = packageVersionItem;
    this.paper_trail_package_version = packageVersionItem.version;
    this.setPackageVersionStep(packageVersionItem);
  }

  setVariablesStep(variables) {
    let variablesValid = true;
    const tags = [];
    if (this.overridePackageVariables) {
      forIn(variables, function (value, key) {
        if (!value) {
          variablesValid = false;
        } else {
          tags.push({
            name: `${escape(key)}: <b>${escape(value)}</b>`,
          });
        }
      });
    }
    this.packageVariablesStep = { ...this.packageVariablesStep, valid: variablesValid, tags };
  }

  onCheckboxChange(value: boolean) {
    if (!this.isInitialSet && value) {
      this.paper_trail_package_version = null;
      this.paper_trail_package_version_object = null;
    }
    this.packageVersionStep = { ...this.packageVersionStep, valid: this.isPackageVersionStepValid(value) };
  }

  onVariablesCheckboxChange(value: boolean) {
    this.overridePackageVariables = value;
    this.setVariablesStep(this.variables);
  }

  onVariablesChange(variables) {
    this.variables = variables;
    this.setVariablesStep(variables);
  }

  onSecretVariablesChange(variables) {
    this.secretVariables = variables;
    this.setVariablesStep(variables);
  }

  savePackage() {
    if (this.packageStep.valid && this.packageVariablesStep.valid && this.packageVersionStep.valid) {
      this.item.overrideVariables = this.overridePackageVariables;
      if (this.item.overrideVariables) {
        this.item.defaultVariables = this.item.variables;
        this.item.variables = this.variables;
        this.item.secret_variables = this.secretVariables;
      }
      this.item.paper_trail_package_version = Number(this.paper_trail_package_version);
      this.item.package_version = this.paper_trail_package_version_object
        ? this.paper_trail_package_version_object.package_version
        : 'Latest version';

      this.store
        .select(selectIsEditingPackage)
        .pipe(first())
        .subscribe((isEditingPackage) => {
          if (isEditingPackage) {
            this.packageEdited.emit(this.item);
          } else {
            this.packageAdded.emit(this.item);
          }

          this.store.dispatch(closePackagePickerModal());
        });
    }
  }

  cancel() {
    this.store.dispatch(closePackagePickerModal());
  }
}
