import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Schema, WindowComponentData } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';

@Component({
  selector: 'window-editor',
  template: `
    <div class="window-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::WindowComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'window-editor.form.labels.group_by' | translate }}</strong></label
              >
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.grouping_type"
                    name="group-by"
                    id="group-all"
                    value="all"
                    (ngModelChange)="onGroupingTypeChange($event)"
                  />
                  <label for="group-all">{{ 'window-editor.form.radiolist.group_by.group_all' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.grouping_type"
                    name="group-by"
                    id="group-by"
                    value="group_by"
                    (ngModelChange)="onGroupingTypeChange($event)"
                  />
                  <label for="group-by">{{ 'window-editor.form.radiolist.group_by.group_by' | translate }}</label>
                </div>
              </div>
              <div [hidden]="rawComponent.grouping_type !== 'group_by'">
                <group-by-collection
                  [records]="rawComponent.partitioned_fields"
                  (recordsChange)="onPartitionedFieldsChange($event)"
                  [parentSchemas]="parentSchemas"
                  fieldsName="partitioned_fields"
                  [isVisible]="rawComponent.grouping_type === 'group_by'"
                  (validityChange)="onPartitionedFieldsValidityChange($event)"
                ></group-by-collection>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'window-editor.form.labels.data_order' | translate }}</strong></label
              >
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.data_order"
                    name="data_order"
                    id="data-order-no-sort"
                    value="no_sort"
                    (ngModelChange)="onDataOrderChange($event)"
                  />
                  <label for="data-order-no-sort">{{
                    'window-editor.form.radiolist.data_order.no_sort' | translate
                  }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.data_order"
                    name="data_order"
                    id="data-order-sort"
                    value="sort"
                    (ngModelChange)="onDataOrderChange($event)"
                  />
                  <label for="data-order-sort">{{ 'window-editor.form.radiolist.data_order.sort' | translate }}</label>
                </div>
              </div>
              <div *ngIf="rawComponent.data_order === 'sort'">
                <sort-collection
                  [records]="rawComponent.ordered_fields"
                  [parentSchemas]="parentSchemas"
                  fieldsName="ordered_fields"
                  [isVisible]="rawComponent.data_order === 'sort'"
                  (recordsChange)="onOrderedFieldsChange($event)"
                  (validityChange)="onSortCollectionValidityChange($event)"
                ></sort-collection>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'window-editor.form.labels.windowed_fields' | translate }}</strong></label
              >
              <window-collection
                [records]="rawComponent.windowed_fields || []"
                [parentSchemas]="parentSchemas"
                fieldsName="windowed_fields"
                (recordsChange)="onWindowedFieldsChange($event)"
                (validityChange)="onWindowedFieldsValidityChange($event)"
              ></window-collection>
            </div>
          </div>
          <div class="row">
            <component-previewer [componentId]="rawComponent.id"></component-previewer>
          </div>
        </form>
      </xp-form-validation>
    </div>
  `,
})
export class WindowEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: WindowComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  sortCollectionValid = false;
  groupByCollectionValidity = false;
  windowCollectionValidity = false;
  isFormValid = false;
  validationChangeSubscription: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });

    if (this.rawComponent.partitioned_fields?.length === 0) {
      this.onGroupingTypeChange('all');
    }

    if (this.rawComponent.ordered_fields?.length === 0) {
      this.onDataOrderChange('no_sort');
    }

    if (!this.rawComponent.grouping_type) {
      this.onGroupingTypeChange(this.rawComponent.partitioned_fields?.length > 0 ? 'group_by' : 'all');
    }

    if (!this.rawComponent.data_order) {
      this.onDataOrderChange(this.rawComponent.ordered_fields?.length > 0 ? 'sort' : 'no_sort');
    }
  }

  onValidityChange() {
    const isValid =
      !(this.rawComponent.grouping_type === 'group_by' && !this.groupByCollectionValidity) &&
      !(this.rawComponent.data_order === 'sort' && !this.sortCollectionValid) &&
      this.windowCollectionValidity &&
      this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onGroupingTypeChange(grouping_type: string) {
    const component: any = { grouping_type };

    if (component.grouping_type === 'all') {
      component.ordered_fields = [];
    }
    this.store.dispatch(updateComponent({ component }));
    this.store.dispatch(updateRawComponent({ rawComponent: { ...component } }));
  }

  onPartitionedFieldsChange(partitioned_fields) {
    this.store.dispatch(updateRawComponent({ rawComponent: { partitioned_fields } }));
    this.store.dispatch(updateComponent({ component: { partitioned_fields } }));
  }

  onSortCollectionValidityChange(value) {
    this.sortCollectionValid = value;

    this.onValidityChange();
  }

  onOrderedFieldsChange(ordered_fields) {
    this.store.dispatch(updateRawComponent({ rawComponent: { ordered_fields } }));
    this.store.dispatch(updateComponent({ component: { ordered_fields } }));
  }

  onWindowedFieldsChange(windowed_fields) {
    this.store.dispatch(updateRawComponent({ rawComponent: { windowed_fields } }));
    this.store.dispatch(updateComponent({ component: { windowed_fields } }));
  }

  onWindowedFieldsValidityChange(value) {
    this.windowCollectionValidity = value;

    this.onValidityChange();
  }

  onPartitionedFieldsValidityChange(value) {
    this.groupByCollectionValidity = value;

    this.onValidityChange();
  }

  onDataOrderChange(data_order: string) {
    const component: any = { data_order };

    if (component.data_order === 'no_sort') {
      component.ordered_fields = [];
    }
    this.store.dispatch(updateComponent({ component }));
    this.store.dispatch(updateRawComponent({ rawComponent: { ...component } }));

    this.onValidityChange();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
