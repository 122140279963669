import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../store';
import { SettingsState } from '../settings.state';

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectAccounts = createSelector(selectSettingsState, (state: SettingsState) => state.accounts.items);
export const selectAccountsErrors = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.errors,
);
export const selectAccountsLoadingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.isLoading,
);
export const selectAccountsErrorFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.isResponseError,
);
export const selectAccountsIsSubmittingFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.isFormSubmitting,
);
export const selectAccountsIsModalOpenFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.isModalOpen,
);

export const selectLastlyCreatedAccount = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.lastlyCreatedAccount,
);

export const selectAreAllAccountsLoadedFlag = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.accounts.areAllItemsLoaded,
);

export const selectIsAccountRemoved = (id: string) =>
  createSelector(
    selectSettingsState,
    (state: SettingsState) => !state.accounts.items.find((item) => item.account_id === id),
  );
