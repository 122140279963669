import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsersResource } from '../resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import { PASSWORD_PATTERN } from '../../config/password-policy';

@Component({
  selector: 'reset-password',
  template: `
    <div class="container-fluid">
      <div class="panel">
        <div class="panel-header">
          <div class="panel-logo">
            <img class="logo-img" width="80" height="80" src="assets/img/xplenty-logo.svg" alt="Prepforce" />
          </div>
          <h3 class="panel-title">{{ 'auth.reset-password.title' | translate }}</h3>
        </div>
        <!-- <hr> -->
        <div class="panel-body">
          <xp-form-validation type="User">
            <form role="form" name="resetPasswordForm" #form="ngForm">
              <fieldset>
                <xp-form-group>
                  <label>{{ 'auth.reset-password.labels.pass' | translate }}</label>
                  <input
                    class="form-control"
                    [(ngModel)]="password"
                    [placeholder]="'auth.sign_in.form.placeholders.password' | translate"
                    name="password"
                    type="password"
                    autocomplete="off"
                    [pattern]="passwordPattern"
                  />
                </xp-form-group>
                <xp-password-strength [password]="password"></xp-password-strength>
              </fieldset>
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block"
                [ngClass]="{ disabled: !form.valid }"
                (click)="submit()"
              >
                {{ 'auth.reset-password.actions.reset' | translate }}
              </button>
            </form>
          </xp-form-validation>
        </div>
      </div>
    </div>
    <help-menu></help-menu>
  `,
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  password = '';
  isLoading = false;
  passwordPattern = PASSWORD_PATTERN;

  constructor(
    private usersResource: UsersResource,
    private notify: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (!this.route.snapshot.queryParams.reset_password_token) {
      this.router.navigate(['/auth/login']);
    }
  }

  submit() {
    this.isLoading = true;
    this.usersResource
      .reset_password({
        password: this.password,
        reset_password_token: this.route.snapshot.queryParams.reset_password_token,
      })
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.notify.success('Password successfully changed! Redirecting to log in');
          setTimeout(() => this.router.navigate(['/auth/login']), 3000);
        },
        error: ({ error }) => {
          this.isLoading = false;
          const { errors } = error;
          if (errors && errors[0] && errors[0].field === 'reset_password_token') {
            this.notify.error(`Token ${errors[0].message}`);
          }
        },
      });
  }
}
