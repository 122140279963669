import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentTypeItem } from '../../../constants/component_types';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { DatabaseDefinitionComponentData } from './database-definition.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  selector: 'database-schema-input',
  template: `
    <xp-form-group class="col-md-6" *ngIf="hasSchema && hasSchemaByAccessMode">
      <label for="schema_name">{{
        'database-definition.form.' + component.componentType + '.labels.schema_name' | translate
      }}</label>
      <xp-input
        type="text"
        class="form-control"
        id="schema_name"
        name="schema_name"
        [ngModel]="rawComponent.schema_name"
        [autoCompleteOptions]="schemaNameOptions"
        [autoCompleteDescription]="schemaNameOptions.length ? 'Existing schemas on the database' : ''"
        (autocompleteReachedEnd)="loadMoreSchemas()"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        [disableSearch]="true"
        [isLazyLoading]="isLazyLoading"
        (ngModelChange)="onValueChange($event)"
        placeholder="{{
          'database-definition.form.' +
            component.componentType +
            '.placeholders.' +
            (hasTableSchemaSelect ? 'schema_name_rich' : 'schema_name') | translate
        }}"
      ></xp-input>
      <i
        class="fa fa-exclamation-circle form-control-info"
        matTooltip="Leave empty to use the user’s default schema."
        matTooltipPosition="right"
        matTooltipClass="right"
      ></i>
      <div *ngIf="schemasErrorMessage" class="error-message">
        {{ schemasErrorMessage }}
      </div>
    </xp-form-group>
  `,
})
export class DatabaseSchemaInputComponent implements OnInit, OnChanges {
  @Input() rawComponent: DatabaseDefinitionComponentData;
  @Input() component: ComponentTypeItem;
  @Input() hasSchemaByAccessMode = false;
  @Input() hasSchema = true;
  @Input() hasTableSchemaSelect;
  schemaNameOptions = [];
  isLoadingSchemas = false;
  schemasErrorMessage = '';
  isLazyLoading = false;
  areAllItemsLoaded = false;
  limit = 100;
  offset = 0;
  search = '';
  schemaInputSubject: Subject<{ value: string; isInputFocused: boolean }> = new Subject();
  isInputFocused = false;
  lastCallWithSearch = false;

  constructor(
    private store: Store<AppState>,
    private connectionItemsResource: ConnectionItemsResource,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.hasSchema || !this.hasSchemaByAccessMode) {
      return;
    }

    const hasConnectionChanged =
      changes.rawComponent?.currentValue?.connection?.id !== changes.rawComponent?.previousValue?.connection?.id &&
      !changes.rawComponent?.firstChange;
    const hasFlagChanged =
      changes.hasTableSchemaSelect?.currentValue &&
      !changes.hasTableSchemaSelect?.previousValue &&
      !changes.hasTableSchemaSelect?.firstChange;

    if (
      (hasConnectionChanged && this.hasTableSchemaSelect && this.rawComponent?.connection?.id) ||
      (hasFlagChanged && this.rawComponent?.connection?.id)
    ) {
      this.offset = 0;
      this.schemaNameOptions = [];
      this.loadSchemas();
    }

    if ((hasFlagChanged || hasConnectionChanged) && !this.hasTableSchemaSelect) {
      this.schemaNameOptions = [];
    }
  }

  ngOnInit() {
    if (!this.hasSchema || !this.hasSchemaByAccessMode) {
      return;
    }

    if (this.rawComponent?.connection?.id) {
      this.loadSchemas();
    }

    this.schemaInputSubject.pipe(debounceTime(300)).subscribe(({ value, isInputFocused }) => {
      this.store.dispatch(
        updateRawComponent({
          rawComponent: { schema_name: value },
        }),
      );
      this.store.dispatch(updateComponent({ component: { schema_name: value } as any }));

      if (this.rawComponent?.connection?.id && isInputFocused) {
        this.search = value;
        this.offset = 0;
        this.loadSchemas();
      }
    });
  }

  loadSchemas() {
    if (!this.hasTableSchemaSelect) return;
    this.isLoadingSchemas = true;
    this.schemasErrorMessage = '';
    this.connectionItemsResource
      .databaseSchemas(this.rawComponent.connection.type, this.rawComponent.connection.id, {
        limit: this.limit,
        offset: this.offset,
        search: this.search,
      })
      .subscribe({
        next: (schemas) => {
          this.isLoadingSchemas = false;
          if (this.isLazyLoading) {
            this.isLazyLoading = false;
            this.schemaNameOptions = [...this.schemaNameOptions, ...schemas.data.map((table) => table[0])];
          } else {
            this.schemaNameOptions = schemas.data.map((table) => table[0]);
          }
          this.areAllItemsLoaded = schemas.data.length < this.limit;
          if (this.search) {
            this.lastCallWithSearch = true;
          }
        },
        error: ({ error }) => {
          this.isLoadingSchemas = false;
          this.schemasErrorMessage = `There was an issue while fetching schemas list: ${error?.error_message}`;
          this.isLazyLoading = false;
        },
      });
  }

  onValueChange(value: any) {
    if (value !== this.rawComponent.schema_name) {
      this.schemaInputSubject.next({ value, isInputFocused: this.isInputFocused });
    }
  }

  loadMoreSchemas() {
    if (this.areAllItemsLoaded) {
      return;
    }

    this.isLazyLoading = true;
    this.offset += this.limit;

    this.loadSchemas();
  }

  onInputBlur() {
    this.isInputFocused = false;
    this.search = '';
    this.offset = 0;
  }

  onInputFocus() {
    this.isInputFocused = true;
    if (!this.rawComponent?.connection?.id) {
      return;
    }

    if (this.schemaNameOptions.length < this.limit && !this.areAllItemsLoaded) {
      this.loadSchemas();
    }

    if (this.lastCallWithSearch) {
      this.loadSchemas();
      this.lastCallWithSearch = false;
    }
  }
}
