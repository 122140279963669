/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const TOKEN_ID = 'satellizer_access_token';

export interface Credentials {
  email: string;
  password?: string;
  mfa_code?: string;
  name?: string;
  company?: string;
}

interface LoginSuccessResponse {
  access_token: string;
  created_at: number;
  expires_in: number;
  token_type: string;
  mfa_required?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiRoot: string = environment.API_URL;
  storage = window.localStorage;
  accountIdValue = '';
  token = '';

  constructor(private http: HttpClient) {}

  set accountId(value: string) {
    this.accountIdValue = value;
  }

  get accountId(): string {
    return this.accountIdValue;
  }

  setToken(token: string) {
    if (this.storage) {
      this.storage.setItem(TOKEN_ID, token);
    } else {
      this.token = token;
    }
  }

  getToken(): string {
    if (this.storage) {
      return this.storage.getItem(TOKEN_ID);
    } else {
      return this.token;
    }
  }

  removeToken() {
    if (this.storage) {
      this.storage.removeItem(TOKEN_ID);
    } else {
      this.token = '';
    }
  }

  login(credentials: Credentials): Observable<LoginSuccessResponse> {
    return this.http
      .post<LoginSuccessResponse>(`${this.apiRoot}/oauth/token`, { ...credentials, grant_type: 'password' })
      .pipe(tap((response: LoginSuccessResponse) => this.setToken(response.access_token)));
  }

  logout() {
    const token = this.getToken();
    this.removeToken();
    return this.http.post<LoginSuccessResponse>(`${this.apiRoot}/oauth/revoke`, { token });
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    // A token is present
    if (token) {
      // Token with a valid JWT format XXX.YYY.ZZZ
      if (token.split('.').length === 3) {
        // Could be a valid JWT or an access token with the same format
        try {
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const { exp } = JSON.parse(window.atob(base64));
          // JWT with an optonal expiration claims
          if (exp) {
            const isExpired = Math.round(new Date().getTime() / 1000) >= exp;
            if (isExpired) {
              // FAIL: Expired token
              return false;
            }
            // PASS: Non-expired token
            return true;
          }
        } catch (e) {
          // PASS: Non-JWT token that looks like JWT
          return true;
        }
      }
      // PASS: All other tokens
      return true;
    }
    // FAIL: No token at all
    return false;
  }
}
