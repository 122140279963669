export enum ConditionOperator {
  teq = 'teq',
  tneq = 'tneq',
  matches = 'matches',
  tise = 'tise',
  tisne = 'tisne',
  eq = 'eq',
  neq = 'neq',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  isn = 'isn',
  isnn = 'isnn',
  ist = 'ist',
}

export enum ConditionOperatorHandler {
  text = 'text',
  none = 'none',
  expression = 'expression',
}

export const ONE_SIDED_CONDITION_OPERATORS = [
  ConditionOperator.tise,
  ConditionOperator.tisne,
  ConditionOperator.isn,
  ConditionOperator.isnn,
  ConditionOperator.ist,
];

export const CONDITION_OPERATOR = [
  {
    value: ConditionOperator.teq,
    translationPath: 'fields-collection.editors.condition-operator-picker.teq',
    handler: ConditionOperatorHandler.text,
  },
  {
    value: ConditionOperator.tneq,
    translationPath: 'fields-collection.editors.condition-operator-picker.tneq',
    handler: ConditionOperatorHandler.text,
  },
  {
    value: ConditionOperator.matches,
    translationPath: 'fields-collection.editors.condition-operator-picker.matches',
    handler: ConditionOperatorHandler.text,
  },
  {
    value: ConditionOperator.tise,
    translationPath: 'fields-collection.editors.condition-operator-picker.tise',
    handler: ConditionOperatorHandler.none,
  },
  {
    value: ConditionOperator.tisne,
    translationPath: 'fields-collection.editors.condition-operator-picker.tisne',
    handler: ConditionOperatorHandler.none,
  },
  {
    value: ConditionOperator.eq,
    translationPath: 'fields-collection.editors.condition-operator-picker.eq',
    handler: ConditionOperatorHandler.expression,
  },
  {
    value: ConditionOperator.neq,
    translationPath: 'fields-collection.editors.condition-operator-picker.neq',
    handler: ConditionOperatorHandler.expression,
  },
  {
    value: ConditionOperator.gt,
    translationPath: 'fields-collection.editors.condition-operator-picker.gt',
    handler: ConditionOperatorHandler.expression,
  },
  {
    value: ConditionOperator.gte,
    translationPath: 'fields-collection.editors.condition-operator-picker.gte',
    handler: ConditionOperatorHandler.expression,
  },
  {
    value: ConditionOperator.lt,
    translationPath: 'fields-collection.editors.condition-operator-picker.lt',
    handler: ConditionOperatorHandler.expression,
  },
  {
    value: ConditionOperator.lte,
    translationPath: 'fields-collection.editors.condition-operator-picker.lte',
    handler: ConditionOperatorHandler.expression,
  },
  {
    value: ConditionOperator.isn,
    translationPath: 'fields-collection.editors.condition-operator-picker.isn',
    handler: ConditionOperatorHandler.none,
  },
  {
    value: ConditionOperator.isnn,
    translationPath: 'fields-collection.editors.condition-operator-picker.isnn',
    handler: ConditionOperatorHandler.none,
  },
  {
    value: ConditionOperator.ist,
    translationPath: 'fields-collection.editors.condition-operator-picker.ist',
    handler: ConditionOperatorHandler.none,
  },
];
