import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Timezone } from '../timezone.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class TimezonesResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(private http: HttpClient) {}

  query(params?: ListParams): Observable<Timezone[]> {
    return this.http.get<Timezone[]>(`${this.apiRoot}/timezones`, { params: params as QueryParams });
  }
}
