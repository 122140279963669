import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NetsuiteDestinationConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { DUMMY_PASSWORD } from './connection-component-username-password.component';

@Component({
  selector: 'connection-form-nsuite',
  template: `
    <xp-form-validation type="NetsuiteDestinationConnection" [name]="formName">
      <form id="connectionFormNetsuiteDestination" name="connectionFormNetsuiteDestination" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.netsuite.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.netsuite.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <div class="form-group">
              <label for="consumer_key">{{ 'connections.form.netsuite.labels.consumer_key' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="consumer_key"
                id="consumer_key"
                [(ngModel)]="connection.consumer_key"
                placeholder=""
              ></xp-input>
            </div>
            <div class="form-group">
              <label for="token_id">{{ 'connections.form.netsuite.labels.token_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="token_id"
                id="token_id"
                [(ngModel)]="connection.token_id"
                placeholder=""
              ></xp-input>
            </div>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="net_suite_account_id">{{ 'connections.form.netsuite.labels.account_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="net_suite_account_id"
                id="net_suite_account_id"
                [(ngModel)]="connection.net_suite_account_id"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group">
              <label for="consumer_secret"
                >{{ 'connections.form.netsuite.labels.consumer_secret' | translate }}
                <span *ngIf="consumerSecretChangeable">
                  (<a (click)="changeConsumerSecret()"
                    ><span *ngIf="consumerSecretDisabled">{{ 'connections.form.labels.change' | translate }}</span
                    ><span *ngIf="!consumerSecretDisabled">{{ 'connections.form.labels.cancel' | translate }}</span></a
                  >)
                </span>
              </label>
              <xp-input
                type="password"
                class="form-control"
                name="consumer_secret"
                id="consumer_secret"
                [(ngModel)]="connection.consumer_secret"
                [attr.disabled]="consumerSecretDisabled"
                [disable]="consumerSecretDisabled"
              ></xp-input>
            </div>
            <div class="form-group">
              <label for="token_secret"
                >{{ 'connections.form.netsuite.labels.token_secret' | translate }}
                <span *ngIf="tokenSecretChangeable">
                  (<a (click)="changeTokenSecret()"
                    ><span *ngIf="tokenSecretDisabled">{{ 'connections.form.labels.change' | translate }}</span
                    ><span *ngIf="!tokenSecretDisabled">{{ 'connections.form.labels.cancel' | translate }}</span></a
                  >)
                </span>
              </label>
              <xp-input
                type="password"
                class="form-control"
                name="token_secret"
                id="token_secret"
                [(ngModel)]="connection.token_secret"
                [attr.disabled]="tokenSecretDisabled"
                [disable]="tokenSecretDisabled"
              ></xp-input>
            </div>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormNetsuiteDestinationComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<NetsuiteDestinationConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormNetsuiteDestination';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  consumerSecretChangeable = false;
  consumerSecretDisabled = true;
  tokenSecretChangeable = false;
  tokenSecretDisabled = true;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.consumerSecretChangeable = !!this.connection.id;
    this.consumerSecretDisabled = this.consumerSecretChangeable;

    if (this.consumerSecretDisabled) {
      this.connection.consumer_secret = DUMMY_PASSWORD;
    }

    this.tokenSecretChangeable = !!this.connection.id;
    this.tokenSecretDisabled = this.tokenSecretChangeable;

    if (this.tokenSecretDisabled) {
      this.connection.token_secret = DUMMY_PASSWORD;
    }
  }

  changeConsumerSecret(): void {
    this.consumerSecretDisabled = !this.consumerSecretDisabled;

    if (this.consumerSecretDisabled) {
      this.connection.consumer_secret = DUMMY_PASSWORD;
    } else {
      this.connection.consumer_secret = '';
    }
  }

  changeTokenSecret(): void {
    this.tokenSecretDisabled = !this.tokenSecretDisabled;

    if (this.tokenSecretDisabled) {
      this.connection.token_secret = DUMMY_PASSWORD;
    } else {
      this.connection.token_secret = '';
    }
  }
}
