import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Package } from '../../../../packages/package.models';

@Component({
  selector: 'xp-select-picker-package-version',
  template: `
    <div class="select-picker-package">
      <div class="select-picker-item">
        <div class="select-picker-header">
          <div class="select-picker-name">
            <div class="select-picker-name-title">
              <span [innerHTML]="packageVersion.name | xpLengthCheck : 50 | xpHighLight : highlightValue"></span>
              <button type="button" class="btn btn-sm btn-black" (click)="openPackageVersion($event, packageVersion)">
                Open <i class="fa fa-external-link"></i>
              </button>
            </div>
            <span
              class="select-picker-name-description"
              [innerHTML]="packageVersion.description | xpLengthCheck : 120 | xpHighLight : highlightValue"
            ></span>
          </div>
        </div>
        <div class="select-picker-body">
          <span class="small version">Version: {{ packageVersion.package_version }}</span>
          <span class="small">Author: {{ packageVersion.author }}</span>
          <span class="small"
            >Created:
            <span class="date-tooltip" [matTooltip]="packageVersion.updated_at | xpDateUTC">{{
              packageVersion.updated_at | xpPrettyDate
            }}</span></span
          >
          <span class="small" *ngIf="packageDescription !== null">{{ packageDescription }}</span>
        </div>
      </div>
    </div>
  `,
})
export class XpSelectPickerPackageVersionComponent {
  @Input() packageVersion: Package;
  @Input() highlightValue: string = '';

  constructor(private route: ActivatedRoute) {}

  get packageDescription(): string {
    return this.packageVersion.version_description ? `Description: ${this.packageVersion.version_description}` : null;
  }

  openPackageVersion(event: MouseEvent, packageVersion: Package) {
    window.open(
      `/${this.route.parent.snapshot.params.account_id}/packages/${packageVersion.item_id}/versions/${packageVersion.version}`,
    );
    event.stopPropagation();
  }
}
