import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../common/services/permissions.service';
import { Account } from '../../../account/account.models';
import { NotifyService } from '../../../common/services/notify.service';
import { ConfirmationDialogService } from '../../../common/services/confirmation-dialog.service';
import { AppState } from '../../../store';
import { selectAccountId, selectUserId } from '../../../account/store/account.selectors';
import { ItemType } from '../../../common/components/lists/list-item-snippet.component';
import { removeMember } from '../../store/members/members.actions';
import { removeAccountFromList } from '../../../account/store/account.actions';

@Component({
  selector: 'account-list-item',
  template: `
    <div class="generic-list-item slider-animation" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="account-list-item slider-animation">
        <div class="account-img" ui-sref="app.account.jobs(item)">
          <img class="media-object img-circle" [src]="item.avatar_url" [alt]="item.name" />
        </div>
        <div class="account-body">
          <div class="account-item-name">
            <a (click)="goToAccountJobs(item)">
              <strong>{{ item.name }}</strong>
            </a>
          </div>
          <p class="account-info">
            <span class="account-region">{{ 'regions.' + item.region + '.text' | translate }}</span
            ><span class="account-role left-separator">{{
              'roles.' + (item.is_owner ? 'owner' : item.role) + '.name' | translate
            }}</span>
          </p>
        </div>
        <div class="dropdown xp-dropdown">
          <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
        </div>
        <mat-menu #dropdown="matMenu">
          <li mat-menu-item *ngIf="hasPermission('listJobs')" (click)="goToAccountJobs(item)">
            <span class="list-item-multiple" [innerHTML]="'sidebar.sub-menu.items.switch' | translate: item"></span>
          </li>
          <li mat-menu-item *ngIf="hasPermission('viewProfile')" class="top-separator" (click)="goToAccountInfo(item)">
            <span class="">{{ 'sidebar.sub-menu.items.information' | translate }}</span>
          </li>
          <li mat-menu-item *ngIf="hasPermission('viewBillingInfo')" (click)="goToAccountPlans(item)">
            <span class="">{{ 'sidebar.sub-menu.items.billing' | translate }}</span>
          </li>
          <li mat-menu-item *ngIf="hasPermission('viewUsage')" (click)="goToAccountUsage(item)">
            <span class="">{{ 'sidebar.sub-menu.items.usage' | translate }}</span>
          </li>
          <li mat-menu-item *ngIf="hasPermission('listHooks')" (click)="goToAccountHooks(item)">
            <span class="">{{ 'sidebar.sub-menu.items.hooks' | translate }}</span>
          </li>
          <li mat-menu-item *ngIf="hasPermission('listMembers')" (click)="goToAccountMembers(item)">
            <span>{{ 'sidebar.sub-menu.items.members' | translate }}</span>
          </li>
          <li
            mat-menu-item
            class="top-separator"
            *ngIf="hasPermission('deleteMember') && !item.is_owner"
            (click)="onLeave(item)"
          >
            <span
              class="text-warning list-item-multiple"
              [innerHTML]="'sidebar.sub-menu.items.leave' | translate: item"
            ></span>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class AccountListItemComponent {
  @Input() item: Account;
  userId: number;
  accountId: string;

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private notify: NotifyService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.store.pipe(selectUserId).subscribe((userId: number) => {
      this.userId = userId;
    });

    this.store.pipe(selectAccountId).subscribe((accountId: string) => {
      this.accountId = accountId;
    });
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  goToAccountJobs(item: Account): void {
    this.router.navigate([`/${item.account_id}/jobs`]);
  }

  goToAccountInfo(item: Account): void {
    this.router.navigate([`/${item.account_id}/settings/account/profile`]);
  }

  goToAccountPlans(item: Account): void {
    this.router.navigate([`/${item.account_id}/settings/account/billing`]);
  }

  goToAccountUsage(item: Account): void {
    this.router.navigate([`/${item.account_id}/settings/account/usage`]);
  }

  goToAccountHooks(item: Account): void {
    this.router.navigate([`/${item.account_id}/settings/account/hooks`]);
  }

  goToAccountMembers(item: Account): void {
    this.router.navigate([`/${item.account_id}/settings/account/members`]);
  }

  onLeave(item: Account): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('member.generic-list.item.modals.leave.confirm.title'),
      hint: this.translate.instant('member.generic-list.item.modals.leave.confirm.hint'),
      yes: this.translate.instant('member.generic-list.item.modals.leave.confirm.yes'),
      no: this.translate.instant('member.generic-list.item.modals.leave.confirm.no'),
      item,
      itemType: ItemType.account,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeMember({ memberId: this.userId, isLogout: false }));
        if (this.accountId === item.account_id) {
          this.store.dispatch(removeAccountFromList({ account: item }));
          this.router.navigate([`/launchpad`]);
        }
      }
    });
  }
}
