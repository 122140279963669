import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { forIn } from 'lodash';
import { CloudStorageDestinationComponentData } from '../../package.models';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';

@Component({
  selector: 'file-names-pattern',
  template: `
    <div class="file-names-pattern">
      <div class="form-group">
        <label for="compression_type" class="label-with-hint"
          >{{ 'file-names-pattern.label.type' | translate }}
          <i
            class="fa fa-exclamation-circle label-with-hint-icon"
            [matTooltip]="'cloud-storage-destination-editor.hints.file_patterns' | translate: { '5': '{5}' }"
            matTooltipPosition="right"
          ></i>
        </label>
        <div class="btn-group btn-group-md btn-group-select">
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ 'active btn-primary': rawComponent.file_names === 'default' }"
            (click)="onValueChange('default', 'file_names')"
          >
            {{ 'file-names-pattern.select.option.default' | translate }}
          </button>
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ 'active btn-primary': rawComponent.file_names === 'custom' }"
            (click)="onValueChange('custom', 'file_names')"
          >
            {{ 'file-names-pattern.select.option.custom' | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="rawComponent.file_names === 'custom'">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="file-name-prefix">{{ 'file-names-pattern.label.prefix' | translate }}</label>
              <xp-input
                [ngModel]="rawComponent.prefix"
                type="text"
                class="form-control input-sm"
                id="file-name-prefix"
                name="prefix"
                [placeholder]="'file-names-pattern.placeholder.prefix' | translate"
                (ngModelChange)="onValueChange($event, 'prefix')"
              ></xp-input>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="file-name-suffix">{{ 'file-names-pattern.label.suffix' | translate }}</label>
              <xp-input
                [ngModel]="rawComponent.suffix"
                type="text"
                class="form-control input-sm"
                id="file-name-suffix"
                name="suffix"
                [placeholder]="'file-names-pattern.placeholder.suffix' | translate"
                (ngModelChange)="onValueChange($event, 'suffix')"
              ></xp-input>
            </div>
          </div>
          <div class="col-md-12">
            <div class="well">
              <div *ngFor="let fileName of fileNames" class="small file-name" [attr.title]="fileName">
                <i class="fa fa-file"></i> <span>{{ fileName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class FileNamesPatternComponent implements OnInit, OnChanges {
  @Input() rawComponent: CloudStorageDestinationComponentData;

  fileNames = [];
  fileTypes = {
    json_destination_type: '.json',
    parquet_destination_type: '.parquet',
    csv_destination_type: '.csv',
  };
  compressionTypes = {
    none: '',
    gzip: '.gz',
    bz2: '.bz2',
  };
  lastSuffix = '';
  timeoutId = null;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.lastSuffix = this.rawComponent.suffix;
  }

  ngOnChanges(changes: SimpleChanges) {
    const newComponent = changes.rawComponent.currentValue;
    const oldComponent = changes.rawComponent.previousValue;

    const typeKeys = Object.keys(newComponent.destination_type);

    if (typeKeys.length === 1 && this.fileTypes[typeKeys[0]]) {
      const isCustomSuffix = (() => {
        let isCustomSuffixValue = true;
        forIn(this.fileTypes, (value, key) => {
          if (oldComponent && value + this.compressionTypes[oldComponent.compression_type] === newComponent.suffix)
            isCustomSuffixValue = false;
        });
        return isCustomSuffixValue;
      })();

      if (!isCustomSuffix || this.lastSuffix === null) {
        let newSuffix = this.fileTypes[typeKeys[0]];
        if (this.compressionTypes[newComponent.compression_type]) {
          newSuffix += this.compressionTypes[newComponent.compression_type];
        }

        this.onValueChange(newSuffix, 'suffix');
      }
      this.lastSuffix = newComponent.suffix;
    }

    const PREVIEW_FILES_COUNT = newComponent && newComponent.enforce_single_file ? 1 : 3;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.fileNames = [];
      for (let i = 0; i < PREVIEW_FILES_COUNT; i += 1) {
        this.fileNames.push(`${newComponent.prefix || 'part-m-'}0000${i}${newComponent.suffix}`);
      }
    }, 100);
  }

  onValueChange(value: any, key: string) {
    if (this.rawComponent[key] === value) {
      return;
    }
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }
}
