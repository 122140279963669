import { Owner } from '../connections/connection.models';
import { Schedule } from '../schedules/schedule.models';
import { IdleTimeValues } from './services/idle-times.service';

export interface Creator {
  display_name: string;
  html_url: string;
  id: number;
  type: string;
  url: string;
}

export enum ClusterStatuses {
  pending_terminate = 'pending_terminate',
  terminated = 'terminated',
  pending = 'pending',
  creating = 'creating',
  available = 'available',
  terminating = 'terminating',
  idle = 'idle',
  error = 'error',
}

export const CLUSTERS_VISIBLE_STATUSES = [
  ClusterStatuses.available,
  ClusterStatuses.creating,
  ClusterStatuses.pending,
  ClusterStatuses.pending_terminate,
  ClusterStatuses.terminating,
  ClusterStatuses.idle,
  ClusterStatuses.error,
];

export enum ClusterTypes {
  production = 'production',
  sandbox = 'sandbox',
}

export interface Cluster {
  allow_fallback: boolean;
  available_since?: string;
  created_at: string;
  creator: Creator | Schedule;
  description?: string;
  html_url: string;
  id: number;
  idle_since?: string;
  launched_at?: string;
  master_instance_type?: string;
  master_spot_percentage?: string;
  master_spot_price?: string;
  name: string;
  nodes: number;
  owner_id: number;
  plan_id?: string;
  region: string;
  running_jobs_count: number;
  queued_jobs_count: number;
  slave_instance_type?: string;
  slave_spot_percentage?: string;
  slave_spot_price?: string;
  stack: string;
  status: ClusterStatuses;
  terminate_on_idle: boolean;
  terminated_at?: string;
  terminated_on_idle: boolean;
  time_to_idle: IdleTimeValues;
  type: ClusterTypes;
  updated_at: string;
  url: string;
  zone?: string;
  owner: Owner;
  isRemoved?: boolean;
  reuse_cluster_strategy?: string;
}
