import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

function formatDate(value: string): string {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  const momentDate = moment(value).utcOffset(window['DEFAULT_TIMEZONE']);
  const year = momentDate.format('YYYY');
  const day = momentDate.format('MMM Do');
  const time = momentDate.format('HH:mm');
  const isCurrentYear = year === moment(Date.now()).format('YYYY');

  return `${day}${isCurrentYear ? '' : `, ${year}`} at ${time}`;
}

@Pipe({ name: 'xpDateWithTime' })
export class XpDateWithTimePipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): string {
    return value ? formatDate(value) : '';
  }
}
