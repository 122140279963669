import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsersResource } from '../resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';

@Component({
  selector: 'email-confirmation',
  template: `
    <div class="auth-container">
      <div class="panel">
        <div class="panel-header">
          <div class="panel-logo">
            <img class="logo-img" width="80" height="80" src="assets/img/xplenty-logo.svg" alt="Prepforce" />
          </div>
          <h3 class="panel-title">Email change confirmation</h3>
        </div>
        <div class="panel-body members-invitation email-confirmation">
          <xp-form-validation>
            <form name="confirmationForm" novalidate autocomplete="off" #form="ngForm">
              <fieldset>
                <xp-form-group>
                  <label>{{ 'auth.confirmation.form.labels.password' | translate }}</label>
                  <input
                    class="form-control"
                    [(ngModel)]="password"
                    [placeholder]="'auth.confirmation.form.placeholders.password' | translate"
                    name="password"
                    type="password"
                    autocomplete="off"
                  />
                </xp-form-group>
                <small>Enter your password to confirm your new email address.</small>
              </fieldset>
              <xp-submit-button
                updateText="Confirm Email Change"
                [isFormValid]="!!password"
                [isFormSubmitting]="isFormSubmitting"
                classNames="btn btn-primary btn-lg btn-block"
                (submitForm)="submit()"
              ></xp-submit-button>
            </form>
          </xp-form-validation>
        </div>
      </div>
      <help-menu></help-menu>
    </div>
  `,
})
export class EmailConfirmationComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  password = '';
  isFormSubmitting = false;

  constructor(
    private usersResource: UsersResource,
    private notify: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (!this.route.snapshot.params.token) {
      this.router.navigate(['/auth/login']);
    }
  }

  submit() {
    this.isFormSubmitting = true;
    this.usersResource
      .newEmailConfirmation({
        password: this.password,
        token: this.route.snapshot.params.token,
      })
      .subscribe({
        next: () => {
          this.notify.success('Email changed successfully!');
          this.router.navigate(['/launchpad']);
          this.isFormSubmitting = false;
        },
        error: ({ error }) => {
          let message = 'There was an error while making a request. Try again or contact our support team.';
          if (error.message) {
            if (typeof error.message === 'object' && error.message.length) {
              // eslint-disable-next-line prefer-destructuring
              message = error.message[0];
            }

            if (typeof error.message === 'string') {
              message = error.message;
            }
          }
          this.notify.error(message);
          this.isFormSubmitting = false;
        },
      });
  }
}
