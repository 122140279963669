import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { AllComponentData } from '../package.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

interface QueryParams extends HttpParams {}

@Injectable({
  providedIn: 'root',
})
export class PackagesResource {
  private apiRoot: string = environment.API_URL;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  preview(package_id: number, body: any, params?: ListParams): Observable<AllComponentData> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<AllComponentData>(`${this.apiRoot}/${account_id}/api/packages/${package_id}/previews`, body, {
          params: params as QueryParams,
          headers: { ignoreLoadingBar: '' },
        }),
      ),
    );
  }

  previewResult(package_id: number, preview_id: number, params?: ListParams): Observable<AllComponentData> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<AllComponentData>(
          `${this.apiRoot}/${account_id}/api/packages/${package_id}/previews/${preview_id}/result`,
          {
            params: params as QueryParams,
            headers: { ignoreLoadingBar: '' },
          },
        ),
      ),
    );
  }
}
