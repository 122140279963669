/* eslint-disable no-underscore-dangle */
import { AssistedSearchDateSource } from './assisted-search-date-source.helper';
import { AssistedSearchListSource } from './assisted-search-list-source.helper';
import { AssistedSearchThumbsListSource } from './assisted-search-thumb-list-source.helper';

export const AssistedSearchToken = function AssistedSearchToken(params) {
  this.modifier = '';
  this.value = '';
  this.configHash = {};

  if (params) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in params) {
      this[key] = params[key];
    }
  }

  this.autoComplete = function autoComplete() {
    const hint = this.firstHint;
    const { subHint } = this;
    if (hint && subHint) {
      const hintValue = typeof hint === 'string' ? hint : hint.value;
      if (hint.modifier) {
        this.fullText = hintValue;
      } else {
        this.value = hintValue;
      }
      return true;
    }
    return false;
  }.bind(this);
};

Object.defineProperties(AssistedSearchToken.prototype, {
  config: {
    get() {
      if (this.modifier) {
        return this.configHash[this.modifier];
      }
      if (this.value && this.value !== ' ') {
        return this.configHash._default;
      }

      return null;
    },
  },
  type: {
    get() {
      return (this.config && (this.config || {}).type) || 'space';
    },
  },
  sectionTitle: {
    get() {
      return this.config && (this.config || {}).sectionTitle;
    },
  },
  content: {
    get() {
      return this.config && (this.config || {}).content;
    },
  },
  adapter: {
    get() {
      const { type } = this;
      if (type === 'list' || type === 'modifier-list') {
        return AssistedSearchListSource;
      }
      if (type === 'thumbs-list') {
        return AssistedSearchThumbsListSource;
      }
      if (type === 'date') {
        return AssistedSearchDateSource;
      }
      return AssistedSearchListSource;
    },
  },

  fullText: {
    get() {
      return this.modifier + this.value;
    },
    set(val) {
      const configs = this.configHash;
      if (configs) {
        let modifier;
        if (val.substring(0, 1) === '+') {
          modifier = '+';
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const k in configs) {
            if (val.substring(0, k.length) === k) {
              modifier = k;
              break;
            }
          }
        }
        if (modifier) {
          const value = val.substring(modifier.length);
          this.modifier = modifier;
          this.value = value;
        } else if (val) {
          this.value = val;
        }
      }
      return val;
    },
  },

  length: {
    get() {
      return this.fullText.length;
    },
  },

  firstHint: {
    get() {
      return this.hints[0];
    },
  },

  subHint: {
    get() {
      const hint = typeof this.firstHint === 'string' ? this.firstHint : this.adapter.serialize(this.firstHint);
      const valueLength = this.value.length;
      if (valueLength && hint && hint.indexOf(this.value) === 0) {
        return hint.substring(valueLength);
      }
      return null;
    },
  },

  hint: {
    get() {
      return this.value.length ? this.subHint : (this.config || {}).defaultHint;
    },
  },

  hints: {
    get() {
      return this.adapter.getHints(this.value, this.content) || [];
    },
  },

  model: {
    set(newModel) {
      const val = this.adapter.serialize(newModel);
      if (newModel.fullText || newModel.modifier) {
        this.fullText = val;
        return null;
      }
      this.value = val;

      return newModel;
    },
    get() {
      if (this.isValueValid) {
        return this.adapter.deserialize(this.value, this.content);
      }
      return null;
    },
  },

  isValueValid: {
    get() {
      return this.adapter.validate(this.value, this.content);
    },
  },
});

AssistedSearchToken.create = function (params) {
  return new this(params);
};
