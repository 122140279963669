/* eslint-disable class-methods-use-this */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { groupBy, flatten, intersection, isEqual, difference } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { v4 as uuidv4 } from 'uuid';
import { CollectionSettings } from './fields-collection.models';
import { DesignerSchemaFieldI } from '../../models/designer-schema-field.model';
import { TemplateNameDirective } from '../../../common/components/template-name.directive';

interface RowStateOptions {
  isActive?: boolean;
  isError?: {
    [prop: string]: boolean;
  };
  isDuplicateError?: boolean;
  isOutsideDuplicateError?: boolean;
}

export interface ErrorType {
  index: number;
  message?: string;
  type?: string;
  prop?: string;
}

const filterPredicate = (data: any, filter: string) => {
  const { name, alias, data_type, projected_name, left, operator, right, function_name, field_name, column_name } =
    data;
  const dataStr = [
    name,
    alias,
    data_type,
    projected_name,
    left,
    operator,
    right,
    function_name,
    field_name,
    column_name,
  ]
    .filter(Boolean)
    .map((item) => item.toLowerCase())
    .join('');
  return dataStr.indexOf(filter) !== -1;
};

@Component({
  selector: 'xp-fields-collection',
  template: `
    <div class="fields-collection-wrapper">
      <div
        class="fields-collection-header"
        *ngIf="
          !collectionSettings.hideSearch || (collectionSettings.autoFillFns && collectionSettings.autoFillFns.length)
        "
      >
        <div class="fields-collection-search" *ngIf="!collectionSettings.hideSearch">
          <input
            class="fields-collection-search-input form-control input-sm"
            (keyup)="applyFilter($event)"
            placeholder="Find"
            #input
          />
        </div>
        <div class="fields-collection-autofill">
          <div
            class="btn-group pull-right"
            *ngIf="collectionSettings.autoFillFns && collectionSettings.autoFillFns.length > 1"
          >
            <button
              type="button"
              class="btn btn-default btn-sm btn-gray"
              (click)="autoFill()"
              [matTooltip]="autofillTooltip"
              matTooltipPosition="left"
              matTooltipClass="left"
            >
              Auto-fill
            </button>
            <button
              type="button"
              class="btn btn-default btn-sm btn-gray dropdown-toggle xp-dropdown"
              [matMenuTriggerFor]="dropdown"
            >
              <span class="caret"></span>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <mat-menu #dropdown="matMenu">
              <li
                mat-menu-item
                *ngFor="let autoFillFn of collectionSettings.autoFillFns"
                [hidden]="autoFillFn.isHidden"
                (click)="autoFill(autoFillFn.func)"
              >
                {{ autoFillFn.text }}
              </li>
            </mat-menu>
          </div>
          <button
            type="button"
            class="btn btn-default btn-sm btn-gray pull-right"
            *ngIf="collectionSettings.autoFillFns && collectionSettings.autoFillFns.length === 1"
            (click)="autoFill()"
          >
            {{ collectionSettings.autoFillFns[0].text }}
          </button>
          <i
            *ngIf="autofillTooltip"
            class="fa fa-exclamation-circle"
            [matTooltip]="autofillTooltip"
            matTooltipPosition="above"
            matTooltipClass="above wide-400"
          ></i>
        </div>
      </div>

      <table
        mat-table
        [dataSource]="dataSource"
        [trackBy]="trackById"
        #table
        cdkDropList
        [cdkDropListData]="dataSource"
        (cdkDropListDropped)="onDragAndDropEvent($event)"
      >
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef class="constant-width"></th>
          <td mat-cell *matCellDef="let row; let i = index" class="constant-width fields-collection-row-index">
            {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
          </td>
        </ng-container>

        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef class="constant-width"></th>
          <td mat-cell *matCellDef="let element" class="constant-width">
            <div
              class="fields-collection-row-sort"
              cdkDragHandle
              (touchstart)="dragDisabled = false"
              (touchend)="dragDisabled = true"
              (mousedown)="dragDisabled = false"
              (mouseup)="dragDisabled = true"
              *ngIf="!collectionSettings.hideSortable"
            >
              <div class="editor-button">
                <i class="fa fa-arrows-v"></i>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="i !== columns.length - 1" [index]="i + 2">
            <ng-template [ngTemplateOutlet]="cellTemplates[column + '-header']"></ng-template>
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [ngClass]="column + '-cell'">
            <ng-template
              [ngTemplateOutlet]="cellTemplates[column]"
              [ngTemplateOutletContext]="{
                $implicit: {
                  record: row,
                  index: index + paginator.pageIndex * paginator.pageSize,
                  focusedProp: row.focusedProp
                }
              }"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="add">
          <th mat-header-cell *matHeaderCellDef class="constant-width"></th>
          <td mat-cell *matCellDef="let row; let index = index" class="constant-width">
            <div class="fields-collection-row-add">
              <div class="editor-button" (click)="addRecord(index + paginator.pageIndex * paginator.pageSize + 1)">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef class="constant-width"></th>
          <td mat-cell *matCellDef="let row; let index = index" class="constant-width">
            <div class="fields-collection-row-remove">
              <div
                class="editor-button"
                (click)="removeRecord(index + paginator.pageIndex * paginator.pageSize)"
                [ngClass]="{
                  disabled: (records.length === 1 && !collectionSettings.allowEmptyCollection) || row.required
                }"
              >
                <i class="fa fa-remove"></i>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="fields-collection-row"
          mat-row
          *matRowDef="let row; let i = index; columns: displayedColumns"
          cdkDrag
          [cdkDragData]="row"
          [cdkDragDisabled]="dragDisabled"
          (cdkDragReleased)="dragDisabled = true"
          [ngClass]="getRowState(i)"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-items-found-text" colspan="4">No matches found</td>
        </tr>
      </table>

      <mat-paginator
        #paginator
        [hidden]="records.length <= collectionSettings.itemsPerPage"
        [pageSizeOptions]="[10, 20, 50]"
        [pageSize]="collectionSettings.itemsPerPage"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
    <xp-fields-collection-errors-box
      [errors]="errors"
      class="fields-collection-errors"
    ></xp-fields-collection-errors-box>
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpFieldsCollectionComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() collectionSettings: Partial<CollectionSettings>;
  @Input() isValid: boolean;
  @Input() columns: string[];
  @Input() duplicationValidationProp: string;
  @Input() duplicationValidationPropName: string;
  @Input() autofillTooltip: string;
  @Output() recordsChange = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() validityChange = new EventEmitter();
  @ContentChildren(TemplateNameDirective) templates: QueryList<TemplateNameDirective>;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      setTimeout(() => {
        this.dataSource.paginator = value;
        this.dataSource.data = this.records;
        this.isPaginationSet = true;
      });
    }
  }
  @ViewChild('table') table: MatTable<any>;

  displayedColumns: string[] = ['index'];
  dataSource: MatTableDataSource<any>;

  dragDisabled = true;
  errors: ErrorType[] = [];
  errorsTemp: ErrorType[] = [];
  find = '';
  rows: any[];
  cellTemplates = {};
  rowStates: RowStateOptions[] = [];
  isPaginationSet = false;

  ngAfterContentInit() {
    const templatesArray = this.templates.toArray();
    this.cellTemplates = templatesArray.reduce((acc, curr) => ({ ...acc, [curr.templateName]: curr.template }), {});
    this.displayedColumns = ['index', 'position', ...this.columns, 'add', 'remove'];

    if (this.collectionSettings.hideIndex) {
      this.displayedColumns = this.displayedColumns.filter((key) => key !== 'index');
    }
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    if (!this.collectionSettings.allowEmptyCollection && !this.records.length) {
      this.addRecord(0, true);
    }

    this.checkCollectionValidity();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.records && changes.records.currentValue && this.dataSource && this.isPaginationSet) {
      const { filter } = this.dataSource;
      this.dataSource.data = this.records;

      if (filter) {
        this.applyFilter({ target: { value: filter } } as any);
      }

      setTimeout(() => {
        this.checkCollectionValidity();
      });
    }

    if (changes.collectionSettings && !changes.collectionSettings.firstChange) {
      const currentSettings: CollectionSettings = changes.collectionSettings.currentValue;

      if (currentSettings.outsideSourceValidation && currentSettings.outsideSourceValidation.unique) {
        this.validateDuplicates();
      }
    }

    if (changes.records && changes.records.currentValue?.length !== changes.records.previousValue?.length) {
      this.rowStates = this.rowStates.slice(0, this.records.length);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackById(index: number, item: DesignerSchemaFieldI) {
    return item.id;
  }

  onDragAndDropEvent(event: CdkDragDrop<MatTableDataSource<any>, any>) {
    const prevIndex = this.records.findIndex((d) => d === event.item.data);
    const toIndex = this.dataSource.paginator
      ? event.currentIndex + this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize
      : event.currentIndex;
    const newRecords = [...this.records];
    moveItemInArray(newRecords, prevIndex, toIndex);
    moveItemInArray(this.rowStates, prevIndex, toIndex);
    const { filter } = this.dataSource;

    this.dataSource.data = newRecords;

    if (filter) {
      this.applyFilter({ target: { value: filter } } as any);
    }
    this.onChange(newRecords);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filterPredicate = filterPredicate;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getRowState(index: number): any {
    if (this.dataSource) {
      if (this.dataSource.paginator) {
        const rowState =
          this.rowStates[index + this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize] || {};
        return {
          active: rowState.isActive,
          error: Object.values(rowState.isError || {}).some(Boolean) || rowState.isDuplicateError,
          duplicateError: rowState.isDuplicateError,
        };
      }

      const rowState = this.rowStates[index] || {};
      return {
        active: rowState.isActive,
        error: Object.values(rowState.isError || {}).some(Boolean) || rowState.isDuplicateError,
        duplicateError: rowState.isDuplicateError,
      };
    }
    return {};
  }

  addRecord(index: number, isInitial?: boolean) {
    const realIndex = isInitial
      ? index
      : this.records.findIndex((item) => item.id === this.dataSource.filteredData[index - 1].id) + 1;
    let record;

    if (typeof this.collectionSettings.addRecord === 'function') {
      this.collectionSettings.addRecord(realIndex);
    } else {
      if (!this.collectionSettings.emptyRecord) {
        this.collectionSettings.emptyRecord = {
          alias: '',
          name: '',
          FC_pristine: true,
        };
      }
      record = { ...this.collectionSettings.emptyRecord, id: uuidv4() };
      this.records.splice(realIndex, 0, record);

      const { filter } = this.dataSource || {};
      this.dataSource.data = this.records;

      if (filter) {
        this.applyFilter({ target: { value: filter } } as any);
      }
      this.onChange(this.records, isInitial);
    }
  }

  removeRecord(index: number) {
    const realIndex = this.records.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    this.removeError(index);

    this.rowStates.splice(realIndex, 1);
    this.records = this.records.filter((item, i) => i !== realIndex);

    this.validateDuplicates();

    const { filter } = this.dataSource;
    this.dataSource.data = this.records;

    if (filter) {
      this.applyFilter({ target: { value: filter } } as any);
    }
    this.onChange(this.records);
  }

  onChange(records: any[], isInitial?: boolean) {
    this.recordsChange.emit({ records: records || this.records, isInitial });
  }

  autoFill(autofillFn?: () => any) {
    let autoFillFunction = autofillFn;

    if (!autofillFn && this.collectionSettings.autoFillFns && this.collectionSettings.autoFillFns.length) {
      autoFillFunction = this.collectionSettings.autoFillFns[0].func;
    }

    if (typeof autoFillFunction === 'function' && this.collectionSettings.parentSchemas) {
      const newRecords = autoFillFunction();
      if (newRecords && newRecords.length) {
        this.records = newRecords;
        const { filter } = this.dataSource;
        this.dataSource.data = this.records;

        if (filter) {
          this.applyFilter({ target: { value: filter } } as any);
        }

        this.onChange(this.records);
      }
    }
  }

  autoGenerateAll() {
    this.records.forEach((record, index) => {
      this.autoGenerate(index);
    });
    this.checkCollectionValidity();
  }

  autoGenerate(recordsIndex: number) {
    if (typeof this.collectionSettings.autoGenerateFn === 'function' && this.records[recordsIndex]) {
      this.records[recordsIndex].FC_pristine = false;
      this.collectionSettings.autoGenerateFn(this.records[recordsIndex]);
    }
    this.checkCollectionValidity();
  }

  setRecordPristineState(index: number, isPristine: boolean) {
    if (this.records[index].FC_pristine !== isPristine) {
      this.records[index] = { ...this.records[index], FC_pristine: isPristine };
      this.onChange(this.records);
    }
  }

  isRecordPristine(index: number): boolean {
    if (!this.records[index]) {
      return true;
    }

    return this.records[index].FC_pristine;
  }

  setRowState(index: number, options: RowStateOptions) {
    this.rowStates[index] = { ...(this.rowStates[index] || {}), ...options };

    if (!options.isActive && !this.dataSource.filter) {
      this.records = this.records.map((item) => ({ ...item, isFocused: false }));
      const { filter } = this.dataSource;

      if (filter) {
        this.applyFilter({ target: { value: filter } } as any);
      }
    }
  }

  validateDuplicates() {
    if (!this.duplicationValidationProp) {
      return;
    }

    const duplicationMap = groupBy(
      this.records.filter((record) => record[this.duplicationValidationProp]),
      (record) => record[this.duplicationValidationProp],
    );
    let outsideErrors = [];
    let hasAnyRecordChanged = false;

    if (this.collectionSettings.outsideSourceValidation && this.collectionSettings.outsideSourceValidation.unique) {
      const duplicationMapForArrays = flatten(Object.keys(duplicationMap).map((item) => item.split(',')));
      const outsideErrorsMap = this.collectionSettings.outsideSourceValidation.unique.validation.filter(Boolean).reduce(
        (acc, value) => ({
          ...acc,
          ...(duplicationMap[value] || duplicationMapForArrays.includes(value) ? { [value]: true } : {}),
        }),
        {},
      );

      outsideErrors = this.records
        .filter(
          (item) =>
            outsideErrorsMap[item[this.duplicationValidationProp]] ||
            intersection(item[this.duplicationValidationProp], Object.keys(outsideErrorsMap)).length,
        )
        .map((record) => ({
          index: this.records.indexOf(record),
          prop: this.duplicationValidationProp,
        }));
    }

    const errors: Array<ErrorType[]> = Object.keys(duplicationMap)
      .filter((key) => key && duplicationMap[key].length > 1)
      .map((key) =>
        duplicationMap[key].map((record) => ({
          index: this.records.indexOf(record),
          prop: this.duplicationValidationProp,
        })),
      );

    const duplicateErrors = flatten(errors).map(({ index }) => index);
    const duplicateErrorsOutside = outsideErrors.map((index) => index);
    let currentDuplicateErrors = [];
    let currentDuplicateErrorsOutside = [];

    this.records.forEach((record, index) => {
      if (record.isDuplicateError) {
        currentDuplicateErrors.push(index);
      }

      if (record.isOutsideDuplicateError) {
        currentDuplicateErrorsOutside.push(index);
      }
    });

    if (duplicateErrors.length < currentDuplicateErrors.length) {
      difference(currentDuplicateErrors, duplicateErrors).forEach((index) => {
        this.removeError(index);
      });
    }

    if (
      isEqual(duplicateErrors, currentDuplicateErrors) &&
      isEqual(duplicateErrorsOutside, currentDuplicateErrorsOutside)
    ) {
      return;
    }

    this.records.forEach((record, index) => {
      if (
        this.records[index] &&
        (this.records[index].isDuplicateError || this.records[index].isOutsideDuplicateError)
      ) {
        hasAnyRecordChanged = true;
        this.records[index] = { ...this.records[index], isDuplicateError: false, isOutsideDuplicateError: false };
      }
      this.setRowState(index, { isDuplicateError: false, isOutsideDuplicateError: false });
    });

    flatten(errors).forEach(({ index }) => {
      hasAnyRecordChanged = true;
      this.setRowState(index, { isDuplicateError: true });
      this.records[index] = { ...this.records[index], isDuplicateError: true };
    });

    outsideErrors.forEach(({ index }) => {
      hasAnyRecordChanged = true;
      this.setRowState(index, { isOutsideDuplicateError: true });
      this.records[index] = { ...this.records[index], isOutsideDuplicateError: true };
    });

    if (this.dataSource.paginator && hasAnyRecordChanged) {
      setTimeout(() => {
        this.dataSource.data = [...this.records];
        this.onChange(this.records);
      });
    }
  }

  isDuplicateError(index: number) {
    return (this.rowStates[index] || {}).isDuplicateError;
  }

  sortErrors() {
    this.errors.sort((a, b) => {
      if (a.index > b.index) return 1;
      if (a.index < b.index) return -1;
      return 0;
    });
  }

  updateRowStateError(index: number, prop: string, rowStateOnly?: boolean) {
    const errorExists = !!this.errorsTemp.find((error) => error.index === index) || rowStateOnly;

    if (!this.rowStates[index]) {
      this.rowStates[index] = {
        isError: {},
      };
    }

    if (!this.rowStates[index].isError) {
      this.rowStates[index] = {
        ...this.rowStates[index],
        isError: {},
      };
    }

    if (this.rowStates[index] && errorExists !== this.rowStates[index].isError[prop]) {
      this.setRowState(index, {
        isError: {
          ...this.rowStates[index].isError,
          [prop]: errorExists,
        },
        isDuplicateError: errorExists,
        isOutsideDuplicateError: errorExists,
      });
    }
  }

  registerError(index: number, message: string, prop: string, hideMessage?: boolean): ErrorType {
    if (hideMessage) {
      this.updateRowStateError(index, prop, true);
      this.checkCollectionValidity();
      return;
    }
    let error = this.errors.find((item) => item.index === index && item.prop === prop);
    let errorTemp = this.errorsTemp.find((item) => item.index === index && item.prop === prop);
    if (!errorTemp) {
      error = {
        index,
        message,
        prop,
      };
      this.errorsTemp = [...this.errors, error];
      setTimeout(() => {
        this.errors = [...this.errors, error];
        this.sortErrors();
      });
    } else if (errorTemp.message !== message) {
      errorTemp.message = message;
    }
    this.updateRowStateError(index, prop);
    return errorTemp;
  }
  removeRowStateError(index: number, prop: string) {
    if (this.rowStates[index] && this.rowStates[index].isError) {
      this.rowStates[index].isError[prop] = false;
      this.rowStates[index].isDuplicateError = false;
      this.rowStates[index].isOutsideDuplicateError = false;
    }
  }

  removeError(index: number, prop?: string) {
    const realIndex = this.records.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    let errorsIndexes: any[] = this.errors.map((item, indexInArray) => ({
      shouldRemove: item.index === realIndex,
      indexInArray,
    }));

    if (prop) {
      errorsIndexes = this.errors.map((item, indexInArray) => ({
        shouldRemove: item.index === realIndex && item.prop === prop,
        indexInArray,
      }));
    }

    errorsIndexes = errorsIndexes.filter((item) => item.shouldRemove).map((item) => item.indexInArray);

    if (errorsIndexes.length) {
      this.errors = this.errors.filter((item, indexInArray) => !errorsIndexes.includes(indexInArray));
      this.errorsTemp = this.errorsTemp.filter((item, indexInArray) => !errorsIndexes.includes(indexInArray));
      this.sortErrors();
    }
    this.removeRowStateError(realIndex, prop);
  }

  checkCollectionValidity() {
    this.validateDuplicates();
    const valid =
      this.errors.length === 0 &&
      this.rowStates.every((rowState) => !(rowState?.isError && Object.values(rowState.isError).some(Boolean)));
    this.validityChange.emit(valid);
  }

  focusError(error: ErrorType) {
    this.dataSource.filter = '';
    this.focusOnInput(error.index, error.prop);
  }

  focusOnInput(matchedIndex: number, prop: string) {
    const realIndex = this.records.findIndex(
      (item) => item.id === (this.dataSource.filteredData[matchedIndex] || {}).id,
    );

    if (realIndex === -1) {
      return;
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.pageIndex = Math.floor(realIndex / this.dataSource.paginator.pageSize);
    }

    this.records = this.records.map((item, index) => ({
      ...item,
      focusedProp: index === realIndex ? prop : null,
      isFocused: index === realIndex,
    }));
    const { filter } = this.dataSource;
    this.dataSource.data = this.records;

    if (filter) {
      this.applyFilter({ target: { value: filter } } as any);
    }

    this.onChange(this.records);
  }
}
