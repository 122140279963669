import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Job, JobOutput } from '../../jobs.models';
import { JobsResource } from '../../resources/jobs.resource';
import { NotifyService } from '../../../common/services/notify.service';

function getGridFromData(res) {
  const data = [];

  const getRow = function (row) {
    const rowItem = {};
    res.fields.forEach((field, index) => {
      const key = Object.keys(field)[0];
      rowItem[key] = row[index];
    });
    return rowItem;
  };

  res.data.forEach((row) => {
    data.push(getRow(row));
  });

  return data;
}

function getGridFromRaw(res) {
  const data = [];

  const getRow = function (raw) {
    const rawItem = {};
    res.fields.forEach((field) => {
      const key = Object.keys(field)[0];
      const jsonRaw = JSON.parse(raw);
      rawItem[key] = JSON.stringify(jsonRaw[key]);
    });
    return rawItem;
  };

  res.raw.forEach((row) => {
    data.push(getRow(row));
  });

  return data;
}

function getColumnDefs(fields) {
  const columnDefs = [];
  for (let i = 0; i < fields.length; i += 1) {
    columnDefs.push({
      name: fields[i],
      width: 100,
    });
  }
  return columnDefs;
}

function getFields(data) {
  const fields = [];
  const rows = data.split('\n');
  const cells = rows[0].split('\t');

  for (let i = 0; i < cells.length; i += 1) {
    const fieldName = `field_${i}`;
    fields.push(fieldName);
  }

  return fields;
}

@Component({
  selector: 'job-output-item',
  template: `
    <div class="job-output-item" [ngClass]="{ 'can-preview': output.can_preview }">
      <div class="job-output-item-grid" (click)="loadPreview()">
        <div class="job-output-item-name">
          <component-icon [type]="output.component.type"></component-icon>
          <div>
            <span>{{ output.component.name }}</span>
            <small>{{ output.path }}</small>
          </div>
        </div>
        <div class="job-output-item-records">
          <span>{{ output.records_count }}</span>
        </div>
      </div>
      <div class="job-output-item-preview" *ngIf="showPreview">
        <div class="data-preview-header">
          <strong>Data preview</strong>
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-sm btn-default"
              [ngClass]="{ active: preview.type === currentPreview.type }"
              *ngFor="let preview of previews"
              (click)="selectPreview(preview)"
              [hidden]="preview.disable"
            >
              {{ preview.name }}
            </button>
          </div>
        </div>
        <!-- ERROR -->
        <div class="alert alert-warning" *ngIf="output.error">{{ output.error }}</div>
        <!-- TABLE -->
        <table-view [data]="gridOptions" *ngIf="currentPreview.type === 'table' && !this.loadingPreview"></table-view>
        <!-- JSON -->
        <json-explorer
          [data]="gridOptions.data"
          [collapsed]="true"
          *ngIf="currentPreview.type === 'json' && !this.loadingPreview"
        ></json-explorer>
        <!-- RAW -->
        <pre *ngIf="currentPreview.type === 'raw' && !this.loadingPreview">{{ output.preview }}</pre>
        <xp-loader *ngIf="this.loadingPreview"></xp-loader>
      </div>
    </div>
  `,
})
export class JobOutputItemComponent {
  @Input() output: JobOutput;
  @Input() job: Job;

  showPreview = false;
  dataLoaded = false;
  loadingPreview = false;
  gridOptions: any = {
    enableSorting: false,
    enableHorizontalScrollbar: 2,
    enableVerticalScrollbar: 2,
    columnDefs: [],
  };

  previews = [
    {
      type: 'table',
      name: 'Table',
    },
    {
      type: 'raw',
      name: 'Raw',
    },
  ];

  currentPreview = this.previews[0];
  constructor(
    private jobsResource: JobsResource,
    private http: HttpClient,
    private notify: NotifyService,
    private translate: TranslateService,
  ) {}

  selectPreview(currentPreview) {
    this.currentPreview = currentPreview;
  }

  toDataObject(row, fields) {
    const cells = row.split(',');

    const object = {};

    if (cells.length === fields.length) {
      for (let i = 0; i < fields.length; i += 1) {
        object[fields[i]] = cells[i];
      }
    } else if (cells.length < fields.length) {
      for (let i = 0; i < fields.length; i += 1) {
        object[fields[i]] = cells.length >= i ? cells[i] : '';
      }
    } else {
      const newFields = [];
      for (let i = 0; i < cells.length; i += 1) {
        const fieldName = `field_${i}`;
        object[fieldName] = cells[i];
        newFields.push(fieldName);
        this.gridOptions.columnDefs = getColumnDefs(newFields);
      }
    }

    return object;
  }

  convertCsvToGrid(preview, fields) {
    const dataSet = [];

    const rows = preview.split('\n');

    for (let i = 1; i < rows.length; i += 1) {
      dataSet.push(this.toDataObject(rows[i], fields));
    }

    return dataSet;
  }

  loadPreview() {
    this.showPreview = !this.showPreview;

    if (!this.dataLoaded) {
      this.loadingPreview = true;

      this.gridOptions.columnDefs = getColumnDefs(this.output.component.fields);

      if (this.output.import_url) {
        this.http.get(this.output.import_url, {}).subscribe({
          next: (res: any) => {
            if (res.data.data && res.data.data.length > 0) {
              this.gridOptions.data = getGridFromData(res.data);
            } else if (res.data.raw && res.data.raw.length > 0) {
              this.gridOptions.data = getGridFromRaw(res.data);
            }
            this.loadingPreview = false;
            this.dataLoaded = true;
          },
          error: (error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
          },
        });
      } else {
        this.jobsResource.output(this.output.id, this.job.id).subscribe({
          next: (data) => {
            this.output.preview = data.preview;
            if (!this.output.component && !this.output.component.fields) {
              this.output.component.fields = getFields(this.output.preview);
            }
            this.gridOptions.data = this.convertCsvToGrid(this.output.preview, this.output.component.fields);
            this.loadingPreview = false;
            this.dataLoaded = true;
          },
          error: (data) => {
            this.notify.error(this.translate.instant(`response.${data.status}.message`));
            this.output.error = data.error.message;
            this.loadingPreview = false;
          },
        });
      }
    }
  }
}
