import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { SearchConfig } from './search-config.model';
import { AppState } from '../../../store';
import { getMembersList } from '../../../settings/store/members/members.actions';
import { selectMembers } from '../../../settings/store/members/members.selectors';
import { Member } from '../../../settings/members.models';
import { getWorkspacesList } from '../../../workspaces/store/workspaces.actions';
import { selectWorkspaces } from '../../../workspaces/store/workspaces.selectors';
import { Workspace } from '../../../workspaces/workspaces.models';

const basicConfig: SearchConfig = {
  'id:': {
    type: 'default',
    defaultHint: 'Packages with this ID',
  },
  'name:': {
    defaultHint: 'Packages with this name',
  },
  'description:': {
    defaultHint: 'Packages with this description',
  },
  'created:>': {
    type: 'date',
    defaultHint: 'Packages created after the date',
  },
  'created:<': {
    type: 'date',
    defaultHint: 'Packages created before the date',
  },
  'modified:>': {
    type: 'date',
    defaultHint: 'Packages modified after the date',
  },
  'modified:<': {
    type: 'date',
    defaultHint: 'Packages modified before the date',
  },
  'user:': {
    type: 'thumbs-list',
    defaultHint: 'Packages owned by matching users',
    sectionTitle: 'Users',
    content: [], // thumbs-list -compiliant autocomplete items will go here
  },
  'workspace:': {
    type: 'list',
    defaultHint: 'Packages included in matching workspaces',
    sectionTitle: 'Workspaces',
    content: [], // thumbs-list -compiliant autocomplete items will go here
  },
};

interface PackageSearchItem {
  id: number;
  value: string;
  label: string;
  thumb_url?: string;
}

function prepareValue(stringVal: string): string {
  if (stringVal && typeof stringVal.indexOf !== 'undefined' && stringVal.indexOf(' ') !== -1) {
    return `"${stringVal}"`; // wrap in quotes if contains spaces
  }
  return stringVal;
}

function formatItemForList(u: Workspace): PackageSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name),
    label: u.name,
  };
}

function formatMemberForThumbsList(u: Member): PackageSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name || u.email),
    label: u.name || u.email,
    thumb_url: u.avatar_url,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssistedSearchPackagesConfiguratorService {
  constructor(private store: Store<AppState>) {}

  getConfig$(): Observable<SearchConfig> {
    this.store.dispatch(
      getWorkspacesList({
        params: {
          sort: 'updated',
          direction: 'desc',
        },
      }),
    );
    this.store.dispatch(getMembersList({ params: {} }));

    return combineLatest([this.store.select(selectWorkspaces), this.store.select(selectMembers)]).pipe(
      map(([workspaces, members]) => {
        const configCopy = { ...basicConfig };
        configCopy['workspace:'].content = workspaces.map(formatItemForList);
        configCopy['user:'].content = members.map(formatMemberForThumbsList);
        return configCopy;
      }),
    );
  }
}
