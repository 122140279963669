import { COMPONENT_TYPES, ComponentTypeItem } from './component_types';

interface ComponentsMap {
  [key: string]: ComponentTypeItem;
}

export const COMPONENTS: ComponentsMap = COMPONENT_TYPES.reduce((acc: ComponentsMap, component: ComponentTypeItem) => {
  acc[component.componentType] = component;
  return acc;
}, {});
