import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AllComponentData, AssertComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { MATCH_TYPES } from '../../../constants/match_types';

@Component({
  selector: 'assert-editor',
  template: `
    <div class="assert-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::AssertComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <div class="col-md-12">
              <label
                ><b>{{ 'assert_component.labels.description' | translate }}</b></label
              >
            </div>
          </div>
          <div class="row">
            <xp-form-group class="col-md-6">
              <label for="message">{{ 'assert_component.labels.message' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="message"
                id="message"
                [ngModel]="rawComponent.message"
                (ngModelChange)="onMessageChange($event)"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="row">
            <xp-form-group class="col-md-6">
              <div class="form-group">
                <label for="match_type">{{ 'assert_component.labels.conditions' | translate }}</label>
                <xp-select
                  name="match_type"
                  id="match_type"
                  [value]="rawComponent.predicates.match_type"
                  [options]="matchTypes"
                  [preventEmpty]="true"
                  (valueChange)="onMatchTypeChange($event)"
                  class="form-control xp-select"
                  style="white-space: nowrap;"
                ></xp-select>
              </div>
            </xp-form-group>
          </div>
          <filter-collection
            [records]="rawComponent.predicates.expressions"
            (recordsChange)="onRecordsChange($event)"
            [parentSchemas]="parentSchemas"
            (validityChange)="onFilterCollectionValidityChange($event)"
          ></filter-collection>
        </form>
      </xp-form-validation>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class AssertEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: AssertComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  matchTypes = MATCH_TYPES;
  filterCollectionValid = false;
  isFormValid = false;
  validationChangeSubscription: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });
  }

  onRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { predicates: { ...this.rawComponent.predicates, expressions: records } },
      }),
    );
    this.store.dispatch(updateComponent({ component: { predicates: { expressions: records } } }));
  }

  onValidityChange() {
    const isValid = (this.rawComponent.data_order === 'no_sort' || this.filterCollectionValid) && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onFilterCollectionValidityChange(value) {
    this.filterCollectionValid = value;

    this.onValidityChange();
  }

  onMatchTypeChange(match_type: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { predicates: { ...this.rawComponent.predicates, match_type } },
      }),
    );
    this.store.dispatch(updateComponent({ component: { predicates: { match_type } } }));
  }

  onMessageChange(message: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { message },
      }),
    );
    this.store.dispatch(updateComponent({ component: { message } }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
