/* eslint-disable default-case */
import { COMPONENT_TYPE } from '../../constants/component_type';
import { ComponentTypeItem } from '../../constants/component_types';

export interface AccountsCampaignsSelectorSettings {
  refreshAvailable: boolean;
  selectAll: boolean;
  selectOne: boolean;
  removeAll: boolean;
  removeOne: boolean;
  search: boolean;
  showMessageNotBeingAvailable: boolean;
  allowRemoveAll: boolean;
  availableAccountsLabel: string;
  selectedAccountsLabel: string;
}

function getDefaultSettings(): AccountsCampaignsSelectorSettings {
  return {
    // Refresh available
    refreshAvailable: false,

    // Select all
    selectAll: false,

    // Select one
    selectOne: false,

    // Remove all
    removeAll: false,

    // Remove one
    removeOne: false,

    // Search
    search: false,

    allowRemoveAll: false,

    // Show message about selected not being available
    showMessageNotBeingAvailable: false,

    // Available accounts label
    availableAccountsLabel: 'Available accounts and campaigns',

    // Selected accounts label
    selectedAccountsLabel: 'Selected accounts and campaigns',
  };
}

export function getSettings(component: ComponentTypeItem): AccountsCampaignsSelectorSettings {
  const settings = getDefaultSettings();

  switch (component.componentType) {
    case COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT:
      settings.refreshAvailable = true;
      settings.selectAll = true;
      settings.allowRemoveAll = true;
      settings.selectOne = true;
      settings.removeAll = true;
      settings.removeOne = true;
      settings.availableAccountsLabel = 'Available accounts and campaigns';
      settings.selectedAccountsLabel = 'Selected accounts and campaigns';
      break;
    case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
      settings.refreshAvailable = true;
      settings.allowRemoveAll = true;
      settings.selectOne = true;
      settings.removeAll = true;
      settings.removeOne = true;
      settings.search = true;
      settings.availableAccountsLabel = 'Available accounts';
      settings.selectedAccountsLabel = 'Selected accounts';
      break;
    case COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT:
      settings.refreshAvailable = true;
      settings.allowRemoveAll = true;
      settings.selectOne = true;
      settings.removeAll = true;
      settings.removeOne = true;
      settings.search = true;
      break;
    case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
      settings.refreshAvailable = true;
      settings.allowRemoveAll = true;
      settings.selectOne = true;
      settings.removeAll = true;
      settings.removeOne = true;
      settings.search = true;
      break;
    case COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT:
      settings.refreshAvailable = true;
      settings.allowRemoveAll = true;
      settings.selectOne = true;
      settings.removeAll = true;
      settings.removeOne = true;
      settings.search = true;
      settings.availableAccountsLabel = 'Available accounts and properties';
      settings.selectedAccountsLabel = 'Selected accounts and properties';
      break;
  }

  return settings;
}
