import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Connection, SalesforceDestinationComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { DesignerSchemaFieldI } from '../../models/designer-schema-field.model';
import { SelectPickerValue } from '../../../common/components/forms/select-picker/xp-select-picker.component';
import { CloudStorageConnectionsQuery } from '../../../connections/connection.models';
import { SALESFORCE_API_VERSION, SALESFORCE_API_VERSIONS } from '../../../constants/salesforce_api_versions';

@Component({
  selector: 'salesforce-destination-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep">
          <div class="salesforce-destination-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::SalesforceDestinationComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="alert alert-warning" *ngIf="errorMessage">
                  <button type="button" class="close" (click)="errorMessage = null">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span>{{ errorMessage }}</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="api_version">{{
                        'salesforce-source-editor.form.labels.api_version' | translate
                      }}</label>
                      <xp-select
                        name="api_version"
                        id="api_version"
                        [value]="rawComponent.api_version"
                        [options]="apiVersions"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onApiVersionChange($event)"
                        emptyPlaceholder="No api version found"
                      ></xp-select>
                    </div>
                  </div>
                </div>
                <salesforce-object-picker
                  (fieldsLoadStart)="onFieldsLoadStart()"
                  (fieldsLoadEnd)="onFieldsLoadEnd($event)"
                  [rawComponent]="rawComponent"
                  [component]="component"
                ></salesforce-object-picker>
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group [validationDisabled]="true">
                      <label for="operation_type">{{
                        'salesforce-destination-editor.form.labels.operation_type' | translate
                      }}</label>
                      <xp-select
                        class="form-control xp-select"
                        name="operation_type"
                        id="operation_type"
                        [value]="rawComponent.operation_type"
                        [options]="operationTypes"
                        (valueChange)="onValueChange($event, 'operation_type')"
                      >
                      </xp-select>
                    </xp-form-group>
                    <xp-form-group
                      *ngIf="rawComponent.operation_type === 'upsert' || rawComponent.operation_type === 'update'"
                      style="min-height: 74px;"
                    >
                      <div class="alert alert-warning" *ngIf="idFieldErrorMsg">
                        <button type="button" class="close" (click)="idFieldErrorMsg = null">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <span [innerHTML]="idFieldErrorMsg"></span>
                      </div>
                      <label *ngIf="rawComponent.operation_type === 'upsert'" for="id_field">{{
                        'salesforce-destination-editor.form.labels.id_field' | translate
                      }}</label>
                      <label *ngIf="rawComponent.operation_type === 'update'" for="id_field">{{
                        'salesforce-destination-editor.form.labels.id_field_update' | translate
                      }}</label>
                      <xp-loader *ngIf="areFieldsLoading"></xp-loader>
                      <xp-select
                        *ngIf="!areFieldsLoading"
                        name="id_field"
                        id="id_field"
                        [value]="rawComponent.id_field"
                        (valueChange)="onValueChange($event, 'id_field')"
                        [options]="idOptions"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                      >
                      </xp-select>
                    </xp-form-group>
                    <h5>{{ 'salesforce-destination-editor.advanced-options' | translate }}</h5>
                    <div class="well">
                      <div class="row">
                        <div class="col-md-6" *ngIf="rawComponent.api_version !== SALESFORCE_API_VERSION.V2">
                          <xp-form-group>
                            <label for="batch_size"
                              >{{ 'salesforce-destination-editor.form.labels.batch_size' | translate
                              }}<i
                                class="fa fa-exclamation-circle"
                                matTooltip="Valid range from 1 to 10,000"
                                matTooltipPosition="after"
                                matTooltipClass="after"
                              ></i
                            ></label>
                            <xp-input
                              type="number"
                              name="batch_size"
                              id="batch_size"
                              [ngModel]="rawComponent.batch_size"
                              (ngModelChange)="onValueChange($event, 'batch_size')"
                              class="form-control"
                            ></xp-input>
                          </xp-form-group>
                        </div>
                        <div class="col-md-6">
                          <xp-form-group>
                            <label for="max_errors">{{
                              'salesforce-destination-editor.form.labels.max_errors' | translate
                            }}</label>
                            <xp-input
                              type="number"
                              name="max_errors"
                              id="max_errors"
                              [ngModel]="rawComponent.max_errors"
                              (ngModelChange)="onValueChange($event, 'max_errors')"
                              class="form-control"
                            ></xp-input>
                          </xp-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-9">
                          <xp-form-group>
                            <label for="parallel">{{
                              'salesforce-destination-editor.form.labels.parallel' | translate
                            }}</label>
                            <xp-input
                              type="number"
                              class="form-control"
                              name="parallel"
                              id="parallel"
                              [ngModel]="rawComponent.parallel"
                              (ngModelChange)="onValueChange($event, 'parallel')"
                            ></xp-input>
                          </xp-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <xp-form-group>
                            <xp-input-checkbox
                              name="track_errors"
                              [ngModel]="rawComponent.track_errors"
                              (ngModelChange)="onValueChange($event, 'track_errors')"
                              [labelText]="'salesforce-destination-editor.form.labels.track_errors' | translate"
                            ></xp-input-checkbox>
                          </xp-form-group>
                        </div>
                      </div>
                      <div
                        class="row"
                        *ngIf="rawComponent.operation_type === 'upsert' || rawComponent.operation_type === 'update'"
                      >
                        <div class="col-md-12">
                          <xp-form-group>
                            <xp-input-checkbox
                              name="enable_null_value"
                              [ngModel]="rawComponent.enable_null_value"
                              (ngModelChange)="onValueChange($event, 'enable_null_value')"
                              [labelText]="'salesforce-destination-editor.form.labels.enable_null_value' | translate"
                            ></xp-input-checkbox>
                          </xp-form-group>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="rawComponent.track_errors">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="errors_output_connection">{{
                            'salesforce-destination-editor.form.labels.errors_output_connection' | translate
                          }}</label>

                          <xp-select-picker-editable
                            id="errors_output_connection"
                            [type]="selectPickerTypes.connection"
                            [value]="rawComponent.errors_output_connection"
                            placeholder="Select connection"
                            emptyPlaceholder="Connections list is empty"
                            [params]="{ type: selectPickerConnectionTypes }"
                            (valueChange)="onSelectErrorsOutputConnection($event)"
                            [hideNew]="true"
                            (createNew)="onCreateNewConnection($event)"
                          ></xp-select-picker-editable>
                        </div>
                        <xp-form-group>
                          <label for="errors_output_path">{{
                            'salesforce-destination-editor.form.labels.errors_output_path' | translate
                          }}</label>
                          <xp-input
                            type="text"
                            class="form-control"
                            name="errors_output_path"
                            id="errors_output_path"
                            [ngModel]="rawComponent.errors_output_path"
                            (ngModelChange)="onValueChange($event, 'errors_output_path')"
                            placeholder="bucket/path/to/errors/output/file.csv"
                          ></xp-input>
                        </xp-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaMappingStep">
          <div class="schema-mapping-salesforce">
            <xp-loader *ngIf="areFieldsLoading"></xp-loader>
            <div class="alert alert-warning" *ngIf="errorMessage">
              <button type="button" class="close" (click)="errorMessage = null">
                <span aria-hidden="true">&times;</span>
              </button>
              <span>{{ errorMessage }}</span>
            </div>
            <div class="schema-mapping-salesforce-{{ rawComponent.operation_type }}">
              <schema-mapping-salesforce-collection
                *ngIf="!areFieldsLoading && schemas && schemas.length"
                [records]="rawComponent.column_mappings"
                (recordsChange)="onColumnMappingsChange($event)"
                [schemas]="schemas"
                (validityChange)="onSchemaValidityChange($event)"
                [fields]="fields"
                [operationType]="rawComponent.operation_type"
                [idField]="rawComponent.id_field"
                [objectName]="rawComponent.object_name"
                [connectionId]="rawComponent.connection.id"
                [connectionType]="rawComponent.connection.type"
              ></schema-mapping-salesforce-collection>
            </div>
          </div>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class SalesforceDestinationEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: SalesforceDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  apiVersions = SALESFORCE_API_VERSIONS;
  SALESFORCE_API_VERSION = SALESFORCE_API_VERSION;
  selectPickerTypes = SelectPickerTypes;

  fieldsCollectionValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaMappingStep: Step = getStep({});

  schemas = [];
  idOptions = [];
  schemaReady = false;
  areFieldsLoading = false;
  records = [];
  idFieldErrorMsg = '';
  fields = [];
  errorMessage = '';
  operationTypes = [
    {
      value: 'insert',
      text: 'salesforce-destination-editor.form.operation-type.option.insert',
      translate: true,
    },
    {
      value: 'upsert',
      text: 'salesforce-destination-editor.form.operation-type.option.upsert',
      translate: true,
    },
    {
      value: 'update',
      text: 'salesforce-destination-editor.form.operation-type.option.update',
      translate: true,
    },
    {
      value: 'delete',
      text: 'salesforce-destination-editor.form.operation-type.option.delete',
      translate: true,
    },
    {
      value: 'hardDelete',
      text: 'salesforce-destination-editor.form.operation-type.option.hard_delete',
      translate: true,
    },
  ];
  selectPickerConnectionTypes = CloudStorageConnectionsQuery;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    });

    this.schemaMappingStep = getStep({
      title: this.translate.instant(`component-editor.step-schema-mapping.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(
        `component-editor.step-schema-mapping.${this.component.componentType}.active`,
      ),
      tags: (this.rawComponent.column_mappings || []).map((record) => ({
        name: escape(record.column_name),
      })),
    });

    this.records = [...this.rawComponent.column_mappings];
  }

  onValidityChange() {
    const isValid = this.fieldsCollectionValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onColumnMappingsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { column_mappings: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { column_mappings: records } }));

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      tags: this.fieldsCollectionValid
        ? records.map((record) => ({
            name: escape(record.column_name),
          }))
        : [],
    };
  }

  onSchemaValidityChange(value) {
    this.fieldsCollectionValid = value;
    const valid = this.fieldsCollectionValid && this.connectionStep.valid && this.isFormValid;

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      valid,
      tags: valid
        ? this.records.map((record) => ({
            name: escape(record.column_name),
          }))
        : [],
    };

    this.onValidityChange();
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onApiVersionChange(apiVersion: string) {
    this.onValueChange(apiVersion, 'api_version');
  }

  onSelectErrorsOutputConnection(value: SelectPickerValue) {
    const connection = value as Connection;
    this.onValueChange({ id: connection.id, name: connection.name, type: connection.type }, 'errors_output_connection');
  }

  onValueChange(value: any, key: string) {
    const component: any = { [key]: value };

    if (key === 'operation_type' && value !== 'upsert' && value !== 'update') {
      component.id_field = null;
    }

    if (key === 'id_field') {
      this.idFieldErrorMsg = '';
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: component,
      }),
    );
    this.store.dispatch(updateComponent({ component }));

    setTimeout(() => {
      this.componentBaseStep = {
        ...this.componentBaseStep,
        tags: this.componentFormTagsService.getTags(component, this.component),
      };
    });
  }

  onFieldsLoadStart() {
    this.schemaReady = false;
    this.schemaMappingStep.lock = false;

    setTimeout(() => {
      this.areFieldsLoading = true;
    });
  }

  checkUpsertKey(fields) {
    if (
      (this.rawComponent.operation_type === 'upsert' || this.rawComponent.operation_type === 'update') &&
      this.rawComponent.id_field
    ) {
      const upsertKeyRecord = fields.find((field) => field.name === this.rawComponent.id_field);
      if (!upsertKeyRecord) {
        this.idFieldErrorMsg = this.translate.instant('salesforce-destination-editor.form.errors.id_field', {
          field: this.rawComponent.id_field,
        });
        this.onValueChange('', 'id_field');
        setTimeout(() => {
          if (this.form.controls.id_field) {
            this.form.controls.id_field.markAsDirty();
          }
        });
      }
    }
  }

  onFieldsLoadEnd({ fields }) {
    const destinationSchema: any = {};

    destinationSchema.id = 'salesforce_fields';
    destinationSchema.name = 'salesforce_fields';
    destinationSchema.fields = [];

    this.idOptions = [];

    fields.forEach((field) => {
      destinationSchema.fields.push({
        name: field.name,
      });
      if (field && field.is_keyable) {
        this.idOptions.push({ text: field.name, value: field.name });
      }
    });

    setTimeout(() => {
      this.schemas = [this.parentSchemas[0], destinationSchema];
      this.fields = fields;

      this.schemaReady = true;
      this.schemaMappingStep.lock = false;

      this.checkUpsertKey(fields);

      this.areFieldsLoading = false;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
