import { Injectable } from '@angular/core';
import { forIn, has } from 'lodash';
import { combineLatestWith, map, mergeMap, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VariablesResource } from '../../account/resources/variables.resource';
import { NotifyService } from '../../common/services/notify.service';
import { VariableItem, Variables } from '../../package-designer/package.models';
import { PermissionsService } from '../../common/services/permissions.service';

export interface PredefinedVariableItem {
  key: string;
  help: string;
}

const predefined = [
  {
    key: '_ACCOUNT_ID',
    help: 'The internal id of the account under which the package and job were created.',
  },
  {
    key: '_ACCOUNT_ID_S3_ESCAPED',
    help: 'The S3 escaped internal id of the account under which the package and job were created.﻿',
  },
  {
    key: '_ACCOUNT_NAME',
    help: 'The name of the account under which the package and job were created.',
  },
  {
    key: '_ACCOUNT_NAME_S3_ESCAPED',
    help: 'The S3 escaped name of the account under which the package and job were created.',
  },
  {
    key: '_CLUSTER_ID',
    help: 'The ID of the cluster on which the job is running.',
  },
  {
    key: '_CLUSTER_ID_S3_ESCAPED',
    help: 'The S3 escaped ID of the cluster on which the job is running.',
  },
  {
    key: '_CLUSTER_NODES_COUNT',
    help: 'The number of nodes in the cluster that executes the job.',
  },
  {
    key: '_JOB_ID',
    help: 'Xplenty Job Identifier.',
  },
  {
    key: '_JOB_ID_S3_ESCAPED',
    help: 'S3 escaped Xplenty Job Identifier.',
  },
  {
    key: '_JOB_SUBMISSION_TIMESTAMP',
    help: 'ISO-8601 date-time value of the time the job was submitted in UTC. For example: 2013-04-22T14:18:17Z',
  },
  {
    key: '_JOB_SUBMISSION_TIMESTAMP_S3_ESCAPED',
    help: 'S3 escaped date-time value of the time the job was submitted in UTC. For example: 2013-01-09T14-52-21Z',
  },
  {
    key: '_JOB_SUBMITTER_EMAIL',
    help: 'The email address of the user who submitted the job. For example: helpdesk@xplenty.com',
  },
  {
    key: '_JOB_SUBMITTER_EMAIL_S3_ESCAPED',
    help: 'The S3 escaped email address of the user who submitted the job. For example: helpdesk-xplenty-com',
  },
  {
    key: '_PACKAGE_LAST_SUCCESSFUL_JOB_SUBMISSION_TIMESTAMP',
    help: '',
  },
  {
    key: '_PACKAGE_OWNER_EMAIL',
    help: 'The email address of the user who owns the package.',
  },
  {
    key: '_PACKAGE_OWNER_EMAIL_S3_ESCAPED',
    help: 'The S3 escaped email address of the user who owns the package.',
  },
];

export interface VariablesData {
  systemVariables: VariableItem[];
  userVariables: VariableItem[];
  globalVariables: VariableItem[];
  secretVariables: VariableItem[];
  predefinedVariables: PredefinedVariableItem[];
}

@Injectable({
  providedIn: 'root',
})
export class VariablesService {
  systems: Variables = null;

  constructor(
    private variablesResource: VariablesResource,
    private notifyService: NotifyService,

    private permissionsService: PermissionsService,
  ) {}

  getSystems(): Observable<Variables> {
    if (this.systems) {
      return of(this.systems);
    } else {
      return this.variablesResource.get().pipe(
        tap((items) => {
          this.systems = items;
        }),
        catchError(() => {
          this.notifyService.error('Failed to load system variables');
          return of({});
        }),
      );
    }
  }

  getGlobals(): Observable<Variables> {
    return this.permissionsService.hasPermission$('viewGlobalVariables').pipe(
      mergeMap((hasPermission) => {
        if (hasPermission) {
          return this.variablesResource.getGlobalVariables().pipe(
            map((response) => response.global_variables || {}),
            catchError(() => {
              this.notifyService.error('Failed to load global variables');
              return of({});
            }),
          );
        }
        return of({});
      }),
    );
  }

  separateVariables(
    vars: Variables,
    defaultVars: Variables,
    usePackageDefault: boolean,
    secretVariables: Variables,
  ): Observable<VariablesData> {
    return this.getSystems().pipe(
      combineLatestWith(this.getGlobals()),
      map(([systemVariables, globalVariables]) => {
        const result: VariablesData = {
          systemVariables: [],
          userVariables: [],
          globalVariables: [],
          predefinedVariables: predefined,
          secretVariables: [],
        };

        forIn(vars, (value, key) => {
          if (has(systemVariables, key)) {
            return;
          }
          result.userVariables.push({
            key,
            value,
            // eslint-disable-next-line no-nested-ternary
            default: usePackageDefault ? (has(defaultVars, key) ? defaultVars[key] : value) : value,
          });
        });

        forIn(systemVariables, (value, key) => {
          result.systemVariables.push({
            key,
            value: has(vars, key) ? vars[key] : value,
            default: value, // usePackageDefault ? (_.has(vars, key) ? vars[key] : value) : value,
            type: 'string',
          });
        });

        forIn(globalVariables, (value, key) => {
          result.globalVariables.push({
            key,
            value: value,
            default: value,
            type: 'string',
          });
        });

        forIn(secretVariables, (value, key) => {
          result.secretVariables.push({
            key,
            value: value,
            default: value,
            type: 'string',
          });
        });

        return result;
      }),
    );
  }

  combineVariables(
    users: VariableItem[],
    systems: VariableItem[],
    secrets: VariableItem[],
  ): Observable<{ variables: Variables; secretVariables: Variables }> {
    return this.getSystems().pipe(
      map((systemVariables) => {
        const variables: Variables = {};
        const secretVariables: Variables = {};

        users.forEach((v) => {
          variables[v.key] = v.value;
        });
        systems.forEach((v) => {
          if (v.value !== systemVariables[v.key]) {
            variables[v.key] = v.value;
          }
        });

        secrets.forEach((v) => {
          secretVariables[v.key] = v.value;
        });
        return { variables, secretVariables };
      }),
    );
  }
}
