import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule as AgnularCommonModule } from '@angular/common';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import './validators';
import { validatorsReducer } from './validators/store/validators.reducer';
import { XpFormGroupComponent } from './validators/components/xp-form-group.component';
import { XpFormValidationComponent } from './validators/components/xp-form-validation.component';
import { XpInputComponent } from './components/forms/xp-input.component';
import { XpIconComponent } from './components/xp-icon.component';
import { XpSelectComponent } from './components/forms/xp-select.component';
import { XpChipsComponent } from './components/forms/xp-chips.component';
import { XpTimezonePickerComponent } from './components/forms/xp-timezone-picker.component';
import { NotifyTemplateComponent } from './services/components/notify-template.component';
import './services/fields-collection-validations.service';
import './services/assisted-search/assisted-search-workspaces-configurator.service';
import { XpPasswordStrengthComponent } from './components/forms/xp-password-strength.component';
import { DialogTemplateComponent } from './services/components/dialog-template.component';
import { XpLoaderComponent } from './components/xp-loader.component';
import { ListItemSnippetComponent } from './components/lists/list-item-snippet.component';
import { GenericListComponent } from './components/lists/generic-list.component';
import { XpDatePipe } from './pipes/xp-date.pipe';
import { XpPrettyDatePipe } from './pipes/xp-pretty-date.pipe';
import { XpModalComponent } from './components/modals/xp-modal.component';
import { XpLengthCheckPipe } from './pipes/xp-length-check.pipe';
import { GenericListEmptyComponent } from './components/lists/generic-list-empty.component';
import { ErrorsNotifyComponent } from './components/errors-notify.component';
import { XpDateUtcPipe } from './pipes/xp-date-utc.pipe';
import { XpSelectPickerComponent } from './components/forms/select-picker/xp-select-picker.component';
import { XpSelectPickerEditableComponent } from './components/forms/select-picker/xp-select-picker-editable.component';
import { XpSelectPickerPackageComponent } from './components/forms/select-picker/select-picker-package.component';
import { selectPickerReducer } from './store/select-picker.reducer';
import { SelectPickerEffects } from './store/select-picker.effects';
import { XpHighLightPipe } from './pipes/xp-highlight.pipe';
import { XpSelectPickerClusterComponent } from './components/forms/select-picker/select-picker-cluster.component';
import { XpSelectPickerSchedulePackageVersionComponent } from './components/forms/select-picker/select-picker-schedule-package-version.component';
import { XpSelectPickerWorkspaceComponent } from './components/forms/select-picker/select-picker-workspace.component';
import { XpSelectPickerPackageVersionComponent } from './components/forms/select-picker/select-picker-package-version.component';
import { XpSelectPickerConnectionComponent } from './components/forms/select-picker/select-picker-connection.component';
import { XpSubmitButtonComponent } from './components/forms/xp-submit-button.component';
import { XpNameDescriptionEditorComponent } from './components/forms/xp-name-description-editor.component';
import { XpStepsComponent } from './components/xp-steps.component';
import { XpStepComponent } from './components/xp-step.component';
import { XpStepIndexComponent } from './components/xp-step-index.component';
import { XpPackageComponent } from './components/xp-package.component';
import { AssistedSearchComponent } from './components/lists/assisted-search/assisted-search.component';
import { AssistedSearchHelpComponent } from './components/lists/assisted-search/assisted-search-help.component';
import { AssistedSearchModifierDirective } from './components/lists/assisted-search/assisted-search-modifier.directive';
import { AssistedSearchPopupDateComponent } from './components/lists/assisted-search/assisted-search-popup-date.component';
import { AssistedSearchPopupListComponent } from './components/lists/assisted-search/assisted-search-popup-list.component';
import { AssistedSearchPopupThumbsListComponent } from './components/lists/assisted-search/assisted-search-popup-thumbs-list.component';
import { XpInputCheckboxComponent } from './components/forms/xp-input-checkbox.component';
import { XpIndexPipe } from './pipes/xp-index.pipe';
import { XpDateWithTimePipe } from './pipes/xp-date-with-time.pipe';
import { XpFormatTimePipe } from './pipes/xp-format-time.pipe';
import { XpCalendarPipe } from './pipes/xp-calendar.pipe';
import { XpNumberTrackerComponent } from './components/xp-number-tracker.component';
import { TemplateNameDirective } from './components/template-name.directive';
import { ComponentIconComponent } from './components/component-icon.component';
import { JsonExplorerComponent } from './components/json-explorer.component';
import { ClusterFormComponent } from './components/forms/cluster-form.component';
import { JobFormComponent } from './components/forms/job-form.component';
import { ClusterCatComponent } from './components/forms/cluster-cat.component';
import { VariablesEditorComponent } from './components/forms/variables-editor.component';
import { VariablesEditorRowComponent } from './components/forms/variables-editor-row.component';
import { PackageVariablesEditorComponent } from './components/forms/package-variables-editor.component';
import { ExpressionEditorIconComponent } from './components/forms/expression-editor-icon.component';
import { HelpMenuComponent } from './components/help-menu.component';
import { LiveSupportBtnComponent } from './components/live-support-btn.component';
import { SidebarComponent } from './components/sidebar.component';
import { AccountsMenuComponent } from './components/accounts-menu.component';
import { SuccessNotifyComponent } from './components/success-notify.component';
import { ShowNullValuesPipe } from './pipes/show-null-values.pipe';
import { AppsToolbarComponent } from './components/apps-toolbar.component';
import { PackageFormComponent } from './components/forms/package-form.component';
import { PackageVersionFormComponent } from './components/forms/package-version-form.component';
import { ShortcutsComponent } from './components/shortcuts.component';
import { ShortcutSymbolsPipe } from './pipes/shortcut-symbols.pipe';
import { appCommonReducer } from './store/app-common.reducer';
import { OnboardingToolbarComponent } from './components/onboarding-toolbar.component';
import { XpExpressionEditorComponent } from './components/xp-expression-editor.component';
import { HelpModule } from '../help/help.module';
import { AuthenticationGuard } from './services/authentication.guard';
import { AuthorizationGuard } from './services/authorization.guard';
import { IconsSpriteComponent } from './components/icons-sprite.component';
import { IntegrateioNotificationComponent } from './components/integrateio-notification.component';
import { IntegrateioTemplateComponent } from './services/components/integrateio-template.component';
import { MatSelectInfiniteScrollDirective } from './components/mat-select-infinite-scroll.directive';
import { CommonIconComponent } from './components/common-icon.component';
import { SidebarSubComponent } from './components/sidebar-sub.component';
import { AiTransformationsDialogComponent } from './services/components/ai-transformations-dialog.component';
import { XpTimePickerComponent } from './components/xp-time-picker.component';
import { UnlockFeaturesDialogService } from './services/unlock-features-dialog.service';
import { UnlockFeaturesDialogTemplateComponent } from './services/components/unlock-features-dialog-template.component';

@NgModule({
  declarations: [
    ClusterFormComponent,
    ClusterCatComponent,
    VariablesEditorComponent,
    VariablesEditorRowComponent,
    PackageVariablesEditorComponent,
    ExpressionEditorIconComponent,
    JobFormComponent,
    PackageFormComponent,
    PackageVersionFormComponent,
    XpFormGroupComponent,
    XpFormValidationComponent,
    AppsToolbarComponent,
    XpInputComponent,
    XpInputCheckboxComponent,
    XpIconComponent,
    SidebarComponent,
    SidebarSubComponent,
    AccountsMenuComponent,
    HelpMenuComponent,
    LiveSupportBtnComponent,
    ComponentIconComponent,
    JsonExplorerComponent,
    XpSelectComponent,
    XpChipsComponent,
    XpTimezonePickerComponent,
    XpNumberTrackerComponent,
    NotifyTemplateComponent,
    XpPasswordStrengthComponent,
    XpLoaderComponent,
    ListItemSnippetComponent,
    GenericListComponent,
    ErrorsNotifyComponent,
    SuccessNotifyComponent,
    XpModalComponent,
    DialogTemplateComponent,
    UnlockFeaturesDialogTemplateComponent,
    AiTransformationsDialogComponent,
    IntegrateioTemplateComponent,
    ShortcutSymbolsPipe,
    XpDatePipe,
    XpDateUtcPipe,
    XpFormatTimePipe,
    XpCalendarPipe,
    XpDateWithTimePipe,
    XpPrettyDatePipe,
    XpIndexPipe,
    XpLengthCheckPipe,
    XpHighLightPipe,
    ShowNullValuesPipe,
    GenericListEmptyComponent,
    XpSelectPickerComponent,
    XpSelectPickerPackageComponent,
    XpSelectPickerConnectionComponent,
    XpSelectPickerClusterComponent,
    XpSelectPickerPackageVersionComponent,
    XpSelectPickerSchedulePackageVersionComponent,
    XpSelectPickerWorkspaceComponent,
    XpSelectPickerEditableComponent,
    XpSubmitButtonComponent,
    XpNameDescriptionEditorComponent,
    XpPackageComponent,
    XpStepsComponent,
    XpStepComponent,
    XpStepIndexComponent,
    AssistedSearchComponent,
    AssistedSearchHelpComponent,
    AssistedSearchModifierDirective,
    AssistedSearchPopupDateComponent,
    AssistedSearchPopupListComponent,
    AssistedSearchPopupThumbsListComponent,
    ShortcutsComponent,
    OnboardingToolbarComponent,
    XpExpressionEditorComponent,
    TemplateNameDirective,
    IconsSpriteComponent,
    IntegrateioNotificationComponent,
    MatSelectInfiniteScrollDirective,
    CommonIconComponent,
    XpTimePickerComponent,
  ],
  exports: [
    IntegrateioTemplateComponent,
    ClusterFormComponent,
    ClusterCatComponent,
    VariablesEditorComponent,
    VariablesEditorRowComponent,
    PackageVariablesEditorComponent,
    ExpressionEditorIconComponent,
    JobFormComponent,
    PackageFormComponent,
    PackageVersionFormComponent,
    XpFormGroupComponent,
    XpFormValidationComponent,
    XpInputComponent,
    XpInputCheckboxComponent,
    XpIconComponent,
    SidebarComponent,
    HelpMenuComponent,
    LiveSupportBtnComponent,
    ComponentIconComponent,
    JsonExplorerComponent,
    XpSelectComponent,
    XpChipsComponent,
    XpTimezonePickerComponent,
    XpNumberTrackerComponent,
    XpPasswordStrengthComponent,
    XpLoaderComponent,
    ListItemSnippetComponent,
    GenericListComponent,
    ErrorsNotifyComponent,
    SuccessNotifyComponent,
    XpModalComponent,
    ShortcutSymbolsPipe,
    XpDatePipe,
    XpDateUtcPipe,
    XpFormatTimePipe,
    XpCalendarPipe,
    XpDateWithTimePipe,
    XpPrettyDatePipe,
    XpIndexPipe,
    XpLengthCheckPipe,
    XpHighLightPipe,
    ShowNullValuesPipe,
    XpSelectPickerEditableComponent,
    XpSubmitButtonComponent,
    XpNameDescriptionEditorComponent,
    XpPackageComponent,
    XpStepsComponent,
    XpStepComponent,
    TemplateNameDirective,
    GenericListEmptyComponent,
    ShortcutsComponent,
    OnboardingToolbarComponent,
    IconsSpriteComponent,
    XpExpressionEditorComponent,
    IntegrateioNotificationComponent,
    CommonIconComponent,
    XpTimePickerComponent,
  ],
  imports: [
    BrowserModule,
    StoreModule.forFeature('validation', validatorsReducer),
    FormsModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    TranslateModule,
    MatSnackBarModule,
    MatDialogModule,
    OverlayModule,
    NgxPermissionsModule,
    StoreModule.forFeature('selectPicker', selectPickerReducer),
    StoreModule.forFeature('appCommon', appCommonReducer),
    EffectsModule.forFeature([SelectPickerEffects]),
    MatMenuModule,
    MatSliderModule,
    DragDropModule,
    HelpModule,
    RouterModule,
    AgnularCommonModule,
    ReactiveFormsModule,
  ],
  providers: [AuthenticationGuard, AuthorizationGuard, provideHttpClient(withInterceptorsFromDi())],
})
export class CommonModule {}
