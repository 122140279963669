import { createAction, props } from '@ngrx/store';
import { Cluster } from '../clusters.models';
import { ValidationError } from '../../config/validation-error.model';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

export const openClustersModal = createAction('[Clusters] Open modal');
export const closeClustersModal = createAction('[Clusters] Close modal');

export const getClustersList = createAction('[Clusters] Get clusters list', props<{ params: ListParams }>());
export const getClustersListResponse = createAction(
  '[Clusters] Get clusters list response',
  props<{ clusters: Cluster[] }>(),
);
export const getClustersListError = createAction(
  '[Clusters] Get clusters list error',
  props<{ errors: ValidationError[] }>(),
);

export const searchClustersList = createAction('[Clusters] Search clusters list', props<{ params: ListParams }>());
export const searchClustersListResponse = createAction(
  '[Clusters] Search clusters list response',
  props<{ clusters: Cluster[] }>(),
);

export const loadMoreClustersList = createAction('[Clusters] Load more clusters list', props<{ params: ListParams }>());
export const loadMoreClustersListResponse = createAction(
  '[Clusters] Load more clusters list response',
  props<{ clusters: Cluster[] }>(),
);

export const saveCluster = createAction('[Clusters] Save cluster', props<{ cluster: Partial<Cluster> }>());
export const saveClusterResponse = createAction(
  '[Clusters] Save cluster response',
  props<{ data: Cluster; closeModal?: boolean }>(),
);
export const saveClusterError = createAction('[Clusters] Save cluster error', props<{ errors: ValidationError[] }>());

export const updateCluster = createAction(
  '[Clusters] Update cluster',
  props<{ cluster: Partial<Cluster>; params: ListParams; clusterId: number }>(),
);
export const updateClusterResponse = createAction(
  '[Clusters] Update cluster response',
  props<{ data: Cluster; closeModal?: boolean }>(),
);
export const updateClusterError = createAction(
  '[Clusters] Update cluster error',
  props<{ errors: ValidationError[] }>(),
);

export const removeCluster = createAction('[Clusters] Remove cluster', props<{ clusterId: number }>());
export const removeClusterItem = createAction('[Clusters] Remove cluster item', props<{ data: Cluster }>());
export const removeClusterResponse = createAction('[Clusters] Remove cluster response', props<{ data: Cluster }>());
export const removeClusterError = createAction(
  '[Clusters] Remove cluster error',
  props<{ errors: ValidationError[] }>(),
);
