import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay, mergeWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  getHook,
  getHookEvents,
  getHookEventsResponse,
  getHookResponse,
  getHooksList,
  getHooksListError,
  getHooksListResponse,
  getHookTypes,
  getHookTypesResponse,
  loadMoreHooksList,
  loadMoreHooksListResponse,
  pingHook,
  pingHookClear,
  pingHookError,
  pingHookResponse,
  removeHook,
  removeHookError,
  removeHookItem,
  removeHookResponse,
  saveHook,
  saveHookError,
  saveHookResponse,
  updateHook,
  updateHookError,
  updateHookResponse,
} from './hooks.actions';
import { HooksResource } from '../../resources/hooks.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../../constants/animation-constants';
import { NotifyService } from '../../../common/services/notify.service';
import { getErrorFromResponse } from '../../../common/helper/response.helpers';

@Injectable()
export class HooksEffects {
  getHook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHook),
      switchMap(({ hookId }) =>
        this.hooksResource.get(hookId).pipe(
          map((hook) => getHookResponse({ hook })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getHooksListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getHookTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHookTypes),
      switchMap(() =>
        this.hooksResource.types().pipe(
          map((hookTypes) => getHookTypesResponse({ hookTypes })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getHooksListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getHookEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHookEvents),
      switchMap(() =>
        this.hooksResource.hookEvents().pipe(
          map((hookEvents) => getHookEventsResponse({ hookEvents })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getHooksListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getHooks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHooksList),
      switchMap(({ params }) =>
        this.hooksResource.query(params).pipe(
          map((hooks) => getHooksListResponse({ hooks })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getHooksListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreHooks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreHooksList),
      switchMap(({ params }) =>
        this.hooksResource.query(params).pipe(
          map((hooks) => loadMoreHooksListResponse({ hooks })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getHooksListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveHook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveHook),
      switchMap(({ hook }) =>
        this.hooksResource.save(hook).pipe(
          map((hookResponse) => saveHookResponse({ data: hookResponse })),
          catchError((response) => of(saveHookError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  updateHook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateHook),
      switchMap(({ hook }) =>
        this.hooksResource.update(hook.id, hook).pipe(
          map((hookResponse) => updateHookResponse({ data: hookResponse })),
          catchError((response) => of(updateHookError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeHook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeHook),
      switchMap(({ hookId }) =>
        this.hooksResource.remove(hookId).pipe(
          map((hookResponse) => removeHookItem({ data: hookResponse })),
          catchError((response) => of(removeHookError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeHookResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeHookItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeHookResponse({ data })),
    ),
  );

  pingHook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pingHook),
      switchMap(({ hookId }) =>
        this.hooksResource.ping(hookId).pipe(
          map((hookResponse) => pingHookResponse({ hook: hookResponse })),
          mergeWith(of(pingHookClear({ hookId })).pipe(delay(5000))),
          catchError((response) => of(pingHookError({ error: response.message, hookId }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private hooksResource: HooksResource,
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}
}
