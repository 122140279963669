import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AnyComponentData } from '../../package.models';
import { AppState } from '../../../store';
import { updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';

@Component({
  selector: 'component-name-editor',
  template: `
    <xp-form-validation type="Component">
      <form name="formComponentName" novalidate class="component-name-editor-new" #form="ngForm">
        <i class="fa fa-pencil component-name-editor-icon-edit"></i>
        <i class="fa fa-exclamation-circle component-name-editor-icon-info"></i>
        <xp-form-group>
          <label class="hidden">Component name</label>
          <xp-input
            id="component-name"
            name="name"
            [ngModel]="rawComponent.name"
            (ngModelChange)="onNameChange($event)"
            placeholder="Enter component name"
          ></xp-input>
        </xp-form-group>
      </form>
    </xp-form-validation>
  `,
})
export class ComponentNameEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: AnyComponentData;
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'formComponentName';
  successMessageText = '';

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  onNameChange(name: string) {
    this.store.dispatch(updateRawComponent({ rawComponent: { name } }));
  }
}
