import { getDataFromComponent } from '../../../package-designer/helpers/components.helpers';
import { ValidatorRunner } from './models';

export const UniqueComponentNameValidator: ValidatorRunner = {
  name: 'UniqueComponentNameValidator',
  message: 'errors.UniqueComponentNameValidator',
  order: 2,
  validate({ value, store }) {
    const components = (store.packageDesigner.package.components || [])
      .map(getDataFromComponent)
      .filter((item) => item && item.name === value && item.name !== store.packageDesigner.component?.name);

    return components.length === 0;
  },
};
