import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Key } from '../key.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KeysResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  get(key_id: number, params?: ListParams): Observable<Key> {
    return this.http.get<Key>(`${this.apiRoot}/user/keys/${key_id}`, { params: params as QueryParams });
  }

  save(body: Partial<Key>, params?: ListParams): Observable<Key> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Key>(`${this.apiRoot}/user/keys`, { ...body, account_id }, { params: params as QueryParams }),
      ),
    );
  }

  update(key_id: number, body: Key, params?: ListParams): Observable<Key> {
    return this.http.put<Key>(`${this.apiRoot}/user/keys/${key_id}`, body, { params: params as QueryParams });
  }

  remove(key_id: number, params?: ListParams): Observable<any> {
    return this.http.delete<Key>(`${this.apiRoot}/user/keys/${key_id}`, { params: params as QueryParams });
  }

  query(params?: ListParams): Observable<Key[]> {
    return this.http.get<Key[]>(`${this.apiRoot}/user/keys`, { params: params as QueryParams });
  }

  search(params?: ListParams): Observable<Key[]> {
    return this.http.get<Key[]>(`${this.apiRoot}/user/keys/search`, { params: params as QueryParams });
  }
}
