import { Component } from '@angular/core';

@Component({
  selector: 'error-404',
  template: `
    <alert-box
      [data]="{
        icon: 'icon-general-404',
        title: 'response.404.title' | translate,
        description: 'response.404.message' | translate
      }"
    ></alert-box>
  `,
})
export class Error404Component {}
