import { Injectable } from '@angular/core';

import { forOwn } from 'lodash';
import Pusher from 'pusher-js/with-encryption';
import * as PusherTypes from 'pusher-js';

import { ActionCreator, Store } from '@ngrx/store';
import { componentPreviewerLog } from '../../package-designer/store/component-previewer.actions';
import { removeHookResponse, saveHookResponse, updateHookResponse } from '../../settings/store/hooks/hooks.actions';
import { removeKeyResponse, saveKeyResponse } from '../../settings/store/keys/keys.actions';
import { saveAccountResponse } from '../../settings/store/accounts/accounts.actions';
import {
  removeMemberResponse,
  saveMemberResponse,
  updateMemberResponse,
} from '../../settings/store/members/members.actions';
import {
  removeWorkspaceResponse,
  saveWorkspaceResponse,
  updateWorkspaceResponse,
} from '../../workspaces/store/workspaces.actions';
import {
  removeClusterResponse,
  saveClusterResponse,
  updateClusterResponse,
} from '../../clusters/store/clusters.actions';
import {
  removeScheduleResponse,
  saveScheduleResponse,
  updateScheduleResponse,
} from '../../schedules/store/schedules.actions';
import {
  removePackageResponse,
  savePackageResponse,
  updatePackageResponse,
} from '../../packages/store/packages.actions';
import { removeJobResponse, saveJobResponse, updateJobResponse } from '../../jobs/store/jobs.actions';
import {
  removeConnectionResponse,
  saveConnectionResponse,
  updateConnectionResponse,
  setConnectionOAuthToken,
} from '../../connections/store/connections.actions';
import { AuthService } from './auth.service';
import { AppState } from '../../store';
import { TitleAlertService } from './title-alert.service';
import { VisibilityService } from './visibility.service';
import { environment } from '../../../environments/environment';

const COMPONENT_PREVIEW_UPDATED = 'updated.componentpreview';
const COMPONENT_PREVIEW_CREATED = 'created.componentpreview';

const CREATED_HOOK = 'created.hook';
const UPDATED_HOOK = 'updated.hook';
const DELETED_HOOK = 'deleted.hook';

const CREATED_KEY = 'created.key';
const UPDATED_KEY = 'updated.key';
const DELETED_KEY = 'deleted.key';

const CREATED_ACCOUNT = 'created.account';
const UPDATED_ACCOUNT = 'updated.account';
const DELETED_ACCOUNT = 'deleted.account';

const CREATED_USER = 'created.user';
const UPDATED_USER = 'updated.user';
const DELETED_USER = 'deleted.user';

const CREATED_WORKSPACE = 'created.workspace';
const UPDATED_WORKSPACE = 'updated.workspace';
const DELETED_WORKSPACE = 'deleted.workspace';

const CREATED_CLUSTER = 'created.cluster';
const UPDATED_CLUSTER = 'updated.cluster';
const DELETED_CLUSTER = 'deleted.cluster';

const CREATED_SCHEDULE = 'created.schedule';
const UPDATED_SCHEDULE = 'updated.schedule';
const DELETED_SCHEDULE = 'deleted.schedule';

const CREATED_PACKAGE = 'created.package';
const UPDATED_PACKAGE = 'updated.package';
const DELETED_PACKAGE = 'deleted.package';

const CREATED_JOB = 'created.job';
const UPDATED_JOB = 'updated.job';
const DELETED_JOB = 'deleted.job';

const CREATED_CONNECTION = 'created.connection';
const UPDATED_CONNECTION = 'updated.connection';
const DELETED_CONNECTION = 'deleted.connection';

const OAUTH_TOKEN = 'oauth.token';

interface PayloadAction {
  data: any;
  shouldNotModifyFormData?: boolean;
}

interface PusherActionsI {
  [key: string]: (payload: PayloadAction) => ActionCreator<any, any>;
}

const PUSHER_ACTIONS: PusherActionsI = {
  [COMPONENT_PREVIEW_UPDATED]: componentPreviewerLog,
  [COMPONENT_PREVIEW_CREATED]: componentPreviewerLog,

  [CREATED_HOOK]: saveHookResponse,
  [UPDATED_HOOK]: updateHookResponse,
  [DELETED_HOOK]: removeHookResponse,

  [CREATED_KEY]: saveKeyResponse,
  [DELETED_KEY]: removeKeyResponse,

  [CREATED_ACCOUNT]: saveAccountResponse,
  [DELETED_ACCOUNT]: removeMemberResponse,

  [CREATED_USER]: saveMemberResponse,
  [UPDATED_USER]: updateMemberResponse,
  [DELETED_USER]: removeMemberResponse,

  [CREATED_WORKSPACE]: saveWorkspaceResponse,
  [UPDATED_WORKSPACE]: updateWorkspaceResponse,
  [DELETED_WORKSPACE]: removeWorkspaceResponse,

  [CREATED_CLUSTER]: saveClusterResponse,
  [UPDATED_CLUSTER]: updateClusterResponse,
  [DELETED_CLUSTER]: removeClusterResponse,

  [CREATED_SCHEDULE]: saveScheduleResponse,
  [UPDATED_SCHEDULE]: updateScheduleResponse,
  [DELETED_SCHEDULE]: removeScheduleResponse,

  [CREATED_PACKAGE]: savePackageResponse,
  [UPDATED_PACKAGE]: updatePackageResponse,
  [DELETED_PACKAGE]: removePackageResponse,

  [CREATED_JOB]: saveJobResponse,
  [UPDATED_JOB]: updateJobResponse,
  [DELETED_JOB]: removeJobResponse,

  [CREATED_CONNECTION]: saveConnectionResponse,
  [UPDATED_CONNECTION]: updateConnectionResponse,
  [DELETED_CONNECTION]: removeConnectionResponse,

  [OAUTH_TOKEN]: setConnectionOAuthToken as any,
};

interface EventsMap {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  userClient: Pusher = null;
  userChannel: PusherTypes.Channel = null;
  accountClient: Pusher = null;
  accountChannel: PusherTypes.Channel = null;
  hidden = false;

  USER_EVENTS: EventsMap = {
    CREATED_USER,
    UPDATED_USER,
    DELETED_USER,

    CREATED_KEY,
    UPDATED_KEY,
    DELETED_KEY,
  };

  ACCOUNT_EVENTS: EventsMap = {
    CREATED_ACCOUNT,
    UPDATED_ACCOUNT,
    DELETED_ACCOUNT,

    CREATED_CONNECTION,
    UPDATED_CONNECTION,
    DELETED_CONNECTION,

    CREATED_CLUSTER,
    UPDATED_CLUSTER,
    DELETED_CLUSTER,

    CREATED_PACKAGE,
    UPDATED_PACKAGE,
    DELETED_PACKAGE,

    CREATED_JOB,
    UPDATED_JOB,
    DELETED_JOB,

    CREATED_SCHEDULE,
    UPDATED_SCHEDULE,
    DELETED_SCHEDULE,

    CREATED_HOOK,
    UPDATED_HOOK,
    DELETED_HOOK,

    CREATED_JOB_VALIDATION: 'created.job_validation',
    UPDATED_JOB_VALIDATION: 'updated.job_validation',
    DELETED_JOB_VALIDATION: 'deleted.job_validation',

    CREATED_WORKSPACE,
    UPDATED_WORKSPACE,
    DELETED_WORKSPACE,

    COMPONENT_PREVIEW_CREATED,
    COMPONENT_PREVIEW_UPDATED,

    OAUTH_TOKEN,
  };

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,

    private titleAlert: TitleAlertService,
    private visibility: VisibilityService,
  ) {
    this.hidden = this.visibility.currentState();
    this.visibility.onChange((state: string) => {
      this.hidden = state === 'hidden';
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private unsubscribe(client: Pusher, channel: PusherTypes.Channel, events: EventsMap) {
    if (client && channel) {
      // unbind all events
      forOwn(events, function (event) {
        channel.unbind(event);
      });
      // and unsubscribe from channel
      client.unsubscribe(channel.name);
    }
  }

  private connect(authUrl: string): Pusher {
    return new Pusher(environment.PUSHER_PUBLIC_KEY, {
      authEndpoint: authUrl,
      auth: {
        headers: {
          Accept: 'application/vnd.xplenty+json; version=2',
          'Content-Type': 'application/x-www-form-urlencoded, application/json',
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      },
      cluster: 'mt1',
    });
  }

  private notifyUserAboutPossibleUpdate(e: string) {
    if (this.hidden) {
      this.titleAlert.notify(e.split('.').join(' ').toUpperCase(), {
        requireBlur: true,
        stopOnFocus: true,
        duration: 10000,
        interval: 500,
      });
    }
  }

  public initUser(id: number) {
    // we already have connected userChannel
    this.unsubscribe(this.userClient, this.userChannel, this.USER_EVENTS);

    // authorize new user userClient
    this.userClient = this.connect(`${environment.API_URL}/user/pusher/auth`);

    // subscribe to private userChannel
    const name = `private-user-${id}-broadcast`;
    this.userChannel = this.userClient.subscribe(name);

    forOwn(this.USER_EVENTS, (event) => {
      this.userChannel.bind(event, (data) => {
        const action = PUSHER_ACTIONS[event];
        if (action) {
          this.store.dispatch(action({ data: data.resource, shouldNotModifyFormData: true }));
        }
      });
    });
  }

  public unsubscribeUser() {
    this.unsubscribe(this.userClient, this.userChannel, this.USER_EVENTS);
  }

  public initAccount(idNumber, accountId) {
    // we already have connected accountClient
    this.unsubscribe(this.accountClient, this.accountChannel, this.ACCOUNT_EVENTS);

    // authorize new user this.accountClient
    this.accountClient = this.connect(`${environment.API_URL}/${accountId}/api/pusher/auth`);

    // subscribe to private this.accountChannel
    const name = `private-account-${idNumber}-broadcast`;
    this.accountChannel = this.accountClient.subscribe(name);

    forOwn(this.ACCOUNT_EVENTS, (event) => {
      this.accountChannel.bind(event, (data) => {
        const action = PUSHER_ACTIONS[event];
        if (action && data.resource) {
          this.store.dispatch(action({ data: data.resource, shouldNotModifyFormData: true }));
        } else if (action && data) {
          this.store.dispatch(action({ ...data, shouldNotModifyFormData: true }));
        }
        this.notifyUserAboutPossibleUpdate(event);
      });
    });
  }

  public unsubscribeAccount() {
    this.unsubscribe(this.accountClient, this.accountChannel, this.ACCOUNT_EVENTS);
  }
}
