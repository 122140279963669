import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import {
  ConditionOperator,
  ConditionOperatorHandler,
  CONDITION_OPERATOR,
  ONE_SIDED_CONDITION_OPERATORS,
} from '../../../../constants/conditions_operator_picker';
import { Schema } from '../../../package.models';

@Component({
  selector: 'filter-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['left', 'operator', 'right']"
    >
      <ng-template templateName="left" let-item>
        <xp-field-expression-editor
          [value]="item.record.left"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [index]="item.index"
          [focusedProp]="item.focusedProp"
          propName="left"
          (fieldChange)="onFieldChange($event, item.record, 'left')"
          class="fields-collection-editor"
        ></xp-field-expression-editor>
      </ng-template>
      <ng-template templateName="left-header" let-item>
        {{ 'fields-collection.headers.field-expression-editor' | translate }}
      </ng-template>

      <ng-template templateName="operator" let-item>
        <xp-condition-operator-picker
          [value]="item.record.operator"
          [index]="item.index"
          (fieldChange)="onFieldChange($event, item.record, 'operator')"
          class="fields-collection-editor"
        ></xp-condition-operator-picker>
      </ng-template>
      <ng-template templateName="operator-header" let-item>
        {{ 'fields-collection.headers.condition-operator-picker' | translate }}
      </ng-template>

      <ng-template templateName="right" let-item>
        <xp-field-expression-editor
          [value]="item.record.right"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [index]="item.index"
          [focusedProp]="item.focusedProp"
          [type]="getOperatorHandler(item.record.operator)"
          propName="right"
          (fieldChange)="onFieldChange($event, item.record, 'right')"
          class="fields-collection-editor"
        ></xp-field-expression-editor>
      </ng-template>
      <ng-template templateName="right-header" let-item>
        {{ 'filter_component.collection-headers.right' | translate }}
        <i
          class="fa fa-exclamation-circle"
          [matTooltip]="'filter_component.collection-headers.hint_right' | translate"
          matTooltipPosition="above"
        ></i>
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class FilterCollectionComponent implements OnInit {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: CollectionSettings;
  @Input() parentSchemas: Schema[];
  @Input() fieldsName: string;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 10,
      emptyRecord: { left: '', operator: ConditionOperator.teq, right: '', FC_pristine: true },
      parentSchemas: this.parentSchemas,
      addRecord: this.addRecord.bind(this),
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  addRecord(index: number) {
    const newRecord = { ...this.collectionSettings.emptyRecord, id: uuidv4() };
    if (this.recordsCopy[index - 1]) {
      newRecord.operator = this.recordsCopy[index - 1].operator;
    }
    this.recordsCopy.splice(index, 0, newRecord);
    this.recordsCopy = this.recordsCopy.slice();
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { left: record.left, operator: record.operator, right: record.right };
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getOperatorHandler(operator: ConditionOperator): ConditionOperatorHandler {
    if (operator) {
      return CONDITION_OPERATOR.find((item) => item.value === operator).handler;
    }
    return ConditionOperatorHandler.none;
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const expressions = records.map(({ left, right, operator }) => ({ left, right, operator }));
    this.store.dispatch(
      updateComponent({
        component: {
          predicates: {
            expressions,
          },
        },
      }),
    );
    this.recordsChange.emit(expressions);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        const newItem = {
          ...item,
          [prop]: value,
        };

        if (prop === 'operator' && ONE_SIDED_CONDITION_OPERATORS.includes(value as ConditionOperator)) {
          newItem.right = '';
        }

        return newItem;
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
