import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { GoogleCloudStorageV2Connection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';

@Component({
  selector: 'connection-form-gs2',
  template: `
    <xp-form-validation type="GoogleCloudStorageV2Connection" [name]="formName">
      <form id="connectionFormGSV2" name="connectionFormGSV2" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <xp-form-group>
              <label for="host">{{ 'connection-form-bigquery.labels.client_email' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="client_email"
                id="client_email"
                [ngModel]="connection.client_email"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="project_id">Project ID</label>
              <xp-input
                type="text"
                class="form-control"
                name="project_id"
                id="project_id"
                [(ngModel)]="connection.project_id"
                placeholder=""
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="json_key_file">{{ 'connection-form-bigquery.labels.json_key' | translate }}</label>
              <input
                style="display: none"
                type="file"
                (change)="onFileChanged($event)"
                id="json_key_file"
                name="json_key_file"
                #fileInput
              />
              <button class="btn btn-default form-control btn-info" (click)="fileInput.click()">
                {{ selectedFile ? 'Select another file' : 'Select file' }}
              </button>
              <span class="file-name" *ngIf="selectedFile">{{ selectedFile.name | xpLengthCheck : 28 }}</span>
              <span class="error-message" *ngIf="error">{{ error }}</span>
            </xp-form-group>
            <div class="connection-component-bigquery-v2 alert alert-info">
              <strong
                ><i class="fa fa-info-circle" aria-hidden="true"></i>
                {{ 'connection-component-cloud-storage-v2.title' | translate }}</strong
              >
              <p [innerHTML]="'connection-component-cloud-storage-v2.text' | translate"></p>
            </div>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormGSV2Component extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<GoogleCloudStorageV2Connection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormGSV2';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  selectedFile: Blob;
  error: string;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.connection.json_key) {
      this.connection.json_key = {
        type: '',
        project_id: '',
        private_key_id: '',
        client_email: '',
        client_id: '',
        auth_uri: '',
        token_uri: '',
        auth_provider_x509_cert_url: '',
        client_x509_cert_url: '',
        private_key: '',
      };
    }

    if (!this.connection.client_email) {
      this.connection.client_email = this.connection.json_key.client_email;
    }

    if (!this.connection.project_id) {
      this.connection.project_id = this.connection.json_key.project_id;
    }
  }

  onFileChanged(event: Event) {
    this.error = '';

    // eslint-disable-next-line prefer-destructuring
    this.selectedFile = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();

    if (this.selectedFile.type !== 'application/json') {
      this.error = 'Selected file is not a JSON type.';
      this.selectedFile = null;
      return;
    }

    fileReader.readAsText(this.selectedFile, 'UTF-8');

    fileReader.onload = () => {
      this.connection.json_key = JSON.parse(fileReader.result as any);
      this.connection.password = this.connection.json_key.private_key;
      this.connection.client_email = this.connection.json_key.client_email;
      this.connection.project_id = this.connection.json_key.project_id;
      delete this.connection.json_key.private_key;
    };

    fileReader.onerror = (error) => {
      this.error = JSON.stringify(error);
    };
  }
}
