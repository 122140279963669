import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fixComponentData } from '../../helpers/components.helpers';
import { Component as PackageComponent } from '../../package.models';

@Component({
  selector: 'components-order-editor',
  template: `
    <div class="components-order-editor">
      <component-thumbnail [component]="fixComponentData(left)"></component-thumbnail>
      <div class="btn btn-default" (click)="switch()" [ngClass]="{ disable: isSwitchDisabled }">
        <i class="fa fa-long-arrow-right"></i>
        <i class="fa fa-long-arrow-left"></i>
      </div>
      <component-thumbnail [component]="fixComponentData(right)"></component-thumbnail>
    </div>
  `,
})
export class ComponentOrderEditorComponent implements OnInit {
  @Input() components: PackageComponent[];
  @Input() isSwitchDisabled: boolean = false;
  @Output() switchComponents = new EventEmitter<PackageComponent[]>();

  left: PackageComponent;
  right: PackageComponent;
  fixComponentData = fixComponentData;

  ngOnInit() {
    // eslint-disable-next-line prefer-destructuring
    this.left = this.components[0];
    // eslint-disable-next-line prefer-destructuring
    this.right = this.components[1];
  }

  switch() {
    if (this.components.length >= 2 && !(this.components.includes(null) || this.components.includes(undefined))) {
      const rightCopy = this.right;

      this.right = this.left;
      this.left = rightCopy;

      this.components[0] = this.left;
      this.components[1] = this.right;

      this.switchComponents.emit(this.components);
    }
  }
}
