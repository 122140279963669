import { intersection, flatten, map } from 'lodash';
import { getComponentId, getComponentType, getDataFromComponent } from './components.helpers';
import {
  AdwordsSourceComponentData,
  AggregateComponentData,
  Analytics4SourceComponentData,
  AnalyticsSourceComponentData,
  AllComponentData,
  BingAdsSourceComponentData,
  CloudStorageSourceComponentData,
  Component,
  CrossJoinComponentData,
  Edge,
  JoinComponentData,
  RankComponentData,
  Schema,
  SelectComponentData,
  UnionComponentData,
  WindowComponentData,
} from '../package.models';
import { COMPONENT_TYPE } from '../../constants/component_type';

const SEPARATOR = '::';

const doubleInputComponents = [
  COMPONENT_TYPE.JOIN_COMPONENT,
  COMPONENT_TYPE.CROSS_JOIN_COMPONENT,
  COMPONENT_TYPE.UNION_COMPONENT,
];

export function inputComponentsLength(componentType: COMPONENT_TYPE): number {
  if (doubleInputComponents.includes(componentType)) {
    return 2;
  }

  return 1;
}

function commonSchemaGenerator({ id, name, schema }: AllComponentData) {
  let fields;
  if (schema) {
    fields = schema.fields.map((field) => {
      if (field.alias) return { name: field.alias };
      if (field.projected_name) return { name: field.projected_name };
      if (field.name) return { name: field.name };
      return {};
    });
  }

  return { id, name, valid: true, fields };
}

function commonParentSchemaGenerator(componentData: AllComponentData, parentSchema?: Schema) {
  return {
    ...(parentSchema || {}),
  };
}

function commonJoinSchemaGenerator(
  { id, name }: JoinComponentData & CrossJoinComponentData,
  firstParentSchema?: Schema,
  secondParentSchema?: Schema,
) {
  if (firstParentSchema && firstParentSchema.fields && secondParentSchema && secondParentSchema.fields) {
    const intersectionData = intersection(
      firstParentSchema.fields.map((field) => field.name),
      secondParentSchema.fields.map((field) => field.name),
    );

    if (intersectionData.length) {
      intersectionData.forEach((fieldName) => {
        // eslint-disable-next-line no-param-reassign
        firstParentSchema.fields = firstParentSchema.fields.map((field) => {
          if (field.name === fieldName) {
            return { ...field, name: firstParentSchema.name + SEPARATOR + fieldName };
          }
          return { ...field };
        });
        // eslint-disable-next-line no-param-reassign
        secondParentSchema.fields = secondParentSchema.fields.map((field) => {
          if (field.name === fieldName) {
            return { ...field, name: secondParentSchema.name + SEPARATOR + fieldName };
          }
          return { ...field };
        });
      });
    }

    return { id, name, fields: [...firstParentSchema.fields, ...secondParentSchema.fields] };
  }
  return { id, name };
}

export const SCHEMA_GENERATION_MAP: any = {
  [COMPONENT_TYPE.WINDOW_COMPONENT]: ({ id, name, windowed_fields }: WindowComponentData, parentSchema?: Schema) => {
    return {
      id,
      name,
      fields: [
        ...((parentSchema || {}).fields || []),
        ...(windowed_fields || []).map((field) => ({ name: field.alias })),
      ],
    };
  },
  [COMPONENT_TYPE.SELECT_COMPONENT]: ({ id, name, fields }: SelectComponentData) => {
    return { id, name, fields: (fields || []).map((field) => ({ name: field.projected_name })) };
  },
  [COMPONENT_TYPE.ASSERT_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.DISTINCT_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.EXECUTE_SQL_COMPONENT]: commonSchemaGenerator,
  [COMPONENT_TYPE.FILE_MOVER_COMPONENT]: commonSchemaGenerator,
  [COMPONENT_TYPE.AGGREGATE_COMPONENT]: ({ id, name, grouped_fields, aggregated_fields }: AggregateComponentData) => {
    return {
      id,
      name,
      fields: [
        ...(grouped_fields || []).map((field) => ({ name: field.field_name })),
        ...(aggregated_fields || []).map((field) => ({ name: field.alias })),
      ],
    };
  },
  [COMPONENT_TYPE.CUBE_COMPONENT]: ({ id, name, grouped_fields, aggregated_fields }: AggregateComponentData) => {
    const groupedFields = flatten(map(grouped_fields || [], 'fields'));

    return {
      id,
      name,
      fields: [
        ...(groupedFields || []).map((fieldName) => ({ name: fieldName })),
        ...(aggregated_fields || []).map((field) => ({ name: field.alias })),
      ],
    };
  },
  [COMPONENT_TYPE.UNION_COMPONENT]: ({ id, name, fields }: UnionComponentData) => {
    return {
      id,
      name,
      fields: (fields || []).map((field) => ({ name: field.alias })),
    };
  },
  [COMPONENT_TYPE.FILTER_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.LIMIT_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.CLONE_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.SAMPLE_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.SORT_COMPONENT]: commonParentSchemaGenerator,
  [COMPONENT_TYPE.CROSS_JOIN_COMPONENT]: commonJoinSchemaGenerator,
  [COMPONENT_TYPE.JOIN_COMPONENT]: commonJoinSchemaGenerator,
  [COMPONENT_TYPE.RANK_COMPONENT]: ({ id, name, field_alias }: RankComponentData, parentSchema?: Schema) => {
    return {
      id,
      name,
      fields: [
        {
          name: field_alias,
          data_type: 'int',
        },
        ...((parentSchema || {}).fields || []),
      ],
    };
  },
  [COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT]: ({
    schema,
    name,
    id,
    source_path_field_alias,
  }: CloudStorageSourceComponentData) => {
    let newFields = (schema || {}).fields.map((field) => ({ name: field.alias || field.name })) || [];
    if (source_path_field_alias && source_path_field_alias.length > 0) {
      newFields = [
        {
          name: source_path_field_alias,
        },
        ...newFields,
      ];
    }
    return { id, name, fields: newFields };
  },
  [COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT]: commonSchemaGenerator,
  [COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT]: (componentData: AdwordsSourceComponentData) => {
    const schema = commonSchemaGenerator(componentData as AllComponentData);

    if (componentData.customer_id_field_alias && componentData.customer_id_field_alias.length > 0 && schema.fields) {
      schema.fields.unshift({
        name: componentData.customer_id_field_alias,
      });
    }

    return schema;
  },
  [COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT]: (componentData: AnalyticsSourceComponentData) => {
    const schema: Schema = { fields: [] } as Schema;
    schema.id = componentData.id;
    schema.name = componentData.name;
    schema.fields = [];

    const getFieldAlias = function (fieldName: string) {
      return componentData.schema.fields.find((field) => field.name === fieldName).alias;
    };

    schema.fields.push({
      name: componentData.profile_id_field_alias,
    });

    schema.fields.push({
      name: componentData.account_name_field_alias,
    });

    schema.fields.push({
      name: componentData.property_name_field_alias,
    });

    schema.fields.push({
      name: componentData.profile_name_field_alias,
    });

    (componentData.dimensions || []).forEach(function (dimension) {
      schema.fields.push({
        name: getFieldAlias(dimension),
      });
    });

    (componentData.metrics || []).forEach(function (metric) {
      schema.fields.push({
        name: getFieldAlias(metric),
      });
    });

    return schema;
  },
  [COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT]: (componentData: Analytics4SourceComponentData) => {
    const schema: Schema = { fields: [] } as Schema;
    schema.id = componentData.id;
    schema.name = componentData.name;
    schema.fields = [];

    const getFieldAlias = function (fieldName: string) {
      return componentData.schema.fields.find((field) => field.name === fieldName).alias;
    };

    schema.fields.push({
      name: componentData.property_id_field_alias,
    });

    schema.fields.push({
      name: componentData.account_name_field_alias,
    });

    schema.fields.push({
      name: componentData.property_name_field_alias,
    });

    schema.fields.push({
      name: componentData.account_id_field_alias,
    });

    (componentData.dimensions || []).forEach(function (dimension) {
      schema.fields.push({
        name: getFieldAlias(dimension),
      });
    });

    (componentData.metrics || []).forEach(function (metric) {
      schema.fields.push({
        name: getFieldAlias(metric),
      });
    });

    return schema;
  },
  [COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT]: (componentData: BingAdsSourceComponentData) => {
    const schema = commonSchemaGenerator(componentData as AllComponentData);
    if (componentData.time_period_hour_field_alias && componentData.time_period_hour_field_alias.length > 0) {
      schema.fields.unshift({
        name: componentData.time_period_hour_field_alias,
      });
    }

    if (componentData.time_period_date_field_alias && componentData.time_period_date_field_alias.length > 0) {
      schema.fields.unshift({
        name: componentData.time_period_date_field_alias,
      });
    }

    return schema;
  },
};

export const COMPONENT_SCHEMA_PARENT_REQUIRED_TYPES = [
  COMPONENT_TYPE.CROSS_JOIN_COMPONENT,
  COMPONENT_TYPE.WINDOW_COMPONENT,
  COMPONENT_TYPE.JOIN_COMPONENT,
  COMPONENT_TYPE.FILTER_COMPONENT,
  COMPONENT_TYPE.CLONE_COMPONENT,
  COMPONENT_TYPE.LIMIT_COMPONENT,
  COMPONENT_TYPE.RANK_COMPONENT,
  COMPONENT_TYPE.SAMPLE_COMPONENT,
  COMPONENT_TYPE.SORT_COMPONENT,
  COMPONENT_TYPE.DISTINCT_COMPONENT,
  COMPONENT_TYPE.ASSERT_COMPONENT,
];

export function getAllComponentAncestors(
  componentId: string,
  components: Component[] = [],
  edges: Edge[] = [],
  ancestors: Component[] = [],
): Component[] {
  const parents = getComponentParents(componentId, components, edges);
  const newAncestors: Component[] = [...ancestors, ...parents];

  if (parents.length) {
    const parentAncestors = parents.map((item) =>
      getAllComponentAncestors(getComponentId(item), components, edges, newAncestors),
    );
    return flatten(parentAncestors);
  } else {
    return newAncestors;
  }
}

export function getComponentParents(
  componentId: string,
  components: Component[] = [],
  edges: Edge[] = [],
): Component[] {
  const parentEdgesIds = (edges || [])
    .filter((edge) => edge.target === componentId)
    .sort((a, b) => a.order - b.order)
    .map((edge) => edge.source);
  return parentEdgesIds.map((sourceId) => components.find((component) => getComponentId(component) === sourceId));
}

function getSchemas(component: Component, components: Component[], edges: Edge[], schemas: Schema[] = []): Schema[] {
  const componentData = getDataFromComponent(component);
  const componentType = getComponentType(component);
  const generator = SCHEMA_GENERATION_MAP[componentType] || commonSchemaGenerator;

  if (COMPONENT_SCHEMA_PARENT_REQUIRED_TYPES.includes(componentType)) {
    const parents = getComponentParents(getComponentId(component), components, edges);
    const parentSchemas = parents.map((parent) => getSchemas(parent, components, edges, [])[0]);
    schemas.push(generator(componentData, ...parentSchemas));
  } else {
    schemas.push(generator(componentData));
  }

  return schemas;
}

export function getComponentSchema(component: Component, components: Component[], edges: Edge[]): Schema {
  const schemas = getSchemas(component, components, edges);
  return schemas.reduce(
    (acc, schema) => {
      acc.fields = [
        ...acc.fields,
        ...(schema.fields || []).map((field) => ({
          name: field.name,
          alias: field.name,
          data_type: 'string',
        })),
      ];
      return acc;
    },
    { valid: true, fields: [] } as Schema,
  );
}

export function getComponentParentsSchema(component: Component, components: Component[], edges: Edge[]): Schema[] {
  const parents = getComponentParents(getComponentId(component), components, edges);

  return parents.map((parent) => getSchemas(parent, components, edges, [])[0]);
}
