import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { logout } from '../../account/store/account.actions';
import { AppState } from '../../store';
import { Account } from '../../account/account.models';
import { selectAccount } from '../../account/store/account.selectors';
import { getAccountsList, loadMoreAccountsList } from '../../settings/store/accounts/accounts.actions';
import { QueryParamsAccountsMenuList } from '../helper/query-params-generic-list.helper';
import {
  selectAccounts,
  selectAccountsLoadingFlag,
  selectAreAllAccountsLoadedFlag,
} from '../../settings/store/accounts/accounts.selectors';

@Component({
  selector: 'accounts-menu',
  template: `
    <div class="accounts-menu" *ngIf="isVisible">
      <div class="accounts-menu-wrapper">
        <div class="accounts-menu-header">
          <strong>{{ 'sidebar.accounts.title' | translate }}</strong>
        </div>
        <div class="accounts-menu-body" (scroll)="scrollY($event)">
          <div class="accounts-menu-list">
            <div
              class="accounts-menu-list-item"
              *ngFor="let account of accounts$ | async"
              [ngClass]="{ active: account.account_id === (currentAccount$ | async).account_id }"
              (click)="goToJobs(account)"
            >
              <a class="accounts-menu-link">
                {{ account.name }}
              </a>
            </div>
            <xp-loader *ngIf="isLoading"></xp-loader>
          </div>
        </div>
        <div class="accounts-menu-footer">
          <a *ngIf="isAuthenticated()" class="btn btn-default btn-sm logout" (click)="logout()">{{
            'user.menu.actions.sign_out' | translate
          }}</a>
          <div class="accounts-menu-arrow"></div>
        </div>
      </div>
    </div>
  `,
})
export class AccountsMenuComponent implements OnInit, OnDestroy {
  @Input() isVisible: boolean;
  currentAccount$ = this.store.select(selectAccount);
  accounts$ = this.store.select(selectAccounts);
  accountsLoading$ = this.store.select(selectAccountsLoadingFlag);
  areAllAccountsLoaded$ = this.store.select(selectAreAllAccountsLoadedFlag);
  isLoading = false;
  allItemsLoaded = false;
  queryParams = { ...QueryParamsAccountsMenuList };
  accountsLoadingSubscription: Subscription;
  areAllAccountsLoadedSubscription: Subscription;

  constructor(private authService: AuthService, private store: Store<AppState>, private router: Router) {
    this.store.dispatch(getAccountsList({ params: { ...this.queryParams } }));
  }

  ngOnInit() {
    this.accountsLoadingSubscription = this.accountsLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });

    this.areAllAccountsLoadedSubscription = this.areAllAccountsLoaded$.subscribe((allItemsLoaded) => {
      this.allItemsLoaded = allItemsLoaded;
    });
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.store.dispatch(logout({ shouldRedirect: true }));
  }

  goToJobs(account: Account) {
    this.router.navigate([`/${account.account_id}/jobs`]);
  }

  scrollY(event: Event) {
    const eventTarget = event.target as HTMLElement;
    if (
      this.isLoading ||
      this.allItemsLoaded ||
      eventTarget.scrollTop === 0 ||
      eventTarget.offsetHeight + eventTarget.scrollTop < eventTarget.scrollHeight
    ) {
      return;
    }

    this.queryParams.offset += 100;

    const params: any = {
      ...this.queryParams,
    };
    this.store.dispatch(loadMoreAccountsList({ params }));
  }

  ngOnDestroy() {
    if (this.accountsLoadingSubscription) {
      this.accountsLoadingSubscription.unsubscribe();
    }
    if (this.areAllAccountsLoadedSubscription) {
      this.areAllAccountsLoadedSubscription.unsubscribe();
    }
  }
}
