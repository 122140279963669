import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { HubspotConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { DUMMY_PASSWORD } from './connection-component-username-password.component';

@Component({
  selector: 'connection-form-hubspot',
  template: `
    <xp-form-validation type="HubspotConnection" [name]="formName">
      <form id="connectionFormHubspot" name="connectionFormHubspot" #form="ngForm" class="form">
        <div class="row" *ngIf="connectionTypeButtonCreate">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="password"
                >{{ 'connections.form.labels.private_app_token' | translate
                }}<span *ngIf="passwordChangeable">
                  (<a (click)="change()"
                    ><span *ngIf="passwordDisabled">{{ 'connections.form.labels.change' | translate }}</span
                    ><span *ngIf="!passwordDisabled">{{ 'connections.form.labels.cancel' | translate }}</span></a
                  >)</span
                ></label
              >
              <xp-input
                type="password"
                class="form-control"
                name="password"
                id="password"
                [(ngModel)]="connection.password"
                [attr.disabled]="passwordDisabled"
                [disable]="passwordDisabled"
              ></xp-input>
            </xp-form-group>
          </div>
        </div>
      </form>
    </xp-form-validation>
    <div class="connection-component-hubspot alert alert-info">
      <strong
        ><i class="fa fa-info-circle" aria-hidden="true"></i>
        {{ 'connection-component-hubspot.title' | translate }}</strong
      >
      <p [innerHTML]="'connection-component-hubspot.text' | translate"></p>
    </div>
  `,
})
export class ConnectionFormHubspotComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<HubspotConnection> = {};
  @Input() connectionTypeButtonCreate: boolean;
  @Output() formValidationChange = new EventEmitter<boolean>();
  passwordChangeable = false;
  passwordDisabled = true;

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormHubspot';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.passwordChangeable = !!this.connection.id;
    this.passwordDisabled = this.passwordChangeable;

    if (this.passwordDisabled) {
      this.connection.password = DUMMY_PASSWORD;
    }
  }

  change(): void {
    this.passwordDisabled = !this.passwordDisabled;

    if (this.passwordDisabled) {
      this.connection.password = DUMMY_PASSWORD;
    } else {
      this.connection.password = '';
    }
  }
}
