import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'xpCalendar' })
export class XpCalendarPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(referenceTime: string, timezone?: string): string {
    const t = moment(referenceTime);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    t.utcOffset(timezone || window['DEFAULT_TIMEZONE']);
    return t.calendar();
  }
}
