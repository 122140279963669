import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkspacesState } from './workspaces.reducer';

export const selectWorkspacesState = createFeatureSelector<WorkspacesState>('workspaces');

export const selectWorkspaces = createSelector(selectWorkspacesState, (state: WorkspacesState) => state.items);
export const selectWorkspacesErrors = createSelector(selectWorkspacesState, (state: WorkspacesState) => state.errors);
export const selectWorkspacesLoadingFlag = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state.isLoading,
);
export const selectWorkspacesErrorFlag = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state.isResponseError,
);
export const selectWorkspacesIsSubmittingFlag = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state.isFormSubmitting,
);
export const selectWorkspacesIsModalOpenFlag = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state.isModalOpen,
);
export const selectAreAllWorkspacesLoaded = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state.areAllItemsLoaded,
);
