import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppState } from '../../store';
import { loadPigFunctions } from '../store/pig.actions';
import { selectPigFunctions } from '../store/pig.selectors';
import { PigFunction } from '../pig.models';

export interface FunctionItem extends PigFunction {
  type: string;
  console?: string;
}

export interface CategoryItem {
  name: string;
  type: string;
  items: FunctionItem[];
}

@Injectable({
  providedIn: 'root',
})
export class XplentyPigFunctionsService {
  private all: FunctionItem[];
  private itemsData$: Observable<FunctionItem[]> = this.store.select(selectPigFunctions).pipe(
    filter((functions) => !!functions[0]),
    map((functions) => Object.values(functions).map((functionItem) => ({ ...functionItem, type: 'function' }))),
    first(),
  );

  constructor(private store: Store<AppState>) {}

  public get items(): Observable<FunctionItem[]> {
    return this.itemsData$;
  }

  public load(): void {
    this.store.dispatch(loadPigFunctions());
  }

  public get(autocomplete: string): FunctionItem {
    return this.all.find((_function) => _function.autocomplete === autocomplete);
  }

  public getCategories(extraFunctions: FunctionItem[] = []): Observable<CategoryItem[]> {
    return this.itemsData$.pipe(
      map((functions) => {
        const items = [...functions, ...extraFunctions];
        this.all = items;

        const categoriesMap = items.reduce((acc: any, functionItem) => {
          if (acc[functionItem.category]) {
            acc[functionItem.category].items.push(functionItem);
          } else {
            acc[functionItem.category] = {
              items: [functionItem],
            };
          }
          return acc;
        }, {});

        return Object.keys(categoriesMap).map((categoryName) => ({
          name: categoryName,
          type: categoriesMap[categoryName].items[0].type,
          items: categoriesMap[categoryName].items,
        }));
      }),
    );
  }
}
