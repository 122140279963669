import { SelectOption } from '../common/components/forms/xp-select.component';

export const ADWORDS_ADS_V10_DATE_RANGE_TYPES: SelectOption[] = [
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.today',
    value: 'TODAY',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.yesterday',
    value: 'YESTERDAY',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.this_week_sun_today',
    value: 'THIS_WEEK_SUN_TODAY',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.this_week_mon_today',
    value: 'THIS_WEEK_MON_TODAY',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_7_days',
    value: 'LAST_7_DAYS',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_week_sun_sat',
    value: 'LAST_WEEK_SUN_SAT',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_week_mon_sun',
    value: 'LAST_WEEK_MON_SUN',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_business_week',
    value: 'LAST_BUSINESS_WEEK',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_14_days',
    value: 'LAST_14_DAYS',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.this_month',
    value: 'THIS_MONTH',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_30_days',
    value: 'LAST_30_DAYS',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.last_month',
    value: 'LAST_MONTH',
  },
  {
    translate: true,
    text: 'adwords-source-editor.form.dates.values.custom_date',
    value: 'CUSTOM_DATE',
  },
];
