import { Component, Input, OnInit } from '@angular/core';
import { Schedule } from '../../../schedules/schedule.models';
import { Package } from '../../../packages/package.models';
import { Member } from '../../../settings/members.models';
import { Key } from '../../../settings/key.models';
import { Job } from '../../../jobs/jobs.models';
import { Hook } from '../../../settings/hook.models';
import { Cluster } from '../../../clusters/clusters.models';
import { Account } from '../../../account/account.models';
import { connectionIconUrlByType } from '../../helper/connection-icon-url-by-type.helper';
import { loadHookImage } from '../../../settings/helpers/load-hook-image.helper';
import { Workspace } from '../../../workspaces/workspaces.models';
import { AnyConnection } from '../../../connections/connection.models';

export enum ItemType {
  account = 'account',
  cluster = 'cluster',
  connection = 'connection',
  hook = 'hook',
  job = 'job',
  key = 'key',
  member = 'member',
  package = 'package',
  schedule = 'schedule',
  workspace = 'workspace',
}

export type ListItemSnippetType =
  | Account
  | Cluster
  | AnyConnection
  | Hook
  | Job
  | Key
  | Member
  | Package
  | Schedule
  | Workspace;

@Component({
  selector: 'list-item-snippet',
  template: `
    <div class="generic-list-item-snippet" [ngClass]="itemType + '-snippet'" [ngSwitch]="itemType">
      <div *ngSwitchCase="itemTypeEnum.account" class="item-snippet-container">
        <div class="item-snippet-icon">
          <div class="account-img">
            <img class="img-circle" [src]="item.avatar_url" [alt]="item.name" />
          </div>
        </div>
        <div class="item-snippet-name">
          {{ item.name }}
          <p class="item-snippet-description">
            <span class="account-region">{{ 'regions.' + item.region + '.text' | translate }}</span>
            <span class="account-role left-separator">{{
              'roles.' + (item.is_owner ? 'owner' : item.role) | translate
            }}</span>
          </p>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.cluster" class="item-snippet-container">
        <div class="item-snippet-icon">
          <common-icon iconId="icon-cluster"></common-icon>
        </div>
        <div class="item-snippet-name">
          {{ item.name }}
          <small
            [innerHTML]="
              'cluster.generic-list.item.labels.running_jobs'
                | translate: { count: item.running_jobs_count, queued: item.queued_jobs_count || 0 }
            "
          ></small>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.connection" class="item-snippet-container">
        <div class="item-snippet-icon">
          <img [src]="connectionIconUrlByType(item.type)" [alt]="item.name" />
        </div>
        <div class="item-snippet-name">
          {{ item.name }}
          <p class="item-snippet-description">
            <span class="connection-type">{{ item.type }}</span>
          </p>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.hook" class="item-snippet-container">
        <div class="item-snippet-wrapper" [ngClass]="{ inactive: !item.active }">
          <div class="item-snippet-icon">
            <div class="hook-img">
              <img [src]="loadHookImage(item.type)" [alt]="item.name" />
            </div>
          </div>
          <div class="item-snippet-name">
            {{ item.name }}
            <span *ngIf="!item.active" class="inactive"
              >({{ 'hook.generic-list.item.labels.inactive' | translate }})</span
            >
            <p class="item-snippet-description">
              <span class="hook-type">{{ 'hook.types.' + item.type | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.job" class="item-snippet-container">
        <div class="item-snippet-icon">
          <common-icon *ngIf="item.package.flow_type === 'workflow'" iconId="icon-workflow"></common-icon>
          <common-icon *ngIf="item.package.flow_type === 'dataflow'" iconId="icon-package"></common-icon>
        </div>
        <div class="item-snippet-name">
          {{ item.id }}
          <p class="item-snippet-description">{{ item.package.name }}</p>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.key" class="item-snippet-name">
        {{ item.name }}
        <code class="item-snippet-description">{{ item.fingerprint }}</code>
      </div>

      <div *ngSwitchCase="itemTypeEnum.member" class="item-snippet-container">
        <div class="item-snippet-icon">
          <div class="member-img">
            <img class="img-circle" [src]="item.avatar_url" [alt]="item.name" />
          </div>
        </div>
        <div class="item-snippet-name">
          {{ item.name || item.email }}<br />
          <p class="item-snippet-description">{{ item.email }}</p>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.package" class="item-snippet-container">
        <div class="item-snippet-icon">
          <common-icon *ngIf="item.flow_type === 'workflow'" iconId="icon-workflow"></common-icon>
          <common-icon *ngIf="item.flow_type === 'dataflow'" iconId="icon-package"></common-icon>
        </div>
        <div class="item-snippet-name">
          {{ item.name }}
          <p class="item-snippet-description" *ngIf="item.description">{{ item.description }}</p>
        </div>
      </div>

      <div *ngSwitchCase="itemTypeEnum.workspace" class="item-snippet-container">
        <div class="item-snippet-icon">
          <common-icon iconId="icon-workspace"></common-icon>
        </div>
        <div class="item-snippet-name">
          {{ item.name }}
        </div>
      </div>

      <div
        *ngSwitchCase="itemTypeEnum.schedule"
        class="item-snippet-wrapper"
        [ngClass]="{ inactive: item.status === 'disabled' }"
      >
        <div class="item-snippet-icon">
          <common-icon iconId="icon-schedules"></common-icon>
        </div>
        <div class="item-snippet-name">
          {{ item.name }}
          <span *ngIf="item.status === 'disabled'" class="inactive"
            >({{ 'schedule.generic-list.item.labels.inactive' | translate }})</span
          >
          <p class="item-snippet-description" *ngIf="item.description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  `,
})
export class ListItemSnippetComponent implements OnInit {
  @Input() itemType: ItemType;
  @Input() item: ListItemSnippetType = {};
  itemTypeEnum = ItemType;
  connectionIconUrlByType = connectionIconUrlByType;
  loadHookImage = loadHookImage;

  ngOnInit() {
    if (!this.item) {
      this.item = {};
    }
  }
}
