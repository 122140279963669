import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Schema, SortComponentData } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateRawComponent } from '../../store/component.actions';

@Component({
  selector: 'sort-editor',
  template: `
    <div class="sort-editor-component">
      <div class="row">
        <div class="col-md-12">
          <label
            ><b>{{ 'sort_component.fields_mapping' | translate }}</b></label
          >
          <sort-collection
            [records]="rawComponent.fields || []"
            (recordsChange)="onRecordsChange($event)"
            [parentSchemas]="parentSchemas"
            (validityChange)="onValidityChange($event)"
            [isVisible]="true"
          ></sort-collection>
        </div>
      </div>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class SortEditorComponent {
  @Input() rawComponent: SortComponentData;
  @Input() parentSchemas: Schema[];

  constructor(private store: Store<AppState>) {}

  onRecordsChange(records) {
    this.store.dispatch(updateRawComponent({ rawComponent: { fields: records } }));
  }

  onValidityChange(isValid: boolean) {
    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }
}
