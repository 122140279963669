import { createAction, props } from '@ngrx/store';
import { ComponentPreviewLogMessage, ComponentPreviewResultData, PackagePreviewPayload } from '../package.models';

export const postComponentPreviewerData = createAction(
  '[PackageDesigner] Post package preview data',
  props<{ data: PackagePreviewPayload; package_id: number }>(),
);

export const componentPreviewerDataResponse = createAction(
  '[PackageDesigner] Package preview data response',
  props<{ package_id: number; preview_id: number }>(),
);

export const componentPreviewerDataError = createAction(
  '[PackageDesigner] Package preview data error',
  props<{ err: any }>(),
);

export const componentPreviewerStatusError = createAction(
  '[PackageDesigner] Package preview status error',
  props<{ err: any }>(),
);

export const componentPreviewerResultResponse = createAction(
  '[PackageDesigner] Package preview result response',
  props<{ data: ComponentPreviewResultData }>(),
);

export const componentPreviewerResultError = createAction(
  '[PackageDesigner] Package preview result error',
  props<{ err: any }>(),
);

export const componentPreviewerLog = createAction(
  '[PackageDesigner] Component previewer log',
  props<{ data: ComponentPreviewLogMessage }>(),
);

export const componentPreviewerSetValidation = createAction(
  '[PackageDesigner] Component previewer set validation',
  props<{ isValid: boolean }>(),
);
