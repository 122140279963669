import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { cloneDeep, escape } from 'lodash';
import { CloudStorageDestinationComponentData, Connection, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ConnectionTypeName } from '../../../connections/connection.models';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';

@Component({
  selector: 'cloud-storage-destination-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
          <dynamic-connection [component]="rawComponent" *ngxPermissionsOnly="'dynamicConnection'"></dynamic-connection>
        </xp-step>
        <xp-step [step]="componentBaseStep">
          <xp-form-validation type="CloudStorageDestination">
            <form
              class="cloud-storage-destination-editor destination-editor"
              name="componentForm"
              novalidate
              #form="ngForm"
            >
              <div class="row" *ngFor="let error of errors">
                <div class="col-md-12">
                  <div class="alert alert-warning">
                    <button type="button" class="close" (click)="removeError(error)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <span>{{ error }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-6"
                  *ngIf="
                    rawComponent.connection &&
                    rawComponent.connection.type !== 'sftp' &&
                    rawComponent.connection.type !== 'ftps'
                  "
                >
                  <xp-form-group
                    *ngIf="
                      [
                        ConnectionTypeName.s3,
                        ConnectionTypeName.gs,
                        ConnectionTypeName.gsv2,
                        ConnectionTypeName.azureBlobStorage
                      ].includes(rawComponent.connection.type)
                    "
                  >
                    <label for="bucket" *ngIf="hasBucket || hasContainer">
                      <span *ngIf="hasBucket">{{ 'cloud-storage-destination-editor.labels.bucket' | translate }}</span>
                      <span *ngIf="hasContainer">{{
                        'cloud-storage-destination-editor.labels.container' | translate
                      }}</span>
                    </label>
                    <xp-input
                      type="text"
                      class="form-control"
                      name="bucket"
                      id="bucket"
                      [ngModel]="rawComponent.bucket"
                      (ngModelChange)="onValueChange($event, 'bucket')"
                      [placeholder]="'cloud-storage-destination-editor.placeholders.bucket-s3' | translate"
                      *ngIf="rawComponent.connection.type === ConnectionTypeName.s3"
                    ></xp-input>
                    <xp-input
                      type="text"
                      class="form-control"
                      name="bucket_gc"
                      id="bucket"
                      [ngModel]="rawComponent.bucket"
                      (ngModelChange)="onValueChange($event, 'bucket')"
                      [placeholder]="'cloud-storage-destination-editor.placeholders.bucket-gs' | translate"
                      *ngIf="
                        rawComponent.connection.type === ConnectionTypeName.gs ||
                        rawComponent.connection.type === ConnectionTypeName.gsv2
                      "
                    ></xp-input>
                    <xp-input
                      type="text"
                      class="form-control"
                      name="container_azureblobstorage"
                      id="bucket"
                      [ngModel]="rawComponent.bucket"
                      (ngModelChange)="onValueChange($event, 'bucket')"
                      [placeholder]="'cloud-storage-destination-editor.placeholders.container' | translate"
                      *ngIf="rawComponent.connection.type === ConnectionTypeName.azureBlobStorage"
                    ></xp-input>
                  </xp-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <xp-form-group>
                    <label for="path">{{ 'cloud-storage-destination-editor.labels.path' | translate }}</label>
                    <xp-input
                      type="text"
                      class="form-control"
                      name="path"
                      id="path"
                      [ngModel]="rawComponent.path"
                      (ngModelChange)="onValueChange($event, 'path')"
                      [placeholder]="pathPlaceholder | translate"
                      (inputChange)="onPathInputChange($event)"
                    ></xp-input>
                    <small>{{ 'cloud-storage-destination-editor.hints.path' | translate }}</small>
                  </xp-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <label>{{ 'cloud-storage-destination-editor.labels.destination_type' | translate }}</label>
                    <div class="btn-group btn-group-md btn-group-select">
                      <button
                        type="button"
                        class="btn btn-sm btn-default"
                        value="{{ destinationType.value }}"
                        [ngClass]="{ active: destinationType.active }"
                        *ngFor="let destinationType of destinationTypes"
                        (click)="selectDestinationType(destinationType.value)"
                        [attr.id]="'destination_type' + destinationType.value"
                      >
                        {{ destinationType.name }}
                      </button>
                    </div>
                  </div>
                  <cloud-storage-delimiter
                    *ngIf="destinationTypes[0].active"
                    [settings]="rawComponent.destination_type.csv_destination_type"
                    [rawComponent]="rawComponent"
                    [component]="component"
                  ></cloud-storage-delimiter>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div *ngIf="destinationTypes[0].active || destinationTypes[1].active">
                    <label for="compression_type">{{
                      'cloud-storage-destination-editor.labels.compression_type' | translate
                    }}</label>
                    <div class="btn-group btn-group-md btn-group-select">
                      <button
                        type="button"
                        class="btn btn-default"
                        [ngClass]="{ 'active btn-primary': rawComponent.compression_type === 'none' }"
                        (click)="onValueChange('none', 'compression_type')"
                      >
                        {{ 'cloud-storage-destination-editor.selects.compression_type.options.none' | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        [ngClass]="{ 'active btn-primary': rawComponent.compression_type === 'gzip' }"
                        (click)="onValueChange('gzip', 'compression_type')"
                      >
                        {{ 'cloud-storage-destination-editor.selects.compression_type.options.gzip' | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        [ngClass]="{ 'active btn-primary': rawComponent.compression_type === 'bz2' }"
                        (click)="onValueChange('bz2', 'compression_type')"
                      >
                        {{ 'cloud-storage-destination-editor.selects.compression_type.options.bz2' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-9">
                  <xp-form-group [validationDisabled]="true">
                    <label for="operation_type">{{
                      'cloud-storage-destination-editor.labels.operation_type' | translate
                    }}</label>
                    <xp-select
                      [value]="rawComponent.operation_type"
                      [options]="operationTypes"
                      [preventEmpty]="true"
                      id="operation_type"
                      name="operation_type"
                      class="form-control xp-select"
                      (valueChange)="onDestinationActionChange($event)"
                    ></xp-select>
                  </xp-form-group>
                  <xp-form-group *ngIf="rawComponent.operation_type === 'copy_files'">
                    <label for="char_encoding">{{
                      'cloud-storage-source-editor.form.labels.char_encoding' | translate
                    }}</label>
                    <xp-select
                      [value]="rawComponent.char_encoding"
                      [options]="charEncodings"
                      [preventEmpty]="true"
                      id="char_encoding"
                      name="char_encoding"
                      class="form-control xp-select"
                      (valueChange)="onCharEncodingChange($event)"
                    ></xp-select>
                  </xp-form-group>
                  <div class="input-checkbox-wrapper form-group">
                    <xp-input-checkbox
                      [ngModel]="rawComponent.enforce_single_file"
                      (ngModelChange)="onValueChange($event, 'enforce_single_file')"
                      [labelText]="'cloud-storage-destination-editor.labels.enforce_single_file' | translate"
                      [hint]="'cloud-storage-destination-editor.hints.enforce_single_file' | translate"
                      name="enforce_single_file"
                    ></xp-input-checkbox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <file-names-pattern [rawComponent]="rawComponent"></file-names-pattern>
                </div>
              </div>
            </form>
          </xp-form-validation>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class CloudStorageDestinationEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: CloudStorageDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  ConnectionTypeName = ConnectionTypeName;

  selectPickerTypes = SelectPickerTypes;

  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});

  hasContainer = false;
  hasBucket = true;
  hasPreProcess = true;
  errors = [];
  destinationTypes = [
    {
      name: this.translate.instant('cloud-storage-destination-editor.selects.format.csv_destination_type'),
      value: 'csv_destination_type',
      active: false,
      data: {
        csv_destination_type: {
          field_delimiter: '\\t',
          text_qualifier: '',
          write_header: false,
          line_ending: 'unix', // has no effect on initial value in UI
        },
      },
    },
    {
      name: this.translate.instant('cloud-storage-destination-editor.selects.format.json_destination_type'),
      value: 'json_destination_type',
      active: false,
      data: {
        json_destination_type: {
          json_destination_type: true,
        },
      },
    },
    {
      name: this.translate.instant('cloud-storage-destination-editor.selects.format.parquet_destination_type'),
      value: 'parquet_destination_type',
      active: false,
      data: {
        parquet_destination_type: {
          parquet_destination_type: true,
        },
      },
    },
  ];

  operationTypes = [
    {
      value: 'fail_if_exists',
      text: 'cloud-storage-destination-editor.selects.directory_exists_actions.fail_if_exists',
      translate: true,
      deprecated: false,
    },
    {
      value: 'delete_destination_path',
      text: 'cloud-storage-destination-editor.selects.directory_exists_actions.delete_destination_path',
      translate: true,
      deprecated: false,
    },
    {
      value: 'replace_existing_files',
      text: 'cloud-storage-destination-editor.selects.directory_exists_actions.replace_existing_files',
      translate: true,
      tooltip: 'cloud-storage-destination-editor.hints.replace_existing_files',
      translateTooltip: true,
      deprecated: false,
    },
    {
      value: 'copy_files',
      text: 'cloud-storage-destination-editor.selects.directory_exists_actions.copy_files',
      translate: true,
      tooltip: 'cloud-storage-destination-editor.hints.directory_exists',
      translateTooltip: true,
      deprecated: false,
    },
    {
      value: 'copy_files_new_directory',
      text: 'cloud-storage-destination-editor.selects.directory_exists_actions.copy_files_new_directory',
      translate: true,
      deprecated: false,
    },
  ];

  charEncodings = [
    {
      value: 'utf-8',
      text: 'cloud-storage-source-editor.form.selects.char_encoding.options.utf_8',
      translate: true,
    },
    {
      value: 'utf-16',
      text: 'cloud-storage-source-editor.form.selects.char_encoding.options.utf_16',
      translate: true,
    },
    {
      value: 'shift-jis',
      text: 'cloud-storage-source-editor.form.selects.char_encoding.options.shift_jis',
      translate: true,
    },
    {
      value: 'cp932',
      text: 'cloud-storage-source-editor.form.selects.char_encoding.options.cp932',
      translate: true,
    },
  ];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    });

    const keys = Object.keys(this.component.destination_type || {});

    if (keys.length === 1) {
      this.selectDestinationType(keys[0], cloneDeep(this.component.destination_type));
    }

    let operationType = this.rawComponent.operation_type;

    if (this.rawComponent.delete_destination_path) {
      operationType = 'delete_destination_path';
    } else if (this.rawComponent.destination_exists_action === 'fail_if_exists') {
      operationType = 'fail_if_exists';
    } else if (this.rawComponent.destination_exists_action === 'replace_existing_files') {
      operationType = 'replace_existing_files';
    } else if (this.rawComponent.destination_exists_action === 'copy_files') {
      operationType = 'copy_files';
    } else if (this.rawComponent.destination_exists_action === 'copy_files_new_directory') {
      operationType = 'copy_files_new_directory';
    }

    this.onValueChange(operationType, 'operation_type');

    if (
      !this.rawComponent.connection ||
      (this.rawComponent.connection.type !== ConnectionTypeName.s3 &&
        this.rawComponent.connection.type !== ConnectionTypeName.gs &&
        this.rawComponent.connection.type !== ConnectionTypeName.gsv2 &&
        this.rawComponent.connection.type !== ConnectionTypeName.azureBlobStorage)
    ) {
      this.onValueChange('', 'bucket');
    }

    if (this.rawComponent.destination_type?.parquet_destination_type) {
      this.onValueChange('none', 'compression_type');
    }

    this.setSchema();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.rawComponent?.currentValue?.dynamic_connection !== changes.rawComponent?.previousValue?.dynamic_connection
    ) {
      if (this.rawComponent.dynamic_connection) {
        if (!this.connectionStep.tags.find((tag) => tag.name === this.component.dynamic_connection)) {
          this.connectionStep.tags = [
            ...this.connectionStep.tags,
            {
              name: this.component.dynamic_connection,
            },
          ];
        }
      } else {
        this.connectionStep.tags = this.connectionStep.tags.filter(
          (tag) => tag.name !== changes.component.previousValue.dynamic_connection,
        );
      }
      this.connectionStep = { ...this.connectionStep };
    }
  }

  onValueChange(value: any, key: string) {
    const componentData: any = {};

    if (key === 'operation_type' && value === 'fail_if_exists') {
      componentData.delete_destination_path = false;
      componentData.destination_exists_action = 'fail_if_exists';
    } else if (key === 'operation_type' && value === 'delete_destination_path') {
      componentData.delete_destination_path = true;
      componentData.destination_exists_action = 'fail_if_exists';
    } else if (key === 'operation_type' && value === 'replace_existing_files') {
      componentData.delete_destination_path = false;
      componentData.destination_exists_action = 'replace_existing_files';
    } else if (key === 'operation_type' && value === 'copy_files') {
      componentData.delete_destination_path = false;
      componentData.destination_exists_action = 'copy_files';
    } else if (key === 'operation_type' && value === 'copy_files_new_directory') {
      componentData.delete_destination_path = false;
      componentData.destination_exists_action = 'copy_files_new_directory';
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value, ...componentData },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value, ...componentData } }));
  }

  onValidityChange() {
    const isValid = this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    if (this.rawComponent.dynamic_connection) {
      this.connectionStep.tags = [...this.connectionStep.tags, { name: this.rawComponent.dynamic_connection }];
    }

    this.connectionStep = { ...this.connectionStep, valid: true };

    if (connection || connection.type) {
      switch (connection.type) {
        case ConnectionTypeName.sftp:
        case ConnectionTypeName.ftps:
        case ConnectionTypeName.hdfs:
          this.hasContainer = false;
          this.hasBucket = false;
          this.hasPreProcess = false;
          break;
        case ConnectionTypeName.s3:
          this.hasContainer = false;
          this.hasBucket = true;
          this.hasPreProcess = true;
          break;
        case ConnectionTypeName.gs:
        case ConnectionTypeName.gsv2:
          this.hasContainer = false;
          this.hasBucket = true;
          this.hasPreProcess = false;
          break;
        case ConnectionTypeName.azureBlobStorage:
          this.hasContainer = true;
          this.hasBucket = false;
          this.hasPreProcess = false;
          break;
        default:
          this.hasContainer = false;
          this.hasBucket = false;
          this.hasPreProcess = false;
          break;
      }
    }
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  selectDestinationType(destinationTypeValue: string, data?: any) {
    this.destinationTypes = this.destinationTypes.map((destinationType) => {
      let isActive;
      if (destinationTypeValue === destinationType.value) {
        isActive = true;

        if (data && data.csv_destination_type && data.csv_destination_type.text_qualifier === '') {
          // eslint-disable-next-line no-param-reassign
          data.csv_destination_type.text_qualifier = 'none';
        }

        if (data) {
          // eslint-disable-next-line no-param-reassign
          destinationType.data = data;
        }

        this.onValueChange(destinationType.data, 'destination_type');

        if (destinationTypeValue === 'parquet_destination_type') {
          this.onValueChange('none', 'compression_type');
          this.onValueChange('unix', 'line_ending');
        }
      } else {
        isActive = false;
      }

      return {
        ...destinationType,
        active: isActive,
      };
    });
  }

  onDestinationActionChange(value: string) {
    this.onValueChange(value, 'operation_type');
    const deprecatedTypes = this.operationTypes.filter((type) => type.deprecated!).map((type) => type.value);

    if (deprecatedTypes.includes(value)) {
      this.addOperationTypeError();
    } else {
      this.errors.pop();
      this.errors = this.errors.slice();
    }
  }

  onCharEncodingChange(charEncoding: string) {
    this.onValueChange(charEncoding, 'char_encoding');
  }

  addOperationTypeError() {
    const suggestedOption = this.operationTypes.find((type) => type.value === 'copy_files');

    const error = this.translate.instant('cloud-storage-destination-editor.form.errors.replace_existing_files', {
      suggested_option: suggestedOption.text,
    });

    this.errors = [...this.errors, error];
  }

  removeError(error) {
    const removeIndex = this.errors.indexOf(error);
    this.errors.splice(removeIndex, 1);
    this.errors = this.errors.slice();
  }

  setSchema() {
    const schema: Schema = {
      fields: ((this.parentSchemas[0] || {}).fields || []).map((field) => ({
        name: field.name,
        alias: field.name,
        data_type: 'string',
      })),
    };

    this.onValueChange(schema, 'schema');
  }

  get pathPlaceholder(): string {
    switch (this.rawComponent.connection?.type) {
      case ConnectionTypeName.s3:
      case ConnectionTypeName.azureBlobStorage:
        return 'cloud-storage-destination-editor.placeholders.path-s3';
      case ConnectionTypeName.gs:
      case ConnectionTypeName.gsv2:
        return 'cloud-storage-destination-editor.placeholders.path-gs';
      case ConnectionTypeName.sftp:
        return 'cloud-storage-destination-editor.placeholders.path-sftp';
      default:
        return 'cloud-storage-destination-editor.placeholders.path';
    }
  }

  onPathInputChange({ value }) {
    if (this.rawComponent.connection.type === ConnectionTypeName.sftp) {
      if (value[0] !== '/') {
        this.onValueChange(`/${value}`, 'path');
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
