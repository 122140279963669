import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getMember,
  getMemberResponse,
  getMembersList,
  getMembersListError,
  getMembersListResponse,
  loadMoreMembersList,
  loadMoreMembersListResponse,
  removeMember,
  removeMemberError,
  removeMemberItem,
  removeMemberResponse,
  saveMember,
  saveMemberError,
  saveMemberResponse,
  updateMember,
  updateMemberError,
  updateMemberResponse,
} from './members.actions';
import { MembersResource } from '../../resources/members.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../../constants/animation-constants';
import { AppState } from '../../../store';
import { logout } from '../../../account/store/account.actions';
import { NotifyService } from '../../../common/services/notify.service';
import { getErrorFromResponse } from '../../../common/helper/response.helpers';

@Injectable()
export class MembersEffects {
  getMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMember),
      switchMap(({ memberId }) =>
        this.membersResource.get(memberId).pipe(
          map((member) => getMemberResponse({ member })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getMembersListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMembersList),
      switchMap(({ params }) =>
        this.membersResource.query(params).pipe(
          map((members) => getMembersListResponse({ members })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getMembersListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreMembersList),
      switchMap(({ params }) =>
        this.membersResource.query(params).pipe(
          map((members) => loadMoreMembersListResponse({ members })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getMembersListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMember),
      switchMap(({ member }) =>
        this.membersResource.save(member).pipe(
          map((memberResponse) => saveMemberResponse({ data: memberResponse })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(saveMemberError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  updateMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMember),
      switchMap(({ member }) =>
        this.membersResource.update(member.id, member).pipe(
          map((memberResponse) => {
            this.notify.success('Role has been successfully updated');
            return updateMemberResponse({ data: memberResponse });
          }),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(updateMemberError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  removeMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMember),
      switchMap(({ memberId, isLogout }) =>
        this.membersResource.remove(memberId).pipe(
          map((memberResponse) => {
            if (isLogout) {
              this.store.dispatch(logout({ shouldRedirect: true }));
            }
            return removeMemberItem({ member: memberResponse });
          }),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(removeMemberError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  removeMemberResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMemberItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ member }) => removeMemberResponse({ data: member })),
    ),
  );

  constructor(
    private actions$: Actions,
    private membersResource: MembersResource,
    protected store: Store<AppState>,
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}
}
