import { SelectOption } from '../common/components/forms/xp-select.component';

export const CUBE_ROLLUP_PICKER: SelectOption[] = [
  {
    text: 'fields-collection.editors.cube-rollup-picker.cube',
    value: 'cube',
    translate: true,
  },
  {
    text: 'fields-collection.editors.cube-rollup-picker.rollup',
    value: 'rollup',
    translate: true,
  },
];
