import * as runners from '../runners';
import { transformErrorFieldName } from '../../helper/transform-error-field-name.helper';
import { AppState } from '../../../store';
import { ValidatorRunner } from '../runners/models';
import { ServicesData } from '../runners/services.models';
import { ValidatorData } from './validator-data.model';

interface GetValidatorData {
  validators?: any;
  value: any;
  name: string;
  services: ServicesData;
  formScope: any;
  store: AppState;
  label?: string;
}

export function getValidatorData({
  validators,
  value,
  name,
  services,
  formScope,
  store,
  label,
}: GetValidatorData): ValidatorData[] {
  return Object.keys(validators || {}).reduce((acc, key) => {
    const options = validators[key];
    const runner = runners[key] as ValidatorRunner;

    if (runner) {
      const isValid = runner.validate({
        value: (value || '').replace('string:', ''),
        options,
        formScope,
        services,
        store,
      });
      return isValid
        ? [...acc, { valid: true }]
        : [
            ...acc,
            {
              valid: false,
              message:
                options.message ||
                services.translate.instant(runner.message, {
                  fieldName: transformErrorFieldName(label || name, runner.addArticle),
                  ...options,
                }),
              order: runner.order,
            },
          ];
    }

    return acc;
  }, []);
}

function getElementValue(element: HTMLInputElement): any {
  if (element.value) {
    return element.value;
  }

  if (element.tagName === 'CODE-EDITOR') {
    return element.querySelector('textarea').value;
  }

  return null;
}

export function serializeForm(form: HTMLFormElement, filteredFields: string[]): any {
  return [
    ...Array.from(form.querySelectorAll('input')),
    ...Array.from(form.querySelectorAll('select')),
    ...Array.from(form.querySelectorAll('textarea')),
    ...Array.from(form.querySelectorAll('code-editor')),
  ]
    .filter(
      (element: HTMLInputElement) =>
        !filteredFields.includes(element.getAttribute('name')) &&
        !(element.getAttribute('name') || '').includes('{{') &&
        element.getAttribute('name') !== null,
    )
    .reduce(
      (acc, element: HTMLInputElement) => ({ ...acc, [element.getAttribute('name')]: getElementValue(element) }),
      {},
    );
}
