import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationGuard } from '../common/services/authorization.guard';

@Component({
  selector: 'edit-package-proxy-component',
  template: ``,
})
export class EditComponentProxyComponent {
  constructor(private router: Router, private route: ActivatedRoute, private authGuard: AuthorizationGuard) {
    router.navigate([`/${this.authGuard.account?.account_id}/packages/${route.snapshot.params['package_id']}/edit`]);
  }
}
