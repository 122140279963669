import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AssistedSearchComponent, Token } from './assisted-search.component';
import { unquote } from './assisted-search-popup-list.component';

@Component({
  selector: 'assisted-search-popup-thumbs-list',
  template: `
    <div>
      <div *ngIf="visible">
        <span class="arrow"></span>
        <span class="arrow-border"></span>
        <div class="hint-menu">
          <span *ngFor="let listItem of listGrouped">
            <div class="section-header" *ngIf="listItem.section || token.sectionTitle">
              <hr />
              <span class="header-label">{{ listItem.section || token.sectionTitle }}</span>
            </div>

            <ul class="search-input-list">
              <li
                *ngFor="let item of listItem.list"
                (click)="selectItem(item.index)"
                [ngClass]="{ current: item.index === currentIndex }"
              >
                <span class="item-image text-center">
                  <img [src]="item.thumb_url" alt="thumb" />
                </span>
                <span class="item-text">
                  <b>{{ item.label | xpLengthCheck: 30 }}</b>
                  <span *ngIf="!areLabelAndValueEqual(item)" class="modifier-value">{{
                    item.value | xpLengthCheck: 30
                  }}</span>
                </span>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  `,
})
export class AssistedSearchPopupThumbsListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() token: Token;
  @Input() cursor: number;
  @Input() keyDownEvents: Observable<any>;
  @Input() keyUpEvents: Observable<any>;
  @Input() enterEvents: Observable<any>;

  listGrouped = [];
  visible: boolean = true;
  currentIndex: number = -1;

  private keyDownEventsSubscription: Subscription;
  private keyUpEventsSubscription: Subscription;
  private enterEventsSubscription: Subscription;

  constructor(
    private assistedSearch: AssistedSearchComponent,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.keyDownEventsSubscription = this.keyDownEvents.subscribe(() => this.goDown());
    this.keyUpEventsSubscription = this.keyUpEvents.subscribe(() => this.goUp());
    this.enterEventsSubscription = this.enterEvents.subscribe(() => this.select());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.token && changes.token.currentValue) {
      this.hintsChanged();
    }
  }

  hintsChanged() {
    if (!this.token.hints) {
      this.listGrouped = [];
    } else {
      const list = this.token.hints.reduce(
        function (sum, listItem) {
          const { section } = listItem;
          if (section) {
            if (sum[section]) {
              sum[section].push(listItem);
            } else {
              // eslint-disable-next-line no-param-reassign
              sum[section] = [listItem];
            }
          } else {
            sum.untitled.push(listItem);
          }
          return sum;
        },
        { untitled: [] },
      );

      const listArray = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in list) {
        listArray.push({ section: key, list: list[key] });
      }
      listArray.filter(function (item) {
        return item.section === 'untitled';
      })[0].section = '';
      let t = 0;
      const array = listArray
        .sort(function (a, b) {
          if (a.section < b.section) return -1;
          if (a.section > b.section) return 1;
          return 0;
        })
        .reverse()
        .map(function (section) {
          // eslint-disable-next-line no-param-reassign
          section.list = section.list.map(function (item) {
            // eslint-disable-next-line no-plusplus,no-param-reassign
            item.index = t++;
            return item;
          });
          return section;
        });
      this.listGrouped = array;
    }
    this.visible = this.getFlatList().length > 0;
  }

  onCurrentIndexChange() {
    if (this.currentIndex === -1) {
      return;
    }
    const listItemElement = this.elementRef.nativeElement.querySelectorAll('li')[this.currentIndex];
    const listElement = this.elementRef.nativeElement.querySelector('.search-input-list');

    const scroll = listElement.scrollTop;
    const listHeight = listElement.innerHeight;

    const itemHeight = listItemElement.offsetHeight;
    const top = listItemElement.offsetTop;
    const bottom = top + itemHeight;

    if (top < 0) {
      listElement.scrollTop(scroll + top);
    } else if (listHeight < bottom) {
      listElement.scrollTop(scroll + top - listHeight + itemHeight);
    }
  }

  goUp() {
    const len = this.getFlatList().length;
    if (!len) {
      return;
    }
    const currentIndex = this.currentIndex % len;
    if (currentIndex <= 0) {
      this.currentIndex = len - 1;
    } else {
      this.currentIndex = currentIndex - 1;
    }
    this.onCurrentIndexChange();
    this.assistedSearch.popupArrowsNavigating();
  }

  goDown() {
    const len = this.getFlatList().length;
    if (!len) {
      return;
    }
    const currentIndex = this.currentIndex % len;
    if (currentIndex >= len - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex = currentIndex + 1;
    }
    this.onCurrentIndexChange();
    this.assistedSearch.popupArrowsNavigating();
  }

  selectItem(index) {
    this.currentIndex = index;
    this.onCurrentIndexChange();
    this.select();
    return false;
  }

  getFlatList() {
    return this.listGrouped.reduce(function (sum, item) {
      return sum.concat(item.list);
    }, []);
  }

  select() {
    const list = this.getFlatList();
    if (this.currentIndex !== -1) {
      const item = list[this.currentIndex];

      if (item) {
        this.assistedSearch.changeTokenModel({
          token: this.token,
          model: item,
          shouldMoveCursor: true,
          shouldFocus: true,
        });
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  areLabelAndValueEqual(item) {
    return unquote(item.label) === unquote(item.value);
  }

  ngOnDestroy() {
    this.keyDownEventsSubscription.unsubscribe();
    this.keyUpEventsSubscription.unsubscribe();
    this.enterEventsSubscription.unsubscribe();
  }
}
