import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Key } from '../../key.models';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { saveKey } from '../../store/keys/keys.actions';
import { selectKeysErrors, selectKeysIsSubmittingFlag } from '../../store/keys/keys.selectors';

@Component({
  selector: 'key-form',
  template: `
    <div class="key-form">
      <div class="key-form-body">
        <xp-form-validation type="Key" [name]="formName">
          <form id="keyForm" name="keyForm" #form="ngForm" class="modal-form-container">
            <div class="form-group">
              <xp-form-group>
                <label for="name"
                  >{{ 'ssh_key.form.labels.name' | translate
                  }}<span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
                >
                <xp-input
                  id="name"
                  type="name"
                  name="name"
                  [(ngModel)]="item.name"
                  class="form-control"
                  placeholder="{{ 'ssh_key.form.placeholders.name' | translate }}"
                ></xp-input>
              </xp-form-group>
              <small>{{ 'ssh_key.form.hints.name' | translate }}</small>
            </div>
            <div class="form-group">
              <xp-form-group>
                <label for="public_key">{{ 'ssh_key.form.labels.key' | translate }}</label>
                <textarea id="public_key" rows="7" name="public_key" [(ngModel)]="item.public_key" class="form-control">
                </textarea>
              </xp-form-group>
              <small>{{ 'ssh_key.form.hints.key' | translate }}</small>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="key-form-footer modal-footer">
        <div class="modal-title-container">
          <h3 class="modal-title">{{ 'ssh_key.generic-object.title' | translate }}</h3>
        </div>

        <xp-submit-button
          (click)="createKey(item)"
          classNames="btn-lg btn-success pull-right"
          [createText]="'ssh_key.form.buttons.create' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
          *ngxPermissionsOnly="'createKey'"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class KeyFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  item: Partial<Key> = {};
  @ViewChild('form') form: NgForm;
  errors$ = this.store.select(selectKeysErrors);
  errorTexts = [];
  formName = 'keyForm';
  successMessageText = 'key.form.success_message';
  isSubmitting$ = this.store.select(selectKeysIsSubmittingFlag);

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  createKey(key: Partial<Key>) {
    this.store.dispatch(saveKey({ key: { ...key } }));

    this.router.navigate(['./'], { relativeTo: this.route });
  }
}
