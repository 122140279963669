import * as VS from '../helpers/validators.helpers';
import { ValidatorRunner } from './models';

export const PresenceValidator: ValidatorRunner = {
  name: 'PresenceValidator',
  message: 'errors.PresenceValidator',
  order: 2,
  addArticle: true,
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    return VS.isNotEmpty(value);
  },
};
