import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../store';
import { SelectPickerTypes } from '../components/forms/select-picker/select-picker-types.enum';
import { SelectPickerState } from './select-picker.reducer';

export const selectSelectPickerState = createFeatureSelector<SelectPickerState>('selectPicker');

export const selectItemsForSelectPicker = (id: string, type: SelectPickerTypes) =>
  createSelector(selectSelectPickerState, (state: SelectPickerState) => (state[type][id] ? state[type][id].items : []));

export const selectIsLoadingFlagForSelectPicker = (id: string, type: SelectPickerTypes) =>
  createSelector(selectSelectPickerState, (state: SelectPickerState) =>
    state[type][id] ? state[type][id].isLoading : true,
  );

export const selectIsLazyLoadingFlagForSelectPicker = (id: string, type: SelectPickerTypes) =>
  createSelector(selectSelectPickerState, (state: SelectPickerState) =>
    state[type][id] ? state[type][id].isLazyLoad : true,
  );
