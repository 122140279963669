const grid_cell = 28;

export const DESIGNER_SETTINGS = {
  CELL: grid_cell,
  COMPONENT_WIDTH: grid_cell * 5,
  COMPONENT_HEIGHT: grid_cell * 3,
  NOTE_WIDTH: grid_cell * 8,
  NOTE_HEIGHT: grid_cell * 6,
  PADDING: grid_cell * 3,
  PACKAGE_DEFAULT_FLOW_TYPE: 'dataflow',
  FLOW_VERSION: '2.0.0',
  OLD_FLOW_VERSION: '1.0.0',
  MAX_NAME_LENGTH: 15,
  TOP_MARGIN_CELL: 42,
  PANEL_SCALE: 0.18,
  NOTE_PADDING_VERTICAL: 20,
  NOTE_PADDING_HORIZONTAL: 55,
};
