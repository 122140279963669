import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { difference } from 'lodash';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { CUBE_ROLLUP_PICKER } from '../../../../constants/cube_rollup_picker';
import { AvailableField } from '../collections/cube-rollup-collection.component';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { FieldsCollectionValidationsService } from '../../../../common/services/fields-collection-validations.service';
import { ValidationsEnum } from '../../../../common/services/validations.enum';
import { ValidatorData } from '../../../../common/validators/helpers/validator-data.model';

@Component({
  selector: 'xp-ordered-fields-picker',
  template: `
    <div class="ordered-fields-picker" [ngClass]="state">
      <xp-select
        [options]="filteredFields"
        [value]="value"
        [isMultiple]="true"
        [isSearchEnabled]="true"
        (valueChange)="onValueChange($event)"
        [placeholder]="placeholder"
        [tooltip]="tooltip"
        panelClass="ordered-field-picker-panel"
        emptyPlaceholder="No field found"
      >
      </xp-select>
    </div>
  `,
  providers: [],
})
export class XpOrderedFieldsPickerComponent implements OnChanges {
  @Input() value: string[];
  @Input() index: number;
  @Input() fields: AvailableField[];
  @Input() schema: DesignerSchema;
  @Input() record: DesignerSchemaFieldI;
  @Input() isOutsideDuplicateError: boolean;
  @Input() outsideValidationMessage: string;
  @Output() fieldChange = new EventEmitter();

  options: SelectOption[] = CUBE_ROLLUP_PICKER;

  validate: (...args: any) => ValidatorData;
  filteredFields: AvailableField[];
  placeholder = '';
  previousValue = [];
  state = '';
  tooltip = '';
  isFirstChange = true;

  constructor(
    private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
  ) {
    this.validate = this.fieldsCollectionValidationsService.getValidationFunction(
      ValidationsEnum.ORDERED_FIELDS_PICKER,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && changes.fields.currentValue) {
      const fields = changes.fields.currentValue;
      this.filteredFields = (fields || []).filter((field) => !field.record || field.record.id === this.record.id);
      this.placeholder = this.filteredFields.length === 0 ? 'No fields found in input' : 'Select fields';
    }

    if (changes.isOutsideDuplicateError) {
      this.validityFn(this.value);
    }
  }

  onValueChange(value: string[]) {
    if (this.value === value) {
      return;
    }
    this.previousValue = this.value;
    this.value = value || [];

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    const removed = difference(this.previousValue, this.value);
    const added = difference(this.value, this.previousValue);

    this.fieldChange.emit({ value, added, removed });
    this.validityFn(value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }

  validityFn(value: string[]): boolean {
    if (this.xpFieldsCollectionComponent && this.xpFieldsCollectionComponent.isRecordPristine(this.index)) {
      return false;
    }

    const validityObject = this.validate(value, this.schema);
    this.setValidity(validityObject);

    return validityObject.valid;
  }

  setValidity(validityObject: Partial<ValidatorData>) {
    const isValid = validityObject.valid && !this.isOutsideDuplicateError;

    if (!isValid) {
      this.state = 'error';
      if (this.isOutsideDuplicateError) {
        this.tooltip = this.outsideValidationMessage;
      } else {
        this.tooltip = validityObject.message;
      }
    } else {
      this.state = 'editable';
      this.tooltip = '';
    }

    this.registerError(isValid, this.tooltip);
  }

  registerError(isValid: boolean, message: string) {
    if (this.xpFieldsCollectionComponent) {
      if (!isValid) {
        this.xpFieldsCollectionComponent.registerError(this.index, message, 'fields');
      } else {
        this.xpFieldsCollectionComponent.removeError(this.index, 'fields');
      }
    }
  }
}
