import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { createMajorVersion } from '../../store/package-designer.actions';

@Component({
  selector: 'package-version-message-form',
  template: `
    <div class="package-json-import-form row">
      <span class="col-md-10 col-md-offset-1">
        {{ 'package_major_version_form.title' | translate }}<br />
        <textarea maxlength="255" class="form-control" [(ngModel)]="versionMessage"></textarea>
      </span>
    </div>
    <div class="modal-footer">
      <div class="modal-title-container">
        <h3 class="modal-title">{{ 'package.controller.modals.major_version.actions.title' | translate }}</h3>
      </div>
      <button type="button" class="btn btn-lg btn-success pull-right modal-btn-save" (click)="importJSON()">
        {{ 'package.controller.modals.major_version.actions.save' | translate }}
      </button>
    </div>
  `,
})
export class PackageVersionMessageFormComponent {
  versionMessage: string = '';

  constructor(private store: Store<AppState>) {}

  importJSON() {
    this.store.dispatch(createMajorVersion({ message: this.versionMessage }));
  }
}
