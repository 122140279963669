import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'xp-chips',
  template: `
    <input #inputRef class="hidden" [id]="id + '1'" [name]="name" type="text" />
    <mat-chip-grid #chipList (blur)="onBlurEvent()">
      <mat-chip-row *ngFor="let item of valueArray" [selectable]="true" [removable]="true" (removed)="remove(item)">
        {{ item }}
        <i class="fa fa-times" matChipRemove></i>
      </mat-chip-row>
      <input
        placeholder="Add"
        [placeholder]="placeholder"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  `,
})
export class XpChipsComponent {
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<any>();
  @Input() id = String(Date.now());
  @Input() name = '';
  @Input() placeholder = '';
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  get valueArray(): string[] {
    return (this.value || '').split(',').filter(Boolean);
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;

    if ((value || '').trim()) {
      this.value = [...this.valueArray, value.trim()].join(',');
      this.onValueChange(this.value);
    }

    if (input) {
      input.value = '';
    }
  }

  remove(value: string): void {
    this.value = this.valueArray.filter((item) => item !== value.trim()).join(',');
    this.onValueChange(this.value);
  }

  onValueChange(value) {
    this.valueChange.emit(value);
    this.inputRef.nativeElement.value = value;
    this.inputRef.nativeElement.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      }),
    );
  }

  onBlurEvent() {
    this.inputRef.nativeElement.dispatchEvent(
      new Event('blur', {
        bubbles: true,
        cancelable: true,
      }),
    );
  }
}
