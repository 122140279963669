interface HttpErrorResponse {
  error: {
    error_message?: string;
    message: string;
  };
  message: string;
  status: number;
}

export function getMessageFromError(res: HttpErrorResponse): string {
  if (res.error.error_message) {
    return res.error.error_message;
  } else if (res.error.message) {
    return `Backend returned code ${res.status}, body was: ${res.error.message}`;
  } else {
    return res.message;
  }
}
