import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FieldsCollectionValidationsService } from '../../../../common/services/fields-collection-validations.service';
import { ValidationsEnum } from '../../../../common/services/validations.enum';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ValidatorData } from '../../../../common/validators/helpers/validator-data.model';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';

const WINDOW_ARGUMENT_PROPS: Array<keyof DesignerSchemaFieldI> = [
  'offset',
  'default_value',
  'range_before',
  'range_after',
  'ntiles',
];

@Component({
  selector: 'xp-window-arguments-editor',
  template: `
    <div class="window-arguments" [ngClass]="state">
      <div class="window-arguments-wrapper">
        <div *ngIf="isOffsetVisible" class="window-argument">
          <label>offset</label>
          <xp-input
            [ngModel]="offsetValue"
            name="offset"
            (ngModelChange)="onValueChange($event, 'offset')"
            [state]="offset_state"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            placeholder="-∞"
            [tooltip]="offset_tooltip"
            [isFocused]="focusedProp === 'offset'"
          ></xp-input>
        </div>
        <div *ngIf="isDefaultVisible" class="window-argument">
          <label>default</label>
          <xp-input
            [ngModel]="defaultValue"
            name="default_value"
            (ngModelChange)="onValueChange($event, 'default_value')"
            [state]="default_value_state"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            placeholder="-∞"
            [tooltip]="default_value_tooltip"
            [isFocused]="focusedProp === 'default_value'"
          ></xp-input>
        </div>
        <div *ngIf="isRangeBeforeVisible" class="window-argument">
          <label>preceding range</label>
          <xp-input
            [ngModel]="rangeBeforeValue"
            name="range_before"
            (ngModelChange)="onValueChange($event, 'range_before')"
            [state]="range_before_state"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            placeholder="-∞"
            [tooltip]="range_before_tooltip"
            [isFocused]="focusedProp === 'range_before'"
          ></xp-input>
        </div>
        <div *ngIf="isRangeAfterVisible" class="window-argument">
          <label>following range</label>
          <xp-input
            [ngModel]="rangeAfterValue"
            name="range_after"
            (ngModelChange)="onValueChange($event, 'range_after')"
            [state]="range_after_state"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            placeholder="∞"
            [tooltip]="range_after_tooltip"
            [isFocused]="focusedProp === 'range_after'"
          ></xp-input>
        </div>
        <div *ngIf="isNtilesVisible" class="window-argument">
          <label>number of ntiles</label>
          <xp-input
            [ngModel]="ntilesValue"
            name="ntiles"
            (ngModelChange)="onValueChange($event, 'ntiles')"
            [state]="ntiles_state"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            placeholder="∞"
            [tooltip]="ntiles_tooltip"
            [isFocused]="focusedProp === 'ntiles'"
          ></xp-input>
        </div>
      </div>
    </div>
  `,
  providers: [],
})
export class XpWindowArgumentsEditorComponent implements OnChanges, OnInit {
  @Input() offsetValue: string;
  @Input() defaultValue: string;
  @Input() rangeBeforeValue: string;
  @Input() rangeAfterValue: string;
  @Input() ntilesValue: string;
  @Input() function: string;
  @Input() index: number;
  @Input() focusedProp: keyof DesignerSchemaFieldI;
  @Output() offsetValueChange = new EventEmitter();
  @Output() defaultValueChange = new EventEmitter();
  @Output() rangeBeforeValueChange = new EventEmitter();
  @Output() rangeAfterValueChange = new EventEmitter();
  @Output() ntilesValueChange = new EventEmitter();
  validate: (...args: any) => ValidatorData;
  state: string = 'editable';
  isActive: boolean;
  tooltip = '';
  isFirstChange = true;
  FUNCTIONS = {
    LEAD: 'lead',
    LAG: 'lag',
    NTILE: 'ntile',
  };
  isOffsetVisible = false;
  isDefaultVisible = false;
  isRangeBeforeVisible = false;
  isRangeAfterVisible = false;
  isNtilesVisible = false;
  offset_tooltip = '';
  default_value_tooltip = '';
  range_before_tooltip = '';
  range_after_tooltip = '';
  ntiles_tooltip = '';
  offset_state = 'editable';
  default_value_state = 'editable';
  range_before_state = 'editable';
  range_after_state = 'editable';
  ntiles_state = 'editable';
  isFirstChangeValue = {
    offset: true,
    default_value: true,
    range_before: true,
    range_after: true,
    ntiles: true,
  };

  constructor(
    private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
  ) {
    this.validate = this.fieldsCollectionValidationsService.getValidationFunction(
      ValidationsEnum.WINDOW_ARGUMENTS_EDITOR,
    );
  }

  ngOnInit() {
    this.initFunction();
    this.setDefaults();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const { currentValue } = changes.value;
      const { previousValue } = changes.value;

      WINDOW_ARGUMENT_PROPS.filter((prop) => !previousValue || currentValue[prop] !== previousValue[prop]).forEach(
        (prop) => {
          this.validityFn(currentValue[prop], prop);
        },
      );
    }

    if (changes.function && changes.function.currentValue) {
      this.initFunction();
      this.setDefaults();
    }
  }

  onInputFocus() {
    this.isActive = true;
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: this.isActive });
    }
  }

  onInputBlur() {
    this.isActive = false;
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: this.isActive });
    }
  }

  onValueChange(value: string, prop: keyof DesignerSchemaFieldI) {
    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.validityFn(value, prop);

    if (prop === 'offset') {
      this.offsetValueChange.emit(value);
    }
    if (prop === 'default_value') {
      this.defaultValueChange.emit(value);
    }
    if (prop === 'range_after') {
      this.rangeAfterValueChange.emit(value);
    }
    if (prop === 'range_before') {
      this.rangeBeforeValueChange.emit(value);
    }
    if (prop === 'ntiles') {
      this.ntilesValueChange.emit(value);
    }
  }

  validityFn(value: string, prop: string): boolean {
    if (this.xpFieldsCollectionComponent && this.xpFieldsCollectionComponent.isRecordPristine(this.index)) {
      return false;
    }

    const validityObject = this.validate(value, prop, this.function);
    this.setValidity(validityObject, prop);

    return validityObject.valid;
  }

  setValidity(validityObject: Partial<ValidatorData>, prop: string) {
    const state = !validityObject.valid ? 'error' : 'editable';
    const tooltip = !validityObject.valid ? validityObject.message : '';

    if (prop === 'offset') {
      if (this.isFirstChangeValue.offset) {
        this.isFirstChangeValue.offset = false;
        return;
      }
      this.offset_state = state;
      this.offset_tooltip = tooltip;
    }
    if (prop === 'default_value') {
      if (this.isFirstChangeValue.default_value) {
        this.isFirstChangeValue.default_value = false;
        return;
      }
      this.default_value_state = state;
      this.default_value_tooltip = tooltip;
    }
    if (prop === 'range_after') {
      if (this.isFirstChangeValue.range_after) {
        this.isFirstChangeValue.range_after = false;
        return;
      }
      this.range_after_state = state;
      this.range_after_tooltip = tooltip;
    }
    if (prop === 'range_before') {
      if (this.isFirstChangeValue.range_before) {
        this.isFirstChangeValue.range_before = false;
        return;
      }
      this.range_before_state = state;
      this.range_before_tooltip = tooltip;
    }
    if (prop === 'ntiles') {
      if (this.isFirstChangeValue.ntiles) {
        this.isFirstChangeValue.ntiles = false;
        return;
      }
      this.ntiles_state = state;
      this.ntiles_tooltip = tooltip;
    }

    this.registerError(validityObject.valid, validityObject.message, prop);
  }

  registerError(isValid: boolean, message: string, prop: string) {
    if (this.xpFieldsCollectionComponent) {
      if (!isValid) {
        this.xpFieldsCollectionComponent.registerError(this.index, message, prop);
      } else {
        this.xpFieldsCollectionComponent.removeError(this.index, prop);
      }
    }
  }

  initFunction() {
    this.isOffsetVisible = false;
    this.isDefaultVisible = false;
    this.isRangeBeforeVisible = false;
    this.isRangeAfterVisible = false;
    this.isNtilesVisible = false;

    switch (this.function) {
      case this.FUNCTIONS.LEAD:
      case this.FUNCTIONS.LAG:
        this.isOffsetVisible = true;
        this.isDefaultVisible = true;
        this.registerError(true, null, 'range_before');
        this.registerError(true, null, 'range_after');
        this.registerError(true, null, 'ntiles');
        break;
      case this.FUNCTIONS.NTILE:
        this.isRangeBeforeVisible = true;
        this.isRangeAfterVisible = true;
        this.isNtilesVisible = true;
        this.registerError(true, null, 'offset');
        this.registerError(true, null, 'default_value');
        break;
      default:
        this.registerError(true, null, 'offset');
        this.registerError(true, null, 'default_value');
        this.registerError(true, null, 'ntiles');
        this.isRangeAfterVisible = true;
        this.isRangeBeforeVisible = true;
        break;
    }
  }

  setDefaults() {
    // eslint-disable-next-line default-case
    switch (this.function) {
      case this.FUNCTIONS.LEAD:
      case this.FUNCTIONS.LAG:
        this.offsetValueChange.emit(this.offsetValue || '1');
        break;
      case this.FUNCTIONS.NTILE:
        this.rangeBeforeValueChange.emit(this.rangeBeforeValue || '');
        this.rangeAfterValueChange.emit(this.rangeAfterValueChange || '');
        this.ntilesValueChange.emit(this.ntilesValue || '10');
        break;
    }
  }
}
