import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidatorState } from './validators.reducer';

export const selectValidation = createFeatureSelector<ValidatorState>('validation');

export const selectValidators = createSelector(selectValidation, (state: ValidatorState) => state.validators);

export const selectForms = createSelector(selectValidation, (state: ValidatorState) => state.forms);
export const selectFormMessages = createSelector(selectValidation, (state: ValidatorState) => state.formMessages);

export const selectFormValidationData = (formName: string) =>
  pipe(
    select(selectForms),
    map((forms) => {
      return forms[formName] || {};
    }),
  );

export const selectFormFieldValidationMessage = (formName: string, formField: string) =>
  pipe(
    select(selectFormMessages),
    map((formMessages) => {
      return (formMessages[formName] || {})[formField] || '';
    }),
  );
