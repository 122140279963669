import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SearchConfig } from './search-config.model';
import { AppState } from '../../../store';
import { Package } from '../../../packages/package.models';
import { getPackagesList } from '../../../packages/store/packages.actions';
import { selectPackages } from '../../../packages/store/packages.selectors';

const basicConfig: SearchConfig = {
  'id:': {
    type: 'default',
    defaultHint: 'Schedules with this ID',
  },
  'name:': {
    defaultHint: 'Schedules with this name',
  },
  'description:': {
    defaultHint: 'Schedules with this description',
  },
  'status:': {
    type: 'list',
    defaultHint: 'Schedules in status',
    sectionTitle: 'Status',
    content: [
      { value: 'enabled', label: 'Enabled' },
      { value: 'disabled', label: 'Disabled' },
    ],
  },
  'package:': {
    type: 'list',
    defaultHint: 'Schedules with matching packages',
    sectionTitle: 'Packages',
    content: [], // list-compiliant autocomplete items will go here
  },
};

interface ScheduleSearchItem {
  id: number;
  value: string;
  label: string;
}

function prepareValue(stringVal: string): string {
  if (stringVal && typeof stringVal.indexOf !== 'undefined' && stringVal.indexOf(' ') !== -1) {
    return `"${stringVal}"`; // wrap in quotes if contains spaces
  }
  return stringVal;
}

function formatPackageForList(u: Package): ScheduleSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name),
    label: u.name,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssistedSearchSchedulesConfiguratorService {
  constructor(private store: Store<AppState>) {}

  getConfig$(): Observable<SearchConfig> {
    this.store.dispatch(getPackagesList({ params: {} }));

    return this.store.select(selectPackages).pipe(
      map((packages) => {
        const configCopy = { ...basicConfig };
        configCopy['package:'].content = packages.map(formatPackageForList);
        return configCopy;
      }),
    );
  }
}
