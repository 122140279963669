import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { AppState } from '../../../store';
import { EdgeEvent, ExtendedEdge } from '../../package.models';
import { selectFixedEdgeById } from '../../store/package-designer.selectors';
import { removeEdge, updateEdge } from '../../store/package-designer.actions';

function getPathData(edge: ExtendedEdge): string {
  return `M0,0 C${edge.sourceCurvePosition.x},${edge.sourceCurvePosition.y},${edge.targetCurvePosition.x},${
    edge.targetCurvePosition.y
  },${edge.targetPosition.x - edge.sourcePosition.x},${edge.targetPosition.y - edge.sourcePosition.y}`;
}

@Component({
  selector: 'xp-designer-edge',
  template: `
    <svg
      *ngIf="edge && edge.id"
      class="designer-edge"
      [id]="edge.id"
      [ngClass]="{
        success: edge.event === edgeEvents.success,
        failure: edge.event === edgeEvents.failure,
        completion: edge.event === edgeEvents.completion
      }"
      [ngStyle]="{
        transform: 'translate(' + edge.sourcePosition.x + 'px, ' + edge.sourcePosition.y + 'px)',
        width: '10px',
        height: '10px'
      }"
    >
      <g>
        <path class="designer-edge-path" [attr.d]="pathData" />
        <path class="designer-edge-path designer-edge-path-dashed" [attr.d]="pathData" />

        <rect
          class="designer-edge-connector"
          [ngStyle]="{
            transform:
              'translate(' +
              (edge.targetPosition.x - edge.sourcePosition.x) +
              'px, ' +
              (edge.targetPosition.y - edge.sourcePosition.y) +
              'px)'
          }"
        ></rect>

        <g
          class="designer-edge-toggle"
          [ngStyle]="{ transform: 'translate(' + edge.togglePosition.x + 'px, ' + edge.togglePosition.y + 'px)' }"
          [matMenuTriggerFor]="dropdown"
          (mousedown)="openDropDown()"
        >
          <circle cx="10.5" cy="10.5" r="10.5" />
          <path
            d="m 6.6398305,8.9237288 3.9999995,3.5555602 4,-3.5555612"
            stroke="#3d4651"
            stroke-width="2"
            fill="transparent"
            id="path405"
          />
        </g>
      </g>
    </svg>
    <mat-menu #dropdown="matMenu">
      <li *ngIf="edge.event" mat-menu-item (click)="onFailure()" class="failure">
        <span>On failure</span>
      </li>
      <li *ngIf="edge.event" mat-menu-item (click)="onCompletion()" class="completion">
        <span>On completion</span>
      </li>
      <li *ngIf="edge.event" mat-menu-item (click)="onSuccess()" class="success bottom-separator">
        <span>On success</span>
      </li>
      <li mat-menu-item (click)="removeEdge()" class="remove">
        <span>Disconnect</span>
      </li>
    </mat-menu>
  `,
})
export class DesignerEdgeComponent implements OnDestroy, OnInit {
  @Input() edgeId: string;
  edge: Partial<ExtendedEdge> = {};
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  pathData = '';
  edgeSubscription: Subscription;
  edgeEvents = EdgeEvent;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.edgeSubscription = this.store
      .select(selectFixedEdgeById(this.edgeId))
      .pipe(filter(Boolean))
      .subscribe((edge: ExtendedEdge) => {
        this.edge = edge;
        this.pathData = getPathData(edge);
      });
  }

  removeEdge() {
    this.store.dispatch(removeEdge({ edge: this.edge as ExtendedEdge }));
  }

  openDropDown() {
    this.trigger.openMenu();
  }

  onFailure() {
    this.store.dispatch(updateEdge({ edgeId: this.edge.id, event: EdgeEvent.failure }));
  }

  onCompletion() {
    this.store.dispatch(updateEdge({ edgeId: this.edge.id, event: EdgeEvent.completion }));
  }

  onSuccess() {
    this.store.dispatch(updateEdge({ edgeId: this.edge.id, event: EdgeEvent.success }));
  }

  ngOnDestroy() {
    if (this.edgeSubscription) {
      this.edgeSubscription.unsubscribe();
    }
  }
}
