/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { NAV_DATA_KEY } from '../../constants/app';
import { loginSuccess } from '../store/account.actions';
import { AppState } from '../../store';
import { AuthService } from '../../common/services/auth.service';
import { BoomerangService } from '../services/boomerang.service';

@Component({
  selector: 'partners',
  template: ` <xp-loader></xp-loader> `,
})
export class PartnersComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private authService: AuthService,
    private boomerangService: BoomerangService,
  ) {}

  ngOnInit() {
    this.authService.setToken(this.route.snapshot.queryParams.token);

    window.localStorage.setItem(NAV_DATA_KEY, this.route.snapshot.queryParams[NAV_DATA_KEY]);

    this.boomerangService.init();

    this.store.dispatch(loginSuccess());

    this.router.navigate(['/launchpad']);
  }
}
