import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { HubspotDestinationComponentData } from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { NotifyService } from '../../../common/services/notify.service';
import { DesignerSchemaFieldI } from '../../models/designer-schema-field.model';
import { getMessageFromError } from '../../../connections/helpers/http-errors.helpers';

@Component({
  selector: 'hubspot-object-picker',
  template: `
    <div>
      <div class="row">
        <div class="col-sm-6">
          <xp-form-group>
            <label for="object_name">{{ component.componentType + '.form.labels.object_name' | translate }}</label>
            <xp-select
              class="form-control xp-select"
              name="object_name"
              id="object_name"
              [value]="rawComponent.object_name"
              [options]="objectNameOptions"
              [preventEmpty]="true"
              (valueChange)="onObjectNameChange($event)"
            ></xp-select>
          </xp-form-group>
        </div>
        <div class="col-sm-6">
          <div class="form-group refresh-button">
            <button class="btn btn-default" (click)="loadObjects()">
              <i class="fa fa-refresh" [ngClass]="{ 'fa-spin fa-fw': loadingObjects }"></i> Refresh
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="error-message higher-message" *ngIf="!loadingObjects && errorMessage">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class HubspotObjectPickerComponent implements OnChanges {
  @Input() rawComponent: HubspotDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Output() fieldsLoadStart = new EventEmitter();
  @Output() fieldsLoadEnd = new EventEmitter();

  objectNameOptions = [];
  loadingObjects = false;
  errorMessage = null;
  lastObjectName = null;

  constructor(
    private connectionItemsResource: ConnectionItemsResource,
    private translate: TranslateService,
    private store: Store<AppState>,
    private notify: NotifyService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentValue = changes.rawComponent.currentValue || {};
    const previousValue = changes.rawComponent.previousValue || {};
    if (currentValue.connection !== previousValue.connection) {
      this.loadObjects();
    }
  }

  loadObjects() {
    if (this.rawComponent.connection && this.rawComponent.connection.type && this.rawComponent.connection.id) {
      this.loadingObjects = true;

      const schemaRequestData: any = {
        force_fetch: true,
      };

      this.connectionItemsResource.objects('hubspot', this.rawComponent.connection.id, schemaRequestData).subscribe({
        next: (response) => {
          if (this.rawComponent.object_name) {
            this.lastObjectName = this.rawComponent.object_name;
          }

          this.objectNameOptions = response.objects.map((entity) => ({ text: entity, value: entity }));
          this.loadingObjects = false;
          if (this.lastObjectName !== this.rawComponent.object_name) {
            this.errorMessage = null;
            this.store.dispatch(
              updateRawComponent({
                rawComponent: { object_name: this.lastObjectName },
              }),
            );
            this.store.dispatch(updateComponent({ component: { object_name: this.lastObjectName } }));
          } else if (!this.objectNameOptions.map((item) => item.value).includes(this.lastObjectName)) {
            this.errorMessage = this.translate.instant('hubspot-destination-editor.form.errors.object_name', {
              objectName: this.lastObjectName,
            });
          } else {
            this.errorMessage = null;
          }
          this.loadFields(this.lastObjectName);
        },
        error: (res) => {
          this.loadingObjects = false;
          this.errorMessage = res.error.error_message || res.error.error;
        },
      });
    }
  }

  loadFields(objectName: string) {
    if (this.rawComponent.connection && this.rawComponent.connection.type && objectName && objectName !== '') {
      const schemaRequestData: any = {
        object_name: objectName,
        force_fetch: true,
      };

      this.connectionItemsResource
        .writableFields('hubspot', this.rawComponent.connection.id, schemaRequestData)
        .subscribe({
          next: (fieldsList: { fields: DesignerSchemaFieldI[] }) => {
            const fields = [];
            fieldsList.fields
              .filter((field) => field.name !== 'hs_object_id')
              .forEach((field) => {
                fields.push({
                  name: field.name,
                  alias: field.name,
                  data_type: field.type,
                  length: field.length,
                  category: 'Schema',
                  is_keyable: field.is_keyable,
                  is_required: field.is_required,
                });
              });
            this.fieldsLoadEnd.emit(fields);
            this.errorMessage = '';
          },
          error: (res) => {
            this.notify.error('An error occurred while loading fields data.');
            this.fieldsLoadEnd.emit([]);
            this.errorMessage = getMessageFromError(res);
          },
        });
    }
  }

  onObjectNameChange(objectName: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { object_name: objectName },
      }),
    );
    this.store.dispatch(updateComponent({ component: { object_name: objectName } }));

    this.errorMessage = null;
    this.fieldsLoadStart.emit();
    this.loadFields(objectName);
  }
}
