import { createAction, props } from '@ngrx/store';
import { SelectPickerTypes } from '../components/forms/select-picker/select-picker-types.enum';
import { ConnectionTypeName } from '../../connections/connection.models';
import { ListParams } from '../helper/query-params-generic-list.helper';
import { SelectPickerValue } from '../components/forms/select-picker/xp-select-picker.component';

export interface loadSelectPickerItemProps {
  id: string;
  itemType: SelectPickerTypes;
  itemId: number;
  params: ListParams;
  connectionType?: ConnectionTypeName;
}

export const loadSelectPickerItems = createAction(
  '[SelectPicker] Load select picker items',
  props<{
    id: string;
    itemType: SelectPickerTypes;
    params: ListParams;
    ignoreLoadingFlag?: boolean;
    isLazyLoad?: boolean;
  }>(),
);
export const loadSelectPickerItemsResponse = createAction(
  '[SelectPicker] Load select picker items response',
  props<{ id: string; items: SelectPickerValue[]; itemType: SelectPickerTypes; isLazyLoad?: boolean }>(),
);

export const loadSelectPickerItem = createAction(
  '[SelectPicker] Load select picker item',
  props<loadSelectPickerItemProps>(),
);
export const loadSelectPickerItemResponse = createAction(
  '[SelectPicker] Load select picker item response',
  props<{ id: string; item: SelectPickerValue; itemType: SelectPickerTypes }>(),
);
export const loadSelectPickerItemError = createAction(
  '[SelectPicker] Load select picker item error',
  props<{ id: string; error: string; itemType: SelectPickerTypes }>(),
);

export const loadSelectPickerItemsWithSearch = createAction(
  '[SelectPicker] Load select picker items with search',
  props<{ id: string; itemType: SelectPickerTypes; params: ListParams }>(),
);
export const loadSelectPickerItemsWithSearchResponse = createAction(
  '[SelectPicker] Load select picker items with search response',
  props<{ id: string; items: SelectPickerValue[]; itemType: SelectPickerTypes }>(),
);

export const updateSelectPickerItem = createAction(
  '[SelectPicker] Update select picker item',
  props<{ id: string; item: SelectPickerValue; itemType: SelectPickerTypes }>(),
);
