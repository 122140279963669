import { Component, Input } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';

@Component({
  selector: 'xp-alias-editor-title',
  template: `
    <div class="alias-editor-title">
      <div class="editor-button">
        <div class="base-icon">
          <i class="fa fa-magic" (click)="autoGenerateAll()"></i>
        </div>
      </div>
      <span *ngIf="title">{{ title }}</span>
      <span *ngIf="!title">{{ 'fields-collection.headers.alias-editor' | translate }}</span>
    </div>
  `,
  providers: [],
})
export class XpAliasEditorTitleComponent {
  @Input() title: string;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  autoGenerateAll() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.autoGenerateAll();
    }
  }
}
