import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, Credentials } from '../../common/services/auth.service';
import { NotifyService } from '../../common/services/notify.service';
import { AppState } from '../../store';
import { loginSuccess } from '../store/account.actions';
import { PusherService } from '../../common/services/pusher.service';
import { getSSOFlag } from '../helpers/account.helpers';
import { environment } from '../../../environments/environment';
import { SegmentService } from '../../common/services/segment.service';

// cross-check name with back-end controller
const cookieName = 'stlzr_tkn';

function getCookie(name: string): string {
  const foundCookie = decodeURIComponent(document.cookie)
    .split(';')
    .map((cookie) => cookie.trim().split('='))
    .find((keyValue) => keyValue[0] === name);

  return foundCookie ? foundCookie[1] : null;
}

function removeCookie(name: string, paths: string[], domains: string[]): void {
  // must use path to delete the correct cookie
  paths.forEach(function (path) {
    domains.forEach(function (domain) {
      document.cookie = `${name}=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=${path}; domain=${domain};`;
    });
  });
}

@Component({
  selector: 'sign-in',
  template: `
    <div class="panels-container">
      <div class="panel">
        <div class="panel-header">
          <div class="panel-logo">
            <img class="logo-img" width="80" height="80" src="assets/img/xplenty-logo.svg" alt="Prepforce" />
          </div>
          <h3 class="panel-title">{{ 'auth.sign_in.title' | translate }}</h3>
        </div>
        <div class="panel-body">
          <div *ngIf="isMFA" style="padding-top: 85px;" class="form-group">
            <label>{{ 'auth.sign_in.labels.enter_mfa' | translate }}</label>
            <span>
              <input
                class="form-control"
                name="mfa_code"
                [(ngModel)]="credentials.mfa_code"
                type="text"
                maxlength="6"
                required
              />
            </span>
          </div>

          <xp-form-validation type="User">
            <form role="form" name="signInForm" #form="ngForm">
              <fieldset *ngIf="!isMFA">
                <div class="form-group">
                  <xp-form-group>
                    <label>{{ 'auth.sign_in.form.labels.email' | translate }}</label>
                    <input
                      class="form-control"
                      [(ngModel)]="credentials.email"
                      [placeholder]="'auth.sign_in.form.placeholders.email' | translate"
                      name="email"
                      type="email"
                      maxlength="254"
                      autofocus
                      required
                    />
                  </xp-form-group>
                </div>
                <div *ngIf="!isSSO" class="form-group">
                  <label>{{ 'auth.sign_in.form.labels.password' | translate }}</label>
                  <span>
                    <input
                      class="form-control"
                      [(ngModel)]="credentials.password"
                      [placeholder]="'auth.sign_in.form.placeholders.password' | translate"
                      name="password"
                      type="password"
                      required
                    />
                  </span>
                </div>
              </fieldset>
              <xp-submit-button
                (submitForm)="submit()"
                classNames="btn-lg btn-block btn-primary"
                [isFormValid]="form.valid"
                [isFormSubmitting]="isLoading"
                [updateText]="'auth.sign_in.form.actions.sign_in' | translate"
              ></xp-submit-button>
            </form>
          </xp-form-validation>
        </div>
        <div *ngIf="!isSSO && !isMFA" class="panel-footer text-center panel-links">
          <a (click)="goToForgotPassword()">{{ 'auth.sign_in.actions.forgot_password' | translate }}</a>
        </div>
        <div class="text-center" style="padding-top: 15px;">
          <a (click)="goToSignUp()">
            {{ 'auth.sign_in.labels.sign_up' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div
      id="refcandy-poprocks"
      data-id="s4cqyl9q72g83hkelufvys61s"
      data-location="right"
      data-minimized="no"
      data-version="2"
    ></div>
    <help-menu></help-menu>
    <div
      id="refcandy-mint"
      data-app-id="mq0j07q0r0m9cc5julivrsqou"
      data-fname="John"
      data-lname="Smith"
      data-email="tayler.jones@xplenty.com"
      data-amount="10.99"
      data-currency="USD"
      data-timestamp="1592459525"
      data-external-reference-id="93211001"
      data-signature="3eaf1ede939e989db5f5305911d8fbc6"
    ></div>
    <script>
      (function (e) {
        var t, n, r, i, s, o, u, a, f, l, c, h, p, d, v;
        z = 'script';
        l = 'refcandy-purchase-js';
        c = 'refcandy-mint';
        p = 'go.referralcandy.com/purchase/';
        t = 'data-app-id';
        r = {
          email: 'a',
          fname: 'b',
          lname: 'c',
          amount: 'd',
          currency: 'e',
          'accepts-marketing': 'f',
          timestamp: 'g',
          'referral-code': 'h',
          locale: 'i',
          'external-reference-id': 'k',
          signature: 'ab',
        };
        i = e.getElementsByTagName(z)[0];
        s = function (e, t) {
          if (t) {
            return '' + e + '=' + encodeURIComponent(t);
          } else {
            return '';
          }
        };
        d = function (e) {
          return '' + p + h.getAttribute(t) + '.js?aa=75&';
        };
        if (!e.getElementById(l)) {
          h = e.getElementById(c);
          if (h) {
            o = e.createElement(z);
            o.id = l;
            a = (function () {
              var e;
              e = [];
              for (n in r) {
                u = r[n];
                v = h.getAttribute('data-' + n);
                e.push(s(u, v));
              }
              return e;
            })();
            o.src = '//' + d(h.getAttribute(t)) + a.join('&');
            return i.parentNode.insertBefore(o, i);
          }
        }
      })(document);
    </script>
  `,
})
export class SignInComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  isMFA = false;
  isSSO = false;
  credentials: Credentials = {
    email: '',
    password: '',
    mfa_code: '',
  };
  errorMessage = '';
  isLoading = false;
  isSSOUser = false;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService,
    private notifyService: NotifyService,
    private store: Store<AppState>,
    private pusherService: PusherService,
    private router: Router,
    private route: ActivatedRoute,
    private segmentService: SegmentService,
  ) {
    // @ts-ignore
    window.email_required = true;
    this.segmentService.analytics.load(environment.SEGMENT_PUBLIC_KEY, { integrations: { HubSpot: false } });
  }

  ngOnInit() {
    this.isSSOUser = getSSOFlag();

    if (this.isAuthBySSO()) {
      this.store.dispatch(loginSuccess());
      this.router.navigate(['/launchpad']);
    } else if (this.authService.isAuthenticated()) {
      this.router.navigate(['/launchpad']);
    } else {
      // no matter if logout was intentional action or
      // session expired because of timeout
      // user will be redirected here
      this.pusherService.unsubscribeUser();
      this.pusherService.unsubscribeAccount();
    }
  }

  goToForgotPassword() {
    this.router.navigate(['/auth/forgot']);
  }

  isAuthBySSO(): boolean {
    // get the access token from the cookie
    const token = getCookie(cookieName);
    // for future debugging reference, the following evaluate to false in JS:
    // 0, "", null, undefined, NaN
    if (token) {
      removeCookie(cookieName, ['/auth', '/auth/login'], ['xplenty.com', 'localhost']);
      this.authService.setToken(token);
      return true;
    }
    return false;
  }

  toggleSSO() {
    this.isSSO = !this.isSSO;
  }

  submit() {
    if (this.isSSO) {
      this.singleSignOn();
      return;
    }
    this.signIn();
  }

  singleSignOn() {
    const url = `${environment.API_URL}/auth/auth0/initiate`;
    const body = this.credentials;
    this.isLoading = true;

    this.http.post(url, body).subscribe({
      next: (response: { url: string }) => {
        this.isLoading = false;
        // status === 200 if we enter here
        if (response.url) {
          window.location.replace(response.url);
        }
      },
      error: ({ error }) => {
        // the catch part gets called if the server responds unexpectedly or knowingly returns != 200
        this.errorMessage = this.translate.instant('auth.sign_in.labels.invalid_response');
        if (error && error.message) {
          this.errorMessage = error.message;
        }

        this.notifyService.warn(this.errorMessage);
        this.isLoading = false;
      },
    });
  }

  goToSignUp() {
    this.router.navigate(['/auth/signup']);
  }

  signIn() {
    this.isLoading = true;
    this.authService.login(this.credentials).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response && response.mfa_required) {
          this.isMFA = true;
          return;
        }

        this.store.dispatch(loginSuccess());
        if (this.route.snapshot.queryParams.return_url) {
          window.location.replace(this.route.snapshot.queryParams.return_url);
        } else {
          this.router.navigate(['/launchpad']);
        }
      },
      error: ({ error }) => {
        this.errorMessage = this.translate.instant('auth.sign_in.labels.invalid_response');

        if (error && error.message) {
          this.errorMessage = error.message;
        }

        this.notifyService.warn(this.errorMessage);
        this.isLoading = false;

        if (this.isMFA) {
          this.isMFA = false;
          this.credentials.mfa_code = '';
        }
      },
    });
  }
}
