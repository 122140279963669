import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectUser } from '../store/account.selectors';
import { GenericListType } from '../../common/components/lists/generic-list.component';

@Component({
  selector: 'welcome',
  template: `
    <div class="main-wrapper">
      <div class="sidebar-wrapper">
        <sidebar-mock
          [type]="type"
          (setType)="setType($event)"
          [started]="started"
          [user]="(user$ | async) || {}"
        ></sidebar-mock>
      </div>
      <div class="page-content-wrapper">
        <div class="app-container-welcome">
          <div class="generic-list" *ngIf="!!type">
            <div class="generic-list-header">
              <div class="generic-list-search invisible"></div>
              <button class="generic-list-new-button btn btn-info btn-lg" type="button" [disabled]="true">
                {{ newBtnText | translate }}
              </button>
            </div>
            <generic-list-empty
              [type]="types.connections"
              *ngIf="type === 'connections'"
              [emptyDescription]="'connection.generic-object.empty.title' | translate"
              [emptyTitle]="'connection.generic-object.empty.description' | translate"
            ></generic-list-empty>
            <generic-list-empty
              [type]="types.workspaces"
              *ngIf="type === 'workspaces'"
              [emptyDescription]="'workspace.generic-object.empty.title' | translate"
              [emptyTitle]="'workspace.generic-object.empty.description' | translate"
            ></generic-list-empty>
            <generic-list-empty
              [type]="types.jobs"
              *ngIf="type === 'jobs'"
              [emptyDescription]="'job.generic-object.empty.title' | translate"
              [emptyTitle]="'job.generic-object.empty.description' | translate"
            ></generic-list-empty>
            <generic-list-empty
              [type]="types.packages"
              *ngIf="type === 'packages'"
              [emptyDescription]="'package.generic-object.empty.title' | translate"
              [emptyTitle]="'package.generic-object.empty.description' | translate"
            ></generic-list-empty>
            <generic-list-empty
              [type]="types.clusters"
              *ngIf="type === 'clusters'"
              [emptyDescription]="'cluster.generic-object.empty.title' | translate"
              [emptyTitle]="'cluster.generic-object.empty.description' | translate"
            ></generic-list-empty>
            <generic-list-empty
              [type]="types.schedules"
              *ngIf="type === 'schedules'"
              [emptyDescription]="'schedule.generic-object.empty.title' | translate"
              [emptyTitle]="'schedule.generic-object.empty.description' | translate"
            ></generic-list-empty>
          </div>
        </div>
        <div class="page-scroll-wrapper" *ngIf="!type">
          <div class="page-wrapper">
            <div class="page-wrapper">
              <div class="alert-box welcome">
                <div class="alert-box-container" *ngIf="user$ | async as user">
                  <img class="hero-img" width="48" height="48" src="assets/img/xplenty-logostamp.svg" alt="Xplenty" />
                  <h1 *ngIf="!started">{{ 'generic.welcome.title' | translate }}</h1>
                  <h1 *ngIf="started">{{ 'generic.welcome.started.title' | translate }}</h1>
                  <p *ngIf="!started" class="lead" [innerHTML]="'generic.welcome.message' | translate"></p>
                  <p *ngIf="started" class="lead" [innerHTML]="'generic.welcome.started.message' | translate"></p>
                  <div *ngIf="!started">
                    <button
                      [disabled]="started"
                      type="button"
                      class="btn btn-xlg btn-success"
                      (click)="start()"
                      [innerHTML]="'generic.welcome.buttons.yes' | translate"
                    ></button>
                  </div>
                  <div *ngIf="!started">
                    <a
                      href="https://app.xplenty.com/help/schedule?email={{ user.email }}"
                      type="button"
                      class="btn btn-md btn-link"
                      target="_blank"
                      [innerHTML]="'generic.welcome.buttons.no' | translate"
                    ></a>
                  </div>
                  <div *ngIf="started">
                    <p [innerHTML]="'generic.welcome.reschedule' | translate: { user: user }"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class WelcomeComponent {
  type: GenericListType = null;
  started = false;
  user$ = this.store.select(selectUser);
  types = GenericListType;

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute) {}

  get newBtnText(): string {
    switch (this.type) {
      case GenericListType.connections:
        return 'connection.generic-object.buttons.new';
      case GenericListType.workspaces:
        return 'workspace.generic-object.buttons.new';
      case GenericListType.schedules:
        return 'schedule.generic-object.buttons.new';
      case GenericListType.clusters:
        return 'cluster.generic-object.buttons.new';
      case GenericListType.packages:
        return 'package.generic-object.buttons.new';
      case GenericListType.jobs:
        return 'job.generic-object.buttons.new';
      default:
        return '';
    }
  }

  setType(type: GenericListType) {
    this.type = type;
  }

  start() {
    this.router.navigate([`start`], { relativeTo: this.route });
    this.started = true;
  }
}
