import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AmazonRedshiftSourceComponentData,
  BigQuerySourceComponentData,
  DatabaseSourceComponentData,
  MongoSourceComponentData,
  NetsuiteSourceComponentData,
  SpannerSourceComponentData,
} from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { ControlContainer, NgForm } from '@angular/forms';

export type DatabaseDefinitionComponentData =
  | AmazonRedshiftSourceComponentData
  | BigQuerySourceComponentData
  | DatabaseSourceComponentData
  | MongoSourceComponentData
  | SpannerSourceComponentData
  | NetsuiteSourceComponentData;

@Component({
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  selector: 'database-definition',
  template: `
    <div class="database-definition">
      <div class="row form-group">
        <div class="col-md-6 access-mode-buttons" *ngIf="hasAccessMode">
          <label for="access_mode">{{
            'database-definition.form.' + component.componentType + '.labels.access_mode' | translate
          }}</label>
          <div class="btn-group btn-group-md btn-group-select">
            <button
              type="button"
              class="btn btn-default"
              value="table"
              name="access_mode"
              [ngClass]="{ 'active btn-primary': rawComponent.access_mode === 'table' }"
              (click)="onValueChange('table', 'access_mode')"
            >
              {{ 'database-definition.form.access_mode.options.table' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-default"
              value="query"
              name="access_mode"
              [ngClass]="{ 'active btn-primary': rawComponent.access_mode === 'query' }"
              (click)="onValueChange('query', 'access_mode')"
            >
              {{ 'database-definition.form.access_mode.options.query' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <database-schema-input
          [rawComponent]="rawComponent"
          [component]="component"
          [hasSchemaByAccessMode]="hasSchemaByAccessMode"
          [hasSchema]="hasSchema"
          [hasTableSchemaSelect]="hasTableSchemaSelect"
        ></database-schema-input>
      </div>
      <div class="row">
        <database-table-input
          [rawComponent]="rawComponent"
          [component]="component"
          [hasTable]="hasTable"
          [hasTableByAccessMode]="hasTableByAccessMode"
          [hasCollection]="hasCollection"
          [hasTableSchemaSelect]="hasTableSchemaSelect"
          [autoCompleteDescription]="tableAutocompleteDescription"
        ></database-table-input>
      </div>
      <div class="row">
        <xp-form-group
          class="col-md-12"
          [ngClass]="{ 'is-code-editor-invisible': !(hasQuery && hasQueryByAccessMode) }"
          [validationDisabled]="!(hasQuery && hasQueryByAccessMode)"
        >
          <label for="query">{{
            'database-definition.form.' + component.componentType + '.labels.query' | translate
          }}</label>
          <code-editor
            [value]="rawComponent.query"
            [options]="queryEditorOptions"
            name="query"
            (valueChange)="onValueChange($event, 'query')"
          ></code-editor>
        </xp-form-group>
        <xp-form-group
          class="col-md-12"
          [ngClass]="{ 'is-code-editor-invisible': !(hasWhereClause && hasWhereClauseByAccessMode) }"
        >
          <label for="where_clause">{{
            'database-definition.form.' + component.componentType + '.labels.where_clause' | translate
          }}</label>
          <code-editor
            [value]="rawComponent.where_clause"
            [options]="whereClauseEditorOptions"
            name="where_clause"
            (valueChange)="onValueChange($event, 'where_clause')"
          ></code-editor>
        </xp-form-group>
      </div>
    </div>
  `,
})
export class DatabaseDefinitionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rawComponent: DatabaseDefinitionComponentData;
  @Input() component: ComponentTypeItem;
  @Input() hasAccessMode = false;
  @Input() hasSchema = false;
  @Input() hasWhereClause = false;
  @Input() hasQuery = false;
  @Input() hasCollection = false;
  @Input() hasTable = true;
  @Input() whereClauseType = '';
  @Input() hasTableSchemaSelect;
  @Input() tableAutocompleteDescription = 'Existing tables on the selected schema';
  hasWhereClauseByAccessMode = false;
  hasTableByAccessMode = true;
  hasQueryByAccessMode = false;
  hasSchemaByAccessMode = true;

  whereClauseEditorOptions: any = {
    placeholder: "prod_category = 1 AND prod_color = 'red'",
  };
  queryEditorOptions: any = {};

  constructor(private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rawComponent) {
      if ((this.rawComponent as DatabaseSourceComponentData).access_mode === 'table') {
        this.hasTableByAccessMode = true;
        this.hasQueryByAccessMode = false;
        this.hasWhereClauseByAccessMode = true;
        this.hasSchemaByAccessMode = true;
      } else if ((this.rawComponent as DatabaseSourceComponentData).access_mode === 'query') {
        this.hasTableByAccessMode = false;
        this.hasSchemaByAccessMode = false;
        this.hasWhereClauseByAccessMode = false;
        this.hasQueryByAccessMode = true;
      }
    }
  }

  ngOnInit() {
    if (this.component.componentType === COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT) {
      this.queryEditorOptions = {
        placeholder:
          '/* Legacy SQL */ \nSELECT * FROM [project-id:dataset.table] \nSELECT * FROM dataset.table \n/* Standard SQL */ \nSELECT * from `project-id.dataset.table` \nSELECT * from project-id.dataset.table',
      };
    }

    if (this.whereClauseType === 'json') {
      this.whereClauseEditorOptions.mode = 'application/json';
      this.whereClauseEditorOptions.placeholder = '{"age":{"$gt":24}}';
    } else {
      this.whereClauseEditorOptions.placeholder = "prod_category = 1 AND prod_color = 'red'";
    }

    window['access_mode_table'] = this.rawComponent.access_mode === 'table';
    window['access_mode_query'] = this.rawComponent.access_mode === 'query';
  }

  onValueChange(value: any, key: string) {
    if (key === 'access_mode') {
      window['access_mode_table'] = value === 'table';
      window['access_mode_query'] = value === 'query';
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  ngOnDestroy() {
    window['access_mode_table'] = false;
    window['access_mode_query'] = false;
  }
}
