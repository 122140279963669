import { Component, Input, Optional } from '@angular/core';
import { ConnectionSchemaComponent } from '../component-editors-helpers/connection-schema.component';
import { XpFieldsCollectionComponent, ErrorType } from './xp-fields-collection.component';

@Component({
  selector: 'xp-fields-collection-errors-box',
  template: `
    <div class="fields-collection-errors-box" [ngClass]="{ empty: errors.length === 0 }">
      <div class="fields-collection-errors-box-total">Total: {{ errors.length }}</div>
      <div class="fields-collection-errors-box-list">
        <a
          class="fields-collection-errors-box-list-item"
          *ngFor="let error of errors; let index = index"
          (click)="focusError(error)"
        >
          <span class="fields-collection-errors-box-list-item-index">{{ index + 1 }}.</span>
          <span class="fields-collection-errors-box-list-item-message">{{ error.message }}</span>
        </a>
      </div>
    </div>
  `,
  providers: [],
})
export class XpFieldsCollectionErrorsBoxComponent {
  @Input() errors: ErrorType[];

  constructor(
    @Optional() private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    @Optional() private connectionSchemaComponent: ConnectionSchemaComponent,
  ) {}

  focusError(error) {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.focusError(error);
    }

    if (this.connectionSchemaComponent) {
      this.connectionSchemaComponent.focusError(error);
    }
  }
}
