import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import { NotifyService } from '../../../common/services/notify.service';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { Hook } from '../../hook.models';
import { selectHooksErrors } from '../../store/hooks/hooks.selectors';

@Component({
  selector: 'hook-form-email',
  template: `
    <xp-form-validation type="EmailHook" [name]="formName">
      <form name="hookForm" id="hookForm" #form="ngForm">
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <xp-form-group>
                <label for="name">{{ 'hook.form.email.labels.name' | translate }}</label>
                <xp-input id="name" type="text" name="name" [(ngModel)]="item.name" class="form-control"></xp-input>
              </xp-form-group>
              <small>{{ 'hook.form.email.hints.name' | translate }}</small>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="status">Active</label>
              <mat-slide-toggle id="status" [checked]="item.active" (toggleChange)="toggleActiveChange()">
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="form-group">
          <xp-form-group>
            <label for="emails">{{ 'hook.form.email.labels.emails' | translate }}</label>
            <xp-input
              type="text"
              name="emails"
              id="emails"
              [(ngModel)]="item.settings.emails"
              class="form-control"
            ></xp-input>
          </xp-form-group>
          <small>{{ 'hook.form.email.hints.emails' | translate }}</small>
        </div>
        <hook-events-editor [(events)]="item.events"></hook-events-editor>
      </form>
      <errors-notify [errors]="errorTexts"></errors-notify>
    </xp-form-validation>
  `,
})
export class HookFormEmailComponent extends BaseForm implements OnInit, OnDestroy, BaseFormInterface {
  @Input() item: Partial<Hook> = {
    settings: {},
    events: [],
  };
  @Output() itemChange = new EventEmitter<Hook>();
  @Output() formValidity = new EventEmitter<boolean>();
  @ViewChild('form', { static: true }) form: NgForm;
  formName = 'hookForm';
  successMessageText = 'hook.form.success_message';
  formChangesSubscription: Subscription;
  errors$ = this.store.select(selectHooksErrors);
  errorTexts = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.formChangesSubscription = this.form.form.statusChanges.subscribe(() => {
      this.formValidity.emit(this.form.valid);
    });
  }

  toggleActiveChange() {
    this.item.active = !this.item.active;
  }

  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
  }
}
