import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryParams } from '../../models/http.models';
import { ApiKeyUser, User } from '../user.models';
import { Credentials } from '../../common/services/auth.service';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class UsersResource {
  private apiRoot: string = `${environment.API_URL}/user`;
  private appUrl: string = `${environment.APP_URL}`;

  constructor(private http: HttpClient) {}

  get(params?: ListParams): Observable<User> {
    return this.http.get<User>(`${this.apiRoot}`, { params: params as QueryParams });
  }

  save(body: Partial<User>, params?: ListParams): Observable<User> {
    return this.http.post<User>(`${this.apiRoot}s`, body, { params: params as QueryParams });
  }

  update(body: Partial<User>, params?: ListParams): Observable<User> {
    return this.http.put<User>(`${this.apiRoot}`, body, { params: params as QueryParams });
  }

  show_api_key(params?: ListParams): Observable<ApiKeyUser> {
    return this.http.post<User>(`${this.apiRoot}/show_api_key`, {}, { params: params as QueryParams });
  }

  regenerate_tokens(body?: User, params?: ListParams): Observable<ApiKeyUser> {
    return this.http.post<User>(`${this.apiRoot}/regenerate-tokens`, body, { params: params as QueryParams });
  }

  reset_password(body: { reset_password_token: string; password: string }, params?: ListParams): Observable<User> {
    return this.http.put<User>(`${this.apiRoot}/password`, body, { params: params as QueryParams });
  }

  invitation(body: { password: string; invitation_token: string }, params?: ListParams): Observable<User> {
    return this.http.put<User>(`${this.apiRoot}/invitation`, body, { params: params as QueryParams });
  }

  confirm(body: { password: string; confirmation_token: string }, params?: ListParams): Observable<User> {
    return this.http.put<User>(`${this.apiRoot}/confirmation`, body, { params: params as QueryParams });
  }

  resetUserPassword(body: Credentials, params?: ListParams): Observable<User> {
    return this.http.post<User>(`${environment.API_URL}/user_password`, body, { params: params as QueryParams });
  }

  newEmailConfirmation(body: { password: string; token: string }) {
    return this.http.post(`${this.appUrl}/user/new_email_confirmation/${body.token}`, body);
  }
}
