import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DatabaseDefaultConnectionWithSSL } from '../../connection.models';

@Component({
  selector: 'connection-component-ssh-tunnel',
  template: `
    <div class="connection-component-ssh-tunnel alert alert-info">
      <strong
        ><i class="fa fa-info-circle" aria-hidden="true"></i>
        {{ 'connection-component-ssh-tunnel.title' | translate }}</strong
      >
      <p *ngIf="isSaved" [innerHTML]="'connection-component-ssh-tunnel.text_saved' | translate"></p>
      <p *ngIf="!isSaved" [innerHTML]="'connection-component-ssh-tunnel.text' | translate"></p>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentSshTunnelComponent {
  @Input() connection: Partial<DatabaseDefaultConnectionWithSSL> = {};
  @Input() isSaved: boolean;
}
