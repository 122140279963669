import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { XplentyConsoleComponent } from './components/xplenty-console.component';
import { XplentyConsoleShortcutsComponent } from './components/xplenty-console-shortcuts.component';
import { XplentyConsoleFunctionsComponent } from './components/xplenty-console-functions.component';
import { ConsoleComponent } from './console.component';

@NgModule({
  declarations: [
    XplentyConsoleComponent,
    XplentyConsoleShortcutsComponent,
    XplentyConsoleFunctionsComponent,
    ConsoleComponent,
  ],
  exports: [XplentyConsoleComponent],
  imports: [BrowserModule, MatDialogModule, FormsModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class HelpModule {}
