import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComponentTypeItem } from '../../../constants/component_types';
import { ACCOUNTS_CAMPAIGNS_SELECTOR_LIST_TYPE } from '../../../constants/accounts_campaigns_selector_list_type';

@Component({
  selector: 'accounts-campaigns-selector-list',
  template: `
    <div class="accounts-campaigns-selector-list">
      <div class="accounts-campaigns-selector-list-header">
        <div *ngIf="searchText">
          <div class="search-btn">
            <button (click)="searchText.name = ''">
              <i
                class="fa"
                [ngClass]="{ 'fa-search': !searchText.name.length, 'fa-times': searchText.name.length > 0 }"
              ></i>
            </button>
          </div>
          <input type="text" [(ngModel)]="searchText.name" (ngModelChange)="onSearchUpdate()" name="search" />
        </div>
      </div>
      <div class="accounts-campaigns-selector-list-scroll">
        <xp-loader *ngIf="accountsLoading"></xp-loader>
        <div
          class="accounts-campaigns-selector-list-item"
          *ngFor="let account of filteredAccounts"
          [ngClass]="{ loaded: account.loaded }"
          [hidden]="accountsLoading"
        >
          <i
            class="fa"
            [ngClass]="{ 'fa-caret-right': account.collapsed, 'fa-caret-down': !account.collapsed }"
            (click)="collapseAccount.emit(account)"
            *ngIf="account.campaigns"
            aria-hidden="true"
          ></i>
          <i
            class="fa"
            [ngClass]="{
              'fa-square-o': !account.selected,
              'fa-square': account.semiSelected,
              'fa-check-square-o': account.selected
            }"
            (click)="selectAccount.emit(account)"
          ></i>
          <strong
            class="accounts-campaigns-selector-list-item-name"
            [innerHTML]="highlight(account.name, searchText.name)"
          ></strong>
          <i
            class="fa fa-refresh fa-spin"
            *ngIf="account.campaigns && account.loading && account.campaigns.length === 0"
          ></i>
          <div class="collapse-wrapper level-1" *ngIf="account.campaigns && !account.collapsed">
            <div
              [hidden]="
                componentType !== 'analytics_source_component' && type === LIST_TYPE.SELECTED && account.selected
              "
              *ngFor="let campaign of filterItems(account.campaigns)"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-caret-right': campaign.collapsed, 'fa-caret-down': !campaign.collapsed }"
                (click)="toggleCampaign(campaign)"
                *ngIf="campaign.views || campaign.can_manage_clients"
                aria-hidden="true"
              ></i>
              <i
                class="fa"
                [ngClass]="{
                  'fa-square-o': !campaign.selected && !campaign.loading,
                  'fa-square': campaign.semiSelected && !campaign.loading,
                  'fa-check-square-o': campaign.selected && !campaign.loading,
                  'fa-refresh fa-spin': campaign.loading
                }"
                aria-hidden="true"
                (click)="selectCampaign.emit({ account: account, campaign: campaign })"
              ></i>
              <span
                class="accounts-campaigns-selector-list-item-name-1"
                [innerHTML]="highlight(campaign.name, searchText.name)"
              ></span
              ><span *ngIf="campaign.status">
                - <span [innerHTML]="highlight(campaign.status, searchText.name)"></span
              ></span>
              <div class="collapse-wrapper level-2" *ngIf="campaign.views && !campaign.collapsed">
                <div *ngFor="let view of filterItems(campaign.views)">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-caret-right': view.collapsed, 'fa-caret-down': !view.collapsed }"
                    (click)="toggleView(view)"
                    *ngIf="view.can_manage_clients"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-square-o': !view.selected && !view.loading,
                      'fa-square': view.semiSelected && !view.loading,
                      'fa-check-square-o': view.selected && !view.loading,
                      'fa-refresh fa-spin': view.loading
                    }"
                    aria-hidden="true"
                    (click)="selectCampaign.emit({ account: account, campaign: campaign, view: view })"
                  ></i>
                  <span
                    class="accounts-campaigns-selector-list-item-name-2"
                    [innerHTML]="highlight(view.name, searchText.name)"
                  ></span>

                  <div class="collapse-wrapper level-3" *ngIf="view.subViews && !view.collapsed">
                    <div *ngFor="let subView of filterItems(view.subViews)">
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-square-o': !subView.selected,
                          'fa-check-square-o': subView.selected
                        }"
                        aria-hidden="true"
                        (click)="
                          selectCampaign.emit({ account: account, campaign: campaign, view: view, subView: subView })
                        "
                      ></i>
                      <span
                        class="accounts-campaigns-selector-list-item-name-3"
                        [innerHTML]="highlight(subView.name, searchText.name)"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <small
              class="text-muted"
              *ngIf="
                type === LIST_TYPE.SELECTED &&
                componentType !== 'analytics_source_component' &&
                account.selected &&
                !account.loading &&
                componentType !== 'adwords_source_component'
              "
              >All campaigns (auto updates)</small
            >
            <small
              class="text-muted"
              *ngIf="
                type === LIST_TYPE.SELECTED &&
                account.selected &&
                !account.loading &&
                componentType !== 'adwords_source_component'
              "
              >All sub accounts</small
            >
            <small
              class="text-muted"
              *ngIf="account.campaigns.length === 0 && !account.loading && type === LIST_TYPE.AVAILABLE"
              >No campaigns have been created yet!</small
            >
          </div>
        </div>
        <small class="text-muted" *ngIf="accounts.length === 0 && !accountsLoading && type === LIST_TYPE.SELECTED">{{
          'account-campaign-selector.selected.' + componentType + '.empty' | translate
        }}</small>
      </div>
    </div>
  `,
})
export class AccountsCampaignsSelectorListComponent implements OnInit, OnChanges {
  @Input() accounts: any[];
  @Output() selectAccount = new EventEmitter();
  @Output() selectCampaign = new EventEmitter();
  @Input() type: string;
  @Input() componentType: any;
  @Output() collapseAccount = new EventEmitter();
  @Output() collapseCampaign = new EventEmitter();
  @Output() collapseView = new EventEmitter();
  @Input() accountsLoading: any;
  @Input() component: ComponentTypeItem;
  filteredAccounts = [];

  LIST_TYPE = ACCOUNTS_CAMPAIGNS_SELECTOR_LIST_TYPE;
  isSelectedList = false;
  searchText = {
    name: '',
  };

  ngOnInit() {
    this.isSelectedList = this.type === ACCOUNTS_CAMPAIGNS_SELECTOR_LIST_TYPE.SELECTED;
    this.filteredAccounts = this.accounts;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.accounts?.currentValue) {
      this.filteredAccounts = this.accounts;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  highlight(text: string, search: string): string {
    if (!search) {
      return text;
    }
    return (text || '').replace(new RegExp(search, 'gi'), '<span class="highlighted">$&</span>');
  }

  filterAccounts(account) {
    let show = false;
    const searchText = this.searchText.name.toUpperCase();
    if (searchText.length > 0) {
      const accountName = account.name.toUpperCase();
      if (accountName.indexOf(searchText) > -1) show = true;
      (account.campaigns || []).forEach((campaign) => {
        const campaignName = campaign.name.toUpperCase();
        if (campaignName.indexOf(searchText) > -1) {
          show = true;
          // eslint-disable-next-line no-param-reassign
          account.collapsed = false;
        }
      });
    } else {
      show = true;
    }
    return show;
  }

  onSearchUpdate() {
    this.filteredAccounts = this.accounts.filter(this.filterAccounts.bind(this));
  }

  filterItems(items: any[]) {
    if (!this.searchText.name) {
      return items;
    }
    return items.filter((item) => item.name.toUpperCase() === this.searchText.name.toUpperCase());
  }

  toggleCampaign(campaign) {
    this.collapseCampaign.emit(campaign);
  }

  toggleView(view) {
    this.collapseView.emit(view);
  }
}
