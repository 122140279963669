import { Pipe, PipeTransform } from '@angular/core';

export function removeHTMLTags(value: string): string {
  return (value || '')
    .replaceAll(/<a[^>]*>(.*?)<\/a>/gi, '$1')
    .replaceAll(/>/gi, '&#62;')
    .replaceAll(/</gi, '&#60;');
}

@Pipe({ name: 'xpHighLight' })
export class XpHighLightPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string = '', searchText: string): string {
    const newValue = removeHTMLTags(value);

    if (!searchText) {
      return newValue;
    }

    const searchTextValue = searchText.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '\\$&');
    return `<span>${(newValue || '').replace(
      new RegExp(searchTextValue.toLowerCase(), 'i'),
      (val) => `<span class="highlighted-text">${val}</span>`,
    )}</span>`;
  }
}
