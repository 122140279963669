import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

interface PositiveMatches {
  lower?: RegExpMatchArray;
  upper?: RegExpMatchArray;
  numbers?: RegExpMatchArray;
  symbols?: RegExpMatchArray;
  middleNumber?: RegExpMatchArray;
  middleSymbol?: RegExpMatchArray;
}

interface NegativeMatches {
  consecLower?: RegExpMatchArray;
  consecUpper?: RegExpMatchArray;
  consecNumbers?: RegExpMatchArray;
  onlyNumbers?: RegExpMatchArray;
  onlyLetters?: RegExpMatchArray;
}

interface Matches {
  pos: PositiveMatches;
  neg: NegativeMatches;
}

interface PositiveCounts {
  lower?: number;
  upper?: number;
  numbers?: number;
  symbols?: number;
  numChars?: number;
  requirements?: number;
  middleNumber?: number;
  middleSymbol?: number;
}

interface NegativeCounts {
  seqLetter: number;
  seqNumber: number;
  seqSymbol: number;
  consecLower?: number;
  consecUpper?: number;
  consecNumbers?: number;
  repeated?: number;
}

interface Counts {
  pos: PositiveCounts;
  neg: NegativeCounts;
}

interface CharCountsMap {
  [key: string]: number;
}

function countRepetedChars(str: string): number {
  return Object.values(
    str.split('').reduce((acc, char) => ({ ...acc, [char]: (acc[char] || 0) + 1 }), {} as CharCountsMap),
  )
    .filter((item) => item > 1)
    .reduce((acc, item) => acc + item, 0);
}

function stringReverse(str: string): string {
  return str.split('').reverse().join('');
}

const measureStrength = function (p: string) {
  const matches: Matches = {
    pos: {},
    neg: {},
  };
  const counts: Counts = {
    pos: {},
    neg: {
      seqLetter: 0,
      seqNumber: 0,
      seqSymbol: 0,
    },
  };
  let tmp;
  let strength = 0;
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '01234567890';
  const symbols = '\\!@#$%&/()=?¿';
  let back;
  let forth;
  let i;

  if (p) {
    // Benefits
    matches.pos.lower = p.match(/[a-z]/g);
    matches.pos.upper = p.match(/[A-Z]/g);
    matches.pos.numbers = p.match(/\d/g);
    matches.pos.symbols = p.match(/[$-/:-?{-~!^_`[\]]/g);
    matches.pos.middleNumber = p.slice(1, -1).match(/\d/g);
    matches.pos.middleSymbol = p.slice(1, -1).match(/[$-/:-?{-~!^_`[\]]/g);

    counts.pos.lower = matches.pos.lower ? matches.pos.lower.length : 0;
    counts.pos.upper = matches.pos.upper ? matches.pos.upper.length : 0;
    counts.pos.numbers = matches.pos.numbers ? matches.pos.numbers.length : 0;
    counts.pos.symbols = matches.pos.symbols ? matches.pos.symbols.length : 0;

    tmp = Object.keys(counts.pos).reduce((acc, key) => acc + Math.min(1, counts.pos[key]), 0);

    counts.pos.numChars = p.length;
    tmp += counts.pos.numChars >= 8 ? 1 : 0;

    counts.pos.requirements = tmp >= 3 ? tmp : 0;
    counts.pos.middleNumber = matches.pos.middleNumber ? matches.pos.middleNumber.length : 0;
    counts.pos.middleSymbol = matches.pos.middleSymbol ? matches.pos.middleSymbol.length : 0;

    // Deductions
    matches.neg.consecLower = p.match(/(?=([a-z]{2}))/g);
    matches.neg.consecUpper = p.match(/(?=([A-Z]{2}))/g);
    matches.neg.consecNumbers = p.match(/(?=(\d{2}))/g);
    matches.neg.onlyNumbers = p.match(/^[0-9]*$/g);
    matches.neg.onlyLetters = p.match(/^([a-z]|[A-Z])*$/g);

    counts.neg.consecLower = matches.neg.consecLower ? matches.neg.consecLower.length : 0;
    counts.neg.consecUpper = matches.neg.consecUpper ? matches.neg.consecUpper.length : 0;
    counts.neg.consecNumbers = matches.neg.consecNumbers ? matches.neg.consecNumbers.length : 0;

    // sequential letters (back and forth)
    for (i = 0; i < letters.length - 2; i += 1) {
      const p2 = p.toLowerCase();
      forth = letters.substring(i, parseInt(i + 3, 10));
      back = stringReverse(forth);
      if (p2.indexOf(forth) !== -1 || p2.indexOf(back) !== -1) {
        counts.neg.seqLetter += counts.neg.seqLetter;
      }
    }

    // sequential numbers (back and forth)
    for (i = 0; i < numbers.length - 2; i += 1) {
      forth = numbers.substring(i, parseInt(i + 3, 10));
      back = stringReverse(forth);
      if (p.indexOf(forth) !== -1 || p.toLowerCase().indexOf(back) !== -1) {
        counts.neg.seqNumber += counts.neg.seqNumber;
      }
    }

    // sequential symbols (back and forth)
    for (i = 0; i < symbols.length - 2; i += 1) {
      forth = symbols.substring(i, parseInt(i + 3, 10));
      back = stringReverse(forth);
      if (p.indexOf(forth) !== -1 || p.toLowerCase().indexOf(back) !== -1) {
        counts.neg.seqSymbol += counts.neg.seqSymbol;
      }
    }

    counts.neg.repeated = countRepetedChars(p);

    strength += counts.pos.numChars * 4;
    if (counts.pos.upper) {
      strength += (counts.pos.numChars - counts.pos.upper) * 2;
    }
    if (counts.pos.lower) {
      strength += (counts.pos.numChars - counts.pos.lower) * 2;
    }
    if (counts.pos.upper || counts.pos.lower) {
      strength += counts.pos.numbers * 4;
    }
    strength += counts.pos.symbols * 6;
    strength += (counts.pos.middleSymbol + counts.pos.middleNumber) * 2;
    strength += counts.pos.requirements * 2;

    strength -= counts.neg.consecLower * 2;
    strength -= counts.neg.consecUpper * 2;
    strength -= counts.neg.consecNumbers * 2;
    strength -= counts.neg.seqNumber * 3;
    strength -= counts.neg.seqLetter * 3;
    strength -= counts.neg.seqSymbol * 3;

    if (matches.neg.onlyNumbers) {
      strength -= counts.pos.numChars;
    }
    if (matches.neg.onlyLetters) {
      strength -= counts.pos.numChars;
    }
    if (counts.neg.repeated) {
      strength -= (counts.neg.repeated / counts.pos.numChars) * 10;
    }
  }

  return Math.max(0, Math.min(100, Math.round(strength)));
};

const getClass = function (s) {
  switch (Math.round(s / 25)) {
    case 0:
      return 'pwd-very-weak';
    case 1:
      return 'pwd-weak';
    case 2:
      return 'pwd-better';
    case 3:
      return 'pwd-good';
    case 4:
      return 'pwd-strong';
    default:
      return 'pwd-very-weak';
  }
};

@Component({
  selector: 'xp-password-strength',
  template: `
    <div class="{{ pwdClass }} pwd-strength">
      <span class="pwd-mark pwd-mark-weak"></span>
      <span class="pwd-mark pwd-mark-better"></span>
      <span class="pwd-mark pwd-mark-good"></span>
      <span class="pwd-mark pwd-mark-strong"></span>
      <div class="pwd-description">{{ 'password-strength.' + pwdClass | translate }}</div>
    </div>
  `,
})
export class XpPasswordStrengthComponent implements OnChanges {
  @Input() password: string;
  pwdClass: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.password && typeof changes.password.currentValue === 'string') {
      const password = changes.password.currentValue;
      this.pwdClass = password && password.length > 0 ? getClass(measureStrength(password)) : 'pwd-empty';
    }
  }
}
