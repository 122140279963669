import { Component, EventEmitter, Input, Output } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator, CONDITION_OPERATOR } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';

@Component({
  selector: 'xp-condition-operator-picker',
  template: `
    <div>
      <xp-select
        [value]="value"
        (valueChange)="onValueChange($event)"
        [options]="conditions"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
      ></xp-select>
    </div>
  `,
  providers: [],
})
export class XpConditionOperatorPickerComponent {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Output() fieldChange = new EventEmitter();
  conditions: SelectOption[] = CONDITION_OPERATOR.map(({ translationPath, value }) => ({
    text: translationPath,
    value,
    translate: true,
  }));
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  onValueChange(value: ConditionOperator) {
    if (this.value === value) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.value = value;
    this.fieldChange.emit(this.value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
