import { createReducer, on } from '@ngrx/store';
import { QueryParamsKeysList } from '../../../common/helper/query-params-generic-list.helper';
import { ValidationError } from '../../../config/validation-error.model';
import { Key } from '../../key.models';
import {
  closeKeysModal,
  getKey,
  getKeyResponse,
  getKeysList,
  getKeysListError,
  getKeysListResponse,
  loadMoreKeysList,
  loadMoreKeysListResponse,
  openKeysModal,
  removeKeyItem,
  removeKeyResponse,
  saveKey,
  saveKeyError,
  saveKeyResponse,
} from './keys.actions';
import { closeAllModals } from '../../../account/store/account.actions';

export const initialState: KeysState = {
  items: [],
  isLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  errors: [],
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface KeysState {
  items: Key[];
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  errors: ValidationError[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const keysReducer = createReducer<KeysState>(
  initialState,

  on(openKeysModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeKeysModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(getKey, (state) => ({ ...state, isLoading: true })),
  on(getKeyResponse, (state, { key }) => ({ ...state, isLoading: false, item: key })),

  on(getKeysList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getKeysListResponse, (state, { keys }) => ({
    ...state,
    isLoading: false,
    items: keys,
    areAllItemsLoaded: keys.length < QueryParamsKeysList.limit,
  })),
  on(getKeysListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(loadMoreKeysList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreKeysListResponse, (state, { keys }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...keys],
    areAllItemsLoaded: keys.length < QueryParamsKeysList.limit,
  })),

  on(saveKey, (state) => ({ ...state, isFormSubmitting: true })),
  on(saveKeyResponse, (state, { data }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: false,
  })),
  on(saveKeyError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(removeKeyItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeKeyResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),
);
