import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, expand, map, reduce, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import {
  getAccountsList,
  getAccountsListError,
  getAccountsListResponse,
  loadMoreAccountsList,
  loadMoreAccountsListResponse,
  saveAccount,
  saveAccountError,
  saveAccountResponse,
} from './accounts.actions';
import { AccountResource } from '../../../account/resources/account.resource';
import { updateAccountsList } from '../../../account/store/account.actions';
import { NotifyService } from '../../../common/services/notify.service';
import { getErrorFromResponse } from '../../../common/helper/response.helpers';
import { Account } from '../../../account/account.models';

@Injectable()
export class AccountsEffects {
  queryCall(offset = 0, params: any): Observable<{ accounts: Account[]; offset: number }> {
    return this.accountResource
      .query({
        ...params,
        offset,
      } as any)
      .pipe(map((accounts) => ({ accounts, offset })));
  }

  getAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccountsList),
      switchMap(({ params, getAll }) =>
        this.queryCall(0, params).pipe(
          expand(({ accounts, offset }) => {
            return accounts.length === params.limit && getAll ? this.queryCall(offset + params.limit, params) : EMPTY;
          }),
          reduce((accounts, res) => accounts.concat(res.accounts), []),
          map((accounts) => getAccountsListResponse({ accounts, limit: params.limit })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getAccountsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreAccountsList),
      switchMap(({ params }) =>
        this.accountResource.query(params).pipe(
          map((accounts) => loadMoreAccountsListResponse({ accounts, limit: params.limit })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getAccountsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAccount),
      switchMap(({ account }) =>
        this.accountResource.save(account).pipe(
          map((accountResponse) => saveAccountResponse({ data: accountResponse })),
          catchError((response) => of(saveAccountError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  saveAccountResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAccountResponse),
      map(({ data }) => updateAccountsList({ accounts: [data] })),
    ),
  );

  constructor(
    private actions$: Actions,
    private accountResource: AccountResource,
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}
}
