import {
  Analytics4SourceComponentData,
  AnalyticsSourceComponentData,
  AllComponentData,
  BingAdsSourceComponentData,
  CloudStorageSourceComponentData,
  Component,
  FacebookAdsInsightsSourceComponentData,
  RestApiSourceComponentData,
  SalesforceSourceComponentData,
} from '../package.models';
import { getComponentDefaultDataByType, getComponentType, getDataFromComponent } from './components.helpers';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { SchemaImporterComponentData } from '../components/component-editors-helpers/schema-importer.component';

const commonDatabaseParams = ({
  access_mode,
  before_action,
  database_connection_id,
  parallel,
  table_name,
  query,
  where_clause,
  use_legacy_sql,
  stored_procedure,
  schema_name,
}: AllComponentData) => {
  return {
    access_mode,
    before_action,
    database_connection_id,
    parallel,
    table_name,
    table: table_name,
    query,
    where_clause,
    use_legacy_sql,
    stored_procedure,
    schema_name,
  };
};

const COMPONENT_PARAMETERS_SCHEMA_MAP: any = {
  [COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT]: commonDatabaseParams,
  [COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT]: commonDatabaseParams,
  [COMPONENT_TYPE.MONGO_SOURCE_COMPONENT]: commonDatabaseParams,
  [COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT]: commonDatabaseParams,
  [COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT]: ({
    before_action,
    bucket,
    char_encoding,
    record_settings,
    json_path,
    manifest_connection,
    path,
    record_delimiter,
    record_type,
    sheet_name,
    zip_password,
    source_path_field_alias,
  }: CloudStorageSourceComponentData) => {
    return {
      before_action,
      bucket,
      char_encoding,
      json_path,
      manifest_connection,
      path,
      record_delimiter,
      record_type,
      sheet_name,
      zip_password,
      skip_header: record_settings.skip_header,
      source_path_field_alias,
      delimiter: record_settings.field_delimiter !== 'space' ? record_settings.field_delimiter : ' ',
      quote:
        !record_settings.text_qualifier ||
        record_settings.text_qualifier === '' ||
        record_settings.text_qualifier === 'none_with_line_ending'
          ? 'none'
          : record_settings.text_qualifier,
      escape: record_settings.escape_character === 'none' ? '' : record_settings.escape_character,
    };
  },
  [COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT]: ({
    schema,
    api_version,
    level,
    action_report_time,
    time_increment,
    report_date_range_type,
    filtering,
    report_on,
    ad_accounts,
    report_date_range_min,
    report_date_range_max,
  }: FacebookAdsInsightsSourceComponentData) => {
    const fields = schema.fields || [];
    const data: any = {
      ad_accounts: report_on === 'all' ? 'any' : ad_accounts.join(),
      api_version,
      level,
      action_report_time,
      time_increment,
      report_date_range_type,
      filtering,
      fields: typeof fields[0] === 'string' ? fields.join() : fields.map((field) => field.name).join(),
      file_type: 'service_type',
    };

    if (report_date_range_type === 'CUSTOM_DATE') {
      data.report_date_range_min = report_date_range_min;
      data.report_date_range_max = report_date_range_max;
    }

    return data;
  },
  [COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT]: () => ({}),
  [COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT]: ({
    report_on,
    profile_ids,
    report_date_range_min,
    report_date_range_max,
    metrics,
    dimensions,
    segment,
    sort,
    filters,
    sampling_level,
    include_empty,
  }: AnalyticsSourceComponentData) => {
    return {
      profile_ids: report_on === 'all' ? '' : profile_ids,
      report_date_range_min,
      report_date_range_max,
      metrics,
      dimensions,
      segment,
      sort,
      filters,
      sampling_level,
      include_empty,
      file_type: 'service_type',
    };
  },
  [COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT]: ({
    report_on,
    property_ids,
    report_date_range_min,
    report_date_range_max,
    metrics,
    dimensions,
    api_version,
    include_empty_rows,
  }: Analytics4SourceComponentData) => {
    return {
      property_ids: report_on === 'all' ? '' : property_ids,
      api_version,
      include_empty_rows,
      report_date_range_min,
      report_date_range_max,
      metrics,
      dimensions,
      file_type: 'service_type',
    };
  },
  [COMPONENT_TYPE.REST_API_SOURCE_COMPONENT]: ({
    authentication,
    body,
    headers,
    url,
    json_path,
    response_type,
    use_pagination,
    method,
    username,
    password,
  }: RestApiSourceComponentData) => {
    return {
      authentication,
      body,
      headers: (headers || []).reduce((acc, header) => ({ ...acc, [header.name]: header.value }), {}),
      url,
      json_path,
      response_type,
      use_pagination,
      method,
      username,
      password,
    };
  },
  [COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT]: ({
    object_name,
    schema,
    where_clause,
    query,
    access_mode,
    source_action,
    api_version,
    max_records,
  }: SalesforceSourceComponentData) => {
    const schemaData: any = {
      object_name,
      where_clause,
      file_type: 'service_type',
      source_action,
      api_version,
      max_records,
    };

    if (access_mode === 'object') {
      schemaData.object_fields =
        typeof schema.fields[0] === 'string' ? schema.fields : schema.fields.map((field) => field.name);
    }

    if (access_mode === 'query') {
      schemaData.query = query;
      // schemaData.source_action = 'query';
      schemaData.access_mode = access_mode;
      schemaData.file_type = 'service_type';
    }
    return schemaData;
  },
  [COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT]: ({ report_type }: BingAdsSourceComponentData) => ({ report_type }),
  [COMPONENT_TYPE.NET_SUITE_SOURCE_COMPONENT]: commonDatabaseParams,
  [COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT]: commonDatabaseParams,
};

const RAW_SCHEMA_DATA_TYPES = [
  COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT,
  COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT,
];

export interface SchemaRequestData {
  component_id: string;
  type: string;
  connection_type: string;
  connection_id: number;
  disableSchemaLoad: boolean;
  lines: number;
  defaults: any;
  hasDataPreview: boolean;
  hasDataPreviewRefreshButton: boolean;
  inputMax?: number;
  inputMin?: number;
  account_id: string;
  id: string | number;
  access_mode: string;
}

export function getDataForSchemaImporter(
  componentData: SchemaImporterComponentData,
  componentType: COMPONENT_TYPE,
  account_id: string,
  isComponentPreviewer = false,
): Partial<SchemaRequestData> {
  const schemaComponentData: Partial<AllComponentData> = COMPONENT_PARAMETERS_SCHEMA_MAP[componentType](componentData);
  const {
    defaults = {},
    hasDataPreview = true,
    hasDataPreviewRefreshButton = false,
    inputMax = 0,
    inputMin = 0,
  } = getComponentDefaultDataByType(componentType);

  const disableSchemaLoad =
    (schemaComponentData as SalesforceSourceComponentData).access_mode === 'object' &&
    componentType === COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT;

  let connection_id;
  let type =
    componentType === COMPONENT_TYPE.REST_API_SOURCE_COMPONENT ? 'curl' : componentData.connection.type || 'curl';
  let connection_type = type;

  if (
    componentType === COMPONENT_TYPE.REST_API_SOURCE_COMPONENT &&
    componentData.connection &&
    ((componentData as RestApiSourceComponentData).authentication === 'connection' || componentData.connection.type)
  ) {
    connection_type = componentData.connection.type;
    type = 'curl';
    connection_id = componentData.connection.id;
  }

  let schemaRequestData: Partial<SchemaRequestData> = {
    ...schemaComponentData,
    account_id,
    id: (componentData.connection || {}).id,
  };

  if (
    RAW_SCHEMA_DATA_TYPES.includes(componentType) ||
    (componentType === COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT && schemaRequestData.access_mode === 'object')
  ) {
    return isComponentPreviewer
      ? {
          ...schemaRequestData,
          component_id: componentData.id,
          type,
        }
      : schemaRequestData;
  }

  schemaRequestData = {
    ...defaults,
    ...schemaRequestData,
    ...schemaComponentData,
    component_id: componentData.id,
    type,
    connection_type,
    connection_id,
    disableSchemaLoad,
    lines: 50,
    defaults,
    hasDataPreview,
    hasDataPreviewRefreshButton,
    inputMax,
    inputMin,
  };

  Object.keys(schemaRequestData)
    // eslint-disable-next-line no-prototype-builtins
    .filter((key) => schemaRequestData.hasOwnProperty(key) && (schemaRequestData as any)[key] === '')
    .forEach((key) => {
      delete (schemaRequestData as any)[key];
    });

  return schemaRequestData;
}

export function getDataForSchemaImporterRawComponent(
  component: Component,
  account_id: string,
  isComponentPreviewer = false,
): Partial<SchemaRequestData> {
  const componentType = getComponentType(component);
  const componentData = getDataFromComponent(component);
  return getDataForSchemaImporter(componentData, componentType, account_id, isComponentPreviewer);
}
