import { Injectable } from '@angular/core';
import { Cluster } from '../../../clusters/clusters.models';
import { SearchConfig } from './search-config.model';

const basicConfig: SearchConfig = {
  'id:': {
    type: 'default',
    defaultHint: 'Clusters with this ID',
  },
  'name:': {
    defaultHint: 'Clusters with this name',
  },
  'created:>': {
    type: 'date',
    defaultHint: 'Clusters created after the date',
  },
  'created:<': {
    type: 'date',
    defaultHint: 'Clusters created before the date',
  },
  'terminated:>': {
    type: 'date',
    defaultHint: 'Clusters terminated after the date',
  },
  'terminated:<': {
    type: 'date',
    defaultHint: 'Clusters terminated before the date',
  },
  'nodes:': {
    defaultHint: 'Clusters of this size',
  },
};

interface ClusterSearchItem {
  id: number;
  value: string;
  label: string;
}

function prepareValue(stringVal: string): string {
  if (stringVal && typeof stringVal.indexOf !== 'undefined' && stringVal.indexOf(' ') !== -1) {
    return `"${stringVal}"`; // wrap in quotes if contains spaces
  }
  return stringVal;
}

function formatClusterForList(u: Cluster): ClusterSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name),
    label: u.name,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssistedSearchClustersConfiguratorService {
  // eslint-disable-next-line class-methods-use-this
  getConfig(): SearchConfig {
    return { ...basicConfig };
  }
}
