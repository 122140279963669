import { Directive, HostListener, Input } from '@angular/core';
import { AssistedSearchComponent } from './assisted-search.component';

@Directive({
  selector: '[assisted-search-modifier]',
})
export class AssistedSearchModifierDirective {
  @Input() modifier: string;

  constructor(private assistedSearch: AssistedSearchComponent) {}

  @HostListener('click') onClick() {
    this.assistedSearch.applyModifier(this.modifier);
  }
}
