import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldsCollectionValidationsService } from '../../../../common/services/fields-collection-validations.service';
import { ValidationsEnum } from '../../../../common/services/validations.enum';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ValidatorData } from '../../../../common/validators/helpers/validator-data.model';

@Component({
  selector: 'xp-alias-editor',
  template: `
    <div class="alias-editor" [ngClass]="[state, isActive ? 'active' : '']">
      <div class="editor-button">
        <div class="base-icon">
          <i class="fa fa-magic" (click)="autoGenerate()"></i>
        </div>
      </div>
      <xp-input
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        [state]="state"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        [tooltip]="tooltip"
        [isFocused]="focusedProp === propName"
        placeholder=""
        name="alias-editor"
      ></xp-input>
    </div>
  `,
  providers: [],
})
export class XpAliasEditorComponent implements OnChanges {
  @Input() value: string;
  @Input() propName: string;
  @Input() index: number;
  @Input() focusedProp: string;
  @Input() isDuplicateError: boolean;
  @Input() isOutsideDuplicateError: boolean;
  @Input() outsideValidationMessage: string;
  @Output() fieldChange = new EventEmitter();
  validate: (...args: any) => ValidatorData;
  state: string = 'editable';
  isActive: boolean;
  tooltip = '';
  isFirstChange = true;

  constructor(
    private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
    private translate: TranslateService,
  ) {
    this.validate = this.fieldsCollectionValidationsService.getValidationFunction(ValidationsEnum.ALIAS_EDITOR);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.validityFn(changes.value.currentValue);
    }

    if (!changes.value && (changes.isDuplicateError || changes.isOutsideDuplicateError)) {
      this.validityFn(this.value);
    }
  }

  onInputFocus() {
    this.isActive = true;
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: this.isActive });
    }
  }

  onInputBlur() {
    this.isActive = false;
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: this.isActive });
    }
  }

  onValueChange(value) {
    if (this.value === value || (!value && !this.value)) {
      return;
    }

    this.value = value;

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.fieldChange.emit(this.value);

    this.validityFn(value);
  }

  validityFn(value: string): boolean {
    if (this.xpFieldsCollectionComponent && this.xpFieldsCollectionComponent.isRecordPristine(this.index)) {
      return false;
    }

    const validityObject = this.validate(value);
    this.setValidity(validityObject);

    return validityObject.valid;
  }

  setValidity(validityObject: Partial<ValidatorData>) {
    const isValid = validityObject.valid && !this.isDuplicateError && !this.isOutsideDuplicateError;
    if (!isValid) {
      this.state = 'error';
      if (this.isDuplicateError) {
        this.tooltip = this.translate.instant('fields-collection.errors.duplicate_field', {
          fieldName: 'Alias',
        });
      } else if (this.isOutsideDuplicateError) {
        this.tooltip = this.outsideValidationMessage;
      } else {
        this.tooltip = validityObject.message;
      }
    } else {
      this.state = 'editable';
      this.tooltip = '';
    }

    this.registerError(isValid, this.tooltip);
  }

  registerError(isValid: boolean, message: string) {
    if (this.xpFieldsCollectionComponent) {
      if (!isValid) {
        this.xpFieldsCollectionComponent.registerError(this.index, message, this.propName);
      } else {
        this.xpFieldsCollectionComponent.removeError(this.index, this.propName);
      }
    }
  }

  autoGenerate() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.autoGenerate(this.index);
    }
  }
}
