import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';
import { AppState } from '../../store';
import { selectAccountId } from '../store/account.selectors';
import { Variables } from '../../package-designer/package.models';

interface GlobalVariablesResponse {
  id: number;
  name: string;
  global_variables: Variables;
}

@Injectable({
  providedIn: 'root',
})
export class VariablesResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  get(params?: ListParams): Observable<Variables> {
    return this.http.get<Variables>(`${this.apiRoot}/variables`, { params: params as QueryParams });
  }

  getGlobalVariables(params?: ListParams): Observable<GlobalVariablesResponse> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<GlobalVariablesResponse>(`${this.apiRoot}/${account_id}/global_variables/`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  setGlobalVariables(variables: Variables, params?: ListParams): Observable<GlobalVariablesResponse> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<GlobalVariablesResponse>(
          `${this.apiRoot}/${account_id}/global_variables/`,
          { global_variables: variables },
          {
            params: params as QueryParams,
          },
        ),
      ),
    );
  }
}
