import { createAction, props } from '@ngrx/store';
import { Key } from '../../key.models';
import { ValidationError } from '../../../config/validation-error.model';
import { ListParams } from '../../../common/helper/query-params-generic-list.helper';

export const openKeysModal = createAction('[Keys] Open modal');
export const closeKeysModal = createAction('[Keys] Close modal');

export const getKey = createAction('[Keys] Get key', props<{ keyId: number }>());
export const getKeyResponse = createAction('[Keys] Get key response', props<{ key: Key }>());

export const getKeysList = createAction('[Keys] Get keys list', props<{ params: ListParams }>());
export const getKeysListResponse = createAction('[Keys] Get keys list response', props<{ keys: Key[] }>());
export const getKeysListError = createAction('[Keys] Get keys list error', props<{ errors: ValidationError[] }>());

export const loadMoreKeysList = createAction('[Keys] Load more keys list', props<{ params: ListParams }>());
export const loadMoreKeysListResponse = createAction('[Keys] Load more keys list response', props<{ keys: Key[] }>());

export const saveKey = createAction('[Keys] Save key', props<{ key: Partial<Key> }>());
export const saveKeyResponse = createAction('[Keys] Save key response', props<{ data: Key }>());
export const saveKeyError = createAction('[Keys] Save key error', props<{ errors: ValidationError[] }>());

export const removeKey = createAction('[Keys] Remove key', props<{ keyId: number }>());
export const removeKeyItem = createAction('[Keys] Remove key item', props<{ data: Key }>());
export const removeKeyResponse = createAction('[Keys] Remove key response', props<{ data: Key }>());
export const removeKeyError = createAction('[Keys] Remove key error', props<{ errors: ValidationError[] }>());
