import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Step, XpStepsComponent } from './xp-steps.component';

@Component({
  selector: 'xp-step',
  template: `
    <div
      class="step"
      [ngClass]="{
        valid: stepItem.valid,
        active: stepItem.active,
        lock: stepItem.lock,
        error: stepItem.isError,
        loading: stepItem.isLoading
      }"
      *ngIf="!stepItem.disable"
    >
      <div class="step-header" (click)="toggle()">
        <xp-step-index [step]="stepItem"></xp-step-index>
        <div class="step-header-title">
          <span *ngIf="!stepItem.active">{{ stepItem.title }}</span>
          <span *ngIf="stepItem.active">{{ stepItem.activeTitle }}</span>
        </div>
        <div class="step-header-tags" *ngIf="!stepItem.active && stepItem.valid && stepItem.tags.length">
          <span class="step-header-tag" *ngFor="let tag of stepItem.tags" [innerHTML]="tag.name"></span>
        </div>
      </div>
      <div class="step-body xp-step-body" #stepBody [ngClass]="{ expand: stepItem.active }">
        <div class="step-body-container" #stepBodyContainer [ngClass]="{ wider: isWide }">
          <ng-content></ng-content>
        </div>
        <div class="step-buttons" #stepBodyButtons>
          <button type="button" class="btn btn-md btn-default" *ngIf="stepItem.index !== 0" (click)="back()">
            Back
          </button>
          <button
            type="button"
            class="btn btn-md btn-info next-button"
            *ngIf="!stepItem.isLast"
            [disabled]="!stepItem.valid"
            (click)="next()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  `,
})
export class XpStepComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() step: Step;
  @Input() isWide = false;
  @Output() activate = new EventEmitter();
  @ViewChild('stepBody') stepBody: ElementRef<HTMLElement>;
  @ViewChild('stepBodyContainer') stepBodyContainer: ElementRef<HTMLElement>;
  @ViewChild('stepBodyButtons') stepBodyButtons: ElementRef<HTMLElement>;
  stepItem: Partial<Step> = {};
  stepIndex: number;
  changesSubscription: Subscription;
  stepBodyHeight = 0;
  observer: ResizeObserver;

  constructor(private stepsComponent: XpStepsComponent) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.step.currentValue && !changes.step.firstChange) {
      this.stepsComponent.updateStepParams(changes.step.currentValue, this.stepIndex);
    }
  }

  ngAfterViewInit() {
    this.stepsComponent.addStep(this.step).subscribe((index) => {
      this.stepIndex = index;
      this.listenChanges(index);
      if (this.stepIndex !== 0 && !this.step.active) {
        this.stepBody.nativeElement.style.position = 'absolute';
        this.stepBody.nativeElement.style.top = '-10000px';
      } else {
        if (this.stepBodyHeight) {
          this.stepBody.nativeElement.style.height = `${this.stepBodyHeight}px`;
        }
      }
    });

    setTimeout(() => {
      if (this.stepIndex !== 0 && this.stepBody && !this.step.active) {
        this.stepBody.nativeElement.style.height = '0';
        this.stepBody.nativeElement.style.position = 'initial';
        this.stepBody.nativeElement.style.top = '0';
      }
    }, 0);

    let timeout;
    this.observer = new ResizeObserver(() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!this.stepBody || !this.stepBodyContainer || !this.stepBodyButtons) {
          return;
        }
        this.stepBodyHeight =
          this.stepBodyContainer.nativeElement.offsetHeight + this.stepBodyButtons.nativeElement.offsetHeight;
        if (this.stepItem.active) {
          this.stepBody.nativeElement.style.height = `${this.stepBodyHeight}px`;
        }
      }, 100);
    });

    this.observer.observe(this.stepBodyContainer.nativeElement);
  }

  listenChanges(index: number) {
    this.changesSubscription = this.stepsComponent.listenChanges(index).subscribe((step) => {
      if (step.active && !this.stepItem.active) {
        this.activate.emit();
      }

      if (this.stepBodyHeight !== 0 && this.stepBody) {
        if (step.active) {
          this.stepBody.nativeElement.style.height = `${this.stepBodyHeight}px`;
        } else {
          this.stepBody.nativeElement.style.height = '0';
        }
      }

      setTimeout(() => {
        this.stepItem = step;
      });
    });
  }

  toggle() {
    this.stepsComponent.setActiveFlag(this.stepIndex, !this.stepItem.active);
  }

  back() {
    this.stepsComponent.activatePreviousStep(this.stepIndex);
  }

  next() {
    this.stepsComponent.activateNextStep(this.stepIndex);
  }

  ngOnDestroy() {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
