import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotifyService } from '../../services/notify.service';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../base/base-form.component';
import { Cluster } from '../../../clusters/clusters.models';
import { Package } from '../../../packages/package.models';
import { openClustersModal } from '../../../clusters/store/clusters.actions';
import { Variables } from '../../../package-designer/package.models';
import { getStep } from '../../helper/get-step.helper';
import { Job } from '../../../jobs/jobs.models';
import { queryParamsMap, SelectPickerTypes } from './select-picker/select-picker-types.enum';
import { selectJobsErrors, selectJobsIsSubmittingFlag } from '../../../jobs/store/jobs.selectors';
import { Tag } from '../xp-steps.component';
import { closeJobsModal, saveJob, updateJob } from '../../../jobs/store/jobs.actions';
import { loadSelectPickerItems } from '../../store/select-picker.actions';
import { selectLastlyCreatedCluster } from '../../../clusters/store/clusters.selectors';

function getPackageIcon(_package: Package): string {
  const dataflowIcon = `<common-icon iconId="icon-package"></common-icon>`;
  const workflowIcon = `<common-icon iconId="icon-workflow"></common-icon>`;
  return _package.flow_type === 'dataflow' ? dataflowIcon : workflowIcon;
}

@Component({
  selector: 'job-form',
  template: `
    <div class="job-form">
      <div class="job-form-body">
        <xp-form-validation [type]="item.id ? 'Job' : 'NewJob'" [name]="formName">
          <form id="jobForm" name="jobForm" #form="ngForm" class="form">
            <div class="job-form-body-content">
              <xp-steps>
                <xp-step [step]="clusterStep">
                  <xp-select-picker-editable
                    id="cluster-picker-job-form"
                    [type]="selectPickerTypes.cluster"
                    [value]="item.cluster_id"
                    placeholder="Select cluster"
                    emptyPlaceholder="The list of clusters is empty"
                    (valueChange)="onClusterChange($event)"
                    (createNew)="onCreateNewCluster()"
                    [preventEmpty]="true"
                  ></xp-select-picker-editable>
                </xp-step>
                <xp-step [step]="packageStep">
                  <xp-select-picker-editable
                    id="package-picker-job-form"
                    [type]="selectPickerTypes.package"
                    [value]="package"
                    placeholder="Select package"
                    emptyPlaceholder="The list of packages is empty"
                    (valueChange)="onPackageChange($event)"
                    [hideNew]="true"
                  ></xp-select-picker-editable>
                </xp-step>
                <xp-step [step]="packageVariablesStep" class="small-padding job-form-package-variables">
                  <package-variables-editor
                    parentType="job"
                    [package]="package"
                    [secretVariables]="secretVariables"
                    [variables]="variables"
                    [disableKey]="true"
                    [hideFooter]="true"
                    [hideAdd]="true"
                    [usePackageDefault]="true"
                    [disableSecrets]="true"
                    [variablesToSet]="variablesToSet"
                    [valid]="packageVariablesStep.valid"
                    [error]="packageVariablesStep.isError"
                    (variablesChange)="onVariablesChange($event)"
                    (secretVariablesChange)="onSecretVariablesChange($event)"
                  ></package-variables-editor>
                </xp-step>
              </xp-steps>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="job-form-footer modal-footer" *ngIf="!hideFooter">
        <div class="modal-title-container active">
          <common-icon iconId="icon-job" size="L"></common-icon>
          <h3 class="modal-title">{{ 'job.form.title' | translate }}</h3>
        </div>
        <xp-submit-button
          (click)="saveJob(item)"
          classNames="btn-lg btn-success pull-right modal-btn-save"
          [createText]="!item.id && 'job.form.buttons.create' | translate"
          [updateText]="item.id && 'job.form.buttons.update' | translate"
          [isFormValid]="form.valid && isFormValid"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class JobFormComponent extends BaseForm implements OnInit, OnChanges, BaseFormInterface {
  @Input() hideName = false;
  @Input() editMode = false;
  @Input() hideSandbox = false;
  @Input() hideFooter = false;
  @Input() productionOnly = false;
  @Input() showReUse = false;
  @Input() isInModalPanel = false;
  @Input() disableModalClose = false;
  @Input() item: Partial<Job> = {};
  @Input() package: Package;
  @Input() createdCluster: Cluster;

  cluster: Cluster;
  selectPickerTypes = SelectPickerTypes;
  clusterStep = getStep({
    active: true,
    valid: false,
    title: this.translate.instant('job-form.steps.cluster'),
    activeTitle: this.translate.instant('job-form.steps.cluster-edit'),
  });
  packageStep = getStep({
    title: this.translate.instant('job-form.steps.package'),
    activeTitle: this.translate.instant('job-form.steps.package-edit'),
  });
  packageVariablesStep = getStep({
    title: this.translate.instant('job-form.steps.variables'),
    activeTitle: this.translate.instant('job-form.steps.variables-edit'),
  });
  variables: Variables = {};
  secretVariables: Variables = {};
  defaultSecrets: Variables = {};
  variablesToSet: Variables = {};

  @ViewChild('form') form: NgForm;
  formName = 'jobForm';
  successMessageText = 'job.form.success_message';
  isSubmitting$ = this.store.select(selectJobsIsSubmittingFlag);
  errors$ = this.store.select(selectJobsErrors);
  errorTexts = [];
  lastlyCreatedClusterSubscription: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.lastlyCreatedClusterSubscription = this.store
      .select(selectLastlyCreatedCluster)
      .pipe(filter(Boolean))
      .subscribe((cluster: Cluster) => {
        this.onClusterChange(cluster);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.createdCluster && changes.createdCluster.currentValue) {
      this.store.dispatch(
        loadSelectPickerItems({
          id: 'cluster-picker-job-form',
          itemType: SelectPickerTypes.cluster,
          params: queryParamsMap[SelectPickerTypes.cluster],
        }),
      );
      this.onClusterChange(this.createdCluster);
    }

    if (changes.item && changes.item.currentValue && changes.item.currentValue.package) {
      this.onPackageChange(this.item.package);
    }
    if (changes.package && changes.package.currentValue) {
      this.onPackageChange(this.package);
    }
  }

  onClusterChange(cluster: Cluster) {
    this.cluster = cluster;
    this.item.cluster_id = cluster.id;
    const clusterIcon =
      '<svg class="icon icon-schedules"><use xmlns:xlink="http://www.w3.org/1999/xlink" href="#icon-cluster"></use></svg>';
    const tags: Tag[] = [
      {
        name: `${clusterIcon} <b>${escape(cluster.name)}</b>`,
      },
    ];
    this.clusterStep = { ...this.clusterStep, valid: true, tags };
  }

  onPackageChange(packageItem: Package) {
    this.package = packageItem;
    this.item.package_id = packageItem.id;
    const packageIcon = getPackageIcon(packageItem);
    const tags: Tag[] = [
      {
        name: `${packageIcon} <b>${escape(packageItem.name)}</b>`,
      },
    ];
    this.packageStep = { ...this.packageStep, valid: true, tags };
    this.packageVariablesStep = { ...this.packageVariablesStep, valid: true };
    this.variables = packageItem.variables;
    this.secretVariables = packageItem.secret_variables;
    this.defaultSecrets = packageItem.secret_variables;
    this.variablesToSet = { ...packageItem.variables };
    this.onVariablesChange(this.variables);
    this.onSecretVariablesChange(this.secretVariables);
  }

  onVariablesChange(variables: Variables) {
    this.variables = variables || {};
    const tags: Tag[] = Object.keys(this.variables).map((key) => {
      return {
        name: `${escape(key)}: <b>${escape(this.variables[key])}</b>`,
      };
    });
    this.packageVariablesStep = { ...this.packageVariablesStep, tags };
  }

  onSecretVariablesChange(secretVariables: Variables) {
    this.secretVariables = secretVariables || {};
  }

  onCreateNewCluster() {
    this.store.dispatch(openClustersModal());
  }

  secretVariablesForJob(): Variables {
    const result = {};
    for (const key in this.secretVariables) {
      if (this.secretVariables[key] !== this.defaultSecrets[key]) {
        result[key] = this.secretVariables[key];
      }
    }
    return result;
  }

  saveJob(job: Partial<Job>) {
    const newJob: Partial<Job> = { ...job };
    const params = {};
    newJob.dynamic_variables = this.variables;
    newJob.secret_variables = this.secretVariablesForJob();

    if (job.id) {
      this.store.dispatch(updateJob({ job: newJob, params, jobId: job.id }));
    } else {
      this.store.dispatch(saveJob({ job: newJob }));
    }
    if (!this.disableModalClose) {
      this.store.dispatch(closeJobsModal());
      this.router.navigate(['./'], { relativeTo: this.route });
    }
  }

  get isFormValid(): boolean {
    return this.clusterStep.valid && this.packageStep.valid && this.packageVariablesStep.valid;
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.lastlyCreatedClusterSubscription) {
      this.lastlyCreatedClusterSubscription.unsubscribe();
    }
  }
}
