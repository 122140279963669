import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'xpFormatTime' })
export class XpFormatTimePipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: number): string {
    const duration = moment.duration(value, 'seconds');

    const seconds = duration.seconds();
    const minutes = duration.minutes();
    const hours = duration.hours();
    const days = duration.days();
    const months = duration.months();
    const years = duration.years();

    let fmt = [hours, minutes, seconds]
      .filter(function (o, i) {
        return o !== 0 || i !== 0;
      }) // Do not take of minutes
      .map(function (o, i) {
        if (i === 0) {
          return o;
        }
        return o < 10 ? `0${o}` : o;
      })
      .join(':');

    if (days > 0) {
      fmt = `${days}d, ${fmt}`;
    }
    if (months > 0) {
      fmt = `${months}mo, ${fmt}`;
    }

    if (years > 0) {
      fmt = `${years}y, ${fmt}`;
    }
    return fmt;
  }
}
