import { createAction, props } from '@ngrx/store';
import { ValidationError } from '../../config/validation-error.model';
import { Workspace } from '../workspaces.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

export const openWorkspacesModal = createAction('[Workspaces] Open modal');
export const closeWorkspacesModal = createAction('[Workspaces] Close modal');

export const getWorkspacesList = createAction(
  '[Workspaces] Get workspaces list',
  props<{ params: ListParams; isWorkspacesView?: boolean }>(),
);
export const getWorkspacesListResponse = createAction(
  '[Workspaces] Get workspaces list response',
  props<{ workspaces: Workspace[] }>(),
);
export const getWorkspacesListError = createAction(
  '[Workspaces] Get workspaces list error',
  props<{ err: ValidationError[] }>(),
);

export const searchWorkspacesList = createAction(
  '[Workspaces] Search workspaces list',
  props<{ params: ListParams }>(),
);
export const searchWorkspacesListResponse = createAction(
  '[Workspaces] Search workspaces list response',
  props<{ workspaces: Workspace[] }>(),
);

export const loadMoreWorkspacesList = createAction(
  '[Workspaces] Load more workspaces list',
  props<{ params: ListParams }>(),
);
export const loadMoreWorkspacesListResponse = createAction(
  '[Workspaces] Load more workspaces list response',
  props<{ workspaces: Workspace[]; limit: number }>(),
);

export const saveWorkspace = createAction('[Workspaces] Save workspace', props<{ workspace: Partial<Workspace> }>());
export const saveWorkspaceResponse = createAction(
  '[Workspaces] Save workspace response',
  props<{ data: Workspace; closeModal?: boolean }>(),
);
export const saveWorkspaceError = createAction(
  '[Workspaces] Save workspace error',
  props<{ errors: ValidationError[] }>(),
);

export const updateWorkspace = createAction(
  '[Workspaces] Update workspace',
  props<{ workspace: Partial<Workspace>; params: ListParams; workspaceId: number }>(),
);
export const updateWorkspaceResponse = createAction(
  '[Workspaces] Update workspace response',
  props<{ data: Workspace }>(),
);
export const updateWorkspaceError = createAction(
  '[Workspaces] Update workspace error',
  props<{ errors: ValidationError[] }>(),
);

export const removeWorkspace = createAction('[Workspaces] Remove workspace', props<{ workspaceId: number }>());
export const removeWorkspaceItem = createAction('[Workspaces] Remove workspace item', props<{ data: Workspace }>());
export const removeWorkspaceResponse = createAction(
  '[Workspaces] Remove workspace response',
  props<{ data: Workspace }>(),
);
export const removeWorkspaceError = createAction(
  '[Workspaces] Remove workspace error',
  props<{ errors: ValidationError[] }>(),
);
