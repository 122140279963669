import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as cronstrue from 'cronstrue';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from '../../common/services/permissions.service';
import { Schedule } from '../schedule.models';
import { NotifyService } from '../../common/services/notify.service';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { duplicateSchedule, removeSchedule, runSchedule, updateSchedule } from '../store/schedules.actions';
import { DialogType } from '../../common/services/components/dialog-template.component';
import { first, map } from 'rxjs/operators';
import { selectAccountPermissions } from '../../account/store/account.selectors';

@Component({
  selector: 'schedule-list-item',
  template: `
    <div class="generic-list-item slider-animation" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div
        class="schedule-list-item"
        [ngClass]="{
          inactive: item.status === 'disabled'
        }"
        id="schedule-{{ item.id }}"
      >
        <div class="schedule-name">
          <common-icon iconId="icon-schedules"></common-icon>
          <div class="schedule-name-container">
            <strong class="name-container"
              ><a
                (click)="editSchedule(item)"
                [matTooltip]="item.name"
                matTooltipPosition="right"
                matTooltipClass="right wide"
                [innerHTML]="item.name | xpHighLight: searchValue"
              ></a>
              <span *ngIf="item.status === 'disabled'" class="inactive status-info">{{
                'schedule.generic-list.item.labels.inactive' | translate
              }}</span>
              <span *ngIf="item.status !== 'disabled'" class="active status-info">{{
                'schedule.generic-list.item.labels.active' | translate
              }}</span></strong
            >
            <p
              class="schedule-description"
              *ngIf="item.description"
              [innerHTML]="item.description | xpHighLight: searchValue"
            ></p>
          </div>
        </div>
        <div class="schedule-owner">
          <a (click)="goToOwner(item)">{{ item.owner.name || item.owner.email }}</a>
        </div>
        <div class="schedule-repeat">
          <strong *ngIf="item.packages_count != null">{{
            'schedule.generic-list.item.labels.packages_count' | translate: { count: item.packages_count }
          }}</strong>
          <span *ngIf="item.schedule_type === 'cron'">{{ scheduleMessage }}</span>
          <span *ngIf="item.schedule_type === 'interval'">{{
            'schedule.generic-list.item.labels.intervals.' + item.interval_unit
              | translate: { interval_amount: item.interval_amount }
          }}</span>
        </div>
        <div class="schedule-last-run">
          <span *ngIf="item.last_run_at == null">{{ 'schedule.generic-list.item.labels.never' | translate }}</span>
          <span
            *ngIf="item.last_run_at != null"
            [matTooltip]="item.last_run_at | xpDateUTC"
            matTooltipPosition="above"
            >{{ item.last_run_at | xpDateWithTime }}</span
          >
        </div>
        <div class="schedule-next-run">
          <span
            *ngIf="item.next_run_at != null"
            [matTooltip]="item.next_run_at | xpDateUTC"
            matTooltipPosition="above"
            >{{ item.next_run_at | xpDateWithTime }}</span
          >
        </div>
        <div class="dropdown xp-dropdown-full" *ngxPermissionsOnly="'updateSchedule'">
          <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
        </div>
        <mat-menu #dropdown="matMenu" class="full-dropdown-menu">
          <li mat-menu-item (click)="toggleStatus(item)" *ngxPermissionsOnly="'updateSchedule'">
            <span>{{ item.status === 'disabled' ? 'Activate' : 'Disable' }} schedule</span>
          </li>
          <li mat-menu-item (click)="runSchedule(item)" *ngxPermissionsOnly="'createJob'">
            <span>{{ 'schedule.generic-list.item.actions.run' | translate }}</span>
          </li>
          <li mat-menu-item (click)="editSchedule(item)" class="top-separator" *ngxPermissionsOnly="'updateSchedule'">
            <span>{{ 'schedule.generic-list.item.actions.edit' | translate }}</span>
          </li>
          <li mat-menu-item (click)="duplicateSchedule(item)" *ngxPermissionsOnly="'createSchedule'">
            <span>{{ 'schedule.generic-list.item.actions.duplicate' | translate }}</span>
          </li>
          <li mat-menu-item (click)="onRemove(item)" *ngxPermissionsOnly="'deleteSchedule'" class="top-separator">
            <span class="text-danger">{{ 'schedule.generic-list.item.actions.delete' | translate }}</span>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class ScheduleListItemComponent {
  @Input() item: Schedule;
  @Input() searchValue: string;
  @Output() edit = new EventEmitter<Schedule>();
  accountPermissions$ = this.store.select(selectAccountPermissions);

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private notify: NotifyService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  editSchedule(item: Schedule) {
    this.accountPermissions$
      .pipe(
        map((permissions) => permissions.includes('updateSchedule')),
        first(),
      )
      .subscribe((hasPermission) => {
        if (hasPermission) {
          this.edit.emit(item);
        }
      });
  }

  duplicateSchedule(item: Schedule) {
    this.store.dispatch(duplicateSchedule({ schedule: item }));
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  goToOwner(item: Schedule): void {
    this.router.navigate([
      `/${this.route.parent.snapshot.params.account_id}/settings/account/members/${item.owner_id}`,
    ]);
  }

  runSchedule(item: Schedule): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('schedule.generic-list.item.modals.run.confirm.title'),
      hint: this.translate.instant('schedule.generic-list.item.modals.run.confirm.hint'),
      yes: this.translate.instant('schedule.generic-list.item.modals.run.confirm.yes'),
      no: this.translate.instant('schedule.generic-list.item.modals.run.confirm.no'),
      item,
      itemType: ItemType.schedule,
      type: DialogType.warning,
      maxWidth: '600px',
      minWidth: '500px',
      hideIcon: true,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(runSchedule({ schedule: item }));
      }
    });
  }

  get scheduleMessage(): string {
    return cronstrue.toString(this.item.schedule_expression.toUpperCase());
  }

  toggleStatus(item: Schedule) {
    const newStatus = item.status === 'enabled' ? 'disabled' : 'enabled';
    const dialogRef = this.confirmationDialog.openDialog({
      title: newStatus === 'enabled' ? 'Activate schedule' : 'Disable schedule',
      hint:
        newStatus === 'enabled'
          ? 'Activating this schedule will enable jobs to run accordingly.'
          : 'Disabling this schedule will prevent jobs from running accordingly.',
      yes: 'Continue',
      no: 'Cancel',
      item: item,
      itemType: ItemType.schedule,
      type: DialogType.warning,
      hideIcon: true,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(
          updateSchedule({
            schedule: { ...item, status: newStatus },
            scheduleId: item.id,
            params: {},
          }),
        );
      }
    });
  }

  onRemove(item: Schedule): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('schedule.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('schedule.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('schedule.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('schedule.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.schedule,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeSchedule({ scheduleId: item.id }));
      }
    });
  }
}
