import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import './resources/jobs.resource';
import { CommonModule } from '../common/common.module';
import { JobsEffects } from './store/jobs.effects';
import { jobsReducer } from './store/jobs.reducer';
import { JobsListComponent } from './components/jobs-list.component';
import { JobListItemComponent } from './components/job-list-item.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { JobViewComponent } from './components/job-details/job-view.component';
import { JobVariablesComponent } from './components/job-details/job-variables.component';
import { JobOutputsComponent } from './components/job-details/job-outputs.component';
import { JobOutputItemComponent } from './components/job-details/job-output-item.component';
import { TableViewComponent } from './components/job-details/table-view.component';
import { JobErrorsComponent } from './components/job-details/job-errors.component';
import { JobTasksComponent } from './components/job-details/job-tasks.component';
import { JobProgressTaskComponent } from './components/job-details/job-progress-task.component';
import { JobsComponent } from './jobs.component';

@NgModule({
  declarations: [
    JobsListComponent,
    JobListItemComponent,
    JobDetailsComponent,
    JobViewComponent,
    JobVariablesComponent,
    JobOutputsComponent,
    JobOutputItemComponent,
    TableViewComponent,
    JobErrorsComponent,
    JobTasksComponent,
    JobProgressTaskComponent,
    JobsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    NgxPermissionsModule,
    MatTooltipModule,
    StoreModule.forFeature('jobs', jobsReducer),
    EffectsModule.forFeature([JobsEffects]),
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class JobsModule {}
