import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  closeSchedulesModal,
  duplicateSchedule,
  getSchedulesList,
  getSchedulesListError,
  getSchedulesListResponse,
  loadMoreSchedulesList,
  loadMoreSchedulesListResponse,
  removeSchedule,
  removeScheduleError,
  removeScheduleItem,
  removeScheduleResponse,
  runSchedule,
  saveSchedule,
  saveScheduleError,
  saveScheduleResponse,
  searchSchedulesList,
  searchSchedulesListResponse,
  updateSchedule,
  updateScheduleError,
  updateScheduleResponse,
} from './schedules.actions';
import { SchedulesResource } from '../resources/schedules.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../constants/animation-constants';
import { NotifyService } from '../../common/services/notify.service';
import { getErrorFromResponse } from '../../common/helper/response.helpers';
import { removeHTMLTags } from '../../common/pipes/xp-highlight.pipe';
import { closeConnectionsModal } from '../../connections/store/connections.actions';

@Injectable()
export class SchedulesEffects {
  getSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSchedulesList),
      switchMap(({ params }) =>
        this.schedulesResource.query(params).pipe(
          map((schedules) => getSchedulesListResponse({ schedules })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getSchedulesListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  searchSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchSchedulesList),
      switchMap(({ params }) =>
        this.schedulesResource.search(params).pipe(
          map((schedules) => searchSchedulesListResponse({ schedules })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getSchedulesListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreSchedulesList),
      switchMap(({ params }) =>
        this.schedulesResource.query(params).pipe(
          map((schedules) => loadMoreSchedulesListResponse({ schedules })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getSchedulesListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSchedule),
      switchMap(({ schedule }) =>
        this.schedulesResource.save(schedule, { include: 'owner,package,workspace' } as any).pipe(
          map((scheduleResponse) => saveScheduleResponse({ data: scheduleResponse, closeModal: true })),
          catchError((response) => of(saveScheduleError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  saveScheduleResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveScheduleResponse),
      map(({ closeModal }) => (closeModal ? closeSchedulesModal() : { type: 'empty' })),
    ),
  );

  duplicateSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateSchedule),
      switchMap(({ schedule }) =>
        this.schedulesResource.clone(schedule.id, { ...schedule }, { include: 'owner,package,workspace' } as any).pipe(
          map((scheduleResponse) => saveScheduleResponse({ data: scheduleResponse })),
          catchError((response) => of(saveScheduleError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  runSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runSchedule),
      switchMap(({ schedule }) =>
        this.schedulesResource.run(schedule.id, { ...schedule }, { include: 'owner,package,workspace' } as any).pipe(
          map((scheduleResponse) => {
            const message = this.translate.instant('schedule.notifications.submitted.message', {
              name: removeHTMLTags(schedule.name),
            });

            this.notify.success(message);

            return updateScheduleResponse({ data: scheduleResponse });
          }),
          catchError((response) => of(updateScheduleError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  updateSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSchedule),
      switchMap(({ schedule, params, scheduleId }) =>
        this.schedulesResource
          .update(scheduleId, schedule, { ...params, include: 'owner,package,workspace' } as any)
          .pipe(
            map((scheduleResponse) => updateScheduleResponse({ data: scheduleResponse, closeModal: true })),
            catchError((response) => of(updateScheduleError({ errors: getErrorFromResponse(response) }))),
          ),
      ),
    ),
  );

  updateScheduleResponse = createEffect(() =>
    this.actions$.pipe(
      ofType(updateScheduleResponse),
      map(({ closeModal }) => (closeModal ? closeSchedulesModal() : { type: 'empty' })),
    ),
  );

  removeSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSchedule),
      switchMap(({ scheduleId }) =>
        this.schedulesResource.remove(scheduleId).pipe(
          map((scheduleResponse) => removeScheduleItem({ data: scheduleResponse })),
          catchError((response) => of(removeScheduleError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeScheduleResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeScheduleItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeScheduleResponse({ data })),
    ),
  );

  constructor(
    private actions$: Actions,
    private schedulesResource: SchedulesResource,
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}
}
