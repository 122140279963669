import { createReducer, on } from '@ngrx/store';
import { Hook, HookEvent, HookType, HookTypesEnum } from '../../hook.models';
import {
  closeHooksModal,
  getHook,
  getHookEvents,
  getHookEventsResponse,
  getHookResponse,
  getHooksList,
  getHooksListError,
  getHooksListResponse,
  getHookTypes,
  getHookTypesResponse,
  loadMoreHooksList,
  loadMoreHooksListResponse,
  openHooksModal,
  pingHook,
  pingHookClear,
  pingHookError,
  pingHookResponse,
  removeHookItem,
  removeHookResponse,
  saveHook,
  saveHookError,
  saveHookResponse,
  selectHookType,
  updateHook,
  updateHookError,
  updateHookResponse,
} from './hooks.actions';
import { ValidationError } from '../../../config/validation-error.model';
import { closeAllModals } from '../../../account/store/account.actions';
import { QueryParamsHooksList } from '../../../common/helper/query-params-generic-list.helper';

export const initialState: HooksState = {
  items: [],
  types: [],
  events: [],
  isLoading: false,
  areTypesLoading: false,
  areEventsLoading: false,
  isFormSubmitting: false,
  isModalOpen: false,
  selectedHookType: undefined,
  errors: [],
  isResponseError: false,
  areAllItemsLoaded: false,
};

export interface HooksState {
  items: Hook[];
  events: HookEvent[];
  types: HookType[];
  areTypesLoading: boolean;
  areEventsLoading: boolean;
  isLoading: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  selectedHookType: HookTypesEnum;
  errors: ValidationError[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
}

export const hooksReducer = createReducer<HooksState>(
  initialState,

  on(selectHookType, (state, { hookType }) => ({ ...state, selectedHookType: hookType })),

  on(openHooksModal, (state) => ({ ...state, isModalOpen: true })),
  on(closeHooksModal, (state) => ({ ...state, isModalOpen: false })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false })),

  on(getHook, (state) => ({ ...state, isLoading: true })),
  on(getHookResponse, (state, { hook }) => ({ ...state, isLoading: false, item: hook })),

  on(getHookTypes, (state) => ({ ...state, areTypesLoading: true })),
  on(getHookTypesResponse, (state, { hookTypes }) => ({ ...state, areTypesLoading: false, types: hookTypes })),

  on(getHookEvents, (state) => ({ ...state, areEventsLoading: true })),
  on(getHookEventsResponse, (state, { hookEvents }) => ({ ...state, areEventsLoading: false, events: hookEvents })),

  on(getHooksList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getHooksListResponse, (state, { hooks }) => ({
    ...state,
    isLoading: false,
    items: hooks,
    areAllItemsLoaded: hooks.length < QueryParamsHooksList.limit,
  })),
  on(getHooksListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(loadMoreHooksList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreHooksListResponse, (state, { hooks }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...hooks],
    areAllItemsLoaded: hooks.length < QueryParamsHooksList.limit,
  })),

  on(saveHook, (state) => ({ ...state, isFormSubmitting: true })),
  on(saveHookResponse, (state, { data }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.find((item) => item.id === data.id) ? state.items : [data, ...state.items],
    isModalOpen: false,
  })),
  on(saveHookError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(updateHook, (state) => ({ ...state, isFormSubmitting: true })),
  on(updateHookResponse, (state, { data }) => ({
    ...state,
    isFormSubmitting: false,
    items: state.items.map((item) => (item.id === data.id ? data : item)),
    isModalOpen: false,
  })),
  on(updateHookError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(removeHookItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),
  on(removeHookResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),

  on(pingHook, (state, { hookId }) => ({
    ...state,
    items: state.items.map((item) => (item.id === hookId ? { ...item, pingLoading: true } : item)),
  })),
  on(pingHookResponse, (state, { hook }) => ({
    ...state,
    items: state.items.map((item) => (item.id === hook.id ? { ...item, pingLoading: false, pingSuccess: true } : item)),
  })),
  on(pingHookError, (state, { error, hookId }) => ({
    ...state,
    items: state.items.map((item) => (item.id === hookId ? { ...item, pingLoading: false, pingError: error } : item)),
  })),
  on(pingHookClear, (state, { hookId }) => ({
    ...state,
    items: state.items.map((item) => (item.id === hookId ? { ...item, pingSuccess: false, pingError: '' } : item)),
  })),
);
