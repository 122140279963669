import { Component, EventEmitter, Input, Output } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { CUBE_ROLLUP_PICKER } from '../../../../constants/cube_rollup_picker';

@Component({
  selector: 'xp-cube-rollup-picker',
  template: `
    <div class="cube-rollup-picker">
      <xp-select
        [value]="value"
        (valueChange)="onValueChange($event)"
        [options]="options"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        [preventEmpty]="true"
      ></xp-select>
    </div>
  `,
  providers: [],
})
export class XpCubeRollupPickerComponent {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Output() fieldChange = new EventEmitter();
  options: SelectOption[] = CUBE_ROLLUP_PICKER;
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  onValueChange(value: ConditionOperator) {
    if (this.value === value) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.value = value;
    this.fieldChange.emit({ value: this.value });
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
