import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Job } from '../../jobs.models';

@Component({
  selector: 'job-progress-task',
  template: `
    <div
      class="job-progress-task {{ progressTask.status }}"
      (click)="redirectToChild(progressTask)"
      *ngIf="progressTask.component.name != ''"
    >
      <div class="job-progress-task-grid">
        <div class="job-progress-task-id">
          <strong>
            <component-icon [type]="progressTask.component.type"></component-icon>
            <span class="name">{{ progressTask.component.name || 'Task' }}</span>
          </strong>
          <span class="id">#{{ progressTask.id }}</span>
        </div>
        <div class="job-progress-task-input">
          <div>
            <strong>Started</strong>
            <span *ngIf="progressTask.started_at"
              ><span
                class="date-tooltip"
                [matTooltip]="progressTask.started_at | xpDateUTC"
                matTooltipPosition="above"
                matTooltipClass="above"
                >{{ progressTask.started_at | xpCalendar }}</span
              ></span
            >
          </div>
        </div>
        <div class="job-progress-task-output">
          <div>
            <strong>Completed</strong>
            <span *ngIf="progressTask.completed_at"
              ><span
                class="date-tooltip"
                [matTooltip]="progressTask.completed_at | xpDateUTC"
                matTooltipPosition="above"
                matTooltipClass="above"
                >{{ progressTask.completed_at | xpCalendar }}</span
              ></span
            >
            <xp-loader *ngIf="!progressTask.completed_at"></xp-loader>
          </div>
        </div>
        <div class="job-progress-task-runtime">
          <div>
            <strong>Status</strong>
            <span>{{ 'job.generic-list.item.labels.' + progressTask.status | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class JobProgressTaskComponent {
  @Input() progressTask: any;
  @Input() item: Job;
  @Output() childOpen = new EventEmitter<Job>();

  redirectToChild(task: Job) {
    this.childOpen.emit(task);
  }
}
