import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { LimitComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';

@Component({
  selector: 'limit-editor',
  template: `
    <div class="limit-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::LimitComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'limit-editor.labels.partitioning' | translate }}</strong></label
              >
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.partitioning"
                    name="partitioning"
                    id="partitioning-all"
                    value="all"
                    (ngModelChange)="onPartitioningChange($event)"
                  />
                  <label for="partitioning-all">{{ 'limit-editor.radiolist.partitioning.all' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.partitioning"
                    name="partitioning"
                    id="partitioning-partition_by"
                    value="partition_by"
                    (ngModelChange)="onPartitioningChange($event)"
                  />
                  <label for="partitioning-partition_by">{{
                    'limit-editor.radiolist.partitioning.partition_by' | translate
                  }}</label>
                </div>
              </div>
              <div *ngIf="rawComponent.partitioning === 'partition_by'">
                <group-by-collection
                  [records]="rawComponent.partitioned_fields || []"
                  [parentSchemas]="parentSchemas"
                  (recordsChange)="onPartitionedFieldsChange($event)"
                  fieldsName="partitioned_fields"
                  [isVisible]="rawComponent.partitioning === 'partition_by'"
                  (validityChange)="onPartitionedFieldsValidityChange($event)"
                ></group-by-collection>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label
                ><strong>{{ 'limit-editor.labels.order' | translate }}</strong></label
              >
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    (ngModelChange)="onOrderChange($event)"
                    [ngModel]="rawComponent.order"
                    name="order"
                    id="order-no_sort"
                    value="no_sort"
                  />
                  <label for="order-no_sort">{{ 'limit-editor.radiolist.order.no_sort' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    (ngModelChange)="onOrderChange($event)"
                    [ngModel]="rawComponent.order"
                    name="order"
                    id="order-sort"
                    value="sort"
                  />
                  <label for="order-sort">{{ 'limit-editor.radiolist.order.sort' | translate }}</label>
                </div>
              </div>
              <div *ngIf="rawComponent.order === 'sort'">
                <sort-collection
                  [records]="rawComponent.ordered_fields || []"
                  [parentSchemas]="parentSchemas"
                  fieldsName="ordered_fields"
                  [isVisible]="rawComponent.order === 'sort'"
                  (recordsChange)="onOrderedFieldsChange($event)"
                  (validityChange)="onSortCollectionValidityChange($event)"
                ></sort-collection>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <xp-form-group>
                <label for="n"
                  ><strong>{{ 'limit-editor.labels.limit_to' | translate }}</strong></label
                >
                <input
                  type="number"
                  class="form-control"
                  [ngModel]="rawComponent.n"
                  name="n"
                  min="1"
                  id="n"
                  (ngModelChange)="onNChange($event)"
                  [placeholder]="'limit-editor.placeholders.limit' | translate"
                />
              </xp-form-group>
            </div>
            <div class="col-md-3 input-text">
              {{ 'limit-editor.labels.records_per_partition' | translate }}
            </div>
          </div>
          <div class="row">
            <component-previewer [componentId]="rawComponent.id"></component-previewer>
          </div>
        </form>
      </xp-form-validation>
    </div>
  `,
})
export class LimitEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: LimitComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  sortCollectionValid = false;
  partitionedCollectionValid = false;
  isFormValid = false;
  validationChangeSubscription: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });

    if (this.rawComponent.partitioned_fields?.length === 0) {
      this.onPartitioningChange('all');
    }

    if (this.rawComponent.ordered_fields?.length === 0) {
      this.onOrderChange('no_sort');
    }

    if (!this.rawComponent.partitioning) {
      this.onPartitioningChange(this.rawComponent.partitioned_fields?.length > 0 ? 'partition_by' : 'all');
    }

    if (!this.rawComponent.order) {
      this.onOrderChange(this.rawComponent.ordered_fields?.length > 0 ? 'sort' : 'no_sort');
    }

    if (!this.rawComponent.n) {
      this.onNChange(1);
    }
  }

  onRecordsChange(records) {
    this.store.dispatch(updateRawComponent({ rawComponent: { fields: records } }));
  }

  onValidityChange() {
    // if 'partition_by' is selected, then group by fields must be valid. if not 'partition_by' then just be valid. same for sort
    const isValid =
      !(this.rawComponent.partitioning === 'partition_by' && !this.partitionedCollectionValid) &&
      !(this.rawComponent.order === 'sort' && !this.sortCollectionValid) &&
      this.rawComponent.n > 0;

    setTimeout(() => {
      this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
    });
  }

  onNChange(n: number) {
    this.store.dispatch(updateRawComponent({ rawComponent: { n } }));
    this.store.dispatch(updateComponent({ component: { n } }));
  }

  onOrderedFieldsChange(ordered_fields) {
    this.store.dispatch(updateRawComponent({ rawComponent: { ordered_fields } }));
    this.store.dispatch(updateComponent({ component: { ordered_fields } }));
  }

  onOrderChange(order: string) {
    const component: any = { order };

    if (order === 'no_sort') {
      component.ordered_fields = [];
    }
    this.store.dispatch(updateRawComponent({ rawComponent: { ...component } }));
    this.store.dispatch(updateComponent({ component }));
  }

  onPartitionedFieldsChange(partitioned_fields) {
    this.store.dispatch(updateRawComponent({ rawComponent: { partitioned_fields } }));
    this.store.dispatch(updateComponent({ component: { partitioned_fields } }));
  }

  onPartitioningChange(partitioning: string) {
    const component: any = { partitioning };

    if (partitioning === 'all') {
      component.partitioned_fields = [];
    }
    this.store.dispatch(updateRawComponent({ rawComponent: { ...component } }));
    this.store.dispatch(updateComponent({ component }));
  }

  onSortCollectionValidityChange(value) {
    this.sortCollectionValid = value;

    this.onValidityChange();
  }

  onPartitionedFieldsValidityChange(value) {
    this.partitionedCollectionValid = value;

    this.onValidityChange();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
