import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectVariables } from '../../store/package-designer.selectors';
import { AppState } from '../../../store';

function isValidDate(date: any): boolean {
  return date instanceof Date && !Number.isNaN(date) && date.toString() !== 'Invalid Date';
}

@Component({
  selector: 'date-range',
  template: `
    <div class="date-range">
      <div class="date-range-buttons-container">
        <div class="btn-group btn-group-md btn-group-select">
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ 'active btn-primary': !isStartDateAsVariable }"
            (click)="isStartDateAsVariable = false"
          >
            Date
          </button>
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ 'active btn-primary': isStartDateAsVariable }"
            (click)="isStartDateAsVariable = true"
          >
            Variable
          </button>
        </div>

        <mat-form-field appearance="fill" class="example-form-field" *ngIf="!isStartDateAsVariable">
          <mat-label>Choose an start date</mat-label>
          <input
            matInput
            [matDatepicker]="startDatepicker"
            [ngModel]="startDateValue"
            name="start_date"
            (dateChange)="onStartDateChange($event)"
          />
          <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker> </mat-datepicker>
        </mat-form-field>
        <div class="form-group" *ngIf="isStartDateAsVariable">
          <xp-select
            [value]="startDateValue"
            [options]="variablesOptions$ | async"
            (valueChange)="onStartDateVariableChange($event)"
            name="start_date"
            id="start_date"
            placeholder="Select a variable"
            class="form-control xp-select"
          ></xp-select>
        </div>
      </div>

      <div class="date-symbol">—</div>

      <div class="date-range-buttons-container">
        <div class="btn-group btn-group-md btn-group-select">
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ 'active btn-primary': !isEndDateAsVariable }"
            (click)="isEndDateAsVariable = false"
          >
            Date
          </button>
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ 'active btn-primary': isEndDateAsVariable }"
            (click)="isEndDateAsVariable = true"
          >
            Variable
          </button>
        </div>
        <mat-form-field appearance="fill" class="example-form-field" *ngIf="!isEndDateAsVariable">
          <mat-label>Choose an end date</mat-label>
          <input
            matInput
            [matDatepicker]="endDatepicker"
            [ngModel]="endDateValue"
            name="end_date"
            (dateChange)="onEndDateChange($event)"
          />
          <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker> </mat-datepicker>
        </mat-form-field>
        <div class="form-group" *ngIf="isEndDateAsVariable">
          <xp-select
            [value]="endDateValue"
            [options]="variablesOptions$ | async"
            (valueChange)="onEndDateVariableChange($event)"
            name="end_date"
            id="end_date"
            placeholder="Select a variable"
            class="form-control xp-select"
          ></xp-select>
        </div>
      </div>

      <i
        class="fa fa-exclamation-circle form-control-info date-range-hint"
        [matTooltip]="'bing-ads-source-editor.form.hints.date_range' | translate"
        matTooltipPosition="after"
        matTooltipClass="after"
      ></i>
    </div>
  `,
})
export class DateRangeComponent implements OnChanges, OnInit, OnDestroy {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() format: string;
  @Input() form: NgForm;
  @Output() startDateChange = new EventEmitter<string>();
  @Output() endDateChange = new EventEmitter<string>();
  startDateValue;
  endDateValue;
  isStartDateAsVariable = false;
  isEndDateAsVariable = false;

  variablesOptions$ = this.store
    .select(selectVariables)
    .pipe(map((variables) => Object.keys(variables || {}).map((key) => ({ value: key, text: key }))));

  constructor(private store: Store<AppState>) {}

  // eslint-disable-next-line class-methods-use-this
  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['use_custom_date_range'] = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startDate?.currentValue !== changes.startDate?.previousValue) {
      const isValidStartDate = isValidDate(new Date(this.startDate));
      if (isValidStartDate || !this.startDate) {
        this.startDateValue = this.startDate ? moment(this.startDate).toDate() : '';
        this.isStartDateAsVariable = false;
      } else {
        this.isStartDateAsVariable = true;
        this.startDateValue = this.startDate.replace('$', '');
      }
    }
    if (changes.endDate?.currentValue !== changes.endDate?.previousValue) {
      const isValidEndDate = isValidDate(new Date(this.endDate));
      if (isValidEndDate || !this.endDate) {
        this.endDateValue = this.endDate ? moment(this.endDate).toDate() : '';
        this.isEndDateAsVariable = false;
      } else {
        this.isEndDateAsVariable = true;
        this.endDateValue = this.endDate.replace('$', '');
      }
    }
  }

  onStartDateChange({ value }) {
    const startDateValue = moment(value).format(this.format || 'll');
    if (!value || this.startDateValue === startDateValue) {
      return;
    }
    this.startDateChange.emit(startDateValue);
  }

  onEndDateChange({ value }) {
    const endDateValue = moment(value).format(this.format || 'll');
    if (!value || this.endDateValue === endDateValue) {
      return;
    }
    this.endDateChange.emit(endDateValue);
  }

  onStartDateVariableChange(variable: string) {
    this.startDateChange.emit(`$${variable}`);
  }

  onEndDateVariableChange(variable: string) {
    this.endDateChange.emit(`$${variable}`);
  }

  // eslint-disable-next-line class-methods-use-this
  ngOnDestroy() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['use_custom_date_range'] = true;
  }
}
