import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { FieldsCollectionValidationsService } from '../../../../common/services/fields-collection-validations.service';
import { ValidatorData } from '../../../../common/validators/helpers/validator-data.model';
import { ValidationsEnum } from '../../../../common/services/validations.enum';
import { ConditionOperatorHandler } from '../../../../constants/conditions_operator_picker';
import { AppState } from '../../../../store';
import { openExpressionEditor } from '../../../store/package-designer.actions';
import { selectExpressionEditorSaveCode, selectIsExpressionOpenFlag } from '../../../store/package-designer.selectors';

@Component({
  selector: 'xp-field-expression-editor',
  template: `
    <div class="field-picker" [ngClass]="state">
      <div
        class="editor-button editor-button-open"
        (click)="editorButtonOpen()"
        *ngIf="type !== 'text' && type !== conditionOperatorHandlers.none"
      >
        <xp-icon
          type="edit"
          *ngIf="type !== conditionOperatorHandlers.text && type !== conditionOperatorHandlers.none"
        ></xp-icon>
      </div>
      <div class="editor-picker">
        <xp-input
          [ngModel]="value"
          (ngModelChange)="onValueChange($event)"
          [autoCompleteOptions]="autocompleteOptions"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
          [tooltip]="tooltip"
          [placeholder]="placeholder"
          [disable]="type === conditionOperatorHandlers.none"
          [isFocused]="focusedProp === propName"
          name="alias-editor"
        ></xp-input>
        <div class="editor-picker-dropdown"></div>
      </div>
    </div>
  `,
  providers: [],
})
export class XpFieldExpressionEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: string;
  @Input() fields: any[];
  @Input() schema: any;
  @Input() index: number;
  @Input() type: ConditionOperatorHandler;
  @Input() focusedProp: string;
  @Input() propName: string = 'name';
  @Output() fieldChange = new EventEmitter();
  validate: (...args: any) => ValidatorData;
  autocompleteOptions: any[];
  placeholder: string;
  state: string;
  tooltip: string;
  lastExpressionValue: string;
  lastTextValue: string;
  conditionOperatorHandlers = ConditionOperatorHandler;
  isFirstChange = true;
  expressionEditorCloseSubscription: Subscription;
  isExpressionEditorOpen: boolean;

  constructor(
    private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
    private translate: TranslateService,

    private store: Store<AppState>,
  ) {
    this.validate = this.fieldsCollectionValidationsService.getValidationFunction(
      ValidationsEnum.FIELDS_EXPRESSION_EDITOR,
    );
  }

  ngOnInit() {
    if (!this.type) {
      this.type = ConditionOperatorHandler.expression;
    }

    if (this.type === ConditionOperatorHandler.none) {
      this.value = null;
    }

    this.placeholder = this.translate.instant(
      `fields-collection.editors.field-expression-editor.placeholders.${this.type}`,
    );

    this.expressionEditorCloseSubscription = this.store
      .select(selectIsExpressionOpenFlag)
      .pipe(
        filter((isOpen) => !isOpen),
        withLatestFrom(this.store.select(selectExpressionEditorSaveCode)),
      )
      .subscribe(([, code]) => {
        if (this.isExpressionEditorOpen) {
          if (code) {
            this.onValueChange(code);
          }
          this.isExpressionEditorOpen = false;
        }
      });
  }

  getAutoCompleteOptions(string = '') {
    if (this.type === ConditionOperatorHandler.text) {
      return [];
    }

    const fieldNames = this.fields.map((field) => field.name);

    if (!string) {
      return fieldNames;
    }

    const query = string.split(/[\s,(]/);
    const searchText = query.pop().toUpperCase();

    const results = fieldNames.filter(function (fieldName) {
      return (fieldName || '').toUpperCase().includes(searchText);
    });

    const currentFieldIndex = results.join(',').toUpperCase().split(',').indexOf(searchText);

    if (currentFieldIndex > -1) results.splice(currentFieldIndex, 1);

    return results;
  }

  editorButtonOpen() {
    this.openExpression();

    this.validityFn(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.validityFn(changes.value.currentValue);
    }

    if (changes.fields && changes.fields.currentValue) {
      this.autocompleteOptions = this.getAutoCompleteOptions();
    }

    if (changes.type && changes.type.currentValue) {
      this.setConditionOperatorHandler(changes.type.currentValue, changes.type.previousValue);
    }
  }

  openExpression() {
    this.isExpressionEditorOpen = true;
    this.store.dispatch(
      openExpressionEditor({
        code: this.value,
        origin: 'fields-collection',
        category: 'User',
      }),
    );
  }

  onValueChange(value: string) {
    if (this.value === value) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.value = value;
    this.autocompleteOptions = this.getAutoCompleteOptions(value);
    this.fieldChange.emit(this.value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }

  validityFn(value: string): boolean {
    if (this.xpFieldsCollectionComponent && this.xpFieldsCollectionComponent.isRecordPristine(this.index)) {
      return false;
    }

    if (this.type === ConditionOperatorHandler.none) {
      return false;
    }

    if (typeof this.validate === 'function') {
      const validityObject = this.validate(value);
      this.setValidity(validityObject);
      return validityObject.valid;
    }

    return false;
  }

  setValidity(validityObject: Partial<ValidatorData>) {
    if (!validityObject.valid) {
      this.state = 'error';
      this.tooltip = validityObject.message;
    } else {
      this.state = 'editable';
      this.tooltip = '';
    }

    this.registerError(validityObject.valid, validityObject.message);
  }

  setConditionOperatorHandler(currentType: ConditionOperatorHandler, previousType: ConditionOperatorHandler) {
    if (currentType === ConditionOperatorHandler.none && typeof previousType === 'string') {
      if (previousType === ConditionOperatorHandler.expression) this.lastExpressionValue = this.value;
      if (previousType === ConditionOperatorHandler.text) this.lastTextValue = this.value;
      this.value = null;
      this.setValidity({ valid: true });
    } else if (currentType === ConditionOperatorHandler.text && typeof previousType === 'string') {
      if (previousType === ConditionOperatorHandler.expression) this.lastExpressionValue = this.value;
      this.value = typeof this.lastTextValue !== 'undefined' ? this.lastTextValue : '';
    } else if (currentType === ConditionOperatorHandler.expression && typeof previousType === 'string') {
      if (previousType === ConditionOperatorHandler.text) this.lastTextValue = this.value;
      this.value = typeof this.lastExpressionValue !== 'undefined' ? this.lastExpressionValue : '';
    }
    this.placeholder = this.translate.instant(
      `fields-collection.editors.field-expression-editor.placeholders.${this.type}`,
    );

    if (this.value) {
      this.validityFn(this.value);
    }
  }

  registerError(valid: boolean, message: string) {
    if (this.xpFieldsCollectionComponent) {
      if (!valid) {
        this.xpFieldsCollectionComponent.registerError(this.index, message, this.propName);
      } else {
        this.xpFieldsCollectionComponent.removeError(this.index, this.propName);
      }
    }
  }

  ngOnDestroy() {
    if (this.expressionEditorCloseSubscription) {
      this.expressionEditorCloseSubscription.unsubscribe();
    }
  }
}
