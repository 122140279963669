export enum FunctionPickerType {
  cubeRollup = 'cubeRollup',
  aggregate = 'aggregate',
  window = 'window',
}

export const FUNCTION_PICKER = {
  [FunctionPickerType.cubeRollup]: [
    {
      value: 'count_star',
      translationPath: 'fields-collection.editors.function-picker.count_star',
    },
    {
      value: 'count',
      translationPath: 'fields-collection.editors.function-picker.count',
    },
    {
      value: 'count_distinct',
      translationPath: 'fields-collection.editors.function-picker.count_distinct',
    },
    {
      value: 'hll',
      translationPath: 'fields-collection.editors.function-picker.hll',
    },
    {
      value: 'avg',
      translationPath: 'fields-collection.editors.function-picker.avg',
    },
    {
      value: 'sum',
      translationPath: 'fields-collection.editors.function-picker.sum',
    },
    {
      value: 'min',
      translationPath: 'fields-collection.editors.function-picker.min',
    },
    {
      value: 'min_by',
      translationPath: 'fields-collection.editors.function-picker.min_by',
    },
    {
      value: 'max',
      translationPath: 'fields-collection.editors.function-picker.max',
    },
    {
      value: 'max_by',
      translationPath: 'fields-collection.editors.function-picker.max_by',
    },
    {
      value: 'var',
      translationPath: 'fields-collection.editors.function-picker.var',
    },
    {
      value: 'varp',
      translationPath: 'fields-collection.editors.function-picker.varp',
    },
    {
      value: 'stdev',
      translationPath: 'fields-collection.editors.function-picker.stdev',
    },
    {
      value: 'stdevp',
      translationPath: 'fields-collection.editors.function-picker.stdevp',
    },
  ],

  [FunctionPickerType.aggregate]: [
    {
      value: 'count_star',
      translationPath: 'fields-collection.editors.function-picker.count_star',
    },
    {
      value: 'count',
      translationPath: 'fields-collection.editors.function-picker.count',
    },
    {
      value: 'count_distinct',
      translationPath: 'fields-collection.editors.function-picker.count_distinct',
    },
    {
      value: 'hll',
      translationPath: 'fields-collection.editors.function-picker.hll',
    },
    {
      value: 'avg',
      translationPath: 'fields-collection.editors.function-picker.avg',
    },
    {
      value: 'sum',
      translationPath: 'fields-collection.editors.function-picker.sum',
    },
    {
      value: 'min',
      translationPath: 'fields-collection.editors.function-picker.min',
    },
    {
      value: 'min_by',
      translationPath: 'fields-collection.editors.function-picker.min_by',
    },
    {
      value: 'max',
      translationPath: 'fields-collection.editors.function-picker.max',
    },
    {
      value: 'max_by',
      translationPath: 'fields-collection.editors.function-picker.max_by',
    },
    {
      value: 'var',
      translationPath: 'fields-collection.editors.function-picker.var',
    },
    {
      value: 'varp',
      translationPath: 'fields-collection.editors.function-picker.varp',
    },
    {
      value: 'stdev',
      translationPath: 'fields-collection.editors.function-picker.stdev',
    },
    {
      value: 'stdevp',
      translationPath: 'fields-collection.editors.function-picker.stdevp',
    },
    {
      value: 'collect',
      translationPath: 'fields-collection.editors.function-picker.collect',
    },
  ],

  [FunctionPickerType.window]: [
    {
      value: 'count',
      translationPath: 'Count',
    },
    {
      value: 'sum',
      translationPath: 'Sum',
    },
    {
      value: 'avg',
      translationPath: 'Average',
    },
    {
      value: 'min',
      translationPath: 'Min',
    },
    {
      value: 'max',
      translationPath: 'Max',
    },
    {
      value: 'first_value',
      translationPath: 'fields-collection.editors.function-picker.first_value',
    },
    {
      value: 'last_value',
      translationPath: 'fields-collection.editors.function-picker.last_value',
    },
    {
      value: 'lead',
      translationPath: 'fields-collection.editors.function-picker.lead',
    },
    {
      value: 'lag',
      translationPath: 'fields-collection.editors.function-picker.lag',
    },
    {
      value: 'row_number',
      translationPath: 'fields-collection.editors.function-picker.row_number',
    },
    {
      value: 'ntile',
      translationPath: 'fields-collection.editors.function-picker.ntile',
    },
  ],
};
