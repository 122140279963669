import { Component, Input } from '@angular/core';

import { GenericListType } from '../generic-list.component';

@Component({
  selector: 'assisted-search-help',
  template: `
    <div id="assisted-search-template" [ngSwitch]="type">
      <span *ngSwitchCase="genericListTypes.clusters">
        <div class="help-title">
          {{ 'assisted-search.help.title' | translate: { resource: 'clusters' } }}
        </div>
        <div class="help-text">
          <span>{{ 'assisted-search.help.prefix' | translate }}</span>
          <span assisted-search-modifier modifier="id:" class="modifier">id:</span>,
          <span assisted-search-modifier modifier="name:" class="modifier">name:</span>,
          <span assisted-search-modifier modifier="created:" class="modifier">created:</span>,
          <span assisted-search-modifier modifier="terminated:" class="modifier">terminated:</span> or
          <span assisted-search-modifier modifier="nodes:" class="modifier">nodes:</span>.
          <span [innerHTML]="'assisted-search.help.suffix' | translate"></span>
        </div>
      </span>

      <span *ngSwitchCase="genericListTypes.jobs">
        <div class="help-title">
          {{ 'assisted-search.help.title' | translate: { resource: 'jobs' } }}
        </div>
        <div class="help-text">
          <span>{{ 'assisted-search.help.prefix' | translate }}</span>
          <span assisted-search-modifier modifier="id:" class="modifier">id:</span>,
          <span assisted-search-modifier modifier="status:" class="modifier">status:</span>,
          <span assisted-search-modifier modifier="started:" class="modifier">started:</span>,
          <span assisted-search-modifier modifier="finished:" class="modifier">finished:</span>,
          <span assisted-search-modifier modifier="duration:" class="modifier">duration:</span>,
          <span assisted-search-modifier modifier="log:" class="modifier">log:</span>,
          <span assisted-search-modifier modifier="package:" class="modifier">package:</span>,
          <span assisted-search-modifier modifier="user:" class="modifier">user:</span>,
          <span assisted-search-modifier modifier="schedule:" class="modifier">schedule:</span> or
          <span assisted-search-modifier modifier="cluster:" class="modifier">cluster:</span>.
          <span [innerHTML]="'assisted-search.help.suffix' | translate"></span>
        </div>
      </span>

      <span *ngSwitchCase="genericListTypes.packages">
        <div class="help-title">
          {{ 'assisted-search.help.title' | translate: { resource: 'packages' } }}
        </div>
        <div class="help-text">
          <span>{{ 'assisted-search.help.prefix' | translate }}</span>
          <span assisted-search-modifier modifier="id:" class="modifier">id:</span>,
          <span assisted-search-modifier modifier="name:" class="modifier">name:</span>,
          <span assisted-search-modifier modifier="user:" class="modifier">user:</span>,
          <span assisted-search-modifier modifier="workspace:" class="modifier">workspace:</span>,
          <span assisted-search-modifier modifier="created:" class="modifier">created:</span> or
          <span assisted-search-modifier modifier="modified:" class="modifier">modified:</span>.
          <span [innerHTML]="'assisted-search.help.suffix' | translate"></span>
        </div>
      </span>

      <span *ngSwitchCase="genericListTypes.schedules">
        <div class="help-title">
          {{ 'assisted-search.help.title' | translate: { resource: 'schedules' } }}
        </div>
        <div class="help-text">
          <span>{{ 'assisted-search.help.prefix' | translate }}</span>
          <span assisted-search-modifier modifier="id:" class="modifier">id:</span>,
          <span assisted-search-modifier modifier="name:" class="modifier">name:</span>,
          <span assisted-search-modifier modifier="description:" class="modifier">description:</span>,
          <span assisted-search-modifier modifier="status:" class="modifier">status:</span> or
          <span assisted-search-modifier modifier="package:" class="modifier">package:</span>.
          <span [innerHTML]="'assisted-search.help.suffix' | translate"></span>
        </div>
      </span>

      <span *ngSwitchCase="genericListTypes.workspaces">
        <div class="help-title">
          {{ 'assisted-search.help.title' | translate: { resource: 'workspaces' } }}
        </div>
        <div class="help-text">
          <span>{{ 'assisted-search.help.prefix' | translate }}</span>
          <span assisted-search-modifier modifier="id:" class="modifier">id:</span>,
          <span assisted-search-modifier modifier="name:" class="modifier">name:</span>,
          <span assisted-search-modifier modifier="description:" class="modifier">description:</span>,
          <span [innerHTML]="'assisted-search.help.suffix' | translate"></span>
        </div>
      </span>
    </div>
  `,
})
export class AssistedSearchHelpComponent {
  @Input() type: GenericListType;
  genericListTypes = GenericListType;
}
