import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { TiktokAdsConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';

@Component({
  selector: 'connection-form-tiktok-ads',
  template: `
    <xp-form-validation type="TiktokAdsConnection" [name]="formName">
      <form id="connectionFormTiktokAds" name="connectionFormTiktokAds" #form="ngForm" class="form">
        <div *ngIf="!connectionTypeButtonCreate" class="text-center">
          <p class="lead">{{ 'connection-form-tiktok-ads.sign_in_text' | translate }}</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <xp-form-group *ngIf="connection.unique_id || connection.code || connection.jwt_token">
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <xp-form-group *ngIf="connection.username">
              <label for="username">{{ 'connections.form.labels.username' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="username"
                id="username"
                [(ngModel)]="connection.username"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </xp-form-group>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormTiktokAdsComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<TiktokAdsConnection> = {};
  @Input() connectionTypeButtonCreate: boolean;
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormTiktokAds';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }
}
