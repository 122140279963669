import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { areComponentFieldsValid } from '../helpers/components.helpers';
import { COMPONENT_PREVIEWER_STATUSES } from '../package.models';
import { PackagesResource } from '../resources/packages.resource';
import {
  componentPreviewerLog,
  componentPreviewerDataError,
  componentPreviewerDataResponse,
  componentPreviewerResultError,
  componentPreviewerResultResponse,
  postComponentPreviewerData,
  componentPreviewerSetValidation,
} from './component-previewer.actions';
import { setComponent, updateRawComponent } from './component.actions';
import { AppState } from '../../store';

@Injectable({
  providedIn: 'root',
})
export class ComponentPreviewerEffects {
  createPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postComponentPreviewerData),
      mergeMap(({ package_id, data }) =>
        this.packagesResource.preview(package_id, data).pipe(
          map((res: any) => componentPreviewerDataResponse({ package_id, preview_id: res.id })),
          catchError((err) => of(componentPreviewerDataError({ err }))),
        ),
      ),
    ),
  );

  getPreviewResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(componentPreviewerLog),
      withLatestFrom(this.store$),
      mergeMap(([{ data }, store]) =>
        data.status === COMPONENT_PREVIEWER_STATUSES.completed &&
        data.id === store.packageDesigner.componentPreviewer.preview_id
          ? this.packagesResource.previewResult(data.package_id, data.id).pipe(
              map((res: any) => componentPreviewerResultResponse({ data: res })),
              catchError((err) => of(componentPreviewerResultError({ err }))),
            )
          : EMPTY,
      ),
    ),
  );

  componentValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRawComponent),
      withLatestFrom(this.store$),
      map(([action, store]) =>
        componentPreviewerSetValidation({ isValid: areComponentFieldsValid(store.packageDesigner.rawComponent) }),
      ),
    ),
  );

  componentSetValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setComponent),
      withLatestFrom(this.store$),
      map(([action, store]) =>
        componentPreviewerSetValidation({ isValid: areComponentFieldsValid(store.packageDesigner.rawComponent) }),
      ),
    ),
  );

  constructor(private actions$: Actions, private packagesResource: PackagesResource, private store$: Store<AppState>) {}
}
