import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'xp-submit-button',
  template: `
    <button
      class="btn"
      data-action="submit"
      [ngClass]="classNames + (isFormSubmitting ? ' loading' : '')"
      [disabled]="!isFormValid || isFormSubmitting"
      (click)="submitForm.emit()"
    >
      <span *ngIf="createText">{{ createText }}</span>
      <span *ngIf="updateText">{{ updateText }}</span>
    </button>
  `,
})
export class XpSubmitButtonComponent {
  @Input() updateText: string = '';
  @Input() createText: string = '';
  @Input() isFormValid: boolean;
  @Input() isFormSubmitting: boolean;
  @Input() classNames: string;
  @Output() submitForm = new EventEmitter();
}
