import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import Clipboard from 'clipboard';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { AuthMethod, SftpConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { selectAccount } from '../../../account/store/account.selectors';

@Component({
  selector: 'connection-form-sftp',
  template: `
    <xp-form-validation type="SftpConnection" [name]="formName">
      <form id="connectionFormSftp" name="connectionFormSftp" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <connection-component-access-type
              [connection]="connection"
              (tunnelTypeChange)="onTunnelTypeChange($event)"
            ></connection-component-access-type>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="auth_method">{{ 'connections.form.sftp.labels.auth_method' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                name="auth_method"
                id="auth_method"
                [value]="connection.auth_method"
                (valueChange)="onAuthMethodChange($event)"
                [options]="authMethodOptions"
              >
              </xp-select>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.auth_method === 'key'">
              <label for="public_key">
                {{ 'connections.form.labels.public_key' | translate }}
              </label>
              <div class="copy-public-key-container">
                <xp-input
                  id="public_key"
                  class="form-control col-md-11"
                  [attr.readonly]="true"
                  [readOnly]="true"
                  type="text"
                  name="public_key"
                  [ngModel]="(account$ | async).public_key"
                ></xp-input
                ><button
                  class="copy-public-key-button sftp-public-button btn btn-gray-light"
                  type="button"
                  [attr.data-clipboard-text]="(account$ | async).public_key"
                  [matTooltip]="'connections.form.sftp.actions.copy_key' | translate"
                  matTooltipPosition="before"
                  matTooltipClass="before"
                >
                  <i class="fa fa-copy" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="connection-component-public-key alert alert-info" *ngIf="connection.auth_method === 'key'">
              <strong
                ><i class="fa fa-info-circle" aria-hidden="true"></i>
                {{ 'connection-component-public-key.title' | translate }}</strong
              >
              <p [innerHTML]="'connection-component-public-key.text' | translate"></p>
            </div>
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
          </div>
        </div>
      </form>
      <div class="alert alert-info" style="margin-top: 30px;">
        <strong><i class="fa fa-info-circle" aria-hidden="true"></i> Don’t have an SFTP server yet?</strong><br />
        You need an SFTP server to store your files that you want to use on the Prepforce platform. If you don’t already
        have an SFTP server, we recommend using SFTP To Go. You can read our step by step instructions for setting this
        up here. It takes ~5 minutes.
      </div>
    </xp-form-validation>
  `,
})
export class ConnectionFormSftpComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<SftpConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() tunnelTypeChange = new EventEmitter<String>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormSftp';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  account$ = this.store.select(selectAccount);
  clipboard: Clipboard;
  authMethodOptions = [
    { value: AuthMethod.password, text: 'connections.form.sftp.select.options.password', translate: true },
    { value: AuthMethod.key, text: 'connections.form.sftp.select.options.key', translate: true },
  ];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    const copyableElemSelector = `.copy-public-key-button.sftp-public-button`;
    this.clipboard = new Clipboard(copyableElemSelector);

    this.clipboard.on('success', this.notifyInfo.bind(this));
  }

  notifyInfo() {
    this.notify.info('Copied!');
  }

  onTunnelTypeChange(value: string) {
    this.tunnelTypeChange.emit(value);
  }

  onAuthMethodChange(authMethod: AuthMethod) {
    this.connection.auth_method = authMethod;
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.clipboard.off('success', this.notifyInfo.bind(this));
  }
}
