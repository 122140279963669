import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { groupBy, uniq } from 'lodash';
import { AppState } from '../../../store';
import { Component as PackageComponent } from '../../package.models';
import {
  hideErrorsViewer,
  openComponentsModal,
  savePackage,
  selectComponents,
  unselectAllComponents,
} from '../../store/package-designer.actions';
import {
  selectComponentsFromPackage,
  selectIsErrorsViewerVisibleFlag,
  selectValidationErrors,
} from '../../store/package-designer.selectors';
import { ValidationError } from '../../store/state.model';
import { getDataFromComponent } from '../../helpers/components.helpers';

interface ErrorsMap {
  general: ValidationError[];
  components: {
    [key: string]: ValidationError[];
  };
  componentIds: string[];
}

@Component({
  selector: 'errors-viewer',
  template: `
    <div class="errors-viewer panel panel-default errors-viewer-new" *ngIf="isVisible$ | async" cdkDrag>
      <div
        class="errors-viewer-header panel-heading errors"
        [ngClass]="{
          'is-success': (errors$ | async).general.length === 0 && (errors$ | async).componentIds.length === 0
        }"
        cdkDragHandle
      >
        <strong class="errors-viewer-title">Package errors</strong>
        <button type="button" class="close" (click)="hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="errors-viewer-body panel-body" *ngIf="errors$ | async as errorsMap">
        <h4 class="errors-viewer-body-title" *ngIf="errorsMap.general.length > 0">
          {{ 'errors-viewer.title' | translate }}
        </h4>
        <div class="panel panel-default" *ngIf="errorsMap.general.length > 0">
          <div class="panel-heading">
            <strong>General errors</strong>
          </div>
          <div class="panel-body">
            <ul class="list-group">
              <li class="list-group-item general-error" *ngFor="let error of errorsMap.general">
                <i class="fa fa-exclamation-circle"></i> {{ error.field || '' }}
                {{ error.message || 'Package is not valid' }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="panel panel-default panel-clickable"
          *ngFor="let componentId of errorsMap.componentIds"
          (click)="openComponent(componentId)"
          (mouseenter)="selectComponent(componentId)"
          (mouseleave)="unselectComponent()"
        >
          <div class="panel-heading">
            <strong>{{ getComponentName(componentId) | xpLengthCheck: 46 }}</strong>
          </div>
          <div class="panel-body">
            <ul class="list-group">
              <li class="list-group-item component-error" *ngFor="let error of errorsMap.components[componentId]">
                <i class="fa fa-exclamation-circle"></i> {{ error.field || '' }} {{ error.message }}
              </li>
            </ul>
          </div>
        </div>
        <div class="text-center" *ngIf="errorsMap.general.length === 0 && errorsMap.componentIds.length === 0">
          <p class="lead">
            <i class="fa fa-check-circle text-success"></i> {{ 'errors-viewer.success-message' | translate }}
          </p>
        </div>
      </div>
      <div class="errors-viewer-footer panel-footer clearfix">
        <button class="btn btn-default pull-right btn-success" style="margin-left: 5px;" (click)="revalidate()">
          {{ 'errors-viewer.revalidate' | translate }}
        </button>
        <button class="btn btn-default pull-right" (click)="hide()">{{ 'errors-viewer.close' | translate }}</button>
      </div>
    </div>
  `,
})
export class ErrorsViewerComponent implements OnInit, OnDestroy {
  isVisible$ = this.store.select(selectIsErrorsViewerVisibleFlag);
  errors$: Observable<ErrorsMap> = this.store.select(selectValidationErrors).pipe(
    map((errors) =>
      errors.reduce(
        (acc, curr) => {
          if (curr.component_id) {
            return {
              ...acc,
              components: [...acc.components, curr],
            };
          }
          if (curr.field === 'components') {
            curr.field = 'Components';
            curr.message = 'are invalid';
          }
          return {
            ...acc,
            general: [...acc.general, curr],
          };
        },
        { general: [], components: [] },
      ),
    ),
    map((errorsMap) => ({
      ...errorsMap,
      components: groupBy(errorsMap.components, 'component_id'),
      componentIds: uniq(errorsMap.components.map((item) => item.component_id)),
    })),
  );

  components: PackageComponent[];
  componentsSubscription: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.componentsSubscription = this.store.select(selectComponentsFromPackage).subscribe((components) => {
      this.components = components;
    });
  }

  hide() {
    this.store.dispatch(hideErrorsViewer());
  }

  selectComponent(componentId: string) {
    this.store.dispatch(selectComponents({ componentIds: [componentId] }));
  }

  unselectComponent() {
    this.store.dispatch(unselectAllComponents());
  }

  openComponent(componentId: string) {
    this.store.dispatch(openComponentsModal({ componentId }));
  }

  revalidate() {
    this.store.dispatch(hideErrorsViewer());
    this.store.dispatch(savePackage({ runValidation: true }));
  }

  getComponentName(componentId: string): string {
    const component = this.components.map(getDataFromComponent).find((item) => item.id === componentId);

    if (!component) {
      this.hide();
      return '';
    }

    return component.name;
  }

  ngOnDestroy() {
    if (this.componentsSubscription) {
      this.componentsSubscription.unsubscribe();
    }
  }
}
