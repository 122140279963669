import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Region } from '../region.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class RegionsResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(private http: HttpClient) {}

  query(region_type: string, params?: ListParams): Observable<Region[]> {
    const url = region_type ? `${this.apiRoot}/regions/${region_type}` : `${this.apiRoot}/regions`;
    return this.http.get<Region[]>(url, { params: params as QueryParams });
  }
}
