import { Injectable } from '@angular/core';
import { Variables } from '../../package-designer/package.models';
import { combineLatestWith, map, Observable } from 'rxjs';
import { VariablesService } from './variables.service';

export interface PackageVariable {
  key: string;
  keyDisabled: boolean;
  hideButtons: boolean;
  setDefault: boolean;
  changed: boolean;
  value: string;
  defaultValue: string;
}

export interface PackageVariablesData {
  systemVariables: PackageVariable[];
  userVariables: PackageVariable[];
  globalVariables: PackageVariable[];
  secretVariables: PackageVariable[];
}

function checkIfIsSystemVariable(variable: PackageVariable, variablesObject: PackageVariablesData): boolean {
  let isSystemVariable = false;

  variablesObject.systemVariables.forEach((defaultVariable, index) => {
    if (defaultVariable.key === variable.key) {
      isSystemVariable = true;
      variablesObject.systemVariables[index].value = variable.value;
      variablesObject.systemVariables[index].changed = true;
    }
  });

  return isSystemVariable;
}

function checkIfIsGlobalVariable(variable: PackageVariable, variablesObject: PackageVariablesData): boolean {
  let isGlobalVariable = false;

  variablesObject.globalVariables.forEach((defaultVariable, index) => {
    if (defaultVariable.key === variable.key) {
      isGlobalVariable = true;
      variablesObject.globalVariables[index].value = variable.value;
      variablesObject.globalVariables[index].changed = true;
    }
  });

  return isGlobalVariable;
}

function objectToArrayOfObjects(obj: Variables, defaults = {}): PackageVariable[] {
  const arrayOfObjects = [];

  for (const key in obj) {
    const variable = {
      key,
      keyDisabled: false,
      hideButtons: false,
      setDefault: false,
      changed: false,
      value: obj[key],
      defaultValue: obj[key],
    };

    arrayOfObjects.push(variable);
  }

  for (const key in defaults) {
    arrayOfObjects.forEach(function (variable) {
      if (variable.key === key) {
        variable.defaultValue = defaults[key];
        variable.changed = defaults[key] !== obj[key];
      }
    });
  }

  return arrayOfObjects;
}

@Injectable({
  providedIn: 'root',
})
export class PackageVariablesService {
  constructor(private variablesService: VariablesService) {}

  separateVariables(variables, defaults, secretVariables): Observable<PackageVariablesData> {
    const variablesObject = {
      userVariables: [],
      systemVariables: [],
      globalVariables: [],
      secretVariables: [],
    };

    return this.variablesService.getSystems().pipe(
      combineLatestWith(this.variablesService.getGlobals()),
      map(([systemVariables, globalVariables]) => [
        objectToArrayOfObjects(systemVariables),
        objectToArrayOfObjects(globalVariables),
      ]),
      map(([systemVariables, globalVariables]) => {
        variablesObject.systemVariables = [...systemVariables];
        variablesObject.globalVariables = [...globalVariables];

        const variablesArray = objectToArrayOfObjects(variables || {}, defaults || {});

        variablesObject.userVariables = variablesArray.filter(
          (variable) =>
            !checkIfIsSystemVariable(variable, variablesObject) && !checkIfIsGlobalVariable(variable, variablesObject),
        );

        variablesObject.secretVariables = objectToArrayOfObjects(secretVariables || {});

        return variablesObject;
      }),
    );
  }
}
