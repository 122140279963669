import { Step } from '../components/xp-steps.component';

export function getStep(params: Partial<Step>): Step {
  return {
    title: '',
    activeTitle: '',
    tags: [],
    valid: false,
    lock: false,
    disable: false,
    ...params,
  };
}
