import { createAction, props } from '@ngrx/store';
import { Hook, HookEvent, HookType, HookTypesEnum } from '../../hook.models';
import { ValidationError } from '../../../config/validation-error.model';
import { ListParams } from '../../../common/helper/query-params-generic-list.helper';

export const openHooksModal = createAction('[Hooks] Open modal');
export const closeHooksModal = createAction('[Hooks] Close modal');

export const selectHookType = createAction('[Hooks] Select hook type', props<{ hookType: HookTypesEnum }>());

export const getHook = createAction('[Hooks] Get hook', props<{ hookId: number }>());
export const getHookResponse = createAction('[Hooks] Get hook response', props<{ hook: Hook }>());

export const getHookTypes = createAction('[Hooks] Get hook types');
export const getHookTypesResponse = createAction('[Hooks] Get hook types response', props<{ hookTypes: HookType[] }>());

export const getHookEvents = createAction('[Hooks] Get hook events');
export const getHookEventsResponse = createAction(
  '[Hooks] Get hook events response',
  props<{ hookEvents: HookEvent[] }>(),
);

export const getHooksList = createAction('[Hooks] Get hooks list', props<{ params: ListParams }>());
export const getHooksListResponse = createAction('[Hooks] Get hooks list response', props<{ hooks: Hook[] }>());
export const getHooksListError = createAction('[Hooks] Get hooks list error', props<{ errors: ValidationError[] }>());

export const loadMoreHooksList = createAction('[Hooks] Load more hooks list', props<{ params: ListParams }>());
export const loadMoreHooksListResponse = createAction(
  '[Hooks] Load more hooks list response',
  props<{ hooks: Hook[] }>(),
);

export const saveHook = createAction('[Hooks] Save hook', props<{ hook: Partial<Hook> }>());
export const saveHookResponse = createAction('[Hooks] Save hook response', props<{ data: Hook }>());
export const saveHookError = createAction('[Hooks] Save hook error', props<{ errors: ValidationError[] }>());

export const updateHook = createAction('[Hooks] Update hook', props<{ hook: Partial<Hook> }>());
export const updateHookResponse = createAction('[Hooks] Update hook response', props<{ data: Hook }>());
export const updateHookError = createAction('[Hooks] Update hook error', props<{ errors: ValidationError[] }>());

export const removeHook = createAction('[Hooks] Remove hook', props<{ hookId: number }>());
export const removeHookItem = createAction('[Hooks] Remove hook item', props<{ data: Hook }>());
export const removeHookResponse = createAction('[Hooks] Remove hook response', props<{ data: Hook }>());
export const removeHookError = createAction('[Hooks] Remove hook error', props<{ errors: ValidationError[] }>());

export const pingHook = createAction('[Hooks] Ping hook', props<{ hookId: number }>());
export const pingHookResponse = createAction('[Hooks] Ping hook response', props<{ hook: Hook }>());
export const pingHookError = createAction('[Hooks] Ping hook error', props<{ error: string; hookId: number }>());
export const pingHookClear = createAction('[Hooks] Ping hook clear', props<{ hookId: number }>());
