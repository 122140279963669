import { createAction, props } from '@ngrx/store';
import { ComponentTypeItem } from '../../constants/component_types';
import { AllComponentData } from '../package.models';

export const setComponent = createAction(
  '[PackageDesigner] Set component',
  props<{ rawComponent: AllComponentData; component: ComponentTypeItem }>(),
);

export const updateComponent = createAction(
  '[PackageDesigner] Update component',
  props<{ component: Partial<ComponentTypeItem> }>(),
);

export const updateRawComponent = createAction(
  '[PackageDesigner] Update raw component',
  props<{ rawComponent: Partial<AllComponentData> }>(),
);

export const setComponentValidity = createAction(
  '[PackageDesigner] Set component validity',
  props<{ isComponentFormValid: boolean }>(),
);

export const setComponentNameFormValidity = createAction(
  '[PackageDesigner] Set component name form validity',
  props<{ isComponentNameFormValid: boolean }>(),
);
