import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { Schema } from '../../../package.models';

@Component({
  selector: 'sort-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['name', 'order_type']"
    >
      <ng-template templateName="name" let-item>
        <xp-field-picker
          [value]="item.record.name"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [index]="item.index"
          propName="name"
          (fieldChange)="onFieldChange($event, item.record, 'name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="name-header" let-item>
        {{ 'fields-collection.headers.field-picker' | translate }}
      </ng-template>

      <ng-template templateName="order_type" let-item>
        <xp-sort-direction-picker
          [value]="item.record.order_type"
          [index]="item.index"
          (fieldChange)="onFieldChange($event, item.record, 'order_type')"
          class="fields-collection-editor"
        ></xp-sort-direction-picker>
      </ng-template>
      <ng-template templateName="order_type-header" let-item>
        {{ 'fields-collection.headers.sort-direction-picker' | translate }}
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class SortCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: CollectionSettings;
  @Input() parentSchemas: Schema[];
  @Input() fieldsName: string;
  @Input() isVisible: boolean;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      hideSearch: true,
      itemsPerPage: 10,
      emptyRecord: { order_type: '', name: '', FC_pristine: true },
      parentSchemas: this.parentSchemas,
      addRecord: this.addRecord.bind(this),
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  addRecord(index: number) {
    const newRecord = { ...this.collectionSettings.emptyRecord, id: uuidv4() };
    if (this.recordsCopy[index - 1]) {
      newRecord.order_type = this.recordsCopy[index - 1].order_type;
    }
    this.recordsCopy.splice(index, 0, newRecord);
    this.recordsCopy = this.recordsCopy.slice();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible && changes.isVisible.currentValue && this.recordsCopy) {
      this.onRecordChange({ records: this.recordsCopy.length ? this.recordsCopy : this.records });
    }
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { order_type: record.order_type, name: record.name };
    });
  }

  onRecordChange({ records }) {
    const fieldsName = this.fieldsName || 'fields';
    if (this.isVisible) {
      this.recordsCopy = records;
      const fields = records.map(({ name, order_type }) => ({ name, order_type }));
      this.store.dispatch(
        updateComponent({
          component: {
            [fieldsName]: fields,
          },
        }),
      );
      this.recordsChange.emit(fields);
    }
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
