import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';

const HEADERS_PLACEHOLDERS = [
  { name: 'Authorization', value: 'Bearer <YOUR-TOKEN>' },
  { name: 'Content-Type', value: 'application/json' },
  { name: 'Accept', value: 'application/json' },
];

@Component({
  selector: 'rest-api-headers-collection',
  template: `
    <div class="col-sm-12">
      <div class="form-group">
        <label>{{ 'headers-editor.title' | translate }}</label>
        <div>
          <button type="button" class="btn btn-default" (click)="toggleHeaders()" *ngIf="!recordsCopy.length">
            Add
          </button>
        </div>
        <br />
        <div *ngIf="recordsCopy.length">
          <xp-fields-collection
            [records]="recordsCopy"
            [collectionSettings]="collectionSettings"
            [isValid]="valid"
            (validityChange)="onFieldsValidityChange($event)"
            (recordsChange)="onRecordChange($event)"
            [columns]="['name', 'value']"
            duplicationValidationProp="name"
            duplicationValidationPropName="Key"
          >
            <ng-template templateName="name" let-item>
              <xp-rest-api-header-key
                [value]="item.record.name"
                [index]="item.index"
                [focusedProp]="item.focusedProp"
                [isDuplicateError]="item.record.isDuplicateError"
                [placeholder]="HEADERS_PLACEHOLDERS[item.index % 3]?.name"
                propName="name"
                (fieldChange)="onFieldChange($event, item.record, 'name')"
                class="fields-collection-editor"
              ></xp-rest-api-header-key>
            </ng-template>
            <ng-template templateName="name-header" let-item>
              {{ 'headers-editor.key' | translate }}
            </ng-template>

            <ng-template templateName="value" let-item>
              <xp-rest-api-header-value
                [value]="item.record.value"
                [index]="item.index"
                [placeholder]="HEADERS_PLACEHOLDERS[item.index % 3]?.value"
                [focusedProp]="item.focusedProp"
                propName="value"
                (fieldChange)="onFieldChange($event, item.record, 'value')"
                class="fields-collection-editor"
              ></xp-rest-api-header-value>
            </ng-template>
            <ng-template templateName="value-header" let-item>
              {{ 'headers-editor.value' | translate }}
            </ng-template>
          </xp-fields-collection>
        </div>
      </div>
    </div>
  `,
  providers: [],
})
export class RestApiHeadersCollectionComponent implements OnInit {
  @Input() records: DesignerSchemaFieldI[] = [];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: CollectionSettings;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  HEADERS_PLACEHOLDERS = HEADERS_PLACEHOLDERS;
  recordsCopy: DesignerSchemaFieldI[] = [];

  ngOnInit() {
    this.recordsCopy = [...(this.records || [])].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      hideIndex: true,
      hideSearch: true,
      allowEmptyCollection: true,
      itemsPerPage: 10,
      emptyRecord: {
        name: '',
        value: '',
        FC_pristine: true,
      },
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  toggleHeaders() {
    if (!this.recordsCopy.length) {
      this.recordsCopy = [{ name: '', value: '', FC_pristine: true }];
    }
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const headers = records.map(({ name, value }) => ({ name, value }));

    this.recordsChange.emit(headers);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
