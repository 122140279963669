import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import { openExpressionEditor } from '../../../package-designer/store/package-designer.actions';
import {
  selectExpressionData,
  selectExpressionEditorSaveCode,
  selectIsExpressionOpenFlag,
} from '../../../package-designer/store/package-designer.selectors';

@Component({
  selector: 'expression-editor-icon',
  template: ` <i (click)="iconClick()" class="fa fa-edit expression-editor-icon"></i> `,
})
export class ExpressionEditorIconComponent implements OnDestroy {
  @Input() readonlyValue = false;
  @Input() value = '';
  @Output() valueChange = new EventEmitter();
  @Input() type = '';
  @Input() index: number;
  @Input() id: string;

  isExpressionEditorOpen: boolean;
  expressionEditorCloseSubscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.expressionEditorCloseSubscription = this.store
      .select(selectIsExpressionOpenFlag)
      .pipe(
        filter((isOpen) => !isOpen),
        withLatestFrom(this.store.select(selectExpressionEditorSaveCode)),
        withLatestFrom(this.store.select(selectExpressionData)),
      )
      .subscribe(([[, code], data]) => {
        if (code && this.isExpressionEditorOpen && data.index === this.index) {
          this.valueChange.emit(code);
          this.isExpressionEditorOpen = false;
        }
      });
  }

  iconClick() {
    this.isExpressionEditorOpen = true;
    this.store.dispatch(
      openExpressionEditor({
        code: String(this.value),
        index: this.index,
        category: this.type === 'user_variables' ? 'User' : 'System',
      }),
    );
  }

  ngOnDestroy() {
    if (this.expressionEditorCloseSubscription) {
      this.expressionEditorCloseSubscription.unsubscribe();
    }
  }
}
