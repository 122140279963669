import { createAction, props } from '@ngrx/store';
import { Account } from '../account.models';
import { User } from '../user.models';

export const loginSuccess = createAction('[Account] Login success');
export const logout = createAction('[Account] Logout', props<{ shouldRedirect: boolean }>());

export const updateAccountInfo = createAction('[Account] Update account info', props<{ account: Account }>());
export const updateUserInfo = createAction('[Account] Update user info', props<{ user: User }>());
export const updateAccountsList = createAction('[Account] Update accounts list', props<{ accounts: Account[] }>());
export const clearAccountsList = createAction('[Account] Clear accounts list');
export const removeAccountFromList = createAction('[Account] Remove account from list', props<{ account: Account }>());

export const closeAllModals = createAction('[Account] Close all modals');
