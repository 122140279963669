import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { PermissionsService } from '../../common/services/permissions.service';
import { Cluster, ClusterStatuses, ClusterTypes, Creator } from '../clusters.models';
import { NotifyService } from '../../common/services/notify.service';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { removeCluster } from '../store/clusters.actions';
import { Schedule } from '../../schedules/schedule.models';

@Component({
  selector: 'cluster-list-item',
  template: `
    <div class="generic-list-item slider-animation clusters" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="cluster-list-square" id="cluster-{{ item.id }}">
        <div class="cluster-list-item-header">
          <strong
            [matTooltip]="item.name"
            matTooltipPosition="right"
            matTooltipClass="right"
            [innerHTML]="item.name | xpHighLight: searchValue"
          ></strong>
          <small
            [innerHTML]="
              'cluster.generic-list.item.labels.running_jobs'
                | translate: { count: item.running_jobs_count, queued: item.queued_jobs_count || 0 } : 'messageformat'
            "
          ></small>
          <i [ngClass]="item.status" [matTooltip]="item.status" matTooltipPosition="below" matTooltipClass="below"></i>
        </div>
        <div class="cluster-list-item-body">
          <button
            (click)="editCluster(item)"
            *ngxPermissionsOnly="'updateCluster'"
            [hidden]="item.status !== 'available' && item.status !== 'idle'"
            [matTooltip]="'cluster.generic-list.item.actions.edit' | translate"
            matTooltipPosition="above"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="19px" viewBox="0 0 18 19">
              <path
                class="designer-component-edit-text"
                d="M6 10H11M8.5 1.3266C8.72101 1.11748 9.02077 1 9.33333 1C9.4881 1 9.64135 1.02884 9.78433 1.08488C9.92731 1.14092 10.0572 1.22306 10.1667 1.3266C10.2761 1.43015 10.3629 1.55308 10.4221 1.68837C10.4814 1.82366 10.5118 1.96866 10.5118 2.11509C10.5118 2.26153 10.4814 2.40653 10.4221 2.54182C10.3629 2.67711 10.2761 2.80004 10.1667 2.90358L3.22222 9.47434L1 10L1.55556 7.89736L8.5 1.3266Z"
                stroke="#BDC5CF"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                fill="transparent"
              />
            </svg>
          </button>
          <strong *ngIf="item.type === ClusterTypes.production" [ngClass]="item.type">{{ item.nodes }}</strong>
          <small *ngIf="item.type === ClusterTypes.production" class="item.type">{{
            'cluster.generic-list.item.labels.production' | translate
          }}</small>
          <strong *ngIf="item.type === ClusterTypes.sandbox" [ngClass]="item.type">{{
            'cluster.generic-list.item.labels.sandbox' | translate
          }}</strong>
        </div>
        <div class="cluster-list-item-footer">
          <button
            class="cluster-list-item-terminate"
            *ngxPermissionsOnly="'updateCluster'"
            (click)="terminateCluster(item)"
            [ngClass]="item.status"
            [matTooltip]="'cluster.generic-list.item.tooltips.terminate' | translate"
            matTooltipPosition="above"
          >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="19px" viewBox="0 0 18 19">
              <path
                style="fill: none; stroke: #e74b3a; stroke-width: 2; stroke-linecap: round; stroke-miterlimit: 10;"
                d="M13.5,3.4
                c0.4,0.3,0.8,0.6,1.2,1c0.4,0.4,0.7,0.8,1,1.2c0.3,0.4,0.5,0.9,0.7,1.4c0.2,0.5,0.4,1,0.5,1.5S17,9.4,17,10s-0.1,1.1-0.2,1.6
                s-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.3,0.4-0.6,0.8-1,1.2c-0.4,0.4-0.8,0.7-1.2,1c-0.4,0.3-0.9,0.5-1.4,0.7
                c-0.5,0.2-1,0.4-1.5,0.5S9.6,18,9,18s-1.1-0.1-1.6-0.2s-1-0.3-1.5-0.5c-0.5-0.2-0.9-0.5-1.4-0.7c-0.4-0.3-0.8-0.6-1.2-1
                s-0.7-0.8-1-1.2c-0.3-0.4-0.5-0.9-0.7-1.4c-0.2-0.5-0.4-1-0.5-1.5C1.1,11.1,1,10.6,1,10s0.1-1.1,0.2-1.6c0.1-0.5,0.3-1,0.5-1.5
                S2.1,6,2.4,5.5s0.6-0.8,1-1.2s0.8-0.7,1.2-1"
              />
              <path
                class="toggle"
                style="fill: #e74b3a;"
                d="M9,8L9,8C8.4,8,8,7.6,8,7V1c0-0.6,0.4-1,1-1h0c0.6,0,1,0.4,1,1v6C10,7.6,9.6,8,9,8z"
              />
            </svg>
          </button>
          <button
            *ngxPermissionsOnly="'createJob'"
            class="cluster-list-item-run"
            (click)="runJob(item)"
            [ngClass]="item.status"
            [matTooltip]="'cluster.generic-list.item.tooltips.run_job' | translate"
            matTooltipPosition="above"
          >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15.7px" height="16px" viewBox="0 0 15.7 16">
              <g>
                <path
                  style="fill:  #177af0;"
                  d="M2.9,15.7C1.5,16.5,0,15.5,0,13.9V8V2.1c0-1.6,1.5-2.6,2.9-1.8l12.1,6.8c0.7,0.4,0.8,1.4,0.1,1.8
                        L2.9,15.7z"
                />
              </g>
            </svg>
          </button>
        </div>
        <div class="cluster-list-item-info">
          <small
            >{{ 'cluster.generic-list.item.labels.created_at' | translate }}
            <span [matTooltip]="item.created_at | xpDateUTC" matTooltipPosition="above">{{
              item.created_at | xpPrettyDate
            }}</span>
            {{ 'cluster.generic-list.item.labels.by' | translate }}
            <a *ngIf="item.creator.type === 'User'" (click)="goToCreator(item.creator)" class="owner">{{
              item.creator.display_name | xpLengthCheck: 32
            }}</a>
            <a *ngIf="item.creator.type === 'Schedule'" (click)="editSchedule(item.creator)" class="owner">
              {{ item.creator.display_name | xpLengthCheck: 32 }}</a
            >
            <span *ngIf="item.terminate_on_idle"
              >{{ 'cluster.generic-list.item.labels.time_to_idle' | translate }}{{ getTimeToIddle(item.time_to_idle)
              }}{{ 'cluster.generic-list.item.labels.time_to_idle_end' | translate }}</span
            >.</small
          >
        </div>
      </div>
    </div>
  `,
})
export class ClusterListItemComponent {
  @Input() item: Cluster;
  @Input() searchValue: string;
  @Output() edit = new EventEmitter<Cluster>();
  @Output() createJob = new EventEmitter<Cluster>();

  ClusterTypes = ClusterTypes;

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private notify: NotifyService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  editCluster(item: Cluster) {
    this.edit.emit(item);
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  goToCreator(creator: Creator): void {
    this.router.navigate([`/${this.route.parent.snapshot.params.account_id}/settings/account/members/${creator.id}`]);
  }

  editSchedule(schedule: Schedule): void {
    this.router.navigate([`/${this.route.parent.snapshot.params.account_id}/schedules/${schedule.id}/edit`]);
  }

  // eslint-disable-next-line class-methods-use-this
  getTimeToIddle(timeToIdle: number) {
    return moment.duration(timeToIdle, 'seconds').humanize();
  }

  runJob(cluster: Cluster) {
    this.createJob.emit(cluster);
  }

  terminateCluster(item: Cluster): void {
    if (
      [
        ClusterStatuses.pending_terminate,
        ClusterStatuses.terminated,
        ClusterStatuses.pending,
        ClusterStatuses.creating,
      ].includes(item.status)
    )
      return;

    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('cluster.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('cluster.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('cluster.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('cluster.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.cluster,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeCluster({ clusterId: item.id }));
      }
    });
  }
}
