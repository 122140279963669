import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  getKey,
  getKeyResponse,
  getKeysList,
  getKeysListError,
  getKeysListResponse,
  loadMoreKeysList,
  loadMoreKeysListResponse,
  removeKey,
  removeKeyError,
  removeKeyItem,
  removeKeyResponse,
  saveKey,
  saveKeyError,
  saveKeyResponse,
} from './keys.actions';
import { KeysResource } from '../../resources/keys.resource';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../../constants/animation-constants';
import { NotifyService } from '../../../common/services/notify.service';
import { getErrorFromResponse } from '../../../common/helper/response.helpers';

@Injectable()
export class KeysEffects {
  getKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKey),
      switchMap(({ keyId }) =>
        this.keysResource.get(keyId).pipe(
          map((key) => getKeyResponse({ key })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getKeysListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKeysList),
      switchMap(({ params }) =>
        this.keysResource.query(params).pipe(
          map((keys) => getKeysListResponse({ keys })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getKeysListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreKeysList),
      switchMap(({ params }) =>
        this.keysResource.query(params).pipe(
          map((keys) => loadMoreKeysListResponse({ keys })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getKeysListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveKey),
      switchMap(({ key }) =>
        this.keysResource.save(key).pipe(
          map((keyResponse) => saveKeyResponse({ data: keyResponse })),
          catchError((response) => of(saveKeyError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeKey),
      switchMap(({ keyId }) =>
        this.keysResource.remove(keyId).pipe(
          map((keyResponse) => removeKeyItem({ data: keyResponse })),
          catchError((response) => of(removeKeyError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeKeyResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeKeyItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeKeyResponse({ data })),
    ),
  );

  constructor(
    private actions$: Actions,
    private keysResource: KeysResource,
    private translate: TranslateService,
    private notify: NotifyService,
  ) {}
}
