import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import './resources/clusters.resource';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '../common/common.module';
import { clustersReducer } from './store/clusters.reducer';
import { ClustersEffects } from './store/clusters.effects';
import { ClusterListItemComponent } from './components/cluster-list-item.component';
import { ClustersListComponent } from './components/clusters-list.component';
import { ClustersComponent } from './clusters.component';

@NgModule({
  declarations: [ClustersListComponent, ClusterListItemComponent, ClustersComponent],
  exports: [],
  imports: [
    BrowserModule,
    FormsModule,
    StoreModule.forFeature('clusters', clustersReducer),
    EffectsModule.forFeature([ClustersEffects]),
    CommonModule,
    TranslateModule,
    NgxPermissionsModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ClustersModule {}
