import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from '../../../store';
import { ComponentTypeItem } from '../../../constants/component_types';
import { selectFixedComponentById } from '../../store/package-designer.selectors';
import { DESIGNER_SETTINGS } from '../../../constants/designer_settings';

@Directive({
  selector: '[designer-map-component]',
})
export class DesignerMapComponentDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() componentId: string;
  @Input() scale: number;

  panelScale = DESIGNER_SETTINGS.PANEL_SCALE;
  componentSubscription: Subscription;
  component: Partial<ComponentTypeItem> = {};

  constructor(private store: Store<AppState>, private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.componentSubscription = this.store
      .select(selectFixedComponentById(this.componentId))
      .pipe(filter(Boolean))
      .subscribe((component: ComponentTypeItem) => {
        this.component = component;
        this.updateAttrs();
      });

    this.renderer.setAttribute(this.elementRef.nativeElement, 'rx', '2');

    this.renderer.setAttribute(this.elementRef.nativeElement, 'ry', '2');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scale?.currentValue !== changes.scale?.previousValue && this.component?.xy) {
      if (this.elementRef.nativeElement && this.component && this.scale !== Infinity) {
        this.updateAttrs();
      }
    }
  }

  updateAttrs() {
    const isNote = this.componentId.includes('note');

    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'x',
      String(this.component.xy[0] * this.panelScale * this.scale),
    );
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'y',
      String(this.component.xy[1] * this.panelScale * this.scale),
    );
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'width',
      String((isNote ? 224 : 140) * this.panelScale * this.scale),
    );
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'height',
      String((isNote ? 168 : 84) * this.panelScale * this.scale),
    );

    if (this.component.isSelected) {
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
    }
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }
}
