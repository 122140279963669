import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { QueryParams } from '../../models/http.models';
import { Account } from '../account.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class AccountResource {
  private apiRoot: string = `${environment.API_URL}/accounts`;

  constructor(private http: HttpClient) {}

  get(account_id: string, params?: ListParams): Observable<Account> {
    return this.http.get<Account>(`${this.apiRoot}/${account_id}`, { params: params as QueryParams });
  }

  save(body: Account, params?: ListParams): Observable<Account> {
    return this.http.post<Account>(`${this.apiRoot}`, body, { params: params as QueryParams });
  }

  update(account_id: string, body: Account, params?: ListParams): Observable<Account> {
    return this.http.put<Account>(`${this.apiRoot}/${account_id}`, body, { params: params as QueryParams });
  }

  remove(account_id: string, params?: ListParams): Observable<any> {
    return this.http.delete<Account>(`${this.apiRoot}/${account_id}`, { params: params as QueryParams });
  }

  query(params?: ListParams): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.apiRoot}`, { params: params as QueryParams });
  }

  search(params?: ListParams): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.apiRoot}/search`, { params: params as QueryParams });
  }
}
