import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import './resources/schedules.resource';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '../common/common.module';
import { SchedulesEffects } from './store/schedules.effects';
import { schedulesReducer } from './store/schedules.reducer';
import { SchedulesListComponent } from './components/schedules-list.component';
import { ScheduleListItemComponent } from './components/schedule-list-item.component';
import { ScheduleFormComponent } from './components/schedule-form.component';
import { ClustersModule } from '../clusters/clusters.module';
import { PackagePickerComponent } from './components/package-picker.component';
import { PackagePickerFormComponent } from './components/package-picker-form.component';
import { JobsModule } from '../jobs/jobs.module';
import { SchedulesComponent } from './schedules.component';

@NgModule({
  declarations: [
    SchedulesListComponent,
    ScheduleListItemComponent,
    ScheduleFormComponent,
    PackagePickerComponent,
    PackagePickerFormComponent,
    SchedulesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    StoreModule.forFeature('schedules', schedulesReducer),
    EffectsModule.forFeature([SchedulesEffects]),
    CommonModule,
    TranslateModule,
    NgxPermissionsModule,
    MatTooltipModule,
    MatMenuModule,
    MatSlideToggleModule,
    ClustersModule,
    JobsModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class SchedulesModule {}
