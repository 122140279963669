// eslint-disable-next-line max-classes-per-file
import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { difference } from 'lodash';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { selectFormValidationData } from '../validators/store/validators.selectors';
import { AppState } from '../../store';
import { NotifyService } from '../services/notify.service';
import { ValidationError } from '../../config/validation-error.model';
import { setFormFieldMessage, setFormFieldValidity } from '../validators/store/validators.actions';
import { transformErrorFieldName } from '../helper/transform-error-field-name.helper';

export class BaseFormInterface {
  protected store: Store<AppState>;
  protected notify: NotifyService;
  protected translate: TranslateService;
  formName: string;
  form: NgForm;
  successMessageText: string;
  errors$?: Observable<ValidationError[]>;
  errorTexts?: string[];
}

@Injectable()
export class BaseForm extends BaseFormInterface implements OnInit, OnDestroy {
  errorsSubscriber: Subscription;
  validationSubscriber: Subscription;
  formValidationChange?: EventEmitter<boolean>;

  ngOnInit() {
    let previousKeys = [];

    this.validationSubscriber = this.store.pipe(selectFormValidationData(this.formName)).subscribe((formData) => {
      if (!this.form) {
        return;
      }

      Object.keys(formData).forEach((key) => {
        const formField = this.form.controls[key];

        if (formField) {
          formField.setErrors(formData[key] ? null : { incorrect: !formData[key] });

          if (formData[key]) {
            formField.markAsDirty();
            formField.markAsTouched();
          }
        }
      });

      difference(previousKeys, Object.keys(formData)).forEach((key) => {
        const formField = this.form.controls[key];

        if (formField) {
          formField.setErrors(null);
        }
      });

      previousKeys = Object.keys(formData);

      if (this.formValidationChange) {
        const isFormValid = Object.keys(formData).every((key) => formData[key]);

        this.formValidationChange.emit(isFormValid);
      }
    });

    if (this.errors$) {
      this.errorsSubscriber = this.errors$.subscribe((errors) => {
        const fieldMessageErrors = (errors || []).filter((item) => item.field && item.message);
        const httpErrors = (errors || []).filter((item) => item.error && item.status);

        fieldMessageErrors.forEach(({ field, message }) => {
          if (field) {
            this.store.dispatch(setFormFieldValidity({ formName: this.formName, isValid: false, fieldName: field }));
            this.store.dispatch(setFormFieldMessage({ formName: this.formName, message, fieldName: field }));
          }
        });

        if (fieldMessageErrors.length) {
          this.errorTexts = (errors || []).map(({ field, message }) => {
            if (field === 'base') {
              return message;
            }
            return `<strong>${transformErrorFieldName(field)}</strong> ${message}`;
          });
          setTimeout(() => {
            this.errorTexts = [];
          }, 7000);
        }

        if (httpErrors.length) {
          this.errorTexts = (errors || []).map(({ error, status }) => {
            return `${status}: ${error}`;
          });
          setTimeout(() => {
            this.errorTexts = [];
          }, 7000);
        }
      });
    }
  }

  handleSuccess(): void {
    this.notify.success(this.translate.instant(this.successMessageText), '');
  }

  ngOnDestroy() {
    if (this.errorsSubscriber) {
      this.errorsSubscriber.unsubscribe();
    }

    if (this.validationSubscriber) {
      this.validationSubscriber.unsubscribe();
    }
  }
}
