import { SelectOption } from '../common/components/forms/xp-select.component';

export const FACEBOOK_API_VERSIONS: SelectOption[] = [
  {
    text: 'v20.0',
    value: 'v20.0',
  },
  {
    text: 'v19.0',
    value: 'v19.0',
    deprecation_date: '2025-02-04T00:00:00+02:00',
    sunset_date: '2025-02-04T00:00:00+02:00',
  },
  {
    text: 'v18.0',
    value: 'v18.0',
    deprecation_date: '2024-08-13T00:00:00+02:00',
    sunset_date: '2024-08-13T00:00:00+02:00',
  },
  {
    text: 'v17.0',
    value: 'v17.0',
    deprecation_date: '2024-05-14T00:00:00+02:00',
    sunset_date: '2024-05-14T00:00:00+02:00',
  },
  {
    text: 'v16.0',
    value: 'v16.0',
    deprecation_date: '2024-02-06T00:00:00+02:00',
    sunset_date: '2024-02-06T00:00:00+02:00',
  },
  {
    text: 'v15.0',
    value: 'v15.0',
    deprecation_date: '2023-08-15T00:00:00+02:00',
    sunset_date: '2023-08-15T00:00:00+02:00',
  },
  {
    text: 'v14.0',
    value: 'v14.0',
    deprecation_date: '2023-08-15T00:00:00+02:00',
    sunset_date: '2023-08-15T00:00:00+02:00',
  },
  {
    text: 'v13.0',
    value: 'v13.0',
    deprecation_date: '2023-01-25T00:00:00+02:00',
    sunset_date: '2023-01-25T00:00:00+02:00',
  },
  {
    text: 'v12.0',
    value: 'v12.0',
    deprecation_date: '2022-08-09T00:00:00+02:00',
    sunset_date: '2022-08-09T00:00:00+02:00',
  },
  {
    text: 'v11.0',
    value: 'v11.0',
    deprecation_date: '2022-02-23T00:00:00+02:00',
    sunset_date: '2022-02-23T00:00:00+02:00',
  },
  {
    text: 'v10.0',
    value: 'v10.0',
    deprecation_date: '2021-10-04T00:00:00+02:00',
    sunset_date: '2021-10-04T00:00:00+02:00',
  },
  {
    text: 'v9.0',
    value: 'v9.0',
    deprecation_date: '2021-08-25T00:00:00+02:00',
    sunset_date: '2021-08-25T00:00:00+02:00',
  },
  {
    text: 'v8.0',
    value: 'v8.0',
    deprecation_date: '2021-05-04T00:00:00+02:00',
    sunset_date: '2021-05-04T00:00:00+02:00',
  },
  {
    text: 'v7.0',
    value: 'v7.0',
    deprecation_date: '2021-03-03T00:00:00+02:00',
    sunset_date: '2021-03-03T00:00:00+02:00',
  },
  {
    text: 'v6.0',
    value: 'v6.0',
    deprecation_date: '2021-02-08T00:00:00+02:00',
    sunset_date: '2021-02-08T00:00:00+02:00',
  },
  {
    text: 'v5.0',
    value: 'v5.0',
    deprecation_date: '2020-05-26T00:00:00+02:00',
    sunset_date: '2020-05-26T00:00:00+02:00',
  },
  {
    text: 'v4.0',
    value: 'v4.0',
    deprecation_date: '2020-04-07T00:00:00+02:00',
    sunset_date: '2020-04-07T00:00:00+02:00',
  },
  {
    text: 'v3.3',
    value: 'v3.3',
    deprecation_date: '2020-01-12T00:00:00+02:00',
    sunset_date: '2020-01-12T00:00:00+02:00',
  },
  {
    text: 'v3.2',
    value: 'v3.2',
    deprecation_date: '2019-07-30T00:00:00+02:00',
    sunset_date: '2019-07-30T00:00:00+02:00',
  },
  {
    text: 'v3.1',
    value: 'v3.1',
    deprecation_date: '2019-04-25T00:00:00+02:00',
    sunset_date: '2019-05-14T00:00:00+02:00',
  },
];
