import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { ADVANCED_ROLES, Member, STANDARD_ROLES } from '../../members.models';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { saveMember } from '../../store/members/members.actions';
import { selectMembersErrors, selectMembersIsSubmittingFlag } from '../../store/members/members.selectors';

@Component({
  selector: 'member-form',
  template: `
    <div class="member-form">
      <div class="member-form-body">
        <xp-form-validation type="User" [name]="formName">
          <form id="memberForm" name="memberForm" #form="ngForm" class="modal-form-container">
            <div class="form-group">
              <xp-form-group>
                <label for="email">{{ 'member.form.labels.email' | translate }}</label>
                <xp-input
                  id="email"
                  type="email"
                  name="email"
                  [(ngModel)]="item.email"
                  class="form-control"
                  placeholder="{{ 'member.form.placeholders.email' | translate }}"
                ></xp-input>
              </xp-form-group>
            </div>
            <!--            <member-edit-roles [roles]="[]" (rolesChange)="onRolesChange($event)"></member-edit-roles>-->
            <div class="form-group">
              <label for="role">{{ 'member.form.labels.role' | translate }}</label>
              <role-picker
                name="role"
                class="role-picker"
                [value]="item.role || STANDARD_ROLES.READER"
                (onChange)="roleUpdate($event)"
              ></role-picker>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="member-form-footer modal-footer">
        <div class="modal-title-container">
          <h3 class="modal-title">{{ 'member.generic-object.title' | translate }}</h3>
        </div>

        <xp-submit-button
          (click)="createMember(item)"
          classNames="btn-lg btn-success pull-right"
          [createText]="'member.form.buttons.create' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
          *ngxPermissionsOnly="'updateProfile'"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class MemberFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  item: Partial<Member> = {
    role: STANDARD_ROLES.READER,
  };
  @ViewChild('form') form: NgForm;
  formName = 'memberForm';
  successMessageText = 'member.form.success_message';
  isSubmitting$ = this.store.select(selectMembersIsSubmittingFlag);
  errors$ = this.store.select(selectMembersErrors);
  errorTexts = [];
  advanced_roles: ADVANCED_ROLES[] = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  createMember(member: Partial<Member>) {
    this.store.dispatch(saveMember({ member: { ...member, advanced_roles: this.advanced_roles } }));

    this.router.navigate(['./'], { relativeTo: this.route });
  }

  roleUpdate(role: STANDARD_ROLES) {
    this.item.role = role;
  }

  onRolesChange(roles: ADVANCED_ROLES[]) {
    this.advanced_roles = roles;
  }

  protected readonly STANDARD_ROLES = STANDARD_ROLES;
}
