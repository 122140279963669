import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'xp-ping',
  template: `
    <div class="alert-box-middle">
      <div class="alert-box">
        <div class="alert-box-container">
          <h3 class="lead">Ping</h3>
        </div>
      </div>
    </div>
  `,
})
export class PingComponent {}
