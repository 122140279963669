import { Component, Edge, ExtendedEdge, Position } from '../package.models';
import { ComponentsPositions } from '../store/state.model';
import { findComponentById, getDataFromComponent } from './components.helpers';
import { DESIGNER_SETTINGS } from '../../constants/designer_settings';

function fixSourcePosition(position: Position): Position {
  return {
    x: position.x + 28 * 2.5,
    y: position.y + (28 * 3 + 7),
  };
}

function fixTargetPosition(position: Position): Position {
  return {
    x: position.x + 28 * 2.5,
    y: position.y - 7,
  };
}

export function updateEdgesPositions(components: Component[], componentTempPositions: ComponentsPositions = {}) {
  return (edge: Edge): ExtendedEdge => {
    const sourceComponentRaw = components.find(findComponentById(edge.source));
    if (!sourceComponentRaw) {
      return null;
    }

    const sourceComponent = getDataFromComponent(sourceComponentRaw);
    const targetRawComponent = components.find(findComponentById(edge.target));
    if (!targetRawComponent) {
      return null;
    }
    const targetComponent = getDataFromComponent(targetRawComponent);

    let sourcePosition: Position = componentTempPositions[sourceComponent.id]
      ? {
          x: componentTempPositions[sourceComponent.id][0],
          y: componentTempPositions[sourceComponent.id][1],
        }
      : {
          x: sourceComponent.xy[0],
          y: sourceComponent.xy[1],
        };
    sourcePosition = fixSourcePosition(sourcePosition);

    let targetPosition: Position = componentTempPositions[targetComponent.id]
      ? {
          x: componentTempPositions[targetComponent.id][0],
          y: componentTempPositions[targetComponent.id][1],
        }
      : {
          x: targetComponent.xy[0],
          y: targetComponent.xy[1],
        };
    targetPosition = fixTargetPosition(targetPosition);

    let toggleX;
    let toggleY;
    const sourceX = 0;
    const sourceY = (targetPosition.y - sourcePosition.y) / 2;

    const targetX = targetPosition.x - sourcePosition.x;
    const targetY = (targetPosition.y - sourcePosition.y) / 2;

    toggleX = (targetPosition.x - sourcePosition.x) / 2;
    toggleY = (targetPosition.y - sourcePosition.y) / 2;

    toggleX -= 11;
    toggleY -= 11;

    const sourceCurvePosition: Position = {
      x: sourceX,
      y: sourceY,
    };

    const targetCurvePosition: Position = {
      x: targetX,
      y: targetY,
    };

    const togglePosition: Position = {
      x: toggleX,
      y: toggleY,
    };

    return {
      ...edge,
      sourcePosition,
      sourceCurvePosition,
      targetPosition,
      targetCurvePosition,
      togglePosition,
    };
  };
}
