import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { flatten } from 'lodash';
import { CubeComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { CollectionSettings } from '../fields-collection/fields-collection.models';
import { FunctionPickerType } from '../../../constants/function_picker';

@Component({
  selector: 'cube-editor',
  template: `
    <div class="cube-editor">
      <xp-form-validation type="Xplenty::JobAuthoring::Components::CubeComponent">
        <form name="componentForm" novalidate #form="ngForm">
          <div class="row">
            <div class="col-md-12">
              <label><strong>Grouping combinations:</strong></label>
              <cube-rollup-collection
                [records]="rawComponent.grouped_fields"
                (recordsChange)="onCubeRecordsChange($event)"
                [collectionSettings]="cubeCollectionSettings"
                [parentSchemas]="parentSchemas"
                fieldsName="grouped_fields"
                (validityChange)="onCubeValidityChange($event)"
              ></cube-rollup-collection>
              <br />
              <label><strong>Aggregate functions:</strong></label>
              <aggregate-collection
                [records]="rawComponent.aggregated_fields"
                (recordsChange)="onAggregatedRecordsChange($event)"
                [collectionSettings]="aggregateCollectionSettings"
                [parentSchemas]="parentSchemas"
                [functionType]="functionPickerTypes.cubeRollup"
                fieldsName="aggregated_fields"
                (validityChange)="onAggregateValidityChange($event)"
              ></aggregate-collection>
            </div>
          </div>
        </form>
      </xp-form-validation>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class CubeEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: CubeComponentData;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  functionPickerTypes = FunctionPickerType;

  aggregateCollectionValid = false;
  cubeCollectionValid = false;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  cubeCollectionSettings: Partial<CollectionSettings> = {
    outsideSourceValidation: {
      unique: {
        validation: [],
        message: this.translate.instant('aggregate-editor.form.errors.duplicate_fields'),
      },
    },
  };

  aggregateCollectionSettings: Partial<CollectionSettings> = {
    outsideSourceValidation: {
      unique: {
        validation: [],
        message: this.translate.instant('aggregate-editor.form.errors.duplicate_fields'),
      },
    },
  };

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });
  }

  onAggregatedRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { aggregated_fields: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { aggregated_fields: records } }));
    this.cubeCollectionSettings.outsideSourceValidation.unique.validation = records.map((item) => item.alias);
    this.cubeCollectionSettings = { ...this.cubeCollectionSettings };
  }

  onCubeRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { grouped_fields: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { grouped_fields: records } }));
    this.aggregateCollectionSettings.outsideSourceValidation.unique.validation = flatten(
      records.map((item) => item.fields),
    );
    this.aggregateCollectionSettings = { ...this.aggregateCollectionSettings };
  }

  onValidityChange() {
    const isValid = this.cubeCollectionValid && this.aggregateCollectionValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onAggregateValidityChange(value) {
    this.aggregateCollectionValid = value;

    this.onValidityChange();
  }

  onCubeValidityChange(value) {
    this.cubeCollectionValid = value;

    this.onValidityChange();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
