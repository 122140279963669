import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { SalesforceSoapConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { DUMMY_PASSWORD } from './connection-component-username-password.component';

@Component({
  selector: 'connection-form-salesforce-soap',
  template: `
    <xp-form-validation type="SalesforceSoapConnection" [name]="formName">
      <form id="connectionFormSalesforceSoap" name="connectionFormSalesforceSoap" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <xp-form-group>
              <label for="security_token"
                >{{ 'connections.form.labels.instance_url' | translate
                }}<i
                  class="fa fa-exclamation-circle"
                  matTooltip="E.g. xplenty.my.salesforce.com"
                  matTooltipPosition="after"
                  matTooltipClass="after"
                ></i>
              </label>
              <xp-input
                type="text"
                class="form-control"
                name="instance"
                id="database"
                [(ngModel)]="connection.instance_url"
                placeholder=""
              ></xp-input>
            </xp-form-group>
          </div>
          <div class="col-md-6">
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
            <xp-form-group>
              <label for="security_token"
                >{{ 'connections.form.labels.security_token' | translate }}
                <span *ngIf="securityTokenChangeable">
                  (<a (click)="changeSecurityToken()"
                    ><span *ngIf="securityTokenDisabled">{{ 'connections.form.labels.change' | translate }}</span
                    ><span *ngIf="!securityTokenDisabled">{{ 'connections.form.labels.cancel' | translate }}</span></a
                  >)
                </span></label
              >
              <xp-input
                type="password"
                class="form-control"
                name="security_token"
                id="security_token"
                [(ngModel)]="connection.security_token"
                [attr.disabled]="securityTokenDisabled"
                [disable]="securityTokenDisabled"
                placeholder=""
              ></xp-input>
            </xp-form-group>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormSalesforceSoapComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<SalesforceSoapConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() tunnelTypeChange = new EventEmitter<String>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormSalesforceSoap';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  securityTokenChangeable = false;
  securityTokenDisabled = true;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.securityTokenChangeable = !!this.connection.id;
    this.securityTokenDisabled = this.securityTokenChangeable;

    if (this.securityTokenDisabled) {
      this.connection.security_token = DUMMY_PASSWORD;
    }

    super.ngOnInit();
  }

  changeSecurityToken(): void {
    this.securityTokenDisabled = !this.securityTokenDisabled;

    if (this.securityTokenDisabled) {
      this.connection.security_token = DUMMY_PASSWORD;
    } else {
      this.connection.security_token = '';
    }
  }
}
