import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { DatabaseDefaultConnection } from '../../connection.models';
import { AppState } from '../../../store';
import { selectAccount } from '../../../account/store/account.selectors';

@Component({
  selector: 'connection-component-reverse-tunnel',
  template: `
    <div class="connection-component-reverse-tunnel alert alert-info">
      <strong
        ><i class="fa fa-info-circle" aria-hidden="true"></i>
        {{ 'connection-component-reverse-tunnel.title' | translate }}</strong
      >
      <p [innerHTML]="'connection-component-reverse-tunnel.text' | translate: values"></p>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentReverseTunnelComponent implements OnInit {
  @Input() connection: Partial<DatabaseDefaultConnection> = {};
  @Input() localPort: number;
  values = {};

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store
      .select(selectAccount)
      .pipe(first())
      .subscribe((account) => {
        this.values = {
          host: account.tunnel_host,
          port: this.localPort,
        };
      });
  }
}
