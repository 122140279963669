import { ValidatorRunner } from './models';
import * as VS from '../helpers/validators.helpers';

const settings = [
  { name: 'in', fn: VS.isIncludedIn },
  { name: 'within', fn: VS.isIncludedWithin },
];

export const InclusionValidator: ValidatorRunner = {
  name: 'InclusionValidator',
  message: 'errors.InclusionValidator',
  validate({ value, options, formScope }) {
    if (VS.shouldNotBeExecuted(options, formScope)) return true;

    // common validator options
    if (VS.isTrue(options.allow_nil) && VS.isNull(value)) return true;
    if (VS.isTrue(options.allow_blank) && VS.isEmpty(value)) return true;

    return settings
      .map(function (o) {
        return VS.isUndefined(options[o.name]) || o.fn.call(null, value, options[o.name]);
      })
      .every(function (result) {
        return result;
      });
  },
};
