import { Component } from '@angular/core';

@Component({
  selector: 'thank-you',
  template: `
    <div class="page thank-you">
      <div class="container text-center">
        <h1>{{ 'auth.thank_you.title' | translate }}</h1>
        <h3>{{ 'auth.thank_you.subtitle' | translate }}</h3>
      </div>
    </div>
  `,
})
export class ThankYouComponent {}
