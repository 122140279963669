import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { DesignerSchema } from '../../../models/designer-schema.model';
import { COMPONENT_TYPE } from '../../../../constants/component_type';
import { Schema } from '../../../package.models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { selectDatabaseColumns } from '../../../../connections/store/connections.selectors';

function autoGenerateAlias(record: DesignerSchemaFieldI): string {
  if (record && record.field_name) {
    return record.field_name;
  }
  return '';
}

@Component({
  selector: 'schema-mapping-database-collection',
  template: `
    <div class="schema-mapping-database">
      <div class="alert alert-warning" *ngIf="errorMessage">
        <button type="button" class="close" (click)="errorMessage = null">
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="error-message-text" [innerHTML]="errorMessage"></span>
      </div>
      <xp-fields-collection
        [records]="recordsCopy"
        [collectionSettings]="collectionSettings"
        [isValid]="valid"
        (validityChange)="onFieldsValidityChange($event)"
        (recordsChange)="onRecordChange($event)"
        [columns]="columns"
        duplicationValidationProp="column_name"
        duplicationValidationPropName="Destination column name"
      >
        <ng-template templateName="field_name" let-item>
          <xp-field-picker
            [value]="item.record.field_name"
            [index]="item.index"
            [schema]="parentSchemas[0]"
            [fields]="(parentSchemas[0] || {}).fields || []"
            propName="field_name"
            (fieldChange)="onFieldChange($event, item.record, 'field_name')"
            class="fields-collection-editor"
          ></xp-field-picker>
        </ng-template>
        <ng-template templateName="field_name-header" let-item>
          <span>{{ 'schema-mapping-database.headers.input-fields' | translate }}</span>
        </ng-template>

        <ng-template templateName="column_name" let-item>
          <xp-database-column-editor
            [availableColumns]="databaseColumns$ | async"
            [value]="item.record.column_name"
            [index]="item.index"
            [focusedProp]="item.focusedProp"
            [isDuplicateError]="item.record.isDuplicateError"
            propName="column_name"
            (fieldChange)="onFieldChange($event, item.record, 'column_name')"
            class="fields-collection-editor"
          ></xp-database-column-editor>
        </ng-template>
        <ng-template templateName="column_name-header" let-item>
          <span>{{ 'schema-mapping-database.headers.destination-column' | translate }}</span>
        </ng-template>

        <ng-template templateName="is_merge_key" let-item>
          <xp-checkbox-editor
            [value]="item.record.is_merge_key"
            [index]="item.index"
            (valueChange)="onFieldChange($event, item.record, 'is_merge_key')"
            class="fields-collection-editor"
          ></xp-checkbox-editor>
        </ng-template>
        <ng-template templateName="is_merge_key-header" let-item>
          <span>{{ 'fields-collection.headers.merge-key' | translate }}</span>
        </ng-template>

        <ng-template templateName="is_bson" let-item>
          <xp-checkbox-editor
            [value]="item.record.is_bson"
            [index]="item.index"
            (valueChange)="onFieldChange($event, item.record, 'is_bson')"
            class="fields-collection-editor"
          ></xp-checkbox-editor>
        </ng-template>
        <ng-template templateName="is_bag" let-item>
          <xp-checkbox-editor
            [value]="item.record.is_bag"
            [index]="item.index"
            (valueChange)="onFieldChange($event, item.record, 'is_bag')"
            class="fields-collection-editor"
          ></xp-checkbox-editor>
        </ng-template>
        <ng-template templateName="is_bson-header" let-item>
          <span>{{ 'fields-collection.headers.bson' | translate }}</span>
          <i
            class="fa fa-exclamation-circle"
            [matTooltip]="'fields-collection.headers.bson-tooltip' | translate"
            matTooltipPosition="above"
          ></i>
        </ng-template>
        <ng-template templateName="is_bag-header" let-item>
          <span>{{ 'fields-collection.headers.bag' | translate }}</span>
          <i
            class="fa fa-exclamation-circle"
            [matTooltip]="'fields-collection.headers.bag-tooltip' | translate"
            matTooltipPosition="above"
          ></i>
        </ng-template>
      </xp-fields-collection>
    </div>
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaMappingDatabaseCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[] = [];
  @Input() valid: boolean;
  @Input() parentSchemas: Schema[];
  @Input() operationType: string;
  @Input() componentType: string;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  databaseColumns$ = this.store.select(selectDatabaseColumns);

  collectionSettings: CollectionSettings;
  columns: string[];
  errorMessage: string;

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.operationType) {
      this.validityChange.emit(this.validateSchemaMapping());
    }
  }

  ngOnInit() {
    this.recordsCopy = [...(this.records || [])].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 10,
      emptyRecord: {
        field_name: '',
        column_name: '',
        is_merge_key: false,
        FC_pristine: true,
      },
      parentSchemas: this.parentSchemas,
      autoGenerateFn: this.autoGenerateAliasAndUpdateRecords.bind(this),
      autoFillFns: [
        {
          func: this.autoFill.bind(this),
          text: 'Auto fill',
          isHidden: true,
        },
        {
          func: this.lowerCaseFields.bind(this),
          text: 'Lowercase fields',
        },
        {
          func: this.upperCaseFields.bind(this),
          text: 'Uppercase fields',
        },
      ],
    };

    let columns = ['field_name', 'column_name', 'is_merge_key'];

    if (this.componentType === COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT) {
      columns.push('is_bson');
    } else if (this.componentType === COMPONENT_TYPE.BIG_QUERY_DESTINATION_COMPONENT) {
      columns.push('is_bag');
    }

    this.columns = columns;

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  autoFill(): DesignerSchemaFieldI[] {
    if (!this.parentSchemas[0] || !this.parentSchemas[0]?.fields?.length) return [];
    return this.fillRecords(this.parentSchemas[0].fields);
  }

  lowerCaseFields(): DesignerSchemaFieldI[] {
    if (!this.parentSchemas[0] || !this.parentSchemas[0]?.fields?.length) return [];
    return (this.recordsCopy || []).map((record) => ({
      ...record,
      column_name: record.column_name.toLowerCase(),
    }));
  }

  upperCaseFields(): DesignerSchemaFieldI[] {
    if (!this.parentSchemas[0] || !this.parentSchemas[0]?.fields?.length) return [];
    return (this.recordsCopy || []).map((record) => ({
      ...record,
      column_name: record.column_name.toUpperCase(),
    }));
  }

  fillRecords(fields: DesignerSchemaFieldI[]): DesignerSchemaFieldI[] {
    return fields.map((field) => {
      let fieldName = field.name;

      const fieldData: DesignerSchemaFieldI = {
        field_name: field.name,
        column_name: fieldName,
        is_merge_key: false,
        id: uuidv4(),
      };

      if (this.componentType === COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT) {
        fieldData.is_bson = false;
      }

      return fieldData;
    });
  }

  autoGenerateAliasAndUpdateRecords(record: DesignerSchemaFieldI) {
    const alias = autoGenerateAlias(record);

    if (alias) {
      this.recordsCopy = this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            column_name: alias,
          };
        }
        return item;
      });
    }

    this.onRecordChange({ records: this.recordsCopy });
  }

  validateSchemaMapping() {
    let schemaMappingValid = true;

    if (this.componentType === COMPONENT_TYPE.SPANNER_DESTINATION_COMPONENT) {
      if (!this.recordsCopy.some((item) => item.is_merge_key)) {
        schemaMappingValid = false;
        this.errorMessage = 'Merge key must be selected';
      } else {
        this.errorMessage = null;
      }
    } else if (['merge', 'insert_or_update', 'merge_update_and_insert'].indexOf(this.operationType) > -1) {
      if (!this.recordsCopy.some((item) => item.is_merge_key)) {
        schemaMappingValid = false;
        this.errorMessage = 'Merge key must be selected';
      } else {
        this.errorMessage = null;
      }
    } else {
      this.errorMessage = null;
    }

    // checking valid records
    if (this.recordsCopy.find((item) => item.FC_pristine)) {
      schemaMappingValid = false;
    }

    return schemaMappingValid;
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fields = records.map((record) => ({
      field_name: record.field_name,
      column_name: record.column_name,
      is_merge_key: record.is_merge_key,
      is_bson: record.is_bson,
      is_bag: record.is_bag,
    }));

    this.recordsChange.emit(fields);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value && this.validateSchemaMapping());
  }
}
