import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'json-path',
  template: `
    <div class="json-path">
      <div class="form-group">
        <label for="json_path">Base record JSONPath Expression</label>
        <xp-input
          type="text"
          name="json_path"
          id="json_path"
          class="form-control"
          placeholder="$.results[*]"
          [ngModel]="path"
          (ngModelChange)="onValueChange($event)"
        ></xp-input>
      </div>
      <div class="form-group">
        <div class="btn-group btn-group-md btn-group-select">
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ active: type === TYPES.object }"
            (click)="selectType(TYPES.object)"
          >
            Object
          </button>
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ active: type === TYPES.array }"
            (click)="selectType(TYPES.array)"
          >
            Array
          </button>
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{ active: type === TYPES.custom }"
            (click)="selectType(TYPES.custom)"
          >
            Custom
          </button>
        </div>
      </div>
    </div>
  `,
})
export class JsonPathComponent implements OnChanges {
  @Input() path: string;
  @Output() valueChange = new EventEmitter<string>();

  TYPES = {
    object: 'object',
    array: 'array',
    custom: 'custom',
  };

  TYPES_VALUES = {
    object: '$',
    array: '$[*]',
    custom: '$.data[*]',
  };
  type = this.TYPES.object;

  selectType(type) {
    this.type = type;
    this.valueChange.emit(this.TYPES_VALUES[type]);
  }

  onValueChange(value) {
    this.valueChange.emit(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.path) {
      if (this.path === this.TYPES_VALUES.object) {
        this.type = this.TYPES.object;
      } else if (this.path === this.TYPES_VALUES.array) {
        this.type = this.TYPES.array;
      } else {
        this.type = this.TYPES.custom;
      }
    }
  }
}
