import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'xpDateUTC' })
export class XpDateUtcPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): string {
    return value ? `${moment(value).utc().format('YYYY-MM-DD HH:mm')} UTC` : '';
  }
}
